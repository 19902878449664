import { customElement, property } from 'lit/decorators.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';
import { html } from 'lit';
import '../elements/d-edit-text.js';
import '../../elements/d-section.js';

export interface NewNameInput {
  title: string;
}

export interface NewNameResult {
  name: string;
  action: 'cancel' | 'done';
}

@customElement('new-name-dialog')
export class NewNameDialog extends BaseDialog<NewNameInput, NewNameResult> {
  headerActions = [
    { name: 'Avbryt', action: 'cancel' },
    { name: 'Ferdig', action: 'done', disabled: true },
  ];
  @property({ type: String })
  newItemName = '';

  width = 400;

  protected fetchResult(detail?: string): NewNameResult {
    return {
      name: detail === 'done' ? this.newItemName : '',
      action: detail === 'done' ? 'done' : 'cancel',
    };
  }

  protected initializeDialog(input: NewNameInput) {
    this.title = input.title;
  }

  protected renderBody() {
    return html`<d-section>
      <d-edit-text label="Navn" .value=${this.newItemName} @value-changed=${this.onNewItemNameChanged}></d-edit-text>
    </d-section>`;
  }

  private onNewItemNameChanged(e: CustomEvent<{ value: string }>) {
    this.newItemName = e.detail.value;
    if (this.newItemName) {
      this.headerActions = [
        { name: 'Avbryt', action: 'cancel' },
        { name: 'Ferdig', action: 'done' },
      ];
    } else {
      this.headerActions = [
        { name: 'Avbryt', action: 'cancel' },
        { name: 'Ferdig', action: 'done', disabled: true },
      ];
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'new-name-dialog': NewNameDialog;
  }
}
