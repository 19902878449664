import { AbstractEntityViewWithRevisions, contentViewBuilder, EntityContent } from 'src/content/entity-content.js';
import '../../library/fields/index.js';
import '../../library/editors/index.js';
import '../../library/editors/components/d-edit-classification.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/lists/d-list-section.js';
import { customElement } from 'lit/decorators.js';
import { html, TemplateResult } from 'lit';
import type { FormViewItem } from 'src/library/components/d-form-view.js';
import type { Checklist } from 'src/library/fields/d-checklist.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { SigningOrder } from 'src/store/api';
import { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { ActionInput } from 'src/library/elements/d-action.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';

export interface ContractViewEditItem {
  pages: string[];
  partners: string[];
  employees: string[];
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  content: string;
  validFromDate: string;
  validToDate: string;
}

export interface ContractView extends AbstractEntityViewWithRevisions<ContractViewEditItem>, UpdateSectionItem {
  partners: string[];
  employees: string[];
  relationsLocked: boolean;
  type: 'contracts';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  content: string;
  validFromDate: string;
  validToDate: string;
  contractPartyNames: string;
  checklist?: Checklist;
  availablePages: SelectTagOption[];
  availableEmployees: SelectTagOption[];
  availablePartners: SelectTagOption[];
  pages: string[];
  signingOrders: SigningOrder[];
}
/**
 *
 * STATUS OK
 */
@customElement('d-contract-view')
export class DContractView extends EntityContent<ContractView, ContractViewEditItem> {
  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    builder.addClassification(
      this.entityView.classification,
      this.entityView.accessControl,
      this.entityView.accessControlOptions,
    );
    builder.addDate('contracts_validFromDate', this.entityView.validFromDate);
    builder.addDate('contracts_validToDate', this.entityView.validToDate);
    builder.addText('contracts_contractPartyNames', this.entityView.contractPartyNames, 'm', true);
    if (this.entityView.checklist !== undefined) {
      builder.addChecklist('generalFields_checklist', this.entityView.checklist);
    }
    builder.addHtmlContent('contracts_content', this.entityView.content);
    return builder.build();
  }

  renderLists(): TemplateResult<1> {
    if (this.entityView.href.startsWith('/account/4322')) {
      const items: ListSectionItemInput[] = this.entityView.signingOrders.map((s) => {
        return {
          label: s.status ?? '-',
          accessible: false,
        };
      });

      const actions: ActionInput[] = [{ name: 'Send til signering', action: 'request-signatures', slot: 'top-right' }];

      return html` <d-list-section
        label="Signering"
        icon="events"
        .items=${items}
        .actions=${actions}
        @action=${() =>
          this.dispatchEvent(
            new CustomEvent('request-signatures', {
              bubbles: true,
              composed: true,
              detail: {
                entityType: 'contracts',
                entityUuid: this.entityView.uuid,
                employees: this.entityView.employees,
              },
            }),
          )}
      ></d-list-section>`;
    }
    return super.renderLists();
  }

  async initializeEditItem() {
    this.editItem = {
      accessControl: this.entityView.accessControl,
      classification: this.entityView.classification,
      content: this.entityView.content,
      employees: this.entityView.employees,
      partners: this.entityView.partners,
      validFromDate: this.entityView.validFromDate,
      validToDate: this.entityView.validToDate,
      name: this.entityView.name,
      pages: this.entityView.pages,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderEditItem(item: ContractViewEditItem): TemplateResult<1> {
    return html`
      ${this.renderEditClassification(item)}
      <d-section>
        <d-edit-text
          field="contracts_name"
          .autofocus=${!this.entityView.isConfirmedEntity}
          .selectOnFocus=${!this.entityView.isConfirmedEntity}
          placeholder="Dette feltet må fylles ut"
          mark-if-empty
          .value=${item.name}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, name: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>

      <d-section>
        <d-select-date
          field="contracts_validFromDate"
          .value=${item.validFromDate}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, validFromDate: e.detail.value };
          }}
        >
        </d-select-date>

        <d-select-date
          field="contracts_validToDate"
          .value=${item.validToDate}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, validToDate: e.detail.value };
          }}
        >
        </d-select-date>
      </d-section>

      ${this.entityView.relationsLocked
        ? html` <d-section>
            <d-view-text class="flex" field="contracts_contractPartyNames" .value=${this.entityView.contractPartyNames}>
            </d-view-text>
          </d-section>`
        : html` <d-section>
              <d-select-tag
                field="contracts_employees"
                .options=${this.entityView.availableEmployees}
                .value=${item.employees}
                @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
                  this.editItem = { ...item, employees: e.detail.value };
                }}
              >
              </d-select-tag>
            </d-section>
            <d-section>
              <d-select-tag
                field="contracts_partners"
                .options=${this.entityView.availablePartners}
                .value=${item.partners}
                @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
                  this.editItem = { ...item, partners: e.detail.value };
                }}
              >
              </d-select-tag>
            </d-section>`}

      <d-edit-html
        field="contracts_content"
        .value=${item.content}
        .docsForLinking=${this.entityView.docsForLinking}
        .contentStickyTop=${this.contentStickyTop}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          this.editItem = { ...item, content: e.detail.value };
        }}
      ></d-edit-html>
    `;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-contract-view': DContractView;
  }
}
