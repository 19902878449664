import { LocalDate } from 'src/utilities/local-date.js';
import type { InstanceRule } from './instance-rule.js';

export abstract class BaseInstanceRule implements InstanceRule {
  private static datesLookup = LocalDate.dates(LocalDate.of(2018, 1, 1), LocalDate.of(2031, 1, 1));

  public expand(start: LocalDate, end: LocalDate, interval: number): LocalDate[] {
    const s: LocalDate[] = [];
    const d = BaseInstanceRule.datesLookup;

    let c = 0;
    d.forEach((value) => {
      if (value.isSameOrAfter(start) && value.isSameOrBefore(end) && this.matches(value)) {
        if (c % interval === 0) {
          s.push(value);
        }
        c++;
      }
    });
    return s;
  }

  public abstract matches(date: LocalDate): boolean;

  public abstract optionsToString(interval: number): string;

  public abstract scheduleDescription(interval: number): string;
}
