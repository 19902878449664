/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SendChangedTaskRecipient
 */
export interface SendChangedTaskRecipient {
  /**
   *
   * @type {string}
   * @memberof SendChangedTaskRecipient
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedTaskRecipient
   */
  instanceUuid?: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedTaskRecipient
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedTaskRecipient
   */
  schedule?: string;
  /**
   *
   * @type {string}
   * @memberof SendChangedTaskRecipient
   */
  assignedTo?: string;
}

/**
 * Check if a given object implements the SendChangedTaskRecipient interface.
 */
export function instanceOfSendChangedTaskRecipient(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SendChangedTaskRecipientFromJSON(json: any): SendChangedTaskRecipient {
  return SendChangedTaskRecipientFromJSONTyped(json, false);
}

export function SendChangedTaskRecipientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendChangedTaskRecipient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: !exists(json, 'uuid') ? undefined : json['uuid'],
    instanceUuid: !exists(json, 'instanceUuid') ? undefined : json['instanceUuid'],
    time: !exists(json, 'time') ? undefined : json['time'],
    schedule: !exists(json, 'schedule') ? undefined : json['schedule'],
    assignedTo: !exists(json, 'assignedTo') ? undefined : json['assignedTo'],
  };
}

export function SendChangedTaskRecipientToJSON(value?: SendChangedTaskRecipient | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    instanceUuid: value.instanceUuid,
    time: value.time,
    schedule: value.schedule,
    assignedTo: value.assignedTo,
  };
}
