/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';

/**
 *
 * @export
 * @interface ExternalConnectionViewModel
 */
export interface ExternalConnectionViewModel {
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionViewModel
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionViewModel
   */
  connectionType?: ExternalConnectionViewModelConnectionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionViewModel
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalConnectionViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionViewModel
   */
  employeeUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionViewModel
   */
  partnerUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionViewModel
   */
  networkUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionViewModel
   */
  supplierUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionViewModel
   */
  otherRiskAssessments: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalConnectionViewModel
   */
  riskAssessmentConcludesOk: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ExternalConnectionViewModel
   */
  riskAssessments: Array<string>;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof ExternalConnectionViewModel
   */
  attachments?: Array<AttachmentViewModel>;
}

/**
 * @export
 */
export const ExternalConnectionViewModelConnectionTypeEnum = {
  None: 'NONE',
  Nhn: 'NHN',
  Other: 'OTHER',
  NhNotherIsp: 'NHNotherISP',
} as const;
export type ExternalConnectionViewModelConnectionTypeEnum =
  (typeof ExternalConnectionViewModelConnectionTypeEnum)[keyof typeof ExternalConnectionViewModelConnectionTypeEnum];

/**
 * Check if a given object implements the ExternalConnectionViewModel interface.
 */
export function instanceOfExternalConnectionViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'otherRiskAssessments' in value;
  isInstance = isInstance && 'riskAssessmentConcludesOk' in value;
  isInstance = isInstance && 'riskAssessments' in value;

  return isInstance;
}

export function ExternalConnectionViewModelFromJSON(json: any): ExternalConnectionViewModel {
  return ExternalConnectionViewModelFromJSONTyped(json, false);
}

export function ExternalConnectionViewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ExternalConnectionViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    type: !exists(json, 'type') ? undefined : json['type'],
    connectionType: !exists(json, 'connectionType') ? undefined : json['connectionType'],
    description: !exists(json, 'description') ? undefined : json['description'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    uuid: json['uuid'],
    employeeUuid: !exists(json, 'employeeUuid') ? undefined : json['employeeUuid'],
    partnerUuid: !exists(json, 'partnerUuid') ? undefined : json['partnerUuid'],
    networkUuid: !exists(json, 'networkUuid') ? undefined : json['networkUuid'],
    supplierUuid: !exists(json, 'supplierUuid') ? undefined : json['supplierUuid'],
    otherRiskAssessments: json['otherRiskAssessments'],
    riskAssessmentConcludesOk: json['riskAssessmentConcludesOk'],
    riskAssessments: json['riskAssessments'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function ExternalConnectionViewModelToJSON(value?: ExternalConnectionViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    type: value.type,
    connectionType: value.connectionType,
    description: value.description,
    deleted: value.deleted,
    uuid: value.uuid,
    employeeUuid: value.employeeUuid,
    partnerUuid: value.partnerUuid,
    networkUuid: value.networkUuid,
    supplierUuid: value.supplierUuid,
    otherRiskAssessments: value.otherRiskAssessments,
    riskAssessmentConcludesOk: value.riskAssessmentConcludesOk,
    riskAssessments: value.riskAssessments,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
