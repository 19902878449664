import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import './d-app.js';
import Bugsnag from '@bugsnag/js';
import * as dabihStore from 'src/store';

const createUsernameInput = () => {
  const input = document.createElement('input');
  input.slot = 'usernameInput';
  input.placeholder = 'epost';
  input.type = 'email';
  input.name = 'loginName';
  input.autocomplete = 'username';
  return input;
};

const createPasswordInput = () => {
  const input = document.createElement('input');
  input.slot = 'passwordInput';
  input.placeholder = 'passord';
  input.type = 'password';
  input.name = 'password';
  input.autocomplete = 'current-password';
  return input;
};

@customElement('app-root')
export class AppRoot extends LitElement {
  @property({ type: Number, attribute: 'body-scroll-top' })
  bodyScrollTop = 0;
  @property({ type: Number, attribute: 'body-scroll-left' })
  bodyScrollLeft = 0;
  @state()
  private readonly _usernameInput = createUsernameInput();
  @state()
  private readonly _passwordInput = createPasswordInput();

  constructor() {
    super();
    const releaseStage =
      'localhost' === location.hostname || '127.0.0.1' === location.hostname ? 'development' : 'production';
    Bugsnag.start({
      apiKey: '042530ff59de7e505265d70acf86de82',
      appVersion: __APP_VERSION__,
      releaseStage: releaseStage,
      enabledReleaseStages: ['production'],
      metadata: {},
      user: {},
      onError: function (event) {
        event.addMetadata('LogRocket', {
          sessionURL: dabihStore.sessionURL,
        });
      },
    });
    this._usernameInput = createUsernameInput();
    this._passwordInput = createPasswordInput();
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      d-app {
        height: 100%;
        width: 100%;
      }
    `;
  }

  connectedCallback() {
    super.connectedCallback();

    this.insertAdjacentElement('beforeend', this._usernameInput);
    this.insertAdjacentElement('beforeend', this._passwordInput);
  }

  render() {
    return html`
      <d-app .bodyScrollTop=${this.bodyScrollTop} .bodyScrollLeft=${this.bodyScrollLeft}>
        <div style="display: flex; align-items: center; justify-content: center; margin-top: 100px; height: 200px;">
          <div style="color: white">Laster inn TrinnVis…</div>
        </div>
        <slot slot="usernameInput" name="usernameInput"></slot>
        <slot slot="passwordInput" name="passwordInput"></slot>
      </d-app>
    `;
  }
}
