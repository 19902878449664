import type { DocumentView, DocumentViewEditItem } from 'src/content/documents/d-document-view.js';
import {
  fetchDraft,
  fetchRevisions,
  pagesAsSelectTagOptions,
  toAttachmentItems,
} from 'src/models/factory-utilities.js';
import { BASE_PATH } from 'src/store/api';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { getOrganization } from 'src/store/selectors/organization.js';
import { toDocumentViewModel } from 'src/store/selectors/organization-documents.js';
import { key } from 'src/store/selectors/selectors';
import { currentUserUuid, writeAccess } from 'src/store/selectors/user.js';
import { assertIsDefined } from 'src/lib';
import { CommonDataEntityView } from 'src/content/entity-content';

export async function buildDocumentView(
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<DocumentView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const availablePages = pagesAsSelectTagOptions(state);

  const item = organization.documentsById[uuid];
  assertIsDefined(item);

  const entity = toDocumentViewModel(item);

  return {
    ...commonData,
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess:
      entity.classification === 'NONE' || (entity.accessControl ?? []).includes(currentUserUuid(state) ?? ''),
    currentUserHasWriteAccess: writeAccess(state),

    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: entity.helpContent ?? '',
    href: currentParent + '/documents/' + uuid,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/documents/' + uuid + '.pdf?key=' + key(state),
    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'documents',
    name: entity.name ?? '',
    classification: entity.classification ?? 'NONE',
    accessControl: entity.accessControl ?? [],
    content: entity.content ?? '',
    revisions: await fetchRevisions(
      state.token ?? '',
      'documents',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
    ),
    attachments: toAttachmentItems('documents', entity.uuid, entity.attachments),
    validFromDate: entity.validFromDate ?? '',
    validToDate: entity.validToDate ?? '',
    availablePages: availablePages,
    pages: (entity.pages ?? []).map((x) => '' + x),
    today: LocalDate.fromString(state.today),
    deletable: true,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(state.token ?? '', 'documents', uuid, organization.organizationId);
      return command.draft as DocumentViewEditItem;
    },
  };
}
