import { css, html, LitElement } from 'lit';
import './d-share-staffing.js';
import './d-staffing-calendar-header.js';
import './d-staffing-calendar-access.js';
import './d-timekeeping-review.js';
import './d-staffing-statistics.js';
import { zoomResolution } from './d-staffing-calendar-table.js';
import './d-sickness-absence-statistics.js';
import '../d-list-leave-periods.js';
import '../../library/fields/d-spinner-robot.js';
import '../../library/editors/elements/d-select-dropdown.js';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import type { ZoomUnit } from 'src/pages/staffing-page/d-staffing-calendar-data.js';

/**
 *
 * Migration: calendarium Months, weeks and days are not selectors. Depending on performance they may need to be reimplemented as selectors.
 */
@customElement('d-staffing-calendar-groups')
export class DStaffingCalendarGroups extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }

      .staffGroupGraph {
        position: relative;
        top: -2px;
        display: flex;
        border-top: 1px solid hsl(0, 0%, 70%);
        background: var(--backgroundGray);
        cursor: pointer;
        transition: all 0.3s;
      }

      .graphDay {
        position: relative;
      }

      .staffGroupGraph .graphDay > .groupGraph {
        background-color: hsl(196, 83%, 49%);
      }

      .staffGroupGraph .graphDay > .groupGraph:nth-child(2) {
        opacity: 0.6;
      }

      .staffGroupGraph .graphDay > .groupGraph:nth-child(3) {
        opacity: 0.2;
      }

      .staffGroupGraphGrid {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-left: 1px solid hsla(1, 0%, 0%, 0.1);
        margin-left: -1px;
        background: transparent;
      }

      .staffGroupGraphGrid.unitStart {
        border-left: 1px solid hsla(1, 0%, 0%, 0.4);
      }

      .staffGroupGraphGrid div {
        flex: 1;
        border-bottom: 1px solid hsla(1, 0%, 0%, 0.1);
      }
    `,
  ];
  @property({ type: Boolean })
  staffGroupGraphExpanded = false;
  @property({ type: Number })
  employeesCount = 0;
  @property({ type: Array })
  groupGraphData: string[][] = [];
  @property({ type: Number })
  startOfDayHours = 8;
  @property({ type: Number })
  endOfDayHours = 16;
  @property({ type: String })
  zoom: ZoomUnit = 'day';
  private get pixelsPerHour() {
    return zoomResolution[this.zoom];
  }
  private get hoursPerDay() {
    return this.endOfDayHours - this.startOfDayHours;
  }
  private get pixelsPerDay() {
    return this.pixelsPerHour * this.hoursPerDay;
  }

  _toggleStaffGroupGraph() {
    this.staffGroupGraphExpanded = !this.staffGroupGraphExpanded;
    this.dispatchEvent(
      new CustomEvent<{ value: boolean }>('set-staff-group-graph-expanded', {
        composed: true,
        bubbles: true,
        detail: { value: this.staffGroupGraphExpanded },
      }),
    );
  }

  _staffGroupGraphHeightStyle() {
    if (this.staffGroupGraphExpanded) {
      return '' + this.employeesCount * 30 + 'px';
    }
    return '' + this.employeesCount * 8 + 'px';
  }

  render() {
    return html`
      <div
        class="staffGroupGraph"
        @click=${this._toggleStaffGroupGraph}
        style="${styleMap({ height: this._staffGroupGraphHeightStyle() })}"
      >
        ${this.groupGraphData.map(
          (day) => html`
            <div class="graphDay" style="${styleMap({ width: '' + this.pixelsPerDay + 'px' })}">
              ${day.map((g) => html`<div class="groupGraph" style="${g}"></div>`)}
              <div class="staffGroupGraphGrid">
                ${Array.from(Array(this.employeesCount).keys()).map(() => html`<div></div>`)}
              </div>
            </div>
          `,
        )}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-staffing-calendar-groups': DStaffingCalendarGroups;
  }
}
