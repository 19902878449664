import { html, TemplateResult } from 'lit';
import { AbstractEntityViewWithRevisions, contentViewBuilder, EntityContent } from 'src/content/entity-content.js';
import '../../library/fields/index.js';
import '../../library/elements/d-section.js';
import '../../library/editors/index.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/editors/components/d-edit-classification.js';
import { customElement } from 'lit/decorators.js';
import type { FormViewItem } from 'src/library/components/d-form-view.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';

export interface DocumentViewEditItem {
  pages: string[];
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  content: string;
  validFromDate: string;
  validToDate: string;
}

export interface DocumentView extends AbstractEntityViewWithRevisions<DocumentViewEditItem>, UpdateSectionItem {
  type: 'documents';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  content: string;
  validFromDate: string;
  validToDate: string;
  availablePages: SelectTagOption[];
  pages: string[];
  hasDraft: boolean;
}

/**
 *
 * STATUS OK
 */
@customElement('d-document-view')
export class DDocumentView extends EntityContent<DocumentView, DocumentViewEditItem> {
  static readonly styles = EntityContent.styles;

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    builder.addClassification(
      this.entityView.classification,
      this.entityView.accessControl,
      this.entityView.accessControlOptions,
    );
    builder.addDate('documents_validFromDate', this.entityView.validFromDate);
    builder.addDate('documents_validToDate', this.entityView.validToDate);
    builder.addHtmlContent('', this.entityView.content);
    return builder.build();
  }

  async initializeEditItem() {
    if (this.entityView.hasDraft) {
      this.editItem = await this.entityView.fetchDraft();
    } else {
      this.editItem = {
        accessControl: this.entityView.accessControl,
        classification: this.entityView.classification,
        content: this.entityView.content,
        validFromDate: this.entityView.validFromDate,
        validToDate: this.entityView.validToDate,
        name: this.entityView.name,
        pages: this.entityView.pages,
      };
    }
  }

  /*renderPreContent(): TemplateResult<1> {
    return this.entityView.hasDraft
      ? html` <d-section>
          <d-wrap split-reverse baseline>
            ${this.entityView.currentUserHasWriteAccess
              ? html` <d-action @click=${() => this.onEditModeOn()}>Åpne utkast</d-action>`
              : nothing}
            <d-label label="Det finnes et nyere utkast til dette dokumentet"></d-label>
          </d-wrap>
        </d-section>`
      : html``;
  }

  renderPreEditMode(): TemplateResult<1> {
    return this.entityView.hasDraft
      ? html` <d-section>
          <d-wrap split-reverse baseline>
            ${this.entityView.currentUserHasWriteAccess
              ? html` <div>
                  <d-action @click=${this.onClearDraft}>Slett utkast</d-action>
                  <d-action @click=${this.onEditModeOff}>Lukk utkast</d-action>
                  <d-action @click=${this.onDone}>Publiser</d-action>
                </div>`
              : nothing}
            <d-label label="Dette er et utkast i arbeid"></d-label>
          </d-wrap>
        </d-section>`
      : html``;
  }
  */

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderEditItem(item: DocumentViewEditItem): TemplateResult<1> {
    return html`
      ${this.renderEditClassification(item)}
      <d-section>
        <d-edit-text
          field="documents_name"
          .autofocus=${!this.entityView.isConfirmedEntity}
          .selectOnFocus=${!this.entityView.isConfirmedEntity}
          placeholder="Dette feltet må fylles ut"
          mark-if-empty
          .value=${item.name}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, name: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>
      <d-section>
        <d-select-date
          field="documents_validFromDate"
          .value=${item.validFromDate}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, validFromDate: e.detail.value };
          }}
        >
        </d-select-date>
        <d-select-date
          field="documents_validToDate"
          .value=${item.validToDate}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, validToDate: e.detail.value };
          }}
        >
        </d-select-date>
      </d-section>
      <d-edit-html
        field="documents_content"
        .value=${item.content}
        .docsForLinking=${this.entityView.docsForLinking}
        .contentStickyTop=${this.contentStickyTop}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          this.editItem = { ...item, content: e.detail.value };
        }}
      ></d-edit-html>
    `;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-document-view': DDocumentView;
  }
}
