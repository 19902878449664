import { css, html } from 'lit';

import '../elements/d-label.js';
import './d-list-header.js';
import './d-list-section.js';
import '../elements/d-action.js';
import '../elements/d-section.js';
import '../fields/d-expansion.js';
import '../editors/elements/d-select-dropdown.js';
import { customElement, property } from 'lit/decorators.js';
import { EventList } from 'src/library/abstracts/event-list.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';

export interface TodoListItem {
  uuid: string;
  name?: string;
  eventNotes?: string;
  href: string;
  assignee?: string;
  myTask: boolean;
  overdue: boolean;
  dateTimeForOrdering: string;
  done: boolean;
  doneDate?: string;
  doneAtDisplay: string;
  doneByEmployeeUuid?: string;
  doneText: string;
  hasDraft: boolean;
  isConfirmedEntity: boolean;
  draftName: string;
}

/**
 *
 * STATUS OK
 */
@customElement('d-todo-list-section')
export class DTodoListSection extends EventList<ListSectionItemInput> {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;
  @property({ type: Array })
  items: ListSectionItemInput[] = [];

  private get hasNotes() {
    return this._hasNotes(
      this.items.filter((i) => {
        return this.showAll || i.eventCurrentUser;
      }),
    );
  }

  _todoActions() {
    const actions = this._actions(this.hasNotes);
    actions.push({
      name: 'Skjul utførte',
      action: 'showOptions',
      slot: 'top-right',
    });
    return actions;
  }

  render() {
    return html`
      <d-list-section
        label="Snarest"
        icon="events"
        no-item-text="Ingen oppgaver"
        big-label
        bordered
        theme-page
        .actions=${this._todoActions()}
        .items=${this._eventsWithFilterProperties(this.items)}
        ?showNotes=${this.showNotes}
        @action=${(e) => this._fireAction(e.detail)}
        .contentStickyTop=${this.contentStickyTop}
      ></d-list-section>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-todo-list-section': DTodoListSection;
  }
}
