/**
 * Returns a shorter path if the requested document is repeated in the path
 */
export function shortenPath(path) {
  if (path) {
    const parts = path.split('/');
    const last = parts[parts.length - 1];
    if (
      parts.filter((p) => {
        return p === last;
      }).length > 1
    ) {
      return path.split(last)[0] + last;
    }
  }
  return path;
}
