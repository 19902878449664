/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Update the name of the attachment.
 * @export
 * @interface AttachmentPropertiesUpdateMessage
 */
export interface AttachmentPropertiesUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof AttachmentPropertiesUpdateMessage
   */
  name: string;
}

/**
 * Check if a given object implements the AttachmentPropertiesUpdateMessage interface.
 */
export function instanceOfAttachmentPropertiesUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;

  return isInstance;
}

export function AttachmentPropertiesUpdateMessageFromJSON(json: any): AttachmentPropertiesUpdateMessage {
  return AttachmentPropertiesUpdateMessageFromJSONTyped(json, false);
}

export function AttachmentPropertiesUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AttachmentPropertiesUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
  };
}

export function AttachmentPropertiesUpdateMessageToJSON(value?: AttachmentPropertiesUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
  };
}
