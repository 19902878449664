/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TutorialState } from './TutorialState';
import { TutorialStateFromJSON, TutorialStateFromJSONTyped, TutorialStateToJSON } from './TutorialState';
import type { TutorialViewModel } from './TutorialViewModel';
import {
  TutorialViewModelFromJSON,
  TutorialViewModelFromJSONTyped,
  TutorialViewModelToJSON,
} from './TutorialViewModel';

/**
 *
 * @export
 * @interface OrganizationViewModel
 */
export interface OrganizationViewModel {
  /**
   *
   * @type {number}
   * @memberof OrganizationViewModel
   */
  organizationId: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  href?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  officeAddress?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  officePostcode?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  herNumber?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  businessEntityType?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  organizationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  mailAddress?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  mailPostcode?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  fax?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  invoicePlan?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  featurePlan?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OrganizationViewModel
   */
  features: Array<string>;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  invoiceReceiver?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  invoicePostcode?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  invoiceLocality?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  reference?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  invoiceReference?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  invoiceAddress?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  ownerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  settingsHref?: string;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationViewModel
   */
  singleUser?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof OrganizationViewModel
   */
  activityCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  specialTerms?: OrganizationViewModelSpecialTermsEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  sector: OrganizationViewModelSectorEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  invoiceSendMethod?: OrganizationViewModelInvoiceSendMethodEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  invoiceOrganizationNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationViewModel
   */
  leavePeriodEditRestriction: boolean;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  vacationSummaryNotes: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  vacationSummaryRecipient?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationViewModel
   */
  vacationSummaryShared?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OrganizationViewModel
   */
  vacationSummaryEmployees: Array<string>;
  /**
   *
   * @type {Array<TutorialViewModel>}
   * @memberof OrganizationViewModel
   */
  tutorials: Array<TutorialViewModel>;
  /**
   *
   * @type {Array<TutorialState>}
   * @memberof OrganizationViewModel
   */
  tutorialStates: Array<TutorialState>;
  /**
   *
   * @type {Array<string>}
   * @memberof OrganizationViewModel
   */
  staffingCalendarAccessList: Array<string>;
}

/**
 * @export
 */
export const OrganizationViewModelSpecialTermsEnum = {
  None: 'NONE',
  Dnlf: 'DNLF',
  Nff: 'NFF',
  Nof: 'NOF',
  Ntf: 'NTF',
  Nkf: 'NKF',
  Nmf: 'NMF',
  Npf: 'NPF',
  Nnf: 'NNF',
} as const;
export type OrganizationViewModelSpecialTermsEnum =
  (typeof OrganizationViewModelSpecialTermsEnum)[keyof typeof OrganizationViewModelSpecialTermsEnum];

/**
 * @export
 */
export const OrganizationViewModelSectorEnum = {
  Health: 'HEALTH',
  CraftsTransportationCleaning: 'CRAFTS_TRANSPORTATION_CLEANING',
  FoodAccommodation: 'FOOD_ACCOMMODATION',
  PersonalTradeService: 'PERSONAL_TRADE_SERVICE',
  Office: 'OFFICE',
  Other: 'OTHER',
} as const;
export type OrganizationViewModelSectorEnum =
  (typeof OrganizationViewModelSectorEnum)[keyof typeof OrganizationViewModelSectorEnum];

/**
 * @export
 */
export const OrganizationViewModelInvoiceSendMethodEnum = {
  Manual: 'MANUAL',
  Ehf: 'EHF',
} as const;
export type OrganizationViewModelInvoiceSendMethodEnum =
  (typeof OrganizationViewModelInvoiceSendMethodEnum)[keyof typeof OrganizationViewModelInvoiceSendMethodEnum];

/**
 * Check if a given object implements the OrganizationViewModel interface.
 */
export function instanceOfOrganizationViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'organizationId' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'features' in value;
  isInstance = isInstance && 'sector' in value;
  isInstance = isInstance && 'leavePeriodEditRestriction' in value;
  isInstance = isInstance && 'vacationSummaryNotes' in value;
  isInstance = isInstance && 'vacationSummaryEmployees' in value;
  isInstance = isInstance && 'tutorials' in value;
  isInstance = isInstance && 'tutorialStates' in value;
  isInstance = isInstance && 'staffingCalendarAccessList' in value;

  return isInstance;
}

export function OrganizationViewModelFromJSON(json: any): OrganizationViewModel {
  return OrganizationViewModelFromJSONTyped(json, false);
}

export function OrganizationViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    organizationId: json['organizationId'],
    href: !exists(json, 'href') ? undefined : json['href'],
    address: !exists(json, 'address') ? undefined : json['address'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    officeAddress: !exists(json, 'officeAddress') ? undefined : json['officeAddress'],
    officePostcode: !exists(json, 'officePostcode') ? undefined : json['officePostcode'],
    email: !exists(json, 'email') ? undefined : json['email'],
    herNumber: !exists(json, 'herNumber') ? undefined : json['herNumber'],
    name: json['name'],
    businessEntityType: !exists(json, 'businessEntityType') ? undefined : json['businessEntityType'],
    organizationNumber: !exists(json, 'organizationNumber') ? undefined : json['organizationNumber'],
    mailAddress: !exists(json, 'mailAddress') ? undefined : json['mailAddress'],
    mailPostcode: !exists(json, 'mailPostcode') ? undefined : json['mailPostcode'],
    fax: !exists(json, 'fax') ? undefined : json['fax'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    type: !exists(json, 'type') ? undefined : json['type'],
    url: !exists(json, 'url') ? undefined : json['url'],
    template: !exists(json, 'template') ? undefined : json['template'],
    invoicePlan: !exists(json, 'invoicePlan') ? undefined : json['invoicePlan'],
    featurePlan: !exists(json, 'featurePlan') ? undefined : json['featurePlan'],
    features: json['features'],
    invoiceReceiver: !exists(json, 'invoiceReceiver') ? undefined : json['invoiceReceiver'],
    invoicePostcode: !exists(json, 'invoicePostcode') ? undefined : json['invoicePostcode'],
    invoiceLocality: !exists(json, 'invoiceLocality') ? undefined : json['invoiceLocality'],
    reference: !exists(json, 'reference') ? undefined : json['reference'],
    invoiceReference: !exists(json, 'invoiceReference') ? undefined : json['invoiceReference'],
    invoiceAddress: !exists(json, 'invoiceAddress') ? undefined : json['invoiceAddress'],
    ownerEmail: !exists(json, 'ownerEmail') ? undefined : json['ownerEmail'],
    settingsHref: !exists(json, 'settingsHref') ? undefined : json['settingsHref'],
    singleUser: !exists(json, 'singleUser') ? undefined : json['singleUser'],
    activityCodes: !exists(json, 'activityCodes') ? undefined : json['activityCodes'],
    specialTerms: !exists(json, 'specialTerms') ? undefined : json['specialTerms'],
    sector: json['sector'],
    invoiceSendMethod: !exists(json, 'invoiceSendMethod') ? undefined : json['invoiceSendMethod'],
    invoiceOrganizationNumber: !exists(json, 'invoiceOrganizationNumber')
      ? undefined
      : json['invoiceOrganizationNumber'],
    leavePeriodEditRestriction: json['leavePeriodEditRestriction'],
    vacationSummaryNotes: json['vacationSummaryNotes'],
    vacationSummaryRecipient: !exists(json, 'vacationSummaryRecipient') ? undefined : json['vacationSummaryRecipient'],
    vacationSummaryShared: !exists(json, 'vacationSummaryShared') ? undefined : json['vacationSummaryShared'],
    vacationSummaryEmployees: json['vacationSummaryEmployees'],
    tutorials: (json['tutorials'] as Array<any>).map(TutorialViewModelFromJSON),
    tutorialStates: (json['tutorialStates'] as Array<any>).map(TutorialStateFromJSON),
    staffingCalendarAccessList: json['staffingCalendarAccessList'],
  };
}

export function OrganizationViewModelToJSON(value?: OrganizationViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    organizationId: value.organizationId,
    href: value.href,
    address: value.address,
    notes: value.notes,
    officeAddress: value.officeAddress,
    officePostcode: value.officePostcode,
    email: value.email,
    herNumber: value.herNumber,
    name: value.name,
    businessEntityType: value.businessEntityType,
    organizationNumber: value.organizationNumber,
    mailAddress: value.mailAddress,
    mailPostcode: value.mailPostcode,
    fax: value.fax,
    phone: value.phone,
    type: value.type,
    url: value.url,
    template: value.template,
    invoicePlan: value.invoicePlan,
    featurePlan: value.featurePlan,
    features: value.features,
    invoiceReceiver: value.invoiceReceiver,
    invoicePostcode: value.invoicePostcode,
    invoiceLocality: value.invoiceLocality,
    reference: value.reference,
    invoiceReference: value.invoiceReference,
    invoiceAddress: value.invoiceAddress,
    ownerEmail: value.ownerEmail,
    settingsHref: value.settingsHref,
    singleUser: value.singleUser,
    activityCodes: value.activityCodes,
    specialTerms: value.specialTerms,
    sector: value.sector,
    invoiceSendMethod: value.invoiceSendMethod,
    invoiceOrganizationNumber: value.invoiceOrganizationNumber,
    leavePeriodEditRestriction: value.leavePeriodEditRestriction,
    vacationSummaryNotes: value.vacationSummaryNotes,
    vacationSummaryRecipient: value.vacationSummaryRecipient,
    vacationSummaryShared: value.vacationSummaryShared,
    vacationSummaryEmployees: value.vacationSummaryEmployees,
    tutorials: (value.tutorials as Array<any>).map(TutorialViewModelToJSON),
    tutorialStates: (value.tutorialStates as Array<any>).map(TutorialStateToJSON),
    staffingCalendarAccessList: value.staffingCalendarAccessList,
  };
}
