import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { EmployeeGroup, StaffingCalendar } from 'src/pages/staffing-page/d-staffing-calendar-table.js';
import {
  StaffingEmployeeDayDialog,
  StaffingEmployeeDayInput,
  StaffingEmployeeDayResult,
} from 'src/pages/staffing-page/staffing-employee-day-dialog.js';
import type { EmployeeForStaffingCalendar } from 'src/store/selectors';
import './d-calendar-legend.js';
import './d-staffing-calendar-table.js';
import {
  EmployeeWithStaffGroup,
  StaffingGroupsDialog,
  StaffingGroupsResult,
} from 'src/pages/staffing-page/staffing-groups-dialog.js';
import { sortBy } from 'lodash';
import type { EmployeeViewModelStaffGroupEnum } from 'src/store/api';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';
import { EndOfDayTime, StartOfDayTime, type ZoomUnit } from 'src/pages/staffing-page/d-staffing-calendar-data.js';

/**
 *
 * Migration: calendarium Months, weeks and days are not selectors. Depending on performance they may need to be reimplemented as selectors.
 */
@customElement('d-staffing-calendar')
export class DStaffingCalendar extends ResponsiveElement {
  static readonly styles = css`
    :host {
      display: block;
      background: var(--backgroundGray);
    }
    .no-horizontal-scroll > div > div {
      box-sizing: border-box;
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      padding-top: 20px;
    }
  `;

  @property({ type: Number })
  width = 0;
  @property({ type: Number })
  appWidth = 1000;
  @property({ type: Number })
  pageWidth = 1000;
  @property({ type: Number })
  calendarWidth = 0;
  @property({ type: Number })
  paddingLeft = 30;
  @property({ type: Number })
  paddingTop = 20;
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: Number })
  scrollLeft = 0;
  @property({ type: Array })
  employeeGroups: EmployeeGroup[] = [];
  @property({ type: String })
  year = '';
  @property({ type: Array })
  employees: EmployeeForStaffingCalendar[] = [];
  @property({ type: Object })
  employeeCalendarData!: StaffingCalendar;
  @property({ type: String })
  zoom: ZoomUnit = 'day';
  @property({ type: Object })
  calendarDayRange!: { start: StartOfDayTime; end: EndOfDayTime };
  @property({ type: Array })
  groupGraphData: string[][] = [];
  @property({ type: Boolean })
  currentUserHasAccess = false;
  @property({ type: Boolean })
  leavePeriodEditRestriction = true;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: String })
  currentEmployeeUuid?: string;

  private get employeesWithStaffGroup(): EmployeeWithStaffGroup[] {
    const list: { name: string; uuid: string; staffGroup: EmployeeViewModelStaffGroupEnum }[] = [];
    const employees = this.employees;
    employees.forEach((employee) => {
      const e = {
        uuid: employee.uuid,
        name: employee.name,
        staffGroup: employee.staffGroup,
      };
      list.push(e);
    });
    return sortBy(list, [(item) => item.name]);
  }

  onResize() {
    this.width = this.offsetWidth;
    this.dispatchEvent(
      new CustomEvent('width-changed', {
        bubbles: true,
        composed: true,
        detail: this.offsetWidth,
      }),
    );
  }

  _onCalenderWidthChanged(e) {
    e.stopPropagation();
    this.calendarWidth = e.detail;
    this.dispatchEvent(
      new CustomEvent('calendar-width-changed', {
        bubbles: true,
        composed: true,
        detail: this.calendarWidth,
      }),
    );
  }

  render() {
    return html`
      <d-staffing-calendar-table
        .appWidth=${this.appWidth}
        .pageWidth=${this.appWidth}
        .contentStickyTop=${this.contentStickyTop}
        .scrollLeft=${this.scrollLeft}
        .paddingLeft=${this.paddingLeft}
        .paddingTop=${this.paddingTop}
        .employeeGroups=${this.employeeGroups}
        .year=${this.year}
        .employees=${this.employees}
        .employeeCalendarData=${this.employeeCalendarData}
        .dayStart=${this.calendarDayRange.start}
        .dayEnd=${this.calendarDayRange.end}
        .zoom=${this.zoom}
        .employeesForStaffingCalendar=${this.employees}
        .groupGraphData=${this.groupGraphData}
        @width-changed=${(e) => this._onCalenderWidthChanged(e)}
        @employee-day-click=${this.onEmployeeDayClick}
        @employee-groups=${this.onEmployeeGroups}
      ></d-staffing-calendar-table>
    `;
  }

  private async onEmployeeGroups(e: CustomEvent) {
    e.stopPropagation();
    const result: StaffingGroupsResult = await StaffingGroupsDialog.open({ employees: this.employeesWithStaffGroup });
    if (result.action === 'done') {
      this.dispatchEvent(
        new CustomEvent('update-employee-staff-groups', {
          bubbles: true,
          composed: true,
          detail: { value: result.changedEmployees },
        }),
      );
    }
  }

  private async onEmployeeDayClick(e: CustomEvent<{ uuid: string; date: string }>) {
    e.stopPropagation();
    console.log('Opening employee day dialog for ' + e.detail.uuid + ' on ' + e.detail.date);
    const employee = this.employees.find((employee) => employee.uuid === e.detail.uuid);
    if (employee) {
      console.log('Employee found with schedules', employee.workSchedules);
      console.log('this.currentEmployeeUuid', this.currentEmployeeUuid);
      const tasks = employee.tasksLookup(e.detail.date);
      const input: StaffingEmployeeDayInput = {
        date: e.detail.date,
        employee: employee,
        currentEmployeeUuid: this.currentEmployeeUuid ?? '',
        currentUserHasAccess: this.currentUserHasAccess,
        leavePeriodEditRestriction: this.leavePeriodEditRestriction,
        writeAccess: this.writeAccess,
        tasks: tasks,
        workScheduleException: employee.workScheduleExceptions.find((exception) => exception.date === e.detail.date),
      };
      const result: StaffingEmployeeDayResult = await StaffingEmployeeDayDialog.open(input);
      console.log(result.action);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-staffing-calendar': DStaffingCalendar;
  }
}
