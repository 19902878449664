import { Link as TipTapLink } from '@tiptap/extension-link';

export const Link = TipTapLink.extend({
  addAttributes() {
    return {
      href: {
        default: null,
        parseHTML: (element: HTMLElement) => {
          return element.hasAttribute('href') ? element.getAttribute('href') : null;
        },
      },
      'data-internal-link-id': {
        default: null,
        parseHTML: (element: HTMLElement) => {
          return element.hasAttribute('data-internal-link-id') ? element.getAttribute('data-internal-link-id') : null;
        },
      },
      'data-internal-link-doctype': {
        default: null,
        parseHTML: (element: HTMLElement) => {
          return element.hasAttribute('data-internal-link-doctype')
            ? element.getAttribute('data-internal-link-doctype')
            : null;
        },
      },
    };
  },
});
