import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../library/editors/elements/d-edit-text.js';
import '../../../../library/editors/elements/d-add-remove-buttons.js';
import './new-asset-dialog.js';
import { uuid } from 'src/utilities/text.js';
import type {
  InfosecDataType,
  InfosecCategory,
  InfosecDataItem,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { isEmailValid } from 'src/store';

export interface DataItemEmailAccountChange {
  uuid?: string;
  email: string;
  dataType: string;
  category: string;
}

/**
 *
 *
 */
@customElement('d-edit-data-items-email-account')
export class DEditDataItemsEmailAccount extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    .wrapper {
      flex: none;
      display: flex;
    }
    .wrapper + .wrapper {
      margin-top: 12px;
    }
    .wrapper > *:first-child {
      flex: 1;
    }
    .wrapper > d-edit-text:first-child {
      max-width: calc(100% - 58px);
    }
    .item {
      min-height: 30px;
      box-sizing: border-box;
      background: hsla(0, 0%, 100%, 0.5);
      padding: 5px 10px 0px;
      font-family: var(--mainSerif), serif;
    }
  `;
  @property({ type: Object })
  dataType!: InfosecDataType;
  @property({ type: Object })
  category!: InfosecCategory;
  @property({ type: Array })
  dataItems: InfosecDataItem[] = [];
  @property({ type: String })
  newItemName = '';

  _update(property) {
    this[property] = JSON.parse(JSON.stringify(this[property]));
  }

  _removeItem(index) {
    const uuid = this.dataItems[index].uuid;
    this.dataItems.splice(index, 1);
    this._update('dataItems');
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; dataType: string; category: string }>('data-items-removed', {
        bubbles: true,
        composed: true,
        detail: {
          uuid,
          dataType: this.dataType.type,
          category: this.category.category,
        },
      }),
    );
  }

  _addItem(value) {
    const uuid1 = uuid();
    this.dataItems.push({
      uuid: uuid1,
      name: value,
      dataType: this.dataType.type,
      category: this.category.category,
      personalData: false,
      dataProcessor: '',
      application: '',
      storageUnit: '',
      storageUnitType: '',
      storageLocation: '',
      accessingEmployees: [],
      accessingPartners: [],
      assets: [],
      backupFrequency: '',
      backupResponsible: '',
      noBackupReason: '',
      createdDateTime: '',
    });
    this.newItemName = 'x';
    setTimeout(() => {
      this.newItemName = '';
    }, 0);
    this._update('dataItems');
    this.dispatchEvent(
      new CustomEvent<DataItemEmailAccountChange>('data-item-changed', {
        composed: true,
        bubbles: true,
        detail: {
          uuid: uuid1,
          email: value,
          dataType: this.dataType.type,
          category: this.category.category,
        },
      }),
    );
  }

  render() {
    return html`
      <div>
        ${this.dataItems.map((dataItem, index) => {
          return html`
            <div class="wrapper">
              <div class="item">${dataItem.name}</div>
              <d-add-remove-buttons showRemove @remove=${() => this._removeItem(index)}></d-add-remove-buttons>
            </div>
          `;
        })}
        <div class="wrapper">
          <d-edit-text
            theme-page
            button
            placeholder="Skriv epostadresse"
            .value=${this.newItemName}
            ?button-disabled=${!isEmailValid(this.newItemName)}
            @value-changed=${(e) => (this.newItemName = e.detail.value)}
            @button-click=${(e) => this._addItem(e.detail.value)}
          ></d-edit-text>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-data-items-email-account': DEditDataItemsEmailAccount;
  }
}
