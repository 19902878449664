export const newItemLabels = [
  { type: 'functions', name: 'Nytt ansvarsområde' },
  { type: 'documents', name: 'Nytt måldokument' },
  { type: 'guidelines', name: 'Ny retningslinje' },
  { type: 'contacts', name: 'Ny kontaktperson' },
  { type: 'contracts', name: 'Ny avtale' },
  { type: 'reports', name: 'Nytt referat/rapport' },
  { type: 'riskAssessments', name: 'Ny risikovurdering' },
  { type: 'issues', name: 'Nytt avvik' },
  { type: 'events', name: 'Ny oppgave' },
  { type: 'tasks', name: 'Ny rutine' },
  {
    type: 'constitutionalDocuments',
    name: 'Nytt stiftelsesdokument',
  },
  { type: 'employees', name: 'Ny person' },
  { type: 'partners', name: 'Ny samarbeidspartner' },
  { type: 'assets', name: 'Ny utstyrsenhet' },
  { type: 'substances', name: 'Nytt stoff' },
  { type: 'meetings', name: 'Nytt møte' },
];
