import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../library/editors/elements/d-checkbox.js';
import '../../library/elements/d-label.js';

/**
 *
 * STATUS OK
 */
@customElement('d-edit-partner-access')
export class DEditPartnerAccess extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;
  @property({ type: Boolean })
  physicalAccessValue = false;
  @property({ type: Boolean })
  remoteAccessValue = false;

  fireChanged() {
    this.dispatchEvent(
      new CustomEvent<{ physical: boolean; remote: boolean }>('access-changed', {
        composed: true,
        bubbles: true,
        detail: { physical: this.physicalAccessValue, remote: this.remoteAccessValue },
      }),
    );
  }

  onPhysicalAccessCheckedChanged(e: CustomEvent<{ checked: boolean }>) {
    this.physicalAccessValue = e.detail.checked;
    this.fireChanged();
  }

  onRemoteAccessCheckedChanged(e: CustomEvent<{ checked: boolean }>) {
    this.remoteAccessValue = e.detail.checked;
    this.fireChanged();
  }

  render() {
    return html`
      <div class="sectionElementInner">
        <d-label field="partners_access"></d-label>
        <div>
          <d-checkbox
            ?checked=${this.physicalAccessValue}
            id="physicalAccess"
            option-field="partners_physicalAccess"
            @checked-changed=${this.onPhysicalAccessCheckedChanged}
          >
          </d-checkbox>

          <d-checkbox
            ?checked=${this.remoteAccessValue}
            id="remoteAccess"
            option-field="partners_remoteAccess"
            @checked-changed=${this.onRemoteAccessCheckedChanged}
          >
          </d-checkbox>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-partner-access': DEditPartnerAccess;
  }
}
