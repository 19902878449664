import { css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ItemTable, ItemTableColumn, TableItem } from '../../library/abstracts/item-table.js';

export interface Properties {
  name: string;
  label: string;
  field: string;
  value: string;
  href: string;
  classes: string;
}

export interface TasksAndEvents {
  classes: string;
  name: string;
  href: string;
  properties: Properties[];
}

export interface TableItemsAssets extends TableItem {
  uuid: string;
  name: string;
  number: string;
  category: string;
  location: string;
  requiresElectricity: boolean;
  tasksAndEvents: TasksAndEvents[];
}

/**
 *
 * STATUS OK
 */
@customElement('d-assets-table')
export class DAssetsTable extends ItemTable<TableItemsAssets> {
  static readonly styles = [
    ...ItemTable.styles,
    css`
      :host {
        display: block;
      }

      tr.tasks td,
      tr.events td {
        background-size: 22px auto;
        background-repeat: no-repeat;
        background-position: -2px 6px;
        padding-left: 0;
        cursor: default;
      }

      div.date {
        display: inline-block;
      }

      .event-done a,
      .asset-ok a,
      .asset-not-ok a {
        background-size: 22px auto;
        background-repeat: no-repeat;
        background-position: 0 -2px;
        padding-left: 26px;
      }

      .event-done a {
        background-image: url(/images/check-mini-gray.svg);
      }

      .asset-ok a {
        background-image: url(/images/check-mini-blue.svg);
      }

      .asset-not-ok a {
        background-image: url(/images/x-fat-orange.svg);
      }
    `,
  ];
  @property({ type: Boolean })
  hideRequiresElectricity = false;
  protected columnsSource: ItemTableColumn[] = [
    {
      name: 'name',
      field: '',
      label: 'Navn',
      sortOption: true,
    },
    {
      name: 'number',
      field: 'assets_number',
      label: 'ID',
      sortOption: true,
    },
    {
      name: 'category',
      field: '',
      label: 'Kategori',
      sortOption: true,
      groupOption: true,
    },
    {
      name: 'location',
      field: '',
      label: 'Plassering',
      groupOption: true,
    },
    {
      name: 'requiresElectricity',
      field: 'assets_requiresElectricity',
      label: 'EMU',
    },
    {
      name: 'tasksAndEvents',
      field: '',
      label: 'Oppgaver',
      noValue: 'Ingen oppgaver',
    },
  ];

  protected get columns(): ItemTableColumn[] {
    return this.hideRequiresElectricity
      ? this.columnsSource.filter((c) => c.name !== 'requiresElectricity')
      : this.columnsSource;
  }

  includeItem(item: TableItemsAssets): boolean {
    return this.filterFields(item.name, item.category, item.location, item.number);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-assets-table': DAssetsTable;
  }
}
