import type { State } from '../types.js';
import type { PageGroupItemViewModel, PageGroupViewModel } from '../api';
import { getFeatureStates } from 'src/store/selectors/features';
import { singleUserVersion } from 'src/store';

export interface PageViewModelExtended extends PageGroupItemViewModel {
  active: boolean;
}
export interface PageGroup {
  code: string;
  name: string;
  pages: PageViewModelExtended[];
}

export interface PageModel {
  /**
   *
   * @type {number}
   * @memberof PageViewModel
   */
  pageId: number;
  /**
   *
   * @type {string}
   * @memberof PageViewModel
   */
  name: string;

  /**
   *
   * @type {string}
   * @memberof PageViewModel
   */
  helpContent: string;
}

export const pageIds = {
  status: 695944,
  events: 318434,
  staffing: 5962,
  meetings: 9772,
  assets: 110,
  substances: 12368,
  dataSystems: 20,
  issues: 882901,
  riskAssessments: 712363,
  management: 357,
  hse: 63,
  business: 365,
  assetManagement: 355,
  informationSecurity: 65,
  quality: 79,
  company: 104,
  employees: 996526,
  partners: 312616,
  people: 476666,
  tutorials: 369026,
  references: 2250,
  trash: 223117,
};

export const defaultPageId = pageIds.status;

export function getPageStatus(state: State, pageId: number): boolean {
  const m = getFeatureStates(state);
  const onlyMeetings =
    m.meetings && !m.core && !m.issues && !m.risk && !m.staffing && !m.assets && !m.substances && !m.data;
  switch (pageId) {
    case pageIds.status:
      return true;
    case pageIds.events:
      return m.core;
    case pageIds.staffing:
      return (m.core || m.staffing) && !singleUserVersion(state);
    case pageIds.meetings:
      return m.core || m.meetings;
    case pageIds.assets:
      return m.assets;
    case pageIds.substances:
      return m.substances;
    case pageIds.dataSystems:
      return m.core || m.data;
    case pageIds.issues:
      return m.core || m.issues;
    case pageIds.riskAssessments:
      return m.core || m.risk;
    case pageIds.management:
      return m.core;
    case pageIds.hse:
      return m.core;
    case pageIds.business:
      return m.core;
    case pageIds.assetManagement:
      return m.core;
    case pageIds.informationSecurity:
      return m.core;
    case pageIds.quality:
      return m.core;
    case pageIds.company:
      return true;
    case pageIds.employees:
      return m.core || m.issues || m.staffing;
    case pageIds.partners:
      return m.core || (m.meetings && !onlyMeetings);
    case pageIds.people:
      return onlyMeetings;
    case pageIds.tutorials:
      return m.core;
    case pageIds.references:
      return m.core;
    case pageIds.trash:
      return true;
  }
  return false;
}

export function getPageGroups(state: State): PageGroup[] {
  return state.pageGroups.map<PageGroup>((group: PageGroupViewModel) => {
    const pages: PageViewModelExtended[] = group.pages.map((page) => {
      const result: PageViewModelExtended = {
        pageId: page.pageId,
        name: page.name,
        active: getPageStatus(state, page.pageId),
        helpContent: page.helpContent,
      };
      return result;
    });
    return {
      code: group.code,
      name: group.name,
      pages: pages,
    };
  });
}

export function flattenPageGroups(pageGroups: PageGroup[]): PageViewModelExtended[] {
  const pages: PageViewModelExtended[] = [];
  pageGroups.forEach((group) => {
    group.pages.forEach((page) => {
      pages.push(page);
    });
  });
  return pages;
}

export function getPages(state: State): PageViewModelExtended[] {
  return flattenPageGroups(getPageGroups(state));
}

export function getPage(pageId: number, pages: PageViewModelExtended[]): PageViewModelExtended | undefined {
  return pages.find((page) => {
    return page.pageId === pageId;
  });
}

export function _pageName(pageId: number, pages: PageViewModelExtended[]): string {
  const page = getPage(pageId, pages);
  if (page) {
    return page.name;
  }
  return '';
}

export function pageViewModel(pageId: number, pageGroups: PageGroup[]): PageModel {
  const pages: PageViewModelExtended[] = flattenPageGroups(pageGroups);
  const page = getPage(pageId, pages);
  if (page) {
    return {
      pageId,
      name: page.name,
      helpContent: page.helpContent,
    };
  }
  throw new Error('IllegalState (E902), pageId ' + pageId);
}

export function isPageId(pageId: number, pages: PageViewModelExtended[]): boolean {
  const page = getPage(pageId, pages);
  return !!page;
}
