export interface NameValue {
  name: string;
  value: number;
}
function groupTotal(group: NameValue[]): number {
  let total: number = 0;
  group.forEach((item: NameValue) => {
    total += item.value;
  });
  return total;
}

function moveable(groups: NameValue[][], index: number) {
  if (index < groups.length - 1) {
    const groupA: NameValue[] = groups[index];
    const groupB = groups[index + 1];
    const item = groupA[groupA.length - 1];
    if (groupTotal(groupA) > item.value + groupTotal(groupB)) {
      return true;
    }
  }
  return false;
}

function move(groups: NameValue[][], index: number): NameValue[][] {
  const groupA: NameValue[] = groups[index];
  const groupB = groups[index + 1];
  const item = groupA[groupA.length - 1];
  groupB.unshift(item);
  groupA.pop();
  return groups;
}

function even(groups: NameValue[][]): NameValue[][] {
  let moved: boolean = false;
  groups.forEach((group: NameValue[], index: number) => {
    if (moveable(groups, index)) {
      groups = move(groups, index);
      moved = true;
    }
  });
  if (moved) {
    groups = even(groups);
  }
  return groups;
}

/**
 * Takes av list of named numeric values and a number of groups
 * Returns a list of groups containing the named values distributed as evenly as possible and in the same order
 */
export function getEven(items: NameValue[], n: number): NameValue[][] {
  const groups: NameValue[][] = [];
  groups.push(items);
  if (n > 1) {
    for (let i = 0; i < n - 1; i++) {
      groups.push([]);
    }
    return even(groups);
  }
  return groups;
}
