import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';
import '../../library/fields/d-spinner-robot.js';
import { customElement, property } from 'lit/decorators.js';
import type { GestureType } from '../../library/fields/d-spinner-robot.js';
import type { UploadedFile } from 'src/pages/substances-page/d-substances-page-content.js';
import type { TableItemsSubstance } from 'src/pages/substances-page/d-substances-table.js';

/**
 *
 */
@customElement('d-substances-upload-feedback')
export class DSubstancesUploadFeedback extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .processAnimation {
      position: relative;
      margin: 100px auto;
      width: 280px;
      text-align: center;
    }

    .processMessage {
      margin-top: 16px;
      font-size: 15px;
      line-height: 140%;
      font-weight: 200;
    }

    .pendingCount {
      font-weight: bold;
      color: hsl(0, 0%, 30%);
    }

    d-spinner-robot {
      position: relative;
    }

    .action.button {
      margin-top: 10px;
      display: inline-block;
      background-color: var(--themeColor);
      padding: 4px 8px;
      border-radius: 4px;
      color: white;
    }

    .action {
      padding: 6px 0;
      font-family: var(--small);
      font-weight: 600;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: var(--themeColor);
      cursor: pointer;
    }
  `;

  timeout: NodeJS.Timeout | undefined;
  /**
   * No animation. Turn off all animations. Used by storybook to enable consistent snapshots.
   */
  @property({ type: Boolean, reflect: true, attribute: 'no-animation' })
  noAnimation = false;
  /**
   * Represents upload status.
   * An ENUM - possible values - `uploading`, `uploadFinished`, `reading`, `timeout`, `finished`, `waiting`
   */
  @property({ type: String })
  state = 'uploading';
  @property({ type: Object })
  organization: any = {};

  @property({ type: Array })
  uploadedFiles: UploadedFile[] = [];
  @property({ type: Array })
  substances: TableItemsSubstance[] = [];

  private get allFilesAreVerified() {
    if (this.allFilesAreListed) {
      return this.newSubstances.length === this.verifiedCount;
    }
    return false;
  }

  private get newSubstancesCount() {
    return this.newSubstances.length;
  }

  private get uploadedFilesCount() {
    return this.uploadedFiles.length;
  }

  private get duplicatesCount() {
    return this.uploadedFiles.filter((f) => f.duplicate).length;
  }

  private get newSubstances(): TableItemsSubstance[] {
    return this.uploadedFiles
      .filter((f) => !f.duplicate)
      .map((f) => this.substances.find((s) => s.uuid === f.uuid))
      .filter(this.isSubstance);
  }

  private get pendingCount() {
    return this.substances.filter((s) => s.state === 'pending').length;
  }

  private get verifiedCount() {
    return this.newSubstances.filter((item) => item.state === '').length;
  }

  private get allFilesAreListed() {
    return (
      this.uploadedFiles.filter(function (item) {
        return !item.duplicate;
      }).length === this.newSubstances.length
    );
  }

  private get gesture(): GestureType[] {
    switch (this.state) {
      case 'uploading':
        return ['upload'];
      case 'reading':
        return ['readHorizontal'];
      case 'timeout':
        return ['blink'];
      case 'finished':
        return this.allFilesAreDuplicates ? ['surprised', 'blink'] : ['happy', 'blink'];
      default:
        return ['happy', 'blink'];
    }
  }

  private get allFilesAreDuplicates() {
    return this.uploadedFiles.length === this.duplicatesCount;
  }

  private get uploadReport() {
    const files = this.uploadedFiles;
    if (files === undefined || files.length === 0) {
      return [];
    }
    let result: string[] = [];
    if (this.duplicatesCount) {
      if (files.length === this.duplicatesCount) {
        if (files.length === 1) {
          result.push('Sikkerhetsdatabladet var et duplikat, og ble ikke lagt til.');
        } else if (files.length === 2) {
          result.push('Begge sikkerhetsdatabladene var duplikater, og ingen ble lagt til.');
        } else {
          result.push('Alle sikkerhetsdatabladene var duplikater, og ingen ble lagt til.');
        }
        return result;
      }
      result.push(this._docsNumberTerm(files.length - this.duplicatesCount, 'stoff') + ' ble lagt til.');
      result.push(this._docsNumberTerm(this.duplicatesCount, 'duplikat') + ' ble ikke lagt til.');
    } else {
      result = [this._docsNumberTerm(files.length, 'stoff') + ' ble lagt til.'];
    }
    return result;
  }

  _stateChange(state) {
    console.log(
      'substances upload state ' +
        state +
        ' ' +
        this.pendingCount +
        ' ' +
        this.verifiedCount +
        ' ' +
        this.duplicatesCount,
    );
    if (state === 'uploadFinished' && this.allFilesAreDuplicates) {
      this.state = 'finished';
      return;
    }

    if (state === 'uploadFinished' && !this.allFilesAreDuplicates) {
      this.state = 'reading';
    }
  }

  _docsNumberTerm(n, term) {
    if (n === 0) {
      return 'Ingen ' + term + 'er';
    }
    if (n === 1) {
      return '1 ' + term;
    }
    return n + ' ' + term + 'er';
  }

  _close() {
    this.state = 'ready';
    this.uploadedFiles = [];
    setTimeout(() => {
      this.dispatchEvent(new CustomEvent('closed'));
    }, 2000);
  }

  render() {
    return html`
      <div class="processAnimation uploading">
        <d-spinner-robot ?no-animation=${this.noAnimation} .gesture=${this.gesture} size="60"></d-spinner-robot>
        <div class="processMessage">
          ${this.state !== 'uploading'
            ? nothing
            : html` <div>
                <p>Laster opp sikkerhetsdatablad… <br />Ikke lukk vinduet mens opplastingen pågår.</p>
              </div>`}
          ${this.state !== 'waiting'
            ? nothing
            : html` <div>
                <p>Venter</p>
              </div>`}
          ${this.state !== 'reading'
            ? nothing
            : html` <div>
                <p>Opplasting ferdig, leser…</p>
                ${this.renderPendingCount()}
              </div>`}
          ${this.state !== 'finished'
            ? nothing
            : html` <div>
                ${this.uploadReport.map((item) => html`<p>${item}</p>`)}

                <div class="action button" @click=${this._close}>OK</div>
              </div>`}
        </div>
      </div>
    `;
  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    /**
     * calls when `state` is really changed
     * trigger _stateChange observer
     */
    if (_changedProperties.has('state')) {
      this._stateChange(this.state);
    }

    if (this.state === 'reading') {
      console.log('checking');
      this.checkAllFilesAreVerified();
      this.checkAllFilesAreListed();
    }
  }

  private checkAllFilesAreListed() {
    if (this.allFilesAreListed) {
      this.timeout = setTimeout(() => {
        if (!this.duplicatesCount) {
          this._close();
        } else {
          this.state = 'finished';
        }
      }, 10000);
    }
  }

  private checkAllFilesAreVerified() {
    if (this.allFilesAreVerified) {
      if (!this.duplicatesCount) {
        this._close();
      } else {
        this.state = 'finished';
      }
    }
  }

  private isSubstance(item: TableItemsSubstance | undefined): item is TableItemsSubstance {
    return !!item;
  }

  private renderPendingCount() {
    return !this.pendingCount
      ? nothing
      : html`<p><span class="pendingCount">${this.pendingCount}</span> igjen å lese</p>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-substances-upload-feedback': DSubstancesUploadFeedback;
  }
}
