import { css } from 'lit';

export const headerViewStyles = css`
  .meta-content-wrapper {
    position: relative;
    box-sizing: border-box;
    background-color: var(--metaContentBackgroundColor);
    border-radius: 0;
    margin: 0;
    max-width: var(--metaContentMaxWidth);
    transition: all 0.5s;
  }

  :host(.width600) .meta-content-wrapper {
    margin: 0 20px 20px 20px;
    border-radius: 12px;
  }

  h2 {
    margin-top: 6px;
    margin-bottom: 12px;
    font-size: 20px;
  }
`;
