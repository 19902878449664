import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { LocalDate } from 'src/utilities/local-date.js';

export function descriptionForExternal(description, eventDate, immediateMeasures, furtherMeasures) {
  let html = '<p>' + description + '</p>';
  if (eventDate) {
    html += '<p><strong>Hendelsesdato:</strong> ' + LocalDate.fromString(eventDate).toStringForDisplay() + '</p>';
  }
  if (immediateMeasures) {
    html += '<p><strong>Strakstiltak som er iverksatt:</strong> ' + immediateMeasures + '</p>';
  }
  if (furtherMeasures) {
    html += '<p><strong>Forslag til flere tiltak:</strong> ' + furtherMeasures + '</p>';
  }
  return html;
}

export class DIssueBehavior extends LitElement {
  @property({
    type: Boolean,
  })
  pllParticipant = false;
  @property({
    type: Array,
  })
  externalRecipients = [];
  ready() {
    this._fetchRecipients();

    if (location.href.startsWith('https://app.trinnvis.no/account/2668')) {
      this.pllParticipant = true;
    }
  }

  _fetchRecipients() {
    fetch('https://api.trinnvis.io/external-issues/recipients/')
      .then((response) => response.text())
      .then((recipients) => {
        const list = JSON.parse(recipients);
        const options = list.map((item) => ({
          uuid: item.id,
          value: item.description,
        }));
        this.externalRecipients = options;
      });
  }

  formatAsSentTime(date) {
    if (date === undefined) {
      return '';
    }
    const localDate = LocalDate.fromString(date.substring(0, 10));
    const dateAsString = localDate.toStringForDisplayWithDayOfWeekAndYear();
    const timePartHours = date.substring(11, 13);
    const timePartMinutes = date.substring(14, 16);
    const timeAsString = 'kl. ' + timePartHours + '.' + timePartMinutes;

    return dateAsString + ' ' + timeAsString;
  }

  capitalize(text) {
    return text.toLowerCase().replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  }
}
