import { css, html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import '../../library/editors/elements/d-select-date.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/editors/elements/d-select-tag.js';
import '../../library/elements/d-label.js';
import '../../library/elements/d-wrap.js';
import { BaseEditRecurrence } from './base-edit-recurrence';
import { endYear } from 'src/store';

/**
 *
 *
 *
 * STATUS OK
 */
@customElement('d-edit-recurrence')
export class DEditRecurrence extends BaseEditRecurrence {
  static readonly styles = css``;

  render() {
    return html`
      <d-label field="events_repetition"></d-label>
      <d-wrap>
        <d-select-dropdown
          .value=${this.freqInterval}
          .options=${this.frequencyOptions}
          @value-changed=${this.onFreqIntervalChanged}
        ></d-select-dropdown>

        ${this.freq === 'WEEKLY'
          ? html` <div>på</div>
              <d-select-tag
                id="weekdays"
                .options=${this.weekdayOptions}
                .value=${this.byDay}
                @value-changed=${this.onWeekdaysChanged}
              >
              </d-select-tag>`
          : nothing}
        ${this.freq === 'MONTHLY' || this.freq === 'YEARLY'
          ? html` <d-select-dropdown
              .options=${this.recurrenceTypeOptions}
              .value=${this.recurrenceType}
              @value-changed=${this.onRecurrenceTypeChanged}
            >
            </d-select-dropdown>`
          : nothing}
        ${this.recurrence === '' ? nothing : html` <div>til</div>`}
        ${this.freq === 'NONE'
          ? nothing
          : html` <d-select-date
              .disableBefore=${this.computeDisableBefore(this.startDate)}
              .disableAfter=${endYear + '-12-31'}
              .value=${this.endDate}
              @value-changed=${this.onEndDateChanged}
            >
            </d-select-date>`}
      </d-wrap>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-recurrence': DEditRecurrence;
  }
}
