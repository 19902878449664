import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

/**
 *
 */
@customElement('d-meeting-update-section')
export class DMeetingUpdateSection extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      align-items: center;
    }
    :host:before {
      display: inline-block;
      content: '';
      margin-right: 8px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--themeColorDarkerOne);
    }
    span {
      color: var(--themeColor);
      cursor: pointer;
    }
    span:hover {
      color: black;
    }
  `;
  openTemplateDialog() {
    this.dispatchEvent(
      new CustomEvent('open-template-dialog', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  render() {
    return html`<div>
      Denne møtetypen har et nytt <span @click=${() => this.openTemplateDialog()}>agendaforslag</span>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-meeting-update-section': DMeetingUpdateSection;
  }
}
