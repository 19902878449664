import { css, html, nothing, PropertyValues } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { EmployeeForStaffingCalendar, staffingCalendarYearOptions } from 'src/store/selectors';
import { LocalDate } from 'src/utilities/local-date.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/fields/d-spinner-robot.js';
import '../d-list-leave-periods.js';
import './d-share-staffing.js';
import './d-sickness-absence-statistics.js';
import './d-staffing-calendar-access.js';
import './d-staffing-calendar-data.js';
import type { EndOfDayTime, StaffingCalendarDataGroup, StartOfDayTime, ZoomUnit } from './d-staffing-calendar-data.js';
import './d-staffing-calendar-groups.js';
import './d-staffing-calendar-header.js';
import './d-staffing-statistics.js';
import './d-timekeeping-review.js';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';
import { isTouchDevice } from 'src/utilities/touch.js';

export interface StaffGroupGraphData {
  groups: { height: number }[];
  unitStart: boolean;
}

export interface EmployeeGroupItem {
  uuid: string;
  displayName: string;
}

export interface EmployeeGroup {
  items: EmployeeGroupItem[];
}

export interface StaffingCalendar {
  data: StaffingCalendarDataGroup[];
}

export const zoomResolution = {
  day: 4,
  hour: 12,
};

/**
 *
 * Migration: calendarium Months, weeks and days are not selectors. Depending on performance they may need to be reimplemented as selectors.
 */
@customElement('d-staffing-calendar-table')
export class DStaffingCalendarTable extends ResponsiveElement {
  static readonly styles = css`
    :host {
      display: block;
      position: relative;
      width: fit-content;
    }

    /* table */

    table {
      white-space: nowrap;
      margin: 0;
      border: none;
      border-spacing: 0;
      table-layout: fixed;
      border-collapse: collapse;
    }
    td,
    th {
      background: var(--backgroundGray);
      padding: 0;
      text-align: left;
      font-weight: normal;
      transition: padding 0.3s;
    }
    thead th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      padding-top: 12px;
      z-index: 1;
      vertical-align: bottom;
    }
    tbody th {
      position: relative;
    }
    tbody th,
    tbody td {
      vertical-align: top;
    }
    thead th:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 2;
    }
    tbody th {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 1;
    }

    /* Navigation */

    .navigation-wrapper {
      display: flex;
      flex-direction: row;
      border-right: 1px solid hsl(1, 0%, 70%);
      border-bottom: 1px solid hsla(0, 0%, 70%);
    }
    .navigation {
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 120px;
      padding-right: 6px;
    }
    .navigation > div {
      display: flex;
      padding-bottom: 6px;
      justify-content: space-between;
    }
    d-select-dropdown {
      --select-dropdown-text-padding: 7px 30px 0px 7px;
    }
    .selectYear {
      width: 54px;
      margin-right: 6px;
      text-align: center;
    }
    .mid-label {
      padding-top: 6px;
      font-size: 15px;
      font-weight: 200;
    }
    .today-button {
      cursor: pointer;
      padding: 5px 8px 6px;
      height: 32px;
      box-sizing: border-box;
      font-size: 15px;
      font-weight: 200;
      color: black;
      background-color: white;
      border: 1px solid var(--borderColor);
      border-radius: 4px;
    }
    body:not(.touch) .today-button:hover {
      color: white;
      background-color: var(--themeColor);
      border: 1px solid var(--themeColor);
    }

    /* calendarium */

    .scroll-buttons-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      top: 18px;
      left: 0;
      height: 0;
      opacity: 0.6;
      transition: 0.3s;
    }
    .scroll-buttons-wrapper:hover {
      opacity: 1;
    }
    #scroll-buttons-holder {
      width: 57px;
      height: 30px;
    }
    #scroll-buttons {
      display: flex;
      justify-content: space-between;
      width: 57px;
      height: 30px;
      border-radius: 6px;
      overflow: hidden;
      cursor: pointer;
      transition: 0.3s;
    }
    .scroll-buttons-wrapper:hover #scroll-buttons {
      box-shadow: 0 3px 8px hsla(0, 0%, 0%, 0.3);
      transition: 0.3s;
    }
    .scroll-button {
      width: 28px;
      height: 30px;
      background: white;
      cursor: pointer;
    }
    .scroll-button div {
      height: 100%;
      opacity: 0.3;
    }
    .scroll-button:hover div {
      opacity: 0.6;
    }
    #scroll-button-left div {
      background: url(/images/calendar-prev.svg) 50% 50% no-repeat;
      background-size: 20px 20px;
    }
    #scroll-button-right div {
      background: url(/images/calendar-next.svg) 50% 50% no-repeat;
      background-size: 20px 20px;
    }
    d-staffing-calendar-header {
      border-bottom: 1px solid hsla(0, 0%, 70%);
    }
    .yearSpacer {
      text-align: center;
      padding-top: 50px;
      color: var(--themeColorDarkerOne);
      display: none;
    }
    .prevNextWrapper {
      text-align: center;
    }
    .prevNext {
      flex: none;
      height: 30px;
      display: inline-block;
      padding: 6px 4px 0 10px;
      box-sizing: border-box;
      position: relative;
      background-color: hsl(1, 0%, 95%);
      font-size: 15px;
      font-weight: normal;
      color: hsl(196, 83%, 49%);
      margin-right: 10px;
      cursor: pointer;
    }
    body:not(.touch) .prevNext:hover {
      background-color: hsl(196, 83%, 49%);
      color: white;
    }
    .prevNext.prev {
      padding: 6px 10px 0 4px;
      margin-left: 10px;
      margin-right: 0;
    }
    .prevNext:before {
      content: '';
      position: absolute;
      right: -15px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 15px solid hsl(1, 0%, 95%);
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
    body:not(.touch) .prevNext:hover:before {
      border-left: 15px solid hsl(196, 83%, 49%);
    }
    .prevNext.prev:before {
      left: -15px;
      border-left: none;
      border-right: 15px solid hsl(1, 0%, 95%);
    }
    body:not(.touch) .prevNext.prev:hover:before {
      border-left: none;
      border-right: 15px solid hsl(196, 83%, 49%);
    }

    /* employees */

    #employees {
      position: relative;
      border-right: 1px solid hsl(1, 0%, 60%);
      cursor: pointer;
      background-color: var(--backgroundGray);
    }
    .group {
      display: flex;
      margin-top: -1px;
      border-top: 1px solid hsl(0, 0%, 70%);
    }
    .group:last-of-type {
      border-bottom: 1px solid hsl(0, 0%, 70%);
    }
    .groupContent {
      flex: 1;
    }
    .group .color-bar {
      flex: none;
      width: 14px;
      margin-right: 6px;
      background-color: hsl(196, 83%, 49%);
      transition: all 0.5s;
    }
    .group:nth-child(2) .color-bar {
      opacity: 0.6;
    }
    .group:nth-child(3) .color-bar {
      opacity: 0.2;
    }
    .person {
      padding-top: 6px;
      padding-right: 20px;
      height: 31px;
      border-top: 1px solid hsl(0, 0%, 80%);
      margin-top: -1px;
      box-sizing: border-box;
      white-space: nowrap;
    }
    .group:last-of-type .person {
      border-bottom: none;
      margin-bottom: -1px;
    }
    .person:first-child {
      border-top: 1px solid hsl(1, 0%, 70%);
    }
    .staffGroupGraphCount {
      display: flex;
      flex-direction: column;
      transition: all 0.3s;
      border-top: 1px solid hsl(0, 0%, 70%);
      margin-top: -1px;
    }
    .staffGroupGraphCount div {
      flex: 1;
      text-align: right;
      padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 13px;
      color: hsl(1, 0%, 40%);
      overflow: hidden;
      opacity: 0;
      transition: all 0.3s;
    }
    .staffGroupGraphCount.expanded div {
      opacity: 1;
      transition-delay: 0.3s;
    }

    /* calendar data */

    d-staffing-calendar-data {
      border-bottom: 1px solid hsla(0, 0%, 0%, 0.3);
    }
  `;

  @property({ type: Number })
  appWidth = 1000;
  @property({ type: Number })
  pageWidth = 1000;
  @property({ type: Number, attribute: 'padding-left' })
  paddingLeft = 30;
  @property({ type: Number, attribute: 'padding-top' })
  paddingTop = 30;
  @property({ type: Number })
  contentStickyTop = 0;
  @query('#yearSpacerBefore')
  yearSpacerBefore!: HTMLElement;
  @query('#yearSpacerAfter')
  yearSpacerAfter!: HTMLElement;
  @query('#calendarium')
  calendarium!: HTMLElement;
  @query('#prev')
  prev!: HTMLElement;
  @query('#next')
  next!: HTMLElement;
  @query('#scroll-buttons-holder')
  scrollButtonsHolder!: HTMLElement;
  @query('#scroll-buttons')
  scrollButtons!: HTMLElement;
  @property({ type: Number })
  prevNextWrapperWidth = 100;
  @property({ type: Array })
  employeesForStaffingCalendar: EmployeeForStaffingCalendar[] = [];
  @property({ type: Number })
  leftColumnWidth = 127;
  @property({ type: Number })
  scrollLeft = 0;
  @property({ type: Object })
  organization: any = {};
  @property({ type: Array })
  employees: EmployeeForStaffingCalendar[] = [];
  @property({ type: Array })
  employeeGroups: EmployeeGroup[] = [];
  @property({ type: String })
  year = '';
  @property({ type: Array })
  calendarDataLeavePeriods: any[] = [];
  @property({ type: Object })
  calendarDataSchedules!: { groups: any[] };
  @property({ type: Array })
  staffGroupGraphData: StaffGroupGraphData[] = [];
  @property({ type: Number })
  yearsFromNow = 10;
  @property({ type: String })
  selectedEmployeeUuid = '';
  @property({ type: String })
  selectedDay = '';
  @property({ type: String })
  selectedLeavePeriodStart = '';
  @property({ type: Array })
  workSchedules = [];
  @property({ type: Boolean })
  staffGroupGraphExpanded = false;
  @property({ type: Boolean })
  showTimekeeping = false;
  @property({ type: Boolean })
  showStaffingStats = false;
  @property({ type: Boolean })
  showSicknessAbsenceStats = false;
  @property({ type: Object })
  employeeCalendarData!: StaffingCalendar;
  @property({ type: Array })
  groupGraphData: string[][] = [];
  @property({ type: String })
  dayStart: StartOfDayTime = '08';
  @property({ type: String })
  dayEnd: EndOfDayTime = '16';
  @property({ type: String })
  zoom: ZoomUnit = 'day';
  displayYearOptions = staffingCalendarYearOptions.map((o) => {
    return { value: o.id, text: o.value };
  });
  displayDayStartOptions = [
    { value: '00', text: '00' },
    { value: '02', text: '02' },
    { value: '04', text: '04' },
    { value: '06', text: '06' },
    { value: '08', text: '08' },
  ];
  displayDayEndOptions = [
    { value: '16', text: '16' },
    { value: '18', text: '18' },
    { value: '20', text: '20' },
    { value: '22', text: '22' },
    { value: '24', text: '24' },
  ];
  displayZoomOptions = [
    { value: 'day', text: 'dag for dag' },
    { value: 'hour', text: 'time for time' },
  ];
  @query('.grid-scroller')
  gridScroller!: HTMLDivElement;

  @property({ type: Number })
  gridWidth = 11679;
  @property({ type: Number })
  cellWidth = 30;

  @query('#top-left-cell')
  private topLeftCell!: HTMLTableCellElement;

  @query('#top-right-cell')
  private topRightCell!: HTMLTableCellElement;

  private get startOfDayHours() {
    return Number(this.dayStart);
  }

  private get endOfDayHours() {
    return Number(this.dayEnd);
  }

  get _getGridStyle() {
    return 'width:' + this.gridWidth + 'px';
  }

  private get leftPaddingStyle() {
    return 'padding-left: ' + this.paddingLeft + 'px;';
  }

  private get topStickyStyle() {
    return 'top: ' + (this.contentStickyTop - 1) + 'px;';
  }

  private get selectedEmployee(): EmployeeForStaffingCalendar | undefined {
    const employees = this.employeesForStaffingCalendar;
    return employees.find((e) => e.uuid === this.selectedEmployeeUuid);
  }

  private get noGroups() {
    let employeesCount = 0;
    this.employeeGroups.forEach((g) => {
      employeesCount += g.items.length;
    });
    return employeesCount < 3;
  }

  /* scrollmargin is the initial left position of the page, see d-application-layout */
  private get scrollMargin() {
    if (this.appWidth < 1024) {
      return 20;
    }
    if (this.appWidth < 845) {
      return 0;
    }
    return 240;
  }

  private getPixelsPerDay(startOfDayHours, endOfDayHours, zoom) {
    const hoursPerDay = endOfDayHours - startOfDayHours;
    const pixelsPerHour = zoomResolution[zoom];
    return pixelsPerHour * hoursPerDay;
  }

  private getDayNumber(scrollLeft, scrollMargin, startOfDayHours, endOfDayHours, zoom): number | undefined {
    const pixelsPerDay = this.getPixelsPerDay(startOfDayHours, endOfDayHours, zoom);
    if (scrollLeft > scrollMargin + this.prevNextWrapperWidth) {
      return (scrollLeft - (scrollMargin + this.prevNextWrapperWidth)) / pixelsPerDay;
    }
    return undefined;
  }

  _setLeftColumnWidth() {
    this.leftColumnWidth = this.topLeftCell.offsetWidth;
  }

  private get calendariumVisibleWidthStyle() {
    return 'width: ' + this.calendariumVisibleWidth + 'px';
  }

  _staffGroupGraphCountClasses(staffGroupGraphExpanded) {
    if (staffGroupGraphExpanded) {
      return 'staffGroupGraphCount expanded';
    }
    return 'staffGroupGraphCount';
  }

  _staffGroupGraphHeightStyle(employeesCount, staffGroupGraphExpanded) {
    if (staffGroupGraphExpanded) {
      return 'height: ' + employeesCount * 30 + 'px';
    }
    return 'height: ' + employeesCount * 8 + 'px';
  }

  onYearChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{ value: string }>('staffing-year-changed', {
        composed: true,
        bubbles: true,
        detail: { value: e.detail.value },
      }),
    );
  }

  onUserDisplaySelectionChanged() {
    this.dispatchEvent(
      new CustomEvent<{ start: string; end: string; zoom: ZoomUnit }>(
        'staffing-calendar-user-display-selection-changed',
        {
          composed: true,
          bubbles: true,
          detail: {
            start: this.dayStart,
            end: this.dayEnd,
            zoom: this.zoom,
          },
        },
      ),
    );
  }

  onDayStartChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.dayStart = e.detail.value as StartOfDayTime;
    this.onUserDisplaySelectionChanged();
  }

  onDayEndChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.dayEnd = e.detail.value as EndOfDayTime;
    this.onUserDisplaySelectionChanged();
  }

  onZoomChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.zoom = e.detail.value as ZoomUnit;
    this.onUserDisplaySelectionChanged();
  }

  _employeesCount(employeeGroups) {
    let count = 0;
    employeeGroups.forEach(function (group) {
      count += group.items.length;
    });
    return count;
  }

  _employeesNumbers(groups) {
    let index = 0;
    const list: number[] = [];
    groups.forEach(function (group) {
      group.items.forEach(() => {
        index += 1;
        list.push(index);
      });
    });
    return list;
  }

  _showEditEmployeeGroups() {
    if (!this.noGroups) {
      this.dispatchEvent(
        new CustomEvent('employee-groups', {
          composed: true,
          bubbles: true,
        }),
      );
    }
  }

  scroll(pos) {
    this.dispatchEvent(
      new CustomEvent<{ value: number }>('scroll-horizontal', {
        composed: true,
        bubbles: true,
        detail: { value: pos },
      }),
    );
  }

  jump(pos) {
    this.dispatchEvent(
      new CustomEvent<{ value: number }>('jump-horizontal', {
        composed: true,
        bubbles: true,
        detail: { value: pos },
      }),
    );
  }

  private scrolling = false;

  scrollStep(step) {
    const leftPos = Math.round(this.scrollLeft / this.cellWidth) * this.cellWidth + 10;
    this.jump(leftPos + step);
  }

  scrollManual(e, direction) {
    const left = this.scrollButtons.getBoundingClientRect().left;
    const top = this.scrollButtons.getBoundingClientRect().top;
    this.scrollButtons.style.left = left + 'px';
    this.scrollButtons.style.top = top + 'px';
    this.scrollButtons.style.position = 'fixed';
    this.scrolling = true;
    let n = 0;
    const scrollInterval = setInterval(() => {
      n += 1;
      let step = this.cellWidth;
      if (n > 10) {
        step = this.cellWidth * 2;
      }
      if (n > 20) {
        step = this.cellWidth * 3;
      }
      if (this.scrolling) {
        this.scrollStep(step * direction);
      } else {
        clearInterval(scrollInterval);
      }
    }, 20);
  }

  scrollManualStop() {
    this.scrolling = false;
    const left = this.scrollButtonsHolder.getBoundingClientRect().left;
    const top = this.scrollButtonsHolder.getBoundingClientRect().top;
    this.scrollButtons.style.left = left + 'px';
    this.scrollButtons.style.top = top + 'px';
    setTimeout(() => {
      this.scrollButtons.removeAttribute('style');
    }, 500);
  }

  _goToDay(day: string) {
    const selectedYear = day.substring(0, 4);
    let delay = 0;
    if (this.year !== selectedYear) {
      delay = 1000;
      this.dispatchEvent(
        new CustomEvent<{ value: string }>('staffing-year-changed', {
          composed: true,
          bubbles: true,
          detail: { value: selectedYear },
        }),
      );
    }
    const days = LocalDate.fromString(selectedYear + '-01-01').until(LocalDate.fromString(day));
    setTimeout(() => {
      const pos = days * this.cellWidth + 100 + this.scrollMargin;
      this.scroll(pos);
    }, delay);
  }

  _today() {
    const day = LocalDate.now().toString();
    this._goToDay(day);
  }

  _prevYear(year) {
    const yearNumber = Number(year);
    if (yearNumber > 2018) {
      return (yearNumber - 1).toString();
    }
    return '';
  }

  _nextYear(year, yearsFromNow) {
    const yearNumber = Number(year);
    const maxYear = Number(LocalDate.now().toString().substring(0, 4)) + yearsFromNow;
    if (yearNumber < maxYear - 1) {
      return (yearNumber + 1).toString();
    }
    return '';
  }

  _setPrevYear() {
    const prevYear = (Number(this.year) - 1).toString();
    this.dispatchEvent(
      new CustomEvent<{ value: string }>('staffing-year-changed', {
        composed: true,
        bubbles: true,
        detail: { value: prevYear },
      }),
    );
    setTimeout(() => {
      this.scroll(12000);
    }, 500);
  }

  _setNextYear() {
    if (Number(this.year) > 2029) {
      return;
    }
    const nextYear = (Number(this.year) + 1).toString();
    this.dispatchEvent(
      new CustomEvent<{ value: string }>('staffing-year-changed', {
        composed: true,
        bubbles: true,
        detail: { value: nextYear },
      }),
    );
    setTimeout(() => {
      this.scroll(0);
    }, 500);
  }

  _getLeavePeriodStart(employeeUuid, day) {
    const employeeLeavePeriods = this.employees.filter(function (item) {
      return item.uuid === employeeUuid;
    })[0].leavePeriods;
    const inRange = function (day2, start, end) {
      return (
        LocalDate.fromString(day2).isSameOrAfter(LocalDate.fromString(start)) &&
        LocalDate.fromString(day2).isSameOrBefore(LocalDate.fromString(end))
      );
    };
    for (const item of employeeLeavePeriods) {
      if (inRange(day, item.start, item.end)) {
        return item.start;
      }
    }
    return '';
  }

  scrollToPeriod(e) {
    this._goToDay(e.detail);
  }

  _onStaffingCalendarHeaderWidthChanged(e) {
    this.gridWidth = e.detail.width;
    this.cellWidth = e.detail.widthPerDay;
  }

  onResize() {
    this.leftColumnWidth = this.topLeftCell.offsetWidth;
    this.dispatchEvent(
      new CustomEvent('width-changed', {
        bubbles: true,
        composed: true,
        detail: this.topLeftCell.offsetWidth + this.topRightCell.offsetWidth,
      }),
    );
  }

  resetScrollPosition(_changedProperties) {
    let startOfDayHours = this.startOfDayHours;
    if (_changedProperties.has('dayStart')) {
      startOfDayHours = Number(_changedProperties.get('dayStart'));
    }
    let endOfDayHours = this.endOfDayHours;
    if (_changedProperties.has('dayEnd')) {
      endOfDayHours = Number(_changedProperties.get('dayEnd'));
    }
    let zoom = this.zoom;
    if (_changedProperties.has('zoom')) {
      zoom = _changedProperties.get('zoom');
    }
    let scrollLeft = this.scrollLeft;
    const bodyStyle = window.document.body.style;
    const positionIsBodyStyle = bodyStyle.position === 'fixed' && this.scrollLeft === 0;
    if (positionIsBodyStyle) {
      scrollLeft = Number(bodyStyle.left.replace('px', '')) * -1;
    }
    const dayNumber = this.getDayNumber(scrollLeft, this.scrollMargin, startOfDayHours, endOfDayHours, zoom);
    setTimeout(() => {
      if (dayNumber) {
        const pixelsPerDay = this.getPixelsPerDay(this.startOfDayHours, this.endOfDayHours, this.zoom);
        const newPos = Math.floor(dayNumber * pixelsPerDay + this.scrollMargin + this.prevNextWrapperWidth);
        if (positionIsBodyStyle) {
          bodyStyle.left = newPos * -1 + 'px';
        } else {
          this.jump(newPos);
        }
      }
    }, 0);
  }

  render() {
    return html`
      <table>
        <thead>
          <tr>
            <th id="top-left-cell" style="${this.leftPaddingStyle} ${this.topStickyStyle}">
              ${this.renderNavigation()}
            </th>
            <th id="top-right-cell" style="${this.topStickyStyle}">${this.renderCalendarium()}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style="${this.leftPaddingStyle}">${this.renderEmployees()}</th>
            <td>${this.renderData()}</td>
          </tr>
        </tbody>
      </table>
    `;
  }

  protected willUpdate(_changedProperties: PropertyValues) {
    super.willUpdate(_changedProperties);
    if (_changedProperties.has('dayStart') || _changedProperties.has('dayEnd') || _changedProperties.has('zoom')) {
      this.resetScrollPosition(_changedProperties);
    }
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    setTimeout(() => {
      this._today();
    }, 0);
    this._setLeftColumnWidth();
  }

  private get calendariumVisibleWidth() {
    let margins = 60;
    if (this.pageWidth < 600) {
      margins = 40;
    }
    return this.pageWidth - this.leftColumnWidth - margins;
  }

  private get scrollButtonsWrapperStyle() {
    let result = 'left:' + this.leftColumnWidth + 'px;width:' + this.calendariumVisibleWidth + 'px;';
    if (isTouchDevice()) {
      result += 'display:none;';
    }
    return result;
  }
  private renderNavigation() {
    return html`
      <div class="navigation-wrapper">
        <div class="navigation">
          <div>
            <d-select-dropdown
              class="selectYear"
              @value-changed=${(e) => this.onYearChanged(e)}
              .options=${this.displayYearOptions}
              .value=${this.year}
              no-arrow
              controller
            ></d-select-dropdown>
            <div class="today-button" @click=${() => this._today()}>I dag</div>
          </div>
          <div>
            <d-select-dropdown
              .options=${this.displayDayStartOptions}
              .value=${this.dayStart}
              @value-changed=${(e) => this.onDayStartChanged(e)}
              no-arrow
              controller
            ></d-select-dropdown>
            <div class="mid-label">til</div>
            <d-select-dropdown
              .options=${this.displayDayEndOptions}
              .value=${this.dayEnd}
              @value-changed=${(e) => this.onDayEndChanged(e)}
              no-arrow
              controller
            ></d-select-dropdown>
          </div>
          <div>
            <d-select-dropdown
              .options=${this.displayZoomOptions}
              .value=${this.zoom}
              @value-changed=${(e) => this.onZoomChanged(e)}
              no-arrow
              controller
            ></d-select-dropdown>
          </div>
        </div>
      </div>
      <div class="scroll-buttons-wrapper" style="${this.scrollButtonsWrapperStyle}">
        <div id="scroll-buttons-holder">
          <div id="scroll-buttons">
            <div
              class="scroll-button"
              id="scroll-button-left"
              @mousedown=${(e) => this.scrollManual(e, -1)}
              @mouseout=${() => this.scrollManualStop()}
              @mouseup=${() => this.scrollManualStop()}
            >
              <div></div>
            </div>
            <div
              class="scroll-button"
              id="scroll-button-right"
              @mousedown=${(e) => this.scrollManual(e, 1)}
              @mouseout=${() => this.scrollManualStop()}
              @mouseup=${() => this.scrollManualStop()}
            >
              <div></div>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  private renderCalendarium() {
    return html`
      <div style="display: flex;">
        <div class="yearSpacer" id="yearSpacerBefore" style="${this.calendariumVisibleWidthStyle}">
          <div style="position:relative; margin-top: 50px;">
            <d-spinner-robot id="spinner" size="60"></d-spinner-robot>
          </div>
        </div>
        <div class="prevNextWrapper" style="width: ${this.prevNextWrapperWidth + 'px'}">
          ${!this._prevYear(this.year)
            ? nothing
            : html`<div class="prevNext prev" id="prev" @click=${() => this._setPrevYear()}>
                ${this._prevYear(this.year)}
              </div>`}
        </div>
        <d-staffing-calendar-header
          .year=${this.year}
          .startOfDayHours=${this.startOfDayHours}
          .endOfDayHours=${this.endOfDayHours}
          .zoom=${this.zoom}
          .stickyLeft=${this.leftColumnWidth}
          @width-changed=${this._onStaffingCalendarHeaderWidthChanged}
        ></d-staffing-calendar-header>
        <div class="prevNextWrapper" style="width: ${this.prevNextWrapperWidth + 'px'}">
          ${!this._nextYear(this.year, this.yearsFromNow)
            ? nothing
            : html`<div class="prevNext next" id="next" @click=${() => this._setNextYear()}>
                ${this._nextYear(this.year, this.yearsFromNow)}
              </div>`}
        </div>
        <div class="yearSpacer" id="yearSpacerAfter" style="${this.calendariumVisibleWidthStyle}">
          <div style="position:relative; margin-top: 50px;">
            <d-spinner-robot id="spinner" size="60"></d-spinner-robot>
          </div>
        </div>
      </div>
    `;
  }

  private renderEmployees() {
    const employeesCount = this._employeesCount(this.employeeGroups);
    return html` <div id="employees" style="grid-column: 1; grid-row: 1" @click=${() => this._showEditEmployeeGroups()}>
      ${this.employeeGroups.map(
        (group) =>
          html`<div class="group">
            ${this.noGroups ? nothing : html`<div class="color-bar"></div>`}
            <div class="groupContent">
              ${group.items.map(
                (employee) => html` <div class="person" data-uuid="${employee.uuid}">${employee.displayName}</div> `,
              )}
            </div>
          </div>`,
      )}

      <div
        class="${this._staffGroupGraphCountClasses(this.staffGroupGraphExpanded)}"
        style="${this._staffGroupGraphHeightStyle(employeesCount, this.staffGroupGraphExpanded)}"
      >
        ${this._employeesNumbers(this.employeeGroups).map((item) => html`<div>${item}</div>`)}
      </div>
    </div>`;
  }

  private renderData() {
    const args = this.employeeCalendarData;
    let employeesCount = 0;
    args.data.forEach((g) => {
      employeesCount += g.employees.length;
    });
    return html`
      <div style="display: flex">
        <div style="width: 99px"></div>
        <div>
          <d-staffing-calendar-data
            .data=${args.data}
            .startOfDayHours=${this.startOfDayHours}
            .endOfDayHours=${this.endOfDayHours}
            .zoom=${this.zoom}
          ></d-staffing-calendar-data>
          <d-staffing-calendar-groups
            .employeesCount=${employeesCount}
            .groupGraphData=${this.groupGraphData}
            .startOfDayHours=${this.startOfDayHours}
            .endOfDayHours=${this.endOfDayHours}
            .zoom=${this.zoom}
            @set-staff-group-graph-expanded=${(e) => (this.staffGroupGraphExpanded = e.detail.value)}
          ></d-staffing-calendar-groups>
        </div>
        <div style="width: 99px"></div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-staffing-calendar-table': DStaffingCalendarTable;
  }
}
