import { css, html, LitElement } from 'lit';
import '../elements/d-label';
import { customElement, property } from 'lit/decorators.js';
import { LocalDate } from 'src/utilities/local-date';
import sortBy from 'lodash.sortby';

/**
 *
 * USAGE:
 *    d-meetings-page-content
 *
 * STATUS OK
 */
@customElement('d-activity-indicator')
export class DActivityIndicator extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 12px;
      line-height: 100%;
      color: hsla(0, 0%, 0%, 0.6);
      mix-blend-mode: multiply;
    }
    .year {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .name {
      flex: none;
      margin-right: 6px;
      margin-top: 2px;
    }
    .content {
      flex: 1;
      box-sizing: border-box;
      width: 100%;
      height: 10px;
      margin-right: 6px;
      border-radius: 5px;
      background-color: hsl(0, 0%, 0%, 0.1);
      padding: 5px;
      overflow: hidden;
      mix-blend-mode: multiply;
    }
    .content > div {
      position: relative;
      left: -5px;
      height: 0;
    }
    .content > div > a {
      display: block;
      position: absolute;
      box-sizing: border-box;
      top: -5px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: var(--themeColor);
      box-shadow: 0 0 3px white;
    }
    .content > div > a.classified {
      background-color: var(--alertColor);
    }
    .content > div > a.restricted {
      background-color: red;
      cursor: default;
      pointer-events: none;
    }
    .content > div > a.meeting-initialized {
      border: 1px solid black;
    }
    @media (hover: hover) {
      .content > div > a:hover {
        background-color: var(--themeColorDarkerOne);
      }
      .content > div > a.classified:hover {
        background-color: var(--alertColorDarkerOne);
      }
      .content > div > a.restricted:hover {
        background-color: red;
    }
  `;

  @property({ type: Array })
  years: number[] = [];
  @property({ type: Array })
  activities: { date: string; href: string; classes: string }[] = [];

  private get data(): { name: number; activities: { href: string; left: number; classes: string }[] }[] {
    return this.years.map((year) => {
      const yearStart = LocalDate.fromString(year + '-01-01');
      const yearStartEpochDay = yearStart.toEpochDay();
      const activities = this.activities
        .filter((a) => {
          return a.date.split('-')[0] === year + '';
        })
        .map((a) => {
          const epochDay = LocalDate.fromString(a.date).toEpochDay();
          const dayOfYear = epochDay - yearStartEpochDay;
          return { href: a.href, left: dayOfYear * (100 / yearStart.daysInYear()), classes: a.classes };
        });
      return {
        name: year,
        activities: sortBy(activities, ['left']),
      };
    });
  }

  render() {
    return html`
      ${this.data.map((year) => {
        return html`
          <div class="year">
            <div class="name">${year.name}</div>
            <div class="content">
              <div>
                ${year.activities.map((a) => {
                  return html` <a href="${a.href}" style="left:${a.left}%" class="${a.classes}"></a> `;
                })}
              </div>
            </div>
          </div>
        `;
      })}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-activity-indicator': DActivityIndicator;
  }
}
