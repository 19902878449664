import { css, html, LitElement, nothing } from 'lit';

import '../editors/elements/d-checkbox.js';
import { customElement, property } from 'lit/decorators.js';
import type { ActivityCode } from '../../store/selectors';
import '../fields/d-expansion.js';

/**
 *
 * STATUS OK
 */
@customElement('d-activity-codes')
export class DActivityCodes extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    d-checkbox {
      margin-bottom: 4px;
    }

    div {
      padding-left: 30px;
    }
  `;
  /**
   * The available activity codes
   */
  @property({ type: Object })
  data!: ActivityCode;

  /**
   * The selected activity codes
   */
  @property({ type: Array })
  codes!: string[];

  @property({ type: Boolean, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean, reflect: true })
  outskirts = false;

  get active() {
    return this.codes.includes(this.data.code);
  }

  onCheckedChanged(e: CustomEvent<{ checked: boolean }>) {
    e.preventDefault();
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent('activity-code-changed', {
        bubbles: true,
        composed: true,
        detail: {
          code: this.data.code,
          active: e.detail.checked,
        },
      }),
    );
  }

  render() {
    return html`
      <d-checkbox
        @checked-changed=${this.onCheckedChanged}
        ?checked=${this.active}
        .optionLabel=${this.data.name}
        ?theme-page=${this.themePage}
        ?outskirts=${this.outskirts}
      ></d-checkbox>
      <d-expansion ?opened=${this.active}>
        <div>
          ${this.data.codes
            ? this.data.codes.map(
                (item) =>
                  html` <d-activity-codes
                    .codes=${this.codes}
                    .data=${item}
                    ?theme-page=${this.themePage}
                    .outskirts=${this.outskirts}
                  ></d-activity-codes>`,
              )
            : nothing}
        </div>
      </d-expansion>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-activity-codes': DActivityCodes;
  }
}
