import { css, html, LitElement, nothing, TemplateResult } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../elements/d-section.js';
import '../fields/d-bullets.js';
import '../fields/d-checklist.js';
import type { Checklist } from '../fields/d-checklist.js';
import '../fields/d-conditional-bullet.js';
import '../fields/d-view-html.js';
import '../fields/d-view-info.js';
import '../fields/d-view-text.js';

export type FormViewItemSizeType = 's' | 'm' | 'l';

export type FormViewItem =
  | FormViewHtmlItem
  | FormViewTextItem
  | FormViewChecklistItem
  | FormViewBulletsItem
  | FormViewTextListItem
  | FormViewConditionalBulletsItem
  | FormViewTextWithEditActionItem
  | FormViewCustomItem;

export interface FormViewTextItem {
  type: 'text' | 'text-vertical';
  field: string;
  value: string;
  size: FormViewItemSizeType;
}
export interface FormViewTextWithEditActionItem {
  type: 'text-with-edit-action';
  label: string;
  value: string;
  action?: () => void | Promise<void>;
  actionLabel: string;
  actionDisabled: boolean;
  icon?: string;
  actionTooltip?: string;
}

export interface FormViewTextListItem {
  type: 'text-list';
  list: {
    field: string;
    value: string;
  }[];
  size: FormViewItemSizeType;
}

export interface FormViewHtmlItem {
  type: 'html' | 'html-big' | 'html-with-checklist' | 'html-expandable';
  field: string;
  value: string;
  sublabel: string;
}

export interface FormViewChecklistItem {
  type: 'checklist';
  field: string;
  value: Checklist;
}

export interface FormViewBulletsItem {
  type: 'bullets';
  field: string;
  value: string[];
}

export interface FormViewConditionalBulletsItem {
  type: 'conditional-bullets';
  items: ConditionalBulletItem[];
}

export interface FormViewCustomItem {
  type: 'custom';
  render: () => TemplateResult<1>;
}

interface ConditionalBulletItemWithField {
  field: string;
  elseField: string;
  value: boolean;
  alert: boolean;
}

interface ConditionalBulletItemWithLabel {
  label: string;
  elseLabel: string;
  value: boolean;
  alert: boolean;
}

export type ConditionalBulletItem = ConditionalBulletItemWithField | ConditionalBulletItemWithLabel;

export class FormViewBuilder {
  private result: FormViewItem[] = [];
  addTextVertical(field: string, value: string | undefined, size: FormViewItemSizeType, condition: boolean) {
    if (condition) {
      this.result.push({ type: 'text-vertical', field, value: value ?? '', size });
    }
  }

  addText(field: string, value: string | undefined, size: FormViewItemSizeType, condition: boolean) {
    if (condition) {
      this.result.push({ type: 'text', field, value: value ?? '', size });
    }
  }

  /**
   * Creates a column of texts. If the value contains line breaks then that item is rendered with label above.
   *
   * @param list
   * @param size
   */
  addTextList(list: { field: string; value: string | undefined }[], size: FormViewItemSizeType) {
    this.result.push({
      type: 'text-list',
      list: list.map(({ field, value }) => ({ field: field, value: value ?? '' })),
      size,
    });
  }

  addHtml(field: string, value: string, sublabel: string, preventChecklists = true) {
    this.result.push({
      type: preventChecklists ? 'html' : 'html-with-checklist',
      field,
      value: value ?? '',
      sublabel: sublabel,
    });
  }

  addHtmlContentBigLabel(field: string, value: string) {
    this.result.push({ type: 'html-big', field, value: value ?? '', sublabel: '' });
  }

  addExpandableHtml(field: string, value: string, sublabel = '') {
    this.result.push({ type: 'html-expandable', field, value: value ?? '', sublabel: sublabel ?? '' });
  }

  addTextVerticalIfNotEmpty(field: string, value: string | undefined, size: FormViewItemSizeType) {
    this.addTextVertical(field, value, size, value !== undefined && value.trim() !== '');
  }

  addTextIfNotEmpty(field: string, value: string | undefined, size: FormViewItemSizeType) {
    this.addText(field, value, size, value !== undefined && value.trim() !== '');
  }

  addChecklist(field: string, value: Checklist) {
    this.result.push({ type: 'checklist', field, value: value });
  }
  addBullets(field: string, value: string[]) {
    this.result.push({ type: 'bullets', field, value: value });
  }
  addCustom(render: () => TemplateResult<1>) {
    this.result.push({ type: 'custom', render });
  }

  addConditionalBullets(items: ConditionalBulletItem[]) {
    this.result.push({ type: 'conditional-bullets', items: items });
  }

  addTextWithEditAction(
    label: string,
    value: string,
    action: (() => void | Promise<void>) | undefined,
    actionLabel = 'Rediger',
    actionDisabled = false,
    icon: string | undefined = undefined,
    actionTooltip: string | undefined = undefined,
  ) {
    this.result.push({
      type: 'text-with-edit-action',
      label: label,
      value: value,
      action: action,
      actionLabel: actionLabel,
      actionDisabled: actionDisabled,
      icon: icon,
      actionTooltip: actionTooltip,
    });
  }

  build(): FormViewItem[] {
    return this.result;
  }
}

export function formViewBuilder(): FormViewBuilder {
  return new FormViewBuilder();
}

/**
 *
 * STATUS OK
 */
@customElement('d-form-view')
export class DFormView extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    div {
      flex: 1;
      margin-left: -1px;
      border-left: 1px solid var(--borderColorOnGray);
      padding: 0 12px;
      max-width: 100%;
      box-sizing: border-box;
    }

    div.minWidth200 {
      min-width: 200px;
    }

    div.minWidth300 {
      min-width: 300px;
    }

    div.minWidth600 {
      min-width: 300px;
    }

    .width600 div.minWidth600 {
      min-width: 600px;
    }

    d-view-text + d-view-text {
      margin-top: 4px;
    }

    d-section > div > d-wrap {
      margin-top: -12px;
      margin-bottom: -12px;
    }
  `;

  @property({ type: Array })
  viewItems!: FormViewItem[];

  render() {
    return html`<d-section>${this.viewItems.map((item) => html`${this.renderItem(item)}`)}</d-section>`;
  }

  private renderTextVertical(item: FormViewTextItem) {
    return html` <div
      class="${classMap({
        minWidth200: item.size === 's',
        minWidth300: item.size === 'm',
        minWidth600: item.size === 'l',
      })}"
    >
      <d-view-text .field=${item.field} .value=${item.value}></d-view-text>
    </div>`;
  }

  private renderText(item: FormViewTextItem) {
    return html` <div
      class="${classMap({
        minWidth200: item.size === 's',
        minWidth300: item.size === 'm',
        minWidth600: item.size === 'l',
      })}"
    >
      <d-view-text inline-label .field=${item.field} .value=${item.value}></d-view-text>
    </div>`;
  }

  private renderTextList(item: FormViewTextListItem) {
    return html` <div
      class="${classMap({
        minWidth200: item.size === 's',
        minWidth300: item.size === 'm',
        minWidth600: item.size === 'l',
      })}"
    >
      ${item.list.map(
        (e) => html`<d-view-text inline-label .field=${e.field} .value=${e.value} tooltip=""></d-view-text>`,
      )}
    </div>`;
  }

  private renderHtml(item: FormViewHtmlItem) {
    return html` <div class="minWidth600">
      <d-view-html
        .field=${item.field}
        .value=${item.value}
        .sublabel=${item.sublabel}
        .preventChecklist=${item.type !== 'html-with-checklist'}
        .big=${item.type === 'html-big'}
        .collapseHeight=${item.type !== 'html-with-checklist' ? 100 : 10000}
      ></d-view-html>
    </div>`;
  }

  private renderExpandableHtml(item: FormViewHtmlItem) {
    return html` <div class="minWidth600">
      <d-view-html .field=${item.field} .value=${item.value} .sublabel=${item.sublabel} big expandable></d-view-html>
    </div>`;
  }

  private renderChecklist(item: FormViewChecklistItem) {
    return html` <div class="minWidth600">
      <d-checklist .field=${item.field} .checklist=${item.value}></d-checklist>
    </div>`;
  }

  private renderBullets(item: FormViewBulletsItem) {
    return html` <div class="minWidth600">
      <d-bullets .field=${item.field} .value=${item.value}></d-bullets>
    </div>`;
  }

  private renderItem(item: FormViewItem) {
    switch (item.type) {
      case 'text-list':
        return this.renderTextList(item);
      case 'text':
        return this.renderText(item);
      case 'text-vertical':
        return this.renderTextVertical(item);
      case 'html':
        return this.renderHtml(item);
      case 'html-big':
        return this.renderHtml(item);
      case 'html-with-checklist':
        return this.renderHtml(item);
      case 'html-expandable':
        return this.renderExpandableHtml(item);
      case 'checklist':
        return this.renderChecklist(item);
      case 'bullets':
        return this.renderBullets(item);
      case 'conditional-bullets':
        return this.renderConditionalBullets(item);
      case 'text-with-edit-action':
        return this.renderTextWithEditAction(item);
      case 'custom':
        return this.renderCustom(item);
    }
  }

  private renderConditionalBullets(item: FormViewConditionalBulletsItem) {
    return html` <div class="minWidth600">
      ${item.items.map((e) =>
        'field' in e
          ? html`<d-conditional-bullet
              .field=${e.field}
              .elseField=${e.elseField}
              ?condition=${e.value}
              .alert=${!e.value && e.alert}
            ></d-conditional-bullet>`
          : html`<d-conditional-bullet
              .label=${e.label}
              .elseLabel=${e.elseLabel}
              ?condition=${e.value}
              .alert=${!e.value && e.alert}
            ></d-conditional-bullet>`,
      )}
    </div>`;
  }

  private renderTextWithEditAction(item: FormViewTextWithEditActionItem) {
    return html` <div class="minWidth600">
      ${item.action === undefined || item.actionLabel === '' ? this.renderNoAction(item) : this.renderAction(item)}
    </div>`;
  }

  private renderAction(item: FormViewTextWithEditActionItem) {
    return html`
      <d-wrap split
        ><d-view-text inline-label .label=${item.label} .value=${item.value}></d-view-text
        ><d-action
          @click=${async (e) => {
            e.preventDefault();
            if (item.action) {
              await item.action();
            }
          }}
          ?disabled=${item.actionDisabled}
          >${item.actionLabel}${item.actionTooltip
            ? html`<d-tooltip .content=${item.actionTooltip}></d-tooltip>`
            : nothing}</d-action
        >
      </d-wrap>
    `;
  }

  private renderNoAction(item: FormViewTextWithEditActionItem) {
    return html`<d-view-text inline-label .label=${item.label} .value=${item.value}></d-view-text>`;
  }

  private renderCustom(item: FormViewCustomItem) {
    return html` <div class="minWidth600">${item.render()}</div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-form-view': DFormView;
  }
}
