import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export type SpinnerColor = 'blue' | 'black' | 'white';
export type SpinnerSize = 'small' | 'medium';

/**
 *
 * STATUS OK
 */
@customElement('d-spinner')
export class DSpinner extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .spinner {
      font-size: 48px;
      position: relative;
      display: inline-block;
      width: 1em;
      height: 1em;
    }

    .spinner.small {
      font-size: 32px;
    }

    .spinner.center {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .spinner .spinner-blade {
      position: absolute;
      left: 0.4629em;
      bottom: 0;
      width: 0.074em;
      height: 0.2777em;
      border-radius: 0.0555em;
      background-color: transparent;
      transform-origin: center -0.2222em;
      animation: spinner-fade 1s infinite linear;
    }

    :host([no-animation]) .spinner .spinner-blade {
      animation: none;
    }

    .spinner.white .spinner-blade {
      animation: spinner-fade-white 1s infinite linear;
    }

    .spinner.black .spinner-blade {
      animation: spinner-fade-black 1s infinite linear;
    }

    .spinner.blue .spinner-blade {
      animation: spinner-fade-blue 1s infinite linear;
    }

    .spinner .spinner-blade:nth-child(1) {
      animation-delay: 0s;
      transform: rotate(0deg);
    }

    .spinner .spinner-blade:nth-child(2) {
      animation-delay: 0.083s;
      transform: rotate(30deg);
    }

    .spinner .spinner-blade:nth-child(3) {
      animation-delay: 0.166s;
      transform: rotate(60deg);
    }

    .spinner .spinner-blade:nth-child(4) {
      animation-delay: 0.249s;
      transform: rotate(90deg);
    }

    .spinner .spinner-blade:nth-child(5) {
      animation-delay: 0.332s;
      transform: rotate(120deg);
    }

    .spinner .spinner-blade:nth-child(6) {
      animation-delay: 0.415s;
      transform: rotate(150deg);
    }

    .spinner .spinner-blade:nth-child(7) {
      animation-delay: 0.498s;
      transform: rotate(180deg);
    }

    .spinner .spinner-blade:nth-child(8) {
      animation-delay: 0.581s;
      transform: rotate(210deg);
    }

    .spinner .spinner-blade:nth-child(9) {
      animation-delay: 0.664s;
      transform: rotate(240deg);
    }

    .spinner .spinner-blade:nth-child(10) {
      animation-delay: 0.747s;
      transform: rotate(270deg);
    }

    .spinner .spinner-blade:nth-child(11) {
      animation-delay: 0.83s;
      transform: rotate(300deg);
    }

    .spinner .spinner-blade:nth-child(12) {
      animation-delay: 0.913s;
      transform: rotate(330deg);
    }

    @keyframes spinner-fade-white {
      0% {
        background-color: white;
      }

      100% {
        background-color: transparent;
      }
    }

    @keyframes spinner-fade-black {
      0% {
        background-color: black;
      }

      100% {
        background-color: transparent;
      }
    }

    @keyframes spinner-fade-blue {
      0% {
        background-color: hsl(196, 83%, 49%);
      }

      100% {
        background-color: transparent;
      }
    }
  `;
  @property({ type: String })
  color: SpinnerColor = 'blue';
  @property({ type: String })
  size: SpinnerSize = 'medium';
  @property({ type: String })
  label = '';

  /**
   * No animation. Turn off all animations. Used by storybook to enable consistent snapshots.
   */
  @property({ type: Boolean, reflect: true, attribute: 'no-animation' })
  noAnimation = false;

  _classes(color, size) {
    return 'spinner center ' + color + ' ' + size;
  }

  render() {
    return html`
      <div class="${this._classes(this.color, this.size)}">
        <div class="spinner-blade"></div>
        <div class="spinner-blade"></div>
        <div class="spinner-blade"></div>
        <div class="spinner-blade"></div>
        <div class="spinner-blade"></div>
        <div class="spinner-blade"></div>
        <div class="spinner-blade"></div>
        <div class="spinner-blade"></div>
        <div class="spinner-blade"></div>
        <div class="spinner-blade"></div>
        <div class="spinner-blade"></div>
        <div class="spinner-blade"></div>
      </div>
      ${this.label !== ''
        ? html`<div
            class="${this._classes(this.color, this.size)}"
            style="font-size: 10px; width: 100px; text-align: center; padding-top: 70px; color: white"
          >
            ${this.label}
          </div>`
        : nothing}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-spinner': DSpinner;
  }
}
