/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Buffer } from 'buffer/index.js';
import * as runtime from '../runtime';
import type {
  AttachmentPropertiesUpdateMessage,
  AttachmentViewModel,
  CreateEventOccurrenceCommand,
  DeleteEventOccurrenceCommand,
  EventOccurrenceRelatedAssetUpdateMessage,
  EventOccurrenceViewModel,
  MarkEventOccurrenceDoneMessage,
  MarkEventOccurrenceNotDoneMessage,
  RepeatEventOccurrenceCommand,
  UpdateChecklistMessage,
  UpdateEventOccurrenceCommand,
} from '../models';
import {
  AttachmentPropertiesUpdateMessageFromJSON,
  AttachmentPropertiesUpdateMessageToJSON,
  AttachmentViewModelFromJSON,
  AttachmentViewModelToJSON,
  CreateEventOccurrenceCommandFromJSON,
  CreateEventOccurrenceCommandToJSON,
  DeleteEventOccurrenceCommandFromJSON,
  DeleteEventOccurrenceCommandToJSON,
  EventOccurrenceRelatedAssetUpdateMessageFromJSON,
  EventOccurrenceRelatedAssetUpdateMessageToJSON,
  EventOccurrenceViewModelFromJSON,
  EventOccurrenceViewModelToJSON,
  MarkEventOccurrenceDoneMessageFromJSON,
  MarkEventOccurrenceDoneMessageToJSON,
  MarkEventOccurrenceNotDoneMessageFromJSON,
  MarkEventOccurrenceNotDoneMessageToJSON,
  RepeatEventOccurrenceCommandFromJSON,
  RepeatEventOccurrenceCommandToJSON,
  UpdateChecklistMessageFromJSON,
  UpdateChecklistMessageToJSON,
  UpdateEventOccurrenceCommandFromJSON,
  UpdateEventOccurrenceCommandToJSON,
} from '../models';

export interface CreateEventOccurrenceRequest {
  organizationId: string;
  createEventOccurrenceCommand: CreateEventOccurrenceCommand;
}

export interface DeleteAttachmentForEventOccurrenceRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteEventOccurrenceRequest {
  organizationId: string;
  deleteEventOccurrenceCommand: DeleteEventOccurrenceCommand;
}

export interface FetchAttachmentForEventOccurrenceRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchEventOccurrenceRequest {
  eventOccurrenceId: string;
  organizationId: string;
}

export interface ListAttachmentsForEventOccurrenceRequest {
  entityId: string;
  organizationId: string;
}

export interface ListEventOccurrencesRequest {
  organizationId: string;
}

export interface MarkEventOccurrenceDoneRequest {
  organizationId: string;
  markEventOccurrenceDoneMessage: MarkEventOccurrenceDoneMessage;
}

export interface MarkEventOccurrenceNotDoneRequest {
  organizationId: string;
  markEventOccurrenceNotDoneMessage: MarkEventOccurrenceNotDoneMessage;
}

export interface RenameAttachmentForEventOccurrenceRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
  attachmentPropertiesUpdateMessage: AttachmentPropertiesUpdateMessage;
}

export interface RepeatEventOccurrenceRequest {
  organizationId: string;
  repeatEventOccurrenceCommand: RepeatEventOccurrenceCommand;
}

export interface UpdateEventOccurrenceRequest {
  organizationId: string;
  updateEventOccurrenceCommand: UpdateEventOccurrenceCommand;
}

export interface UpdateEventOccurrenceChecklistRequest {
  eventOccurrenceId: string;
  organizationId: string;
  updateChecklistMessage: UpdateChecklistMessage;
}

export interface UpdateEventOccurrenceRelatedAssetRequest {
  organizationId: string;
  eventOccurrenceRelatedAssetUpdateMessage: EventOccurrenceRelatedAssetUpdateMessage;
}

export interface UploadAttachmentForEventOccurrenceRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
  contentLength?: string;
  body?: string;
}

/**
 *
 */
export class EventOccurrencesApi extends runtime.BaseAPI {
  /**
   * Oppretter en ny oppgave. Oppgaver kan opprettes basert på en dato, basert på et utstyr og/eller basert på en rutine.  De kan også opprettes direkte som snarest oppgave. Oppgaver basert på rutiner får mange av de initielle verdiene  fra rutinen. Ellers får nye oppgaver verdier fra den \'forrige\' oppgaven eller den \'neste\' oppgaven. Denne oppgaven  er angitt som `eventUuid`.
   */
  async createEventOccurrenceRaw(
    requestParameters: CreateEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createEventOccurrence.',
      );
    }

    if (
      requestParameters.createEventOccurrenceCommand === null ||
      requestParameters.createEventOccurrenceCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'createEventOccurrenceCommand',
        'Required parameter requestParameters.createEventOccurrenceCommand was null or undefined when calling createEventOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/:create-event-occurrence`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateEventOccurrenceCommandToJSON(requestParameters.createEventOccurrenceCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Oppretter en ny oppgave. Oppgaver kan opprettes basert på en dato, basert på et utstyr og/eller basert på en rutine.  De kan også opprettes direkte som snarest oppgave. Oppgaver basert på rutiner får mange av de initielle verdiene  fra rutinen. Ellers får nye oppgaver verdier fra den \'forrige\' oppgaven eller den \'neste\' oppgaven. Denne oppgaven  er angitt som `eventUuid`.
   */
  async createEventOccurrence(
    requestParameters: CreateEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createEventOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   */
  async deleteAttachmentForEventOccurrenceRaw(
    requestParameters: DeleteAttachmentForEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling deleteAttachmentForEventOccurrence.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteAttachmentForEventOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAttachmentForEventOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteAttachmentForEventOccurrence(
    requestParameters: DeleteAttachmentForEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAttachmentForEventOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   * Sletter en oppgave. Denne metoden kan også slette oppgaver merket utført eller hvor sjekklisten er påbegynt. Dersom `saveType` har verdien `FUTURE` vil også fremtidige oppgaver slettes  forutsatt at de ikke er merket utført eller sjekklisten er påbegynt. Fremtidige oppgaver er relativ til datoen  på oppgaven som slettes. Dersom oppgaven som slettes er en snarest oppgave er fremtidige oppgaver fra og med dagens dato. Utførte oppgaver har en \"virtuell\" dato og kan vises i kalenderen. Denne virtuelle datoen vil ikke ha noe å  si for beregningen av fremtidige oppgaver. Slett fremtidige oppgaver inkluder også alle snarest-oppgaver og er uavhengig av hvem oppgaven er tildelt.
   */
  async deleteEventOccurrenceRaw(
    requestParameters: DeleteEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteEventOccurrence.',
      );
    }

    if (
      requestParameters.deleteEventOccurrenceCommand === null ||
      requestParameters.deleteEventOccurrenceCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'deleteEventOccurrenceCommand',
        'Required parameter requestParameters.deleteEventOccurrenceCommand was null or undefined when calling deleteEventOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/:delete-event-occurrence`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeleteEventOccurrenceCommandToJSON(requestParameters.deleteEventOccurrenceCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Sletter en oppgave. Denne metoden kan også slette oppgaver merket utført eller hvor sjekklisten er påbegynt. Dersom `saveType` har verdien `FUTURE` vil også fremtidige oppgaver slettes  forutsatt at de ikke er merket utført eller sjekklisten er påbegynt. Fremtidige oppgaver er relativ til datoen  på oppgaven som slettes. Dersom oppgaven som slettes er en snarest oppgave er fremtidige oppgaver fra og med dagens dato. Utførte oppgaver har en \"virtuell\" dato og kan vises i kalenderen. Denne virtuelle datoen vil ikke ha noe å  si for beregningen av fremtidige oppgaver. Slett fremtidige oppgaver inkluder også alle snarest-oppgaver og er uavhengig av hvem oppgaven er tildelt.
   */
  async deleteEventOccurrence(
    requestParameters: DeleteEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteEventOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   */
  async fetchAttachmentForEventOccurrenceRaw(
    requestParameters: FetchAttachmentForEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling fetchAttachmentForEventOccurrence.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentForEventOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentForEventOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   */
  async fetchAttachmentForEventOccurrence(
    requestParameters: FetchAttachmentForEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentForEventOccurrenceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async fetchEventOccurrenceRaw(
    requestParameters: FetchEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EventOccurrenceViewModel>> {
    if (requestParameters.eventOccurrenceId === null || requestParameters.eventOccurrenceId === undefined) {
      throw new runtime.RequiredError(
        'eventOccurrenceId',
        'Required parameter requestParameters.eventOccurrenceId was null or undefined when calling fetchEventOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchEventOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/{eventOccurrenceId}`
          .replace(`{${'eventOccurrenceId'}}`, encodeURIComponent(String(requestParameters.eventOccurrenceId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => EventOccurrenceViewModelFromJSON(jsonValue));
  }

  /**
   */
  async fetchEventOccurrence(
    requestParameters: FetchEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EventOccurrenceViewModel> {
    const response = await this.fetchEventOccurrenceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async listAttachmentsForEventOccurrenceRaw(
    requestParameters: ListAttachmentsForEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AttachmentViewModel>>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling listAttachmentsForEventOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listAttachmentsForEventOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/{entityId}/attachments`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentViewModelFromJSON));
  }

  /**
   */
  async listAttachmentsForEventOccurrence(
    requestParameters: ListAttachmentsForEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AttachmentViewModel>> {
    const response = await this.listAttachmentsForEventOccurrenceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async listEventOccurrencesRaw(
    requestParameters: ListEventOccurrencesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<EventOccurrenceViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listEventOccurrences.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventOccurrenceViewModelFromJSON));
  }

  /**
   */
  async listEventOccurrences(
    requestParameters: ListEventOccurrencesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<EventOccurrenceViewModel>> {
    const response = await this.listEventOccurrencesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Oppdaterer en ikke utført oppgave som utført.
   */
  async markEventOccurrenceDoneRaw(
    requestParameters: MarkEventOccurrenceDoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling markEventOccurrenceDone.',
      );
    }

    if (
      requestParameters.markEventOccurrenceDoneMessage === null ||
      requestParameters.markEventOccurrenceDoneMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'markEventOccurrenceDoneMessage',
        'Required parameter requestParameters.markEventOccurrenceDoneMessage was null or undefined when calling markEventOccurrenceDone.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/:mark-event-occurrence-done`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: MarkEventOccurrenceDoneMessageToJSON(requestParameters.markEventOccurrenceDoneMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Oppdaterer en ikke utført oppgave som utført.
   */
  async markEventOccurrenceDone(
    requestParameters: MarkEventOccurrenceDoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markEventOccurrenceDoneRaw(requestParameters, initOverrides);
  }

  /**
   * Oppdaterer en utført oppgave som ikke utført.
   */
  async markEventOccurrenceNotDoneRaw(
    requestParameters: MarkEventOccurrenceNotDoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling markEventOccurrenceNotDone.',
      );
    }

    if (
      requestParameters.markEventOccurrenceNotDoneMessage === null ||
      requestParameters.markEventOccurrenceNotDoneMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'markEventOccurrenceNotDoneMessage',
        'Required parameter requestParameters.markEventOccurrenceNotDoneMessage was null or undefined when calling markEventOccurrenceNotDone.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/:mark-event-occurrence-not-done`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: MarkEventOccurrenceNotDoneMessageToJSON(requestParameters.markEventOccurrenceNotDoneMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Oppdaterer en utført oppgave som ikke utført.
   */
  async markEventOccurrenceNotDone(
    requestParameters: MarkEventOccurrenceNotDoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markEventOccurrenceNotDoneRaw(requestParameters, initOverrides);
  }

  /**
   */
  async renameAttachmentForEventOccurrenceRaw(
    requestParameters: RenameAttachmentForEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling renameAttachmentForEventOccurrence.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling renameAttachmentForEventOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling renameAttachmentForEventOccurrence.',
      );
    }

    if (
      requestParameters.attachmentPropertiesUpdateMessage === null ||
      requestParameters.attachmentPropertiesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentPropertiesUpdateMessage',
        'Required parameter requestParameters.attachmentPropertiesUpdateMessage was null or undefined when calling renameAttachmentForEventOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/{entityId}/attachments/{attachmentUuid}/properties`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AttachmentPropertiesUpdateMessageToJSON(requestParameters.attachmentPropertiesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async renameAttachmentForEventOccurrence(
    requestParameters: RenameAttachmentForEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameAttachmentForEventOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   * Gjentar en gitt oppgave fremover. Alle datoer for nye oppgaver og instanser av oppgaver som fjernes må være etter  den gitte oppgave.  Nye oppgaver som opprettes kan ikke opprettes på samme dag som eksisterende oppgaver. Oppgaver som fjernes med denne metoden kan ikke være merket utført eller ha sjekklisten oppdatert. Eventuelle vedlegg blir ikke duplisert til nye oppgaver. Heller ikke utført status eller status på relatert utstyr.
   */
  async repeatEventOccurrenceRaw(
    requestParameters: RepeatEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling repeatEventOccurrence.',
      );
    }

    if (
      requestParameters.repeatEventOccurrenceCommand === null ||
      requestParameters.repeatEventOccurrenceCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'repeatEventOccurrenceCommand',
        'Required parameter requestParameters.repeatEventOccurrenceCommand was null or undefined when calling repeatEventOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/:repeat-event-occurrence`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RepeatEventOccurrenceCommandToJSON(requestParameters.repeatEventOccurrenceCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Gjentar en gitt oppgave fremover. Alle datoer for nye oppgaver og instanser av oppgaver som fjernes må være etter  den gitte oppgave.  Nye oppgaver som opprettes kan ikke opprettes på samme dag som eksisterende oppgaver. Oppgaver som fjernes med denne metoden kan ikke være merket utført eller ha sjekklisten oppdatert. Eventuelle vedlegg blir ikke duplisert til nye oppgaver. Heller ikke utført status eller status på relatert utstyr.
   */
  async repeatEventOccurrence(
    requestParameters: RepeatEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.repeatEventOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   * Oppdaterer en oppgave.  Dersom `saveType`har verdien `FUTURE` vil også etterfølgende oppgaver oppdateres. Etterfølgende oppgaver beregnes basert  på den nye dato i oppgaven. Dersom datoen endres vil fremtidige oppgaver endres dato med tilsvarende periode. Klokkeslett endres ikke relativt.  Denne blir satt til samme verdi som det lagrede møte. Snarest oppgaver er alltid fremtidige oppgave, men vil ikke endre tidspunkt. Oppgaver som er merket som \"utført\" vil ikke endres som del av en serie. Oppgaver med sjekklister hvor listen er  delvis utfylt blir ikke oppdatert. Når oppgaven ikke blir oppdatert blir ingen data i oppgaven endret. Endringer i relatert utstyr vil kun legge til eller fjerne utstyr. Dette gjelder selv om utstyret er merket med OK / ikke OK  eller kommentarer.
   */
  async updateEventOccurrenceRaw(
    requestParameters: UpdateEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateEventOccurrence.',
      );
    }

    if (
      requestParameters.updateEventOccurrenceCommand === null ||
      requestParameters.updateEventOccurrenceCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'updateEventOccurrenceCommand',
        'Required parameter requestParameters.updateEventOccurrenceCommand was null or undefined when calling updateEventOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/:update-event-occurrence`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateEventOccurrenceCommandToJSON(requestParameters.updateEventOccurrenceCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Oppdaterer en oppgave.  Dersom `saveType`har verdien `FUTURE` vil også etterfølgende oppgaver oppdateres. Etterfølgende oppgaver beregnes basert  på den nye dato i oppgaven. Dersom datoen endres vil fremtidige oppgaver endres dato med tilsvarende periode. Klokkeslett endres ikke relativt.  Denne blir satt til samme verdi som det lagrede møte. Snarest oppgaver er alltid fremtidige oppgave, men vil ikke endre tidspunkt. Oppgaver som er merket som \"utført\" vil ikke endres som del av en serie. Oppgaver med sjekklister hvor listen er  delvis utfylt blir ikke oppdatert. Når oppgaven ikke blir oppdatert blir ingen data i oppgaven endret. Endringer i relatert utstyr vil kun legge til eller fjerne utstyr. Dette gjelder selv om utstyret er merket med OK / ikke OK  eller kommentarer.
   */
  async updateEventOccurrence(
    requestParameters: UpdateEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateEventOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   * Oppdaterer sjekklisten for denne oppgaven. Sjekklisten er sjekkpunkter i innholdet `description` feltet. Denne metoden brukes for å oppdatere enkeltpunkter. Sjekklistepunkter kan også endres ved å redigere selve prosedyren.
   */
  async updateEventOccurrenceChecklistRaw(
    requestParameters: UpdateEventOccurrenceChecklistRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.eventOccurrenceId === null || requestParameters.eventOccurrenceId === undefined) {
      throw new runtime.RequiredError(
        'eventOccurrenceId',
        'Required parameter requestParameters.eventOccurrenceId was null or undefined when calling updateEventOccurrenceChecklist.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateEventOccurrenceChecklist.',
      );
    }

    if (requestParameters.updateChecklistMessage === null || requestParameters.updateChecklistMessage === undefined) {
      throw new runtime.RequiredError(
        'updateChecklistMessage',
        'Required parameter requestParameters.updateChecklistMessage was null or undefined when calling updateEventOccurrenceChecklist.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/{eventOccurrenceId}/:update-checklist`
          .replace(`{${'eventOccurrenceId'}}`, encodeURIComponent(String(requestParameters.eventOccurrenceId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateChecklistMessageToJSON(requestParameters.updateChecklistMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Oppdaterer sjekklisten for denne oppgaven. Sjekklisten er sjekkpunkter i innholdet `description` feltet. Denne metoden brukes for å oppdatere enkeltpunkter. Sjekklistepunkter kan også endres ved å redigere selve prosedyren.
   */
  async updateEventOccurrenceChecklist(
    requestParameters: UpdateEventOccurrenceChecklistRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateEventOccurrenceChecklistRaw(requestParameters, initOverrides);
  }

  /**
   * Merker utstyret på denne oppgaven med status og eventuell kommentar. Dersom det siste utstyret får angitt verdi settes oppgaven som utført.
   */
  async updateEventOccurrenceRelatedAssetRaw(
    requestParameters: UpdateEventOccurrenceRelatedAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateEventOccurrenceRelatedAsset.',
      );
    }

    if (
      requestParameters.eventOccurrenceRelatedAssetUpdateMessage === null ||
      requestParameters.eventOccurrenceRelatedAssetUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'eventOccurrenceRelatedAssetUpdateMessage',
        'Required parameter requestParameters.eventOccurrenceRelatedAssetUpdateMessage was null or undefined when calling updateEventOccurrenceRelatedAsset.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/:update-event-occurrence-related-asset`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EventOccurrenceRelatedAssetUpdateMessageToJSON(
          requestParameters.eventOccurrenceRelatedAssetUpdateMessage,
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Merker utstyret på denne oppgaven med status og eventuell kommentar. Dersom det siste utstyret får angitt verdi settes oppgaven som utført.
   */
  async updateEventOccurrenceRelatedAsset(
    requestParameters: UpdateEventOccurrenceRelatedAssetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateEventOccurrenceRelatedAssetRaw(requestParameters, initOverrides);
  }

  /**
   */
  async uploadAttachmentForEventOccurrenceRaw(
    requestParameters: UploadAttachmentForEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling uploadAttachmentForEventOccurrence.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling uploadAttachmentForEventOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling uploadAttachmentForEventOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.contentLength !== undefined && requestParameters.contentLength !== null) {
      headerParameters['Content-Length'] = String(requestParameters.contentLength);
    }

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.body !== undefined) {
      formParams.append('body', requestParameters.body as any);
    }

    const response = await this.request(
      {
        path: `/organizations/{organizationId}/eventOccurrences/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async uploadAttachmentForEventOccurrence(
    requestParameters: UploadAttachmentForEventOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.uploadAttachmentForEventOccurrenceRaw(requestParameters, initOverrides);
  }
}
