export class LocalTime {
  private readonly _hour: number;
  private readonly _minute: number;

  private constructor(hour: number, minute: number) {
    this._hour = hour;
    this._minute = minute;
  }

  public static of(byHour: number, byMinute: number) {
    return new LocalTime(byHour, byMinute);
  }

  private hourToString(): string {
    return this._hour < 10 ? '0' + this._hour : '' + this._hour;
  }

  private minuteToString(): string {
    return this._minute < 10 ? '0' + this._minute : '' + this._minute;
  }

  toStringForDisplay() {
    return this.hourToString() + '.' + this.minuteToString();
  }

  toString() {
    return this.hourToString() + ':' + this.minuteToString();
  }

  hour() {
    return this._hour;
  }

  minute() {
    return this._minute;
  }

  static fromString(string: string) {
    const s = string.split(':');
    return LocalTime.of(Number(s[0]), Number(s[1]));
  }
}
