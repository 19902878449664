import '../../library/fields/d-spinner-robot.js';
import '../../library/fields/d-view-info.js';
import { customElement, property } from 'lit/decorators.js';
import { css, LitElement } from 'lit';
import { html } from 'lit-html';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import type { GestureType } from '../../library/fields/d-spinner-robot.js';
import { commonFieldsByCode } from 'src/store/fields.js';

const fields = commonFieldsByCode();
/**
 *
 * STATUS OK
 */
@customElement('d-inline-robot-alert')
export class DInlineRobotAlert extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .sectionElementInner {
      display: flex;
    }

    d-spinner-robot {
      flex: none;
      position: relative;
      display: inline-block;
      margin-right: 15px;
      margin-left: 5px;
    }

    #alertHeader {
      margin-bottom: 8px;
      font-weight: bold;
    }
  `;

  @property({ type: Array })
  gesture: GestureType[] = ['roll'];
  @property({ type: String })
  field = '';
  /**
   * No animation. Turn off all animations. Used by storybook to enable consistent snapshots.
   */
  @property({ type: Boolean, reflect: true, attribute: 'no-animation' })
  noAnimation = false;

  fieldItem() {
    if (this.field) {
      return fields[this.field];
    }
    return undefined;
  }

  render() {
    const f = this.fieldItem();
    const header = f?.label ?? '';
    const content = f?.tooltip ?? '';

    return html`
      <div class="sectionElementInner">
        <d-spinner-robot ?no-animation=${this.noAnimation} .gesture=${this.gesture} size="40"></d-spinner-robot>
        <div>
          <div id="alertHeader">${unsafeHTML(header)}</div>
          <d-view-info .content=${content}></d-view-info>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-inline-robot-alert': DInlineRobotAlert;
  }
}
