/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateEventOccurrenceCommand
 */
export interface CreateEventOccurrenceCommand {
  /**
   * The initial date of the occurrence or none if todo task.
   * @type {string}
   * @memberof CreateEventOccurrenceCommand
   */
  date?: string;
  /**
   * The uuid of the new event occurrence.
   * @type {string}
   * @memberof CreateEventOccurrenceCommand
   */
  uuid: string;
  /**
   * An initial asset to add to the new event occurrence.
   * @type {string}
   * @memberof CreateEventOccurrenceCommand
   */
  assetUuid?: string;
  /**
   * An existing event occurrence uuid used as a template for the new occurrence.
   * @type {string}
   * @memberof CreateEventOccurrenceCommand
   */
  eventUuid: string;
  /**
   * A task uuid used to associate the new occurrence with an existing routine.
   * @type {string}
   * @memberof CreateEventOccurrenceCommand
   */
  taskUuid?: string;
}

/**
 * Check if a given object implements the CreateEventOccurrenceCommand interface.
 */
export function instanceOfCreateEventOccurrenceCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'eventUuid' in value;

  return isInstance;
}

export function CreateEventOccurrenceCommandFromJSON(json: any): CreateEventOccurrenceCommand {
  return CreateEventOccurrenceCommandFromJSONTyped(json, false);
}

export function CreateEventOccurrenceCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateEventOccurrenceCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: !exists(json, 'date') ? undefined : json['date'],
    uuid: json['uuid'],
    assetUuid: !exists(json, 'assetUuid') ? undefined : json['assetUuid'],
    eventUuid: json['eventUuid'],
    taskUuid: !exists(json, 'taskUuid') ? undefined : json['taskUuid'],
  };
}

export function CreateEventOccurrenceCommandToJSON(value?: CreateEventOccurrenceCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date === undefined ? undefined : value.date,
    uuid: value.uuid,
    assetUuid: value.assetUuid,
    eventUuid: value.eventUuid,
    taskUuid: value.taskUuid,
  };
}
