/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Buffer } from 'buffer/index.js';
import * as runtime from '../runtime';
import type {
  AttachmentPropertiesUpdateMessage,
  AttachmentViewModel,
  RevisionViewModel,
  RiskAssessmentUpdateMessage,
  RiskAssessmentViewModel,
} from '../models';
import {
  AttachmentPropertiesUpdateMessageFromJSON,
  AttachmentPropertiesUpdateMessageToJSON,
  AttachmentViewModelFromJSON,
  AttachmentViewModelToJSON,
  RevisionViewModelFromJSON,
  RevisionViewModelToJSON,
  RiskAssessmentUpdateMessageFromJSON,
  RiskAssessmentUpdateMessageToJSON,
  RiskAssessmentViewModelFromJSON,
  RiskAssessmentViewModelToJSON,
} from '../models';

export interface CreateOrUpdateRiskAssessmentRequest {
  riskAssessmentId: string;
  organizationId: string;
  riskAssessmentUpdateMessage: RiskAssessmentUpdateMessage;
}

export interface DeleteAttachmentForRiskAssessmentRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteRiskAssessmentRequest {
  riskAssessmentId: string;
  organizationId: string;
}

export interface FetchAttachmentForRiskAssessmentRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentLinkForRiskAssessmentRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchRiskAssessmentRequest {
  riskAssessmentId: string;
  organizationId: string;
}

export interface ListAttachmentsForRiskAssessmentRequest {
  entityId: string;
  organizationId: string;
}

export interface ListRevisionsForRiskAssessmentRequest {
  riskAssessmentId: string;
  organizationId: string;
}

export interface ListRiskAssessmentsRequest {
  organizationId: string;
}

export interface RenameAttachmentForRiskAssessmentRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
  attachmentPropertiesUpdateMessage: AttachmentPropertiesUpdateMessage;
}

export interface UploadAttachmentForRiskAssessmentRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
  contentLength?: string;
  body?: string;
}

/**
 *
 */
export class RiskAssessmentsApi extends runtime.BaseAPI {
  /**
   * Update or create riskAssessment with the specified riskAssessment id, and the search index for the riskAssessment will also be updated or created.
   * Creates or updates a risk assessment.
   */
  async createOrUpdateRiskAssessmentRaw(
    requestParameters: CreateOrUpdateRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.riskAssessmentId === null || requestParameters.riskAssessmentId === undefined) {
      throw new runtime.RequiredError(
        'riskAssessmentId',
        'Required parameter requestParameters.riskAssessmentId was null or undefined when calling createOrUpdateRiskAssessment.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateRiskAssessment.',
      );
    }

    if (
      requestParameters.riskAssessmentUpdateMessage === null ||
      requestParameters.riskAssessmentUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'riskAssessmentUpdateMessage',
        'Required parameter requestParameters.riskAssessmentUpdateMessage was null or undefined when calling createOrUpdateRiskAssessment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/riskAssessments/{riskAssessmentId}`
          .replace(`{${'riskAssessmentId'}}`, encodeURIComponent(String(requestParameters.riskAssessmentId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: RiskAssessmentUpdateMessageToJSON(requestParameters.riskAssessmentUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update or create riskAssessment with the specified riskAssessment id, and the search index for the riskAssessment will also be updated or created.
   * Creates or updates a risk assessment.
   */
  async createOrUpdateRiskAssessment(
    requestParameters: CreateOrUpdateRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateRiskAssessmentRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for a risk assessment.
   */
  async deleteAttachmentForRiskAssessmentRaw(
    requestParameters: DeleteAttachmentForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling deleteAttachmentForRiskAssessment.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteAttachmentForRiskAssessment.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAttachmentForRiskAssessment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/riskAssessments/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for a risk assessment.
   */
  async deleteAttachmentForRiskAssessment(
    requestParameters: DeleteAttachmentForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAttachmentForRiskAssessmentRaw(requestParameters, initOverrides);
  }

  /**
   * Delete riskAssessment with the specified riskAssessment id, the search index of the riskAssessment will also be deleted.
   * Deletes a risk assessment.
   */
  async deleteRiskAssessmentRaw(
    requestParameters: DeleteRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.riskAssessmentId === null || requestParameters.riskAssessmentId === undefined) {
      throw new runtime.RequiredError(
        'riskAssessmentId',
        'Required parameter requestParameters.riskAssessmentId was null or undefined when calling deleteRiskAssessment.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteRiskAssessment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/riskAssessments/{riskAssessmentId}`
          .replace(`{${'riskAssessmentId'}}`, encodeURIComponent(String(requestParameters.riskAssessmentId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete riskAssessment with the specified riskAssessment id, the search index of the riskAssessment will also be deleted.
   * Deletes a risk assessment.
   */
  async deleteRiskAssessment(
    requestParameters: DeleteRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteRiskAssessmentRaw(requestParameters, initOverrides);
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForRiskAssessmentRaw(
    requestParameters: FetchAttachmentForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling fetchAttachmentForRiskAssessment.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentForRiskAssessment.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentForRiskAssessment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/riskAssessments/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForRiskAssessment(
    requestParameters: FetchAttachmentForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentForRiskAssessmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of a risk assessment.
   */
  async fetchAttachmentLinkForRiskAssessmentRaw(
    requestParameters: FetchAttachmentLinkForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling fetchAttachmentLinkForRiskAssessment.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentLinkForRiskAssessment.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentLinkForRiskAssessment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/riskAssessments/{entityId}/attachments/{attachmentUuid}/session`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of a risk assessment.
   */
  async fetchAttachmentLinkForRiskAssessment(
    requestParameters: FetchAttachmentLinkForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentLinkForRiskAssessmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get the riskAssessment with the specified riskAssessment id.
   * Gets a risk assessment.
   */
  async fetchRiskAssessmentRaw(
    requestParameters: FetchRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RiskAssessmentViewModel>> {
    if (requestParameters.riskAssessmentId === null || requestParameters.riskAssessmentId === undefined) {
      throw new runtime.RequiredError(
        'riskAssessmentId',
        'Required parameter requestParameters.riskAssessmentId was null or undefined when calling fetchRiskAssessment.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchRiskAssessment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/riskAssessments/{riskAssessmentId}`
          .replace(`{${'riskAssessmentId'}}`, encodeURIComponent(String(requestParameters.riskAssessmentId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => RiskAssessmentViewModelFromJSON(jsonValue));
  }

  /**
   * Get the riskAssessment with the specified riskAssessment id.
   * Gets a risk assessment.
   */
  async fetchRiskAssessment(
    requestParameters: FetchRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RiskAssessmentViewModel> {
    const response = await this.fetchRiskAssessmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for a risk assessment.
   */
  async listAttachmentsForRiskAssessmentRaw(
    requestParameters: ListAttachmentsForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AttachmentViewModel>>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling listAttachmentsForRiskAssessment.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listAttachmentsForRiskAssessment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/riskAssessments/{entityId}/attachments`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentViewModelFromJSON));
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for a risk assessment.
   */
  async listAttachmentsForRiskAssessment(
    requestParameters: ListAttachmentsForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AttachmentViewModel>> {
    const response = await this.listAttachmentsForRiskAssessmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Lists versioning for a constitutional document.
   */
  async listRevisionsForRiskAssessmentRaw(
    requestParameters: ListRevisionsForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<RevisionViewModel>>> {
    if (requestParameters.riskAssessmentId === null || requestParameters.riskAssessmentId === undefined) {
      throw new runtime.RequiredError(
        'riskAssessmentId',
        'Required parameter requestParameters.riskAssessmentId was null or undefined when calling listRevisionsForRiskAssessment.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listRevisionsForRiskAssessment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/riskAssessments/{riskAssessmentId}/revisions`
          .replace(`{${'riskAssessmentId'}}`, encodeURIComponent(String(requestParameters.riskAssessmentId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RevisionViewModelFromJSON));
  }

  /**
   * Lists versioning for a constitutional document.
   */
  async listRevisionsForRiskAssessment(
    requestParameters: ListRevisionsForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<RevisionViewModel>> {
    const response = await this.listRevisionsForRiskAssessmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all the riskAssessments within the specified organization.
   * Lists risk assessments.
   */
  async listRiskAssessmentsRaw(
    requestParameters: ListRiskAssessmentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<RiskAssessmentViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listRiskAssessments.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/riskAssessments`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RiskAssessmentViewModelFromJSON));
  }

  /**
   * Get all the riskAssessments within the specified organization.
   * Lists risk assessments.
   */
  async listRiskAssessments(
    requestParameters: ListRiskAssessmentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<RiskAssessmentViewModel>> {
    const response = await this.listRiskAssessmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for a risk assessment.
   */
  async renameAttachmentForRiskAssessmentRaw(
    requestParameters: RenameAttachmentForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling renameAttachmentForRiskAssessment.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling renameAttachmentForRiskAssessment.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling renameAttachmentForRiskAssessment.',
      );
    }

    if (
      requestParameters.attachmentPropertiesUpdateMessage === null ||
      requestParameters.attachmentPropertiesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentPropertiesUpdateMessage',
        'Required parameter requestParameters.attachmentPropertiesUpdateMessage was null or undefined when calling renameAttachmentForRiskAssessment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/riskAssessments/{entityId}/attachments/{attachmentUuid}/properties`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AttachmentPropertiesUpdateMessageToJSON(requestParameters.attachmentPropertiesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for a risk assessment.
   */
  async renameAttachmentForRiskAssessment(
    requestParameters: RenameAttachmentForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameAttachmentForRiskAssessmentRaw(requestParameters, initOverrides);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for a risk assessment.
   */
  async uploadAttachmentForRiskAssessmentRaw(
    requestParameters: UploadAttachmentForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling uploadAttachmentForRiskAssessment.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling uploadAttachmentForRiskAssessment.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling uploadAttachmentForRiskAssessment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.contentLength !== undefined && requestParameters.contentLength !== null) {
      headerParameters['Content-Length'] = String(requestParameters.contentLength);
    }

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.body !== undefined) {
      formParams.append('body', requestParameters.body as any);
    }

    const response = await this.request(
      {
        path: `/organizations/{organizationId}/riskAssessments/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for a risk assessment.
   */
  async uploadAttachmentForRiskAssessment(
    requestParameters: UploadAttachmentForRiskAssessmentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.uploadAttachmentForRiskAssessmentRaw(requestParameters, initOverrides);
  }
}
