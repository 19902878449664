import type {
  ConstitutionalDocumentView,
  ConstitutionalDocumentViewEditItem,
} from 'src/content/constitutional-documents/d-constitutional-document-view.js';
import { fetchDraft, fetchRevisions, toAttachmentItems } from 'src/models/factory-utilities.js';
import { BASE_PATH, EmployeeViewModelStatusEnum } from 'src/store/api';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { getOrganization } from 'src/store/selectors/organization.js';
import {
  employeesNotDeleted,
  getEmployeesWithForAccessControl,
  isCurrentUserEmployee,
} from 'src/store/selectors/organization-employees.js';
import { partnersNotDeleted } from 'src/store/selectors/organization-partners.js';
import { toConstitutionalDocumentViewModel } from 'src/store/selectors/organization-documents.js';
import { currentUserUuid, writeAccess } from 'src/store/selectors/user.js';
import { key } from 'src/store/selectors/selectors';
import { assertIsDefined } from 'src/lib';
import { CommonDataEntityView } from 'src/content/entity-content';

export async function buildConstitutionalDocumentView(
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<ConstitutionalDocumentView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const item = organization.constitutionalDocumentsById[uuid];
  assertIsDefined(item);

  const entity = toConstitutionalDocumentViewModel(item, organization);

  return {
    ...commonData,
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess:
      entity.classification === 'NONE' || (entity.accessControl ?? []).includes(currentUserUuid(state) ?? ''),
    currentUserHasWriteAccess: writeAccess(state),
    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: entity.helpContent ?? '',
    href: currentParent + '/constitutionalDocuments/' + uuid,
    pdfHref:
      BASE_PATH +
      '/organizations/' +
      organization.organizationId +
      '/constitutionalDocuments/' +
      uuid +
      '.pdf?key=' +
      key(state),

    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'constitutionalDocuments',
    name: entity.name ?? '',
    classification: entity.classification ?? 'NONE',
    accessControl: entity.accessControl ?? [],
    accessControlOptions: getEmployeesWithForAccessControl(state).map((e) => ({
      value: e.id,
      text: e.value,
      disabled: e.disabled,
    })),
    content: entity.content ?? '',
    revisions: await fetchRevisions(
      state.token ?? '',
      'constitutionalDocuments',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
    ),
    attachments: toAttachmentItems('constitutionalDocuments', entity.uuid, entity.attachments),
    validFromDate: entity.validFromDate ?? '',
    validToDate: entity.validToDate ?? '',
    contractPartyNames: entity.contractPartyNames ?? '',
    isEmployee: isCurrentUserEmployee(state),
    availablePartners: partnersNotDeleted(state).map((p) => ({ value: p.uuid, text: p.name })),
    availableEmployees: employeesNotDeleted(state)
      .filter((e) => e.status !== EmployeeViewModelStatusEnum.Terminated)
      .map((p) => ({ value: p.uuid, text: p.name })),
    employees: entity.employees ?? [],
    partners: entity.partners ?? [],
    today: LocalDate.fromString(state.today),
    deletable: true,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(state.token ?? '', 'constitutionalDocuments', uuid, organization.organizationId);
      return command.draft as ConstitutionalDocumentViewEditItem;
    },
  };
}
