import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import * as dabihStore from 'src/store';
import { LocalDate } from 'src/utilities/local-date.js';
import '../../library/components/d-popup.js';
import '../../library/editors/components/d-edit-access.js';
import type { UserForAccess } from '../../library/editors/components/d-edit-access.js';
import '../../library/elements/d-wrap.js';
import type { EditAccessInput, EditAccessResult } from 'src/layout/parts/edit-access-dialog.js';
import { EditAccessDialog } from 'src/layout/parts/edit-access-dialog.js';
import type { UpdateAccessCommandAccessLevelEnum } from 'src/store/api';

interface UpdateUserAccessDetail {
  userUuid: string;
  accessLevel: UpdateAccessCommandAccessLevelEnum;
  accessExpires?: string;
  email: string;
  userType: 'EMPLOYEE' | 'CONTACT';
}

export class UpdateUserAccessEvent extends CustomEvent<UpdateUserAccessDetail> {
  constructor(detail: UpdateUserAccessDetail) {
    super('update-user-access', {
      composed: true,
      bubbles: true,
      detail: detail,
    });
  }
}
/**
 *
 * @fires update-user-access
 */
@customElement('d-organization-edit-access')
export class DOrganizationEditAccess extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    d-wrap {
      margin: -12px 0;
      padding: 12px 0;
      cursor: pointer;
    }

    d-wrap:hover {
      background: hsla(0, 0%, 100%, 0.2);
    }
  `;
  @property({ type: Array })
  users!: UserForAccess[];
  @property({ type: Object })
  currentUser!: UserForAccess;
  @property({ type: Object })
  editUser!: UserForAccess;

  @property({ type: String })
  ownerEmail!: string;

  private get displayName() {
    let result = this.editUser.name;
    if (this.editUser.partnerName) {
      result += ' (' + this.editUser.partnerName + ')';
    }
    return result;
  }

  private get accessDisplay() {
    let result = '';
    const profiles = dabihStore.accessProfiles().filter((p) => {
      return p.name === this.editUser.accessLevel;
    });
    if (profiles.length) {
      result = profiles[0].displayName;
    }
    if (this.editUser.accessExpires) {
      result += ' til ' + LocalDate.fromString(this.editUser.accessExpires).toStringForDisplay();
    }
    return result;
  }

  render() {
    return html`
      <d-wrap split @click=${this.onEdit}>
        <div>${this.displayName}</div>
        <div>${this.accessDisplay}</div>
      </d-wrap>
    `;
  }

  private async onEdit(e: Event) {
    e.stopPropagation();
    const input: EditAccessInput = {
      displayName: this.displayName,
      editUser: this.editUser,
      isOwner: this.editUser.email === this.ownerEmail,
      isSelf: this.editUser.uuid === this.currentUser.uuid,
      originalEmail: this.editUser.email,
      otherUserEmails: this.users
        .filter((u) => u.uuid !== this.editUser.uuid)
        .map((u) => u.email)
        .filter((email) => email !== ''),
    };
    const result: EditAccessResult = await EditAccessDialog.open(input);
    if (result.action === 'done') {
      this.dispatchEvent(
        new UpdateUserAccessEvent({
          userUuid: this.editUser.uuid,
          email: result.email,
          accessLevel: result.accessLevel,
          userType: this.isNotEmpty(this.editUser.partnerName) ? 'CONTACT' : 'EMPLOYEE',
          accessExpires: result.accessExpires,
        }),
      );
    }
  }

  private isNotEmpty(n: string | undefined) {
    return n !== '' && n !== undefined;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-edit-access': DOrganizationEditAccess;
  }
}
