import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import '../elements/d-label';
import '../elements/d-action';
import { DListSection } from 'src/library/lists/d-list-section';
import { classMap } from 'lit/directives/class-map.js';
import { LocalDate } from 'src/utilities/local-date';
import { capitalize } from 'src/utilities/text';
import { PropertyValues } from 'lit/development';

export type MeetingStatus = 'NONE' | 'NOTICE_SENT' | 'REPORT_WRITTEN';

export interface Occurrence {
  occurrenceUuid: string;
  href?: string;
  classified: boolean;
  restricted: boolean;
  disabled?: boolean;
  dateTime: string;
  durationMinutes: number;
  displayDate?: string;
  assignees?: string;
  meetingStatus?: MeetingStatus;
  doneDate?: string;
  assetRelated?: boolean;
  assetSelected?: boolean;
}

/**
 *
 * USAGE:
 *    d-view-occurrences
 *    d-list-section-occurrences
 *
 */
@customElement('d-occurrences-list')
export class DOccurrencesList extends LitElement {
  static readonly styles = [
    ...DListSection.styles,
    css`
      :host {
        display: block;
        scroll-snap-type: y mandatory;
      }
      .item {
        display: flex;
        border-top: 1px solid var(--borderColorLight);
        scroll-snap-align: start;
      }
      .item:first-child {
        margin-top: -1px;
      }
      #current.item {
        font-weight: 500;
      }
      .item.disabled {
        opacity: 0.5;
      }
      .item.done {
        padding-left: 30px;
        background: url(/images/check-mini-gray.svg) 0 8px no-repeat;
        background-size: 24px;
      }
      .asset {
        flex: none;
        width: 36px;
        background: url(/images/assets-gray.svg) 0 5px no-repeat;
        background-size: 30px;
        opacity: 0.5;
        cursor: pointer;
      }
      .asset.related {
        opacity: 0.5;
        background-image: url(/images/assets-color.svg);
        cursor: default;
      }
      .asset.selected {
        opacity: 1;
        background-image: url(/images/assets-color.svg);
      }
      @media (hover: hover) {
        .asset:not(.related):hover {
          opacity: 1;
        }
      }
      .delete {
        flex: none;
        width: 36px;
        background: url(/images/x-fat-orange.svg) 12px 8px no-repeat;
        background-size: 24px;
        opacity: 0.5;
        cursor: pointer;
      }
      .disabled .delete {
        background: none;
        cursor: default;
      }
      @media (hover: hover) {
        .delete:hover {
          opacity: 1;
        }
      }
      a,
      .nolink {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        padding: 10px 0;
        line-height: var(--lineHeightDefault);
      }
      a {
        color: var(--linkColorGray);
        text-decoration: none;
        cursor: pointer;
      }
      a[disabled] {
        color: var(--text-color);
        pointer-events: none;
        cursor: default;
      }
      .classified a,
      .classified .nolink {
        padding-left: 30px;
        background: url(/images/locked.svg) 2px 8px no-repeat;
        background-size: 24px auto;
      }
      .nolink {
        color: var(--text-color);
      }
      .name {
        padding-right: 0.3em;
        font-weight: 500;
      }
      .assignee {
        flex-grow: 1;
        text-align: right;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      @media (hover: hover) {
        a:not([disabled]):hover {
          color: black;
        }
      }
    `,
  ];
  @property({ type: String })
  entityName = '';
  @property({ type: Array })
  occurrences: Occurrence[] = [];
  @property({ type: Array })
  assets: string[] = [];
  @property({ type: Boolean })
  edit = false;
  @property({ type: String })
  parentHref = '';
  @property({ type: String })
  currentUuid = '';
  @query('current')
  current!: HTMLElement;

  selectAsset(related, index) {
    if (!related) {
      this.occurrences = this.occurrences.map((o, i) => {
        if (i === index) {
          o.assetSelected = !o.assetSelected;
        }
        return o;
      });
      this.dispatchEvent(
        new CustomEvent('asset-selection-changed', {
          bubbles: true,
          composed: true,
          detail: { value: this.occurrences },
        }),
      );
    }
  }

  delete(dateTime) {
    this.occurrences = this.occurrences.filter((o) => {
      return o.dateTime !== dateTime;
    });
    this.dispatchEvent(
      new CustomEvent('delete', {
        bubbles: true,
        composed: true,
        detail: { value: dateTime },
      }),
    );
  }

  displayDate(o) {
    if (o.displayDate) {
      return o.displayDate;
    }
    let displayDate = 'Snarest';
    if (o.dateTime) {
      displayDate = LocalDate.fromString(o.dateTime.split(' ')[0]).toStringForDisplayWithDayOfWeekAndYear();
      if (o.dateTime.split(' ').length > 1) {
        displayDate += ' ' + o.dateTime.split(' ')[1].replace(':', '.');
      }
    }
    return capitalize(displayDate);
  }

  tellCurrentPosition() {
    const current = this.shadowRoot?.getElementById('current') as DOccurrencesList;
    if (current) {
      const center = current.getBoundingClientRect().top + current.getBoundingClientRect().height / 2;
      this.dispatchEvent(
        new CustomEvent('current-position', {
          bubbles: true,
          composed: true,
          detail: { value: center },
        }),
      );
    }
  }

  disabled(o) {
    if (o.disabled) {
      return true;
    }
    if (!this.edit) {
      return false;
    }
    return (
      (o.doneDate !== undefined && o.doneDate !== null && o.doneDate !== '') ||
      o.meetingStatus === 'NOTICE_SENT' ||
      o.meetingStatus === 'REPORT_WRITTEN'
    );
  }

  renderItemContent(o) {
    return html`
      <span>
        ${this.entityName ? html` <span class="name">${this.entityName}</span> ` : nothing}
        <span class="date">${this.displayDate(o)}</span>
      </span>
      <span class="assignee">${o.assignees}</span>
    `;
  }

  render() {
    return html`
      ${this.occurrences.map((o, index) => {
        return html`
          <div
            id="${o.occurrenceUuid === this.currentUuid ? 'current' : o.occurrenceUuid}"
            class="item ${classMap({
              disabled: this.disabled(o),
              classified: o.classified,
              restricted: o.restricted,
              done: o.doneDate !== undefined && o.doneDate !== null && o.doneDate !== '',
            })}"
          >
            ${this.assets.length
              ? html`
                  <div
                    class="asset ${classMap({
                      related: o.assetRelated === true,
                      selected: o.assetSelected === true,
                    })}"
                    @click=${() => this.selectAsset(o.assetRelated, index)}
                  ></div>
                `
              : nothing}
            ${this.edit
              ? html` <span class="nolink"> ${this.renderItemContent(o)} </span> `
              : html`
                  <a
                    href="${this.parentHref + o.href}"
                    ?disabled=${o.restricted || o.occurrenceUuid === this.currentUuid || this.assets.length > 0}
                  >
                    ${this.renderItemContent(o)}
                  </a>
                `}
            ${this.edit ? html` <div class="delete" @click=${() => this.delete(o.dateTime)}></div> ` : nothing}
          </div>
        `;
      })}
    `;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    if (_changedProperties.has('currentUuid')) {
      setTimeout(() => {
        const currentElm = this.shadowRoot?.getElementById('current') as HTMLElement;
        if (currentElm) {
          const top = currentElm.getBoundingClientRect().top;
          this.dispatchEvent(
            new CustomEvent('current-occurrence-position', {
              bubbles: true,
              composed: true,
              detail: { value: top },
            }),
          );
        }
      }, 0);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-occurrences-list': DOccurrencesList;
  }
}
