import { css, html, nothing } from 'lit';
import 'src/library/elements/d-section';
import 'src/library/fields/d-view-info';
import 'src/library/editors/elements/d-edit-text';
import 'src/library/editors/components/d-edit-access';
import 'src/library/fields/d-spinner-robot';
import { customElement, property } from 'lit/decorators.js';
import { BaseDialog } from 'src/library/components/BaseDialog';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import type { UserForAccess } from 'src/library/editors/components/d-edit-access';
import { CreateEmployeeInput } from 'src/content/meeting-occurrences/d-meeting-occurrence-view';

export interface NewEmployeeDialogInput {
  userEmails: string[];
  create: () => void;
}

export interface CreateEmployeeData {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  accessLevel: string;
  phone: string;
}
export interface NewEmployeeDialogResult {
  action: 'cancel' | 'done';
  employeeData: CreateEmployeeData;
}

/**
 *
 * STATUS OK
 */
@customElement('d-new-employee-dialog')
export class DNewEmployeeDialog extends BaseDialog<NewEmployeeDialogInput, NewEmployeeDialogResult> {
  static readonly styles = [
    BaseDialog.styles[0],
    css`
      .cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: white;
        z-index: 1;
      }
    `,
  ];
  @property({ type: String })
  title = 'Ny person';
  @property({ type: String })
  content = '';
  @property({ type: String })
  uuid = '';
  @property({ type: String })
  firstName = '';
  @property({ type: String })
  lastName = '';
  @property({ type: String })
  email = '';
  @property({ type: Array })
  userEmails: string[] = [];
  @property({ type: String })
  accessLevel = 'NONE';
  @property({ type: String })
  phone = '';
  @property({ type: Number })
  width = 400;
  @property({ type: Boolean })
  spinner = false;

  protected get calculatedFooterActions() {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Ferdig', action: 'done', disabled: this.noName },
    ];
  }

  private get noName(): boolean {
    return isEmptyOrInvalidString(this.firstName) && isEmptyOrInvalidString(this.lastName);
  }

  private get editUser(): UserForAccess {
    return {
      uuid: '',
      email: this.email,
      accessLevel: this.accessLevel,
      accessExpires: '',
      name: '',
      partnerName: '',
    };
  }
  renderBody() {
    return html`
      <d-section>
        <d-view-info
          .content=${`<p>Legg til en ny person i personalet. Personen vil få en epost med innloggingsdetaljer.</p>`}
        ></d-view-info>
        <d-edit-text
          class="minWidth200"
          field="employees_firstName"
          .autofocus=${true}
          .selectOnFocus=${true}
          ?mark-if-empty="${this.noName}"
          .value=${this.firstName}
          @value-changed=${(e) => {
            this.firstName = e.detail.value;
          }}
        ></d-edit-text>
        <d-edit-text
          class="minWidth200"
          field="employees_lastName"
          ?mark-if-empty="${this.noName}"
          .value=${this.lastName}
          @value-changed=${(e) => {
            this.lastName = e.detail.value;
          }}
        ></d-edit-text>
      </d-section>
      <d-edit-access
        field="employees_accessLevel"
        default-access-level="USER"
        .editUser=${this.editUser}
        .isSelf=${false}
        .isOwner=${false}
        .otherUserEmails=${this.userEmails}
        @value-changed=${(e) => {
          this.accessLevel = e.detail.accessLevel;
          this.email = e.detail.email;
        }}
      >
      </d-edit-access>
      <d-section>
        <d-edit-text
          class="minWidth200"
          field="employees_phone"
          .value=${this.phone}
          @value-changed=${(e) => {
            this.phone = e.detail.value;
          }}
        ></d-edit-text>
      </d-section>
      ${this.spinner
        ? html` <div class="cover">
            <d-spinner-robot></d-spinner-robot>
          </div>`
        : nothing}
    `;
  }

  fireCreate() {
    this.uuid = uuid();
    this.dispatchEvent(
      new CustomEvent<CreateEmployeeInput>('create-employee', {
        bubbles: true,
        composed: true,
        detail: {
          data: {
            uuid: this.uuid,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            accessLevel: this.accessLevel,
            phone: this.phone,
          },
          complete: () => void super.onDispatchAction(false, 'done'),
        },
      }),
    );
  }
  protected async onDispatchAction(disabled: boolean, action: string) {
    if (!disabled && action === 'done') {
      this.spinner = true;
      this.fireCreate();
    } else {
      await super.onDispatchAction(disabled, action);
    }
  }

  protected fetchResult(detail: string | undefined): NewEmployeeDialogResult {
    return {
      action: detail === 'done' ? 'done' : 'cancel',
      employeeData: {
        uuid: this.uuid,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        accessLevel: this.accessLevel,
        phone: this.phone,
      },
    };
  }

  protected initializeDialog(input: NewEmployeeDialogInput) {
    this.userEmails = input.userEmails;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-new-employee-dialog': DNewEmployeeDialog;
  }
}
