import { css, html } from 'lit';
import 'src/library/elements/d-smooth-resize';
import 'src/library/lists/d-occurrences-list';
import 'src/library/components/d-calendar-year';
import 'src/library/elements/d-section';
import 'src/library/elements/d-wrap';
import 'src/library/elements/d-action';
import 'src/library/fields/d-expansion';
import 'src/content/event-occurrences/d-create-occurrences';

import { customElement, property, query } from 'lit/decorators.js';
import { BaseDialog } from 'src/library/components/BaseDialog';
import { LocalDate } from 'src/utilities/local-date';
import type { Occurrence } from 'src/library/lists/d-occurrences-list';
import { endYear } from 'src/store';
import { PropertyValues } from 'lit/development';
import { classMap } from 'lit/directives/class-map.js';
import { dateTimeDescription } from 'src/store/utilities';

export interface OccurrencesDialogInput {
  parentUuid: string;
  modelOccurrence: Occurrence;
  occurrences: Occurrence[];
  eventType: string;
  eventName: string;
}
export interface OccurrencesDialogResult {
  occurrences: Occurrence[];
  action: 'cancel' | 'done';
}

/**
 *
 * STATUS OK
 */
@customElement('d-edit-occurrences-dialog')
export class DEditOccurrencesDialog extends BaseDialog<OccurrencesDialogInput, OccurrencesDialogResult> {
  static readonly styles = [
    BaseDialog.styles[0],
    css`
      dialog[open] {
        max-height: calc((var(--vh, 1vh) * 100) - 40px);
      }
      .header-wrapper {
        display: block;
        background: white;
        padding: 0 20px;
        line-height: var(--lineHeightDefault);
        z-index: 2;
      }
      #settings {
        border-bottom: 1px solid var(--borderColor);
      }
      .actions {
        margin: -6px -8px;
      }
      .todo.bordered {
        border-bottom: 1px solid var(--borderColor);
      }
      d-create-occurrences {
        border-bottom-width: 20px;
      }
      #occurrences {
        line-height: 100%;
      }
      d-calendar-year {
        margin: 0;
      }
      .body {
        scroll-behavior: smooth;
      }
    `,
  ];
  @property({ type: String })
  eventType = 'standard';
  @property({ type: String })
  eventName = '';
  @property({ type: String })
  parentUuid = '';
  @property({ type: Object })
  modelOccurrence!: Occurrence;
  @property({ type: Array })
  occurrences: Occurrence[] = [];
  @property({ type: Boolean })
  createOccurencesOpened = false;
  @property({ type: String })
  today = LocalDate.now().toString();
  @property({ type: Array })
  years: number[] = [];
  @property({ type: Boolean })
  showAsList = false;
  @property({ type: Boolean })
  onlyDated = false;
  @property({ type: Boolean })
  onlyRecurring = false;
  @property({ type: Number })
  width = 764;
  @query('.body')
  dialogBodyElm!: HTMLElement;

  protected get calculatedSubtitle(): string {
    let time = '';
    if (this.modelOccurrence.dateTime.split(' ').length > 1) {
      time = this.modelOccurrence.dateTime.split(' ')[1];
    }
    return dateTimeDescription(
      this.modelOccurrence.dateTime.split(' ')[0] ?? '',
      time ?? '',
      this.modelOccurrence.durationMinutes,
    );
  }

  protected get calculatedHeaderActions() {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Ferdig', action: 'done', disabled: this.createOccurencesOpened },
    ];
  }

  private get eventTerm() {
    if (this.eventType === 'meeting') {
      return 'møte';
    }
    return 'oppgave';
  }

  private get toggleCreateOccurrencesText() {
    if (this.showAsList) {
      return this.occurrences.length === 1 ? 'Legg til gjentakelse' : 'Legg til ny gjentakelse';
    }
    return 'Lag regelmessig gjentakelse';
  }

  private get recurrenceTime() {
    if (this.modelOccurrence.dateTime.split(' ').length === 2) {
      return this.modelOccurrence.dateTime.split(' ')[1];
    }
    return '12:00';
  }

  private get recurrenceDuration() {
    if (this.modelOccurrence.durationMinutes) {
      return this.modelOccurrence.durationMinutes;
    }
    return 0;
  }

  private get firstEditableDate() {
    if (this.modelOccurrence.dateTime) {
      const firstDateAfterModel = LocalDate.fromString(this.modelOccurrence.dateTime.split(' ')[0]).plusDays(1);
      if (firstDateAfterModel.isSameOrBefore(LocalDate.fromString(this.today))) {
        return this.today;
      }
      return firstDateAfterModel.toString();
    }
    return LocalDate.fromString(this.today).plusDays(1).toString();
  }

  private get recurrenceStartDate() {
    if (this.modelOccurrence.dateTime) {
      return this.modelOccurrence.dateTime.split(' ')[0];
    }
    return this.firstEditableDate;
  }

  private get recurrence() {
    if (this.showAsList) {
      return '';
    }
    return (
      'FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=' +
      LocalDate.fromString(this.recurrenceStartDate).day() +
      ';UNTIL=' +
      endYear +
      '1231'
    );
  }

  private get todoOcurrences(): Occurrence[] {
    return this.occurrences
      .filter((o) => {
        return o.dateTime === '';
      })
      .map((o) => {
        return { ...o, disabled: true };
      });
  }

  private get datedOccurrences(): Occurrence[] {
    const modelOccurrenceDate = LocalDate.fromString(this.modelOccurrence.dateTime.split(' ')[0]);
    return this.occurrences
      .filter((o) => {
        return o.dateTime !== '';
      })
      .map((o) => {
        if (
          LocalDate.fromString(o.dateTime.split(' ')[0]).isSameOrBefore(modelOccurrenceDate) ||
          this.isDone(o) ||
          o.restricted
        ) {
          o.disabled = true;
        }
        return o;
      });
  }

  private get hasFutureDeletableOccurrence() {
    return (
      this.datedOccurrences.filter((o) => {
        return (
          LocalDate.fromString(o.dateTime.split(' ')[0]).isSameOrAfter(LocalDate.fromString(this.firstEditableDate)) &&
          !o.disabled
        );
      }).length > 0
    );
  }

  setYears() {
    let startYear = Number(this.today.split('-')[0]);
    if (this.datedOccurrences.length && this.datedOccurrences[0].dateTime) {
      const firstEventYear = LocalDate.fromString(this.datedOccurrences[0].dateTime.split(' ')[0]).year();
      if (firstEventYear < startYear) {
        startYear = firstEventYear;
      }
    }
    const years: number[] = [];
    for (let y = startYear; y <= endYear; y++) {
      years.push(y);
    }
    this.years = years;
  }

  tellMonthPosition(year) {
    let startDate = LocalDate.fromString(this.firstEditableDate);
    if (startDate.isBefore(LocalDate.now())) {
      startDate = LocalDate.now();
    }
    return year === startDate.year() ? startDate.month() : 0;
  }

  scrollToCurrent(e) {
    e.stopPropagation();
    const dialogBodyTop = this.dialogBodyElm.getBoundingClientRect().top;
    this.dialogBodyElm.scrollBy(0, e.detail.value - dialogBodyTop + 1);
  }

  toggleView() {
    this.showAsList = !this.showAsList;
    this.onlyRecurring = !this.showAsList;
  }

  toggleCreateOccurrences() {
    this.createOccurencesOpened = !this.createOccurencesOpened;
  }

  removeFutureOccurrences() {
    const firstEditableDate = LocalDate.fromString(this.firstEditableDate);
    this.occurrences = this.occurrences.filter((o) => {
      if (o.dateTime) {
        return LocalDate.fromString(o.dateTime.split(' ')[0]).isBefore(firstEditableDate) || !this.isDeletable(o);
      }
      return true;
    });
  }

  onOccurrencesCreated(e) {
    e.stopPropagation();
    const occurrences = e.detail.value;
    const newOccurrences = [...this.occurrences];
    occurrences.forEach((o) => {
      const existingOccurrence = this.occurrences.find((e) => {
        return e.dateTime.split(' ')[0] === o.split(' ')[0];
      });
      if (existingOccurrence === undefined) {
        const s = o.split(' ');
        const dateTime = s.length === 1 ? s[0] : s[0] + ' ' + s[1];
        newOccurrences.push({
          occurrenceUuid: '',
          dateTime: dateTime,
          durationMinutes: s.length === 3 ? parseInt(s[2]) : 0,
          classified: this.modelOccurrence.classified,
          restricted: this.modelOccurrence.restricted,
          assignees: this.modelOccurrence.assignees,
        });
      }
    });
    this.occurrences = newOccurrences;
    this.createOccurencesOpened = false;
  }

  newOccurrence(date: string) {
    let dateTime = date;
    if (this.modelOccurrence.dateTime.split(' ').length === 2) {
      dateTime = date + ' ' + this.modelOccurrence.dateTime.split(' ')[1];
    }
    return {
      occurrenceUuid: '',
      dateTime,
      durationMinutes: this.modelOccurrence.durationMinutes,
      classified: this.modelOccurrence.classified,
      restricted: this.modelOccurrence.restricted,
      assignees: this.modelOccurrence.assignees,
    };
  }

  onDeleteFromList(e) {
    this.toggleOccurrence(e.detail.value);
  }

  toggleOccurrence(dateTime) {
    const existing = this.occurrences.find((o) => {
      return o.dateTime === dateTime;
    });
    if (existing) {
      this.occurrences = this.occurrences.filter((o) => {
        return o.dateTime !== dateTime;
      });
    } else {
      const occurrences = [...this.occurrences];
      occurrences.push(this.newOccurrence(dateTime.split(' ')[0]));
      this.occurrences = occurrences;
    }
  }

  onDayClicked(e) {
    e.preventDefault();
    e.stopPropagation();
    const dateTime = e.detail.value;
    const dayOccurrences = this.occurrences.filter((o) => {
      if (o.dateTime) {
        return o.dateTime.split(' ')[0] === dateTime.split(' ')[0];
      }
      return false;
    });
    if (dayOccurrences.length === 0) {
      this.toggleOccurrence(dateTime);
    } else if (dayOccurrences.length === 1) {
      this.toggleOccurrence(dayOccurrences[0].dateTime);
    }
  }

  onOccurrenceClicked(e) {
    e.preventDefault();
    e.stopPropagation();
    this.toggleOccurrence(e.detail.value);
  }

  yearOccurrences(year) {
    return this.datedOccurrences.filter((e) => {
      return e.dateTime.split('-')[0] === year + '';
    });
  }

  renderBody() {
    return html`
      <d-smooth-resize>
        ${this.showAsList
          ? html`
              <d-occurrences-list
                edit
                .occurrences=${this.datedOccurrences}
                .currentUuid=${this.modelOccurrence.occurrenceUuid}
                @current-occurrence-position=${(e) => this.scrollToCurrent(e)}
                @delete=${(e) => this.onDeleteFromList(e)}
              ></d-occurrences-list>
            `
          : html`
              <div id="calendar">
                ${this.years.map((year, index) => {
                  return html`
                    <d-calendar-year
                      edit
                      id="${'year-index-' + index}"
                      .year=${year}
                      .occurrences=${this.yearOccurrences(year)}
                      .eventTerm=${this.eventTerm}
                      .disableBefore=${this.firstEditableDate}
                      .tellMonthPosition=${this.tellMonthPosition(year)}
                      @current-month-position=${(e) => this.scrollToCurrent(e)}
                      @day-clicked=${(e) => this.onDayClicked(e)}
                      @occurrence-clicked=${(e) => this.onOccurrenceClicked(e)}
                    ></d-calendar-year>
                  `;
                })}
              </div>
            `}
      </d-smooth-resize>
    `;
  }

  protected renderPostHeader() {
    return html`
      <div class="header-wrapper">
        <div id="settings">
          <d-section>
            <d-wrap tight class="actions">
              <d-action plain @click=${() => this.toggleView()}
                >${this.showAsList ? 'Vis som kalender' : 'Vis som liste'}</d-action
              >
              <d-action plain @click=${() => this.toggleCreateOccurrences()}
                >${this.toggleCreateOccurrencesText}</d-action
              >
              <d-action
                plain
                ?disabled="${!this.hasFutureDeletableOccurrence}"
                @click=${() => this.removeFutureOccurrences()}
                >Slett fremtidige</d-action
              >
            </d-wrap>
          </d-section>
          <d-expansion .opened=${this.createOccurencesOpened}>
            <d-section>
              <d-create-occurrences
                .label=${this.occurrences.length === 1 ? 'Gjentakelse' : 'Ny gjentakelse'}
                .onlyDated=${this.onlyDated}
                .onlyRecurring=${!this.showAsList}
                .startDate=${this.recurrenceStartDate}
                .recurrence=${this.recurrence}
                .recurrenceTime=${this.recurrenceTime}
                .recurrenceDuration=${this.recurrenceDuration}
                @done=${(e) => this.onOccurrencesCreated(e)}
                @cancel=${() => this.toggleCreateOccurrences()}
              ></d-create-occurrences>
            </d-section>
          </d-expansion>
        </div>
        <div
          class="todo ${classMap({
            bordered: this.todoOcurrences.length > 0,
          })}"
        >
          <d-smooth-resize>
            <d-occurrences-list
              edit
              .occurrences=${this.todoOcurrences}
              .currentUuid=${this.modelOccurrence.occurrenceUuid}
              @delete=${(e) => this.onDeleteFromList(e)}
            ></d-occurrences-list>
          </d-smooth-resize>
        </div>
      </div>
    `;
  }

  protected willUpdate(_changedProperties: PropertyValues) {
    super.willUpdate(_changedProperties);
    if (_changedProperties.has('modelOccurrence')) {
      this.setYears();
    }
  }

  protected initializeDialog(input: OccurrencesDialogInput) {
    this.modelOccurrence = input.modelOccurrence;
    this.parentUuid = input.parentUuid;
    this.occurrences = input.occurrences;
    this.eventType = input.eventType;
    this.eventName = input.eventName;
    if (this.eventType === 'standard') {
      this.showAsList = true;
      this.onlyDated = false;
    }
    if (this.eventType === 'meeting') {
      this.showAsList = false;
      this.onlyDated = true;
    }
    if (this.eventType === 'meeting') {
      if (this.occurrences.length === 1) {
        this.title = 'Gjenta ' + this.eventName;
      } else {
        this.title = 'Rediger møteserien ' + this.eventName;
      }
    } else {
      if (this.occurrences.length === 1) {
        this.title = 'Gjenta oppgaven ' + this.eventName;
        this.createOccurencesOpened = true;
      } else {
        this.title = 'Rediger gjentakelse av ' + this.eventName;
      }
    }
  }

  protected fetchResult(detail: string | undefined): OccurrencesDialogResult {
    return {
      occurrences: detail === 'done' ? this.occurrences : [],
      action: detail === 'done' ? 'done' : 'cancel',
    };
  }

  private isDone(o) {
    return o.doneDate !== undefined && o.doneDate !== null && o.doneDate !== '';
  }

  private isDeletable(occurrence: Occurrence) {
    return (
      !occurrence.restricted &&
      !occurrence.disabled &&
      occurrence.meetingStatus !== 'NOTICE_SENT' &&
      occurrence.meetingStatus !== 'REPORT_WRITTEN'
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-occurrences-dialog': DEditOccurrencesDialog;
  }
}
