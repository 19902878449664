import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 *
 *
 * @fires checked-changed - Dispatched immediately after changes by the user
 *
 * STATUS OK
 */
@customElement('d-radio-cell')
export class DRadioCell extends LitElement {
  static readonly styles = css`
    :host {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;
      background: var(--inputElementBackground);
      box-shadow: var(--inputElementShadow);
    }

    :host([checked]):before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: var(--themeColor);
    }
  `;
  /**
   * Allow this radio option to be deselected. Normally a selected radio option is only deselected when another option is selected.
   */
  @property({ type: Boolean })
  deselectable = false;
  @property({ type: Boolean, reflect: true })
  checked = false;
  @property({ type: String })
  name = '';

  constructor() {
    super();
    this.addEventListener('click', () => this._toggle());
  }

  _toggle() {
    if (!this.checked || this.deselectable) {
      this.checked = !this.checked;
      this.dispatchEvent(
        new CustomEvent('checked-changed', {
          bubbles: true,
          composed: true,
          detail: { checked: this.checked, name: this.name },
        }),
      );
    }
  }

  render() {
    return html``;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-radio-cell': DRadioCell;
  }
}
