/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';
import type { RelatedItemViewModel } from './RelatedItemViewModel';
import {
  RelatedItemViewModelFromJSON,
  RelatedItemViewModelFromJSONTyped,
  RelatedItemViewModelToJSON,
} from './RelatedItemViewModel';

/**
 *
 * @export
 * @interface TaskViewModel
 */
export interface TaskViewModel {
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  situation?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  procedures?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  uuid: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TaskViewModel
   */
  assets: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof TaskViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  draftName?: string;
  /**
   *
   * @type {boolean}
   * @memberof TaskViewModel
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  functionUuid: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  responsibleFunctionUuid?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  asset?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  employee?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  rrule?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  helpContent?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  templateUpdated?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  templateDeclined?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  templateDeleted?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  templateDeletedMessage?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  contentLastModified?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  contentLastModifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  smartTaskEvent?: string;
  /**
   *
   * @type {boolean}
   * @memberof TaskViewModel
   */
  contentLastModifiedWasCreate?: boolean;
  /**
   *
   * @type {number}
   * @memberof TaskViewModel
   */
  mainTemplateId?: number;
  /**
   * The pageId of the template. Null if item is not attached to a template.
   * @type {number}
   * @memberof TaskViewModel
   */
  templateId?: number;
  /**
   *
   * @type {boolean}
   * @memberof TaskViewModel
   */
  hasTemplateUpdate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TaskViewModel
   */
  assetSelection: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TaskViewModel
   */
  hasTemplateDelete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TaskViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TaskViewModel
   */
  locked?: boolean;
  /**
   *
   * @type {string}
   * @memberof TaskViewModel
   */
  lastExecuted?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof TaskViewModel
   */
  pages?: Array<number>;
  /**
   *
   * @type {Array<RelatedItemViewModel>}
   * @memberof TaskViewModel
   */
  relatedItems?: Array<RelatedItemViewModel>;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof TaskViewModel
   */
  attachments?: Array<AttachmentViewModel>;
}

/**
 * Check if a given object implements the TaskViewModel interface.
 */
export function instanceOfTaskViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'assets' in value;
  isInstance = isInstance && 'hasDraft' in value;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'functionUuid' in value;
  isInstance = isInstance && 'assetSelection' in value;

  return isInstance;
}

export function TaskViewModelFromJSON(json: any): TaskViewModel {
  return TaskViewModelFromJSONTyped(json, false);
}

export function TaskViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: !exists(json, 'type') ? undefined : json['type'],
    situation: !exists(json, 'situation') ? undefined : json['situation'],
    procedures: !exists(json, 'procedures') ? undefined : json['procedures'],
    name: !exists(json, 'name') ? undefined : json['name'],
    uuid: json['uuid'],
    assets: json['assets'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    isConfirmedEntity: json['isConfirmedEntity'],
    functionUuid: json['functionUuid'],
    responsibleFunctionUuid: !exists(json, 'responsibleFunctionUuid') ? undefined : json['responsibleFunctionUuid'],
    asset: !exists(json, 'asset') ? undefined : json['asset'],
    employee: !exists(json, 'employee') ? undefined : json['employee'],
    rrule: !exists(json, 'rrule') ? undefined : json['rrule'],
    helpContent: !exists(json, 'helpContent') ? undefined : json['helpContent'],
    templateUpdated: !exists(json, 'templateUpdated') ? undefined : json['templateUpdated'],
    templateDeclined: !exists(json, 'templateDeclined') ? undefined : json['templateDeclined'],
    templateDeleted: !exists(json, 'templateDeleted') ? undefined : json['templateDeleted'],
    templateDeletedMessage: !exists(json, 'templateDeletedMessage') ? undefined : json['templateDeletedMessage'],
    contentLastModified: !exists(json, 'contentLastModified') ? undefined : json['contentLastModified'],
    contentLastModifiedBy: !exists(json, 'contentLastModifiedBy') ? undefined : json['contentLastModifiedBy'],
    smartTaskEvent: !exists(json, 'smartTaskEvent') ? undefined : json['smartTaskEvent'],
    contentLastModifiedWasCreate: !exists(json, 'contentLastModifiedWasCreate')
      ? undefined
      : json['contentLastModifiedWasCreate'],
    mainTemplateId: !exists(json, 'mainTemplateId') ? undefined : json['mainTemplateId'],
    templateId: !exists(json, 'templateId') ? undefined : json['templateId'],
    hasTemplateUpdate: !exists(json, 'hasTemplateUpdate') ? undefined : json['hasTemplateUpdate'],
    assetSelection: json['assetSelection'],
    hasTemplateDelete: !exists(json, 'hasTemplateDelete') ? undefined : json['hasTemplateDelete'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    locked: !exists(json, 'locked') ? undefined : json['locked'],
    lastExecuted: !exists(json, 'lastExecuted') ? undefined : json['lastExecuted'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    relatedItems: !exists(json, 'relatedItems')
      ? undefined
      : (json['relatedItems'] as Array<any>).map(RelatedItemViewModelFromJSON),
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function TaskViewModelToJSON(value?: TaskViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    situation: value.situation,
    procedures: value.procedures,
    name: value.name,
    uuid: value.uuid,
    assets: value.assets,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    isConfirmedEntity: value.isConfirmedEntity,
    functionUuid: value.functionUuid,
    responsibleFunctionUuid: value.responsibleFunctionUuid,
    asset: value.asset,
    employee: value.employee,
    rrule: value.rrule,
    helpContent: value.helpContent,
    templateUpdated: value.templateUpdated,
    templateDeclined: value.templateDeclined,
    templateDeleted: value.templateDeleted,
    templateDeletedMessage: value.templateDeletedMessage,
    contentLastModified: value.contentLastModified,
    contentLastModifiedBy: value.contentLastModifiedBy,
    smartTaskEvent: value.smartTaskEvent,
    contentLastModifiedWasCreate: value.contentLastModifiedWasCreate,
    mainTemplateId: value.mainTemplateId,
    templateId: value.templateId,
    hasTemplateUpdate: value.hasTemplateUpdate,
    assetSelection: value.assetSelection,
    hasTemplateDelete: value.hasTemplateDelete,
    deleted: value.deleted,
    locked: value.locked,
    lastExecuted: value.lastExecuted === undefined ? undefined : value.lastExecuted,
    pages: value.pages,
    relatedItems:
      value.relatedItems === undefined ? undefined : (value.relatedItems as Array<any>).map(RelatedItemViewModelToJSON),
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
