import { css, html, LitElement } from 'lit';
import '../../library/elements/d-wrap';
import '../../library/elements/d-label.js';
import '../../library/editors/elements/d-select-dropdown.js';
import { DayOfWeek, LocalDate } from 'src/utilities/local-date.js';
import { customElement, property } from 'lit/decorators.js';
import { RotationEmployeeWeek } from 'src/content/functions/d-edit-rotation';

/**
 *
 * STATUS OK
 */
@customElement('d-view-rotation-schedule')
export class DViewRotationSchedule extends LitElement {
  static readonly styles = css`
    .table-wrapper {
      margin-top: 12px;
      max-width: calc(100vw - 42px);
      overflow-x: scroll;
    }
    table {
      border-collapse: collapse;
    }
    table td {
      border: 1px solid #d7d7d7;
      padding: 6px;
      width: 14%;
      vertical-align: top;
    }
    table td .date {
      color: rgba(0, 0, 0, 0.5);
    }
    d-select-dropdown {
      flex: none;
    }
  `;
  @property({ type: Number })
  count = 1;
  @property({ type: Array })
  employees: { uuid: string; firstName: string; lastName: string }[] = [];
  @property({ type: String })
  rotationType = 'R-1';
  /**
   * The days array is 7 entries of true/false for each day of week?
   */
  @property({ type: Array })
  rotations: RotationEmployeeWeek[] = [];
  @property({ type: Array })
  selectedEmployees: string[] = [];
  @property({ type: Object })
  today!: LocalDate;
  private displayPeriodOptions = [
    { value: '1', text: 'Vis denne uken' },
    { value: '2', text: 'Vis neste to uker' },
    { value: '4', text: 'Vis neste fire uker' },
    { value: '8', text: 'Vis neste åtte uker' },
    { value: '16', text: 'Vis neste seksten uker' },
    { value: '26', text: 'Vis neste halvår' },
  ];

  listItems() {
    let currentDate = this.today.withPreviousOrSame(DayOfWeek.MONDAY);
    if (!this.rotationType) {
      this.rotationType = 'R-1';
    }
    const weeks = parseInt(this.rotationType.split('-')[1], 10);
    const v: { index: number; days: { dateDisplay: string; name: string }[] }[] = [];
    for (let i = 0; i < this.count; i++) {
      const days: { dateDisplay: string; name: string }[] = [];
      for (let j = 0; j < 7; j++) {
        const n = this.nameForDate(currentDate, weeks);
        days.push({
          dateDisplay: currentDate.toStringForDisplay(),
          name: n,
        });
        currentDate = currentDate.plusDays(1);
      }
      v.push({
        index: i,
        days: days,
      });
    }
    return v;
  }

  findItem(id) {
    if (id && this.employees) {
      const v = this.employees.filter(function (e) {
        return e.uuid === id;
      });
      if (v.length > 0) {
        return v[0];
      }
      return undefined;
    }
    return undefined;
  }

  employeeName(employeeId) {
    const e = this.findItem(employeeId);
    return e === undefined ? '' : e.firstName + ' ' + e.lastName;
  }

  nameForDate(currentDate: LocalDate, weeks: number): string {
    let name = '';
    this.selectedEmployees.forEach((e) => {
      if (this.isAssigned(e, currentDate, weeks)) {
        name = this.employeeName(e);
      }
    });
    return name;
  }

  isAssigned(employeeId: string, date: LocalDate, weeks: number) {
    if (!this.rotations) {
      return false;
    }

    const rotationsForEmployee = this.rotations.filter((r) => r.employee === employeeId);
    const d = date.withPreviousOrSame(DayOfWeek.MONDAY);
    const s1 = d;
    const rotationsForEmployeeAndWeek = rotationsForEmployee.filter((r) => {
      //var weekIndex = d.diff(thisWeek,'weeks');
      const s2 = LocalDate.fromString(r.startDate);

      const weekOffset = Math.floor(s2.until(s1) / 7);
      return Math.abs(weekOffset % weeks) === 0;
    });
    if (rotationsForEmployeeAndWeek.length === 1) {
      const rotation = rotationsForEmployeeAndWeek[0];
      const dayNumber = date.dayOfWeek().valueOf() + 1;
      const key = 'day' + dayNumber;
      return rotation[key] === '1';
    }
    return false;
  }

  render() {
    const rowItems = this.listItems();
    return html`
      <d-wrap split>
        <d-label field="functions_rotations"></d-label>
        <d-select-dropdown
          controller
          .value=${'' + this.count}
          .options=${this.displayPeriodOptions}
          @value-changed=${(e) => {
            this.count = Number(e.detail.value);
          }}
        >
          </div>
      </d-wrap>
      <div class="table-wrapper">
        <table cellpadding="0" cellspacing="0" id="weeks" role="presentation">
          <tbody>
            ${rowItems.map((r) => this.renderRow(r))}
          </tbody>
        </table>
      </div>
    `;
  }

  private renderRow(r: { index: number; days: { dateDisplay: string; name: string }[] }) {
    return html`<tr>
      ${r.days.map((day) => {
        return html`<td>
          <div class="date"><span>${day.dateDisplay}</span></div>
          <div class="name"><span>${day.name}</span></div>
        </td>`;
      })}
    </tr>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-rotation-schedule': DViewRotationSchedule;
  }
}
