import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 * Wraps a row of elements. This is a flexbox container that wraps the elements under each other when the width is smaller.
 *
 *
 * STATUS OK
 */
@customElement('d-wrap')
export class DWrap extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    div {
      display: flex;
      flex-wrap: wrap;
      margin: -6px;
    }

    :host([nowrap]) div {
      flex-wrap: nowrap;
    }

    :host([tight]) div {
      margin: -3px;
    }

    :host([baseline]) div {
      align-items: baseline;
    }

    :host([center]) div {
      align-items: center;
    }

    :host([right]) div {
      justify-content: flex-end;
    }

    :host([split]) div,
    :host([split-reverse]) div {
      justify-content: space-between;
      align-items: baseline;
    }

    :host([split-reverse]) div {
      flex-direction: row-reverse;
    }

    ::slotted(*) {
      margin: 6px;
    }

    ::slotted(d-action) {
      margin-right: -2px;
      margin-left: -2px;
    }

    :host([tight]) ::slotted(*) {
      margin: 3px;
    }

    :host([split]) ::slotted(*),
    :host([split-reverse]) ::slotted(*) {
      flex-grow: 1;
    }

    :host([split]) ::slotted(*:first-child),
    :host([split-reverse]) ::slotted(*:last-child) {
      text-align: left;
    }

    :host([split]) ::slotted(*:last-child),
    :host([split-reverse]) ::slotted(*:first-child) {
      text-align: right;
    }

    :host([align]) ::slotted(d-label),
    :host([align]) ::slotted(d-view-text) {
      padding-top: 5px;
    }

    ::slotted(d-inline[tight]) {
      margin-left: 0;
      margin-right: 0;
    }
  `;
  @property({ type: Boolean, reflect: true })
  tight = false;
  @property({ type: Boolean, reflect: true })
  baseline = false;
  @property({ type: Boolean, reflect: true })
  align = false;
  @property({ type: Boolean, reflect: true })
  center = false;
  @property({ type: Boolean, reflect: true })
  right = false;
  @property({ type: Boolean, reflect: true })
  split = false;
  @property({ type: Boolean, reflect: true, attribute: 'split-reverse' })
  splitReverse = false;
  @property({ type: Boolean, reflect: true })
  nowrap = false;

  render() {
    return html`<div><slot></slot></div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-wrap': DWrap;
  }
}
