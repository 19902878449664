import { css, html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-edit-data-item-application.js';
import './d-edit-data-item-asset.js';
import '../../../../library/editors/elements/d-add-remove-buttons.js';
import type { InfosecCategory } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { BaseListWithAddAndRemove } from 'src/library/editors/components/base-list-with-add-and-remove.js';
import type { ApplicationDataItem } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-application.js';

/**
 * Given a data type and a category, renders filtered the data items. The data items are rendered using
 * different elements based on the category. This element does not handle the 'accounting' or 'emailAccounts' categories.
 *
 * The element also handles add / remove options for the data items
 *
 * @fires data-items-removed
 *
 * The other events are fired by the individual editing elements.
 *
 *
 */
@customElement('d-edit-data-items-application')
export class DEditDataItemsApplication extends BaseListWithAddAndRemove<ApplicationDataItem> {
  static readonly styles = [
    ...BaseListWithAddAndRemove.styles,
    css`
      :host {
        display: block;
      }
      d-edit-data-item-application {
        display: block;
        box-sizing: border-box;
        border: 1px solid var(--borderColorOnGray);
        border-radius: 12px;
        background-color: var(--map-element-background-color);
        padding: 8px 12px 4px 12px;
      }
    `,
  ];
  @property({ type: Object })
  category!: InfosecCategory;
  @property({ type: Array })
  computers: SelectDropdownOption[] = [];
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];

  renderExistingItem(item: ApplicationDataItem): TemplateResult<1> {
    return html`
      <d-edit-data-item-application
        light-label
        small-label
        .dataItem=${item}
        .computers=${this.computers}
        .partners=${this.partners}
        .applicationPlaceholder=${this.category.applicationPlaceholder ?? ''}
        .applicationAddText=${this.category.applicationAddText ?? ''}
        .applicationLabel=${this.category.label ?? ''}
        .availableApplicationOptions=${this.category.applicationOptions ?? []}
      ></d-edit-data-item-application>
    `;
  }

  renderNewItem(): TemplateResult<1> {
    return html`
      <d-edit-data-item-application
        light-label
        small-label
        .computers=${this.computers}
        .partners=${this.partners}
        .applicationPlaceholder=${this.category.applicationPlaceholder ?? ''}
        .applicationAddText=${this.category.applicationAddText ?? ''}
        .applicationLabel=${this.category.label ?? ''}
        .availableApplicationOptions=${this.category.applicationOptions ?? []}
        @add-data-item=${() => {
          this.addingItem = false;
        }}
      ></d-edit-data-item-application>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-data-items-application': DEditDataItemsApplication;
  }
}
