import { css, html, nothing } from 'lit';
import '../../library/elements/d-label.js';
import '../../library/editors/components/d-select-time.js';
import '../../library/editors/elements/d-select-date.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/editors/elements/d-checkbox.js';
import '../../library/fields/d-inline.js';
import '../../library/elements/d-wrap.js';
import './d-edit-work-weeks.js';
import { customElement, property } from 'lit/decorators.js';
import { ResponsiveTable } from 'src/library/abstracts/responsive-table.js';
import { produce } from 'immer';
import { defaultWorkWeek, timeInMinutes } from 'src/store';
import { type WorkSchedule, WorkScheduleRestPeriodEnum, type WorkScheduleWeek } from 'src/store/api';
import { LocalDate } from 'src/utilities/local-date.js';
import { minutesToTime } from 'src/utilities/text.js';

/**
 *
 */
@customElement('d-edit-work-schedule')
export class DEditWorkSchedule extends ResponsiveTable {
  static readonly styles = [
    ...ResponsiveTable.styles,
    css`
      :host(.wrap) d-edit-work-weeks {
        margin-top: 10px;
      }
    `,
  ];

  @property({ type: Object })
  schedule!: WorkSchedule;
  @property({ type: Array })
  schedules: WorkSchedule[] = [];
  private typeOptions = [
    { value: '1', text: 'Én ukes syklus' },
    { value: '2', text: 'To ukers syklus' },
    { value: '3', text: 'Tre ukers syklus' },
    { value: '4', text: 'Fire ukers syklus' },
    { value: '5', text: 'Fem ukers syklus' },
    { value: '6', text: 'Seks ukers syklus' },
    { value: '7', text: 'Syv ukers syklus' },
    { value: '8', text: 'Åtte ukers syklus' },
    { value: '9', text: 'Ni ukers syklus' },
    { value: '10', text: 'Ti ukers syklus' },
    { value: '11', text: 'Elleve ukers syklus' },
    { value: '12', text: 'Tolv ukers syklus' },
  ];
  private workHoursTypeOptions = [
    { value: 'default', text: 'Fast' },
    { value: 'custom', text: 'Varierende' },
  ];
  private breakOptions = [
    { value: WorkScheduleRestPeriodEnum.None, text: 'Ingen' },
    { value: WorkScheduleRestPeriodEnum.WithPay, text: '30 min betalt' },
    { value: WorkScheduleRestPeriodEnum.WithoutPay, text: '30 min ubetalt' },
  ];
  private holidaysOption = 'Fri på offentlige fridager';

  private get weeksCountValue() {
    return this.schedule.workWeeks.length + '';
  }

  private get isDefaultWorkHours() {
    return !this.schedule.workHoursDefinedPerDay;
  }

  private get workHoursType() {
    return this.schedule.workHoursDefinedPerDay ? 'custom' : 'default';
  }

  private get workDayStart() {
    return this.schedule.workDayStartTime || '00:00';
  }
  private get workDayEnd() {
    return this.schedule.workDayEndTime || '00:00';
  }

  private get disabledPeriods() {
    return this.schedules
      .filter((s) => {
        return s.start !== this.schedule.start;
      })
      .map((s) => {
        return {
          start: LocalDate.fromString(s.start),
          end: LocalDate.fromString(s.start),
        };
      });
  }

  renderTableContent() {
    return html`
      <tr>
        <td>
          <d-label label="Startdato"></d-label>
        </td>
        <td>
          <d-label label="Startdato"></d-label>
          <d-select-date
            .value=${this.schedule.start}
            .disabledPeriods=${this.disabledPeriods}
            @value-changed=${this.onStartChanged}
          ></d-select-date>
        </td>
      </tr>
      <tr>
        <td>
          <d-label label="Type"></d-label>
        </td>
        <td>
          <d-label label="Type"></d-label>
          <d-select-dropdown
            .options=${this.typeOptions}
            .value=${this.weeksCountValue}
            @value-changed=${this.onWeekCountChanged}
          ></d-select-dropdown>
        </td>
      </tr>
      <tr>
        <td>
          <d-label label="Arbeidstid"></d-label>
        </td>
        <td>
          <d-label label="Arbeidstid"></d-label>
          <d-wrap>
            <d-select-dropdown
              .options=${this.workHoursTypeOptions}
              .value=${this.workHoursType}
              @value-changed=${this.onWorkHoursTypeChanged}
            ></d-select-dropdown>
            ${this.isDefaultWorkHours
              ? html`
                  <d-wrap>
                    <d-inline>fra</d-inline>
                    <d-select-time
                      max="23:50"
                      .value=${this.workDayStart}
                      @value-changed=${this.onWorkDayStartChanged}
                    ></d-select-time>
                    <d-inline>til</d-inline>
                    <d-select-time
                      min="00:05"
                      .value=${this.workDayEnd}
                      @value-changed=${this.onWorkDayEndChanged}
                    ></d-select-time>
                  </d-wrap>
                `
              : nothing}
          </d-wrap>
        </td>
      </tr>
      <tr>
        <td>
          <d-label label="Spisepause"></d-label>
        </td>
        <td>
          <d-label label="Spisepause"></d-label>
          <d-wrap>
            <d-select-dropdown
              .options=${this.breakOptions}
              .value=${this.schedule.restPeriod}
              @value-changed=${this.onBreakChanged}
            ></d-select-dropdown>
            <d-checkbox
              .optionLabel=${this.holidaysOption}
              ?checked=${this.schedule.holidaysAreTimeOff}
              @checked-changed=${this.onHolidaysChanged}
            ></d-checkbox>
          </d-wrap>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div></div>
          <d-edit-work-weeks
            .workWeeks=${this.schedule.workWeeks}
            .workHoursType=${this.workHoursType}
            @work-weeks-changed=${this.onWorkWeeksChanged}
          ></d-edit-work-weeks>
        </td>
      </tr>
    `;
  }

  private onWorkWeeksChanged(e: CustomEvent<{ workWeeks: WorkScheduleWeek[] }>) {
    e.stopPropagation();
    this.schedule = produce(this.schedule, (draft) => {
      draft.workWeeks = e.detail.workWeeks;
    });
    this.fireScheduleChanged();
  }

  private onHolidaysChanged(e: CustomEvent<{ checked: boolean }>) {
    e.stopPropagation();
    this.schedule = produce(this.schedule, (draft) => {
      draft.holidaysAreTimeOff = e.detail.checked;
    });
    this.fireScheduleChanged();
  }

  private onBreakChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.schedule = produce(this.schedule, (draft) => {
      draft.restPeriod = e.detail.value as WorkScheduleRestPeriodEnum;
    });
    this.fireScheduleChanged();
  }

  private onStartChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.schedule = produce(this.schedule, (draft) => {
      draft.start = e.detail.value;
    });
    this.fireScheduleChanged();
  }

  private setWorkDayStart(value) {
    this.schedule = produce(this.schedule, (draft) => {
      draft.workDayStartTime = value;
      if (!draft.workHoursDefinedPerDay) {
        draft.workWeeks = draft.workWeeks.map((w) => {
          return {
            monday: { ...w.monday, start: value },
            tuesday: { ...w.tuesday, start: value },
            wednesday: { ...w.wednesday, start: value },
            thursday: { ...w.thursday, start: value },
            friday: { ...w.friday, start: value },
            saturday: { ...w.saturday, start: value },
            sunday: { ...w.sunday, start: value },
          };
        });
      }
    });
    this.fireScheduleChanged();
  }

  private setWorkDayEnd(value) {
    this.schedule = produce(this.schedule, (draft) => {
      draft.workDayEndTime = value;
      if (!draft.workHoursDefinedPerDay) {
        draft.workWeeks = draft.workWeeks.map((w) => {
          return {
            monday: { ...w.monday, end: value },
            tuesday: { ...w.tuesday, end: value },
            wednesday: { ...w.wednesday, end: value },
            thursday: { ...w.thursday, end: value },
            friday: { ...w.friday, end: value },
            saturday: { ...w.saturday, end: value },
            sunday: { ...w.sunday, end: value },
          };
        });
      }
    });
    this.fireScheduleChanged();
  }

  private onWorkDayStartChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.setWorkDayStart(e.detail.value);
    const startMinutes = timeInMinutes(e.detail.value);
    const endMinutes = timeInMinutes(this.schedule.workDayEndTime || '16:00');
    if (endMinutes - startMinutes < 5) {
      this.setWorkDayEnd(minutesToTime(startMinutes + 5));
    }
  }

  private onWorkDayEndChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.setWorkDayEnd(e.detail.value);
    const startMinutes = timeInMinutes(this.schedule.workDayStartTime || '08:00');
    const endMinutes = timeInMinutes(e.detail.value);
    if (endMinutes - startMinutes < 5) {
      this.setWorkDayStart(minutesToTime(endMinutes - 5));
    }
  }
  private onWorkHoursTypeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.schedule = produce(this.schedule, (draft) => {
      draft.workHoursDefinedPerDay = e.detail.value === 'custom';
    });
    this.fireScheduleChanged();
  }
  private onWeekCountChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    const count = Number(e.detail.value);
    if (count !== this.schedule.workWeeks.length) {
      this.schedule = produce(this.schedule, (draft) => {
        if (count < draft.workWeeks.length) {
          draft.workWeeks = draft.workWeeks.slice(0, count);
        } else {
          const add = count - draft.workWeeks.length;
          for (let i = 0; i < add; i++) {
            draft.workWeeks.push(defaultWorkWeek);
          }
        }
      });
      this.fireScheduleChanged();
    }
  }

  private fireScheduleChanged() {
    this.dispatchEvent(
      new CustomEvent<{ schedule: WorkSchedule }>('schedule-changed', {
        composed: true,
        bubbles: true,
        detail: { schedule: this.schedule },
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-work-schedule': DEditWorkSchedule;
  }
}
