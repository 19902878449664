import { css, html, LitElement, nothing } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import type {
  CloudServiceExternalItem,
  ConnectedEmployeeExternalItem,
  ConnectedPartnerExternalItem,
  RemoteServerExternalItem,
} from 'src/pages/computers-page/computers-page-view-model.js';
import type { ComputerMapLocal } from 'src/pages/computers-page/d-computer-unit-local.js';
import type { ComputerMapPrinter } from 'src/pages/computers-page/d-computer-unit-printer.js';
import type { NetworkConnection } from 'src/pages/computers-page/d-network-connection-view.js';
import type { NetworkFirewall } from 'src/pages/computers-page/d-network-firewall-view.js';
import type { NetworkItem } from 'src/pages/computers-page/d-network-item-view.js';
import type { NationalHealthNetworkItem } from 'src/pages/computers-page/d-nhn-view.js';
import type { DataItem } from 'src/pages/computers-page/d-data-item-view.js';

import 'src/pages/page-content';
import '../../library/elements/d-action.js';
import '../../library/fields/d-expansion.js';
import '../../library/lists/d-list-section.js';
import './d-computer-unit-cloud-service.js';
import './d-computer-unit-local.js';
import './d-computer-unit-printer.js';
import './d-computer-unit-remote-server.js';
import './d-external-connection-view.js';
import './d-network-connection-view.js';
import './d-network-firewall-view.js';
import './d-network-item-view.js';
import './d-nhn-view.js';
import './d-data-item-view.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';

export interface Network {
  uuid: string;
  name: string;
  computers: ComputerMapLocal[];
  connection: NetworkConnection;
  firewall: NetworkFirewall;
  item: NetworkItem;
}

export interface RiskAssessmentInformation {
  uuid: string;
  name: string;
  riskDisplay: string;
  pageId?: number;
}

/**
 *
 * @fires save-computer
 * @fires save-personal-data-item
 */
@customElement('d-computers-page-content-map')
export class DComputersPageContentMap extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    d-view-info {
      margin-top: 8px;
    }

    .map {
      margin-top: 20px;
      max-width: 724px;
    }

    .middle {
      display: flex;
      background-image: url(/images/data-map/connection.svg);
      background-position: -27px 0;
      background-repeat: repeat-y;
      background-size: 2000px 100px;
    }

    .middle div {
      flex: none;
    }

    .middle .internet {
      background: transparent url(/images/data-map/internet-mask.svg) 0 0 no-repeat;
      background-size: 46px 46px;
      width: 46px;
      height: 46px;
      flex: none;
    }

    .middle .internet,
    .offNhn .stack {
      background-color: #f37e1d;
    }

    .mapItem {
      position: relative;
      padding-left: 50px;
      transition: all 0.5s;
    }

    .mapItem.nhn {
      flex: 1;
      padding-left: 7px;
    }

    .external .mapItem .mapItemPadding {
      padding-bottom: 12px;
    }

    #new .mapItem .mapItemPadding,
    .internal .mapItem .mapItemPadding,
    .offline .mapItem .mapItemPadding {
      padding-top: 12px;
    }

    .mapElement {
      background-color: hsl(1, 0%, 96%);
      border: 2px solid silver;
      border-radius: 12px;
      padding: 9px;
      transition: all 0.5s;
    }

    /* Change appearance when element is open */

    .mapElement.opened {
      padding: 20px;
      padding-bottom: 10px;
      border-radius: 20px;
      border-width: 4px;
      transition: all 0.5s;
    }

    .personalDataItem {
      border-radius: 0;
      margin-top: 6px;
    }

    .personalDataItem.mapElement.opened {
      border-radius: 0;
    }

    .mapElement.computer.opened .mapElement.personalDataItem,
    .mapElement.remoteServer.opened .mapElement.personalDataItem,
    .mapElement.cloudService.opened .mapElement.personalDataItem {
      margin-bottom: 10px;
    }

    /* */

    .internal {
      display: flex;
      flex-direction: column;
    }

    .internal .offNhn {
      order: 2;
    }

    .internal .onNhn {
      order: 1;
    }

    .onNhn {
      padding-left: 50px;
    }

    .offlineNetwork {
      margin-top: 12px;
    }

    /* CONNECTION GRAPHICS */

    .internet {
      border: none;
      padding: 0;
      background-color: transparent;
    }

    .mapItem,
    .offNhn:not([hidden]) + .onNhn {
      background-color: silver;
      background-image: url(/images/data-map/connection.svg);
      background-size: 2000px 100px;
      background-position: -27px 0;
      background-repeat: repeat-y;
    }

    .mapItem.offlineComputer {
      background-color: transparent;
    }

    .mapItem:before {
      content: 'x';
      text-indent: -100px;
      overflow: hidden;
      display: block;
      height: 58px;
      width: 50px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .middle .mapItem:before {
      width: 7px;
    }

    .external .mapItem:before {
      background: transparent url(/images/data-map/connection-external.svg) -27px -27px no-repeat;
      background-size: 100px 100px;
    }

    .external .mapItem:first-of-type:before {
      background-image: url(/images/data-map/connection-external-first.svg);
      background-size: 100px 100px;
    }

    .external .mapItem .mapItem:before {
      background: blue url(/images/data-map/connection-internal.svg) -30px -27px no-repeat;
      background-size: 100px 100px;
      bottom: 0;
    }

    .external .mapItem .mapItem:last-of-type:before,
    .external .mapItem .mapItem.lastItem:before {
      background-image: url(/images/data-map/connection-internal-last.svg);
      background-size: 100px 100px;
    }

    .middle .mapItem:last-of-type:before {
      background-image: url(/images/data-map/connection-external-first.svg);
      background-position-x: 100%;
      background-position-y: -27px;
      background-size: 100px 100px;
    }

    #new .mapItem:before,
    .internal .mapItem:before,
    .offline .mapItem:before {
      background: silver url(/images/data-map/connection-internal.svg) -27px -14px no-repeat;
      background-size: 100px 100px;
    }

    .offline .mapItem.offlineComputer:before {
      background-color: transparent;
    }

    #new .mapItem:last-of-type:before,
    .internal .mapItem:last-of-type:before,
    .offline .mapItem:last-of-type:before,
    #new .mapItem.lastItem:before,
    .internal .mapItem.lastItem:before,
    .offline .mapItem.lastItem:before {
      background-image: url(/images/data-map/connection-internal-last.svg);
      background-size: 100px 100px;
    }

    .stack {
      background: silver url(/images/data-map/connection.svg) -30px 0 repeat-y;
      background-size: 2000px 100px;
    }

    .stack d-network-connection-view,
    .stack d-network-firewall-view {
      margin-bottom: 12px;
    }

    .mapItem.offlineComputer {
      background: none;
    }

    .mapItem.offlineComputer:before {
      content: '';
      display: none;
    }

    /* CONNECTION AND BORDER COLORS */

    .offNhn:not([hidden]) + .onNhn,
    .middle.networksOffNhn,
    .offNhn .mapItem,
    .offNhn .mapItem:before,
    .external .offNhn .mapItem .mapItem:before,
    .middle .mapItem:before,
    .middle .internet,
    .offNhn .stack {
      background-color: #f37e1d;
    }

    .offNhn d-external-connection-view,
    .offNhn d-computer-unit-cloud-service,
    .offNhn d-computer-unit-remote-server,
    .offNhn {
      --map-element-border-color: #f37e1d;
    }

    .onNhn .mapItem,
    .onNhn .mapItem:before,
    .external .onNhn .mapItem .mapItem:before,
    .onNhn .stack {
      background-color: hsl(196, 83%, 49%);
      background-color: #70c1b8;
    }

    .onNhn .mapElement,
    .middle div:last-child {
      border-color: hsl(196, 83%, 49%);
      border-color: #70c1b8;
    }

    .onNhn,
    d-nhn-view {
      --map-element-border-color: #70c1b8;
    }

    .external .mapItem .mapItem:last-of-type,
    #new .mapItem:last-of-type,
    .internal .mapItem:last-of-type,
    .offline .mapItem:last-of-type,
    .external .mapItem .mapItem.lastItem,
    #new .mapItem.lastItem,
    .internal .mapItem.lastItem,
    .offline .mapItem.lastItem {
      background: none;
    }

    .mapElement + .mapElement {
      margin-top: 12px;
    }

    /* ELEMENT HEADERS */

    .elementHeader {
      font-size: 15px;
      font-weight: bold;
      color: hsl(1, 0%, 40%);
      margin-top: -4px;
      margin-bottom: -4px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      transition: all 0.5s;
    }

    .elementHeader .icon {
      width: 32px;
      height: 32px;
      margin-left: -4px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;
      flex: none;
      transition: all 0.5s;
    }

    .elementHeader .text {
      flex: 1;
      margin-left: 4px;
    }

    .elementHeader .text > div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap-reverse;
    }

    .elementHeader .buttons {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .elementHeader:hover {
      color: black;
    }

    .details ul {
      padding: 0 8px;
      cursor: pointer;
      line-height: 130%;
      list-style-type: none;
      margin-left: 24px;
      color: hsl(1, 0%, 40%);
    }

    #new .details {
      display: none;
    }

    .elementType {
      margin-top: 7px;
      flex-grow: 1;
    }

    .elementName {
      font-weight: normal;
    }

    div.elementName {
      font-size: 1px;
      opacity: 0;
      transition: all 0.5s;
    }

    span.elementName {
      font-size: 15px;
      opacity: 1;
      transition: all 0.5s;
    }

    .incomplete {
      color: rgb(243, 126, 29);
      font-weight: normal;
    }

    .actionLink {
      flex: none;
      margin-left: 10px;
    }

    #new a.actionLink.deleteLink {
      display: none;
    }

    .actionLink.deleteLink:hover {
      background-color: red;
      color: white;
    }

    .actionLink[disabled] {
      opacity: 0.5;
      cursor: default;
    }

    .actionLink[disabled]:hover {
      color: hsl(196, 83%, 49%);
      background-color: rgba(0, 0, 0, 0.05);
    }

    /* BROWSE AREA */

    .close {
      display: block;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: url(/images/closer.svg) 0 0 no-repeat;
      background-size: 30px 87px;
    }

    .close:hover {
      background-position: 0 -57px;
    }

    section[view] {
      margin-top: 20px;
    }

    d-section,
    d-list-section .listHeader,
    d-view-text,
    d-view-text-vertical,
    d-labeled-row {
      border-color: hsl(1, 0%, 84%);
    }

    d-data-item-view {
      margin-top: 6px;
    }

    d-list-section-attachment .listHeader {
      background-color: hsl(1, 0%, 96%);
    }

    d-view-html #expandableOverlay.d-view-html {
      background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
    }

    /* EDITING AREA */

    .edit {
      margin-left: 36px;
      margin-bottom: -10px;
      margin-top: 12px;
    }

    /* EDIT SECTION */

    .mapElement section[edit] {
      margin-top: 20px;
    }

    d-section ::content d-universal-selector,
    d-section section.horizontal > * {
      border-color: hsl(1, 0%, 88%);
    }

    d-section ::content d-universal-selector[type='check'] ::content #selector #options .option .icon,
    d-edit-checkbox #box {
      background: white url('../../../images/checkradio_white.svg') -2px -2px no-repeat;
      box-shadow: none;
      border: 1px solid hsl(1, 0%, 88%);
    }

    d-section ::content d-edit-checkbox {
      margin-left: 0;
      padding: 0;
    }

    d-section ::content d-universal-selector[type='check'] ::content #selector #options .option[selected] .icon,
    d-edit-checkbox #box[checked] {
      background: white url('../../../images/checkradio_white.svg') -30px -2px no-repeat;
    }

    d-section ::content input,
    textarea,
    .selectWrapper,
    .selectWrapper select,
    .textarea,
    d-section ::content .textarea,
    d-section ::content d-universal-selector ::content .selectWrapper {
      background-color: white;
      box-shadow: none;
      border: 1px solid hsl(1, 0%, 88%);
    }

    d-section ::content d-universal-selector ::content .selectWrapper {
      margin-bottom: 8px !important;
    }

    d-edit-html ::content h3.editLabel.labelBigger {
      font-size: 15px;
    }

    d-edit-html ::content #textEditHeader {
      background-color: hsl(1, 0%, 96%);
      border-bottom: 1px solid hsl(1, 0%, 88%);
    }

    d-edit-html ::content .textEditTop {
      background-color: white !important;
      box-shadow: none !important;
      border: 1px solid hsl(1, 0%, 88%);
      border-bottom: none;
    }

    d-edit-html ::content #textEdit {
      background-color: white !important;
      box-shadow: none !important;
      border-left: 1px solid hsl(1, 0%, 88%);
      border-right: 1px solid hsl(1, 0%, 88%);
    }

    .offlineNetwork,
    .offlineComputer,
    .offlinePersonalDataItem {
      padding-left: 103px;
    }

    .offlinePersonalDataItem .personalDataItem {
      margin-top: 12px;
    }

    /* CREATING NEW ELEMENTS */

    #new.map {
      margin-top: 0;
    }

    #new.map.noComputer .mapElement.personalDataItem {
      margin-top: 0;
    }

    #new.map .mapItem.externalConnection .mapItemPadding {
      padding-top: 0;
    }

    #new.map .mapItem.externalConnection .mapElement,
    #new.map .stack {
      margin-top: 12px;
    }

    .noNetwork .mapItem {
      padding-left: 0;
    }

    .noNetwork .mapItem:before {
      display: none;
    }

    .noNetwork .mapItemPadding {
      padding: 0;
    }

    .noComputer .mapElement.computer {
      border: none;
      background: none;
      padding: 0;
    }

    .noComputer.noPersonalDataItem .mapItem.computer,
    .noPersonalDataItem .mapElement.personalDataItem {
      display: none;
    }

    .noExternalConnection .externalConnection {
      display: none;
    }

    .actionLink.disabled {
      opacity: 0.5;
      cursor: default;
    }

    .actionLink.disabled:hover {
      color: hsl(196, 83%, 49%);
      background-color: rgba(0, 0, 0, 0.05);
    }
  `;

  @property({ type: String })
  organizationName = '';
  @property({ type: Object })
  nhn: NationalHealthNetworkItem = {
    uuid: 'x',
    unitType: 'nhn',
    alerts: [],
  };
  @property({ type: Array })
  computers: any[] = [];
  @property({ type: Array })
  personalDataItems: any[] = [];
  @property({ type: Array })
  computersOnNhn: ComputerMapLocal[] = [];
  @property({ type: Array })
  connectedPartners: ConnectedPartnerExternalItem[] = [];
  @property({ type: Array })
  connectedEmployees: ConnectedEmployeeExternalItem[] = [];
  @property({ type: Array })
  cloudServices: CloudServiceExternalItem[] = [];
  @property({ type: Array })
  remoteServers: RemoteServerExternalItem[] = [];
  @property({ type: Array })
  computersOffNhn: ComputerMapLocal[] = [];
  @property({ type: Array })
  networks: Network[] = [];
  @property({ type: Array })
  offlinePersonalDataItems: DataItem[] = [];
  @property({ type: Array })
  standaloneComputers: ComputerMapLocal[] = [];
  @property({ type: Array })
  standalonePrinters: ComputerMapPrinter[] = [];
  @property({ type: Boolean })
  hideDetails = false;
  @property({ type: Boolean })
  creatingNewElements = false;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Array })
  employees: SelectDropdownOption[] = [];
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: Array })
  availableRiskAssessments: RiskAssessmentInformation[] = [];
  @property({ type: Array })
  assets: { uuid: string; name: string; number: string }[] = [];
  @property({ type: Number })
  contentStickyTop = 0;

  @property({ type: String })
  highlightComputer?: string;

  private get externalItemsOffNhn() {
    return (
      this.externalConnectionsOffNhn.length > 0 ||
      this.cloudServicesOffNhn.length > 0 ||
      this.remoteServersOffNhn.length > 0
    );
  }

  private get externalConnectionsOffNhn() {
    return this.externalConnections
      .filter((e) => !e.externalConnection.connectedViaNhn)
      .map((e) => e.externalConnection);
  }

  private get cloudServicesOffNhn() {
    return this.cloudServices.filter((e) => e.cloudService.connectionType === 'OTHER').map((e) => e.cloudService);
  }

  private get cloudServicesOnNhn() {
    return this.cloudServices.filter((e) => e.cloudService.connectionType !== 'OTHER').map((e) => e.cloudService);
  }

  private get remoteServersOffNhn() {
    return this.remoteServers.filter((e) => e.remoteServer.connectionType === 'OTHER').map((e) => e.remoteServer);
  }

  private get remoteServersOnNhn() {
    return this.remoteServers.filter((e) => e.remoteServer.connectionType !== 'OTHER').map((e) => e.remoteServer);
  }

  private get externalConnectionsOnNhn() {
    return this.externalConnections
      .filter((e) => e.externalConnection.connectedViaNhn)
      .map((e) => e.externalConnection);
  }

  private get elementsOnNhn() {
    return (
      this.externalConnectionsOnNhn.length > 0 ||
      this.cloudServicesOnNhn.length > 0 ||
      this.remoteServersOnNhn.length > 0 ||
      this.networksLocalOnNhn.length > 0
    );
  }

  private get externalConnections() {
    return [...this.connectedPartners, ...this.connectedEmployees];
  }

  private get onlineElementsExist() {
    return this.externalConnections.length > 0 || this.cloudServices.length > 0 || this.remoteServers.length > 0;
  }

  private get mapElementsExist() {
    return (
      this.onlineElementsExist ||
      this.offlinePersonalDataItems.length > 0 ||
      this.standaloneComputers.length > 0 ||
      this.networksOffline.length > 0
    );
  }

  private get networksLocalOffNhn() {
    return this.networks.filter((n) => n.connection.connectionType === 'OTHER');
  }

  private get networksLocalOnNhn() {
    return this.networks.filter(
      (n) => n.connection.connectionType === 'NHN' || n.connection.connectionType === 'NHNotherISP',
    );
  }

  private get networksOffline() {
    return this.networks.filter((n) => n.connection.connectionType === 'NONE');
  }

  _mapItemClasses(array, index) {
    let classes = 'mapItem';
    if (array.length - 1 === index) {
      classes += ' lastItem';
    }
    return classes;
  }

  _middleClasses(networksLocalOffNhn) {
    if (networksLocalOffNhn.length > 0) {
      return 'middle networksOffNhn';
    } else {
      return 'middle';
    }
  }

  _offNhnClasses(networks) {
    if (networks.length > 0) {
      return 'offNhn';
    } else {
      return '';
    }
  }

  onToggleDetails() {
    this.hideDetails = !this.hideDetails;
  }

  render() {
    return html`
      <d-expansion ?opened="${!this.creatingNewElements}">
        ${!this.mapElementsExist
          ? html`
              <d-view-info
                .content=${`<p><em>Ingen elementer er registrert. Gå til fanen Databehandling og registrer hvilke data som behandles.</em></p>`}
              ></d-view-info>
            `
          : html` <div class="options">
              <d-action @click=${this.onToggleDetails}
                >${this.hideDetails ? 'Vis kommentarer' : 'Skjul kommentarer'}
              </d-action>
            </div>`}

        <div class="map" id="map">
          <div class="external">
            ${!this.externalItemsOffNhn
              ? nothing
              : html` <div class="offNhn">
                  ${this.externalConnectionsOffNhn.map(
                    (item, index) =>
                      html` <div class="${this._mapItemClasses(this.externalConnectionsOffNhn, index)}">
                        <div class="mapItemPadding">
                          <d-external-connection-view
                            ?hideDetails=${this.hideDetails}
                            .item=${item}
                            .contentStickyTop=${this.contentStickyTop}
                            .writeAccess=${this.writeAccess}
                            .employees=${this.employees}
                            .partners=${this.partners}
                            .riskAssessmentsList=${this.availableRiskAssessments}
                          >
                          </d-external-connection-view>
                        </div>
                      </div>`,
                  )}
                  ${this.cloudServicesOffNhn.map(
                    (item, index) =>
                      html` <div class="${this._mapItemClasses(this.cloudServicesOffNhn, index)}">
                        <div class="mapItemPadding">
                          <d-computer-unit-cloud-service
                            ?hideDetails=${this.hideDetails}
                            .item=${item}
                            .writeAccess=${this.writeAccess}
                          >
                            ${item.personalDataItems.map(
                              (p) => html`
                                <d-data-item-view
                                  ?hideDetails=${this.hideDetails}
                                  .item=${p}
                                  .contentStickyTop=${this.contentStickyTop}
                                  .writeAccess=${this.writeAccess}
                                  .organizationName=${this.organizationName}
                                  .assets=${this.assets}
                                >
                                </d-data-item-view>
                              `,
                            )}
                          </d-computer-unit-cloud-service>
                        </div>
                      </div>`,
                  )}
                  ${this.remoteServersOffNhn.map(
                    (item, index) =>
                      html` <div class="${this._mapItemClasses(this.remoteServersOffNhn, index)}">
                        <div class="mapItemPadding">
                          <d-computer-unit-remote-server
                            ?hideDetails=${this.hideDetails}
                            .item=${item}
                            .writeAccess=${this.writeAccess}
                          >
                            ${item.personalDataItems.map(
                              (item) =>
                                html` <d-expansion opened="">
                                  <d-data-item-view
                                    ?hideDetails=${this.hideDetails}
                                    .item="${item}"
                                    .contentStickyTop=${this.contentStickyTop}
                                    .writeAccess=${this.writeAccess}
                                    .organizationName=${this.organizationName}
                                    .assets=${this.assets}
                                  >
                                  </d-data-item-view>
                                </d-expansion>`,
                            )}
                          </d-computer-unit-remote-server>
                        </div>
                      </div>`,
                  )}
                </div>`}

            <div class="onNhn">
              ${this.externalConnectionsOnNhn.map(
                (item, index) =>
                  html` <div class="${this._mapItemClasses(this.externalConnectionsOnNhn, index)}">
                    <div class="mapItemPadding">
                      <d-external-connection-view
                        ?hideDetails=${this.hideDetails}
                        .item=${item}
                        .contentStickyTop=${this.contentStickyTop}
                        .writeAccess=${this.writeAccess}
                        .employees=${this.employees}
                        .partners=${this.partners}
                        .riskAssessmentsList=${[]}
                      >
                      </d-external-connection-view>
                    </div>
                  </div>`,
              )}
              ${this.cloudServicesOnNhn.map(
                (item, index) =>
                  html` <div class="${this._mapItemClasses(this.cloudServicesOnNhn, index)}">
                    <div class="mapItemPadding">
                      <d-computer-unit-cloud-service
                        ?hideDetails=${this.hideDetails}
                        .item=${item}
                        .writeAccess=${this.writeAccess}
                      >
                        ${item.personalDataItems.map(
                          (item) =>
                            html` <d-expansion opened="">
                              <d-data-item-view
                                ?hideDetails=${this.hideDetails}
                                .item="${item}"
                                .contentStickyTop=${this.contentStickyTop}
                                .writeAccess=${this.writeAccess}
                                .organizationName=${this.organizationName}
                                .assets=${this.assets}
                              >
                              </d-data-item-view>
                            </d-expansion>`,
                        )}
                      </d-computer-unit-cloud-service>
                    </div>
                  </div>`,
              )}
              ${this.remoteServersOnNhn.map(
                (item, index) =>
                  html` <div class="${this._mapItemClasses(this.remoteServersOnNhn, index)}">
                    <div class="mapItemPadding">
                      <d-computer-unit-remote-server
                        ?hideDetails=${this.hideDetails}
                        .item=${item}
                        .writeAccess=${this.writeAccess}
                      >
                        ${item.personalDataItems.map(
                          (item) =>
                            html` <d-expansion opened="">
                              <d-data-item-view
                                ?hideDetails=${this.hideDetails}
                                .item="${item}"
                                .contentStickyTop=${this.contentStickyTop}
                                .writeAccess=${this.writeAccess}
                                .organizationName=${this.organizationName}
                                .assets=${this.assets}
                              >
                              </d-data-item-view>
                            </d-expansion>`,
                        )}
                      </d-computer-unit-remote-server>
                    </div>
                  </div>`,
              )}
            </div>
          </div>
          ${!this.onlineElementsExist
            ? nothing
            : html` <div class="${this._middleClasses(this.networksLocalOffNhn)}">
                <div class="internet"></div>
                ${!this.elementsOnNhn
                  ? nothing
                  : html` <div class="mapItem nhn">
                      <d-nhn-view ?hideDetails=${this.hideDetails} .item=${this.nhn} .writeAccess=${this.writeAccess}>
                      </d-nhn-view>
                    </div>`}
              </div>`}
          <div class="internal">
            <div class="${this._offNhnClasses(this.networksLocalOffNhn)}">
              ${this.networksLocalOffNhn.map(
                (item, index) => html`
                  <div class="${this._mapItemClasses(this.networksLocalOffNhn, index)}">
                    <div class="mapItemPadding">
                      <div class="stack">
                        <d-network-connection-view
                          ?hideDetails=${this.hideDetails}
                          .writeAccess=${this.writeAccess}
                          .item=${item.connection}
                          ?ghost=${item.connection.ghost}
                          type=""
                        ></d-network-connection-view>
                        <d-network-firewall-view
                          ?hideDetails=${this.hideDetails}
                          .writeAccess=${this.writeAccess}
                          .item=${item.firewall}
                          ?ghost=${item.firewall.ghost}
                          type=""
                        ></d-network-firewall-view>
                        <d-network-item-view
                          ?hideDetails=${this.hideDetails}
                          .writeAccess=${this.writeAccess}
                          .item=${item.item}
                          type=""
                          .partners=${this.partners}
                        ></d-network-item-view>
                      </div>
                      ${this.renderComputers(item.computers)}
                    </div>
                  </div>
                `,
              )}
            </div>
            <div class="onNhn">
              ${this.networksLocalOnNhn.map(
                (item, index) => html`
                  <div class="${this._mapItemClasses(this.networksLocalOnNhn, index)}">
                    <div class="mapItemPadding">
                      <div class="stack">
                        <d-network-connection-view
                          ?hideDetails=${this.hideDetails}
                          .writeAccess=${this.writeAccess}
                          .item=${item.connection}
                          ?ghost=${item.connection.ghost}
                          type=""
                        ></d-network-connection-view>
                        <d-network-firewall-view
                          ?hideDetails=${this.hideDetails}
                          .writeAccess=${this.writeAccess}
                          .item=${item.firewall}
                          ?ghost=${item.firewall.ghost}
                          type=""
                        ></d-network-firewall-view>
                        <d-network-item-view
                          ?hideDetails=${this.hideDetails}
                          .writeAccess=${this.writeAccess}
                          .item=${item.item}
                          type=""
                          .partners=${this.partners}
                        ></d-network-item-view>
                      </div>
                      ${this.renderComputers(item.computers)}
                    </div>
                  </div>
                `,
              )}
            </div>
          </div>
          <div class="offline">
            ${this.offlinePersonalDataItems.map(
              (item) =>
                html` <div class="offlinePersonalDataItem">
                  <div class="mapItemPadding">
                    <d-data-item-view
                      ?hideDetails=${this.hideDetails}
                      .item=${item}
                      .contentStickyTop=${this.contentStickyTop}
                      .writeAccess=${this.writeAccess}
                      .assets=${this.assets}
                      .organizationName=${this.organizationName}
                    >
                    </d-data-item-view>
                  </div>
                </div>`,
            )}
            ${this.networksOffline.map(
              (item) =>
                html` <div class="offlineNetwork">
                  <div class="mapItemPadding">
                    <div class="stack">
                      <d-network-connection-view
                        ?hideDetails=${this.hideDetails}
                        .writeAccess=${this.writeAccess}
                        .item=${item.connection}
                        ?ghost=${item.connection.ghost}
                        type=""
                      ></d-network-connection-view>
                      <d-network-firewall-view
                        ?hideDetails=${this.hideDetails}
                        .writeAccess=${this.writeAccess}
                        .item=${item.firewall}
                        ?ghost=${item.firewall.ghost}
                        type=""
                      ></d-network-firewall-view>
                      <d-network-item-view
                        ?hideDetails=${this.hideDetails}
                        .writeAccess=${this.writeAccess}
                        .item=${item.item}
                        type=""
                        .partners=${this.partners}
                      ></d-network-item-view>
                    </div>
                    ${this.renderComputers(item.computers)}
                  </div>
                </div>`,
            )}
            ${this.renderComputers(this.standaloneComputers, true)}
            ${this.standalonePrinters.map(
              (item) =>
                html` <div class="mapItem offlineComputer">
                  <div class="mapItemPadding">
                    <d-computer-unit-printer
                      ?hideDetails=${this.hideDetails}
                      .item=${item}
                      .contentStickyTop=${this.contentStickyTop}
                      ?writeAccess=${this.writeAccess}
                      .networks=${this.networks}
                    >
                    </d-computer-unit-printer>
                  </div>
                </div>`,
            )}
          </div>
        </div>
      </d-expansion>
    `;
  }

  private renderComputers(computers: ComputerMapLocal[], offline = false) {
    return computers.map((computer, index) => {
      const highlighted = computer.uuid === this.highlightComputer;

      if (highlighted) {
        setTimeout(() => {
          const e = this.shadowRoot?.getElementById('cc-' + computer.uuid);
          if (e) {
            e.scrollIntoView({
              behavior: 'smooth',
              inline: 'center',
            });
          }
        }, 50);
      }

      return html` <div
        id="${'cc-' + computer.uuid}"
        class="${offline ? 'mapItem offlineComputer' : this._mapItemClasses(computers, index)}"
      >
        <div class="mapItemPadding">
          <d-computer-unit-local
            ?hideDetails=${this.hideDetails}
            .item="${computer}"
            .writeAccess=${this.writeAccess}
            .contentStickyTop=${this.contentStickyTop}
            .highlighted=${highlighted}
          >
            ${computer.personalDataItems.map(
              (p) =>
                html` <div>
                  <d-data-item-view
                    ?hideDetails=${this.hideDetails}
                    .item=${p}
                    .contentStickyTop=${this.contentStickyTop}
                    .writeAccess=${this.writeAccess}
                    .organizationName=${this.organizationName}
                    .assets=${this.assets}
                  >
                  </d-data-item-view>
                </div>`,
            )}
          </d-computer-unit-local>
        </div>
      </div>`;
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-computers-page-content-map': DComputersPageContentMap;
  }
}
