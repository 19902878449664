/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Buffer } from 'buffer/index.js';
import * as runtime from '../runtime';
import type {
  AttachmentPropertiesUpdateMessage,
  AttachmentViewModel,
  EmployeeUpdateMessage,
  EmployeeViewModel,
  LeavePeriodUpdateMessage,
  PlusTimePeriodUpdateMessage,
  PropertiesUpdateMessage,
  TimekeepingCorrectionUpdateMessage,
  UpdateAccessCommand,
  UpdateGenderCommand,
  UpdateStaffGroupCommand,
  UpdateTutorialStateCommand,
  WorkScheduleExceptionUpdateMessage,
  WorkSchedulesUpdateMessage,
} from '../models';
import {
  AttachmentPropertiesUpdateMessageFromJSON,
  AttachmentPropertiesUpdateMessageToJSON,
  AttachmentViewModelFromJSON,
  AttachmentViewModelToJSON,
  EmployeeUpdateMessageFromJSON,
  EmployeeUpdateMessageToJSON,
  EmployeeViewModelFromJSON,
  EmployeeViewModelToJSON,
  LeavePeriodUpdateMessageFromJSON,
  LeavePeriodUpdateMessageToJSON,
  PlusTimePeriodUpdateMessageFromJSON,
  PlusTimePeriodUpdateMessageToJSON,
  PropertiesUpdateMessageFromJSON,
  PropertiesUpdateMessageToJSON,
  TimekeepingCorrectionUpdateMessageFromJSON,
  TimekeepingCorrectionUpdateMessageToJSON,
  UpdateAccessCommandFromJSON,
  UpdateAccessCommandToJSON,
  UpdateGenderCommandFromJSON,
  UpdateGenderCommandToJSON,
  UpdateStaffGroupCommandFromJSON,
  UpdateStaffGroupCommandToJSON,
  UpdateTutorialStateCommandFromJSON,
  UpdateTutorialStateCommandToJSON,
  WorkScheduleExceptionUpdateMessageFromJSON,
  WorkScheduleExceptionUpdateMessageToJSON,
  WorkSchedulesUpdateMessageFromJSON,
  WorkSchedulesUpdateMessageToJSON,
} from '../models';

export interface CreateOrUpdateEmployeeRequest {
  employeeId: string;
  organizationId: string;
  employeeUpdateMessage: EmployeeUpdateMessage;
}

export interface CreateOrUpdateEmployeeLeavePeriodRequest {
  periodId: string;
  entityId: string;
  organizationId: string;
  leavePeriodUpdateMessage: LeavePeriodUpdateMessage;
}

export interface CreateOrUpdateEmployeePlusTimePeriodRequest {
  periodId: string;
  entityId: string;
  organizationId: string;
  plusTimePeriodUpdateMessage: PlusTimePeriodUpdateMessage;
}

export interface CreateOrUpdateEmployeeTimekeepingCorrectionRequest {
  periodId: string;
  entityId: string;
  organizationId: string;
  timekeepingCorrectionUpdateMessage: TimekeepingCorrectionUpdateMessage;
}

export interface CreateOrUpdateEmployeeWorkScheduleExceptionRequest {
  date: string;
  entityId: string;
  organizationId: string;
  workScheduleExceptionUpdateMessage: WorkScheduleExceptionUpdateMessage;
}

export interface DeleteAttachmentForEmployeeRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteEmployeeRequest {
  employeeId: string;
  organizationId: string;
}

export interface DeleteEmployeeLeavePeriodRequest {
  periodId: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteEmployeePlusTimePeriodRequest {
  periodId: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteEmployeeTimekeepingCorrectionRequest {
  periodId: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteEmployeeWorkScheduleExceptionRequest {
  date: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentForEmployeeRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentLinkForEmployeeRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchEmployeeRequest {
  employeeId: string;
  organizationId: string;
}

export interface ListAttachmentsForEmployeeRequest {
  entityId: string;
  organizationId: string;
}

export interface ListEmployeesRequest {
  organizationId: string;
}

export interface RenameAttachmentForEmployeeRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
  attachmentPropertiesUpdateMessage: AttachmentPropertiesUpdateMessage;
}

export interface UpdateEmployeeAccessRequest {
  entityId: string;
  organizationId: string;
  updateAccessCommand: UpdateAccessCommand;
}

export interface UpdateEmployeeGenderRequest {
  entityId: string;
  organizationId: string;
  updateGenderCommand: UpdateGenderCommand;
}

export interface UpdateEmployeeStaffGroupRequest {
  entityId: string;
  organizationId: string;
  updateStaffGroupCommand: UpdateStaffGroupCommand;
}

export interface UpdateEmployeeWorkSchedulesRequest {
  entityId: string;
  organizationId: string;
  workSchedulesUpdateMessage: WorkSchedulesUpdateMessage;
}

export interface UpdatePropertiesForEmployeeRequest {
  entityId: string;
  organizationId: string;
  requestBody: { [key: string]: object };
}

export interface UpdateTutorialStateForEmployeeRequest {
  entityId: string;
  organizationId: string;
  updateTutorialStateCommand: UpdateTutorialStateCommand;
}

export interface UploadAttachmentForEmployeeRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
  contentLength?: string;
  body?: string;
}

/**
 *
 */
export class EmployeesApi extends runtime.BaseAPI {
  /**
   * Update or create employee with the specified employee id, and the search index for the employee will also be updated or created.
   * Creates or updates an employee.
   */
  async createOrUpdateEmployeeRaw(
    requestParameters: CreateOrUpdateEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
      throw new runtime.RequiredError(
        'employeeId',
        'Required parameter requestParameters.employeeId was null or undefined when calling createOrUpdateEmployee.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateEmployee.',
      );
    }

    if (requestParameters.employeeUpdateMessage === null || requestParameters.employeeUpdateMessage === undefined) {
      throw new runtime.RequiredError(
        'employeeUpdateMessage',
        'Required parameter requestParameters.employeeUpdateMessage was null or undefined when calling createOrUpdateEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{employeeId}`
          .replace(`{${'employeeId'}}`, encodeURIComponent(String(requestParameters.employeeId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: EmployeeUpdateMessageToJSON(requestParameters.employeeUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update or create employee with the specified employee id, and the search index for the employee will also be updated or created.
   * Creates or updates an employee.
   */
  async createOrUpdateEmployee(
    requestParameters: CreateOrUpdateEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateEmployeeRaw(requestParameters, initOverrides);
  }

  /**
   * update employee leave period.
   * Updates or creates leave period for an employee.
   */
  async createOrUpdateEmployeeLeavePeriodRaw(
    requestParameters: CreateOrUpdateEmployeeLeavePeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.periodId === null || requestParameters.periodId === undefined) {
      throw new runtime.RequiredError(
        'periodId',
        'Required parameter requestParameters.periodId was null or undefined when calling createOrUpdateEmployeeLeavePeriod.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling createOrUpdateEmployeeLeavePeriod.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateEmployeeLeavePeriod.',
      );
    }

    if (
      requestParameters.leavePeriodUpdateMessage === null ||
      requestParameters.leavePeriodUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'leavePeriodUpdateMessage',
        'Required parameter requestParameters.leavePeriodUpdateMessage was null or undefined when calling createOrUpdateEmployeeLeavePeriod.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/leave-periods/{periodId}`
          .replace(`{${'periodId'}}`, encodeURIComponent(String(requestParameters.periodId)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: LeavePeriodUpdateMessageToJSON(requestParameters.leavePeriodUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update employee leave period.
   * Updates or creates leave period for an employee.
   */
  async createOrUpdateEmployeeLeavePeriod(
    requestParameters: CreateOrUpdateEmployeeLeavePeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateEmployeeLeavePeriodRaw(requestParameters, initOverrides);
  }

  /**
   * update employee plus time period.
   * Updates or creates plus time period for an employee.
   */
  async createOrUpdateEmployeePlusTimePeriodRaw(
    requestParameters: CreateOrUpdateEmployeePlusTimePeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.periodId === null || requestParameters.periodId === undefined) {
      throw new runtime.RequiredError(
        'periodId',
        'Required parameter requestParameters.periodId was null or undefined when calling createOrUpdateEmployeePlusTimePeriod.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling createOrUpdateEmployeePlusTimePeriod.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateEmployeePlusTimePeriod.',
      );
    }

    if (
      requestParameters.plusTimePeriodUpdateMessage === null ||
      requestParameters.plusTimePeriodUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'plusTimePeriodUpdateMessage',
        'Required parameter requestParameters.plusTimePeriodUpdateMessage was null or undefined when calling createOrUpdateEmployeePlusTimePeriod.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/plus-time-periods/{periodId}`
          .replace(`{${'periodId'}}`, encodeURIComponent(String(requestParameters.periodId)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PlusTimePeriodUpdateMessageToJSON(requestParameters.plusTimePeriodUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update employee plus time period.
   * Updates or creates plus time period for an employee.
   */
  async createOrUpdateEmployeePlusTimePeriod(
    requestParameters: CreateOrUpdateEmployeePlusTimePeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateEmployeePlusTimePeriodRaw(requestParameters, initOverrides);
  }

  /**
   * update employee timekeeping correction.
   * Updates or creates timekeeping correction for an employee.
   */
  async createOrUpdateEmployeeTimekeepingCorrectionRaw(
    requestParameters: CreateOrUpdateEmployeeTimekeepingCorrectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.periodId === null || requestParameters.periodId === undefined) {
      throw new runtime.RequiredError(
        'periodId',
        'Required parameter requestParameters.periodId was null or undefined when calling createOrUpdateEmployeeTimekeepingCorrection.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling createOrUpdateEmployeeTimekeepingCorrection.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateEmployeeTimekeepingCorrection.',
      );
    }

    if (
      requestParameters.timekeepingCorrectionUpdateMessage === null ||
      requestParameters.timekeepingCorrectionUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'timekeepingCorrectionUpdateMessage',
        'Required parameter requestParameters.timekeepingCorrectionUpdateMessage was null or undefined when calling createOrUpdateEmployeeTimekeepingCorrection.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/timekeeping-corrections/{periodId}`
          .replace(`{${'periodId'}}`, encodeURIComponent(String(requestParameters.periodId)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: TimekeepingCorrectionUpdateMessageToJSON(requestParameters.timekeepingCorrectionUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update employee timekeeping correction.
   * Updates or creates timekeeping correction for an employee.
   */
  async createOrUpdateEmployeeTimekeepingCorrection(
    requestParameters: CreateOrUpdateEmployeeTimekeepingCorrectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateEmployeeTimekeepingCorrectionRaw(requestParameters, initOverrides);
  }

  /**
   * update employee work schedule exception.
   * Updates or creates work schedule exception for an employee.
   */
  async createOrUpdateEmployeeWorkScheduleExceptionRaw(
    requestParameters: CreateOrUpdateEmployeeWorkScheduleExceptionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.date === null || requestParameters.date === undefined) {
      throw new runtime.RequiredError(
        'date',
        'Required parameter requestParameters.date was null or undefined when calling createOrUpdateEmployeeWorkScheduleException.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling createOrUpdateEmployeeWorkScheduleException.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateEmployeeWorkScheduleException.',
      );
    }

    if (
      requestParameters.workScheduleExceptionUpdateMessage === null ||
      requestParameters.workScheduleExceptionUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'workScheduleExceptionUpdateMessage',
        'Required parameter requestParameters.workScheduleExceptionUpdateMessage was null or undefined when calling createOrUpdateEmployeeWorkScheduleException.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/work-schedule-exceptions/{date}`
          .replace(`{${'date'}}`, encodeURIComponent(String(requestParameters.date)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: WorkScheduleExceptionUpdateMessageToJSON(requestParameters.workScheduleExceptionUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update employee work schedule exception.
   * Updates or creates work schedule exception for an employee.
   */
  async createOrUpdateEmployeeWorkScheduleException(
    requestParameters: CreateOrUpdateEmployeeWorkScheduleExceptionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateEmployeeWorkScheduleExceptionRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for an employee.
   */
  async deleteAttachmentForEmployeeRaw(
    requestParameters: DeleteAttachmentForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling deleteAttachmentForEmployee.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteAttachmentForEmployee.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAttachmentForEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for an employee.
   */
  async deleteAttachmentForEmployee(
    requestParameters: DeleteAttachmentForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAttachmentForEmployeeRaw(requestParameters, initOverrides);
  }

  /**
   * Delete employee for specified employee id.
   * Deletes an employee.
   */
  async deleteEmployeeRaw(
    requestParameters: DeleteEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
      throw new runtime.RequiredError(
        'employeeId',
        'Required parameter requestParameters.employeeId was null or undefined when calling deleteEmployee.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{employeeId}`
          .replace(`{${'employeeId'}}`, encodeURIComponent(String(requestParameters.employeeId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete employee for specified employee id.
   * Deletes an employee.
   */
  async deleteEmployee(
    requestParameters: DeleteEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteEmployeeRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified leave period for employee.
   * Deletes a leave period for an employee.
   */
  async deleteEmployeeLeavePeriodRaw(
    requestParameters: DeleteEmployeeLeavePeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.periodId === null || requestParameters.periodId === undefined) {
      throw new runtime.RequiredError(
        'periodId',
        'Required parameter requestParameters.periodId was null or undefined when calling deleteEmployeeLeavePeriod.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteEmployeeLeavePeriod.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteEmployeeLeavePeriod.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/leave-periods/{periodId}`
          .replace(`{${'periodId'}}`, encodeURIComponent(String(requestParameters.periodId)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified leave period for employee.
   * Deletes a leave period for an employee.
   */
  async deleteEmployeeLeavePeriod(
    requestParameters: DeleteEmployeeLeavePeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteEmployeeLeavePeriodRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified plus time period for employee.
   * Deletes a plus time period for an employee.
   */
  async deleteEmployeePlusTimePeriodRaw(
    requestParameters: DeleteEmployeePlusTimePeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.periodId === null || requestParameters.periodId === undefined) {
      throw new runtime.RequiredError(
        'periodId',
        'Required parameter requestParameters.periodId was null or undefined when calling deleteEmployeePlusTimePeriod.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteEmployeePlusTimePeriod.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteEmployeePlusTimePeriod.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/plus-time-periods/{periodId}`
          .replace(`{${'periodId'}}`, encodeURIComponent(String(requestParameters.periodId)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified plus time period for employee.
   * Deletes a plus time period for an employee.
   */
  async deleteEmployeePlusTimePeriod(
    requestParameters: DeleteEmployeePlusTimePeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteEmployeePlusTimePeriodRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified timekeeping correction for employee.
   * Deletes a timekeeping correction for an employee.
   */
  async deleteEmployeeTimekeepingCorrectionRaw(
    requestParameters: DeleteEmployeeTimekeepingCorrectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.periodId === null || requestParameters.periodId === undefined) {
      throw new runtime.RequiredError(
        'periodId',
        'Required parameter requestParameters.periodId was null or undefined when calling deleteEmployeeTimekeepingCorrection.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteEmployeeTimekeepingCorrection.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteEmployeeTimekeepingCorrection.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/timekeeping-corrections/{periodId}`
          .replace(`{${'periodId'}}`, encodeURIComponent(String(requestParameters.periodId)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified timekeeping correction for employee.
   * Deletes a timekeeping correction for an employee.
   */
  async deleteEmployeeTimekeepingCorrection(
    requestParameters: DeleteEmployeeTimekeepingCorrectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteEmployeeTimekeepingCorrectionRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified work schedule exception for employee.
   * Deletes a work schedule exception for an employee.
   */
  async deleteEmployeeWorkScheduleExceptionRaw(
    requestParameters: DeleteEmployeeWorkScheduleExceptionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.date === null || requestParameters.date === undefined) {
      throw new runtime.RequiredError(
        'date',
        'Required parameter requestParameters.date was null or undefined when calling deleteEmployeeWorkScheduleException.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteEmployeeWorkScheduleException.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteEmployeeWorkScheduleException.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/work-schedule-exceptions/{date}`
          .replace(`{${'date'}}`, encodeURIComponent(String(requestParameters.date)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified work schedule exception for employee.
   * Deletes a work schedule exception for an employee.
   */
  async deleteEmployeeWorkScheduleException(
    requestParameters: DeleteEmployeeWorkScheduleExceptionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteEmployeeWorkScheduleExceptionRaw(requestParameters, initOverrides);
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForEmployeeRaw(
    requestParameters: FetchAttachmentForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling fetchAttachmentForEmployee.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentForEmployee.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentForEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForEmployee(
    requestParameters: FetchAttachmentForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentForEmployeeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of an employee.
   */
  async fetchAttachmentLinkForEmployeeRaw(
    requestParameters: FetchAttachmentLinkForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling fetchAttachmentLinkForEmployee.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentLinkForEmployee.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentLinkForEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/attachments/{attachmentUuid}/session`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of an employee.
   */
  async fetchAttachmentLinkForEmployee(
    requestParameters: FetchAttachmentLinkForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentLinkForEmployeeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get employee for specified employee id within specified organization.
   * Gets an employee.
   */
  async fetchEmployeeRaw(
    requestParameters: FetchEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EmployeeViewModel>> {
    if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
      throw new runtime.RequiredError(
        'employeeId',
        'Required parameter requestParameters.employeeId was null or undefined when calling fetchEmployee.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{employeeId}`
          .replace(`{${'employeeId'}}`, encodeURIComponent(String(requestParameters.employeeId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeViewModelFromJSON(jsonValue));
  }

  /**
   * Get employee for specified employee id within specified organization.
   * Gets an employee.
   */
  async fetchEmployee(
    requestParameters: FetchEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EmployeeViewModel> {
    const response = await this.fetchEmployeeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for an employee.
   */
  async listAttachmentsForEmployeeRaw(
    requestParameters: ListAttachmentsForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AttachmentViewModel>>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling listAttachmentsForEmployee.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listAttachmentsForEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/attachments`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentViewModelFromJSON));
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for an employee.
   */
  async listAttachmentsForEmployee(
    requestParameters: ListAttachmentsForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AttachmentViewModel>> {
    const response = await this.listAttachmentsForEmployeeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all employees for specified organization.
   * Lists employees.
   */
  async listEmployeesRaw(
    requestParameters: ListEmployeesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<EmployeeViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listEmployees.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmployeeViewModelFromJSON));
  }

  /**
   * Get all employees for specified organization.
   * Lists employees.
   */
  async listEmployees(
    requestParameters: ListEmployeesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<EmployeeViewModel>> {
    const response = await this.listEmployeesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for an employee.
   */
  async renameAttachmentForEmployeeRaw(
    requestParameters: RenameAttachmentForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling renameAttachmentForEmployee.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling renameAttachmentForEmployee.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling renameAttachmentForEmployee.',
      );
    }

    if (
      requestParameters.attachmentPropertiesUpdateMessage === null ||
      requestParameters.attachmentPropertiesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentPropertiesUpdateMessage',
        'Required parameter requestParameters.attachmentPropertiesUpdateMessage was null or undefined when calling renameAttachmentForEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/attachments/{attachmentUuid}/properties`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AttachmentPropertiesUpdateMessageToJSON(requestParameters.attachmentPropertiesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for an employee.
   */
  async renameAttachmentForEmployee(
    requestParameters: RenameAttachmentForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameAttachmentForEmployeeRaw(requestParameters, initOverrides);
  }

  /**
   * update access for specified employee.
   * Updates access for specified employee.
   */
  async updateEmployeeAccessRaw(
    requestParameters: UpdateEmployeeAccessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling updateEmployeeAccess.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateEmployeeAccess.',
      );
    }

    if (requestParameters.updateAccessCommand === null || requestParameters.updateAccessCommand === undefined) {
      throw new runtime.RequiredError(
        'updateAccessCommand',
        'Required parameter requestParameters.updateAccessCommand was null or undefined when calling updateEmployeeAccess.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/:update-access`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateAccessCommandToJSON(requestParameters.updateAccessCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update access for specified employee.
   * Updates access for specified employee.
   */
  async updateEmployeeAccess(
    requestParameters: UpdateEmployeeAccessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateEmployeeAccessRaw(requestParameters, initOverrides);
  }

  /**
   * update employee gender.
   * Updates gender for an employee.
   */
  async updateEmployeeGenderRaw(
    requestParameters: UpdateEmployeeGenderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling updateEmployeeGender.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateEmployeeGender.',
      );
    }

    if (requestParameters.updateGenderCommand === null || requestParameters.updateGenderCommand === undefined) {
      throw new runtime.RequiredError(
        'updateGenderCommand',
        'Required parameter requestParameters.updateGenderCommand was null or undefined when calling updateEmployeeGender.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/:update-gender`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateGenderCommandToJSON(requestParameters.updateGenderCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update employee gender.
   * Updates gender for an employee.
   */
  async updateEmployeeGender(
    requestParameters: UpdateEmployeeGenderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateEmployeeGenderRaw(requestParameters, initOverrides);
  }

  /**
   * update employee staff group.
   * Updates staff group for an employee.
   */
  async updateEmployeeStaffGroupRaw(
    requestParameters: UpdateEmployeeStaffGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling updateEmployeeStaffGroup.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateEmployeeStaffGroup.',
      );
    }

    if (requestParameters.updateStaffGroupCommand === null || requestParameters.updateStaffGroupCommand === undefined) {
      throw new runtime.RequiredError(
        'updateStaffGroupCommand',
        'Required parameter requestParameters.updateStaffGroupCommand was null or undefined when calling updateEmployeeStaffGroup.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/:update-staff-group`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateStaffGroupCommandToJSON(requestParameters.updateStaffGroupCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update employee staff group.
   * Updates staff group for an employee.
   */
  async updateEmployeeStaffGroup(
    requestParameters: UpdateEmployeeStaffGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateEmployeeStaffGroupRaw(requestParameters, initOverrides);
  }

  /**
   * update employee work schedules.
   * Updates work schedules for an employee.
   */
  async updateEmployeeWorkSchedulesRaw(
    requestParameters: UpdateEmployeeWorkSchedulesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling updateEmployeeWorkSchedules.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateEmployeeWorkSchedules.',
      );
    }

    if (
      requestParameters.workSchedulesUpdateMessage === null ||
      requestParameters.workSchedulesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'workSchedulesUpdateMessage',
        'Required parameter requestParameters.workSchedulesUpdateMessage was null or undefined when calling updateEmployeeWorkSchedules.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/work-schedules`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: WorkSchedulesUpdateMessageToJSON(requestParameters.workSchedulesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update employee work schedules.
   * Updates work schedules for an employee.
   */
  async updateEmployeeWorkSchedules(
    requestParameters: UpdateEmployeeWorkSchedulesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateEmployeeWorkSchedulesRaw(requestParameters, initOverrides);
  }

  /**
   * update organization properties for specified organization.
   * Updates user defined properties for an employee.
   */
  async updatePropertiesForEmployeeRaw(
    requestParameters: UpdatePropertiesForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling updatePropertiesForEmployee.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updatePropertiesForEmployee.',
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling updatePropertiesForEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/properties`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update organization properties for specified organization.
   * Updates user defined properties for an employee.
   */
  async updatePropertiesForEmployee(
    requestParameters: UpdatePropertiesForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updatePropertiesForEmployeeRaw(requestParameters, initOverrides);
  }

  /**
   * Updates user defined properties for an organization.
   */
  async updateTutorialStateForEmployeeRaw(
    requestParameters: UpdateTutorialStateForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling updateTutorialStateForEmployee.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateTutorialStateForEmployee.',
      );
    }

    if (
      requestParameters.updateTutorialStateCommand === null ||
      requestParameters.updateTutorialStateCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'updateTutorialStateCommand',
        'Required parameter requestParameters.updateTutorialStateCommand was null or undefined when calling updateTutorialStateForEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/:update-tutorial-state`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateTutorialStateCommandToJSON(requestParameters.updateTutorialStateCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates user defined properties for an organization.
   */
  async updateTutorialStateForEmployee(
    requestParameters: UpdateTutorialStateForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateTutorialStateForEmployeeRaw(requestParameters, initOverrides);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for an employee.
   */
  async uploadAttachmentForEmployeeRaw(
    requestParameters: UploadAttachmentForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling uploadAttachmentForEmployee.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling uploadAttachmentForEmployee.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling uploadAttachmentForEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.contentLength !== undefined && requestParameters.contentLength !== null) {
      headerParameters['Content-Length'] = String(requestParameters.contentLength);
    }

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.body !== undefined) {
      formParams.append('body', requestParameters.body as any);
    }

    const response = await this.request(
      {
        path: `/organizations/{organizationId}/employees/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for an employee.
   */
  async uploadAttachmentForEmployee(
    requestParameters: UploadAttachmentForEmployeeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.uploadAttachmentForEmployeeRaw(requestParameters, initOverrides);
  }
}
