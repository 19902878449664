/** Size of PDF page */
import type { PDFDocumentProxy } from 'pdfjs-dist';

export interface PdfPageSize {
  width: number;
  height: number;
}

/** Get the size of the first page.
 * @param pdf The PDF document proxy.
 * @param zoom The current zoom level.
 * @returns The width and height in pixels. */
export async function firstPageSize(pdf: PDFDocumentProxy, zoom: number): Promise<PdfPageSize> {
  // Get the size of the first page and estimate rest from that
  const firstPage = await pdf.getPage(1);
  const viewport = firstPage.getViewport({ scale: zoom });
  return {
    width: viewport.width,
    height: viewport.height,
  };
}
