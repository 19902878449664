import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';

import '../../elements/d-label.js';

/**
 *
 *
 * @fires checked-changed - Dispatched immediately after changes by the user
 *
 * STATUS OK
 */
@customElement('d-checkbox')
export class DCheckbox extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      :host {
        display: block;
      }

      :host([disabled]) {
        opacity: 0.5;
      }

      .checkbox {
        display: flex;
        align-items: flex-start;
        box-sizing: border-box;
        min-height: var(--inputElementHeight);
        padding: 6px 14px 0 0;
        cursor: pointer;
      }

      :host([checklist]) .checkbox {
        padding: 4px 14px 2px 0;
      }

      :host([disabled]) .checkbox {
        cursor: default;
      }

      .checkIcon {
        color: initial;
        position: relative;
        width: 22px;
        height: 22px;
        margin-top: -2px;
        margin-right: 7px;
        cursor: pointer;
        flex: none;
      }

      :host([checklist]) .checkIcon {
        width: 20px;
        box-shadow: none;
        background: transparent url('/images/check-mini-gray.svg') -4px 2px no-repeat;
        background-size: 24px;
        opacity: 0.5;
      }

      :host([checklist]) .checkIcon[checked] {
        background-image: url('/images/check-mini-blue.svg');
        background-size: 24px;
        opacity: 1;
      }

      :host([theme-page]) .checkIcon {
        box-shadow: none;
        background-color: white;
      }

      :host([outskirts]) .checkIcon {
        border-radius: 4px;
      }

      .checkIcon[checked] {
        background-image: url('/images/checkbox-check.svg');
        background-size: 22px 22px;
      }

      :host([disabled]) .checkIcon {
        cursor: default;
      }

      .checkIcon.disabled {
        cursor: default;
        opacity: 0.7;
      }

      d-label {
        cursor: pointer;
      }

      :host([checklist]) d-label {
        flex-grow: 1;
        padding: 2px 0;
      }

      :host([disabled]) d-label {
        cursor: default;
      }

      #label.d-label {
        display: inline-block;
        margin-bottom: 6px;
        font-weight: 600;
        color: hsla(1, 0%, 0%, 0.8);
      }

      #label {
        font-weight: 600;
        font-size: 15px;
      }
    `,
  ];
  @property({ type: String })
  label = '';
  @property({ type: String })
  field = '';
  @property({ type: String, attribute: 'option-label' })
  optionLabel = '';
  @property({ type: String, attribute: 'option-field' })
  optionField = '';
  @property({ type: Boolean })
  hideTooltip = false;
  @property({ type: Boolean, attribute: 'hide-option-tooltip' })
  hideOptionTooltip = false;
  @property({ type: Boolean })
  vertical = false;
  @property({ type: Boolean })
  labelFirst = false;
  @property({ type: Boolean })
  lockDeselect = false;
  @property({ type: Boolean, attribute: 'disabled' })
  disabled = false;
  @property({ type: Boolean })
  checked = false;
  @property({ type: Boolean, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean, attribute: 'outskirts' })
  outskirts = false;
  @property({ type: Boolean, attribute: 'wrap-label', reflect: true })
  wrapLabel = false;
  @property({ type: Boolean, reflect: true })
  checklist = false;
  _checkIconClass(disabled) {
    if (disabled) {
      return 'checkIcon disabled';
    }
    return 'checkIcon';
  }

  _optionClasses(disabled) {
    if (disabled) {
      return 'option disabled';
    }
    return 'option';
  }

  clickHandler() {
    if (!this.disabled) {
      this.checked = !this.checked;
      this.dispatchEvent(
        new CustomEvent('checked-changed', {
          bubbles: true,
          composed: true,
          detail: { checked: this.checked },
        }),
      );
    }
  }

  renderContent() {
    return html`
      <div class="checkbox">
        <div ?checked=${this.checked} class="${this._checkIconClass(this.disabled)}" @click=${this.clickHandler}></div>
        <d-label
          .checklist=${this.checklist}
          classes="${this._optionClasses(this.disabled)}"
          field="${this.optionField}"
          .hideTooltip=${this.hideOptionTooltip}
          option
          .allowWrap=${this.wrapLabel}
          label="${this.optionLabel}"
          @label-click=${this.clickHandler}
        >
        </d-label>
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-checkbox': DCheckbox;
  }
}
