import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 *
 */
@customElement('d-menu')
export class DMenu extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
        overflow: hidden;
        margin-top: -1px;
      }
      :host([small]) {
        font-size: 14px;
      }
      :host > div {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid var(--borderColorOnGray);
        border-bottom: 1px solid var(--borderColorOnGray);
        margin: 0 -8px;
        padding: 8px 0 10px 0;
      }
      :host > div > div {
        padding: 4px 8px 4px 8px;
        color: var(--linkColorGray);
      }
      :host > div > div:hover,
      :host > div > div[selected] {
        color: black;
      }
      :host > div > div[selected] {
        font-weight: 500;
      }
      :host([small]) > div > div[selected] {
        font-weight: normal;
      }
      :host > div > div:not([selected]) {
        cursor: pointer;
      }
    `,
  ];

  @property({ type: Array })
  items: { value: string; text: string; disabled?: boolean }[] = [];
  @property({ type: String })
  value = '';
  @property({ type: Boolean, reflect: true })
  small = false;

  _selected(value) {
    return this.value === value;
  }

  _select(e, value) {
    if (!e.target.disabled) {
      this.value = value;
      this.dispatchEvent(
        new CustomEvent('value-changed', {
          bubbles: true,
          composed: true,
          detail: { value: value },
        }),
      );
    }
  }

  render() {
    return html`
      <div>
        ${this.items.map((item) => {
          return html`
            <div
              ?selected=${this._selected(item.value)}
              ?disabled=${item.disabled}
              @click=${(e) => this._select(e, item.value)}
            >
              ${item.text}
            </div>
          `;
        })}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-menu': DMenu;
  }
}
