import { css, html, LitElement, nothing } from 'lit';
import '../../library/fields/d-view-text.js';
import { customElement, property } from 'lit/decorators.js';
import { leavePeriodTypes } from '../../store/selectors';

/**
 *
 */
@customElement('d-view-icon-text')
export class DViewIconText extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: flex;
        align-items: baseline;
      }

      div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        color: transparent;
      }

      .work {
        border: 1px solid hsla(1, 0%, 0%, 0.15);
        background: white;
      }

      .nonwork {
        background: hsl(1, 0%, 90%);
      }

      .plustime {
        border: 1px solid hsla(1, 0%, 0%, 0.15);
        background-image: linear-gradient(hsl(196, 83%, 90%), hsla(0, 0%, 100%, 0));
      }

      .plustime:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: 20px;
        height: 4px;
        background: var(--themeColorDarkerOne);
      }

      .unconfirmed:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background-image: url(/images/hatched.png);
        background-size: 30px 30px;
      }

      .plustime.unconfirmed:after {
        width: 18px;
        height: 18px;
      }

      .calendar {
        top: -1px;
        background: url(/images/calendar.svg) -6px -6px no-repeat;
        background-size: 32px 32px;
      }
    `,
  ];

  @property({ type: String })
  type = '';
  @property({ type: Boolean, attribute: 'light-label' })
  lightLabel = false;
  @property({ type: Boolean })
  unconfirmed = false;
  @property({ type: String })
  label = '';
  @property({ type: String })
  field = '';
  @property({ type: String })
  value = '';

  _period() {
    const p = leavePeriodTypes.filter((l) => {
      return l.value === this.type;
    });
    if (p.length) {
      return p[0];
    }
    return undefined;
  }

  _periodClass() {
    let c = '';
    if (this.type === 'work' || this.type === 'plustime' || this.type === 'calendar' || this.type === 'nonwork') {
      c = this.type;
    }
    if (this.unconfirmed) {
      c += ' unconfirmed';
    }
    return c;
  }

  _periodStyle() {
    const period = this._period();
    if (period) {
      return 'background: ' + period.color;
    }
    return '';
  }

  _label() {
    if (this.label === 'NONE') {
      return '';
    }
    if (this.type === 'work') {
      return 'Arbeidstid';
    }
    if (this.type === 'plustime') {
      return 'Plusstid';
    }
    if (this.label) {
      return this.label;
    }
    const period = this._period();
    if (period) {
      return period.text;
    }
    return '';
  }

  render() {
    return html`
      ${this.type ? html` <div class=${this._periodClass()} style=${this._periodStyle()}>X</div> ` : nothing}
      <d-view-text
        .lightLabel=${this.lightLabel}
        wrap-label
        system-content
        .label=${this._label()}
        .field=${this.field}
        .value=${this.value}
      ></d-view-text>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-icon-text': DViewIconText;
  }
}
