/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';

/**
 *
 * @export
 * @interface ContactPersonViewModel
 */
export interface ContactPersonViewModel {
  /**
   *
   * @type {string}
   * @memberof ContactPersonViewModel
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonViewModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonViewModel
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonViewModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonViewModel
   */
  telephone?: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonViewModel
   */
  mobilePhone?: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonViewModel
   */
  partnerUuid: string;
  /**
   *
   * @type {string}
   * @memberof ContactPersonViewModel
   */
  accessLevel: ContactPersonViewModelAccessLevelEnum;
  /**
   *
   * @type {string}
   * @memberof ContactPersonViewModel
   */
  accessExpires?: string;
  /**
   *
   * @type {boolean}
   * @memberof ContactPersonViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof ContactPersonViewModel
   */
  draftName?: string;
  /**
   *
   * @type {boolean}
   * @memberof ContactPersonViewModel
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ContactPersonViewModel
   */
  deleted: boolean;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof ContactPersonViewModel
   */
  attachments: Array<AttachmentViewModel>;
}

/**
 * @export
 */
export const ContactPersonViewModelAccessLevelEnum = {
  None: 'NONE',
  Readonly: 'READONLY',
  User: 'USER',
  Assets: 'ASSETS',
} as const;
export type ContactPersonViewModelAccessLevelEnum =
  (typeof ContactPersonViewModelAccessLevelEnum)[keyof typeof ContactPersonViewModelAccessLevelEnum];

/**
 * Check if a given object implements the ContactPersonViewModel interface.
 */
export function instanceOfContactPersonViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'lastName' in value;
  isInstance = isInstance && 'firstName' in value;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'partnerUuid' in value;
  isInstance = isInstance && 'accessLevel' in value;
  isInstance = isInstance && 'hasDraft' in value;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'deleted' in value;
  isInstance = isInstance && 'attachments' in value;

  return isInstance;
}

export function ContactPersonViewModelFromJSON(json: any): ContactPersonViewModel {
  return ContactPersonViewModelFromJSONTyped(json, false);
}

export function ContactPersonViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPersonViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    email: !exists(json, 'email') ? undefined : json['email'],
    lastName: json['lastName'],
    firstName: json['firstName'],
    telephone: !exists(json, 'telephone') ? undefined : json['telephone'],
    mobilePhone: !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
    uuid: json['uuid'],
    partnerUuid: json['partnerUuid'],
    accessLevel: json['accessLevel'],
    accessExpires: !exists(json, 'accessExpires') ? undefined : json['accessExpires'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    isConfirmedEntity: json['isConfirmedEntity'],
    deleted: json['deleted'],
    attachments: (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function ContactPersonViewModelToJSON(value?: ContactPersonViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    notes: value.notes,
    email: value.email,
    lastName: value.lastName,
    firstName: value.firstName,
    telephone: value.telephone,
    mobilePhone: value.mobilePhone,
    uuid: value.uuid,
    partnerUuid: value.partnerUuid,
    accessLevel: value.accessLevel,
    accessExpires: value.accessExpires === undefined ? undefined : value.accessExpires,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    isConfirmedEntity: value.isConfirmedEntity,
    deleted: value.deleted,
    attachments: (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
