import { css, html, LitElement } from 'lit';
import '../../library/elements/d-label.js';
import '../../library/fields/d-spinner-robot.js';
import { customElement, property } from 'lit/decorators.js';
import type { GestureType } from 'src/library/fields/d-spinner-robot.js';

/**
 *
 * STATUS OK
 */
@customElement('d-smart-task-info')
export class DSmartTaskInfo extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      position: relative;
      min-height: 38px;
    }
    d-spinner-robot {
      flex: none;
      top: 4px;
      width: 36px;
      height: 36px;
    }
    div {
      margin-left: 50px;
    }
    d-label {
      display: inline;
    }
    span {
      display: inline;
      font-family: var(--mainSans), sans-serif;
      line-height: var(--lineHeightDefault);
    }
  `;

  @property({ type: String })
  functionName = '';
  @property({ type: String })
  smartTaskEventText = '';
  render() {
    const gesture: GestureType[] = ['blink', 'peekRight', 'happy'];
    return html`
      <d-spinner-robot size="36" .gesture=${gesture}></d-spinner-robot>
      <div>
        <d-label field="tasks_smartTask" allow-wrap></d-label>
        <span>
          ${this.functionName} tildeles automatisk en oppgave knyttet til denne rutinen ${this.smartTaskEventText}
        </span>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-smart-task-info': DSmartTaskInfo;
  }
}
