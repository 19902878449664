import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sortBy } from 'lodash';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import { ReportSection } from 'src/pages/staffing-page/report-section.js';
import { holidaysForStaffingCalendar, leavePeriodTypes } from 'src/store/selectors';
import { DayOfWeek, LocalDate } from 'src/utilities/local-date.js';

import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/editors/elements/d-select-tag.js';
import '../../library/elements/d-section.js';
import '../../library/fields/d-expansion.js';
import { commonFieldsByCode } from 'src/store/fields';

interface StatisticsPeriodSummary {
  daysText: string;
  start: string;
  end: string;
  periodsText: string;
  days: number;
}

interface PeriodData {
  daysSumText: string;
  periods: StatisticsPeriodSummary[];
  periodsSumText: string;
  periodsSum: number;
  daysSum: number;
}

interface EmployeeData {
  selected: boolean;
  name: string;
  periodData: PeriodData;
}

interface PeriodType {
  selected: boolean;
  daysSumText: string;
  value: string;
  periodsSum: number;
  periodsSumText: string;
  name: string;
  employeesData: EmployeeData[];
}

interface StatsData {
  periodTypes: PeriodType[];
}

interface EmployeePeriod {
  start: string;
  end: string;
  type: string;
  confirmed: boolean;
}

export interface StaffingStatisticsEmployee {
  leavePeriods: EmployeePeriod[];
  displayName: string;
  uuid: string;
}

/**
 *
 */
@customElement('d-staffing-statistics')
export class DStaffingStatistics extends ReportSection {
  static readonly styles = [
    ...ReportSection.styles,
    css`
      :host {
        display: flex;
        flex-direction: column;
      }

      .col-1 {
        flex: 1;
        min-width: 130px;
        margin-right: 10px;
      }

      .col-2 {
        width: 200px;
        flex: none;
        margin-right: 10px;
      }

      .col-3 {
        width: 170px;
        flex: 1;
      }

      .employeeRow .col-1 {
        font-weight: 500;
      }

      @media only screen and (max-width: 500px) {
        .col-1 {
          flex: none;
          width: 100%;
        }

        .periodHeader .col-1,
        .employeeRow .col-1 {
          margin-bottom: 8px;
        }
      }

      .maxWidth500 .col-1 {
        flex: none;
        width: 100%;
      }

      .maxWidth500 .periodHeader .col-1,
      .maxWidth500 .employeeRow .col-1 {
        margin-bottom: 8px;
      }

      #year {
        flex: none;
      }

      .periodTypeRow {
        align-items: stretch;
        max-width: 500px;
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid var(--borderColorOnGray);
        border-bottom: 1px solid var(--borderColorOnGray);
        margin-top: -1px;
        padding: 8px 0;
      }

      .periodTypeRowContent {
        flex: 1;
        min-width: 310px;
      }

      .periodHeader {
        display: flex;
        flex-wrap: wrap;
        margin-top: -1px;
        border: none;
        padding-top: 4px;
        padding-bottom: 10px;
        font-weight: bold;
      }

      .periodTypeColor {
        width: 8px;
        flex: none;
        margin-right: 12px;
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid var(--borderColorOnGray);
        border-bottom: 1px solid var(--borderColorOnGray);
        margin-top: -1px;
        padding: 8px 0;
      }

      .employeeRow {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid var(--borderColorOnGray);
        border-bottom: 1px solid var(--borderColorOnGray);
        margin-top: -1px;
        padding: 8px 0;
      }

      .employeeRow:last-of-type {
        border-bottom: none;
      }

      .periods {
        padding-bottom: 6px;
      }

      .periodRow {
        display: flex;
        flex-wrap: wrap;
        margin-top: -1px;
        padding: 6px 0;
        font-weight: 200;
        color: hsla(0, 0%, 0%, 0.6);
        cursor: pointer;
      }

      body:not(.touch) .periodRow:hover,
      .periodRow:active {
        color: black;
        background: hsla(0, 0%, 100%, 0.3);
      }

      .none {
        font-weight: normal;
        font-style: italic;
      }

      .genderSelector {
        max-width: 743px;
      }

      .genderSelector > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--borderColorOnGray);
        border-bottom: 1px solid var(--borderColorOnGray);
        margin-top: -1px;
        padding: 8px 0;
      }

      .genderSelector d-select-gender {
        flex: none;
        margin-bottom: -4px;
      }

      .stats {
        position: relative;
        min-height: 193px;
        max-width: 900px;
      }

      .stats #label {
        margin-bottom: 0;
      }

      .gender {
        min-width: 320px;
      }

      .stats.updating .content {
        filter: blur(2px);
      }

      .progressDimmer {
        position: absolute;
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: hsla(0, 0%, 90%, 0.5);
        display: none;
      }

      .stats.updating .progressDimmer {
        display: block;
      }

      .progressDimmer .progress {
        margin: 72px auto;
        display: inline-flex;
        align-items: center;
        background-color: var(--themeColorDarkerOne);
        padding: 16px;
        border-radius: 6px;
        color: white;
        font-weight: 500;
      }

      div.vacation {
        background-color: hsl(80, 70%, 50%);
      }

      div.leave {
        background-color: hsl(130, 50%, 60%);
      }

      div.timeOff {
        background-color: hsl(190, 50%, 60%);
      }

      div.seminar {
        background-color: hsl(220, 50%, 60%);
      }

      div.jobTravel {
        background-color: hsl(257, 45%, 59%);
      }

      div.homeOffice {
        background-color: hsl(280, 100%, 77%);
      }

      div.sickLeave {
        background-color: hsl(324, 93%, 59%);
      }

      div.sickSelf {
        background-color: hsl(15, 95%, 49%);
      }

      div.sickChildren {
        background-color: hsl(31, 92%, 51%);
      }

      div.OTHER {
        background-color: hsl(50, 80%, 60%);
      }
    `,
  ];
  @property({ type: Boolean })
  show = false;
  @property({ type: Array })
  employees: StaffingStatisticsEmployee[] = [];
  @property({ type: String })
  selectedYear = LocalDate.now().year().toString();
  @property({ type: Array })
  selectedDisplayOptions: string[] = [];
  @property({ type: Array })
  selectedLeavePeriodTypes: string[] = [];
  @property({ type: Array })
  selectedEmployees: string[] = [];
  private fieldsByCode = commonFieldsByCode();
  private vacationDaysInfo = this.fieldsByCode['staffing_vacationRules'].tooltip;

  constructor() {
    super('Fraværsoversikt');
  }

  get start() {
    return '' + this.selectedYear + '-01-01';
  }

  get end() {
    return '' + this.selectedYear + '-12-31';
  }

  private get statsData(): StatsData {
    const data: StatsData = {
      periodTypes: [],
    };

    const startDate = LocalDate.fromString(this.start);
    const endDate = LocalDate.fromString(this.end);

    leavePeriodTypes.forEach((periodType) => {
      const employeesData = this.getEmployeeDataForLeaveType(startDate, endDate, this.employees, periodType.value);
      let periodsSum = 0;
      let daysSum = 0;
      employeesData.forEach((employeeData) => {
        if (employeeData.selected) {
          periodsSum += employeeData.periodData.periodsSum;
          daysSum += employeeData.periodData.daysSum;
        }
      });
      let periodsSumText = periodsSum + ' periode';
      if (periodsSum !== 1) {
        periodsSumText += 'r';
      }
      let daysSumText = daysSum + ' dag';
      if (daysSum !== 1) {
        daysSumText += 'er';
      }
      const periodType2 = {
        value: periodType.value,
        name: periodType.text,
        selected: this.selectedLeavePeriodTypes.includes(periodType.value),
        employeesData: employeesData,
        periodsSum: periodsSum,
        daysSum: daysSum,
        periodsSumText: periodsSumText,
        daysSumText: daysSumText,
      };
      data.periodTypes.push(periodType2);
    });

    return data;
  }

  _is(a, b) {
    return a === b;
  }

  _isZero(n) {
    return n === 0;
  }

  _containsValue(list, value) {
    return list.indexOf(value) > -1;
  }

  _years() {
    const startYear = 2018;
    const endYear = 2049;
    const options: SelectDropdownOption[] = [];
    for (let i = startYear; i <= endYear; i++) {
      options.push({
        value: i.toString(),
        text: i.toString(),
      });
    }
    return options;
  }

  _displayOptions(o) {
    if (o.includes('showEmployees')) {
      return [
        { value: 'showEmployees', text: 'Vis personer' },
        { value: 'showPeriods', text: 'Vis perioder' },
      ];
    } else {
      return [{ value: 'showEmployees', text: 'Vis personer' }];
    }
  }

  _leavePeriodTypeOptions() {
    const options: SelectTagOption[] = [];
    const types = leavePeriodTypes;
    for (const item of types) {
      options.push({
        value: item.value,
        text: item.text,
        styleForSelected: 'background: ' + item.color + ' ;border-color: ' + item.color,
      });
    }
    return options;
  }

  _employeeOptions(employees) {
    const options: SelectTagOption[] = [];
    for (const item of employees) {
      options.push({
        value: item.uuid,
        text: item.displayName,
      });
    }
    return options;
  }

  _leavePeriodClasses(type) {
    return 'periodTypeColor ' + type;
  }

  _periodTap(e) {
    this.dispatchEvent(
      new CustomEvent('period-tap', { bubbles: true, composed: true, detail: e.target.getAttribute('data-day') }),
    );
  }

  renderContent() {
    return html`
      <d-section>
        <d-select-tag
          label="Fraværstyper"
          theme-page
          toggle-all
          controller
          .options=${this._leavePeriodTypeOptions()}
          .value=${this.selectedLeavePeriodTypes}
          @value-changed=${this.onLeavePeriodTypesChanged}
        >
        </d-select-tag>
      </d-section>
      <d-section>
        <d-select-tag
          theme-page
          toggle-all
          controller
          label="Personer"
          .options=${this._employeeOptions(this.employees)}
          .value=${this.selectedEmployees}
          @value-changed=${this.onSelectedEmployeesChanged}
        ></d-select-tag>
      </d-section>
      <d-section>
        <d-select-dropdown
          id="year"
          label="År"
          theme-page
          .options=${this._years()}
          .value=${this.selectedYear}
          @value-changed=${this.onSelectedYearChanged}
        ></d-select-dropdown>
        <d-select-tag
          controller
          theme-page
          label="Visningsvalg"
          .options=${this._displayOptions(this.selectedDisplayOptions)}
          .value=${this.selectedDisplayOptions}
          @value-changed=${this.onSelectedDisplayOptionsChanged}
        ></d-select-tag>
      </d-section>

      ${this.statsData.periodTypes.map(
        (type) => html`
          <d-expansion ?opened=${type.selected}>
            <div class="periodTypeRow">
              <div class="${this._leavePeriodClasses(type.value)}"></div>
              <div class="periodTypeRowContent">
                <div class="periodHeader">
                  <div class="col-1">${type.name}</div>
                  <div class="col-2">
                    ${this._isZero(type.periodsSum) ? nothing : html` <span>${type.periodsSumText}</span>`}
                    ${!this._isZero(type.periodsSum) ? nothing : html`<span class="none">Ingen</span>`}
                  </div>
                  <div class="col-3">
                    ${this._isZero(type.periodsSum)
                      ? nothing
                      : html`<span
                          >${type.daysSumText}
                          ${!this._is(type.value, 'vacation')
                            ? nothing
                            : html` <d-tooltip content="${this.vacationDaysInfo}"></d-tooltip>`}
                        </span>`}
                  </div>
                </div>
                <d-expansion ?opened=${this._containsValue(this.selectedDisplayOptions, 'showEmployees')}>
                  ${type.employeesData.map(
                    (employeeData) => html`
                      <d-expansion ?opened=${employeeData.selected}>
                        <div class="employeeRow">
                          <div class="col-1">${employeeData.name}</div>
                          <div class="col-2">
                            ${this._isZero(employeeData.periodData.periodsSum)
                              ? nothing
                              : html` <span> ${employeeData.periodData.periodsSumText} </span>`}
                            ${!this._isZero(employeeData.periodData.periodsSum)
                              ? nothing
                              : html`<span class="none">Ingen</span>`}
                          </div>
                          <div class="col-3">
                            ${this._isZero(employeeData.periodData.periodsSum)
                              ? nothing
                              : html` <span> ${employeeData.periodData.daysSumText} </span>`}
                          </div>
                        </div>
                        <d-expansion ?opened=${this._containsValue(this.selectedDisplayOptions, 'showPeriods')}>
                          <div class="periods">
                            ${employeeData.periodData.periods.map(
                              (period) =>
                                html` <div
                                  class="periodRow"
                                  data-day="${period.start}"
                                  @click=${(e) => this._periodTap(e)}
                                >
                                  <div class="col-1" data-day="${period.start}"></div>
                                  <div class="col-2" data-day="${period.start}">${period.periodsText}</div>
                                  <div class="col-3" data-day="${period.start}">${period.daysText}</div>
                                </div>`,
                            )}
                          </div>
                        </d-expansion>
                      </d-expansion>
                    `,
                  )}
                </d-expansion>
              </div>
            </div>
          </d-expansion>
        `,
      )}
    `;
  }

  private periodInRange(periodStart: LocalDate, periodEnd: LocalDate, rangeStart: LocalDate, rangeEnd: LocalDate) {
    return (
      (periodStart.isSameOrBefore(rangeEnd) && periodStart.isSameOrAfter(rangeStart)) ||
      (periodEnd.isSameOrAfter(rangeStart) && periodStart.isSameOrBefore(rangeEnd))
    );
  }

  private holidaysCount(periodStart: LocalDate, periodEnd: LocalDate) {
    const holidays = holidaysForStaffingCalendar();
    const daysCount = periodStart.until(periodEnd) + 1;
    let holidaysCount = 0;
    for (let i = 0; i < daysCount; i++) {
      const day = periodStart.plusDays(i);
      if (day.dayOfWeek() === DayOfWeek.SUNDAY || holidays[day.toString()]) {
        holidaysCount += 1;
      }
    }
    return holidaysCount;
  }

  private calculatePeriods(type: string, periods: EmployeePeriod[], start: LocalDate, end: LocalDate) {
    const result: StatisticsPeriodSummary[] = [];

    const sortedPeriods = sortBy(periods, [(item) => item.start]);
    sortedPeriods.forEach((period) => {
      const periodStart = LocalDate.fromString(period.start);
      const periodEnd = LocalDate.fromString(period.end);
      if (period.type === type && period.confirmed && this.periodInRange(periodStart, periodEnd, start, end)) {
        let croppedStart = periodStart;
        if (periodStart.isBefore(start)) {
          croppedStart = start;
        }
        let croppedEnd = periodEnd;
        if (periodEnd.isAfter(end)) {
          croppedEnd = end;
        }
        let periodsText = croppedStart.toStringForDisplay();
        if (!croppedStart.isSame(croppedEnd)) {
          periodsText += ' – ' + croppedEnd.toStringForDisplay();
        }
        let days = croppedStart.until(croppedEnd) + 1;
        if (period.type === 'vacation') {
          days = days - this.holidaysCount(croppedStart, croppedEnd);
        }
        let daysText = days + ' dag';
        if (days !== 1) {
          daysText += 'er';
        }
        result.push({
          start: croppedStart.toString(),
          end: croppedEnd.toString(),
          days: days,
          periodsText: periodsText,
          daysText: daysText,
        });
      }
    });

    return result;
  }

  private getEmployeePeriodData(start: LocalDate, end: LocalDate, periods: EmployeePeriod[], type: string): PeriodData {
    const calculatedPeriods = this.calculatePeriods(type, periods, start, end);
    const periodsSum = calculatedPeriods.length;
    let daysSum = 0;
    calculatedPeriods.forEach(function (item) {
      daysSum += item.days;
    });
    let periodsSumText = periodsSum + ' periode';
    if (periodsSum !== 1) {
      periodsSumText += 'r';
    }
    let daysSumText = daysSum + ' dag';
    if (daysSum !== 1) {
      daysSumText += 'er';
    }
    return {
      periodsSum: periodsSum,
      daysSum: daysSum,
      periodsSumText: periodsSumText,
      daysSumText: daysSumText,
      periods: calculatedPeriods,
    };
  }

  private getEmployeeDataForLeaveType(
    start: LocalDate,
    end: LocalDate,
    employees: StaffingStatisticsEmployee[],
    type: string,
  ): EmployeeData[] {
    return employees.map((employee) => {
      return {
        name: employee.displayName,
        selected: this.selectedEmployees.indexOf(employee.uuid) > -1,
        periodData: this.getEmployeePeriodData(start, end, employee.leavePeriods, type),
      };
    });
  }

  private onLeavePeriodTypesChanged(e: CustomEvent<{ value: string[] }>) {
    e.stopPropagation();
    this.selectedLeavePeriodTypes = e.detail.value;
  }

  private onSelectedEmployeesChanged(e: CustomEvent<{ value: string[] }>) {
    e.stopPropagation();
    this.selectedEmployees = e.detail.value;
  }

  private onSelectedYearChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.selectedYear = e.detail.value;
  }

  private onSelectedDisplayOptionsChanged(e: CustomEvent<{ value: string[] }>) {
    e.stopPropagation();
    this.selectedDisplayOptions = e.detail.value;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-staffing-statistics': DStaffingStatistics;
  }
}
