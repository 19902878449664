import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../library/editors/components/d-select-dropdown-or-add.js';
import '../../../../library/elements/d-wrap.js';

import '../../../../library/editors/elements/d-add-remove-buttons.js';
import { NewComputerDialog, NewComputerResult } from './new-computer-dialog.js';
import {
  type InfosecStorageUnit,
  computerTypeOptions,
  type InfosecNetwork,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { ResponsiveTable } from 'src/library/abstracts/responsive-table.js';
import type { ComputerNetworkChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-computers-network.js';
import { uuid } from 'src/utilities/text.js';

/**
 * Edit the computers connected to a single network. Allows adding new computers.
 *
 * Existing computers connected to this network may only be disconnected. This does not delete the computer.
 *
 * @fires computer-network-change - A computer that previously was unconnected, is connected to this network or a
 *   new computer is created and connected to this network. Also fired if a computer is disconnected from this network.
 *   @fires items-changed - The list of computers changed. Used to sync with the other editors of networks.
 *
 */
@customElement('d-edit-network-computers')
export class DEditNetworkComputers extends LitElement {
  static readonly styles = [
    ...ResponsiveTable.styles,
    css`
      :host {
        display: block;
      }
      d-wrap {
        flex: 1;
      }
      d-select-dropdown-or-add {
        flex: 1;
        margin-right: 0;
      }
    `,
  ];

  @property({ type: Object })
  network!: InfosecNetwork;
  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];

  editedComputerUuid = '';
  private get networkItems(): InfosecStorageUnit[] {
    const computers = this.computers.filter((computer) => {
      return computer.networkUuid === this.network.uuid;
    });
    if (computers.length === 0) {
      computers.push({
        uuid: '',
        name: '',
        unitType: '',
        computerType: '',
        application: '',
        partnerUuid: '',
        networkUuid: '',
      });
    }
    return computers;
  }

  _options(computerUuid): SelectDropdownOption[] {
    return this.computers
      .filter((computer) => {
        if (computer.uuid === '') {
          return false;
        }
        if (computer.uuid === computerUuid) {
          return true;
        }
        if (computer.networkUuid === '') {
          return true;
        }
      })
      .map((computer) => {
        let name = computer.name;
        if (!name) {
          const types = computerTypeOptions.filter((item) => {
            return item.value === computer.computerType;
          });
          if (types.length) {
            name = types[0].text;
          }
        }
        return {
          value: computer.uuid,
          text: name,
        };
      });
  }

  async _selectorChanged(computerUuid, value) {
    if (value === 'NEW') {
      this.editedComputerUuid = computerUuid;
      const result: NewComputerResult = await NewComputerDialog.open({
        name: '',
        requireNameForServers: this.computers.filter((c) => c.computerType === 'Server').length !== 0,
      });
      if (result.action === 'done') {
        this.dispatchEvent(
          new CustomEvent<ComputerNetworkChange>('computer-network-change', {
            composed: true,
            bubbles: true,
            detail: {
              computerUuid: uuid(),
              networkUuid: this.network.uuid,
              newNetwork: false,
              newComputer: true,
              newComputerName: result.name,
              newComputerType: result.type,
            },
          }),
        );
        this.editedComputerUuid = '';
      }
    } else if (value === '') {
      this.computers = this.computers.map((computer) => {
        if (computer.uuid === computerUuid) {
          computer.networkUuid = '';
        }
        return computer;
      });
      this._dispatchItemsChanged();
    } else {
      this.computers = this.computers.map((computer) => {
        if (computer.uuid === computerUuid) {
          computer.networkUuid = '';
        }
        if (computer.uuid === value) {
          computer.networkUuid = this.network.uuid;
        }
        return computer;
      });
      this._dispatchItemsChanged();
      this.dispatchEvent(
        new CustomEvent<ComputerNetworkChange>('computer-network-change', {
          composed: true,
          bubbles: true,
          detail: {
            computerUuid: value,
            networkUuid: this.network.uuid,
            newNetwork: false,
            newComputer: false,
          },
        }),
      );
    }
  }

  _removeItem(uuid) {
    this.computers = this.computers.map((computer) => {
      if (computer.uuid === uuid) {
        computer.networkUuid = '';
      }
      return computer;
    });
    this.computers = JSON.parse(JSON.stringify(this.computers));
    this._dispatchItemsChanged();
    this.dispatchEvent(
      new CustomEvent<ComputerNetworkChange>('computer-network-change', {
        composed: true,
        bubbles: true,
        detail: {
          computerUuid: uuid,
          networkUuid: '',
          newNetwork: false,
          newComputer: false,
        },
      }),
    );
  }

  _addItem() {
    this.computers.push({
      uuid: '',
      name: '',
      unitType: 'computers',
      computerType: '',
      application: '',
      partnerUuid: '',
      networkUuid: this.network.uuid,
    });
    this.computers = JSON.parse(JSON.stringify(this.computers));
  }

  _dispatchItemsChanged() {
    this.dispatchEvent(
      new CustomEvent('items-changed', {
        bubbles: true,
        composed: true,
        detail: this.computers.filter((computer) => {
          return computer.uuid !== '';
        }),
      }),
    );
  }

  render() {
    return html`
      ${this.networkItems.map((computer, index) => {
        return html`
          <d-wrap nowrap>
            <d-select-dropdown-or-add
              theme-page
              placeholder="Velg enhet"
              firstItemText="Registrer enhet"
              additionalItemText="Annen enhet"
              ?disabled=${computer.uuid !== ''}
              .options=${this._options(computer.uuid)}
              .value=${computer.uuid}
              @value-changed=${(e) => this._selectorChanged(computer.uuid, e.detail.value)}
            ></d-select-dropdown-or-add>
            <d-add-remove-buttons
              ?showRemove=${computer.networkUuid !== ''}
              ?showAdd=${computer.networkUuid !== '' && index === this.networkItems.length - 1}
              @remove=${() => this._removeItem(computer.uuid)}
              @add=${() => this._addItem()}
            ></d-add-remove-buttons>
          </d-wrap>
        `;
      })}
    `;
  }
}

/*
 <d-popup-new-computer
        .show=${this.popup}
        .computers=${this.computers}
        @cancel=${() => this._cancelPopup()}
        @done=${(e) => this._donePopup(e.detail)}
      ></d-popup-new-computer>
 */
declare global {
  interface HTMLElementTagNameMap {
    'd-edit-network-computers': DEditNetworkComputers;
  }
}
