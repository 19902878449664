import { html } from 'lit';
import '../../../../library/editors/elements/d-select-dropdown.js';
import '../../../../library/editors/elements/d-edit-text.js';
import '../../../../library/elements/d-section.js';
import '../../../../library/fields/d-expansion.js';
import '../../../../library/components/d-popup.js';
import { customElement, property } from 'lit/decorators.js';
import { computerTypeOptions } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';

export interface NewComputerInput {
  name: string;
  requireNameForServers: boolean;
}

export interface NewComputerResult {
  name: string;
  type: string;
  action: 'cancel' | 'done';
}

/**
 * Dialog to create new computer or server. This is a two-step procedure where the user first selects the type and then
 * adds a name. `DONE` is disabled if the user has not selected a type or if the name is required but empty.
 *
 * For servers the user is not prompted for a name for the first server. If there are existing servers then the user is
 * required to name the sever also.
 *
 * @fires cancel - the parent element must close the dialog
 * @fires done - the parent element must close the dialog
 *
 *
 * USAGE:
 *    d-data-items
 *    d-edit-data-item-accounting
 *    d-edit-data-item-application
 *    d-edit-data-item-asset
 *    d-edit-data-item-computer
 *    d-edit-network-computers
 *
 */
@customElement('new-computer-dialog')
export class NewComputerDialog extends BaseDialog<NewComputerInput, NewComputerResult> {
  /**
   * Current value of the type
   */
  @property({ type: String })
  type = '';
  /**
   * Current value of the name
   */
  @property({ type: String })
  name = '';
  /**
   * Require name for servers. First server can be without name. Used to determine if there are already servers registered.
   */
  @property({ type: Boolean })
  requireNameForServers = false;
  width = 400;
  title = 'Ny datamaskin';

  protected get calculatedHeaderActions() {
    let disabled = true;
    if (this.type && (this.name || !this.nameRequired)) {
      disabled = false;
    }
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Ferdig', action: 'done', disabled: disabled },
    ];
  }

  private get nameRequired() {
    return !(this.type === '' || (this.type === 'Server' && !this.requireNameForServers));
  }

  _typeChange(e) {
    this.type = e.detail.value;
  }

  _nameChange(e) {
    this.name = e.detail.value;
  }

  protected renderBody() {
    return html`
      <d-section>
        <d-select-dropdown
          label="Type"
          placeholder="Velg type"
          .value=${this.type}
          .options=${computerTypeOptions}
          @value-changed=${(e) => this._typeChange(e)}
        ></d-select-dropdown>
      </d-section>
      <d-expansion .opened=${this.nameRequired}>
        <d-section>
          <d-edit-text
            label="Navn"
            placeholder="Gi enheten et navn"
            .value=${this.name}
            @value-changed=${(e) => this._nameChange(e)}
          ></d-edit-text>
        </d-section>
      </d-expansion>
    `;
  }

  protected fetchResult(detail: string | undefined): NewComputerResult {
    return {
      name: detail === 'done' ? this.name : '',
      type: detail === 'done' ? this.type : '',
      action: detail === 'done' ? 'done' : 'cancel',
    };
  }

  protected initializeDialog(input: NewComputerInput) {
    this.name = input.name;
    this.requireNameForServers = input.requireNameForServers;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'new-computer-dialog': NewComputerDialog;
  }
}
