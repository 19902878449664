import { Frequency, InstanceRule, RecurrenceRuleOptions } from './instance-rules/instance-rule.js';
import { Days, getMonthName, getWeekdayName, WeekdayStr } from './date.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { SingleInstanceRule } from './instance-rules/single-instance-rule.js';
import { instanceRuleFromOptions } from './instance-rules/instance-rule-factory.js';
import { LocalTime } from 'src/utilities/local-time.js';
import { endYear } from 'src/store/config';

/**
 * The RecurrenceRule class for working with recurring dates. The rules are a subset of
 * the ICAL[https://tools.ietf.org/html/rfc5545] spec format.
 *
 * Specifically only granularity above DAILY is supported.
 *
 * The following rules are supported:
 *
 * NONE: (single instance of none repeated date)
 * DAILY: (interval must be 1)
 * WEEKLY: (interval between 1 and 6), BYDAY must be set and can be multiple values.
 *
 * MONTHLY:
 *    either: BYDAY AND BYSETPOS 1-4 eller -1, BYDAY must be single value
 *    or: BYMONTHDAY
 *
 * YEARLY:
 *    either: BYDAY AND BYSETPOS AND BYMONTH
 *    or: BYMONTHDAY og BYMONTH
 *
 *
 *
 *  UNTIL: For all frequencies except NONE, until is the date of the last occurrence. Until is
 *  included in the set
 *
 *  START: For all types start is the first recurrence. It must also match the rule so it is an error
 *  if the start date is a day of week not included in the BYDAY array or if the start date does not
 *  match bymonth or bymonthday
 */
export class RecurrenceRule {
  readonly start: LocalDate;
  private readonly time: LocalTime | null;
  private readonly options: Partial<RecurrenceRuleOptions>;
  private readonly instanceRule: InstanceRule;
  private maxDate = LocalDate.of(endYear + 1, 1, 1);

  public constructor(options: Partial<RecurrenceRuleOptions>) {
    this.options = options;
    if (this.options.start !== undefined) {
      this.start = LocalDate.fromRecurrenceRuleString(this.options.start);
    } else {
      throw new Error('Illegal start date ' + this.options.start);
    }

    if (this.options.byHour !== undefined && this.options.byMinute !== undefined) {
      this.time = LocalTime.of(this.options.byHour, this.options.byMinute);
    } else {
      this.time = null;
    }

    this.instanceRule = instanceRuleFromOptions(this.options);
  }

  public static calculateRecurrenceRule(dateTime: string, recurrence: string): string {
    return dateTime === '' ? '' : RecurrenceRule.fromStringWithDateTime(recurrence, dateTime).toString();
  }

  public static fromString(rule: string): RecurrenceRule {
    if (rule === '') {
      throw new TypeError('empty rule passed to RecurrenceRule constructor');
    }

    const options = this.parseRule(rule);
    return new RecurrenceRule(options);
  }

  public static hasStartDate(rule: string): boolean {
    if (rule === '') {
      return false;
    }

    const options = this.parseRule(rule);
    return options.start !== undefined;
  }

  public static fromStringWithDateTime(rule: string, dateAndTime: string): RecurrenceRule {
    const v4 = dateAndTime.split(' ');
    const start = v4[0].split('-').join('');
    const r = rule === '' ? 'FREQ=DAILY;COUNT=1' : rule;
    let h = '';
    if (v4.length === 2) {
      const hhmm = v4[1].split(':');
      h = ';BYHOUR=' + hhmm[0] + ';BYMINUTE=' + hhmm[1] + ';BYSECOND=0';
    }
    const newRruleForNextSeries = 'DTSTART=' + start + ';' + r + h;

    return this.fromString(newRruleForNextSeries);
  }

  private static formatLocalDate(date: LocalDate, until?: LocalDate): string {
    const y = until === undefined || date.isAfter(until) ? ' ' + date.year() : '';
    return getWeekdayName(date.dayOfWeek()) + ' ' + date.day() + '. ' + getMonthName(date.month()) + y;
  }

  private static parseRule(rule: string): Partial<RecurrenceRuleOptions> {
    const attrs = rule.split(';');
    const options: Partial<RecurrenceRuleOptions> = {};

    attrs.forEach((attr) => {
      const [key, value] = attr.split('=');
      switch (key.toUpperCase()) {
        case 'FREQ':
          options.freq = Frequency[value.toUpperCase() as keyof typeof Frequency];
          break;
        case 'COUNT':
          options.count = Number(value);
          break;
        case 'INTERVAL':
          options.interval = Number(value);
          break;
        case 'BYSETPOS':
          options.bySetPos = Number(value);
          break;
        case 'BYMONTH':
          options.byMonth = Number(value);
          break;
        case 'BYHOUR':
          options.byHour = Number(value);
          break;
        case 'BYMINUTE':
          options.byMinute = Number(value);
          break;
        case 'BYSECOND':
          options.bySecond = Number(value);
          break;
        case 'BYMONTHDAY':
          options.byMonthDay = Number(value);
          break;
        case 'BYWEEKDAY':
        case 'BYDAY':
          options.byWeekDay = this.parseWeekday(value);
          break;
        case 'UNTIL':
          options.until = value;
          break;
        case 'DTSTART':
          options.start = value;
          break;
        default:
          throw new Error('Unknown RRULE property: ' + key);
      }
    });

    return options;
  }

  private static parseWeekday(value: string): WeekdayStr[] {
    const days = value.split(',');

    return days.map((day) => {
      // MO, TU, ...
      const weekdayStr = Days[day as keyof typeof Days];

      if (weekdayStr === undefined) {
        throw new Error('Unknown weekday entry property: ' + value);
      }
      return weekdayStr;
    });
  }

  public toString(): string {
    const s = 'DTSTART=' + this.start.toStringForRecurrenceRule();
    return s + ';' + this.toStringExcludingStartDateAndTime() + this.toStringForTime();
  }

  public toStringExcludingStartDateAndTime(): string {
    const r = this.instanceRule.optionsToString(this.options.interval || 1);
    const u = this.options.until ? ';UNTIL=' + this.options.until : '';
    return r + u;
  }

  public isSameExcludingStartDateAndTime(other: RecurrenceRule): boolean {
    return this.toStringExcludingStartDateAndTime() === other.toStringExcludingStartDateAndTime();
  }

  public withStartAfter(instance: LocalDate): RecurrenceRule {
    const nextInstanceDate = this.after(instance);

    if (nextInstanceDate !== null) {
      const updatedOptions = {
        ...this.options,
        ...{ start: nextInstanceDate.toStringForRecurrenceRule() },
      };
      return new RecurrenceRule(updatedOptions);
    }

    throw new Error('withStartAfter called with no later instances');
  }

  public all(): LocalDate[] {
    const u = this.options.until !== undefined ? LocalDate.fromRecurrenceRuleString(this.options.until) : this.maxDate;
    const i = this.options.interval || 1;
    return this.instanceRule.expand(this.start, u, i);
  }

  /**
   * All occurrences of the repetition. The first and last are inclusive.
   * @param first
   * @param last
   */
  public between(first: LocalDate, last: LocalDate): LocalDate[] {
    return this.all().filter((d) => !d.isBefore(first) && !d.isAfter(last));
  }

  public before(instance: LocalDate): LocalDate | null {
    const d = instance.plusDays(-1);

    const b = this.between(this.start, d);

    if (b.length === 0) {
      return null;
    } else {
      return b[b.length - 1];
    }
  }

  public sameOrAfter(instance: LocalDate): LocalDate | null {
    const b = this.between(instance, this.maxDate);
    if (b.length === 0) {
      return null;
    } else {
      return b[0];
    }
  }

  public after(instance: LocalDate): LocalDate | null {
    const d = instance.plusDays(1);
    return this.sameOrAfter(d);
  }

  public withUntilBefore(instance: LocalDate): RecurrenceRule {
    const until = this.before(instance);

    if (until !== null) {
      const updatedOptions = {
        ...this.options,
        ...{ until: until.toStringForRecurrenceRule() },
      };
      return new RecurrenceRule(updatedOptions);
    }

    throw new Error('withUntilBefore called with no earlier instances');
  }

  public instanceFormatted(instance: LocalDate, until?: LocalDate): string {
    return RecurrenceRule.formatLocalDate(instance, until) + this.formattedTime('HH.mm', ' kl. ');
  }

  public scheduleDescription(): string {
    let scheduleDescription = this.instanceRule.scheduleDescription(this.options.interval || 1);
    if (this.options.until !== undefined) {
      const d = LocalDate.fromRecurrenceRuleString(this.options.until);
      scheduleDescription += ' inntil ' + RecurrenceRule.formatLocalDate(d);
    }
    return scheduleDescription;
  }

  formattedTime(pattern: string, prefix: string): string {
    if (this.time === null) {
      return '';
    } else {
      return prefix + this.time.toStringForDisplay();
    }
  }

  public hasBefore(instance: LocalDate): boolean {
    return this.before(instance) !== null;
  }

  public hasAfter(instance: LocalDate): boolean {
    return this.after(instance) !== null;
  }

  public startDateFormatted(): string {
    return this.start.toString();
  }

  public timeDisplay(): string {
    return this.time === null ? '' : this.time.toString();
  }

  public timeForOrdering(): string {
    return this.time === null ? '00:00' : this.time.toString();
  }

  public hasTime(): boolean {
    return this.time !== null;
  }

  public isSingleInstanceRule(): boolean {
    return this.instanceRule instanceof SingleInstanceRule;
  }

  public startDate(): LocalDate {
    return this.start;
  }

  public asSingleInstance(instance: LocalDate): RecurrenceRule {
    const dateTime = instance.toString() + (this.time ? ' ' + this.time.toString() : '');
    return RecurrenceRule.fromStringWithDateTime('', dateTime);
  }

  private toStringForTime(): string {
    function zeroPad(element: number): string {
      return ('00' + element).slice(-2);
    }

    let h = '';
    if (this.time !== null) {
      h = ';BYHOUR=' + zeroPad(this.time.hour()) + ';BYMINUTE=' + zeroPad(this.time.minute()) + ';BYSECOND=0';
    }
    return h;
  }
}
