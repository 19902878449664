import { property, query } from 'lit/decorators.js';
import { IntersectionController } from '@lit-labs/observers/intersection-controller.js';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';

/**
 *
 *
 */
export class ResponsiveLevel extends ResponsiveElement {
  @query('#small-header')
  smallHeader!: HTMLElement;
  @query('#large-header')
  largeHeader!: HTMLElement;
  @query('#content')
  content!: HTMLElement;
  @property({ type: Boolean, attribute: 'prevent-header-shrink' })
  preventHeaderShrink = false;
  protected intersectionController = new IntersectionController(this, {
    callback: (entries) => this.onIntersectionChanged(entries),
    config: { threshold: [1] },
  });

  onResize() {
    const smallHeaderHeight = this.smallHeader.getBoundingClientRect().height;
    const largeHeaderHeight = this.largeHeader.getBoundingClientRect().height;
    const contentHeight = this.content.getBoundingClientRect().height;
    const smallHeight = smallHeaderHeight + contentHeight;
    const largeHeight = largeHeaderHeight + contentHeight;
    if (largeHeight > window.innerHeight && smallHeight < window.innerHeight) {
      this.style.paddingBottom = window.innerHeight - smallHeight + 4 + 'px';
    } else {
      this.style.paddingBottom = '0px';
    }
  }

  onIntersectionChanged(entries: IntersectionObserverEntry[]) {
    if (!this.preventHeaderShrink) {
      entries.forEach((e) => {
        const element = e.target as HTMLElement;
        if (!e.isIntersecting) {
          element.setAttribute('top', '');
        } else {
          element.removeAttribute('top');
        }
      });
    }
  }
}
