import { css, html, LitElement } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import './d-edit-data-item-computer.js';
import '../../../../library/elements/d-wrap.js';
import '../../../../library/fields/d-expansion.js';
import '../../../../library/editors/elements/d-select-dropdown.js';
import type { InfosecApplicationOption } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { applicationPlatformOptions } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { uuid } from 'src/utilities/text.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { NewNameDialog, NewNameResult } from 'src/library/editors/components/new-name-dialog.js';
import {
  NewComputerDialog,
  NewComputerResult,
} from 'src/pages/computers-page/infosec-procedure/editors/new-computer-dialog.js';

export interface DataItemNewSupplier {
  newSupplier: true;
  newSupplierName: string;
  supplierUuid: string;
}

export interface DataItemExistingSupplier {
  newSupplier: false;
  supplierUuid: string;
}

export type DataItemSupplier = DataItemNewSupplier | DataItemExistingSupplier;

export interface DataItemNewComputer {
  newComputer: true;
  computerUuid: string;
  newComputerName: string;
  newComputerType: string;
}

export interface DataItemExistingComputer {
  newComputer: false;
  computerUuid: string;
}

export type DataItemComputer = DataItemNewComputer | DataItemExistingComputer;

export type AddDataItemCloudServiceApplication = {
  application: string;
  storageUnitType: 'cloudServices';
} & DataItemSupplier;

export type AddDataItemRemoteServerApplication = {
  application: string;
  storageUnitType: 'remoteServers';
} & DataItemSupplier;

export type AddDataItemComputerApplication = {
  application: string;
  storageUnitType: 'computers';
} & DataItemComputer;

export type AddDataItemApplication =
  | AddDataItemComputerApplication
  | AddDataItemCloudServiceApplication
  | AddDataItemRemoteServerApplication;

export type DataItemApplicationSupplierChange =
  | {
      newSupplier: false;
      supplierUuid: string;
    }
  | {
      newSupplier: true;
      supplierUuid: string;
      newSupplierName: string;
    };

export type DataItemApplicationComputerChange =
  | {
      newComputer: false;
      computerUuid: string;
    }
  | {
      newComputer: true;
      newComputerName: string;
      newComputerType: string;
    };

export type DataItemApplicationStorageChange =
  | ({
      storageUnitType: 'cloudServices' | 'remoteServers';
    } & DataItemApplicationSupplierChange)
  | ({
      storageUnitType: 'computers';
    } & DataItemApplicationComputerChange);

export type DataItemApplicationChange = {
  uuid?: string;
  application: string;
  dataType: string;
  category: string;
} & DataItemApplicationStorageChange;

export interface ComputerApplicationDataItem {
  uuid: string;
  application: string;
  platform: 'computers';
  computerUuid: string;
}

export interface CloudServiceApplicationDataItem {
  uuid: string;
  application: string;
  platform: 'cloudServices';
  supplierUuid: string;
}

export interface RemoteServerApplicationDataItem {
  uuid: string;
  application: string;
  platform: 'remoteServers';
  supplierUuid: string;
}

export type ApplicationDataItem =
  | ComputerApplicationDataItem
  | CloudServiceApplicationDataItem
  | RemoteServerApplicationDataItem;

/**
 * The editor for the selection of application with a platform and a designated storage unit. The editor is based on a
 * list of applications where each application has a defined set of available platforms. These choices are `cloud` for
 * applications that may only be cloudServices, `standalone` for applications that can be either locally installed or
 * installed on a remote server, or `any` for applications that can be either standalone or cloud service.
 *
 * The behaviour of the element is based on the application type selected.
 *
 * For `cloud` the selection of the application is complete and the component triggers an event that results in a data item,
 * a cloud service if not already present and a partner if not already present. If the partner is already present and has
 * multiple cloud services, then an arbitrary service is selected.
 *
 * For `standalone` an event is trigger that creates the data item, but with no defined storage unit. No further changes
 * to application is allowed but the platform selection is available to choose remote server or local computer. No event
 * is triggered. Selecting either of these shows the second stage of storage unit selection where the user selects either
 * a partner or a computer. When the partner or the computer has been selected a new event is triggered. For remote servers
 * this event results in a remote server if not present with the partner as supplier and this remote server used as storage
 * unit for the data item. If the partner has multiple remote servers registered an arbitrary remote server is selected
 * for this data item. For local computer, then the event results in that computer being used as storage unit for the
 * data item.
 *
 * For `any` the platform choice will result in behaviour as described above. If the platform cloud services is selected
 * an event is fired immediately resulting in the provider from the application options being used with a possibly new
 * cloud service. No further editing is possible. If either of the other two options are selected the component behaviour
 * continues as described for `standalone` applications above.
 *
 * After the selection of the storage unit no further editing is possible. The data item is created when the application
 * is selected. If the platform is available as then the choice of remote server or local computer is transient and unstored
 * until a partner or computer is selected.
 *
 * The element supports creating new applications, computers or partners. New applications have the type `any` but do
 * not have a default provider. They therefore behave as remote servers where the user must also select a provider for
 * the cloud service. Creating a new computer results in that computer immediately being assigned to the data item. Creating
 * a new partner results in the partner being used for a new cloud service or remote server and that cloud service or remote
 * server is used for the data item.
 *
 * Cloud services and remote servers created from this element are created outside "helsenett". Computers are created
 * standalone with no network.
 *
 * For `standalone` applications the supplied default provider is not used. For `any`the provider is used only if the
 * user selects cloud service platform.
 *
 * The application types and data type/ category do not provide a default for the field `personal data`. The data item
 * is created with the field personal data as `false` and the field `data processor` as undefined.
 *
 */
@customElement('d-edit-data-item-application')
export class DEditDataItemApplication extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
        flex: 1;
      }

      d-select-dropdown,
      d-select-dropdown-or-add {
        margin-bottom: 12px;
      }

      :host([small-label]) d-select-dropdown,
      :host([small-label]) d-select-dropdown-or-add {
        margin-bottom: 8px;
      }

      .hidden,
      .hidden > * {
        margin-bottom: 0;
        height: 0;
        overflow: hidden;
      }
    `,
  ];

  /**
   * Determines if elements should be created
   * when user has made the selections required to define new item
   * or on save event
   */
  @property({ type: Boolean, attribute: 'create-on-save' })
  createOnSave = false;

  /**
   * The existing data item. If not defined then new item is shown
   */
  @property({ type: Object })
  dataItem: ApplicationDataItem | undefined;

  /**
   * The available computers
   */
  @property({ type: Array })
  computers: SelectDropdownOption[] = [];

  /**
   * The available partners.
   */
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];

  /**
   * Name of remote provider for default applications
   */
  @property({ type: String })
  newRemoteProviderName = '';

  /**
   * light label display property
   */
  @property({ type: Boolean, attribute: 'light-label' })
  lightLabel = false;

  /**
   * small label display property
   */
  @property({ type: Boolean, attribute: 'small-label' })
  smallLabel = false;

  /**
   * Do not display the application level. Used when element is embedded
   */
  @property({ type: Boolean, attribute: 'hide-application-label' })
  hideApplicationLabel = false;

  /**
   * The label for this data item
   */
  @property({ type: String, attribute: 'application-label' })
  applicationLabel = '';

  @property({ type: String, attribute: 'application-add-text' })
  applicationAddText = '';

  @property({ type: String, attribute: 'application-placeholder' })
  applicationPlaceholder = '';

  /**
   * The available application options.
   *
   */
  @property({ type: Array })
  availableApplicationOptions: InfosecApplicationOption[] = [];
  @state()
  private newApplication = '';
  @state()
  private newPlatform: '' | 'computers' | 'remoteServers' | 'cloudServices' = '';
  @state()
  private newRemoteProviderValue = '';

  private get applicationOptions() {
    const options: SelectDropdownOption[] = this.availableApplicationOptions.map((item) => {
      return {
        value: item.value,
        text: item.text,
      };
    });
    if (this.dataItem?.application && this.dataItem.application.startsWith('CUSTOM')) {
      options.push({
        value: this.dataItem.application,
        text: this.dataItem.application.slice(7),
      });
    }
    if (this.newApplication && this.newApplication.startsWith('CUSTOM')) {
      options.push({
        value: this.newApplication,
        text: this.newApplication.slice(7),
      });
    }
    options.push({
      value: 'NEW',
      text: this.applicationAddText || 'Annet system',
    });
    return options;
  }

  private get storageUnitTypeOptions() {
    let storageUnitTypeOptions = [...applicationPlatformOptions];
    if (this.defaultApplication?.type === 'standalone') {
      storageUnitTypeOptions = storageUnitTypeOptions.filter((option) => option.value !== 'cloudServices');
    }
    return storageUnitTypeOptions;
  }

  private get partnerForRemotePlaceholder() {
    if (this.newPlatform === 'cloudServices') {
      return 'Velg leverandør av skytjeneste';
    }
    return 'Velg leverandør av fjernserver';
  }

  private get partnerOptions() {
    const options = [...this.partners];
    if (this.newRemoteProviderName) {
      options.push({
        value: this.newRemoteProviderValue,
        text: this.newRemoteProviderName,
      });
    }
    let addUnitText = 'Annen samarbeidspartner';
    if (options.length === 0) {
      addUnitText = 'Ny samarbeidspartner';
    }
    options.push({
      value: 'NEW',
      text: addUnitText,
    });
    return options;
  }

  private get remoteProviderLabel() {
    const p = this.dataItem !== undefined ? this.dataItem.platform : this.newPlatform;

    if (p === 'cloudServices') {
      return 'Leverandør av skytjenesten';
    }
    if (p === 'remoteServers') {
      return 'Leverandør av fjernserver';
    }
    return '';
  }

  private get defaultApplication() {
    const applications = this.availableApplicationOptions.filter((item) => {
      return item.value === this.newApplication;
    });
    if (applications.length) {
      return applications[0];
    }
    return undefined;
  }

  private get applicationLabelComputed() {
    if (this.hideApplicationLabel) {
      return '';
    }
    return this._capitalize(this.applicationLabel);
  }

  private get selectApplicationDisabled() {
    return this.newApplication !== '' && !this.createOnSave;
  }

  private get selectPlatformDisabled() {
    if (this.newPlatform !== '' && !this.createOnSave) {
      return true;
    }
    return !!(this.createOnSave && this.defaultApplication && this.defaultApplication.type === 'cloud');
  }

  private get selectRemoteProviderDisabled() {
    return this.defaultApplication && this.newPlatform === 'cloudServices';
  }

  _capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async _applicationChanged(value) {
    this.newApplication = value;
    this.newPlatform = '';
    this.newRemoteProviderValue = '';
    if (value === 'NEW') {
      const result: NewNameResult = await NewNameDialog.open({ title: this.applicationAddText || 'Ny' });
      if (result.action === 'done') {
        this.newApplication = 'CUSTOM-' + result.name;
      } else {
        this.newApplication = '';
      }
    } else if (this.defaultApplication) {
      if (this.defaultApplication.type === 'cloud') {
        this.newPlatform = 'cloudServices';
        if (!this.createOnSave) {
          const providerName = this.defaultApplication.provider;
          const registeredProvider = this.partners.find((p) => {
            return p.text.includes(providerName);
          });
          if (registeredProvider) {
            this.newRemoteProviderValue = registeredProvider.value;
            this.dispatchEvent(
              new CustomEvent<AddDataItemCloudServiceApplication>('add-data-item', {
                composed: true,
                bubbles: true,
                detail: {
                  application: value,
                  newSupplier: false,
                  storageUnitType: 'cloudServices',
                  supplierUuid: registeredProvider.value,
                },
              }),
            );
          } else {
            this.newRemoteProviderName = providerName;
            this.newRemoteProviderValue = uuid();
            this.dispatchEvent(
              new CustomEvent<AddDataItemCloudServiceApplication>('add-data-item', {
                composed: true,
                bubbles: true,
                detail: {
                  application: value,
                  newSupplier: true,
                  storageUnitType: 'cloudServices',
                  newSupplierName: providerName,
                  supplierUuid: this.newRemoteProviderValue,
                },
              }),
            );
          }
        }
      } else {
        if (!this.createOnSave) {
          this.newRemoteProviderName = '';
          this._cancelPendingAddDataItem();
        }
      }
    }
  }

  _cancelPendingAddDataItem() {
    this.dispatchEvent(
      new CustomEvent('cancel-pending-add-data-item', {
        composed: true,
        bubbles: true,
        detail: {},
      }),
    );
  }

  async _remoteProviderChanged(value) {
    if (value === 'NEW') {
      const result: NewNameResult = await NewNameDialog.open({ title: 'Ny samarbeidspartner' });
      if (result.action === 'done') {
        const partnerUuid = uuid();
        this.newRemoteProviderValue = partnerUuid;
        this.newRemoteProviderName = result.name;
        this.requestUpdate();
        this.dispatchEvent(
          new CustomEvent<AddDataItemApplication>('add-data-item', {
            composed: true,
            bubbles: true,
            detail: {
              application: this.newApplication,
              newSupplier: true,
              newSupplierName: result.name,
              storageUnitType: this.newPlatform === 'cloudServices' ? 'cloudServices' : 'remoteServers',
              supplierUuid: partnerUuid,
            },
          }),
        );
      }
    } else {
      this.dispatchEvent(
        new CustomEvent<AddDataItemApplication>('add-data-item', {
          composed: true,
          bubbles: true,
          detail: {
            application: this.newApplication,
            newSupplier: false,
            storageUnitType: 'remoteServers',
            supplierUuid: value,
          },
        }),
      );
    }
  }

  async _computerChanged(value) {
    if (value === 'NEW') {
      const result: NewComputerResult = await NewComputerDialog.open({
        name: '',
        requireNameForServers: this.computers.length !== 0,
      });
      if (result.action === 'done') {
        this.dispatchEvent(
          new CustomEvent<AddDataItemApplication>('add-data-item', {
            composed: true,
            bubbles: true,
            detail: {
              application: this.newApplication,
              storageUnitType: 'computers',
              newComputer: true,
              computerUuid: uuid(),
              newComputerName: result.name,
              newComputerType: result.type,
            },
          }),
        );
      }
    } else {
      this.dispatchEvent(
        new CustomEvent<AddDataItemApplication>('add-data-item', {
          composed: true,
          bubbles: true,
          detail: {
            application: this.newApplication,
            storageUnitType: 'computers',
            computerUuid: value,
            newComputer: false,
          },
        }),
      );
    }
  }

  render() {
    if (this.dataItem !== undefined) {
      return this.renderExistingDataItem(this.dataItem);
    }

    return this.renderNewApplication();
  }

  renderExistingDataItem(dataItem: ApplicationDataItem) {
    return html`
      <d-select-dropdown
        .label=${this.applicationLabelComputed}
        theme-page
        ?light-label=${this.lightLabel}
        ?small-label=${this.smallLabel}
        unselectablePlaceholder
        disabled
        .options=${this.applicationOptions}
        .value=${dataItem.application}
      ></d-select-dropdown>

      <d-select-dropdown
        label="Plattform"
        theme-page
        ?light-label=${this.lightLabel}
        ?small-label=${this.smallLabel}
        .options=${applicationPlatformOptions}
        .value=${dataItem.platform}
        disabled
      ></d-select-dropdown>
      ${dataItem.platform === 'computers'
        ? html` <d-select-dropdown
            label="Datamaskin"
            theme-page
            ?light-label=${this.lightLabel}
            ?small-label=${this.smallLabel}
            .options=${this.computers}
            .value=${dataItem.computerUuid}
            disabled
          ></d-select-dropdown>`
        : html` <d-select-dropdown
            .label=${this.remoteProviderLabel}
            theme-page
            ?light-label=${this.lightLabel}
            ?small-label=${this.smallLabel}
            .options=${this.partnerOptions}
            .value=${dataItem.supplierUuid}
            disabled
          ></d-select-dropdown>`}
    `;
  }

  updated(_changedProperties) {
    if (_changedProperties.has('availableApplicationOptions')) {
      this.newApplication = '';
      this.newPlatform = '';
      this.newRemoteProviderValue = '';
    }
  }

  private renderNewApplication() {
    return html`
      <d-select-dropdown
        .label=${this.applicationLabelComputed}
        theme-page
        ?light-label=${this.lightLabel}
        ?small-label=${this.smallLabel}
        unselectablePlaceholder
        ?disabled=${this.selectApplicationDisabled}
        .placeholder=${this.applicationPlaceholder ?? ''}
        .options=${this.applicationOptions}
        .value=${this.newApplication}
        @value-changed=${(e) => this._applicationChanged(e.detail.value)}
      ></d-select-dropdown>
      <d-expansion ?opened=${this.newApplication !== '' && this.newApplication !== 'NEW'}>
        <d-select-dropdown
          label="Plattform"
          theme-page
          ?light-label=${this.lightLabel}
          ?small-label=${this.smallLabel}
          placeholder="Velg plattform"
          .options=${this.storageUnitTypeOptions}
          .value=${this.newPlatform}
          ?disabled=${this.selectPlatformDisabled}
          @value-changed=${(e) => this.onPlatformChanged(e.detail.value)}
        ></d-select-dropdown>
      </d-expansion>
      <d-expansion
        ?opened=${this.newApplication !== '' &&
        (this.newPlatform === 'remoteServers' || this.newPlatform === 'cloudServices')}
      >
        <d-select-dropdown
          .label=${this.remoteProviderLabel}
          theme-page
          ?light-label=${this.lightLabel}
          ?small-label=${this.smallLabel}
          .placeholder=${this.partnerForRemotePlaceholder}
          .options=${this.partnerOptions}
          .value=${this.newRemoteProviderValue}
          ?disabled=${this.selectRemoteProviderDisabled}
          @value-changed=${(e) => this._remoteProviderChanged(e.detail.value)}
        ></d-select-dropdown>
      </d-expansion>
      <d-expansion ?opened=${this.newApplication !== '' && this.newPlatform === 'computers'}>
        <d-select-dropdown-or-add
          theme-page
          ?light-label=${this.lightLabel}
          ?small-label=${this.smallLabel}
          label="Datamaskin"
          unselectablePlaceholder
          placeholder="Velg datamaskin"
          firstItemText="Registrer datamaskin"
          additionalItemText="Annen datamaskin"
          .options=${this.computers}
          .value=${''}
          @value-changed=${(e) => this._computerChanged(e.detail.value)}
        ></d-select-dropdown-or-add>
      </d-expansion>
    `;
  }
  /*
   <d-popup-new-application
        .show=${this.popupApplication}
        .title=${this.applicationAddText ?? ''}
        .partners=${this.partners}
        @cancel=${() => this._cancelPopupApplication()}
        @done=${(e) => this._donePopupApplication(e.detail)}
      ></d-popup-new-application>

      <d-popup-new-computer
        .show=${this.popupComputer}
        .computers=${this.computers}
        @cancel=${() => this._cancelPopupComputer()}
        @done=${(e) => this._donePopupComputer(e.detail)}
      ></d-popup-new-computer>
   */

  private onPlatformChanged(value) {
    this.newPlatform = value;
    if (value === 'cloudServices') {
      let providerName = '';
      if (this.defaultApplication) {
        providerName = this.defaultApplication.provider;
        const registeredProvider = this.partners.find((p) => {
          return p.text.includes(providerName);
        });
        if (registeredProvider) {
          this.newRemoteProviderValue = registeredProvider.value;
          this.dispatchEvent(
            new CustomEvent<AddDataItemCloudServiceApplication>('add-data-item', {
              composed: true,
              bubbles: true,
              detail: {
                application: this.newApplication,
                newSupplier: false,
                storageUnitType: 'cloudServices',
                supplierUuid: this.newRemoteProviderValue,
              },
            }),
          );
        } else {
          this.newRemoteProviderName = this.defaultApplication.provider;
          this.newRemoteProviderValue = uuid();
          this.dispatchEvent(
            new CustomEvent<AddDataItemCloudServiceApplication>('add-data-item', {
              composed: true,
              bubbles: true,
              detail: {
                application: this.newApplication,
                newSupplier: true,
                storageUnitType: 'cloudServices',
                newSupplierName: providerName,
                supplierUuid: this.newRemoteProviderValue,
              },
            }),
          );
        }
      } else {
        this.newRemoteProviderValue = '';
        this._cancelPendingAddDataItem();
      }
    } else {
      this.newRemoteProviderValue = '';
      this._cancelPendingAddDataItem();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-data-item-application': DEditDataItemApplication;
  }
}
