import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-item.js';
import { displayName, uuid } from 'src/utilities/text';
import { CreateEntityInput } from 'src/layout/parts/d-new-document';
import { css, html, nothing } from 'lit';
import { PageContent } from 'src/pages/page-content';
import '../../library/fields/d-expansion';
import '../../library/fields/d-activity-indicator';
import type { MeetingsPageMeeting, MeetingsPageViewModel } from './meetings-page-view-model';
import { customElement, property, state } from 'lit/decorators.js';
import { ActionInput } from 'src/library/elements/d-action';
import { LocalDate } from 'src/utilities/local-date';
import { DNewEmployeeDialog } from 'src/layout/parts/d-new-employee-dialog';
import { features, promo } from 'src/store/selectors/features';

/**
 *
 */
@customElement('d-meetings-page-content')
export class DMeetingsPageContent extends PageContent<MeetingsPageViewModel> {
  static readonly styles = [
    ...super.styles,
    css`
      d-list-header:first-child,
      d-help-section + d-list-header {
        margin-top: 1px;
      }
      .more {
        padding-left: var(--listPaddingLeft);
        padding-top: 12px;
        padding-bottom: 12px;
        color: var(--themeColor);
        cursor: pointer;
        mix-blend-mode: multiply;
      }
      @media (hover: hover) {
        .more:hover {
          color: black;
        }
      }
      .meeting-wrapper:last-child {
        margin-bottom: -1px;
      }
      .meeting {
        padding: 12px 0 10px 0;
        border-bottom: 1px solid var(--borderColorOnGray);
      }
      .meeting-wrapper:hover .meeting {
        background-color: hsla(0, 0%, 100%, 0.2);
      }
      .meeting-header {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: space-between;
      }
      .meeting-wrapper[suggested] .meeting-header d-label {
        opacity: 0.6;
      }
      :host([write-access]) .meeting-wrapper[suggested] {
        cursor: pointer;
      }
      .meeting-wrapper:hover .meeting-header d-label {
        opacity: 1;
      }
      .meeting-header > *:last-child {
        flex-grow: 1;
        margin-right: 12px;
        margin-bottom: 2px;
      }
      .meeting-header .actions {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin: -6px -8px;
      }
      .meetings {
        margin-bottom: -1px;
      }
      .meeting-prev-next {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-top: 4px;
      }
      .meeting-prev-next > * {
        margin-right: 12px;
      }
      d-activity-indicator {
        margin: 8px 0;
      }
      a.prev-next-text {
        color: var(--linkColorGray);
        text-decoration: none;
      }
      a.prev-next-text[disabled] {
        color: black;
        cursor: default;
        pointer-events: none;
      }
      a.prev-next-text[classified] {
        padding-left: 20px;
        background-image: url(/images/locked.svg);
        background-repeat: no-repeat;
        background-position: -4px -4px;
        background-size: 24px;
      }
      span.prev-next-text {
        font-style: italic;
        font-weight: 200;
      }
      .prev-next-text .label {
        padding-right: 2px;
        font-weight: 500;
        font-style: normal;
      }
      @media only screen and (min-width: 600px) {
        .meeting-wrapper {
          padding-left: var(--listPaddingLeft);
        }
        .meeting-wrapper[classified] .meeting-header {
          padding-left: 0;
        }
      }
      @media (hover: hover) {
        .meeting a.prev-next-text:hover {
          color: var(--themeColorDarkerOne);
        }
        .meeting a.prev-next-text[disabled],
        .meeting a.prev-next-text[disabled]:hover {
          color: black;
          cursor: default;
        }
      }
    `,
  ];
  @property({ type: Number })
  limitListOver = 5;
  @property({ type: Number })
  limitedListLength = 3;
  @property({ type: Boolean })
  pastMeetingsShowAll = false;
  @property({ type: Boolean })
  suggestedMeetingsShowAll = false;
  @property({ type: Boolean })
  activeEmployeeInterviewsShowAll = false;
  @property({ type: Boolean })
  terminatedEmployeeInterviewsShowAll = false;

  @state()
  private futureMeetingsShowAll = true;

  private get indicatorYears(): number[] {
    const thisYear = LocalDate.now().year();
    return [thisYear - 1, thisYear, thisYear + 1];
  }

  handleWrapperClick(e, templateHref: string, meeting: MeetingsPageMeeting) {
    if (meeting.suggested) {
      this.onCreateOccurrence(e, meeting);
    }
  }

  firstItems(items) {
    return items.filter((item, index) => {
      return index < this.limitedListLength;
    });
  }

  lastItems(items) {
    return items.filter((item, index) => {
      return index > this.limitedListLength - 1;
    });
  }

  listActions(listName): ActionInput[] {
    const actions: ActionInput[] = [];
    if (listName === 'futureMeetings' && this.pageView.writeAccess) {
      actions.push({
        name: 'Legg til nytt møte',
        action: 'create-meeting',
        slot: 'top-right',
      });
    } else if (listName === 'terminatedEmployeeInterviews') {
      if (this.terminatedEmployeeInterviewsShowAll) {
        actions.push({
          name: 'Skjul',
          action: 'toggleShowAll',
          slot: 'top-right',
        });
      } else {
        actions.push({
          name: 'Vis',
          action: 'toggleShowAll',
          slot: 'top-right',
        });
      }
    } else if (listName === 'activeEmployeeInterviews' && this.pageView.writeAccess) {
      actions.push({
        name: 'Legg til ny person',
        action: 'create-employee',
        slot: 'top-right',
      });
    }
    if (this.pageView[listName].length > this.limitListOver) {
      let name = 'Vis alle';
      if (this[listName + 'ShowAll']) {
        name = 'Vis færre';
      }
      actions.push({ name, action: 'toggleShowAll', slot: 'top-right' });
    }
    return actions;
  }

  async handeListHeaderAction(e, listName) {
    e.preventDefault();
    e.stopPropagation();
    if (e.detail === 'toggleShowTerminated') {
      this.terminatedEmployeeInterviewsShowAll = !this.terminatedEmployeeInterviewsShowAll;
    }
    if (e.detail === 'toggleShowAll') {
      this[listName + 'ShowAll'] = !this[listName + 'ShowAll'];
    } else if (e.detail === 'create-meeting') {
      const id = uuid();
      const input: CreateEntityInput = {
        entityType: 'meetingOccurrences',
        entityUuid: id,
        instance: LocalDate.now().plusDays(1).toString(),
        targetUrl: '/account/' + this.pageView.organizationId + '/9772/meetingOccurrences/' + id + '?edit',
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
    if (e.detail === 'create-employee') {
      await DNewEmployeeDialog.open({
        userEmails: this.pageView.userEmails,
      });
    }
  }

  activities(occurrences) {
    return occurrences.map((o) => {
      let href = this.pageView.href + 'meetingOccurrences/' + o.uuid;
      if (!o.isConfirmedEntity) {
        href += '?edit';
      }
      let classes = '';
      if (o.classified) {
        classes += ' classified';
      }
      if (o.restricted) {
        classes += ' restricted';
      }
      if (o.meetingStatus === 'NOTICE_SENT' || o.meetingStatus === 'REPORT_WRITTEN') {
        classes += ' meeting-initialized';
      }
      return { date: o.date, href, classes };
    });
  }

  renderMeeting({ meeting, listName }: { meeting: MeetingsPageMeeting; listName: string }) {
    const templateHref = this.pageView.href + 'meetings/' + meeting.uuid;
    const optionalMeetingCalendarAction =
      meeting.prevDate || meeting.nextDate
        ? html` <d-action gray href="${templateHref}" theme-page mini>Møtekalender</d-action> `
        : nothing;
    const addMeetingLabel = meeting.suggested ? html`Ta i bruk` : html`Legg til`;
    const optionalAddMeetingAction =
      this.pageView.writeAccess && listName !== 'terminatedEmployeeInterviews'
        ? html`
            <d-action gray @click=${(e: CustomEvent) => this.onCreateOccurrence(e, meeting)} theme-page mini
              >${addMeetingLabel}</d-action
            >
          `
        : nothing;
    const previousMeetingLink = meeting.prevText
      ? html`
          <a
            class="prev-next-text"
            ?disabled="${meeting.prevRestricted}"
            ?classified="${meeting.prevClassified}"
            href="${meeting.prevHref}"
          >
            <span class="label">Forrige</span>
            ${meeting.prevText}
          </a>
        `
      : html`
          <span class="prev-next-text">
            <span class="label">Forrige</span>
            Ingen tidligere
          </span>
        `;
    const nextMeetingLink = meeting.nextText
      ? html`
          <a
            class="prev-next-text"
            ?disabled="${meeting.nextRestricted}"
            ?classified="${meeting.nextClassified}"
            href="${meeting.nextHref}"
          >
            <span class="label">Neste</span>
            ${meeting.nextText}
          </a>
        `
      : html`
          <span class="prev-next-text">
            <span class="label">Neste</span>
            Ingen kommende
          </span>
        `;
    const optionalNextPreviousLinks = !meeting.suggested
      ? html` <d-activity-indicator
            .years=${this.indicatorYears}
            .activities=${this.activities(meeting.occurrences)}
          ></d-activity-indicator>
          <div class="meeting-prev-next">
            <div>${previousMeetingLink}</div>
            ${listName !== 'pastMeetings' ? html` <div>${nextMeetingLink}</div> ` : nothing}
          </div>`
      : nothing;
    return html`
      <div
        class="meeting-wrapper"
        ?suggested="${meeting.suggested}"
        @click=${(e) => this.handleWrapperClick(e, templateHref, meeting)}
      >
        <div class="meeting">
          <div class="meeting-header">
            <div class="actions">${optionalMeetingCalendarAction} ${optionalAddMeetingAction}</div>
            <div class="meeting-name">
              <d-label big allow-wrap .label=${displayName(meeting.name, 'Møte uten navn')}></d-label>
            </div>
          </div>
          ${optionalNextPreviousLinks}
        </div>
      </div>
    `;
  }

  renderMeetingsListIfNotEmpty(label: string, sublabel: string, listName: string, meetings: MeetingsPageMeeting[]) {
    if (meetings.length === 0) {
      return nothing;
    } else {
      return this.renderMeetingsList(label, sublabel, listName, meetings);
    }
  }

  renderMeetingsList(label: string, sublabel: string, listName: string, meetings: MeetingsPageMeeting[]) {
    const optionalListHeader = label
      ? html`<d-list-header
          label="${label}"
          sublabel="${sublabel}"
          icon="meetings"
          theme-page
          bordered
          style="top: ${this.contentStickyTop - 1}px"
          .actions=${this.listActions(listName)}
          @action=${(e) => this.handeListHeaderAction(e, listName)}
        ></d-list-header>`
      : nothing;
    return html` ${optionalListHeader}
      <div class="meetings">
        ${meetings.length > this.limitListOver
          ? html`
              ${this.firstItems(meetings).map((meeting: MeetingsPageMeeting) => {
                return html` ${this.renderMeeting({ meeting: meeting, listName })} `;
              })}
              <d-expansion .opened=${this[listName + 'ShowAll']}>
                ${this.lastItems(meetings).map((meeting: MeetingsPageMeeting) => {
                  return html` ${this.renderMeeting({ meeting: meeting, listName: listName })} `;
                })}
              </d-expansion>
            `
          : html`
              ${meetings.map((meeting: MeetingsPageMeeting) => {
                return html` ${this.renderMeeting({ meeting: meeting, listName: listName })} `;
              })}
            `}
      </div>`;
  }

  renderMeetingsListIfNotEmptyAllHidden(
    label: string,
    sublabel: string,
    listName: string,
    meetings: MeetingsPageMeeting[],
  ) {
    const optionalListHeader = label
      ? html`<d-list-header
          label="${label}"
          sublabel="${sublabel}"
          icon="meetings"
          theme-page
          bordered
          style="top: ${this.contentStickyTop - 1}px"
          .actions=${this.listActions(listName)}
          @action=${(e) => this.handeListHeaderAction(e, listName)}
        ></d-list-header>`
      : nothing;
    if (meetings.length === 0) {
      return nothing;
    } else {
      return html`
        ${optionalListHeader}
        <d-expansion .opened=${this[listName + 'ShowAll']}>
          <div class="meetings">
            ${meetings.map((meeting: MeetingsPageMeeting) => {
              return html` ${this.renderMeeting({ meeting: meeting, listName: listName })} `;
            })}
          </div>
        </d-expansion>
      `;
    }
  }

  renderContent() {
    if (!this.pageView.featureStates.core && !this.pageView.featureStates.meetings) {
      const promoData = promo.find((p) => {
        return p.id === 'meetings';
      });
      if (promoData) {
        return html` <d-promo-section
          theme-page
          .employeesCount=${this.pageView.employeesCount}
          .specialTerms=${this.pageView.specialTerms}
          .promoData=${promoData}
          .features=${features}
        ></d-promo-section>`;
      }
      return nothing;
    }
    return html`
      ${this.renderMeetingsList('Kommende møter', '', 'futureMeetings', this.pageView.futureMeetings)}
      ${this.renderMeetingsListIfNotEmpty('Tidligere møter', '', 'pastMeetings', this.pageView.pastMeetings)}
      ${this.renderMeetingsListIfNotEmpty(
        'Medarbeidersamtaler',
        '',
        'activeEmployeeInterviews',
        this.pageView.activeEmployeeInterviews,
      )}
      ${this.renderMeetingsListIfNotEmptyAllHidden(
        'Medarbeidersamtaler',
        'Sluttet personale',
        'terminatedEmployeeInterviews',
        this.pageView.terminatedEmployeeInterviews,
      )}
      ${this.renderMeetingsListIfNotEmpty('Foreslåtte møter', '', 'suggestedMeetings', this.pageView.suggestedMeetings)}
    `;
  }

  private onCreateOccurrence(e: CustomEvent, meeting: MeetingsPageMeeting) {
    e.stopPropagation();
    if (this.pageView.writeAccess) {
      const id = uuid();
      let t: string | undefined = undefined;
      if (meeting.meetingUuid === undefined && meeting.employeeUuid === undefined) {
        t = meeting.uuid;
      }
      const input: CreateEntityInput = {
        entityType: 'meetingOccurrences',
        entityUuid: id,
        meetingUuid: meeting.meetingUuid,
        employeeUuid: meeting.employeeUuid,
        templateMeetingOccurrenceUuid: t,
        instance: LocalDate.now().toString(),
        targetUrl: '/account/' + this.pageView.organizationId + '/9772/meetingOccurrences/' + id + '?edit',
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-meetings-page-content': DMeetingsPageContent;
  }
}
