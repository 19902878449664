/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FeedbackMessage
 */
export interface FeedbackMessage {
  /**
   *
   * @type {string}
   * @memberof FeedbackMessage
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackMessage
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackMessage
   */
  message?: string;
}

/**
 * Check if a given object implements the FeedbackMessage interface.
 */
export function instanceOfFeedbackMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function FeedbackMessageFromJSON(json: any): FeedbackMessage {
  return FeedbackMessageFromJSONTyped(json, false);
}

export function FeedbackMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, 'email') ? undefined : json['email'],
    url: !exists(json, 'url') ? undefined : json['url'],
    message: !exists(json, 'message') ? undefined : json['message'],
  };
}

export function FeedbackMessageToJSON(value?: FeedbackMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    url: value.url,
    message: value.message,
  };
}
