import { css, html, LitElement } from 'lit';
import '../../library/fields/d-expansion.js';

import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/elements/d-section.js';
import '../../library/editors/elements/d-edit-text.js';
import * as dabihStore from 'src/store';
import { associationTypes, professions } from 'src/store';
import { customElement, property, state } from 'lit/decorators.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { AccountStateEmployeeViewModelAccessLevelEnum } from 'src/store/api';
import { produce } from 'immer';

export interface PrepareEmployeeRowItem {
  profession: string;
  lastName: string;
  firstName: string;
  associationType: string;
  accessLevel: AccountStateEmployeeViewModelAccessLevelEnum;
  email: string;
  uuid: string;
}

/**
 *
 *
 */
@customElement('d-prepare-employee-row')
export class DPrepareEmployeeRow extends LitElement {
  static shadowRootOptions = { ...LitElement.shadowRootOptions, delegatesFocus: true };
  static readonly styles = css`
    :host {
      display: flex;
    }

    .personBox {
      flex: 1;
      padding: 0 14px 12px 16px;
      background: hsla(0, 0%, 100%, 0.2);
      margin: 8px 0;
      border-radius: 14px;
    }

    d-section {
      border: none;
    }
    .personRow d-edit-text,
    .personRow d-prepare-select {
      min-width: 260px;
    }

    d-section > * {
      padding-top: 8px;
      padding-bottom: 6px;
    }

    d-section > * {
      font-family: var(--mainSans);
    }

    .minWidth300 {
      min-width: 300px;
      flex: 1;
    }

    d-edit-text,
    d-select-dropdown {
      border: none;
      box-shadow: none;
    }
  `;
  @property({ type: Number })
  index = 0;
  @property({ type: Object })
  item!: PrepareEmployeeRowItem;
  @property({ type: Boolean })
  hideProfessions = false;
  @state()
  private nameFocused = false;
  private accessLevelOptions: SelectDropdownOption[] = [
    { value: 'NONE', text: 'Ingen' },
    { value: 'READONLY', text: 'Lesetilgang' },
    { value: 'USER', text: 'Skrivetilgang' },
  ];

  private get professionOptions() {
    const result: SelectDropdownOption[] = [];
    professions.forEach(function (item) {
      result.push({ value: item, text: item });
      if (item === 'Fysioterapeut') {
        result.push({ value: '- Manuellterapeut', text: '- Manuellterapeut' });
      }
    });
    return result;
  }

  _stringIsEmpty(value: string) {
    return value ? value.trim().length === 0 : true;
  }

  _isEmail(email) {
    return !this._stringIsEmpty(email) && dabihStore.isEmailValid(email);
  }

  _isAccessLevelDisabled(index, email) {
    return index === 0 || !this._isEmail(email);
  }

  _isDisabled(index) {
    return index === 0;
  }

  render() {
    return html`
      <div class="personBox">
        <d-section>
          <d-edit-text
            .disabled=${this._isDisabled(this.index)}
            class="minWidth300"
            .value=${this.item.firstName}
            label="Fornavn"
            select-on-focus
            outskirts
            system-content
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              this.item = produce(this.item, (draft) => {
                draft.firstName = e.detail.value;
              });
              this.dispatchEvent(
                new CustomEvent<{ item: PrepareEmployeeRowItem }>('employee-changed', { detail: { item: this.item } }),
              );
            }}
          >
          </d-edit-text>
          <d-edit-text
            .disabled=${this._isDisabled(this.index)}
            class="minWidth300"
            .value=${this.item.lastName}
            label="Etternavn"
            select-on-focus
            system-content
            outskirts
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              this.item = produce(this.item, (draft) => {
                draft.lastName = e.detail.value;
              });
              this.dispatchEvent(
                new CustomEvent<{ item: PrepareEmployeeRowItem }>('employee-changed', { detail: { item: this.item } }),
              );
            }}
          >
          </d-edit-text>
        </d-section>
        <d-section>
          <d-edit-text
            label="Epost"
            .disabled=${this._isDisabled(this.index)}
            class="minWidth300"
            placeholder="Epost"
            type="email"
            .value=${this.item.email}
            select-on-focus
            system-content
            outskirts
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              this.item = produce(this.item, (draft) => {
                draft.email = e.detail.value;
              });
              this.dispatchEvent(
                new CustomEvent<{ item: PrepareEmployeeRowItem }>('employee-changed', {
                  detail: { item: this.item },
                }),
              );
            }}
          >
          </d-edit-text>
          <d-select-dropdown
            .options=${this.accessLevelOptions}
            class="minWidth300"
            .disabled=${this._isAccessLevelDisabled(this.index, this.item.email)}
            field="employees_accessLevel"
            .value=${this.item.accessLevel}
            select-on-focus
            system-content
            outskirts
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              this.item = produce(this.item, (draft) => {
                draft.accessLevel = e.detail.value as AccountStateEmployeeViewModelAccessLevelEnum;
              });
              this.dispatchEvent(
                new CustomEvent<{ item: PrepareEmployeeRowItem }>('employee-changed', {
                  detail: { item: this.item },
                }),
              );
            }}
          >
          </d-select-dropdown>
        </d-section>
        <d-section>
          ${this.hideProfessions
            ? html`<d-edit-text
                label="Yrkestittel"
                class="minWidth300"
                .value=${this.item.profession}
                system-content
                outskirts
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.item = produce(this.item, (draft) => {
                    draft.profession = e.detail.value;
                  });
                  this.dispatchEvent(
                    new CustomEvent<{ item: PrepareEmployeeRowItem }>('employee-changed', {
                      detail: { item: this.item },
                    }),
                  );
                }}
              >
              </d-edit-text>`
            : html`<d-select-dropdown
                .options=${this.professionOptions}
                label="Yrke"
                class="minWidth300"
                placeholder="Velg yrke"
                .value=${this.item.profession}
                system-content
                outskirts
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.item = produce(this.item, (draft) => {
                    draft.profession = e.detail.value;
                  });
                  this.dispatchEvent(
                    new CustomEvent<{ item: PrepareEmployeeRowItem }>('employee-changed', {
                      detail: { item: this.item },
                    }),
                  );
                }}
              >
              </d-select-dropdown>`}

          <d-select-dropdown
            .options=${associationTypes.map((s) => {
              return { value: s, text: s };
            })}
            label="Tilknytning"
            class="minWidth300"
            placeholder="Velg tilknytning"
            value="${this.item.associationType}"
            system-content
            outskirts
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              this.item = produce(this.item, (draft) => {
                draft.associationType = e.detail.value;
              });
              this.dispatchEvent(
                new CustomEvent<{ item: PrepareEmployeeRowItem }>('employee-changed', {
                  detail: { item: this.item },
                }),
              );
            }}
          >
          </d-select-dropdown>
        </d-section>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-prepare-employee-row': DPrepareEmployeeRow;
  }
}
