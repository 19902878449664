import type { LocalDate } from 'src/utilities/local-date.js';
import { BaseInstanceRule } from './base-instance-rule.js';

export class MonthlyByDayOfMonthInstanceRule extends BaseInstanceRule {
  private static intervalDescriptions = ['', ' annen', ' tredje', ' fjerde', ' femte', ' sjette'];
  private readonly byMonthDay: number;

  public constructor(byMonthDay: number) {
    super();
    this.byMonthDay = byMonthDay;
  }

  public matches(date: LocalDate): boolean {
    return date.day() === this.byMonthDay;
  }

  public optionsToString(interval: number): string {
    return 'FREQ=MONTHLY;INTERVAL=' + interval + ';BYMONTHDAY=' + this.byMonthDay;
  }

  public scheduleDescription(interval: number): string {
    const i = MonthlyByDayOfMonthInstanceRule.intervalDescriptions[interval - 1];
    return 'Hver' + i + ' måned på den ' + this.byMonthDay + '.';
  }
}
