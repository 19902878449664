import { html } from 'lit';
import '../../../../library/editors/elements/d-edit-text.js';
import '../../../../library/elements/d-section.js';
import '../../../../library/components/d-popup.js';
import { customElement, property } from 'lit/decorators.js';
import { BaseDialog, DialogCancelResult } from 'src/library/components/BaseDialog.js';

export interface NewAssetInput {
  name: string;
  number: string;
}
export type NewAssetResult =
  | DialogCancelResult
  | {
      name: string;
      number: string;
      action: 'done';
    };

/**
 * Dialog to create new asset. Assets require a name, and may also provide an id. `DONE` is disabled if the user has not selected a type or if the name is required but empty.
 *
 *
 * @fires cancel - the parent element must close the dialog
 * @fires done - the parent element must close the dialog
 *
 * USAGE:
 *    d-edit-data-item-asset
 *
 */
@customElement('new-asset-dialog')
export class NewAssetDialog extends BaseDialog<NewAssetInput, NewAssetResult> {
  /**
   * Current value of the name
   */
  @property({ type: String })
  name = '';
  /**
   * Current value of the number
   */
  @property({ type: String })
  number = '';
  width = 400;
  title = 'Ny utstyrsenhet';

  protected get calculatedHeaderActions() {
    let disabled = true;
    if (this.name) {
      disabled = false;
    }
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Ferdig', action: 'done', disabled: disabled },
    ];
  }

  _nameChange(e) {
    this.name = e.detail.value;
  }

  _numberChange(e) {
    this.number = e.detail.value;
  }

  _reset() {
    this.title = '';
    this.name = '';
    this.number = '';
  }

  _action(value) {
    if (value === 'cancel') {
      this.dispatchEvent(
        new CustomEvent('cancel', {
          bubbles: true,
          composed: true,
        }),
      );
    }
    if (value === 'done') {
      this.dispatchEvent(
        new CustomEvent('done', {
          bubbles: true,
          composed: true,
          detail: {
            name: this.name,
            number: this.number,
          },
        }),
      );
    }
    this._reset();
  }

  protected renderBody() {
    return html`
      <d-section>
        <d-edit-text
          autofocus
          label="Navn"
          .value=${this.name}
          @value-changed=${(e) => this._nameChange(e)}
        ></d-edit-text>
        <d-edit-text
          field="assets_number"
          .value=${this.number}
          @value-changed=${(e) => this._numberChange(e)}
        ></d-edit-text>
      </d-section>
    `;
  }

  protected fetchResult(detail: string | undefined): NewAssetResult {
    if (detail === 'done') {
      return {
        name: this.name,
        number: this.number,
        action: 'done',
      };
    }
    return {
      action: 'cancel',
    };
  }

  protected initializeDialog(input: NewAssetInput) {
    this.name = input.name;
    this.number = input.number;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'new-asset-dialog': NewAssetDialog;
  }
}
