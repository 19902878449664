import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import type { ApplicationViewModel } from 'src/layout/application-view-model';
import type { SearchResult } from 'src/layout/parts/d-organization-search';
import { LocalDate } from 'src/utilities/local-date';
import '../../layout/d-application';
import { FileViewerDialog } from 'src/layout/parts/file-viewer-dialog';
import type { IssueUpdateMessage, OrganizationReference } from 'src/store/api';
import type { User } from 'src/store/types';
import { DAccountLayout } from './d-account-layout';
import './mobile-app/d-mobile-app';
import './d-account-layout';
import './d-account-secured';
import './d-organizations';
import './d-prepare';
import './d-dialog-context';
import { NewIssueDialog, NewIssueResult } from 'src/content/issues/new-issue-dialog';
import { DFeedbackDialog, FeedbackResult } from 'src/layout/parts/d-feedback-dialog';
import { sessionURL } from 'src/store';

/**
 *
 * Viser enten en liste med kontoer for å velge fra. Eller viser den valgte kontoen.
 *
 * Kontoen vises i et av to modus: pending = oppstart eller open som er "vanlig"
 *
 * If the selected account requires secure authentication and the user is not logged in using this method a warning is displayed.
 *
 */
@customElement('d-account')
export class DAccount extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .outskirts {
      margin: 0 auto;
      height: 100vh;
      overflow: auto;
    }

    .outskirts d-application-header {
      margin: 0 auto;
      width: 768px;
      padding-left: 20px;
      padding-right: 20px;
      max-width: var(--appWidth);
      min-width: 360px;
      box-sizing: border-box;
    }

    .outskirtsHeader {
      margin: 0 auto;
      padding: 0 20px;
      width: 768px;
      max-width: var(--appWidth);
      min-width: 360px;
      box-sizing: border-box;
    }

    .outskirtsHeader > div {
      display: flex;
      justify-content: space-between;
      position: relative;
      color: white;
      height: var(--applicationHeaderHeight);
      box-sizing: border-box;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
    }

    .outskirtsHeader > div > div {
      display: flex;
    }

    .outskirtsHeader .logo {
      flex: none;
      width: 137px;
      height: 45px;
      margin-bottom: -5px;
      background: url(/images/trinnvis-logo.svg) -2px 10px no-repeat;
      background-size: 97px 25px;
      text-indent: -10000px;
    }

    .outskirtsHeader a {
      font-size: 13px;
      padding: 16px 20px 0 16px;
      height: 100%;
      opacity: 0.7;
      box-sizing: border-box;
      text-decoration: none;
      cursor: hand;
    }

    .outskirtsHeader a:last-child {
      margin-right: -20px;
    }

    .outskirts a {
      color: white;
    }

    .outskirts input,
    .outskirts .selectWrapper,
    .outskirts iron-autogrow-textarea {
      border-radius: 4px;
    }

    .outskirts input,
    .outskirts select,
    .outskirts iron-autogrow-textarea {
      font-family: var(--mainSans);
    }

    .outskirts h1 {
      font-size: 24px;
      font-weight: 500;
      line-height: 120%;
      color: white;
      margin-bottom: 20px;
    }

    .outskirts h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 100%;
      color: black;
      margin-bottom: 10px;
    }

    .outskirts p,
    .outskirts ul {
      font-size: 16px;
      font-weight: 300;
      line-height: 140%;
      color: black;
      margin-bottom: 10px;
    }

    .outskirts ul {
      margin-left: 18px;
    }

    .outskirts #label.d-label {
      font-weight: 500;
    }

    .outskirts d-section #flexWrapper {
      margin: 0 -5px;
      border: none;
    }

    .outskirts #flexWrapper > d-section-element,
    .outskirts #flexWrapper > d-edit-text,
    .outskirts #flexWrapper > d-select,
    .outskirts #flexWrapper > d-select-access-level,
    .outskirts #flexWrapper > d-universal-selector,
    .outskirts #flexWrapper > d-edit-textarea,
    .outskirts #flexWrapper > d-prepare-select {
      border: none;
      padding: 8px 0 6px 0;
    }

    .outskirts #flexWrapper > * > .sectionElementInner {
      border: none;
      padding: 0 6px;
    }

    .outskirts d-edit-text[disabled] {
      opacity: 1;
    }

    .outskirts d-edit-text[disabled] input {
      opacity: 0.5;
    }

    .outskirts d-application-header a {
      font-size: 13px;
      padding: 16px 20px 0 16px;
      height: 100%;
      opacity: 0.7;
      box-sizing: border-box;
    }

    .outskirts .tooltipTrigger {
      color: var(--themeColorDarkerTwo);
    }

    .outskirtsContent {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .outskirtsContent {
      margin: 0 auto;
      width: 768px;
      padding-left: 20px;
      padding-right: 20px;
      max-width: var(--appWidth);
      min-width: 360px;
      box-sizing: border-box;
    }

    d-application {
      min-height: 100%;
    }
  `;
  @query('#viewer')
  viewer!: FileViewerDialog;
  @query('d-account-layout')
  accountLayoutEl!: DAccountLayout;
  @property({ type: Object })
  user!: User;
  @property({ type: Boolean })
  active = false;
  @property({ type: String })
  client = '';
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  helpViewerOpen = false;
  @property({ type: String })
  helpViewerPage = '';
  @property({ type: Object })
  applicationViewModel: ApplicationViewModel | undefined = undefined;
  /**
   * The local path segments
   * The path segments for this account. First element is organizationId, the rest are passed.
   *
   *
   */
  @property({ type: Array })
  pathSegments: string[] = [];
  @property({ type: String })
  searchQuery = '';
  @property({ type: Array })
  searchResults: SearchResult[] = [];
  @property({ type: Boolean })
  online = false;
  /**
   * Scroll positions
   */
  @property({ type: Number })
  bodyScrollLeft = 0;
  @property({ type: Number })
  mainScrollLeft = 0;
  @property({ type: Number })
  bodyScrollTop = 0;
  @property({ type: Number })
  mainScrollTop = 0;
  @property({ type: Number })
  setMainScrollTop = 0;
  @property({ type: Boolean })
  edit = false;

  get selectedUserOrganization() {
    if (this.user?.organizations) {
      const u = this.user.organizations.filter((x) => {
        return x.id === this.organizationId;
      });
      return u.length === 1 ? u[0] : undefined;
    }
    return undefined;
  }

  get organizationStatus() {
    const userOrganization: OrganizationReference | undefined = this.selectedUserOrganization;
    if (userOrganization) {
      if (userOrganization.requiresSecureLogin && this.user.provider === '') {
        return 'secured';
      }
      if (
        userOrganization.closed &&
        userOrganization.expires &&
        userOrganization.expires < LocalDate.now().toString()
      ) {
        return 'closed';
      }

      if (userOrganization.pending) {
        return 'pending';
      }

      return 'open';
    }

    return '';
  }

  get organizationId() {
    if (this.pathSegments.length > 0) {
      return Number(this.pathSegments[0]);
    }
    return undefined;
  }

  get hasOrganizationId() {
    return this.organizationId !== undefined;
  }

  get scrollLeft() {
    if (this.scrollContextIsMain) {
      return this.mainScrollLeft;
    }
    return this.bodyScrollLeft;
  }

  private get scrollContextIsMain() {
    return !!(
      this.applicationViewModel?.loaded &&
      (this.applicationViewModel.helpViewerOpen ||
        this.applicationViewModel.tutorialViewerOpen ||
        navigator.userAgent.toLowerCase().includes('android'))
    );
  }

  renderSecuredAccount() {
    return nothing;
  }

  closeViewer() {
    this.dispatchEvent(
      new CustomEvent<{ value: boolean }>('toggle-help', { bubbles: true, composed: true, detail: { value: false } }),
    );
  }

  private async onDisplayNewIssue() {
    if (this.applicationViewModel !== undefined && this.applicationViewModel.loaded) {
      const createIssueAction = (id: string, message: IssueUpdateMessage) => {
        this.dispatchEvent(
          new CustomEvent<{ id: string; message: IssueUpdateMessage }>('create-issue', {
            composed: true,
            bubbles: true,
            detail: { id: id, message: message },
          }),
        );
      };
      const result: NewIssueResult = await NewIssueDialog.open({
        organizationId: this.applicationViewModel.currentOrganizationId,
        currentEmployeeUuid: this.applicationViewModel.currentEmployeeUuid,
        recipientOptions: this.applicationViewModel.recipientOptionsForIssues,
        createIssueAction: createIssueAction,
      });
      console.error(result);
    }
  }

  private async onDisplayFeedback() {
    const result: FeedbackResult = await DFeedbackDialog.open({ message: '' });
    if (result.action === 'done') {
      this.dispatchEvent(
        new CustomEvent<{ url: string; message: string }>('send-feedback', {
          bubbles: true,
          composed: true,
          detail: {
            url: location.href,
            message: result.message + '\n\n Økt: ' + sessionURL(),
          },
        }),
      );
    }
  }

  private get isAppPlatform() {
    return (
      this.applicationViewModel !== undefined &&
      this.applicationViewModel.loaded &&
      this.applicationViewModel.uiSettings !== undefined &&
      this.applicationViewModel.uiSettings.isAppPlatform
    );
  }

  private get isSimplifiedUi() {
    return (
      this.applicationViewModel !== undefined &&
      this.applicationViewModel.loaded &&
      this.applicationViewModel.uiSettings !== undefined &&
      this.applicationViewModel.uiSettings.isSimplifiedUi
    );
  }

  private get mobileAppViewModel() {
    return this.applicationViewModel !== undefined && this.applicationViewModel.loaded
      ? this.applicationViewModel.mobileAppViewModel
      : undefined;
  }

  renderAccountLayout() {
    if (this.applicationViewModel !== undefined) {
      return html`<d-account-layout
        id="account-layout"
        .isAppPlatform=${this.isAppPlatform}
        .isSimplifiedUi=${this.isSimplifiedUi}
        .mobileAppViewModel=${this.mobileAppViewModel}
        .help=${this.applicationViewModel.loaded && this.applicationViewModel.helpViewerOpen}
        .tutorial=${this.applicationViewModel.loaded && this.applicationViewModel.tutorialViewerOpen}
        .scrollContextIsMain=${this.scrollContextIsMain}
        .currentHelpPage=${this.applicationViewModel.loaded ? this.applicationViewModel.currentHelpPage : ''}
        .currentTutorialContent=${this.applicationViewModel.loaded
          ? this.applicationViewModel.currentTutorialContent
          : undefined}
        .availableTutorials=${this.applicationViewModel.loaded ? this.applicationViewModel.availableTutorials : []}
        .edit=${this.edit}
        @main-scroll-position-changed=${(e) => {
          this.mainScrollLeft = e.detail.left;
          this.mainScrollTop = e.detail.top;
        }}
        .setMainScrollTop=${this.setMainScrollTop}
      >
        ${this.organizationStatus === 'open'
          ? html`
              <d-application
                .isAppPlatform=${this.isAppPlatform}
                .scrollLeft=${this.scrollLeft}
                .bodyScrollTop=${this.bodyScrollTop}
                .mainScrollTop=${this.mainScrollTop}
                .scrollContextIsMain=${this.scrollContextIsMain}
                .organizationId=${this.organizationId ?? 0}
                organization-status="${this.organizationStatus}"
                .route=${this.pathSegments.slice(1)}
                .user=${this.user}
                .applicationViewModel=${this.applicationViewModel}
                ?writeAccess=${this.writeAccess}
                .searchQuery=${this.searchQuery}
                .searchResults=${this.searchResults}
                .online=${this.online}
                .edit=${this.edit}
                @set-main-scrolltop=${(e) => (this.setMainScrollTop = e.detail)}
                @close-viewer=${() => this.closeViewer()}
                @scroll-horizontal=${(e) => this.accountLayoutEl.scrollHorizontal(e, 'smooth')}
                @jump-horizontal=${(e) => this.accountLayoutEl.scrollHorizontal(e, 'auto')}
                @display-new-issue=${this.onDisplayNewIssue}
                @display-feedback=${this.onDisplayFeedback}
              ></d-application>
            `
          : nothing}
      </d-account-layout> `;
    }
    return html``;
  }

  render() {
    if (this.organizationStatus === 'secured') {
      return this.renderSecuredAccount();
    } else if (this.organizationStatus === 'pending' && this.selectedUserOrganization !== undefined) {
      return html` <d-account-layout
        .help=${this.helpViewerOpen}
        .tutorial=${false}
        .currentHelpPage=${this.helpViewerPage}
        .currentTutorialContent=${undefined}
        .availableTutorials=${[]}
      >
        <d-prepare .user=${this.user} .userOrganization=${this.selectedUserOrganization}> </d-prepare>
      </d-account-layout>`;
    }
    return html`
      ${this.applicationViewModel !== undefined
        ? html`
            <d-dialog-context></d-dialog-context>
            ${this.renderAccountLayout()}
          `
        : html` <d-organizations .user=${this.user}> </d-organizations> `}
    `;
  }

  protected updated(_changedProperties) {
    super.updated(_changedProperties);
    this.setBodyScrollStyle();

    if (_changedProperties.has('pathSegments')) {
      const lastOrganizationId = this.organizationId;
      if (lastOrganizationId !== undefined) {
        localStorage.setItem('lastOrganizationId', String(lastOrganizationId));
      }
    }
  }

  private setBodyScrollStyle() {
    if (
      !this.scrollContextIsMain &&
      this.applicationViewModel &&
      this.applicationViewModel.loaded &&
      this.applicationViewModel.currentPageView.type === 'staffing-page'
    ) {
      window.document.body.style.overflowX = 'auto';
    } else {
      window.document.body.style.overflowX = 'hidden';
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-account': DAccount;
  }
}
