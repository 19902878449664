import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../library/elements/d-label';
import '../library/elements/d-action';
import '../library/elements/d-smooth-resize';
import '../library/lists/d-occurrences-list';
import { Occurrence } from '../library/lists/d-occurrences-list';
import { DListSection } from 'src/library/lists/d-list-section';
import { numberWord } from 'src/utilities/text';
import { RelateAssetToEventOccurrencesDetail } from 'src/content/d-content';

/**
 *
 * USAGE:
 *    d-standard-event-edit
 *
 */
@customElement('d-confirmation-on-occurrences')
export class DConfirmationOnOccurrences extends LitElement {
  static readonly styles = [
    ...DListSection.styles,
    css`
      :host {
        display: block;
        position: relative;
        border-top: 1px solid var(--borderColor);
        line-height: var(--lineHeightLoose);
      }
      .header {
        position: sticky;
        top: 0;
        margin-bottom: -1px;
        border-bottom: 1px solid var(--borderColorLight);
        padding: 12px 0;
        background: white;
        z-index: 1;
      }
      .actions {
        text-align: right;
        margin-right: -8px;
        margin-bottom: -8px;
      }
      d-action {
        margin-left: 0;
      }
      d-action[plain] {
        margin: -6px -8px;
      }
      .info p {
        margin-top: 0.5em;
        margin-bottom: 0;
      }
      .info p .actions {
        white-space: nowrap;
      }
    `,
  ];
  @property({ type: Array })
  occurrences: Occurrence[] = [];
  @property({ type: Boolean })
  newItem = false;
  @property({ type: Array })
  assets: string[] = [];
  @property({ type: String })
  parentHref = '';
  @property({ type: String })
  entityType = 'event';
  @property({ type: String })
  entityName = '';
  @property({ type: Number })
  contentStickyTop = 0;

  private get label() {
    if (this.entityType === 'meeting') {
      if (this.newItem) {
        return 'Møtet finnes fra før';
      }
      return 'Møte med det nye navnet finnes fra før';
    }
    if (this.newItem) {
      return 'Oppgaven finnes fra før';
    }
    return 'Oppgave med det nye navnet finnes fra før';
  }

  private get occurrencesText() {
    if (this.entityType === 'meeting') {
      let result = 'Det finnes allerede et fremtidig møte med dette navnet. ';
      if (this.occurrences.length > 1) {
        result = 'Det finnes allerede ' + numberWord(this.occurrences.length) + ' fremtidige møter med dette navnet. ';
      }
      if (this.newItem) {
        result += 'Vil du lage et nytt likevel?';
      } else {
        result += 'Vil du bruke navnet likevel?';
      }
      return result;
    }
    let result = 'Det finnes allerede en fremtidig oppgave med dette navnet. ';
    if (this.occurrences.length > 1) {
      result = 'Det finnes allerede ' + numberWord(this.occurrences.length) + ' fremtidige oppgaver med dette navnet. ';
    }
    if (this.newItem) {
      result += 'Vil du lage en ny likevel?';
    } else {
      result += 'Vil du bruke navnet likevel?';
    }
    return result;
  }

  private get confirmationText() {
    if (this.entityType === 'meeting') {
      if (this.newItem) {
        return 'Ja, lag nytt møte';
      }
      return 'Ja, bruk dette navnet';
    }
    if (this.newItem) {
      return 'Ja, lag ny oppgave';
    }
    return 'Ja, bruk dette navnet';
  }

  private get allRelated() {
    return (
      this.occurrences.filter((o) => {
        return o.assetSelected === true || o.assetRelated === true;
      }).length === this.occurrences.length
    );
  }

  private get noneRelated() {
    return (
      this.occurrences.filter((o) => {
        return o.assetSelected === true || o.assetRelated === true;
      }).length === 0
    );
  }

  private get newAssetRelatedOccurrences(): string[] {
    return this.occurrences
      .filter((o) => {
        return !o.assetRelated && o.assetSelected;
      })
      .map((o) => {
        return o.occurrenceUuid;
      });
  }

  confirmNew() {
    this.fireRelateAssetEvent();
    this.dispatchEvent(
      new CustomEvent('confirm', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  cancelNew() {
    this.fireRelateAssetEvent();
    this.dispatchEvent(
      new CustomEvent('cancel', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  onAssetSelectionChanged(e) {
    e.stopPropagation();
    this.occurrences = e.detail.value;
  }

  selectAllAssets() {
    this.occurrences = this.occurrences.map((o) => {
      o.assetSelected = true;
      return o;
    });
  }

  fireRelateAssetEvent() {
    if (this.newAssetRelatedOccurrences.length) {
      this.dispatchEvent(
        new CustomEvent<RelateAssetToEventOccurrencesDetail>('relate-asset-to-event-occurrences', {
          bubbles: true,
          composed: true,
          detail: {
            asset: this.assets[0],
            eventOccurrences: this.newAssetRelatedOccurrences,
          },
        }),
      );
    }
  }

  renderConditionalInfo() {
    if (this.assets.length) {
      return this.renderConditionalInfoForAsset();
    } else {
      return this.renderConditionalInfoNoAssets();
    }
  }

  private renderConditionalInfoNoAssets() {
    return html`
      <p>${this.occurrencesText}</p>
      <div class="actions">
        <d-action @click=${() => this.cancelNew()}>Nei, avbryt</d-action>
        <d-action @click=${() => this.confirmNew()}>${this.confirmationText}</d-action>
      </div>
    `;
  }

  private renderConditionalInfoForAsset() {
    if (this.occurrences.length === 1) {
      if (this.allRelated) {
        return html`
          <p>Oppgaven finnes allerede, og den er knyttet til den aktuelle utstyrsenheten. Vil du gjenta oppgaven?</p>
          <div class="actions">
            <d-action @click=${() => this.cancelNew()}>Nei, avbryt</d-action>
            <d-action @click=${() => this.confirmNew()}>Ja, gjenta oppgaven</d-action>
          </div>
        `;
      }
      return html`
        <p>
          Oppgaven finnes allerede, men den er ikke knyttet til den aktuelle utstyrsenheten.
          <d-action @click=${this.selectAllAssets}>Knytt til utstyrsenheten</d-action>
        </p>
        <p>Vil du gjenta oppgaven?</p>
        <div class="actions">
          <d-action @click=${() => this.cancelNew()}>Nei, avbryt</d-action>
          <d-action @click=${() => this.confirmNew()}>Ja, gjenta oppgaven</d-action>
        </div>
      `;
    } else {
      if (this.allRelated) {
        return html`
          <p>
            Det finnes allerede ${numberWord(this.occurrences.length)} fremtidige gjentakelser av denne oppgaven,
            ${this.occurrences.length === 2 ? html`begge` : html`alle`} knyttet til den aktuelle utstyrsenheten.
          </p>
          <p>Vil du lage en ny gjentakelse?</p>
          <div class="actions">
            <d-action @click=${() => this.cancelNew()}>Nei, avbryt</d-action>
            <d-action @click=${() => this.confirmNew()}>Ja, lag ny gjentakelse</d-action>
          </div>
        `;
      }
      if (this.noneRelated) {
        return html`
          <p>
            Det finnes allerede ${numberWord(this.occurrences.length)} fremtidige gjentakelser av denne oppgaven, men de
            er ikke knyttet til den aktuelle utstyrsenheten. Trykk på utstyrsikonet for å knytte en oppgave til
            utstyrsenheten eller
            <span><d-action plain @click=${this.selectAllAssets}>Knytt til alle</d-action></span>
          </p>
          <p>Vil du lage en ny gjentakelse?</p>
          <div class="actions">
            <d-action @click=${() => this.cancelNew()}>Nei, avbryt</d-action>
            <d-action @click=${() => this.confirmNew()}>Ja, lag ny gjentakelse</d-action>
          </div>
        `;
      }
      return html`
        <p>
          Det finnes allerede ${numberWord(this.occurrences.length)} fremtidige gjentakelser av denne oppgaven, men ikke
          alle er knyttet til den aktuelle utstyrsenheten. Trykk på utstyrsikonet for å knytte en oppgave til
          utstyrsenheten eller
          <d-action plain @click=${this.selectAllAssets}>Knytt til alle</d-action>
        </p>
        <p>Vil du lage en ny gjentakelse?</p>
        <div class="actions">
          <d-action @click=${() => this.cancelNew()}>Nei, avbryt</d-action>
          <d-action @click=${() => this.confirmNew()}>Ja, lag ny gjentakelse</d-action>
        </div>
      `;
    }
  }

  render() {
    return html`
      <div class="header" style="top: ${this.contentStickyTop}px">
        <d-label allow-wrap .label=${this.label}></d-label>
        <d-smooth-resize><div class="info">${this.renderConditionalInfo()}</div></d-smooth-resize>
      </div>
      <d-occurrences-list
        .entityName=${this.entityName}
        .occurrences=${this.occurrences}
        .assets=${this.assets}
        .parentHref=${this.parentHref}
        @asset-selection-changed=${(e) => this.onAssetSelectionChanged(e)}
      ></d-occurrences-list>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-confirmation-on-occurrences': DConfirmationOnOccurrences;
  }
}
