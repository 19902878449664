import { html, nothing } from 'lit';
import 'src/content/entity-content';
import '../../library/fields/index.js';
import '../../library/editors/index.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/fields/d-expansion.js';
import '../../pages/computers-page/d-edit-single-related-item.js';
import './d-view-checklist.js';
import '../../library/editors/elements/d-select-checkbox.js';
import './d-view-checkpoint.js';
import './d-view-related-items.js';

import { customElement, property } from 'lit/decorators.js';
import { DataMapElement, DataMapItem } from './data-map-element.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { AttachmentItem } from 'src/library/lists/d-list-section-attachment.js';
import type { DataItem } from 'src/pages/computers-page/d-data-item-view.js';
import type { RelatedItem } from 'src/pages/computers-page/d-view-related-items.js';

export interface ComputerMapCloudService extends DataMapItem {
  personalDataItems: DataItem[];
  connectionType: string;
  unitType: 'cloudService';
  supplierName: string;
  supplierUuid: string;
  contractName: string;
  contractUuid: string;
  serviceName: string;
  availableSuppliers: SelectDropdownOption[];
  availableContracts: SelectDropdownOption[];
  attachments: AttachmentItem[];
  deletable: boolean;
}

export interface CloudServiceEditItem {
  supplierUuid: string;
  serviceName: string;
  connectionType: string;
  contractUuid: string;
}

/**
 *
 */
@customElement('d-computer-unit-cloud-service')
export class DComputerUnitCloudService extends DataMapElement<ComputerMapCloudService, CloudServiceEditItem> {
  static readonly styles = [...DataMapElement.styles];
  @property({ type: Boolean })
  simplifiedPrivacy = false;

  _displayTypeName(): string {
    return 'Skytjeneste';
  }

  _displayName() {
    return this.item.supplierName;
  }

  _getName(type, uuid) {
    if (Array.isArray(uuid)) {
      uuid = uuid[0];
    }
    for (const item of type) {
      if (item.uuid === uuid) {
        return item.name;
      }
    }
  }

  _isValid(): boolean {
    return this.editItem !== undefined && this.editItem.supplierUuid !== '';
  }

  _isEmpty(prop) {
    return prop.length === 0;
  }

  renderUnitView() {
    const contracts: RelatedItem[] = this.item.availableContracts
      .filter((c) => c.value === this.item.contractUuid)
      .map((c) => ({ href: '', name: c.text }));

    return html` <section view="">
      <d-section>
        <d-view-related-items field="cloudServices_serviceProviderContract" .items=${contracts} type="contracts">
        </d-view-related-items>
      </d-section>

      <d-list-section-attachment
        .attachments="${this.item.attachments}"
        class="computers"
        label="Vedlegg"
        page-id="20"
        sort="1"
        .writeAccess=${this.writeAccess}
        theme-page
      >
      </d-list-section-attachment>
    </section>`;
  }

  renderUnitEdit(editItem: CloudServiceEditItem) {
    return html`
      <d-section>
        <d-edit-single-related-item
          class="minWidth300"
          field="cloudServices_serviceProvider"
          .items=${this.item.availableSuppliers}
          @new-item=${this.onNewPartner}
          placeholder="Velg leverandør"
          .relatedItem=${editItem.supplierUuid}
          @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              supplierUuid: e.detail.relatedItem,
            };
          }}
        >
        </d-edit-single-related-item>
        <d-edit-text
          theme-page
          field="cloudServices_name"
          value="${editItem.serviceName}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              serviceName: e.detail.value,
            };
          }}
        ></d-edit-text>
        ${this.simplifiedPrivacy
          ? nothing
          : html` <d-select-dropdown
              theme-page
              class="minWidth300"
              field="cloudServices_connectionType"
              .options="${this.connectionTypes}"
              .value="${editItem.connectionType}"
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  connectionType: e.detail.value,
                };
              }}
            >
            </d-select-dropdown>`}
      </d-section>
      <d-section>
        <d-edit-single-related-item
          field="cloudServices_serviceProviderContract"
          .items=${this.item.availableContracts}
          @new-item=${this.onNewContract}
          placeholder="Velg avtale"
          .relatedItem="${editItem.contractUuid}"
          @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              contractUuid: e.detail.relatedItem,
            };
          }}
        >
        </d-edit-single-related-item>
      </d-section>
    `;
  }

  initializeEditItem(): CloudServiceEditItem {
    return {
      supplierUuid: this.item.supplierUuid,
      serviceName: this.item.serviceName,
      connectionType: this.item.connectionType,
      contractUuid: this.item.contractUuid,
    };
  }

  protected isDeleteDisabled(): boolean {
    return !this.item.deletable;
  }

  protected typeClasses(): string {
    return '';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-computer-unit-cloud-service': DComputerUnitCloudService;
  }
}
