import { css, html, LitElement } from 'lit';
import '../../library/elements/d-label.js';
import '../../library/elements/d-action.js';
import '../../library/components/pdf-viewer/index.js';
import { customElement, property } from 'lit/decorators.js';

/**
 * Viser pdf dokument
 *
 */
@customElement('d-view-document')
export class DViewDocument extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .sectionElementInner {
      display: block;
    }

    d-label {
      margin-right: 6px;
      line-height: 140%;
    }

    img {
      box-sizing: border-box;
      width: 100%;
      border: 1px solid silver;
      margin-top: 8px;
    }

    /* d-view-document */

    .sectionHeader {
      position: -webkit-sticky;
      position: sticky;
      top: -1px;
      background: white;
      border-top: 1px solid var(--borderColor);
      border-bottom: 1px solid var(--borderColor);
      margin-bottom: 0;
      padding-top: 15px;
      z-index: 1;
    }

    .flexbox-split {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: -4px;
    }

    .flexbox-split > d-label {
      margin-bottom: 4px;
      flex-grow: 1;
      flex-shrink: 1;
    }

    pdf-viewer {
      border-left: 1px solid var(--borderColor);
      border-right: 1px solid var(--borderColor);
    }

    pdf-viewer pdf-viewer-document #viewer {
      padding-top: 0;
      padding-left: 0;
    }
  `;
  @property({ type: String })
  label = '';
  @property({ type: String })
  sublabel = '';
  @property({ type: String })
  field = '';
  @property({ type: String })
  subfield = '';
  @property({ type: Boolean })
  hideTooltip = false;
  @property({ type: String })
  tooltip = '';
  @property({ type: String })
  href = '';
  @property({ type: String })
  downloadHref = '';
  @property({ type: String })
  name = '';
  @property({ type: String })
  attachmentHref = '';
  @property({ type: String })
  token = '';
  @property({ type: Boolean, attribute: 'no-animation' })
  noAnimation = false;

  _updateHref(token, attachmentHref) {
    this.href = '';
    this.downloadHref = '';
    if (token && attachmentHref) {
      fetch(attachmentHref, {
        headers: {
          Authorization: 'Basic ' + token,
        },
      })
        .then((r) => {
          return r.json();
        })
        .then((data) => {
          console.log(data);
          this.href = data.urlForInline;
          this.downloadHref = data.url;
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  render() {
    return html`
      <div class="sectionHeader flexbox-split flex-baseline">
        <d-label
          field="${this.field}"
          hide-tooltip="${this.hideTooltip}"
          label="${this.label}"
          subfield="${this.subfield}"
          sublabel="${this.sublabel}"
          tooltip="${this.tooltip}"
        ></d-label>
        <d-action @click=${() => this.downloadURI(this.downloadHref, this.name + '.pdf')}>Last ned</d-action>
      </div>
      <pdf-viewer src="${this.href}" ?no-animation=${this.noAnimation}></pdf-viewer>
    `;
  }

  downloadURI(uri = '', name = '') {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    link.target = '_blank';
    this.appendChild(link);
    link.click();
    this.removeChild(link);
    link.remove();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-document': DViewDocument;
  }
}
