import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import type { ContentEditDoneDetail, ContentView } from 'src/content/d-content';
import type { ApplicationViewModel } from 'src/layout/application-view-model';
import type { SearchResult } from 'src/layout/parts/d-organization-search';
import type { SettingsItem } from 'src/layout/parts/d-organization-settings';
import { DShareContentDialog, ShareContentResult } from 'src/layout/parts/d-share-content-dialog';
import { DPage } from 'src/pages/d-page';
import 'src/pages/d-page';
import 'src/content/d-content';
import 'src/layout/d-application-layout';
import { uuid } from 'src/utilities/text';
import '../content/employees/reassign-functions-dialog';
import '../library/fields/d-spinner-robot';
import type { DApplicationLayout } from './d-application-layout';
import './parts/file-viewer-dialog';
import './parts/d-new-document';
import './parts/d-organization-header';
import { DOrganizationHeader, HeaderSearchView, HeaderSettingsView } from './parts/d-organization-header';
import './parts/d-page-menu';
import './parts/d-robot-alert';
import './parts/d-select-organization';
import '../library/editors/elements/d-select-checkbox';
/**
 *
 *
 */
@customElement('d-application')
export class DApplication extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      position: relative;
    }

    .content-dummy {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      /* To prevent flickering when header is reduced */
      min-height: calc(100vh + 1px);
      background-color: white;
      box-shadow: 0 0 10px hsla(1, 0%, 0%, 0.3);
    }

    .content-dummy d-spinner-robot {
      max-height: 500px;
    }

    @media print {
      d-organization-header {
        display: none;
      }
    }
  `;
  @property({ type: Boolean })
  isAppPlatform = false;
  @property({ type: Array })
  searchResults: SearchResult[] = [];
  /**
   * No animation. Turn off all animations. Used by storybook to enable consistent snapshots.
   */
  @property({ type: Boolean, reflect: true, attribute: 'no-animation' })
  noAnimation = false;
  @property({ type: Object })
  user!: { username: string; token: string };
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Object })
  organization: any = {};
  @property({ type: String })
  path = 'undefined';
  /**
   * The route path segments. The first element is the current pageId
   */
  @property({ type: Array })
  route: string[] = [];
  @property({ type: Boolean })
  active = false;
  @property({ type: Boolean })
  online = false;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  feedbackOpen = false;
  @property({ type: Boolean })
  issueOpen = false;
  @property({ type: Boolean })
  menuUncoveredSideWays = false;
  @property({ type: Boolean })
  scrollContextIsMain = false;
  @property({ type: Object })
  applicationViewModel!: ApplicationViewModel;
  @property({ type: Number })
  appWidth = 0;
  @query('d-page')
  page?: DPage;
  @property({ type: String })
  searchQuery = '';
  @property({ type: Boolean })
  searchOpen = false;
  @property({ type: Boolean })
  settingsOpen = false;
  @property({ type: Number })
  bodyScrollTop = 0;
  @property({ type: Number })
  mainScrollTop = 0;
  @property({ type: Number })
  scrollLeft = 0;
  @property({ type: Boolean })
  edit = false;
  private saveNewMode = false;
  @state()
  private currentMode: 'edit' | 'new' | 'done' | 'normal' | 'diff' = 'normal';
  @state()
  private savedLevels: { context: string; href: string; top: number }[] = [
    { context: '', href: '', top: 0 },
    { context: '', href: '', top: 0 },
    { context: '', href: '', top: 0 },
    { context: '', href: '', top: 0 },
    { context: '', href: '', top: 0 },
    { context: '', href: '', top: 0 },
  ];
  @state()
  private setScrollTops: number[] = [0, 0, 0, 0, 0, 0];
  @query('d-application-layout')
  private _applicationLayoutEl?: DApplicationLayout;

  private get currentLevelData() {
    return {
      level: this.currentLevel,
      href: '/account/' + this.organizationId + '/' + this.route.join('/'),
    };
  }

  private get headerSearchView(): HeaderSearchView {
    return {
      type: 'search',
      query: this.searchQuery,
      results: this.searchResults,
      opened: true,
    };
  }

  private get headerSettingsView(): HeaderSettingsView {
    return {
      type: 'settings',
      user: {
        username: '',
        token: '',
        authDescription: '',
        criiptoAuthDescription: '',
      },
      isEmployee: false,
      alerts: 'NONE',
      receiveReminders: 'SMS',
      items: this.settingsItems,
    };
  }

  private get currentLevel() {
    if (this.route.length) {
      return (this.route.length + 1) / 2;
    }
    return 0;
  }

  private get settingsItems(): SettingsItem[] {
    if (this.applicationViewModel.loaded) {
      return this.applicationViewModel.settings;
    }
    return [];
  }

  private get levelData() {
    if (this.applicationViewModel.loaded) {
      const vm = { ...this.applicationViewModel };
      const levels = [{ context: 'content', href: '' }];
      if (vm.currentPathArray.length > 3) {
        levels.push({ context: 'content', href: vm.currentPageView.href.slice(0, -1) });
      }
      if (vm.contentViews.length) {
        vm.contentViews.forEach((view) => {
          levels.push({ context: 'content', href: view.href });
        });
      }
      return levels.map((level, index) => {
        if (index === levels.length - 1) {
          if (this.scrollContextIsMain) {
            level.context = 'main';
          } else {
            level.context = 'body';
          }
        }
        return level;
      });
    }
    return [];
  }

  async onShareContent() {
    if (this.applicationViewModel.loaded) {
      const result: ShareContentResult = await DShareContentDialog.open({
        employees: this.applicationViewModel.employeesForShare,
      });
      if (result.action === 'send') {
        this.dispatchEvent(
          new CustomEvent<{ message: string; recipients: string[] }>('share', {
            bubbles: true,
            composed: true,
            detail: { message: result.message, recipients: result.recipients },
          }),
        );
      }
    }
  }

  saveLevel(level, context, href, top) {
    this.savedLevels[level] = { context, href, top };
  }

  saveBodyScrollingLevel() {
    if (this.currentLevelData && !this.scrollContextIsMain) {
      let top = this.bodyScrollTop - this.levelTop(this.currentLevelData.level);
      if (top < 0) {
        top = 0;
      }
      this.saveLevel(this.currentLevelData.level, 'body', this.currentLevelData.href, top);
    }
  }

  saveMainScrollingLevel() {
    if (this.currentLevelData) {
      let top = this.mainScrollTop - this.levelTop(this.currentLevelData.level);
      if (top < 0) {
        top = 0;
      }
      this.saveLevel(this.currentLevelData.level, 'main', this.currentLevelData.href, top);
    }
  }

  getSavedLevelScrollTop(level, href) {
    let result = 0;
    if (this.savedLevels[level].href === href) {
      result = this.savedLevels[level].top;
    }
    return result;
  }

  scrollBody(top) {
    setTimeout(() => {
      window.scrollTo({
        top: top,
        left: window.scrollX,
        behavior: 'smooth',
      });
    }, 0);
  }

  scrollMain(top) {
    this.dispatchEvent(
      new CustomEvent('set-main-scrolltop', {
        bubbles: true,
        composed: true,
        detail: top,
      }),
    );
  }

  setNewScrollTops() {
    if (this.applicationViewModel.loaded) {
      const newLevels = this.levelData;
      if (this.savedLevels.length) {
        let bodyScrolled = false;
        let mainScrolled = false;
        const setScrollTops: number[] = [];
        this.setScrollTops.forEach((item, index) => {
          let scrollTop = 0;
          if (newLevels[index]) {
            scrollTop = this.getSavedLevelScrollTop(index, newLevels[index].href);
            if (scrollTop > 0) {
              if (newLevels[index].context === 'body') {
                this.scrollBody(scrollTop + this.levelTop(index));
                bodyScrolled = true;
              }
              if (newLevels[index].context === 'main') {
                this.scrollMain(scrollTop + this.levelTop(index));
                mainScrolled = true;
              }
            }
          }
          setScrollTops.push(scrollTop);
        });
        if (!bodyScrolled && !this.scrollContextIsMain) {
          this.scrollBody(0);
        }
        if (!mainScrolled) {
          this.scrollMain(0);
        }
        this.setScrollTops = setScrollTops;
      }
    }
  }

  /**
   * Render each content view.
   *
   * @param contentView
   */
  renderContentView(contentView: ContentView, index: number, array: ContentView[], singleUserVersion) {
    const contentLevel = index + 2;
    let covered = false;
    if (this.currentLevel > contentLevel) {
      covered = true;
    }
    if (contentView && this.applicationViewModel.loaded) {
      return html`
        <d-content
          slot="level-${contentLevel}-content"
          id="level-${contentLevel}"
          ?covered=${covered}
          .contentView=${contentView}
          .parentHref=${contentView.parentHref}
          .writeAccess=${contentView.currentUserHasAccess}
          .singleUserVersion=${singleUserVersion}
          .mode=${this.contentMode(index, array)}
          .setScrolltop=${this.setScrollTops[contentLevel]}
          @scrolltop-changed=${(e) => this.saveLevel(contentLevel, 'content', contentView.href, e.detail)}
          @share-content=${this.onShareContent}
          @content-edit-done=${(e: CustomEvent<ContentEditDoneDetail>) => {
            this.currentMode = 'normal';
            if (this.saveNewMode) {
              this.saveNewMode = false;
              e.stopPropagation();
              this.dispatchEvent(
                new CustomEvent<ContentEditDoneDetail>('content-edit-done', {
                  bubbles: true,
                  composed: true,
                  detail: {
                    ...e.detail,
                    uuid: uuid(),
                  },
                }),
              );
            }
          }}
        ></d-content>
      `;
    }
    return html`
      <div slot="level-${contentLevel}-content" class="content-dummy">
        ${this.edit ? html` <d-spinner-robot></d-spinner-robot> ` : nothing}
      </div>
    `;
  }

  contentViewsWithDummies(contentViews) {
    const levels = [0, 1, 2, 3];
    return levels.map((level) => {
      if (contentViews[level]) {
        return contentViews[level];
      }
      return undefined;
    });
  }

  levelTop(level) {
    let levelId = 'level-' + level;
    if (level === 1) {
      levelId = 'd-page';
    }
    const elm = this.shadowRoot?.getElementById(levelId);
    if (elm) {
      return elm.offsetTop;
    }
    return 0;
  }

  private get isPageMenuCovered(): boolean {
    return (
      (this.scrollLeft > 0 ||
        (this.currentLevel > 0 && this.appWidth < 1024) ||
        (this.currentLevel > 1 && this.appWidth < 1220) ||
        (this.currentLevel > 4 && this.appWidth < 1280)) &&
      !this.menuUncoveredSideWays
    );
  }

  uncoverMenu() {
    if (this.applicationViewModel.loaded) {
      if (this.appWidth < 845) {
        this.dispatchEvent(
          new CustomEvent<{ href: string }>('navigate', {
            bubbles: true,
            composed: true,
            detail: { href: '/account/' + this.applicationViewModel.currentOrganizationId },
          }),
        );
      } else {
        if (this.scrollLeft > 0) {
          let type = 'scroll-horizontal';
          if (this.applicationViewModel.helpViewerOpen || this.applicationViewModel.tutorialViewerOpen) {
            type = 'jump-horizontal';
          }
          this.dispatchEvent(
            new CustomEvent<{ value: number }>(type, {
              composed: true,
              bubbles: true,
              detail: { value: 0 },
            }),
          );
          setTimeout(() => {
            this.menuUncoveredSideWays = true;
          }, 0);
        } else {
          this.menuUncoveredSideWays = true;
        }
      }
    }
  }

  updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('bodyScrollTop')) {
      this.saveBodyScrollingLevel();
    }
    if (_changedProperties.has('mainScrollTop')) {
      this.saveMainScrollingLevel();
    }
    if (_changedProperties.has('applicationViewModel')) {
      this.setNewScrollTops();
    }
    if (_changedProperties.has('route')) {
      this.menuUncoveredSideWays = false;
    }
  }

  render() {
    const viewModel = this.applicationViewModel;
    return viewModel.loaded
      ? html` <d-application-layout
          id="application-layout"
          .isAppPlatform=${this.isAppPlatform}
          .organizationId=${this.organizationId}
          .route=${this.route}
          .currentLevel=${this.currentLevel}
          .settings=${this.settingsOpen}
          ?edit=${this.edit}
          .uncoverMenu=${this.menuUncoveredSideWays}
          @app-width-changed=${(e: CustomEvent<{ appWidth: number }>) => (this.appWidth = e.detail.appWidth)}
          @open-search=${() => this.openSearch()}
        >
          <d-organization-header
            slot="header"
            id="orgHeader"
            .menuCovered=${this.isPageMenuCovered}
            online="${this.online}"
            organization="${this.organization}"
            .organizationId=${this.organizationId}
            .user="${this.user}"
            .writeAccess=${this.writeAccess}
            .displayedUserName=${viewModel.displayedUserName}
            .headerSearchView=${this.headerSearchView}
            .headerSettingsView=${this.headerSettingsView}
            .startTask=${viewModel.headerStartTask.startTask}
            .startTaskExpanded=${viewModel.headerStartTask.startTaskExpanded}
            .userTotal=${viewModel.headerStartTask.userTotal}
            .userExecuted=${viewModel.headerStartTask.userExecuted}
            .singleUserVersion=${viewModel.singleUserVersion}
            .helpViewerOpen=${viewModel.helpViewerOpen}
            .tutorialViewerOpen=${viewModel.tutorialViewerOpen}
            .tutorialActive=${viewModel.tutorialActive}
            .featureStates=${viewModel.featureStates}
            .uiSettings=${viewModel.uiSettings}
            @header-height-changed=${this.onHeaderHeightChanged}
            @toggle-search=${(e) => this.toggleSearch(e)}
            @toggle-settings=${(e) => this.toggleSettings(e)}
            @uncover-menu=${() => this.uncoverMenu()}
          >
          </d-organization-header>
          <d-page-menu
            slot="menu"
            .organizationId=${viewModel.currentOrganizationId}
            .currentLevel=${this.currentLevel}
            .pageId=${viewModel.currentPageId}
            .pageMenu=${viewModel.pageMenu}
            .employeesCount=${viewModel.employeesCount}
            .specialTerms=${viewModel.specialTerms}
            ?covered=${this.currentLevel > 0}
            .setScrolltop=${this.setScrollTops[0]}
            .featureStates=${viewModel.featureStates}
            @scrolltop-changed=${(e) => this.saveLevel(0, 'content', '/account/' + this.organizationId + '/', e.detail)}
          >
          </d-page-menu>
          <d-page
            id="d-page"
            slot="level-1-content"
            .pageView=${viewModel.currentPageView}
            .scrollLeft=${this.scrollLeft}
            .setScrolltop=${this.setScrollTops[1]}
            .featureStates=${viewModel.featureStates}
            @scrolltop-changed=${(e) =>
              this.saveLevel(
                1,
                'content',
                'currentPageView' in viewModel ? viewModel.currentPageView.href.slice(0, -1) : '',
                e.detail,
              )}
            @share-content=${this.onShareContent}
            organization="${this.organization}"
            .user="${this.user}"
            .writeAccess=${this.writeAccess}
            .singleUserVersion=${viewModel.singleUserVersion}
            .appWidth=${this.appWidth}
            ?covered=${this.currentLevel > 1}
            ?fit-content=${viewModel.currentPageView.type === 'staffing-page'}
          ></d-page>
          ${this.contentViewsWithDummies(viewModel.contentViews).map((contentView, index, array) =>
            this.renderContentView(contentView, index, array, viewModel.singleUserVersion),
          )}
          <d-new-document
            slot="new-document"
            .appWidth=${this.appWidth}
            ?writeAccess=${this.writeAccess}
            .pageMenu=${viewModel.pageMenu}
            .currentPathArray=${viewModel.currentPathArray}
            .singleUser=${viewModel.singleUserVersion}
            .featureStates=${viewModel.featureStates || []}
            .userEmails=${viewModel.userEmails}
            .partners=${viewModel.partners}
            .defaultFunctionUuid=${viewModel.defaultFunctionUuid}
          ></d-new-document>
        </d-application-layout>
        <d-robot-alert
          .currentRobotAlert=${viewModel.currentRobotAlert}
          .functionsByTemplateId=${viewModel.functionsByTemplateId}
        ></d-robot-alert>
        </div>
      `
      : html` <d-spinner-robot ?no-animation=${this.noAnimation} size="60"></d-spinner-robot> `;
  }

  private onHeaderHeightChanged(e: CustomEvent<{ height: number }>) {
    if (this._applicationLayoutEl) {
      this._applicationLayoutEl.style.setProperty('--top-height', e.detail.height + 'px');
    }
  }

  private openSearch() {
    if (!this.searchOpen) {
      const header = this.shadowRoot?.getElementById('orgHeader') as DOrganizationHeader;
      if (header) {
        header.toggleSearch();
      }
    }
  }

  private toggleSearch(e) {
    this.searchOpen = e.detail.value;
    if (this.searchOpen) {
      this.settingsOpen = false;
    }
  }

  private toggleSettings(e) {
    this.settingsOpen = e.detail.value;
    if (this.settingsOpen) {
      this.searchOpen = false;
    }
  }

  private contentMode(index: number, array: ContentView[]) {
    return index === array.length - 1 ? this.currentMode : 'normal';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-application': DApplication;
  }
}
