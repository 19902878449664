import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { LocalDate } from 'src/utilities/local-date.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/editors/elements/d-select-tag.js';
import '../../library/elements/d-label.js';
import '../../library/elements/d-section.js';
import '../../library/fields/d-expansion.js';
import '../../library/fields/d-view-info.js';
import './d-sickness-absence-statistics-periods.js';
import { ReportSection } from './report-section.js';
import type { EmployeeForStaffingCalendar } from 'src/store';
import { EmployeeViewModelGenderEnum } from 'src/store/api';

export type SicknessAbsenceStats = SicknessAbsenceStatsData | SicknessAbsenceStatsNoGender;

export interface SimplePeriodData {
  employees: EmployeeWithWorkHours[];
  periods: EmployeeStatsPeriod[];
}

export interface QuarterData {
  quarterName: string;
  workDays: number;
  absenceDays: number;
  male: StatisticsAggregate;
  female: StatisticsAggregate;
}

export interface EmployeeStatsPeriod {
  employeeUuid: string;
  employeeName: string;
  employeeGender: string;
  types: string[];
  start: string;
  end: string;
  absenceDays: number;
}

export interface EmployeeWithWorkHours {
  uuid: string;
  name: string;
  gender: string;
  workHours: number;
}

export interface SicknessAbsenceStatsDataYear {
  yearName: string;
  workDays: number;
  absenceDays: number;
  female: StatisticsAggregate;
  male: StatisticsAggregate;
  quarters: QuarterData[];
}

export interface StatisticsAggregate {
  workDays: number;
  absenceDays: number;
  periodsCountTotal: number;
  periods: EmployeeStatsPeriod[];
}

export interface SicknessAbsenceStatsData {
  genderDefined: true;
  years: SicknessAbsenceStatsDataYear[];
}

export interface SicknessAbsenceStatsNoGender {
  genderDefined: false;
}

/**
 *
 */
@customElement('d-sickness-absence-statistics')
export class DSicknessAbsenceStatistics extends ReportSection {
  static readonly styles = [
    ...ReportSection.styles,
    css`
      .col-1 {
        flex: 1;
        min-width: 130px;
      }

      .col-2 {
        display: flex;
        flex: 1;
        min-width: 200px;
      }

      .col-2-1 {
        flex-shrink: 0;
        flex-grow: 1;
        width: 75px;
        min-width: 75px;
      }

      .col-2-2 {
        flex-shrink: 0;
        flex-grow: 1;
        width: 75px;
        min-width: 75px;
      }

      .genderSelector > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--borderColorOnGray);
        border-bottom: 1px solid var(--borderColorOnGray);
        margin-top: -1px;
        padding: 8px 0;
      }

      #year {
        flex: none;
      }

      .quarterHeader {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        border-top: 1px solid var(--borderColorOnGray);
        border-bottom: 1px solid var(--borderColorOnGray);
        margin-top: -1px;
        padding: 16px 0 8px 0;
      }

      .quarterHeader > * {
        margin-top: 4px;
      }

      .quarterHeader h2.quarterName {
        font-size: 18px;
      }

      .quarterHeader d-label {
        margin-left: 20px;
      }

      .genderHeader {
        display: flex;
        align-items: baseline;
        padding-bottom: 10px;
      }

      .genderName {
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .periodGroupHeader {
        display: flex;
        margin-top: -1px;
        padding: 8px 0;
        border-top: 1px solid var(--borderColorOnGray);
        border-bottom: 1px solid var(--borderColorOnGray);
      }

      .periodGroupHeader #label {
        font-weight: 500;
      }

      d-sickness-absence-statistics-periods:last-child {
        margin-bottom: -11px;
      }
    `,
  ];
  @property({ type: Array })
  employees: EmployeeForStaffingCalendar[] = [];
  @property({ type: String })
  selectedYear = LocalDate.now().toString().substring(0, 4);
  @property({ type: Array })
  displayOptions: string[] = [];
  @property({ type: Array })
  selectedEmployees: string[] = [];
  /**
   * Statitiskk for hver år
   */
  @property({ type: Object })
  sicknessAbsenceStats!: SicknessAbsenceStats;
  private genderOptions = [
    { value: EmployeeViewModelGenderEnum.Female, text: 'Kvinne' },
    { value: EmployeeViewModelGenderEnum.Male, text: 'Mann' },
  ];

  constructor() {
    super('Sykefraværsstatistikk');
  }

  renderContent() {
    return this.sicknessAbsenceStats.genderDefined
      ? html`<d-section>
            <d-select-dropdown
              controller
              id="year"
              label="År"
              .options="${this.years()}"
              .value="${this.selectedYear}"
              @value-changed=${this.onSelectedYearChanged}
            ></d-select-dropdown>
            <d-select-tag
              class="displayOption"
              deselectable
              label="Visningsvalg"
              .options="${this.calculateDisplayOptions(this.displayOptions)}"
              type="tag"
              theme-page
              controller
              .value="${this.displayOptions}"
              @value-changed=${this.onDisplayOptionsChanged}
            ></d-select-tag>
          </d-section>

          <div class="stats">
            <div class="content">
              ${this.sicknessAbsenceStats.years
                .filter((y) => y.yearName === this.selectedYear)
                .map((year) => this.renderYear(year))}
            </div>
          </div>`
      : this.renderMissingGenders();
  }

  renderPeriods(periods: EmployeeStatsPeriod[]) {
    const checkDurationAndNotSickChild = (
      period: EmployeeStatsPeriod,
      minInclusive: number,
      maxInclusive: number | undefined = undefined,
    ) => {
      const periodLength = LocalDate.fromString(period.start).until(LocalDate.fromString(period.end)) + 1;
      return (
        !period.types.includes('sickChildren') &&
        periodLength >= minInclusive &&
        (maxInclusive === undefined || periodLength <= maxInclusive)
      );
    };

    const periodTypes: {
      label: string;
      type: string;
      check: (p: EmployeeStatsPeriod) => boolean;
    }[] = [
      {
        label: 'Inntil 3 dager',
        type: 'short',
        check: (p) => checkDurationAndNotSickChild(p, 0, 3),
      },
      {
        label: '4 – 16 dager',
        type: 'medium',
        check: (p) => checkDurationAndNotSickChild(p, 4, 16),
      },
      {
        label: 'Over 16 dager',
        type: 'long',
        check: (p) => checkDurationAndNotSickChild(p, 17),
      },
      {
        label: 'Over 8 uker',
        type: 'extraLong',
        check: (p) => checkDurationAndNotSickChild(p, 57),
      },
      {
        label: 'Sykt barn',
        type: 'sickChild',
        check: (p) => p.types.includes('sickChildren'),
      },
    ];

    return periodTypes.map((p) => {
      return html`
        <d-sickness-absence-statistics-periods
          .showPeriods=${this.displayOptions.includes('showPeriods')}
          .label=${p.label}
          .periods=${periods.filter((period) => p.check(period))}
        >
        </d-sickness-absence-statistics-periods>
      `;
    });
  }

  private setGender(e: CustomEvent<{ value: string[] }>, employeeUuid: string) {
    const gender =
      e.detail.value.length === 0
        ? EmployeeViewModelGenderEnum.Undefined
        : (e.detail.value[0] as EmployeeViewModelGenderEnum);
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; gender: EmployeeViewModelGenderEnum }>('employee-gender', {
        bubbles: true,
        composed: true,
        detail: { uuid: employeeUuid, gender },
      }),
    );
  }

  private percentage(part: number, total: number) {
    if (part && total) {
      return Math.round((part / total) * 10000) / 100 + '%';
    }
    return '0%';
  }

  private round(n: number) {
    return Math.round(n * 100) / 100;
  }

  private roundDays(n: number) {
    const days = Math.round(n * 100) / 100;
    if (!days) {
      return 'Ingen';
    }
    if (days === 1) {
      return days + ' dag';
    }
    return days + ' dager';
  }

  private periodsText(n: number) {
    if (!n) {
      return '';
    }
    if (n === 1) {
      return n + ' periode';
    }
    return n + ' perioder';
  }

  private showDetails() {
    return this.displayOptions.includes('showDetails');
  }

  private years(): SelectDropdownOption[] {
    const startYear = 2018;
    const endYear = Number(LocalDate.now().toString().substring(0, 4));
    const options: any[] = [];
    for (let i = startYear; i <= endYear; i++) {
      options.push({
        value: i.toString(),
        text: i.toString(),
      });
    }
    return options;
  }

  private calculateDisplayOptions(o: string[]) {
    if (o.includes('showDetails')) {
      return [
        { value: 'showDetails', text: 'Vis detaljer' },
        { value: 'showPeriods', text: 'Vis perioder' },
      ];
    } else {
      return [{ value: 'showDetails', text: 'Vis detaljer' }];
    }
  }

  private onSelectedYearChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.selectedYear = e.detail.value;
  }

  private onDisplayOptionsChanged(e: CustomEvent<{ value: string[] }>) {
    e.stopPropagation();
    this.displayOptions = e.detail.value;
  }

  private renderYear(year: SicknessAbsenceStatsDataYear) {
    return html`${year.quarters.map((quarter) => this.renderQuarter(quarter))}`;
  }

  private renderQuarter(quarter: QuarterData) {
    return html` <div class="quarterHeader">
        <h2 class="quarterName">${quarter.quarterName}</h2>
        <div>
          <d-label label="Sykefravær totalt"></d-label>
          ${this.percentage(quarter.absenceDays, quarter.workDays)}
        </div>
        <div>
          <d-label label="Kvinner"></d-label>
          ${this.percentage(quarter.female.absenceDays, quarter.female.workDays)}
          <d-label label="Menn"></d-label>
          ${this.percentage(quarter.male.absenceDays, quarter.male.workDays)}
        </div>
      </div>

      ${this.renderQuarterDetails(quarter)}`;
  }

  private renderQuarterDetails(quarter: QuarterData) {
    return html` <d-expansion ?opened=${this.showDetails()}>
      <d-section class="quarterContent">
        <div class="gender">
          <div class="genderHeader">
            <div class="col-1 genderName">Kvinner</div>
            <div class="col-2">
              ${this.round(quarter.female.workDays)}
              <span class="hideOnNarrowScreen">&nbsp;avtalte</span>&nbsp;dagsverk
            </div>
          </div>
          <div class="genderHeader">
            <div class="col-1">
              <d-label label="Sykefravær"></d-label>
            </div>
            <div class="col-2">
              <div class="col-2-1">${this.roundDays(quarter.female.absenceDays)}</div>
              <div class="col-2-2">${this.periodsText(quarter.female.periodsCountTotal)}</div>
            </div>
          </div>
          ${this.renderPeriods(quarter.female.periods)}
        </div>

        <div class="gender">
          <div class="genderHeader">
            <div class="col-1 genderName">Menn</div>
            <div class="col-2">
              ${this.round(quarter.male.workDays)}
              <span class="hideOnNarrowScreen">&nbsp;avtalte</span>&nbsp;dagsverk
            </div>
          </div>
          <div class="genderHeader">
            <div class="col-1">
              <d-label label="Sykefravær"></d-label>
            </div>
            <div class="col-2">
              <div class="col-2-1">${this.roundDays(quarter.male.absenceDays)}</div>
              <div class="col-2-2">${this.periodsText(quarter.male.periodsCountTotal)}</div>
            </div>
          </div>
          ${this.renderPeriods(quarter.male.periods)}
        </div>
      </d-section>
    </d-expansion>`;
  }

  private renderMissingGenders() {
    const genderSelectorInfo = `<ul><li>Etter <i>Forskrift om statistikk over sykefravær</i> skal
                                             statistikken fordeles på kjønn. Du må registrere kjønn for alt personale
                                             før statistikken kan beregnes.</li></ul>`;

    return html`<div class="genderSelector">
      <div class="row borderRow spaceBetween">
        <d-view-info info-list content="${genderSelectorInfo}"></d-view-info>
      </div>
      ${this.employees.map(
        (employee) =>
          html` <div>
            <div class="employeeName">${employee.name}</div>
            <d-select-tag
              @value-changed=${(e: CustomEvent) => this.setGender(e, employee.uuid)}
              .value="${employee.gender === EmployeeViewModelGenderEnum.Undefined ? [] : [employee.gender]}"
              .options=${this.genderOptions}
            ></d-select-tag>
          </div>`,
      )}
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-sickness-absence-statistics': DSicknessAbsenceStatistics;
  }
}
