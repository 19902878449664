import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-edit-data-item-application.js';
import './d-edit-data-item-asset.js';
import '../../../../library/editors/elements/d-add-remove-buttons.js';
import type {
  InfosecDataType,
  InfosecCategory,
  InfosecDataItem,
  InfosecStorageUnit,
  InfosecAsset,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { changeOrAddDataItem } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';

/**
 * Given a data type and a category, renders filtered the data items. The data items are rendered using
 * different elements based on the category. This element does not handle the 'accounting' or 'emailAccounts' categories.
 *
 * The element also handles add / remove options for the data items
 *
 * @fires data-items-removed
 *
 * The other events are fired by the individual editing elements.
 *
 *
 */
@customElement('d-edit-data-items')
export class DEditDataItems extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }
      div {
        display: flex;
      }
      div + div {
        margin-top: 12px;
      }
      d-edit-data-item-application,
      d-edit-data-item-asset {
        flex: 1;
        display: block;
        box-sizing: border-box;
        border: 1px solid var(--borderColorOnGray);
        border-radius: 12px;
        background-color: var(--map-element-background-color);
        padding: 8px 12px 4px 12px;
      }
      d-edit-data-item-application + d-edit-data-item-application,
      d-edit-data-item-asset + d-edit-data-item-asset {
        margin-top: 12px;
      }
      d-edit-data-item-asset,
      d-edit-data-item-computer,
      d-edit-data-item-remote-server,
      d-edit-data-item-location {
        flex: 1;
      }
    `,
  ];
  @property({ type: Object })
  dataType!: InfosecDataType;
  @property({ type: Object })
  category!: InfosecCategory;
  @property({ type: Array })
  dataItems: InfosecDataItem[] = [];
  @property({ type: Array })
  cloudServices: InfosecStorageUnit[] = [];
  @property({ type: Array })
  remoteServers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: Array })
  assets: InfosecAsset[] = [];

  private get dataItemsForCategory(): InfosecDataItem[] {
    const dataItems: InfosecDataItem[] = this.dataItems.filter((m) => {
      return m.dataType === this.dataType.type && m.category === this.category.category;
    });
    if (dataItems.length === 0) {
      dataItems.push(this.newDataItem);
    }
    return dataItems;
  }

  private get newDataItem(): InfosecDataItem {
    return {
      uuid: '',
      name: '',
      dataType: this.dataType.type,
      category: this.category.category,
      personalData: false,
      dataProcessor: '',
      application: '',
      storageUnit: '',
      storageUnitType: '',
      storageLocation: '',
      accessingEmployees: [],
      accessingPartners: [],
      assets: [],
      backupFrequency: '',
      backupResponsible: '',
      noBackupReason: '',
      createdDateTime: '',
    };
  }

  _update(property) {
    this[property] = JSON.parse(JSON.stringify(this[property]));
  }

  _assetsChanged(detail) {
    this.assets = detail;
  }

  _remoteServersChanged(detail) {
    this.remoteServers = detail;
  }

  _partnersChanged(detail) {
    this.partners = detail;
  }

  _dataItemChanged(detail) {
    this.dataItems = changeOrAddDataItem(this.dataItems, detail);
    console.log('_dataItemChanged dataItems after', this.dataItems);
    // this._dispatchDataItemsChanged();
  }

  _dispatchDataItemsChanged() {
    this.dispatchEvent(
      new CustomEvent('data-items-changed', {
        bubbles: true,
        composed: true,
        detail: this.dataItems,
      }),
    );
  }

  _removeDataItem(index) {
    const infosecDataItem = this.dataItems[index];
    const uuid = infosecDataItem.uuid;
    this.dataItems.splice(index, 1);
    this._update('dataItems');
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; dataType: string; category: string }>('data-items-removed', {
        bubbles: true,
        composed: true,
        detail: { uuid, dataType: infosecDataItem.dataType, category: infosecDataItem.category },
      }),
    );
  }

  _addDataItem() {
    this.dataItems.push(this.newDataItem);
    this._update('dataItems');
  }

  render() {
    if (this.dataItems.length === 0) {
      this._addDataItem();
    }
    return html`
      ${this.dataItemsForCategory.map((dataItem, index) => {
        return html`
          <div>
            ${this.category.category === 'assetData'
              ? html`
                  <d-edit-data-item-asset
                    light-label
                    small-label
                    .dataItem=${dataItem}
                    .dataType=${this.dataType}
                    .category=${this.category}
                    .assets=${this.assets}
                    .computers=${this.computers}
                    @data-item-updated=${(e) => this._dataItemChanged(e.detail)}
                  ></d-edit-data-item-asset>
                `
              : nothing}
            <d-add-remove-buttons
              ?showRemove=${dataItem.uuid !== ''}
              ?showAdd=${dataItem.uuid !== '' && index === this.dataItemsForCategory.length - 1}
              @remove=${() => this._removeDataItem(index)}
              @add=${() => this._addDataItem()}
            ></d-add-remove-buttons>
          </div>
        `;
      })}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-data-items': DEditDataItems;
  }
}
