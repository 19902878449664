import { css, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import '../editors/elements/d-edit-text.js';
import '../editors/elements/d-select-dropdown.js';
import '../elements/d-wrap.js';
import '../elements/d-section.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { commonFieldsByCode } from 'src/store/fields.js';
import { classMap } from 'lit/directives/class-map.js';

export interface ItemTableColumn {
  name: string;
  field: string;
  label: string;
  sortOption?: boolean;
  groupOption?: boolean;
  noValue?: string;
  hide?: boolean;
}

export interface TableItem {
  target?: string;
  href: string;
  hasDraft: boolean;
}

interface TableGroup<T extends TableItem> {
  groupLabel: string;
  items: T[];
  sortValue: any;
  groupLabelDisplay: string;
}

/**
 * Base class for item tables. Specialized implementations are needed for each type.
 *
 * Note that the rows are implemented as links for the whole row. This may have usability issues.
 * See https://robertcooper.me/post/table-row-links
 *
 *
 * @fires item-click
 */
export abstract class ItemTable<T extends TableItem> extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: flex;
        justify-content: center;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        line-height: 120%;
      }

      thead {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
      }

      :host([theme-page]) thead {
        background-color: var(--backgroundGray);
      }

      /* No sticky header in outskirts because background color cannot be determined */
      :host([outskirts]) thead {
        position: relative;
        background-color: transparent;
      }

      th d-label {
        display: block;
        padding: 8px 12px 8px 0;
        border-bottom: 1px solid var(--borderColorOnGray);
      }

      td {
        padding: 8px 12px 8px 0;
      }

      th,
      td {
        color: var(--linkColorGray);
        text-align: left;
        word-break: break-word;
      }

      th {
        vertical-align: bottom;
      }

      td {
        border-bottom: 1px solid var(--borderColorOnGray);
        vertical-align: top;
        cursor: pointer;
      }

      tr.subitem:last-child td {
        border-bottom: none;
      }

      td.draft:first-child {
        background: url(/images/draft.svg) -5px 6px no-repeat;
        background-size: 24px 24px;
        padding-left: 23px;
      }

      tr.group-label th {
        padding: 16px 0 0 0;
      }

      tr.item:hover {
        background-color: hsla(0, 0%, 100%, 0.2);
      }

      tr.item:hover td {
        color: black;
      }

      td table {
        width: calc(100% + 12px);
        margin: -9px -12px -9px 0;
      }

      td a {
        color: inherit;
        text-decoration: none;
        mix-blend-mode: multiply;
      }

      td a:hover {
        color: var(--themeColor);
        text-decoration: underline;
      }

      .property {
        display: inline-block;
        margin: 4px 8px 0 0;
      }

      .no-value {
        font-style: italic;
        opacity: 0.5;
      }

      tr.item:hover td .no-value {
        color: var(--linkColorGray);
      }

      tr.item > td > d-label {
        display: none;
      }

      @media only screen and (max-width: 700px) {
        :host > table {
          display: block;
        }

        :host > table > tr {
          display: block;
          border-top: 1px solid var(--borderColorOnGray);
          border-bottom: 1px solid var(--borderColorOnGray);
          margin-top: -1px;
          padding: 8px 12px 8px 0;
        }

        tr.group-label th {
          width: var(--appWidth);
        }

        .column-labels {
          display: none;
        }

        td {
          display: block;
          border: none;
        }

        :host > table > tr > td {
          padding: 3px 0;
        }

        tr.item > td > d-label {
          display: inline-block;
          margin-right: 8px;
        }

        td table {
          margin: 0;
        }

        td table td {
          padding-bottom: 0;
        }
      }

      .alert td,
      .overdue a {
        color: var(--alertColorDarkerOne);
      }

      tr.item:hover .alert td {
        color: var(--alertColorDarkerOne);
      }
    `,
  ];
  @property({ type: Boolean })
  filter = false;
  @property({ type: String, attribute: 'sort-by' })
  sortBy = '';
  @property({ type: String })
  filterBy = '';
  @property({ type: Boolean })
  sortItemsDescending = false;
  @property({ type: String, attribute: 'group-by' })
  groupBy = '';
  @property({ type: Boolean, attribute: 'theme-page' })
  themePage = true;
  @property({ type: Boolean, attribute: 'outskirts' })
  outskirts = false;
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: Array })
  items: T[] = [];
  protected abstract columns: ItemTableColumn[];
  private fieldsByCode = commonFieldsByCode();

  protected get sortGroupsDescending(): boolean {
    return false;
  }

  private get sortOptions() {
    return this.columns
      .filter((c) => {
        return c.sortOption === true;
      })
      .map((c) => {
        return { value: c.name, text: 'sortert etter ' + c.label.toLocaleLowerCase() };
      });
  }

  private get groupOptions() {
    const result = [{ value: '', text: 'ikke gruppert' }];
    this.columns.forEach((c) => {
      if (c.groupOption) {
        result.push({ value: c.name, text: 'gruppert etter ' + c.label.toLocaleLowerCase() });
      }
    });
    return result;
  }

  filteredColumns(): ItemTableColumn[] {
    return this.columns.filter((c) => {
      return !c.hide && c.name !== this.groupBy;
    });
  }

  getFieldLabel(name) {
    const column = this.columns.find((c) => c.name === name);
    if (column) {
      return column.label;
    }
    return '';
  }

  getGroups(): TableGroup<T>[] {
    const items = this.items;
    const groups: TableGroup<T>[] = [];
    if (items.length) {
      if (this.sortBy) {
        let sortField = this.sortBy;
        if (Object.prototype.hasOwnProperty.call(items[0], this.sortBy + 'SortValue')) {
          sortField = this.sortBy + 'SortValue';
        }
        if (this.sortItemsDescending) {
          items.sort((b, a) => +!a[sortField] - +!b[sortField] || a[sortField].localeCompare(b[sortField]));
        } else {
          items.sort((a, b) => +!a[sortField] - +!b[sortField] || a[sortField].localeCompare(b[sortField]));
        }
      }
      if (this.groupBy) {
        items.forEach((item) => {
          let groupExists = false;
          groups.forEach((group) => {
            if (group.groupLabel === item[this.groupBy]) {
              groupExists = true;
              group.items.push(item);
            }
          });
          if (!groupExists) {
            let groupLabelDisplay = item[this.groupBy];
            if (!item[this.groupBy]) {
              groupLabelDisplay = this.getFieldLabel(this.groupBy) + ' ikke angitt';
            }
            let sortValue = item[this.groupBy];
            if (Object.prototype.hasOwnProperty.call(item, this.groupBy + 'SortValue')) {
              sortValue = item[this.groupBy + 'SortValue'];
            }
            if (!sortValue) {
              sortValue = 'å';
            }
            groups.push({
              groupLabel: item[this.groupBy],
              groupLabelDisplay: groupLabelDisplay,
              sortValue: sortValue,
              items: [item],
            });
          }
        });
        if (this.sortGroupsDescending) {
          groups.sort((b, a) => +!a.sortValue - +!b.sortValue || a.sortValue.localeCompare(b.sortValue));
        } else {
          groups.sort((a, b) => +!a.sortValue - +!b.sortValue || a.sortValue.localeCompare(b.sortValue));
        }
        return groups;
      } else {
        const groupItems: T[] = [];
        items.forEach((i) => {
          groupItems.push(i);
        });
        return [{ groupLabel: '', groupLabelDisplay: '', sortValue: '', items: groupItems }];
      }
    }
    return [];
  }

  renderGroupHeader(groups, group, columns) {
    const theadStyles = { top: this.contentStickyTop - 1 + 'px' };
    return html`
      <thead style=${styleMap(theadStyles)}>
        ${this.groupBy
          ? html`
              <tr class="group-label">
                <th data-field="${this.groupBy}" data-value="${group.groupLabel}" colspan="${this.columns.length}">
                  <d-label big allow-wrap label=${group.groupLabelDisplay}></d-label>
                </th>
              </tr>
            `
          : nothing}
        <tr class="column-labels">
          ${columns.map((column) => {
            const label = (column.label || this.fieldsByCode[column.field]?.label) ?? '';
            const tooltip = this.fieldsByCode[column.field]?.tooltip ?? '';
            return html`
              <th>
                <d-label allow-wrap .tooltip=${tooltip} .label=${label}></d-label>
              </th>
            `;
          })}
        </tr>
      </thead>
    `;
  }

  isText(data: any): data is string {
    return typeof data === 'string';
  }

  renderSubItems(itemName, subitems) {
    return html`
      <table>
        ${subitems.map((subitem) => {
          const value = this.isText(subitem) ? subitem : subitem.name;
          return html`
            <tr class="click-elm subitem ${subitem.classes}" href="${subitem.href}" target="${subitem.target}">
              <td data-field="${itemName}" data-value="${value}">
                ${subitem.href !== '' ? html`<a href="${subitem.href}">${subitem.name}</a>` : html`${subitem.name}`}
                <div>
                  ${!subitem.properties
                    ? nothing
                    : html`
                        ${subitem.properties.map(
                          (property) => html`
                            <div class="${property.classes}">
                              <d-label semibold .field=${property.field} .label=${property.label}></d-label>
                              ${property.href !== ''
                                ? html`<a class="click-elm property" href="${property.href}" target="${property.target}"
                                    >${property.value}</a
                                  >`
                                : html`<span class="click-elm property">${property.value}</span>`}
                            </div>
                          `,
                        )}
                      `}
                </div>
              </td>
            </tr>
          `;
        })}
      </table>
    `;
  }

  renderRow(item: T, columns: ItemTableColumn[]) {
    const tdClasses = {
      draft: item.hasDraft,
    };
    const fields: any[] = [];
    columns.forEach((c) => {
      let value = item[c.name];
      if (typeof item[c.name] === 'object') {
        const subitems = item[c.name];
        if (subitems.length) {
          value = this.renderSubItems(c.name, subitems);
        } else {
          value = html`<span class="no-value">${c.noValue}</span>`;
        }
      } else if (typeof item[c.name] === 'boolean') {
        value = 'Nei';
        if (item[c.name]) {
          value = 'Ja';
        }
      }
      fields.push({ name: c.name, field: c.field, label: c.label, value: value, hasDraft: item.hasDraft });
    });
    return html`
      <tr
        class="click-elm item"
        href="${item.href}"
        target="${ifDefined(item.target)}"
        @click=${(e) => this.rowClick(item.href, e)}
      >
        ${fields.map(
          (field) => html`
            <td data-field="${field.name}" data-value="${field.value}" class="${classMap(tdClasses)}">
              ${!field.label ? nothing : html` <d-label .label=${field.label} .field=${field.field}></d-label> `}
              ${field.value}
            </td>
          `,
        )}
      </tr>
    `;
  }

  render() {
    return html`
      ${this.renderHeader()}
      <table>
        ${this.renderContent()}
      </table>
    `;
  }

  abstract includeItem(item: T): boolean;

  filterItem(item: T): boolean {
    return this.filter && this.filterBy.trim() !== '' ? this.includeItem(item) : true;
  }

  protected filterFields(...args: (string | undefined)[]) {
    return args.filter((a) => this.filterField(a)).length > 0;
  }

  protected renderContent() {
    const groups = this.getGroups();
    const columns = this.filteredColumns();
    return html`
      ${groups.map(
        (group) => html`
          ${this.renderGroupHeader(groups, group, columns)}
          ${group.items.filter((item) => this.filterItem(item)).map((item) => html` ${this.renderRow(item, columns)} `)}
        `,
      )}
    `;
  }

  protected rowClick(href: string, e: MouseEvent) {
    if (e.target === null) {
      return;
    }
    const target = e.target as HTMLElement;
    const anchor = target.closest('a');
    if (anchor !== null) {
      return;
    }

    const proceed = this.dispatchEvent(
      new CustomEvent('row-clicked', { bubbles: true, composed: true, detail: { href: href } }),
    );

    if (href && proceed) {
      if (href.startsWith('https://www.skilnet.no')) {
        window.open(href, 'skil');
      } else {
        this.dispatchEvent(new CustomEvent('navigate', { bubbles: true, composed: true, detail: { href: href } }));
      }
    }
  }

  private renderHeader() {
    if (this.sortOptions.length || this.groupOptions.length > 1 || this.filter) {
      return html`
        <d-section>
          <d-wrap>
            ${this.sortOptions.length === 0
              ? nothing
              : html`
                  <d-select-dropdown
                    controller
                    .options=${this.sortOptions}
                    .value=${this.sortBy}
                    @value-changed=${(e) => {
                      this.sortBy = e.detail.value;
                    }}
                  ></d-select-dropdown>
                `}
            ${this.groupOptions.length < 2
              ? nothing
              : html`
                  <d-select-dropdown
                    controller
                    .options=${this.groupOptions}
                    .value=${this.groupBy}
                    @value-changed=${(e) => {
                      this.groupBy = e.detail.value;
                    }}
                  ></d-select-dropdown>
                `}
            ${this.filter
              ? html`
                  <d-edit-text
                    value=""
                    id="filter"
                    placeholder="Søk"
                    type="search"
                    ?theme-page=${this.themePage}
                    @value-changed=${(e) => {
                      this.filterBy = e.detail.value;
                    }}
                  ></d-edit-text>
                `
              : nothing}
          </d-wrap>
        </d-section>
      `;
    }
    return nothing;
  }

  private filterField(t: string | undefined): boolean {
    return (t ?? '').toLocaleLowerCase().includes(this.filterBy.trim().toLocaleLowerCase());
  }
}
