import { css, html, nothing, PropertyValues } from 'lit';

import { customElement, property, query } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { iconStyles } from 'src/library/icon-styles.js';
import { entities } from 'src/models/search.js';
import '../../library/editors/elements/d-edit-text.js';
import '../../library/fields/d-closer.js';
import '../../library/fields/d-expansion.js';
import { headerViewStyles } from 'src/layout/parts/header-view-styles.js';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';

/**
 * The search result type.
 *
 */
export interface SearchResult {
  entity: string;
  target: '_blank' | '_self';
  party: string;
  description: string;
  id: string;
  href: string;
}

/**
 *
 *
 */
@customElement('d-organization-search')
export class DOrganizationSearch extends ResponsiveElement {
  static readonly styles = [
    iconStyles,
    headerViewStyles,
    css`
      :host {
        display: block;
      }

      .wrapper {
        min-height: 50px;
      }

      :host(.width600) .wrapper {
        min-height: unset;
      }

      .closer {
        margin-right: -2px;
        background: url(/images/x-thin-black.svg) 50% 50% no-repeat;
        background-size: 12px 12px;
        width: 24px;
        height: 24px;
        opacity: 0.4;
        cursor: pointer;
      }

      .header {
        display: flex;
        position: relative;
        padding: 4px 34px 0 20px;
        margin-bottom: 16px;
        max-width: var(--metaContentMaxWidth);
      }

      d-edit-text {
        flex: 1;
      }

      .comment.noResults {
        padding: 0 10px 10px 38px;
        margin-top: -4px;
        font-family: var(--mainSans);
        font-weight: 200;
        line-height: 140%;
        font-style: italic;
      }

      .search-result-item {
        padding: 8px 0 7px 28px;
        background-size: 24px 24px;
        background-position: 0 7px;
        background-repeat: no-repeat;
        text-decoration: none;
        display: block;
        margin: 0 10px;
        font-size: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        color: black;
        opacity: 0.7;
      }

      body:not(.touch) .search-result-item:hover,
      .search-result-item:active {
        opacity: 1;
      }

      .listWrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
        margin-top: 10px;
        margin-bottom: 6px;
      }

      .action {
        color: white;
        font-weight: 500;
        padding: 12px 10px 14px 38px;
        font-family: var(--small);
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
      }

      .meta-content-wrapper {
        max-height: calc((var(--vh, 1vh) * 100) - var(--applicationHeaderHeight) - 300px);
        overflow-y: auto;
      }

      @media only screen and (max-height: 600px) {
        .meta-content-wrapper {
          max-height: calc((var(--vh, 1vh) * 100) - var(--applicationHeaderHeight) - 180px);
        }
      }
    `,
  ];
  @property({ type: Boolean, reflect: true })
  open = false;
  @query('#searchBox')
  searchBox!: HTMLInputElement;
  /**
   * The search query
   */
  @property({ type: String })
  query = '';
  /**
   * Number of results to display.
   */
  @property({ type: Number })
  limit = 10;
  /**
   * The search results to display
   */
  @property({ type: Array })
  results: SearchResult[] = [];

  private get items(): SearchResult[] {
    return this.results.slice(0, this.limit);
  }

  _close() {
    this.dispatchEvent(new CustomEvent('close-search'));
  }
  fireQueryValueChanged(e: CustomEvent<{ value: string }>) {
    this.dispatchEvent(
      new CustomEvent<{ query: string }>('search-query-changed', {
        bubbles: true,
        composed: true,
        detail: { query: e.detail.value },
      }),
    );
  }

  render() {
    return html`
      <d-expansion ?opened=${this.open}>
        <div class="wrapper">
          <div class="header">
            <d-edit-text
              type="search"
              outskirts
              id="searchBox"
              placeholder="Søk i alt innhold i TrinnVis"
              .value="${this.query}"
              @value-changed=${this.fireQueryValueChanged}
            ></d-edit-text>
            <d-closer color="white" @click=${() => this._close()}></d-closer>
          </div>
          ${!this.items.length
            ? nothing
            : html`<div class="meta-content-wrapper">
                ${this.items.map(
                  (result) =>
                    html` <a
                      class="search-result-item ${entities[result.entity]}"
                      href="${this._href(result)}"
                      target="${ifDefined(result.target === '_self' ? undefined : result.target)}"
                      data-id="${result.id}"
                      @click=${(e: Event) => this._setStartTask(e, result)}
                    >
                      ${result.description} ${result.party}
                    </a>`,
                )}
                ${this._showingAll(this.results.length, this.limit)
                  ? nothing
                  : html`<div class="action" @click=${this._extendLimit}>Vis flere</div>`}
              </div>`}
          ${this._shouldHaveResultsOrNoQuery(this.items.length, this.query)
            ? nothing
            : html`<div class="comment noResults">...</div>`}
        </div>
      </d-expansion>
    `;
  }

  _setStartTask(e: Event, result: SearchResult) {
    if (result.entity === 'StartTask') {
      e.preventDefault();
      this.dispatchEvent(
        new CustomEvent<{ uuid: string }>('start-task-selected', {
          bubbles: true,
          composed: true,
          detail: { uuid: result.id },
        }),
      );
    }
  }

  _shouldHaveResultsOrNoQuery(length: number, query: string) {
    const q = query ? query.trim() : '';
    return length > 0 || q === '' || (q && q.length < 3);
  }

  _openChange() {
    if (this.searchBox) this.searchBox.focus();
  }

  _extendLimit() {
    this.limit = 999;
  }

  _showingAll(resultsLength, limit) {
    return resultsLength === 0 || resultsLength < limit;
  }

  _href(result) {
    if (result.entity === 'StartTask') {
      return '';
    }
    return result.href;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    this.dispatchEvent(
      new CustomEvent<{ query: string }>('search-updated', {
        bubbles: true,
        composed: true,
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-search': DOrganizationSearch;
  }
}
