import { css, html, LitElement, nothing } from 'lit';
import './d-login.js';
import { customElement, property } from 'lit/decorators.js';

/**
 * Legger til logo for SKIL dersom dette er en link derfra.
 *
 * @fires request-login submit login request. (fra d-login)
 * @fires request-password submit password request (fra d-login)
 *
 * STATUS OK
 */
@customElement('d-signin')
export class DSignin extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      background: var(--outskirts-background-color);
    }

    .referral {
      background: white;
      text-align: center;
      padding: 20px;
    }

    .referral a.logo {
      display: inline-block;
      color: var(--linkColorGray);
      text-decoration: none;
      cursor: pointer;
    }

    .referral a.logo.skil {
      width: 140px;
      height: 100px;
      background: url(/images/partners/skil.svg) 50% 50% no-repeat;
      background-size: contain;
    }
  `;
  @property({ type: String })
  referral = '';
  /**
   * Vis feilmelding for innlogging feilet.
   */
  @property({ type: Boolean })
  loginFailed = false;
  /**
   * The mode. Use ??? for linking ssecure login methods
   */
  @property({ type: String })
  mode = '';

  /**
   * The location search. Set this to value of `window.location.search` when mode is `link`.
   */
  @property({ type: String })
  locationSearch = '';

  render() {
    return html`
      ${this.referral === 'skil'
        ? html`
            <div class="referral">
              <a class="logo skil" href="https://skilnet.no" rel="noopener noreferrer" target="_blank"></a>
            </div>
          `
        : nothing}
      <d-login .loginFailed=${this.loginFailed} .mode=${this.mode} .locationSearch=${this.locationSearch}
        ><slot slot="usernameInput" name="usernameInput"></slot> <slot slot="passwordInput" name="passwordInput"></slot
      ></d-login>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-signin': DSignin;
  }
}
