/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminSearchOrganizationCommandFilter } from './AdminSearchOrganizationCommandFilter';
import {
  AdminSearchOrganizationCommandFilterFromJSON,
  AdminSearchOrganizationCommandFilterFromJSONTyped,
  AdminSearchOrganizationCommandFilterToJSON,
} from './AdminSearchOrganizationCommandFilter';

/**
 *
 * @export
 * @interface AdminSearchOrganizationCommand
 */
export interface AdminSearchOrganizationCommand {
  /**
   *
   * @type {string}
   * @memberof AdminSearchOrganizationCommand
   */
  query?: string;
  /**
   *
   * @type {number}
   * @memberof AdminSearchOrganizationCommand
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof AdminSearchOrganizationCommand
   */
  from?: number;
  /**
   * List of filters
   * @type {Array<AdminSearchOrganizationCommandFilter>}
   * @memberof AdminSearchOrganizationCommand
   */
  filters?: Array<AdminSearchOrganizationCommandFilter>;
  /**
   * Indicators to include
   * @type {Array<string>}
   * @memberof AdminSearchOrganizationCommand
   */
  included?: Array<string>;
  /**
   * Indicators to exclude
   * @type {Array<string>}
   * @memberof AdminSearchOrganizationCommand
   */
  excluded?: Array<string>;
}

/**
 * Check if a given object implements the AdminSearchOrganizationCommand interface.
 */
export function instanceOfAdminSearchOrganizationCommand(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AdminSearchOrganizationCommandFromJSON(json: any): AdminSearchOrganizationCommand {
  return AdminSearchOrganizationCommandFromJSONTyped(json, false);
}

export function AdminSearchOrganizationCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AdminSearchOrganizationCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    query: !exists(json, 'query') ? undefined : json['query'],
    size: !exists(json, 'size') ? undefined : json['size'],
    from: !exists(json, 'from') ? undefined : json['from'],
    filters: !exists(json, 'filters')
      ? undefined
      : (json['filters'] as Array<any>).map(AdminSearchOrganizationCommandFilterFromJSON),
    included: !exists(json, 'included') ? undefined : json['included'],
    excluded: !exists(json, 'excluded') ? undefined : json['excluded'],
  };
}

export function AdminSearchOrganizationCommandToJSON(value?: AdminSearchOrganizationCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    query: value.query,
    size: value.size,
    from: value.from,
    filters:
      value.filters === undefined
        ? undefined
        : (value.filters as Array<any>).map(AdminSearchOrganizationCommandFilterToJSON),
    included: value.included,
    excluded: value.excluded,
  };
}
