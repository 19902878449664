import { html, nothing } from 'lit';

import { customElement } from 'lit/decorators.js';
import 'src/content/entity-content';
import type { SelectCheckboxOption } from 'src/library/editors/elements/d-select-checkbox.js';
import '../../library/editors/index.js';
import '../../library/editors/elements/d-select-checkbox.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import '../../library/fields/index.js';
import '../../library/fields/d-expansion.js';
import '../../library/fields/d-view-html.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../pages/computers-page/d-edit-single-related-item.js';
import { DataMapElement, DataMapItem } from './data-map-element.js';
import './d-view-checklist.js';
import './d-view-checkpoint.js';
import './d-view-related-items.js';
import type { DataItem } from 'src/pages/computers-page/d-data-item-view.js';

export interface ComputerMapLocal extends DataMapItem {
  networks: { uuid: string; name: string }[];
  name: string;
  mobileMedicalDataRequirementsOptions: SelectCheckboxOption[];
  partners: SelectDropdownOption[];
  antiVirusRequirementsOptions: SelectCheckboxOption[];
  systemUpdateOperatorType: string;
  systemUpdateOperator: string;
  antiVirusOperator: string;
  antiVirusOperatorType: string;
  locked: boolean;
  antiVirusRequirements: string[];
  location: string;
  elevated: boolean;
  restrictedPhysicalAccess: boolean;
  networkUuid: string;
  mobileMedicalDataRequirements: string[];
  notes: string;
  displayPositionSecure: boolean;
  unitType: 'computer';
  type: string;
  displayName: string;
  attachments: string[];
  hasMedicalData: boolean;
  computerIsInNetworkWithMedicalData: boolean;
  personalDataItems: DataItem[];
  deletable: boolean;
}

export interface LocalEditItem {
  name: string;
  systemUpdateOperatorType: string;
  systemUpdateOperator: string;
  antiVirusOperator: string;
  antiVirusOperatorType: string;
  locked: boolean;
  antiVirusRequirements: string[];
  location: string;
  elevated: boolean;
  restrictedPhysicalAccess: boolean;
  networkUuid: string;
  mobileMedicalDataRequirements: string[];
  notes: string;
  displayPositionSecure: boolean;
  type: string;
}

/**
 *
 */
@customElement('d-computer-unit-local')
export class DComputerUnitLocal extends DataMapElement<ComputerMapLocal, LocalEditItem> {
  static readonly styles = [...DataMapElement.styles];
  printerPositionSecureOptions = [
    {
      value: 'true',
      text: 'Skriveren er plassert slik at uvedkommende ikke kan få tilgang til utskrifter',
    },
  ];

  private localUnitTypes: SelectDropdownOption[] = [
    { value: 'UNDEFINED', text: 'Velg type' },
    { value: 'Server', text: 'Server' },
    { value: 'Arbeidsstasjon', text: 'Arbeidsstasjon/klient' },
    { value: 'mobile', text: 'Bærbar enhet' },
  ];

  _displayTypeName(): string {
    if (this.item.type === 'Server') {
      return 'Server';
    }
    if (this.item.type === 'Arbeidsstasjon') {
      return 'Arbeidsstasjon/klient';
    }
    if (this.item.type === 'mobile') {
      return 'Bærbar enhet';
    }
    return 'Lokal enhet';
  }

  _displayName() {
    return this.item.name;
  }

  _operatorTypes(networkConnection) {
    const list = [
      { value: 'UNDEFINED', text: 'Velg driftsmåte' },
      { value: 'NONE', text: 'Ingen' },
    ];
    if (networkConnection !== 'NONE' && networkConnection !== '') {
      list.push({ value: 'INHERIT', text: 'Samme som for nettverket' });
    }
    list.push(
      { value: 'internal', text: 'Driftes internt' },
      { value: 'external', text: 'Driftes av ekstern leverandør' },
      {
        value: 'internalAndExternal',
        text: 'Driftes både internt og eksternt',
      },
    );
    return list;
  }

  _getOperatorTypeValue(id) {
    let result = '';
    const list = this._operatorTypes(this.item.networkUuid);
    for (const item of list) {
      if (id === item.value) {
        result = item.text;
      }
    }

    if (result === 'Velg driftsmåte') {
      result = 'Ikke valgt';
    }

    return result;
  }

  _isValid(): boolean {
    return true;
  }

  _operatorIsExternal(operatorType) {
    return operatorType === 'external' || operatorType === 'internalAndExternal';
  }
  renderUnitView() {
    return html`<section>
      <d-section>
        <d-view-text
          field="localUnits_systemUpdateOperatorType"
          value="${this._getOperatorTypeValue(this.item.systemUpdateOperatorType)}"
        >
        </d-view-text>
        <d-view-text
          field="localUnits_systemUpdateOperator"
          value="${this._getName(this.item.partners, this.item.systemUpdateOperator)}"
        >
        </d-view-text>
      </d-section>
      <d-section>
        <d-view-text
          field="localUnits_antiVirusOperatorType"
          value="${this._getOperatorTypeValue(this.item.antiVirusOperatorType)}"
        >
        </d-view-text>
        <d-view-text
          field="localUnits_antiVirusOperator"
          value="${this._getName(this.item.partners, this.item.antiVirusOperator)}"
        >
        </d-view-text>
      </d-section>

      ${!this.item.computerIsInNetworkWithMedicalData
        ? nothing
        : html`<d-section>
            <d-view-checklist
              .checked="${this.item.antiVirusRequirements}"
              entity="localUnits"
              field="localUnits_antiVirusRequirements"
              .options="${this.item.antiVirusRequirementsOptions}"
            >
            </d-view-checklist>
          </d-section>`}
      ${this.item.type === 'mobile'
        ? nothing
        : html`<d-section>
            <d-view-text field="localUnits_location" value="${this.item.location}"> </d-view-text>
          </d-section>`}
      ${!this.item.computerIsInNetworkWithMedicalData
        ? nothing
        : html`<d-section>
            <d-view-checklist
              .checked=${[]}
              entity="localUnits"
              field="localUnits_physicalSecurityRequirements"
              .options=${[]}
            >
            </d-view-checklist>
            ${!this.item.hasMedicalData
              ? nothing
              : html`<d-view-checkpoint
                  ?checked="${this.item.restrictedPhysicalAccess}"
                  field="localUnits_restrictedPhysicalAccess"
                >
                </d-view-checkpoint>`}
            ${!this.item.hasMedicalData
              ? nothing
              : html`<d-view-checkpoint ?checked="${this.item.elevated}" field="localUnits_elevated">
                </d-view-checkpoint>`}
            ${!this.item.hasMedicalData
              ? nothing
              : html`<d-view-checkpoint ?checked="${this.item.locked}" field="localUnits_locked"> </d-view-checkpoint>`}
            ${!this.item.computerIsInNetworkWithMedicalData
              ? nothing
              : html`
                  ${this.item.type !== 'Arbeidsstasjon'
                    ? nothing
                    : html`<d-view-checkpoint
                        ?checked="${this.item.displayPositionSecure}"
                        field="localUnits_displayPositionSecure"
                      >
                      </d-view-checkpoint>`}
                `}
          </d-section>`}

      <d-expansion ?opened=${this.item.hasMedicalData}>
        ${this.item.type !== 'mobile'
          ? nothing
          : html`<d-section>
              <d-view-checklist
                .checked="${this.item.mobileMedicalDataRequirements}"
                entity="localUnits"
                field="localUnits_mobileMedicalDataRequirements"
                .options="${this.item.mobileMedicalDataRequirementsOptions}"
              >
              </d-view-checklist>
            </d-section>`}
      </d-expansion>

      ${!this.item.notes
        ? nothing
        : html`<d-section>
            <d-view-html field="localUnits_notes" value="${this.item.notes}"> </d-view-html>
          </d-section>`}
      <d-list-section-attachment
        .attachments="${this.item.attachments}"
        class="computers"
        label="Vedlegg"
        page-id="20"
        sort="1"
        .writeAccess=${this.writeAccess}
        theme-page
      >
      </d-list-section-attachment>
    </section>`;
  }

  renderUnitEdit(editItem: LocalEditItem) {
    return html`
      <d-section>
        <d-select-dropdown
          theme-page
          field="localUnits_type"
          .options=${this.localUnitTypes}
          .value=${editItem.type}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              type: e.detail.value,
            };
          }}
        >
        </d-select-dropdown>
        <d-edit-text
          theme-page
          field="localUnits_name"
          .value=${editItem.name}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              name: e.detail.value,
            };
          }}
        >
        </d-edit-text>
      </d-section>
      <d-section>
        <d-select-dropdown
          theme-page
          field="localUnits_networkUuid"
          .options=${this._networkConnections(this.item.networks)}
          .value=${editItem.networkUuid}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              networkUuid: e.detail.value,
            };
            this.setOperatorTypeDefaultValues(e.detail.value);
          }}
        >
        </d-select-dropdown>
      </d-section>
      <d-section>
        <d-select-dropdown
          theme-page
          field="localUnits_systemUpdateOperatorType"
          .options=${this._operatorTypes(this.item.networkUuid)}
          .value=${editItem.systemUpdateOperatorType}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              systemUpdateOperatorType: e.detail.value,
            };
          }}
        >
        </d-select-dropdown>
      </d-section>
      <d-expansion ?opened="${this._operatorIsExternal(editItem.systemUpdateOperatorType)}">
        <d-section>
          <d-edit-single-related-item
            field="localUnits_systemUpdateOperator"
            .items=${this.item.partners}
            @new-item=${this.onNewPartner}
            placeholder="Velg leverandør"
            .relatedItem=${editItem.systemUpdateOperator}
            @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                systemUpdateOperator: e.detail.relatedItem,
              };
            }}
          >
          </d-edit-single-related-item>
        </d-section>
      </d-expansion>
      <d-section>
        <d-select-dropdown
          theme-page
          field="localUnits_antiVirusOperatorType"
          .options=${this._operatorTypes(this.item.networkUuid)}
          .value=${editItem.antiVirusOperatorType}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              antiVirusOperatorType: e.detail.value,
            };
          }}
        >
        </d-select-dropdown>
      </d-section>
      <d-expansion ?opened=${this._operatorIsExternal(editItem.antiVirusOperatorType)}>
        <d-section>
          <d-edit-single-related-item
            field="localUnits_antiVirusOperator"
            .items=${this.item.partners}
            @new-item=${this.onNewPartner}
            placeholder="Velg leverandør"
            .relatedItem=${editItem.antiVirusOperator}
            @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                antiVirusOperator: e.detail.relatedItem,
              };
            }}
          >
          </d-edit-single-related-item>
        </d-section>
      </d-expansion>
      <d-section>
        <d-select-checkbox
          theme-page
          deselectable
          vertical
          wrap-label
          field="localUnits_antiVirusRequirements"
          option-direction="vertical"
          .options=${this.item.antiVirusRequirementsOptions}
          .value=${editItem.antiVirusRequirements}
          @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              antiVirusRequirements: e.detail.value,
            };
          }}
        >
        </d-select-checkbox>
      </d-section>
      <d-expansion ?opened=${editItem.type !== 'mobile'}>
        <d-section>
          <d-edit-text
            theme-page
            field="localUnits_location"
            placeholder="Beskriv hvor maskinen er plassert"
            value="${editItem.location}"
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                location: e.detail.value,
              };
            }}
          >
          </d-edit-text>
        </d-section>
      </d-expansion>

      <d-expansion ?opened=${this.item.hasMedicalData}>
        <d-section>
          <d-checkbox
            theme-page
            wrap-label
            ?checked="${editItem.restrictedPhysicalAccess}"
            option-field="localUnits_restrictedPhysicalAccess"
            @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                restrictedPhysicalAccess: e.detail.checked,
              };
            }}
          >
          </d-checkbox>
        </d-section>

        <d-expansion ?opened=${editItem.type !== 'mobile'}>
          <d-section>
            <d-checkbox
              theme-page
              wrap-label
              ?checked=${editItem.elevated}
              option-field="localUnits_elevated"
              @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  elevated: e.detail.checked,
                };
              }}
            >
            </d-checkbox>
          </d-section>
          <d-section>
            <d-checkbox
              theme-page
              wrap-label
              ?checked="${editItem.locked}"
              option-field="localUnits_locked"
              @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  locked: e.detail.checked,
                };
              }}
            >
            </d-checkbox>
          </d-section>
        </d-expansion>

        <d-expansion ?opened="${editItem.type === 'mobile'}">
          <d-section>
            <d-select-checkbox
              theme-page
              wrap-label
              deselectable
              field="localUnits_mobileMedicalDataRequirements"
              .options=${this.item.mobileMedicalDataRequirementsOptions}
              .value=${editItem.mobileMedicalDataRequirements}
              vertical
              @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  mobileMedicalDataRequirements: e.detail.value,
                };
              }}
            >
            </d-select-checkbox>
          </d-section>
        </d-expansion>
      </d-expansion>

      <d-expansion ?opened="${editItem.type === 'Arbeidsstasjon'}">
        <d-section>
          <d-checkbox
            theme-page
            ?checked=${editItem.displayPositionSecure}
            option-field="localUnits_displayPositionSecure"
            @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                displayPositionSecure: e.detail.checked,
              };
            }}
          >
          </d-checkbox>
        </d-section>
      </d-expansion>
      <d-edit-html
        theme-page
        map-element
        field="localUnits_notes"
        .value=${editItem.notes}
        .contentStickyTop=${this.contentStickyTop}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          e.stopPropagation();
          this.editItem = {
            ...editItem,
            notes: e.detail.value,
          };
        }}
      >
      </d-edit-html>
    `;
  }

  initializeEditItem(): LocalEditItem {
    return {
      name: this.item.name,
      systemUpdateOperatorType: this.item.systemUpdateOperatorType,
      systemUpdateOperator: this.item.systemUpdateOperator,
      antiVirusOperator: this.item.antiVirusOperator,
      antiVirusOperatorType: this.item.antiVirusOperatorType,
      locked: this.item.locked,
      antiVirusRequirements: this.item.antiVirusRequirements,
      location: this.item.location,
      elevated: this.item.elevated,
      restrictedPhysicalAccess: this.item.restrictedPhysicalAccess,
      networkUuid: this.item.networkUuid,
      mobileMedicalDataRequirements: this.item.mobileMedicalDataRequirements,
      notes: this.item.notes,
      displayPositionSecure: this.item.displayPositionSecure,
      type: this.item.type,
    };
  }

  protected isDeleteDisabled(): boolean {
    return !this.item.deletable;
  }

  protected typeClasses(): string {
    if (this.item.type === 'Server') {
      return ' server';
    }
    if (this.item.type === 'Arbeidsstasjon') {
      return ' computer';
    }
    if (this.item.type === 'mobile') {
      return ' mobile';
    } else {
      return ' computer';
    }
  }

  private _networkConnections(networks: { name: string; uuid: string }[]): SelectDropdownOption[] {
    const list = [
      { value: '', text: 'Velg nettverkstilknytning' },
      { value: 'NONE', text: 'Ingen nettverkstilknytning' },
    ];
    let unregisteredLocalNetworkTerm = 'Lokalt nettverk';
    if (networks.length > 0) {
      unregisteredLocalNetworkTerm = 'Annet lokalt nettverk';
      for (const element of networks) {
        let value = 'Lokalt nettverk uten navn';
        if (element.name !== '') {
          value = 'Lokalt nettverk: ' + element.name;
        }
        list.push({ value: element.uuid, text: value });
      }
    }
    list.push({ value: 'OTHER', text: unregisteredLocalNetworkTerm });
    return list;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-computer-unit-local': DComputerUnitLocal;
  }
}
