import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../library/editors/elements/d-checkbox.js';
import '../../library/editors/elements/d-edit-textarea.js';
import '../../library/elements/d-section.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';
import type { ActionInput } from 'src/library/elements/d-action.js';

export interface DeleteMeetingOccurrenceDialogInput {
  noticeSent: boolean;
  reportWritten: boolean;
}

export interface DeleteMeetingOccurrenceDialogResult {
  action: string;
}

/**
 *
 * USAGE:
 *    d-event-view
 *
 */
@customElement('delete-meeting-occurrence-dialog')
export class DeleteMeetingOccurrenceDialog extends BaseDialog<
  DeleteMeetingOccurrenceDialogInput,
  DeleteMeetingOccurrenceDialogResult
> {
  @property({ type: Boolean })
  noticeSent = false;
  @property({ type: Boolean })
  reportWritten = false;
  @property({ type: Number })
  width = 400;

  protected get calculatedTitle(): string {
    return 'Slette møte';
  }

  protected get calculatedFooterActions(): ActionInput[] {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Slett', action: 'delete' },
    ];
  }

  private get info() {
    if (this.reportWritten) {
      return '<p>Dette møtet har et referat. Hvis du sletter møtet, slettes også møtereferatet.</p><p>Er du sikker på at du vil slette møtet?</p>';
    }
    if (this.noticeSent) {
      return '<p>Det er sendt innkalling til dette møtet. <br>Er du sikker på at du vil slette det?</p>';
    }
    return '';
  }

  renderBody() {
    return html`
      <d-section>
        <d-view-info .content=${this.info}></d-view-info>
      </d-section>
    `;
  }

  protected fetchResult(detail: string): DeleteMeetingOccurrenceDialogResult {
    this.show = false;
    return { action: detail };
  }

  protected initializeDialog(input: DeleteMeetingOccurrenceDialogInput) {
    this.noticeSent = input.noticeSent;
    this.reportWritten = input.reportWritten;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'delete-meeting-occurrence-dialog': DeleteMeetingOccurrenceDialog;
  }
}
