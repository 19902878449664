/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface WebhookSignaturesCommand
 */
export interface WebhookSignaturesCommand {
  /**
   *
   * @type {string}
   * @memberof WebhookSignaturesCommand
   */
  event: string;
  /**
   *
   * @type {string}
   * @memberof WebhookSignaturesCommand
   */
  documentId?: string;
  /**
   *
   * @type {string}
   * @memberof WebhookSignaturesCommand
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof WebhookSignaturesCommand
   */
  signatoryId?: string;
  /**
   *
   * @type {string}
   * @memberof WebhookSignaturesCommand
   */
  signatureOrderId?: string;
  /**
   *
   * @type {string}
   * @memberof WebhookSignaturesCommand
   */
  error?: string;
}

/**
 * Check if a given object implements the WebhookSignaturesCommand interface.
 */
export function instanceOfWebhookSignaturesCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'event' in value;

  return isInstance;
}

export function WebhookSignaturesCommandFromJSON(json: any): WebhookSignaturesCommand {
  return WebhookSignaturesCommandFromJSONTyped(json, false);
}

export function WebhookSignaturesCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WebhookSignaturesCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event: json['event'],
    documentId: !exists(json, 'documentId') ? undefined : json['documentId'],
    status: !exists(json, 'status') ? undefined : json['status'],
    signatoryId: !exists(json, 'signatoryId') ? undefined : json['signatoryId'],
    signatureOrderId: !exists(json, 'signatureOrderId') ? undefined : json['signatureOrderId'],
    error: !exists(json, 'error') ? undefined : json['error'],
  };
}

export function WebhookSignaturesCommandToJSON(value?: WebhookSignaturesCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event: value.event,
    documentId: value.documentId,
    status: value.status,
    signatoryId: value.signatoryId,
    signatureOrderId: value.signatureOrderId,
    error: value.error,
  };
}
