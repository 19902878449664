import { html, nothing, TemplateResult } from 'lit';
import { PageContent } from 'src/pages/page-content';
import { customElement } from 'lit/decorators.js';
import type { AssetsPageViewModel } from './assets-page-view-model';
import './d-assets-table';
import { isEmpty } from 'lodash';
import { uuid } from 'src/utilities/text';
import { CreateAssetInput, CreateEntityInput } from 'src/layout/parts/d-new-document';
import type { PageLink, PageLinks } from 'src/pages/overview-page/overview-page-view-model';
import 'src/library/promo/d-promo-section';
import { features, promo } from 'src/store/selectors/features';
import { registerItemsPromptStyles } from 'src/library/register-items-prompt-styles';

/**
 *
 */
@customElement('d-assets-page-content')
export class DAssetsPageContent extends PageContent<AssetsPageViewModel> {
  static readonly styles = [...super.styles, registerItemsPromptStyles];

  renderPageLinks(list: PageLinks) {
    if (list) {
      return html`
        ${list.items.map((pageLink: PageLink) => {
          return html`<a class="page-link" href="${pageLink.href}">${pageLink.name}</a>`;
        })}
      `;
    }
    return html``;
  }
  renderContent() {
    if (!this.pageView.featureStates.assets && !this.pageView.featureStates.complete) {
      const promoData = promo.find((p) => {
        return p.id === 'assets';
      });
      if (promoData) {
        return html` <d-promo-section
          theme-page
          .employeesCount=${this.pageView.employeesCount}
          .specialTerms=${this.pageView.specialTerms}
          .promoData=${promoData}
          .features=${features}
        ></d-promo-section>`;
      }
      return nothing;
    }
    return html`
      ${this.renderPageLinks(this.pageView.pageLinks)}
      ${isEmpty(this.pageView.assetsNotDiscarded) && isEmpty(this.pageView.assetsDiscarded)
        ? html`<div class="register-items-prompt assets" @click=${() => this.onCreateAsset()}>
            <h2>Ingen utstyrsenheter er registrert.</h2>
            ${this.pageView.writeAccess ? html` <p>Trykk for å legge til.</p>` : nothing}
          </div>`
        : nothing}
      ${isEmpty(this.pageView.assetsNotDiscarded)
        ? nothing
        : html` <d-assets-table
            .items=${this.pageView.assetsNotDiscarded}
            .sortBy=${'name'}
            .groupBy=${'location'}
            .filter=${true}
            theme-page
            .contentStickyTop=${this.contentStickyTop}
            ?hideRequiresElectricity=${this.pageView.variantSimplifiedAssets}
          ></d-assets-table>`}
      ${isEmpty(this.pageView.assetsDiscarded)
        ? nothing
        : html`
            <h2>Kassert utstyr</h2>
            <d-assets-table
              .items=${this.pageView.assetsDiscarded}
              .sortBy=${'name'}
              .groupBy=${'location'}
              .filter=${true}
              label="Kasserte utstyrsenheter"
              theme-page
              .contentStickyTop=${this.contentStickyTop}
              ?hideRequiresElectricity=${this.pageView.variantSimplifiedAssets}
            ></d-assets-table>
          `}
    `;
  }

  protected renderCustomActions(): TemplateResult | typeof nothing {
    return html`
      <d-action href="${this.pageView.pdfHref}" id="print" blank>PDF</d-action>
      ${this.singleUserVersion
        ? nothing
        : html`<d-action class="action" @click=${() => this.onShareContent()}>Del</d-action>`}
    `;
  }

  private onCreateAsset() {
    if (this.pageView.writeAccess) {
      const id = uuid();
      const input: CreateAssetInput = {
        entityType: 'assets',
        entityUuid: id,
        targetUrl: '/account/' + this.pageView.organizationId + '/110/assets/' + id + '?edit',
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-assets-page-content': DAssetsPageContent;
  }
}
