import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { AbstractEntityView, EntityContent } from 'src/content/entity-content.js';
import 'src/utilities/display-alert';
import '../../library/editors/index.js';
import '../../library/editors/components/d-edit-access.js';
import '../../library/editors/elements/d-select-dropdown';
import type { UserForAccess } from 'src/library/editors/components/d-edit-access';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import type { FeatureStates } from 'src/store/selectors/features';
import { SectionField } from 'src/library/components/d-field-section';
import { accessProfiles } from 'src/store';

export interface ContactView extends AbstractEntityView<ContactViewEditItem> {
  lastName: string;
  firstName: string;
  notes: string;
  accessLevel: 'NONE' | 'USER' | 'READONLY' | 'ASSETS';
  email: string;
  telephone: string;
  mobilePhone: string;
  type: 'contacts';
  name: string;
  parentEntityId: string;
  partnerName: string;
  accessExpires: string;
  partnerUuid: string;
  partners: SelectDropdownOption[];
  featureStates: FeatureStates;
}

export interface ContactViewEditItem {
  lastName: string;
  firstName: string;
  name: string;
  accessLevel: 'NONE' | 'USER' | 'READONLY' | 'ASSETS';
  email: string;
  telephone: string;
  mobilePhone: string;
  notes: string;
  partnerId: string;
  accessExpires: string;
}

/**
 *
 */
@customElement('d-contact-view')
export class DContactView extends EntityContent<ContactView, ContactViewEditItem> {
  static readonly styles = EntityContent.styles;
  @property({ type: String })
  entityType = 'contacts';
  @property({ type: String })
  parentEntityType = 'partners';
  @property({ type: String })
  currentUserEmail = '';

  private get accessLevelOptions(): SelectDropdownOption[] {
    return accessProfiles().map((a) => {
      return { value: a.name, text: a.displayName };
    });
  }

  static get observers() {
    return ['_nameChanged(editItem.firstName,editItem.lastName)'];
  }

  _isCurrentUser(currentUserEmail, email) {
    return currentUserEmail === email;
  }

  doneDisabled(): boolean {
    return this.noName;
  }
  private get noName(): boolean {
    return !!(
      this.editItem &&
      isEmptyOrInvalidString(this.editItem.firstName) &&
      isEmptyOrInvalidString(this.editItem.lastName)
    );
  }

  async initializeEditItem() {
    this.editItem = {
      firstName: this.entityView.firstName,
      lastName: this.entityView.lastName,
      accessLevel: this.entityView.accessLevel,
      email: this.entityView.email,
      mobilePhone: this.entityView.mobilePhone,
      notes: this.entityView.notes,
      telephone: this.entityView.telephone,
      name: this.entityView.name,
      partnerId: this.entityView.parentEntityId,
      accessExpires: this.entityView.accessExpires,
    };
  }

  editUser(item: ContactViewEditItem): UserForAccess {
    return {
      uuid: this.entityView.uuid,
      email: item.email,
      accessLevel: item.accessLevel,
      accessExpires: item.accessExpires,
      name: this.entityView.name,
      partnerName: this.entityView.partnerName,
      partnerUuid: this.entityView.partnerUuid,
    };
  }

  protected renderAttachments() {
    if (this.entityView.featureStates.core) {
      return super.renderAttachments();
    }
    return html``;
  }

  private viewFields(): SectionField[] {
    const m = this.entityView.featureStates;
    return [
      {
        field: 'partnerName',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'mobilePhone',
        type: 'view-text',
        class: 'minWidth200',
      },
      {
        field: 'telephone',
        type: 'view-text',
        class: 'minWidth200',
        condition: m.core,
      },
      {
        field: 'email',
        type: 'view-text',
        class: 'minWidth200',
      },
      {
        field: 'accessLevel',
        type: 'view-text',
        options: this.accessLevelOptions,
        class: 'minWidth200',
        condition: m.core,
      },
      {
        field: 'notes',
        type: 'view-text',
        class: 'fullWidth',
        condition: m.core,
      },
    ];
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('contacts', this.viewFields(), this.entityView)} `;
  }

  private editFields(item: ContactViewEditItem): SectionField[] {
    const m = this.entityView.featureStates;
    return [
      {
        field: 'firstName',
        type: 'edit-text',
        class: 'minWidth300',
        autofocus: !this.entityView.isConfirmedEntity,
        selectOnFocus: !this.entityView.isConfirmedEntity,
        markIfEmpty: true,
        placeholder: this.noName ? 'Personen må ha et navn' : '',
      },
      {
        field: 'lastName',
        type: 'edit-text',
        class: 'minWidth300',
        autofocus: !this.entityView.isConfirmedEntity,
        selectOnFocus: !this.entityView.isConfirmedEntity,
        markIfEmpty: true,
        placeholder: this.noName ? 'Personen må ha et navn' : '',
      },
      {
        field: 'mobilePhone',
        type: 'edit-text',
        class: 'minWidth200',
      },
      {
        field: 'telephone',
        type: 'edit-text',
        class: 'minWidth200',
        condition: m.core,
      },
      {
        field: 'email',
        type: 'edit-text',
        class: 'minWidth200',
        condition: !m.core,
      },
      {
        type: 'custom',
        render: () => {
          return this.renderEditAccess(item);
        },
        condition: m.core,
      },
      {
        field: 'notes',
        type: 'edit-textarea',
        class: 'fullWidth',
        condition: m.core,
      },
    ];
  }
  renderEditAccess(item: ContactViewEditItem): TemplateResult<1> {
    return html`
      <d-edit-access
        style="margin-top: 0px; border: none; box-shadow: none;"
        disabled="${this._isCurrentUser(this.currentUserEmail, item.email)}"
        email-value="${item.email}"
        field="contacts_accessLevel"
        .value=${item.accessLevel}
        .editUser=${this.editUser(item)}
        .otherUserEmails=${[]}
        .currentUserUuid=${'TODO'}
        @value-changed=${(e: CustomEvent<{ accessLevel: 'NONE' | 'USER'; email: string; accessExpires: string }>) => {
          this.editItem = {
            ...item,
            accessLevel: e.detail.accessLevel,
            accessExpires: e.detail.accessExpires,
            email: e.detail.email,
          };
        }}
      >
      </d-edit-access>
    `;
  }

  renderEditItem(item: ContactViewEditItem): TemplateResult<1> {
    return html` ${this.renderFieldSection('contacts', this.editFields(item), item)} `;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-contact-view': DContactView;
  }
}
