import { html } from 'lit';

import '../../library/editors/elements/d-edit-textarea.js';
import '../../library/editors/elements/d-select-tag.js';
import '../../library/elements/d-section.js';
import { customElement, property, query } from 'lit/decorators.js';
import type { DPopup } from 'src/library/components/d-popup.js';
import type { EntityOption } from 'src/layout/application-view-model.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';

export interface ShareContentInput {
  employees: EntityOption[];
}
export interface ShareContentResult {
  action: 'cancel' | 'send';
  message: string;
  recipients: string[];
}

/**
 *
 * STATUS OK
 */
@customElement('d-share-content-dialog')
export class DShareContentDialog extends BaseDialog<ShareContentInput, void> {
  static readonly styles = [...BaseDialog.styles];

  @property({ type: Array })
  employees: EntityOption[] = [];
  @property({ type: Array })
  recipients: string[] = [];
  @property({ type: Array })
  paths: { href: string }[] = [];
  @property({ type: String })
  message = '';
  @query('d-popup')
  popup!: DPopup;
  width = 600;
  title = 'Del innhold';
  private info =
    '<p>Send en epost til kolleger med lenke til dette innholdet. ' +
    'Innhold med begrenset tilgang vil bare kunne ses av dem som er gitt tilgang.</p>';

  protected get calculatedHeaderActions() {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Send', action: 'send', disabled: this.sendDisabled },
    ];
  }

  private get employeeOptions(): SelectTagOption[] {
    return this.employees.map((e) => {
      return { value: e.uuid, text: e.name, disabled: e.disabled };
    });
  }

  private get sendDisabled() {
    return (
      this.recipients.filter((r) => {
        return (
          this.employees.filter((e) => {
            return e.uuid === r && !e.disabled;
          }).length > 0
        );
      }).length === 0
    );
  }

  renderBody() {
    return html`
      <d-section>
        <d-view-info .content=${this.info}></d-view-info>
      </d-section>
      <d-section topless>
        <d-edit-textarea
          max-rows="50"
          placeholder="Melding/kommentar"
          value="${this.message}"
          @value-changed=${(e) => {
            this.message = e.detail.value;
          }}
        ></d-edit-textarea>
      </d-section>
      <d-section topless>
        <d-select-tag
          label="Del med"
          .options=${this.employeeOptions}
          .value=${this.recipients}
          toggle-all
          @value-changed=${(e) => {
            this.recipients = e.detail.value;
          }}
        >
        </d-select-tag>
      </d-section>
    `;
  }

  protected fetchResult(detail: string | undefined): ShareContentResult {
    return {
      action: detail === 'send' ? 'send' : 'cancel',
      message: detail === 'send' ? this.message : '',
      recipients: detail === 'send' ? this.recipients : [],
    };
  }

  protected initializeDialog(input: ShareContentInput) {
    this.employees = input.employees;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-share-content-dialog': DShareContentDialog;
  }
}
