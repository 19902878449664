/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SaveDraftCommand
 */
export interface SaveDraftCommand {
  /**
   *
   * @type {string}
   * @memberof SaveDraftCommand
   */
  entityType: string;
  /**
   * Empty for new items. Normally an uuid
   * @type {string}
   * @memberof SaveDraftCommand
   */
  entityUuid?: string;
  /**
   *
   * @type {object}
   * @memberof SaveDraftCommand
   */
  draft: object;
}

/**
 * Check if a given object implements the SaveDraftCommand interface.
 */
export function instanceOfSaveDraftCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'entityType' in value;
  isInstance = isInstance && 'draft' in value;

  return isInstance;
}

export function SaveDraftCommandFromJSON(json: any): SaveDraftCommand {
  return SaveDraftCommandFromJSONTyped(json, false);
}

export function SaveDraftCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveDraftCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entityType: json['entityType'],
    entityUuid: !exists(json, 'entityUuid') ? undefined : json['entityUuid'],
    draft: json['draft'],
  };
}

export function SaveDraftCommandToJSON(value?: SaveDraftCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entityType: value.entityType,
    entityUuid: value.entityUuid,
    draft: value.draft,
  };
}
