import { DayOfWeek } from 'src/utilities/local-date.js';

export type WeekdayStr = 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU';

export const Days: {
  MO: WeekdayStr;
  TU: WeekdayStr;
  WE: WeekdayStr;
  TH: WeekdayStr;
  FR: WeekdayStr;
  SA: WeekdayStr;
  SU: WeekdayStr;
} = {
  MO: 'MO',
  TU: 'TU',
  WE: 'WE',
  TH: 'TH',
  FR: 'FR',
  SA: 'SA',
  SU: 'SU',
};

export const DayOfWeekLookup: {
  MO: DayOfWeek;
  TU: DayOfWeek;
  WE: DayOfWeek;
  TH: DayOfWeek;
  FR: DayOfWeek;
  SA: DayOfWeek;
  SU: DayOfWeek;
} = {
  MO: DayOfWeek.MONDAY,
  TU: DayOfWeek.TUESDAY,
  WE: DayOfWeek.WEDNESDAY,
  TH: DayOfWeek.THURSDAY,
  FR: DayOfWeek.FRIDAY,
  SA: DayOfWeek.SATURDAY,
  SU: DayOfWeek.SUNDAY,
};

export function toDayOfWeek(weekday: WeekdayStr): DayOfWeek {
  return DayOfWeekLookup[weekday];
}

const dayOfWeekNameLookup: Map<DayOfWeek, string> = new Map<DayOfWeek, string>([
  [DayOfWeek.MONDAY, 'mandag'],
  [DayOfWeek.TUESDAY, 'tirsdag'],
  [DayOfWeek.WEDNESDAY, 'onsdag'],
  [DayOfWeek.THURSDAY, 'torsdag'],
  [DayOfWeek.FRIDAY, 'fredag'],
  [DayOfWeek.SATURDAY, 'lørdag'],
  [DayOfWeek.SUNDAY, 'søndag'],
]);

export function getWeekdayName(weekday: DayOfWeek): string {
  const value = dayOfWeekNameLookup.get(weekday);
  if (value !== undefined) {
    return value;
  } else {
    throw new Error('Illegal state (E801), weekday not found ' + weekday);
  }
}

const monthNameLookup = [
  'januar',
  'februar',
  'mars',
  'april',
  'mai',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'desember',
];

export function getMonthName(month: number): string {
  const value = monthNameLookup[month - 1];
  if (value !== undefined) {
    return value;
  } else {
    throw new Error('Illegal state (E802), month out of range ' + month);
  }
}

export function toOrdinal(index: number): string {
  const ordinals = ['første', 'andre', 'tredje', 'fjerde', 'femte', 'sjette'];
  if (index === -1) {
    return 'siste';
  } else {
    return ordinals[index - 1];
  }
}
