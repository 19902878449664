/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LeavePeriod } from './LeavePeriod';
import { LeavePeriodFromJSON, LeavePeriodFromJSONTyped, LeavePeriodToJSON } from './LeavePeriod';

/**
 *
 * @export
 * @interface LeavePeriodUpdateMessage
 */
export interface LeavePeriodUpdateMessage {
  /**
   *
   * @type {LeavePeriod}
   * @memberof LeavePeriodUpdateMessage
   */
  leavePeriod: LeavePeriod;
}

/**
 * Check if a given object implements the LeavePeriodUpdateMessage interface.
 */
export function instanceOfLeavePeriodUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'leavePeriod' in value;

  return isInstance;
}

export function LeavePeriodUpdateMessageFromJSON(json: any): LeavePeriodUpdateMessage {
  return LeavePeriodUpdateMessageFromJSONTyped(json, false);
}

export function LeavePeriodUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LeavePeriodUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    leavePeriod: LeavePeriodFromJSON(json['leavePeriod']),
  };
}

export function LeavePeriodUpdateMessageToJSON(value?: LeavePeriodUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    leavePeriod: LeavePeriodToJSON(value.leavePeriod),
  };
}
