import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import * as dabihStore from 'src/store';
import { LocalDate } from 'src/utilities/local-date.js';
import '../../library/editors/elements/d-edit-text.js';
import '../../library/editors/elements/d-edit-textarea.js';
import '../../library/editors/elements/d-select-tag.js';
import type { SelectTagOption } from '../../library/editors/elements/d-select-tag.js';
import '../../library/elements/d-action.js';
import '../../library/elements/d-label.js';

import '../../library/elements/d-section.js';
import '../../library/elements/d-wrap.js';
import '../../library/fields/d-view-info.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';
import { LocalDateTime } from 'src/utilities/local-date-time.js';

export interface ShareStaffingInput {
  employeesOptions: SelectTagOption[];
  selectedEmployees: string[];
  notes: string;
  email: string;
  time: string;
}

export interface ShareVacationResult {
  notes: string;
  recipient: string;
  employees: string[];
}

/**
 *
 * USAGE:
 *    d-share-staffing
 *
 * STATUS OK
 */

@customElement('share-staffing-dialog')
export class ShareStaffingDialog extends BaseDialog<ShareStaffingInput, ShareVacationResult> {
  static readonly styles = [
    ...BaseDialog.styles,
    css`
      .shares > * {
        margin-bottom: 6px;
      }
    `,
  ];

  @property({ type: Array })
  employeesOptions: SelectTagOption[] = [];
  @property({ type: Array })
  selectedEmployees: string[] = [];
  @property({ type: String })
  notes = '';
  @property({ type: String })
  email = '';
  /**
   * Epostadressene som planen er delt med. For tiden kan dette kun være 1 eller ingen
   */
  @property({ type: Array })
  shares: { email: string; time: string }[] = [];
  @property({ type: Boolean })
  currentUserHasAccess = false;
  @property({ type: Number })
  organizationId = 0;
  @property({ type: String })
  token = '';
  headerActions = [{ name: 'lukk', action: 'close' }];
  width = 600;
  private info =
    '<p>Del ferieplanen med kommunen eller andre samarbeidspartnere ved å sende en epost med lenke ' +
    'til en egen ferieplan. Planen vil vise bekreftede ferieperioder for angitte personer i perioden juni - august. ' +
    'Du vil selv få kopi av eposten som sendes.</p>' +
    '<p>NB: Den delte planen er et øyeblikksbilde og vil ikke ' +
    'automatisk oppdateres, men du kan dele på nytt ved endringer.</p>';

  protected get calculatedTitle(): string {
    return 'Del ferieplan for sommeren ' + this._thisYear();
  }

  _thisYear() {
    const date = new Date();
    return date.getFullYear();
  }

  _sharedWithLabel(shares) {
    if (shares.length) {
      return 'Delt med';
    }
    return 'Del med';
  }

  _showEmailInput(shares) {
    return shares.length < 1;
  }

  _timeDisplay(time) {
    if (time) {
      const dateString = time.split(' ')[0];
      const timeString = time.split(' ')[1];
      return LocalDate.fromString(dateString).toStringForDisplay() + ' kl. ' + timeString.replace(':', '.');
    }
    return '';
  }

  _stringIsEmpty(value) {
    return value ? value.trim().length === 0 : true;
  }

  _isEmail(email) {
    return !this._stringIsEmpty(email) && dabihStore.isEmailValid(email);
  }

  _share(e: Event) {
    e.stopPropagation();
    if (this._isEmail(this.email)) {
      const c: ShareVacationResult = {
        recipient: this.email,
        notes: this.notes,
        employees: this.selectedEmployees,
      };
      this.dispatchEvent(
        new CustomEvent<ShareVacationResult>('share-vacation', { composed: true, bubbles: true, detail: c }),
      );
      this.shares = [{ email: this.email, time: LocalDateTime.now().toString() }];
    }
  }

  _unshare(e: Event, email: string) {
    e.preventDefault();
    e.stopPropagation();
    const c = {
      recipient: email,
    };
    this.dispatchEvent(
      new CustomEvent<{
        recipient: string;
      }>('unshare-vacation', { composed: true, bubbles: true, detail: c }),
    );
    this.email = '';
    this.shares = [];
  }

  _showShare(e: Event, email: string) {
    e.preventDefault();
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{ email: string }>('show-share-vacation', {
        composed: true,
        bubbles: true,
        detail: { email: email },
      }),
    );
  }

  _reshare(e: Event, email: string) {
    e.preventDefault();
    e.stopPropagation();
    const c = {
      recipient: email,
      notes: this.notes,
      employees: this.selectedEmployees,
    };
    this.dispatchEvent(
      new CustomEvent<ShareVacationResult>('share-vacation', { composed: true, bubbles: true, detail: c }),
    );
    this.shares = [{ email: this.email, time: LocalDateTime.now().toString() }];
  }

  renderBody() {
    const showEmailInput = this._showEmailInput(this.shares);
    return html`
      <d-section>
        <d-view-info .content=${this.info}></d-view-info>
      </d-section>
      <d-section>
        <d-select-tag
          label="Del ferieplan for"
          .options=${this.employeesOptions}
          .value=${this.selectedEmployees}
          @value-changed=${this.onSelectedEmployeesChanged}
          toggle-all
        ></d-select-tag>
      </d-section>
      <d-section>
        <d-edit-textarea
          label="Kommentarer til ferieplanen"
          placeholder="F.eks. om virksomheten skal holde åpent i ferien, om noen ferier er usikre etc."
          .value=${this.notes}
          max-rows="30"
          @value-changed=${this.onNotesChanged}
        >
        </d-edit-textarea>
      </d-section>
      <d-section>
        <div>
          <d-label label="${this._sharedWithLabel(this.shares)}"></d-label>
          <div class="shares">
            ${this.shares.map(
              (item) =>
                html` <d-wrap split>
                    <div>${item.email}</div>
                    <div class="comment">${this._timeDisplay(item.time)}</div>
                  </d-wrap>
                  <d-wrap split>
                    <d-action mini @click=${(e) => this._showShare(e, item.email)}>Vis deling</d-action>
                    <d-wrap right>
                      <d-action mini @click=${(e) => this._unshare(e, item.email)}>Stopp deling</d-action>
                      <d-action mini @click=${(e) => this._reshare(e, item.email)}>Del på nytt</d-action>
                    </d-wrap>
                  </d-wrap>`,
            )}
          </div>
          ${showEmailInput
            ? html`
                <d-edit-text
                  placeholder="epostadresse"
                  .value=${this.email}
                  button
                  button-text="Del"
                  ?button-disabled=${!this._isEmail(this.email)}
                  @button-click=${this._share}
                  @value-changed=${this.onEmailChanged}
                ></d-edit-text>
              `
            : nothing}
        </div>
      </d-section>
    `;
  }

  protected fetchResult(_detail: string | undefined): ShareVacationResult {
    return {
      recipient: this.email,
      notes: this.notes,
      employees: this.selectedEmployees,
    };
  }

  protected initializeDialog(input: ShareStaffingInput) {
    this.email = input.email;
    this.employeesOptions = input.employeesOptions;
    this.notes = input.notes;
    this.selectedEmployees = input.selectedEmployees;
    if (input.email !== '') {
      this.shares = [{ email: input.email, time: input.time }];
    }
  }

  private onSelectedEmployeesChanged(e: CustomEvent<{ value: string[] }>) {
    e.stopPropagation();
    this.selectedEmployees = e.detail.value;
  }

  private onNotesChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.notes = e.detail.value;
  }

  private onEmailChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.email = e.detail.value;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'share-staffing-dialog': ShareStaffingDialog;
  }
}
