import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 * This element replaces d-collapse and iron-collapse. Currently it could be replaced by standard javascript if or
 * the when directive, but the intent is tht this element shall animate the opening and closing.
 *
 * Example https://github.com/yujiri8/expandable-section/blob/master/expandable-section.js
 *
 *
 * STATUS OK
 */
@customElement('d-expansion')
export class DExpansion extends LitElement {
  static readonly styles = css`
    :host {
      display: none;
      height: 0;
    }

    :host([opened]) {
      display: block;
      height: auto;
    }

    :host([in-section]) {
      display: block;
      position: relative;
      width: 100%;
      flex-wrap: wrap;
    }

    :host([in-section]) ::slotted(*) {
      flex-grow: 1;
      border-image-source: url(/images/section-element-border-image.svg);
      /* border-image provides border-left */
      border-top-width: 12px;
      /* border-top-width appears as padding-top */
      border-bottom-width: 12px;
      /* border-bottom-width appears as padding-bottom */
      border-left-width: 1px;
      border-right-width: 0;
      border-style: solid;
      border-image-slice: 25%;
      border-image-repeat: repeat;
      padding: 0 12px;
      box-shadow: 0 -1px var(--borderColorTransparent);
      /* box-shadow appears as border-top */
    }
  `;

  @property({ type: Boolean, reflect: true })
  opened = false;
  @property({ type: Number })
  speed = 300;
  @property({ type: Boolean, attribute: 'no-open-animation' })
  noOpenAnimation = false;
  @property({ type: Boolean, attribute: 'no-close-animation' })
  noCloseAnimation = false;

  render() {
    return html`<div id="container"><slot></slot></div>`;
  }

  _open() {
    if (this.shadowRoot) {
      const host = this.shadowRoot.host as HTMLElement;
      if (host) {
        host.style.display = 'block';
        host.style.overflow = 'clip';
        if (this.noOpenAnimation) {
          host.style.transition = 'height 0s';
        }
        const container = this.shadowRoot.getElementById('container');
        if (container) {
          const height = container.offsetHeight;
          host.style.height = height + 'px';
          setTimeout(() => {
            host.style.height = 'auto';
            host.style.removeProperty('overflow');
            host.style.transition = 'height ' + this.speed + 'ms';
          }, this.speed);
        }
      }
    }
  }

  _close() {
    if (this.shadowRoot) {
      const host = this.shadowRoot.host as HTMLElement;
      if (host) {
        const height = host.offsetHeight;
        host.style.height = height + 'px';
        host.style.overflow = 'clip';
        if (this.noCloseAnimation) {
          host.style.transition = 'height 0s';
        }
        setTimeout(() => {
          host.style.height = '0px';
          setTimeout(() => {
            host.style.display = 'none';
            host.style.removeProperty('overflow');
            host.style.transition = 'height ' + this.speed + 'ms';
          }, this.speed);
        }, 0);
      }
    }
  }

  updated(changedProperties: Map<string, any>) {
    if (changedProperties.has('opened')) {
      if (this.opened) {
        this._open();
      } else {
        this._close();
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-expansion': DExpansion;
  }
}
