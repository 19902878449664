import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { EmployeeForStaffingCalendar, getOrganizationUrl, pageIds } from 'src/store';
import 'src/library/editors/elements/d-select-dropdown';
import './d-mobile-new-issue';
import './d-mobile-new-leave-period';
import type { OrganizationReference } from 'src/store/api';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

export interface MobileAppViewModel {
  currentOrganizationId: number;
  currentEmployeeUuid: string;
  currentPath: string;
  employeeForStaffing?: EmployeeForStaffingCalendar;
  organizations: OrganizationReference[];
  recipientOptionsForIssues: SelectDropdownOption[];
  currentUserHasStaffingAccess: boolean;
  leavePeriodEditRestriction: boolean;
  eventsCount: number;
}

/**
 *
 * STATUS OK
 */
@customElement('d-mobile-app')
export class DMobileApp extends LitElement {
  static readonly styles = css`
    :root {
      --header-height: 60px;
      --footer-height: 80px;
    }
    :host {
      position: fixed;
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      background-color: var(--app-background-color);
      background-size: 150px auto;
      border: 1px solid transparent;
    }
    :host * {
      box-sizing: border-box;
    }
    header {
      flex: none;
      height: var(--mobile-app-header-height);
    }
    .organization {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      height: var(--mobile-app-header-height);
      padding: 20px;
      color: white;
      text-align: center;
    }
    .organization .selector {
      flex: none;
      display: flex;
      width: fit-content;
    }
    .organization .name {
      max-width: calc(100vw - 80px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .organization.select .name {
      max-width: calc(100vw - 80px);
      padding-left: 8px;
    }
    .arrow:after {
      content: '';
      display: inline-block;
      position: relative;
      top: -1px;
      font-size: 15px;
      width: 0;
      height: 0;
      margin-left: 10px;
      border-left: 0.3em solid transparent;
      border-right: 0.3em solid transparent;
      border-top: 0.55em solid var(--themeColorLighterOne);
    }
    select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      opacity: 0;
    }
    main {
      flex: 1;
      display: flex;
      overflow: auto;
    }
    main .content {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
    }
    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 130px;
      margin: 0 6px;
      padding: 16px 0;
      font-size: 20px;
      color: white;
      text-align: center;
    }
    .button:before {
      content: '';
      display: block;
      width: 130px;
      height: 130px;
      margin-bottom: 12px;
      box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.3);
      border-radius: 50%;
    }
    .button.calendar:before {
      background: white url(/images/calendar-color.svg) 50% 50% no-repeat;
      background-size: 146px auto;
    }
    .button.leave:before {
      background: white url(/images/staffing-color.svg) 50% 50% no-repeat;
      background-size: 146px auto;
    }
    .button.issues:before {
      background: white url(/images/issues-color.svg) 50% 50% no-repeat;
      background-size: 146px auto;
    }
    .count {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 19px;
      right: -7px;
      min-width: 34px;
      height: 36px;
      border: 2px solid white;
      border-radius: 20px;
      padding: 4px;
      background: var(--alertColor);
      color: white;
      font-weight: 500;
    }
    footer {
      flex: none;
      height: var(--mobile-app-footer-height);
      padding: 12px 0;
    }
    .ui-toggle {
      box-sizing: border-box;
      width: 154px;
      height: 40px;
      position: fixed;
      bottom: 20px;
      left: calc(50vw - 77px);
      border-radius: 20px;
      padding: 10px 0 0 0;
      background: var(--themeColor);
      font-size: 16px;
      text-align: center;
      color: white;
      z-index: 0;
    }
    .panel {
      width: 100vw;
      top: calc(100vh + 10px);
      z-index: 1;
    }
    .panel.up {
      top: 60px;
    }
    .panel-wrapper {
      height: calc(100vh);
      box-shadow: 0 0 10px hsla(1, 0%, 0%, 0.3);
      background: white;
    }
  `;
  @property({ type: Object })
  viewModel!: MobileAppViewModel;
  @property({ type: String })
  panel = '';
  @property({ type: Number })
  visualViewPortHeight = 0;
  @query('#organization-selector')
  orgSelector!: HTMLSelectElement;

  constructor() {
    super();
    this.visualViewPortHeight = document.documentElement.clientHeight;
  }

  get panelHeight() {
    return this.visualViewPortHeight - 60;
  }

  showRegularUi() {
    this.dispatchEvent(
      new CustomEvent<{ isAppPlatform: boolean; isSimplifiedUi: boolean }>('update-ui-settings', {
        composed: true,
        bubbles: true,
        detail: {
          isAppPlatform: true,
          isSimplifiedUi: false,
        },
      }),
    );
  }

  showMainMenu() {
    this.dispatchEvent(
      new CustomEvent('navigate', {
        bubbles: true,
        composed: true,
        detail: { href: '/account/' + this.viewModel.currentOrganizationId },
      }),
    );
    this.showRegularUi();
  }

  private get organizationName(): string {
    const organization = this.viewModel.organizations.find((o) => {
      return o.id === this.viewModel.currentOrganizationId;
    });
    if (organization) {
      return organization.name;
    }
    return '';
  }

  private get organizationsOptions() {
    return this.viewModel.organizations.map((o) => {
      return { value: o.id + '', text: o.name };
    });
  }

  changeOrganization() {
    this.viewModel.currentOrganizationId = Number(this.orgSelector.value);
    const selectedOrganization = this.viewModel.organizations.find((o) => {
      return o.id === this.viewModel.currentOrganizationId;
    });
    if (selectedOrganization) {
      this.dispatchEvent(
        new CustomEvent('navigate', {
          bubbles: true,
          composed: true,
          detail: { href: getOrganizationUrl(selectedOrganization) },
        }),
      );
    }
  }

  private get buttons(): { name: string; label: string; count?: number }[] {
    return [
      { name: 'calendar', label: 'Ukeplan', count: this.viewModel.eventsCount },
      { name: 'leave', label: 'Meld fravær' },
      { name: 'issues', label: 'Meld avvik' },
    ];
  }

  onButtonClick(panel: string) {
    if (panel === 'calendar') {
      this.dispatchEvent(
        new CustomEvent('navigate', {
          bubbles: true,
          composed: true,
          detail: { href: '/account/' + this.viewModel.currentOrganizationId + '/' + pageIds.events },
        }),
      );
      this.showRegularUi();
    } else {
      this.panel = panel;
      setTimeout(() => {
        const elm = this.shadowRoot?.getElementById(panel);
        if (elm) {
          elm.classList.add('up');
        }
      }, 100);
    }
  }

  closeSlide() {
    const elm = this.shadowRoot?.getElementById(this.panel);
    if (elm) {
      elm.style.removeProperty('top');
      elm.classList.remove('up');
    }
    setTimeout(() => {
      this.panel = '';
    }, 300);
  }

  renderCount(b) {
    if (b.count) {
      return html` <div class="count">${b.count}</div>`;
    }
    return html``;
  }

  renderButtons() {
    return html`
      ${this.buttons.map(
        (b) =>
          html`<div class="button ${b.name}" @click=${() => this.onButtonClick(b.name)}>
            ${b.label}${this.renderCount(b)}
          </div> `,
      )}
    `;
  }

  renderPanel() {
    switch (this.panel) {
      case 'issues':
        return html`<d-mobile-new-issue
          class="panel"
          id="issues"
          icon="issues"
          title="Ny avviksmelding"
          .recipientOptions=${this.viewModel.recipientOptionsForIssues}
          .organizationId=${this.viewModel.currentOrganizationId}
          .currentEmployeeUuid=${this.viewModel.currentEmployeeUuid}
          .availableHeight=${this.panelHeight}
          @close=${() => this.closeSlide()}
        ></d-mobile-new-issue>`;
      case 'leave':
        if (this.viewModel.employeeForStaffing) {
          return html` <d-mobile-new-leave-period
            class="panel"
            id="leave"
            icon="staffing"
            title="Nytt fravær"
            .employee=${this.viewModel.employeeForStaffing}
            .currentUserHasAccess=${this.viewModel.currentUserHasStaffingAccess}
            .leavePeriodEditRestriction=${this.viewModel.leavePeriodEditRestriction}
            .availableHeight=${this.panelHeight}
            @close=${() => this.closeSlide()}
          ></d-mobile-new-leave-period>`;
        }
        return html``;
      case 'tasks':
        return html`<div>tasks</div>`;
      default:
        return html``;
    }
  }
  render() {
    return html`
      <header>
        <div class="organization ${this.viewModel.organizations.length > 1 ? 'select' : ''}">
          <div class="selector">
            <div class="name">${this.organizationName}</div>
            ${this.viewModel.organizations.length > 1 ? html` <div class="arrow"></div> ` : nothing}
          </div>
        </div>
        ${this.viewModel.organizations.length > 1
          ? html`
              <select id="organization-selector" @change=${() => this.changeOrganization()}>
                ${this.organizationsOptions.map(
                  (o) => html`
                    <option value="${o.value}" ?selected="${Number(o.value) === this.viewModel.currentOrganizationId}">
                      ${o.text}
                    </option>
                  `,
                )}
              </select>
            `
          : nothing}
      </header>
      <main><div class="content">${this.renderButtons()}</div></main>
      <footer>
        <div class="ui-toggle" @click=${() => this.showMainMenu()}>Vis hovedmeny</div>
      </footer>
      ${this.renderPanel()}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-mobile-app': DMobileApp;
  }
}
