/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountStateEmployeeViewModel } from './AccountStateEmployeeViewModel';
import {
  AccountStateEmployeeViewModelFromJSON,
  AccountStateEmployeeViewModelFromJSONTyped,
  AccountStateEmployeeViewModelToJSON,
} from './AccountStateEmployeeViewModel';

/**
 *
 * @export
 * @interface AccountStateViewModel
 */
export interface AccountStateViewModel {
  /**
   *
   * @type {number}
   * @memberof AccountStateViewModel
   */
  page?: number;
  /**
   *
   * @type {boolean}
   * @memberof AccountStateViewModel
   */
  pending?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AccountStateViewModel
   */
  singleUser?: boolean;
  /**
   *
   * @type {string}
   * @memberof AccountStateViewModel
   */
  businessEntityType?: string;
  /**
   *
   * @type {Array<AccountStateEmployeeViewModel>}
   * @memberof AccountStateViewModel
   */
  employees: Array<AccountStateEmployeeViewModel>;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountStateViewModel
   */
  activityCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof AccountStateViewModel
   */
  leaderFunction: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateViewModel
   */
  healthSafetyAndEnvironmentalFunction: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateViewModel
   */
  assetFunction: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateViewModel
   */
  computerFunction: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateViewModel
   */
  economicsFunction: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateViewModel
   */
  qualityManagerFunction: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateViewModel
   */
  professionalDevelopmentFunction: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateViewModel
   */
  humanResourcesFunction: string;
}

/**
 * Check if a given object implements the AccountStateViewModel interface.
 */
export function instanceOfAccountStateViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'employees' in value;
  isInstance = isInstance && 'leaderFunction' in value;
  isInstance = isInstance && 'healthSafetyAndEnvironmentalFunction' in value;
  isInstance = isInstance && 'assetFunction' in value;
  isInstance = isInstance && 'computerFunction' in value;
  isInstance = isInstance && 'economicsFunction' in value;
  isInstance = isInstance && 'qualityManagerFunction' in value;
  isInstance = isInstance && 'professionalDevelopmentFunction' in value;
  isInstance = isInstance && 'humanResourcesFunction' in value;

  return isInstance;
}

export function AccountStateViewModelFromJSON(json: any): AccountStateViewModel {
  return AccountStateViewModelFromJSONTyped(json, false);
}

export function AccountStateViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountStateViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    page: !exists(json, 'page') ? undefined : json['page'],
    pending: !exists(json, 'pending') ? undefined : json['pending'],
    singleUser: !exists(json, 'singleUser') ? undefined : json['singleUser'],
    businessEntityType: !exists(json, 'businessEntityType') ? undefined : json['businessEntityType'],
    employees: (json['employees'] as Array<any>).map(AccountStateEmployeeViewModelFromJSON),
    activityCodes: !exists(json, 'activityCodes') ? undefined : json['activityCodes'],
    leaderFunction: json['leaderFunction'],
    healthSafetyAndEnvironmentalFunction: json['healthSafetyAndEnvironmentalFunction'],
    assetFunction: json['assetFunction'],
    computerFunction: json['computerFunction'],
    economicsFunction: json['economicsFunction'],
    qualityManagerFunction: json['qualityManagerFunction'],
    professionalDevelopmentFunction: json['professionalDevelopmentFunction'],
    humanResourcesFunction: json['humanResourcesFunction'],
  };
}

export function AccountStateViewModelToJSON(value?: AccountStateViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    page: value.page,
    pending: value.pending,
    singleUser: value.singleUser,
    businessEntityType: value.businessEntityType,
    employees: (value.employees as Array<any>).map(AccountStateEmployeeViewModelToJSON),
    activityCodes: value.activityCodes,
    leaderFunction: value.leaderFunction,
    healthSafetyAndEnvironmentalFunction: value.healthSafetyAndEnvironmentalFunction,
    assetFunction: value.assetFunction,
    computerFunction: value.computerFunction,
    economicsFunction: value.economicsFunction,
    qualityManagerFunction: value.qualityManagerFunction,
    professionalDevelopmentFunction: value.professionalDevelopmentFunction,
    humanResourcesFunction: value.humanResourcesFunction,
  };
}
