import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';

/**
 * The dialog context is placed inside the d-app hierarchy so that d-app may process events from the dialogs.
 *
 */
@customElement('d-dialog-context')
export class DDialogContext extends LitElement {
  static instance?: DDialogContext;

  static addDialog(dialog: BaseDialog<any, any>) {
    DDialogContext.instance?.shadowRoot?.appendChild(dialog);
  }

  render() {
    return html``;
  }

  connectedCallback() {
    super.connectedCallback();
    if (DDialogContext.instance !== undefined) {
      throw new Error(
        'Illegal state (E321), instance already defined, use only one dialog context element per hierarchy',
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    DDialogContext.instance = this;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    DDialogContext.instance = undefined;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-dialog-context': DDialogContext;
  }
}
