import {
  assetsNotDeleted,
  constitutionalDocumentsNotDeleted,
  contactsNotDeleted,
  contractsNotDeletedWithParties,
  currentUserUuid,
  documentsNotDeleted,
  employeesNotDeleted,
  eventOccurrencesNotDeleted,
  functionsNotDeleted,
  getOrganization,
  getPages,
  guidelinesNotDeleted,
  issuesNotDeleted,
  meetingOccurrencesNotDeleted,
  meetingsNotDeleted,
  partnersNotDeleted,
  reportsNotDeleted,
  riskAssessmentsNotDeleted,
  substancesNotDeleted,
  tasksNotDeleted,
} from 'src/store';
import { dateTimeDescription } from 'src/store/utilities';
import { sortBy } from 'lodash';

export interface DocForLinking {
  templateId: number | undefined;
  uuid: string | undefined;
  docType: string;
  classified: boolean;
  noAccess: boolean;
  href: string;
  name: string;
  dateTimeForOrdering: string;
}

function getDocForLinking(item, type, organizationId, pageId, userId, parentHref) {
  if (item.pages?.length) {
    pageId = item.pages[0];
  }
  let noAccess = false;
  let classified = false;
  let href = '/account/' + organizationId + '/' + pageId + '/' + parentHref + type + '/' + item.uuid;
  if (item.classification && item.classification !== 'NONE') {
    classified = true;
    if (!item.accessControl?.includes(userId + '')) {
      noAccess = true;
      href = 'restricted';
    }
  }
  return {
    templateId: item.mainTemplateId ?? undefined,
    uuid: item.uuid,
    docType: type,
    classified,
    noAccess,
    href,
    name: item.name,
    dateTimeForOrdering: '',
  };
}
export function getDocsForLinking(state): DocForLinking[] {
  const result: DocForLinking[] = [];
  const organization = getOrganization(state);
  if (organization) {
    const organizationId = organization.organizationId;
    const userId = currentUserUuid(state);
    getPages(state).forEach((page) => {
      result.push({
        templateId: page.pageId,
        uuid: page.pageId + '',
        docType: 'pages',
        classified: false,
        noAccess: false,
        href: '/account/' + organization.organizationId + '/' + page.pageId,
        name: page.name,
        dateTimeForOrdering: '',
      });
    });
    assetsNotDeleted(state).forEach((item) => {
      const doc = getDocForLinking(item, 'assets', organizationId, 110, userId, '');
      doc.name = item.name + ' ' + item.number;
      result.push(doc);
    });
    constitutionalDocumentsNotDeleted(state).forEach((item) => {
      if (item.name) {
        result.push(getDocForLinking(item, 'constitutionalDocuments', organizationId, 104, userId, ''));
      }
    });
    contractsNotDeletedWithParties(state).forEach((item) => {
      if (item.name) {
        const doc = getDocForLinking(item, 'contracts', organizationId, 357, userId, '');
        doc.name = item.nameWithParties;
        result.push(doc);
      }
    });
    documentsNotDeleted(state).forEach((item) => {
      if (item.name) {
        result.push(getDocForLinking(item, 'documents', organizationId, 357, userId, ''));
      }
    });
    employeesNotDeleted(state).forEach((item) => {
      if (item.name) {
        result.push(getDocForLinking(item, 'employees', organizationId, 104, userId, ''));
      }
    });
    functionsNotDeleted(state).forEach((item) => {
      if (item.name) {
        result.push(getDocForLinking(item, 'functions', organizationId, 357, userId, ''));
      }
    });
    tasksNotDeleted(state).forEach((item) => {
      if (item.name && !item.locked) {
        const parent = organization.functionsById[item.functionUuid];
        const parentHref = 'functions/' + parent.uuid + '/';
        const pageId = parent.pages[0];
        result.push(getDocForLinking(item, 'tasks', organizationId, pageId, userId, parentHref));
      }
    });
    guidelinesNotDeleted(state).forEach((item) => {
      if (item.name) {
        result.push(getDocForLinking(item, 'guidelines', organizationId, 357, userId, ''));
      }
    });
    issuesNotDeleted(state).forEach((item) => {
      if (item.name) {
        result.push(getDocForLinking(item, 'issues', organizationId, 281, userId, ''));
      }
    });
    meetingsNotDeleted(state).forEach((item) => {
      if (item.name) {
        result.push(getDocForLinking(item, 'meetings', organizationId, 9772, userId, ''));
      }
    });
    partnersNotDeleted(state).forEach((item) => {
      if (item.name) {
        result.push(getDocForLinking(item, 'partners', organizationId, 104, userId, ''));
      }
    });
    contactsNotDeleted(state).forEach((item) => {
      if (item.firstName || item.lastName) {
        const parent = organization.partnersById[item.partnerUuid];
        const parentHref = 'partners/' + parent.uuid + '/';
        const doc = getDocForLinking(item, 'contacts', organizationId, 104, userId, parentHref);
        doc.name = item.firstName + ' ' + item.lastName + ' (' + parent.name + ')';
        result.push(doc);
      }
    });
    reportsNotDeleted(state).forEach((item) => {
      if (item.name) {
        result.push(getDocForLinking(item, 'reports', organizationId, 357, userId, ''));
      }
    });
    riskAssessmentsNotDeleted(state).forEach((item) => {
      if (item.name) {
        result.push(getDocForLinking(item, 'riskAssessments', organizationId, 279, userId, ''));
      }
    });
    substancesNotDeleted(state).forEach((item) => {
      if (item.name) {
        result.push(getDocForLinking(item, 'substances', organizationId, 12368, userId, ''));
      }
    });
    eventOccurrencesNotDeleted(state).forEach((item) => {
      if (item.name) {
        const doc = getDocForLinking(item, 'eventOccurrences', organizationId, 318434, userId, '');
        doc.name = item.name + ' ' + dateTimeDescription(item.date ?? '', item.time ?? '', item.durationMinutes);
        doc.dateTimeForOrdering = item.date + ' ' + (item.time && item.time !== 'NONE' ? item.time : '00:00');
        result.push(doc);
      }
    });
    meetingOccurrencesNotDeleted(state).forEach((item) => {
      if (item.name) {
        const doc = getDocForLinking(item, 'meetingOccurrences', organizationId, 9772, userId, '');
        doc.name = item.name + ' ' + dateTimeDescription(item.date ?? '', item.time ?? '', item.durationMinutes);
        doc.dateTimeForOrdering = item.date + ' ' + (item.time && item.time !== 'NONE' ? item.time : '00:00');
        result.push(doc);
      }
    });
  }
  return sortBy(result, ['dateTimeForOrdering', 'name']);
}

export function convertInternalLinks(string, docsForLinking): string {
  const doc = new DOMParser().parseFromString('<div id="temp">' + string + '</div>', 'text/html');
  const elm = doc.getElementById('temp') as HTMLElement;
  const anchors = elm.getElementsByTagName('a');
  for (const a of anchors) {
    const id = a.getAttribute('data-internal-link-id');
    if (id) {
      const doc: DocForLinking | undefined = docsForLinking.find((item) => {
        return item.templateId === Number(id) || item.uuid === id;
      });
      if (doc) {
        if (doc.classified) {
          a.classList.add('classified');
        } else {
          a.classList.remove('classified');
        }
        if (doc.noAccess) {
          a.setAttribute('href', 'restricted');
        } else {
          a.setAttribute('href', doc.href);
        }
        a.setAttribute('target', '_self');
        a.innerHTML = doc.name;
      } else {
        a.setAttribute('href', '');
        a.innerHTML = 'Finner ikke dokumentet: ' + a.innerHTML;
      }
    }
  }
  return elm.innerHTML;
}
