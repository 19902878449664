import type { LocalDate } from 'src/utilities/local-date.js';
import { DayOfWeek, DayOfWeekValues } from 'src/utilities/local-date.js';
import { getWeekdayName, toDayOfWeek, WeekdayStr } from '../date.js';
import { BaseInstanceRule } from './base-instance-rule.js';

export class WeeklyInstanceRule extends BaseInstanceRule {
  private static intervalDescriptionPrefix = [
    'Hver ',
    'Annenhver uke på ',
    'Hver tredje uke på ',
    'Hver fjerde uke på ',
    'Hver femte uke på ',
    'Hver sjette uke på ',
  ];

  private static intervalDescriptionsForAllDays = [
    'Hver dag',
    'Alle dager i annenhver uke',
    'Alle dager i hver tredje uke',
    'Alle dager i hver fjerde uke',
    'Alle dager i hver femte uke',
    'Alle dager i hver sjette uke',
  ];

  private static intervalDescriptionPrefixForSingleDay = [
    'Hver ',
    'Annenhver ',
    'Hver tredje ',
    'Hver fjerde ',
    'Hver femte ',
    'Hver sjette ',
  ];

  private readonly dayOfWeekSet: Set<DayOfWeek> = new Set<DayOfWeek>();

  private readonly byWeekDay: WeekdayStr[];

  public constructor(byWeekDay: WeekdayStr[]) {
    super();
    this.byWeekDay = byWeekDay;

    this.byWeekDay.forEach((b) => {
      this.dayOfWeekSet.add(toDayOfWeek(b));
    });
  }

  public matches(date: LocalDate): boolean {
    return this.dayOfWeekSet.has(date.dayOfWeek());
  }

  public optionsToString(interval: number): string {
    return 'FREQ=WEEKLY;INTERVAL=' + interval + ';BYDAY=' + this.byWeekDay.join(',');
  }

  public scheduleDescription(interval: number): string {
    if (this.dayOfWeekSet.size === 7) {
      return WeeklyInstanceRule.intervalDescriptionsForAllDays[interval - 1];
    } else {
      const s: string[] = DayOfWeekValues.filter((d) => this.dayOfWeekSet.has(d)).map((d) => getWeekdayName(d));

      if (s.length === 1) {
        return WeeklyInstanceRule.intervalDescriptionPrefixForSingleDay[interval - 1] + s[0];
      } else {
        return (
          WeeklyInstanceRule.intervalDescriptionPrefix[interval - 1] +
          s.slice(0, s.length - 1).join(', ') +
          ' og ' +
          s[s.length - 1]
        );
      }
    }
  }

  public expand(start: LocalDate, end: LocalDate, interval: number): LocalDate[] {
    if (interval === 1 || this.byWeekDay.length === 1) {
      return super.expand(start, end, interval);
    } else {
      const s = super.expand(start, end, 1);
      const mondayOfStartWeek = start.withPreviousOrSame(DayOfWeek.MONDAY);
      return s.filter((d) => {
        const monday = d.withPreviousOrSame(DayOfWeek.MONDAY);
        const distance = mondayOfStartWeek.until(monday) / 7;
        return distance % interval === 0;
      });
    }
  }
}
