import { BaseInstanceRule } from './base-instance-rule.js';
import type { LocalDate } from 'src/utilities/local-date.js';

export class SingleInstanceRule extends BaseInstanceRule {
  private readonly start: LocalDate;

  public constructor(start: LocalDate) {
    super();
    this.start = start;
  }

  public matches(date: LocalDate): boolean {
    return date.equals(this.start);
  }

  public optionsToString(): string {
    return 'FREQ=DAILY;COUNT=1';
  }

  public scheduleDescription(): string {
    return 'Ingen';
  }
}
