import { css, html, LitElement, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

import '../elements/d-section';
import '../elements/d-wrap';
import '../elements/d-action';
import '../fields/d-view-text';
import '../fields/d-view-html';
import '../fields/d-view-info';
import '../editors/elements/d-edit-text';
import '../editors/elements/d-edit-textarea';
import '../editors/elements/d-select-dropdown';
import '../editors/elements/d-select-tag';
import '../editors/elements/d-select-checkbox';
import '../editors/elements/d-select-date';
import '../editors/components/d-edit-html';
import '../editors/components/d-edit-access';
import '../../content/partners/d-edit-partner-access';
import '../../content/employees/d-select-profession';

import { ActionInput } from 'src/library/elements/d-action';
import { LocalDate } from 'src/utilities/local-date';
import { isEmptyHtmlContent, isEmptyOrInvalidString } from 'src/utilities/text';

interface BaseField {
  /**
   * Field name corresponding to a property in item.
   * Also provides the field code for label and tooltip, if found.
   */
  field: string;
  /**
   * Function to calcuate value, if needed.
   * */
  value?: () => string;
  /**
   * The element label, in case a field code is not used.
   */
  label?: string;
  /**
   * If field code for value is other than field code for label.
   * Field refers to a property in the entity view model, while fieldForLabel can refer to a computed value in viewItem
   */
  fieldForLabel?: string;
  hideLabel?: boolean;
  class?: string;
  condition?: boolean;
  listItems?: ViewConditionalBullet[];
}

interface EditField extends BaseField {
  disabled?: boolean;
  /**
   * Name of function to fire on change.
   * If omitted, default function 'updateEditItem' is fired.
   */
  onValueChanged?: string;
  valueType?: 'boolean' | 'number';
}

interface Header {
  type: 'header';
  field?: string;
  label?: string;
  subfield?: string;
  sublabel?: string;
  size?: 'big' | 'verybig';
  class?: string;
  condition?: boolean;
}

interface Break {
  type: 'break';
  condition?: boolean;
}

interface Expansion {
  type: 'expansion';
  indent?: number;
  condition?: boolean;
  opened: boolean;
  resetHiddenFields?: boolean;
  fields: SectionField[];
}

interface ViewText extends BaseField {
  type: 'view-text';
  /**
   * If value is a lookup value
   */
  options?: SelectDropdownOption[];
  /**
   * If field code for value is other than field code for label.
   * Field refers to a property in the entity view model, while fieldForLabel can refer to a computed value in viewItem
   */
  fieldForLabel?: string;
  action?: ActionInput;
  hideIfEmpty?: boolean;
  noValueValue?: string;
  noWrapLabel?: boolean;
  inlineLabel?: boolean;
}

interface ViewHtml extends BaseField {
  type: 'view-html';
  hideIfEmpty?: boolean;
  noValueValue?: string;
}

export type DateTimeFormat = 'date-short';

interface ViewDate extends BaseField {
  type: 'view-date';
  format: DateTimeFormat;
  /**
   * If field code for value is other than field code for label.
   * Field refers to a property in the entity view model, while fieldForLabel can refer to a computed value in viewItem
   */
  fieldForLabel?: string;
  hideIfEmpty?: boolean;
  noValueValue?: string;
}

interface ViewInfo extends BaseField {
  type: 'view-info';
  /**
   * If value is a lookup value
   */
  options?: SelectDropdownOption[];
  /**
   * If field code for value is other than field code for label.
   * Field refers to a property in the entity view model, while fieldForLabel can refer to a computed value in viewItem
   */
  fieldForLabel?: string;
  /**
   * If content is not given by field
   */
  content?: string;
}

interface ViewConditionalBullet {
  /**
   * Field value is condition
   * Field tooltip displayed if condition is true
   */
  field: string;
  /**
   * Field tooltip displayed if condition is false
   */
  elseField?: string;
  /**
   * If true, elseField is displayed if condition is null
   * If false, nothing is displayed if condition is null
   * Defaults to true
   */
  hideIfNull?: boolean;
  alert?: boolean;
}

interface ViewConditionalBulletList extends BaseField {
  type: 'view-conditional-bullet-list';
  listItems: ViewConditionalBullet[];
}

interface EditText extends EditField {
  type: 'edit-text';
  autofocus?: boolean;
  selectOnFocus?: boolean;
  placeholder?: string;
  markIfEmpty?: boolean;
}

interface EditTextarea extends EditField {
  type: 'edit-textarea';
  autofocus?: boolean;
  selectOnFocus?: boolean;
  placeholder?: string;
}

interface EditHtml extends EditField {
  type: 'edit-html';
  autofocus?: boolean;
  selectOnFocus?: boolean;
  placeholder?: string;
}

interface EditDate extends EditField {
  type: 'edit-date';
  markIfEmpty?: boolean;
}

interface EditSelect extends EditField {
  type: 'edit-dropdown' | 'edit-tags' | 'edit-radio' | 'edit-checkboxes';
  options: SelectDropdownOption[];
  /**
   * True if option values are corresponding to properties in editItem, instead of field value
   */
  storedAsOptions?: boolean;
  /**
   * True if the input element should accept one selected option only
   */
  single?: boolean;
  /**
   * True if the value is saved as a single value and not an array
   */
  storedAsSingle?: boolean;
  placeholder?: string;
  markIfEmpty?: boolean;
  inlineLabel?: boolean;
  lightLabel?: boolean;
}

interface EditCheckbox extends EditField {
  type: 'edit-checkbox';
  optionField?: string;
  optionLabel?: string;
  hideOptionTooltip?: boolean;
}

interface CustomField {
  type: 'custom';
  render: () => TemplateResult<1>;
  condition?: boolean;
}

export type SectionField =
  | Header
  | Break
  | Expansion
  | ViewText
  | ViewHtml
  | ViewDate
  | ViewInfo
  | ViewConditionalBulletList
  | EditText
  | EditTextarea
  | EditHtml
  | EditSelect
  | EditDate
  | EditCheckbox
  | CustomField;

/**
 * Renders a responsive collection of html view fields and input fields based on a list of fields.
 * Can have dynamically expanding parts.
 * Automatically clears values of input fields in collapsed parts.
 */
@customElement('d-field-section')
export class DFieldSection extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    .cropper {
      overflow: hidden;
    }
    .wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 1px -12px 0 -12px;
    }
    .wrapper > *:not(.break) {
      flex-grow: 1;
      flex-basis: 10%;
      /* border-image provides border-left */
      border-image-source: url(/images/section-element-border-image.svg);
      /* border-top-width appears as padding-top */
      border-top-width: 12px;
      /* border-bottom-width appears as padding-bottom */
      border-bottom-width: 10px;
      border-left-width: 1px;
      border-right-width: 0;
      border-style: solid;
      border-image-slice: 25%;
      border-image-repeat: repeat;
      padding: 0 12px;
      /* box-shadow appears as border-top */
      box-shadow: 0 -1px var(--borderColor);
    }
    :host([theme-page]) .wrapper > * {
      box-shadow: 0 -1px var(--borderColorOnGray);
    }
    .break {
      min-width: calc(100% - 24px);
    }
    .wrapper > d-label[verybig] {
      border-top-width: 18px;
      border-bottom-width: 16px;
    }
    .minWidth200 {
      min-width: 200px;
    }
    .minWidth300 {
      min-width: 300px;
    }
    .fullWidth {
      min-width: calc(100% - 24px);
    }
    d-expansion > div {
      margin-top: -13px;
      margin-bottom: -12px;
    }
    .conditional-bullet-list d-label {
      display: block;
      margin-bottom: 4px;
    }
  `;

  @property({ type: String })
  entityType = '';
  @property({ type: Array })
  fields: SectionField[] = [];
  @property({ type: Object })
  item: any = undefined;
  @property({ type: Boolean, attribute: 'theme-page' })
  themePage = false;

  updateItem(value: any, item: any, field: any) {
    if (field.valueType === 'number') {
      value = Number(value);
    }
    if (field.valueType === 'boolean') {
      if (value === 'true') {
        value = true;
      }
      if (value === 'false') {
        value = false;
      }
    }
    if (field.storedAsOptions) {
      field.options.forEach((o) => {
        let propertyValue = false;
        if (value.includes(o.value)) {
          propertyValue = true;
        }
        item[o.value] = propertyValue;
      });
      this.item = { ...item };
    } else if (field.onValueChanged) {
      this[field.onValueChanged](item);
    } else {
      if (field.storedAsSingle) {
        value = value[0];
      }
      this.item = { ...item, [field.field]: value };
    }
    this.dispatchEvent(
      new CustomEvent('item-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.item },
      }),
    );
  }

  handleAction(e) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent('field-action', {
        bubbles: true,
        composed: true,
        detail: { value: e.detail },
      }),
    );
  }

  private getArrayFromSingularProperties(item: any, options: SelectDropdownOption[]): string[] {
    return options
      .filter((o) => {
        return item[o.value];
      })
      .map((o) => {
        return o.value;
      });
  }

  private getEditFieldValue(item: any, field: any) {
    if (field.storedAsOptions) {
      return this.getArrayFromSingularProperties(item, field.options);
    }
    if (field.storedAsSingle) {
      return [item[field.field]];
    }
    if (field.value) {
      return this[field.value](item);
    }
    if (field.valueType === 'boolean') {
      if (item[field.field] === true) {
        return 'true';
      }
      if (item[field.field] === false) {
        return 'false';
      }
    }
    return item[field.field];
  }

  private getViewFieldValue(item: any, field: any) {
    if (field.value) {
      return field.value();
    }
    const value = item[field.field];
    if (field.options) {
      return (
        field.options.find((o) => {
          return o.value === value;
        })?.text ?? ''
      );
    }
    if (value === '' && field.noValueValue) {
      return field.noValueValue;
    }
    return value;
  }

  private getFormattedDate(value: string, format: DateTimeFormat) {
    if (value && format === 'date-short') {
      return LocalDate.fromString(value).toStringForDisplay();
    }
    return '';
  }

  private getFieldForElement(
    entityType: string,
    field: ViewText | ViewHtml | ViewDate | ViewInfo | EditText | EditTextarea | EditHtml | EditSelect | EditDate,
  ): string {
    if (field.hideLabel) {
      return '';
    }
    if (field.fieldForLabel) {
      return entityType + '_' + field.fieldForLabel;
    }
    return entityType + '_' + field.field;
  }

  private showConditionalBullet(item: any, field: ViewConditionalBulletList): boolean {
    const bulletWithValue = field.listItems.find((listItem) => {
      return item[listItem.field] !== null || !listItem.hideIfNull;
    });
    return bulletWithValue !== undefined;
  }

  private renderBreak(): TemplateResult<1> {
    return html`<div class="break"></div>`;
  }

  private renderSectionHeader(entityType: string, field: Header): TemplateResult<1> {
    return html`<d-label
      allow-wrap
      .big=${!field.size || field.size === 'big'}
      .verybig=${field.size === 'verybig'}
      .field=${entityType + '_' + field.field}
      .label=${field.label ?? ''}
      .subfield=${entityType + '_' + field.subfield}
      .sublabel=${field.sublabel ?? ''}
      class="fullWidth ${field.class ?? ''}"
    ></d-label>`;
  }

  private renderViewTextElement(
    entityType: string,
    field: ViewText,
    value: string,
    classes: string = '',
  ): TemplateResult<1> {
    return html`
      <d-view-text
        .wrapLabel=${!field.noWrapLabel}
        ?inline-label="${field.inlineLabel}"
        .field=${this.getFieldForElement(entityType, field)}
        .label=${field.label ?? ''}
        class="${classes}"
        .value=${value}
      >
      </d-view-text>
    `;
  }

  private renderViewText(item: any, entityType: string, field: ViewText): TemplateResult<1> {
    const value = this.getViewFieldValue(item, field);
    if (!field.hideIfEmpty || !isEmptyOrInvalidString(item[field.field])) {
      if (field.action) {
        return html`
          <d-wrap split class="fullWidth">
            <div>${this.renderViewTextElement(entityType, field, value)}</div>
            <div style="margin-right: -2px">
              <d-action .input=${field.action} @action=${(e) => this.handleAction(e)}>${field.action.name}</d-action>
            </div>
          </d-wrap>
        `;
      }
      return html`${this.renderViewTextElement(entityType, field, value, field.class ?? '')}`;
    }
    return html``;
  }

  private renderViewHtml(item: any, entityType: string, field: ViewHtml): TemplateResult<1> {
    if (!field.hideIfEmpty || !isEmptyHtmlContent(item[field.field])) {
      return html`<d-view-html
        .field=${this.getFieldForElement(entityType, field)}
        .label=${field.label ?? ''}
        .value=${item[field.field]}
        class="fullWidth"
      >
      </d-view-html>`;
    }
    return html``;
  }

  private renderViewDate(item: any, entityType: string, field: ViewDate): TemplateResult<1> {
    const value = item[field.field];
    if (typeof value === 'string' && (!field.hideIfEmpty || value)) {
      return html`
        <d-view-text
          wrap-label
          .field=${this.getFieldForElement(entityType, field)}
          .label=${field.label ?? ''}
          class="${field.class ?? ''}"
          .value=${this.getFormattedDate(value, field.format)}
        >
        </d-view-text>
      `;
    }
    return html``;
  }

  private renderViewInfo(entityType: string, field: ViewInfo): TemplateResult<1> {
    return html`<d-view-info
      .field=${this.getFieldForElement(entityType, field)}
      .content=${field.content ?? ''}
      .label=${field.label ?? ''}
      class="fullWidth"
    >
    </d-view-info>`;
  }

  private renderConditionalBulletList(
    item: any,
    entityType: string,
    field: ViewConditionalBulletList,
  ): TemplateResult<1> {
    if (this.showConditionalBullet(item, field)) {
      return html` <div class="conditional-bullet-list fullWidth">
        ${field.field ? html` <d-label big .field=${entityType + '_' + [field.field]}></d-label> ` : nothing}
        ${field.listItems.map((listItem) => {
          const value = item[listItem.field];
          console.log('XXXXXXXX', listItem, value);
          if (
            value === true ||
            (value === false && listItem.elseField) ||
            (value !== true && value !== false && listItem.elseField && !listItem.hideIfNull)
          ) {
            return html`
              <d-conditional-bullet
                .field=${entityType + '_' + listItem.field}
                .elseField=${entityType + '_' + listItem.elseField}
                .hideIfNull=${listItem.hideIfNull ?? false}
                .condition=${value}
                .alert=${value === false && listItem.alert ? true : false}
              ></d-conditional-bullet>
            `;
          }
        })}
      </div>`;
    }
    return html``;
  }

  private renderEditText(item: any, entityType: string, field: EditText, reset: boolean): TemplateResult<1> {
    const value = this.getEditFieldValue(item, field);
    if (reset && value !== '') {
      this.updateItem('', item, field);
    }
    return html`<d-edit-text
      .field=${this.getFieldForElement(entityType, field)}
      .label=${field.label ?? ''}
      class="${field.class ?? ''}"
      .autofocus=${field.autofocus ?? false}
      .selectOnFocus=${field.selectOnFocus ?? false}
      placeholder="${field.placeholder ?? ''}"
      ?mark-if-empty="${field.markIfEmpty}"
      .value=${value}
      .themePage=${this.themePage}
      @value-changed=${(e) => {
        e.stopPropagation();
        this.updateItem(e.detail.value, item, field);
      }}
    ></d-edit-text>`;
  }

  private renderEditTextarea(item: any, entityType: string, field: EditTextarea, reset: boolean): TemplateResult<1> {
    const value = this.getEditFieldValue(item, field);
    if (reset && value !== '') {
      this.updateItem('', item, field);
    }
    return html`
      <d-edit-textarea
        .field=${this.getFieldForElement(entityType, field)}
        .label=${field.label ?? ''}
        class="${field.class ?? ''}"
        .autofocus=${field.autofocus ?? false}
        .selectOnFocus=${field.selectOnFocus ?? false}
        placeholder="${field.placeholder ?? ''}"
        .value=${value}
        .themePage=${this.themePage}
        wrap-label
        @value-changed=${(e) => {
          e.stopPropagation();
          this.updateItem(e.detail.value, item, field);
        }}
      ></d-edit-textarea>
    `;
  }

  private renderEditHtml(item: any, entityType: string, field: EditHtml, reset: boolean): TemplateResult<1> {
    const value = this.getEditFieldValue(item, field);
    if (reset && value !== '') {
      this.updateItem('', item, field);
    }
    return html`
      <d-edit-html
        .field=${this.getFieldForElement(entityType, field)}
        .label=${field.label ?? ''}
        class="fullWidth ${field.class ?? ''}"
        .autofocus=${field.autofocus ?? false}
        .selectOnFocus=${field.selectOnFocus ?? false}
        placeholder="${field.placeholder ?? ''}"
        .value=${value}
        .themePage=${this.themePage}
        @value-changed=${(e) => {
          e.stopPropagation();
          this.updateItem(e.detail.value, item, field);
        }}
      ></d-edit-html>
    `;
  }

  private renderSelectDropdown(item: any, entityType: string, field: EditSelect, reset: boolean): TemplateResult<1> {
    const value = this.getEditFieldValue(item, field);
    if (reset && value !== '') {
      this.updateItem('', item, field);
    }
    return html`
      <d-select-dropdown
        .field=${this.getFieldForElement(entityType, field)}
        .label=${field.label ?? ''}
        class="${field.class ?? ''}"
        .options=${field.options}
        placeholder="${field.placeholder ?? ''}"
        ?mark-if-empty="${field.markIfEmpty}"
        .value=${value}
        .themePage=${this.themePage}
        ?inline-label="${field.inlineLabel ?? false}"
        ?light-label="${field.lightLabel ?? false}"
        @value-changed=${(e) => {
          e.stopPropagation();
          this.updateItem(e.detail.value, item, field);
        }}
      >
      </d-select-dropdown>
    `;
  }

  private renderSelectTag(item: any, entityType: string, field: EditSelect, reset: boolean): TemplateResult<1> {
    const value = this.getEditFieldValue(item, field);
    if (reset && value.length !== 0) {
      this.updateItem([], item, field);
    }
    if (field.options.length) {
      return html`
        <d-select-tag
          .field=${this.getFieldForElement(entityType, field)}
          .label=${field.label ?? ''}
          class="${field.class ?? ''}"
          .options="${field.options}"
          .value=${value}
          ?single="${field.single}"
          .themePage=${this.themePage}
          @value-changed=${(e) => {
            e.stopPropagation();
            this.updateItem(e.detail.value, item, field);
          }}
        >
        </d-select-tag>
      `;
    }
    return html``;
  }

  private renderSelectRadio(item: any, entityType: string, field: EditSelect, reset: boolean): TemplateResult<1> {
    const value = this.getEditFieldValue(item, field);
    if (reset && value !== null) {
      this.updateItem(null, item, field);
    }
    return html`
      <d-select-radio
        .field=${this.getFieldForElement(entityType, field)}
        .label=${field.label ?? ''}
        class="${field.class ?? ''}"
        .options=${field.options}
        .value=${value}
        .themePage=${this.themePage}
        ?inline-label="${field.inlineLabel ?? false}"
        ?light-label="${field.lightLabel ?? false}"
        @value-changed=${(e) => {
          e.stopPropagation();
          this.updateItem(e.detail.value, item, field);
        }}
      >
      </d-select-radio>
    `;
  }

  private renderCheckboxes(item: any, entityType: string, field: EditSelect, reset: boolean): TemplateResult<1> {
    const value = this.getEditFieldValue(item, field);
    if (reset && value.length !== 0) {
      this.updateItem([], item, field);
    }
    return html`
      <d-select-checkbox
        .field=${this.getFieldForElement(entityType, field)}
        .label=${field.label ?? ''}
        class="${field.class ?? ''}"
        .options=${field.options}
        .value=${value}
        .themePage=${this.themePage}
        @value-changed=${(e) => {
          e.stopPropagation();
          this.updateItem(e.detail.value, item, field);
        }}
      >
      </d-select-checkbox>
    `;
  }

  private renderSelectDate(item: any, entityType: string, field: EditDate, reset: boolean): TemplateResult<1> {
    const value = this.getEditFieldValue(item, field);
    if (reset && value !== '') {
      this.updateItem('', item, field);
    }
    return html`<d-select-date
      .field=${this.getFieldForElement(entityType, field)}
      .label=${field.label ?? ''}
      class="${field.class ?? ''}"
      .value=${value}
      .themePage=${this.themePage}
      @value-changed=${(e) => {
        e.stopPropagation();
        this.updateItem(e.detail.value, item, field);
      }}
    ></d-select-date>`;
  }

  private renderCheckbox(item: any, entityType: string, field: EditCheckbox, reset: boolean): TemplateResult<1> {
    const value = this.getEditFieldValue(item, field);
    if (reset && value !== false) {
      this.updateItem(false, item, field);
    }
    return html`<d-checkbox
      .field=${field.optionField ? '' : entityType + '_' + field.field}
      .optionField=${field.optionField ? entityType + '_' + field.optionField : ''}
      .label=${field.label ?? ''}
      .optionLabel=${field.optionLabel ? field.optionLabel : ''}
      class="${field.class ?? ''}"
      ?hide-option-tooltip="${field.hideOptionTooltip}"
      wrap-label
      .checked=${value}
      .themePage=${this.themePage}
      @checked-changed=${(e) => {
        e.stopPropagation();
        this.updateItem(e.detail.checked, item, field);
      }}
    ></d-checkbox>`;
  }

  private renderCustom(field: CustomField) {
    if (!Object.hasOwn(field, 'condition') || field.condition) {
      return field.render();
    }
    return html``;
  }

  protected renderElement(item: any, entityType: string, field: SectionField, reset: boolean): TemplateResult<1> {
    if (!Object.hasOwn(field, 'condition') || field.condition) {
      switch (field.type) {
        case 'break':
          return html`${this.renderBreak()}`;
        case 'header':
          return html`${this.renderSectionHeader(entityType, field as Header)}`;
        case 'view-text':
          return html`${this.renderViewText(item, entityType, field as ViewText)}`;
        case 'view-html':
          return html`${this.renderViewHtml(item, entityType, field as ViewHtml)}`;
        case 'view-date':
          return html`${this.renderViewDate(item, entityType, field as ViewDate)}`;
        case 'view-info':
          return html`${this.renderViewInfo(entityType, field as ViewInfo)}`;
        case 'view-conditional-bullet-list':
          return html`${this.renderConditionalBulletList(item, entityType, field as ViewConditionalBulletList)}`;
        case 'edit-text':
          return html`${this.renderEditText(item, entityType, field as EditText, reset)}`;
        case 'edit-date':
          return html`${this.renderSelectDate(item, entityType, field as EditDate, reset)}`;
        case 'edit-textarea':
          return html`${this.renderEditTextarea(item, entityType, field as EditTextarea, reset)}`;
        case 'edit-html':
          return html`${this.renderEditHtml(item, entityType, field as EditHtml, reset)}`;
        case 'edit-dropdown':
          return html`${this.renderSelectDropdown(item, entityType, field as EditSelect, reset)}`;
        case 'edit-tags':
          return html`${this.renderSelectTag(item, entityType, field as EditSelect, reset)}`;
        case 'edit-radio':
          return html`${this.renderSelectRadio(item, entityType, field as EditSelect, reset)}`;
        case 'edit-checkboxes':
          return html`${this.renderCheckboxes(item, entityType, field as EditSelect, reset)}`;
        case 'edit-checkbox':
          return html`${this.renderCheckbox(item, entityType, field as EditCheckbox, reset)}`;
        default:
          return html``;
      }
    }
    return html``;
  }

  private renderExpansion(type: string, item: any, field: Expansion, reset: boolean = false): TemplateResult<1> {
    if (!Object.hasOwn(field, 'condition') || field.condition) {
      if ((field.resetHiddenFields && !field.opened) || reset) {
        reset = true;
      } else {
        reset = false;
      }
      return html` <d-expansion ?opened="${field.opened}" class="fullWidth">
        <div style=${field.indent ? 'padding-left:' + field.indent + 'px' : ''}>
          ${this.renderSection(type, item, field.fields, reset)}
        </div>
      </d-expansion>`;
    }
    return html``;
  }

  protected renderSection(type: string, item: any, fields: SectionField[], reset: boolean = false): TemplateResult<1> {
    return html` <div class="cropper">
      <div class="wrapper">
        ${fields.map((field: SectionField) => {
          return html`
            ${field.type === 'expansion'
              ? html`${this.renderExpansion(type, item, field, reset)} `
              : html`
                  ${field.type === 'custom'
                    ? html`${this.renderCustom(field as CustomField)} `
                    : html`${this.renderElement(item, type, field, reset)} `}
                `}
          `;
        })}
      </div>
    </div>`;
  }
  render() {
    if (this.item) {
      return html`${this.renderSection(this.entityType, this.item, this.fields)}`;
    }
    return html``;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-field-section': DFieldSection;
  }
}
