import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import type { EmployeeForStaffingCalendar, EmployeePeriod } from 'src/store';
import {
  calculateActualWorkDay,
  calculateScheduledWorkDay,
  defaultWorkEndTime,
  defaultWorkStartTime,
  findHolidayName,
  getPeriodDayData,
  getScheduleDayData,
  timeInMinutes,
} from 'src/store';
import { LocalDate } from 'src/utilities/local-date';
import '../../library/editors/components/d-select-time';
import '../../library/editors/elements/d-select-dropdown';
import '../../library/elements/d-action';
import '../../library/elements/d-label';
import '../../library/elements/d-section';
import '../../library/elements/d-wrap';
import '../../library/fields/d-view-info';
import '../../library/fields/d-view-text';
import './d-view-icon-text';
import { EditWorkSchedulesDialog, EditWorkSchedulesResult } from './edit-work-schedules-dialog';
import { EditPeriodResult, EditPeriodsDialog, EditPeriodsInput, EditPeriodsResult } from '../edit-periods-dialog';
import { BaseDialog } from 'src/library/components/BaseDialog';
import type { PlusTimePeriod, WorkSchedule, WorkScheduleException } from 'src/store/api';
import { minutesToTime } from 'src/utilities/text';

export interface Functions {
  uuid: string;
  employees: string[];
}

export type WorkDay =
  | {
      workDay: false;
      workMinutes?: number;
    }
  | {
      workDay: true;
      start: string;
      end: string;
      workMinutes?: number;
    };

export type WorkDayWithException = WorkDay & { exception: boolean };

export interface EditException {
  start: string;
  end: string;
  type: 'work' | 'leave';
  editing: boolean;
}

export interface StaffingEmployeeDayTask {
  uuid: string;
  name: string;
  functionUuid: string;
  time: LocalDate;
  dayTask: boolean;
  hours: string;
}

export interface StaffingEmployeeDayInput {
  employee: EmployeeForStaffingCalendar;
  date: string;
  currentEmployeeUuid: string;
  currentUserHasAccess: boolean;
  leavePeriodEditRestriction: boolean;
  writeAccess: boolean;

  /**
   * The tasks for the employee for the selected day
   */
  tasks: StaffingEmployeeDayTask[];

  workScheduleException?: WorkScheduleException;
}

export interface StaffingEmployeeDayResult {
  action: string;
}

/**
 *
 * USAGE:
 *    d-staffing-calendar-table
 *    d-staffing-calendar
 *
 */
@customElement('staffing-employee-day-dialog')
export class StaffingEmployeeDayDialog extends BaseDialog<StaffingEmployeeDayInput, StaffingEmployeeDayResult> {
  static readonly styles = [
    ...BaseDialog.styles,
    css`
      d-view-info {
        margin-bottom: 10px;
      }

      d-view-info + d-view-info {
        margin-top: -6px;
      }
    `,
  ];
  @property({ type: Array })
  tasks: StaffingEmployeeDayTask[] = [];
  @property({ type: Object })
  workScheduleException?: WorkScheduleException;
  @property({ type: Object })
  employee!: EmployeeForStaffingCalendar;
  @property({ type: String })
  currentUser?: string = '';
  @property({ type: String })
  currentEmployeeUuid = '';
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  currentUserHasAccess = false;
  @property({ type: Boolean })
  leavePeriodEditRestriction = false;
  @property({ type: String })
  day = '';
  @property({ type: String })
  leavePeriodStart = '';
  @property({ type: Object })
  editLeavePeriod = {};
  @state()
  editException: EditException = {
    editing: false,
    type: 'work',
    start: '00:00',
    end: '00:00',
  };

  @state()
  editTimeEntry?: {
    day: string;
    type: string;
    newPeriod: boolean;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    confirmed: boolean;
    notes: string;
  };
  @property({ type: String })
  periodList = '';
  @property({ type: String })
  periodStart = '';
  /**
   * The default date for new entries. Normally today.
   */
  @property({ type: String })
  defaultDate = '';
  headerActions = [{ name: 'Lukk', action: 'close' }];
  width = 500;
  private leaveOrWorkOptions = [
    { value: 'leave', text: 'Fridag' },
    { value: 'work', text: 'Arbeidsdag' },
  ];

  protected get calculatedTitle(): string {
    return this.employee.name;
  }
  private get dayName(): string {
    return LocalDate.fromString(this.day).toStringForDisplayWithDayOfWeekAndYear();
  }

  private get holidayName(): string {
    return findHolidayName(this.day);
  }

  private get access() {
    return this.currentUserHasAccess || this.currentUser === this.employee.uuid;
  }

  private get userIsEmployee() {
    return this.currentEmployeeUuid === this.employee.uuid;
  }

  private get showLeavePeriods() {
    return this.currentUserHasAccess || this.userIsEmployee;
  }

  private get actualWorkDay() {
    return calculateActualWorkDay(this.employee, this.day, this.workScheduleException);
  }

  private get exceptionComment() {
    const scheduledWorkDay = calculateScheduledWorkDay(this.employee.workSchedules, this.day);
    return scheduledWorkDay.workDay
      ? 'Fast arbeidsmønster: ' + scheduledWorkDay.start + '-' + scheduledWorkDay.end
      : 'Fast arbeidsmønster: Fridag';
  }

  private get plusTimePeriods() {
    const date = LocalDate.fromString(this.day);
    const periods = this.employee.plusTimePeriods ?? [];
    const filteredPeriods = periods.filter((p) =>
      date.inRange(LocalDate.fromString(p.start), LocalDate.fromString(p.end)),
    );
    return filteredPeriods.map((period) => {
      return getPeriodDayData(this.employee, period, 'plusTime', date, this.workScheduleException);
    });
  }

  private get leavePeriods() {
    const date = LocalDate.fromString(this.day);
    const periods = this.employee.leavePeriods ?? [];
    const filteredPeriods = periods.filter((p) =>
      date.inRange(LocalDate.fromString(p.start), LocalDate.fromString(p.end)),
    );
    return filteredPeriods.map((period) => {
      return getPeriodDayData(this.employee, period, period.type, date, this.workScheduleException);
    });
  }

  showEditLeavePeriods(period) {
    return (
      this.showLeavePeriods && (this.currentUserHasAccess || !this.leavePeriodEditRestriction || !period.confirmed)
    );
  }

  _editWorkday() {
    if (this.actualWorkDay.workDay) {
      this.editException = {
        editing: true,
        type: 'work',
        start: this.actualWorkDay.start,
        end: this.actualWorkDay.end,
      };
    } else {
      const scheduledWorkDay = calculateScheduledWorkDay(this.employee.workSchedules, this.day);
      this.editException = {
        editing: true,
        type: 'leave',
        start: scheduledWorkDay.workDay ? scheduledWorkDay.start : '08:00',
        end: scheduledWorkDay.workDay ? scheduledWorkDay.end : '16:00',
      };
    }
  }

  _cancelEditWorkday() {
    this.editException = {
      editing: false,
      type: 'work',
      start: defaultWorkStartTime,
      end: defaultWorkEndTime,
    };
  }

  _saveWorkday() {
    const s = getScheduleDayData(LocalDate.fromString(this.day), this.employee);
    const sameAsScheduled =
      (this.editException.type === 'leave' && s.workHours === 0) ||
      (this.editException.type === 'work' &&
        this.editException.start === s.scheduleStart &&
        this.editException.end === s.scheduleEnd);
    this.dispatchEvent(
      new CustomEvent<{ value: EditException; employeeUuid: string; sameAsScheduled: boolean; day: string }>(
        'save-workday',
        {
          composed: true,
          bubbles: true,
          detail: {
            value: { ...this.editException },
            employeeUuid: this.employee.uuid,
            sameAsScheduled,
            day: this.day,
          },
        },
      ),
    );

    if (sameAsScheduled) {
      this.workScheduleException = undefined;
    } else {
      this.workScheduleException = {
        date: this.day,
        end: this.editException.end,
        start: this.editException.start,
        work: this.editException.type === 'work',
      };
    }
    this.editException = {
      editing: false,
      type: 'work',
      start: defaultWorkStartTime,
      end: defaultWorkEndTime,
    };
  }

  renderPlusTimeSection() {
    if (this.currentUserHasAccess || this.userIsEmployee) {
      return html` <d-section>
        <div>
          ${this.renderPlusTime()}
          ${!this.writeAccess
            ? nothing
            : html` <d-wrap right>
                <d-action mini @click=${this.onAddPlusTime}>Legg til plusstid</d-action>
              </d-wrap>`}
        </div>
      </d-section>`;
    } else {
      return nothing;
    }
  }

  renderLeaveSection() {
    if (this.currentUserHasAccess || this.currentEmployeeUuid === this.employee.uuid) {
      return html`<d-section>
        <div>
          ${this.renderLeavePeriods()}
          <d-wrap right>
            <d-action mini @click=${this.onAddLeavePeriod}>Legg til fraværsperiode</d-action>
          </d-wrap>
        </div>
      </d-section>`;
    } else {
      return nothing;
    }
  }

  renderBody() {
    return html`
      <d-section>
        <div>${this.renderWorkDay()}</div>
      </d-section>

      ${this.renderPlusTimeSection()}${this.renderLeaveSection()} ${this.renderTasksSection()}
    `;
  }

  protected fetchResult(detail: string | undefined): StaffingEmployeeDayResult {
    return {
      action: detail ?? '',
    };
  }

  protected initializeDialog(input: StaffingEmployeeDayInput) {
    this.employee = input.employee;
    this.day = input.date;
    this.currentEmployeeUuid = input.currentEmployeeUuid;
    this.currentUserHasAccess = input.currentUserHasAccess;
    this.writeAccess = input.writeAccess;
    this.leavePeriodEditRestriction = input.leavePeriodEditRestriction;
    this.subtitle = this.dayName;
    this.subtitleSecond = this.holidayName;
    this.tasks = input.tasks;
    this.workScheduleException = input.workScheduleException;
  }

  private async onEditWorkSchedules() {
    const schedules = this.employee?.workSchedules;
    if (schedules) {
      const result: EditWorkSchedulesResult = await EditWorkSchedulesDialog.open({
        employee: this.employee,
        defaultDate: this.day,
      });
      if (result.action === 'done') {
        this.dispatchEvent(
          new CustomEvent<{
            employeeUuid: string;
            workSchedules: WorkSchedule[];
          }>('update-employee-work-schedules', {
            bubbles: true,
            composed: true,
            detail: {
              employeeUuid: this.employee.uuid,
              workSchedules: result.workSchedules,
            },
          }),
        );
        this.employee = {
          ...this.employee,
          workSchedules: result.workSchedules,
        };
      }
    }
  }

  private async onAddPlusTime() {
    let startTime = '16:00';
    if (this.actualWorkDay?.workDay) {
      startTime = this.actualWorkDay.end;
    }
    const startHours = startTime.split(':')[0];
    const startMinutes = startTime.split(':')[1];
    let endHours = '23';
    if (startHours !== '23') {
      endHours = ('00' + (Number(startHours) + 1)).slice(-2);
    }
    const endTime = endHours + ':' + startMinutes;
    const input: EditPeriodsInput = {
      application: 'timekeeping',
      newPeriod: true,
      startDate: this.day,
      endDate: this.day,
      startTime: startTime,
      endTime: endTime,
      employee: this.employee,
      confirmed: false,
      type: 'plusTime',
      grade: 100,
      notes: '',
      days: [],
      currentUserHasAccess: this.currentUserHasAccess,
      leavePeriodEditRestriction: this.leavePeriodEditRestriction,
    };
    const result: EditPeriodsResult = await EditPeriodsDialog.open(input);

    if (result.action === 'done') {
      const value: EditPeriodResult = {
        end: result.end,
        start: result.start,
        type: result.type,
        confirmed: result.confirmed,
        notes: result.notes,
        grade: result.grade,
        employeeUuid: this.employee.uuid,
        newPeriod: true,
        days: result.days,
      };
      this.dispatchEvent(
        new CustomEvent<EditPeriodResult>('save-timekeeping-period', {
          bubbles: true,
          composed: true,
          detail: value,
        }),
      );
      this.close();
    }
  }

  private async onEditPlusTime(p: {
    notes: string;
    comment: string;
    id: string | undefined;
    confirmed: boolean;
    timeDisplay: string;
    item: PlusTimePeriod;
  }) {
    const input: EditPeriodsInput = {
      application: 'timekeeping',
      newPeriod: false,
      startDate: p.item.start,
      endDate: p.item.end,
      startTime: p.item.startTime ?? 'NONE',
      endTime: p.item.endTime ?? 'NONE',
      notes: p.item.notes,
      confirmed: p.item.confirmed,
      employee: this.employee,
      type: 'plusTime',
      grade: 100,
      days: [],
      currentUserHasAccess: this.currentUserHasAccess,
      leavePeriodEditRestriction: this.leavePeriodEditRestriction,
    };
    const result: EditPeriodsResult = await EditPeriodsDialog.open(input);

    if (result.action === 'done') {
      const value: EditPeriodResult = {
        end: result.end,
        start: result.start,
        type: result.type,
        confirmed: result.confirmed,
        notes: result.notes,
        grade: result.grade,
        employeeUuid: this.employee.uuid,
        newPeriod: false,
        periodId: p.id,
        days: result.days,
      };
      this.dispatchEvent(
        new CustomEvent<EditPeriodResult>('save-timekeeping-period', {
          bubbles: true,
          composed: true,
          detail: value,
        }),
      );
      this.close();
    }
    if (result.action === 'delete') {
      this.dispatchEvent(
        new CustomEvent<{ employeeUuid: string; periodId: string }>('delete-timekeeping-period', {
          bubbles: true,
          composed: true,
          detail: { employeeUuid: this.employee.uuid, periodId: p.id ?? '' },
        }),
      );
      this.close();
    }
  }

  private async onEditLeavePeriod(p: {
    item: EmployeePeriod;
    notes: string;
    comment: string;
    id: string | undefined;
    confirmed: boolean;
    timeDisplay: string;
  }) {
    const input: EditPeriodsInput = {
      application: '',
      newPeriod: false,
      startDate: p.item.start,
      endDate: p.item.end,
      startTime: p.item.startTime ?? 'NONE',
      endTime: p.item.endTime ?? 'NONE',
      notes: p.item.notes,
      confirmed: p.item.confirmed,
      employee: this.employee,
      grade: p.item.grade,
      type: p.item.type,
      days: p.item.days,
      currentUserHasAccess: this.currentUserHasAccess,
      leavePeriodEditRestriction: this.leavePeriodEditRestriction,
    };
    const result: EditPeriodsResult = await EditPeriodsDialog.open(input);

    if (result.action === 'done') {
      const value: EditPeriodResult = {
        end: result.end,
        start: result.start,
        type: result.type,
        confirmed: result.confirmed,
        notes: result.notes,
        grade: result.grade,
        employeeUuid: this.employee.uuid,
        newPeriod: false,
        periodId: p.id,
        days: result.days,
      };
      this.dispatchEvent(
        new CustomEvent<EditPeriodResult>('save-leave-period', {
          bubbles: true,
          composed: true,
          detail: value,
        }),
      );
      this.close();
    }
    if (result.action === 'delete') {
      this.dispatchEvent(
        new CustomEvent<{ employeeUuid: string; periodId: string }>('delete-leave-period', {
          bubbles: true,
          composed: true,
          detail: { employeeUuid: this.employee.uuid, periodId: p.id ?? '' },
        }),
      );
      this.close();
    }
  }

  private exceptionStartChange(value) {
    this.editException = { ...this.editException, start: value };
    const startMinutes = timeInMinutes(value);
    const endMinutes = timeInMinutes(this.editException.end);
    if (endMinutes - startMinutes < 5) {
      this.editException = { ...this.editException, end: minutesToTime(startMinutes + 5) };
    }
  }

  private exceptionEndChange(value) {
    this.editException = { ...this.editException, end: value };
    const startMinutes = timeInMinutes(this.editException.start);
    const endMinutes = timeInMinutes(value);
    if (endMinutes - startMinutes < 5) {
      this.editException = { ...this.editException, start: minutesToTime(endMinutes - 5) };
    }
  }

  private renderWorkDay() {
    if (this.editException.editing) {
      return this.renderWorkDayEditing();
    } else {
      return this.renderWorkDayView();
    }
  }

  private renderWorkDayEditing() {
    return html`
      <d-wrap split-reverse>
        <d-wrap right>
          <d-action mini @click=${() => this._cancelEditWorkday()}>Avbryt</d-action>
          <d-action mini @click=${() => this._saveWorkday()}>Ferdig</d-action>
        </d-wrap>
        <d-label label="Rediger arbeidstid"></d-label>
      </d-wrap>
      <d-wrap>
        <d-select-dropdown
          .options=${this.leaveOrWorkOptions}
          .value=${this.editException.type}
          @value-changed=${(e) => (this.editException = { ...this.editException, type: e.detail.value })}
        ></d-select-dropdown>
        ${this.renderEditException()}
      </d-wrap>
    `;
  }

  private renderWorkDayView() {
    let labelDisplay = this.actualWorkDay.workDay ? 'Arbeidstid' : 'Fridag';
    if (this.actualWorkDay.exception) {
      labelDisplay += ' (unntak)';
    }
    return html`
      <d-wrap split>
        <d-wrap>
          <d-view-icon-text
            .type=${this.actualWorkDay.workDay ? 'work' : 'nonwork'}
            .label=${labelDisplay}
            .value=${this.actualWorkDay.workDay ? this.actualWorkDay.start + ' - ' + this.actualWorkDay.end : ''}
          ></d-view-icon-text>
        </d-wrap>
        ${!this.currentUserHasAccess
          ? nothing
          : html` <d-action mini @click=${() => this._editWorkday()}> Rediger arbeidstid</d-action> `}
      </d-wrap>

      ${!this.currentUserHasAccess
        ? nothing
        : html`<d-wrap right>
            <d-action mini @click=${() => this.onEditWorkSchedules()}>Rediger fast arbeidsmønster</d-action>
          </d-wrap>`}
    `;
  }

  private renderEditException() {
    return this.editException.type === 'leave'
      ? nothing
      : html`
          <d-wrap>
            <d-select-time
              inline-label
              light-label
              label="fra"
              max="23:50"
              .value=${this.editException.start}
              @value-changed=${(e) => this.exceptionStartChange(e.detail.value)}
            ></d-select-time>
            <d-select-time
              inline-label
              light-label
              label="til"
              min="00:05"
              .value=${this.editException.end}
              @value-changed=${(e) => this.exceptionEndChange(e.detail.value)}
            ></d-select-time>
          </d-wrap>
          ${this.actualWorkDay.exception
            ? html`<d-view-info mini .content=${this.exceptionComment}></d-view-info>`
            : nothing}
        `;
  }

  private renderTasksSection() {
    return html` <d-section>
      <div>
        <d-wrap>
          <d-view-icon-text type="calendar" label="Oppgaver og hendelser"></d-view-icon-text>
        </d-wrap>
        ${this.tasks.length === 0
          ? html` <d-view-info mini content="Ingen oppgaver eller hendelser"></d-view-info>`
          : this.tasks.map((t) => {
              const taskDescription = t.hours + ' ' + t.name;
              return html` <d-view-info mini .content=${taskDescription}></d-view-info>`;
            })}
      </div>
    </d-section>`;
  }

  private renderLeavePeriods() {
    return html`
      ${this.leavePeriods.map(
        (p) =>
          html`<d-wrap split>
              <d-view-icon-text
                .type=${p.item.type}
                .unconfirmed=${!p.item.confirmed}
                .value=${p.timeDisplay}
              ></d-view-icon-text>
              ${this.showEditLeavePeriods(p)
                ? html` <d-action mini @click=${() => this.onEditLeavePeriod(p)}> Rediger</d-action> `
                : nothing}
            </d-wrap>
            ${p.comment ? html` <d-view-info mini alert .content=${p.comment}></d-view-info> ` : nothing}
            ${p.notes ? html` <d-view-info mini .content=${p.notes}></d-view-info> ` : nothing}`,
      )}
    `;
  }

  private renderPlusTime() {
    return html`
      ${this.plusTimePeriods.map(
        (p) =>
          html`<d-wrap split>
              <d-view-icon-text type="plustime" .value=${p.timeDisplay}></d-view-icon-text>
              <d-action mini @click=${() => this.onEditPlusTime(p)}> Rediger</d-action>
            </d-wrap>
            ${p.comment ? html` <d-view-info mini alert .content=${p.comment}></d-view-info> ` : nothing}
            ${p.notes ? html` <d-view-info mini .content=${p.notes}></d-view-info> ` : nothing}`,
      )}
    `;
  }

  private async onAddLeavePeriod() {
    const input: EditPeriodsInput = {
      application: '',
      newPeriod: true,
      startDate: this.day,
      endDate: this.day,
      startTime: '',
      endTime: '',
      employee: this.employee,
      notes: '',
      confirmed: false,
      type: 'vacation',
      grade: 100,
      days: [],
      currentUserHasAccess: this.currentUserHasAccess,
      leavePeriodEditRestriction: this.leavePeriodEditRestriction,
    };
    const result: EditPeriodsResult = await EditPeriodsDialog.open(input);

    if (result.action === 'done') {
      const value: EditPeriodResult = {
        end: result.end,
        start: result.start,
        type: result.type,
        confirmed: result.confirmed,
        notes: result.notes,
        employeeUuid: this.employee.uuid,
        newPeriod: true,
        grade: result.grade,
        days: result.days,
      };
      this.dispatchEvent(
        new CustomEvent<EditPeriodResult>('save-leave-period', {
          bubbles: true,
          composed: true,
          detail: value,
        }),
      );
      this.close();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'staffing-employee-day-dialog': StaffingEmployeeDayDialog;
  }
}

/*
      this.workScheduleException || this.employee.workScheduleExceptions.find((e) => e.date === this.day);

 */
