import { html, nothing, TemplateResult } from 'lit';
import { AbstractEntityView, contentViewBuilder, EntityContent } from 'src/content/entity-content.js';
import '../../library/fields/index.js';
import '../../library/editors/index.js';
import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/elements/d-label.js';
import './d-edit-partner-access.js';
import { customElement } from 'lit/decorators.js';
import type { FormViewItem } from 'src/library/components/d-form-view.js';
import { sortedByName } from 'src/store/utilities.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import { commonFieldsByCode } from 'src/store/fields.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import { ActionInput } from 'src/library/elements/d-action';
import { CreateEntityInput } from 'src/layout/parts/d-new-document';
import { FeatureStates } from 'src/store/selectors/features';
import { SectionField } from 'src/library/components/d-field-section';

export interface PartnerView extends AbstractEntityView<PartnerViewEditItem> {
  pages: string[];
  contracts: { href: string; name: string; locked: boolean; accessible: boolean; hasDraft: boolean }[];
  contacts: { href: string; firstName: string; lastName: string; hasDraft: boolean }[];
  remoteAccess: boolean;
  notes: string;
  url: string;
  address: string;
  physicalAccess: boolean;
  email: string;
  phone: string;
  industry: string;
  type: 'partners';
  name: string;
  availablePages: SelectTagOption[];
  featureStates: FeatureStates;
}

export interface PartnerViewEditItem {
  pages: string[];
  name: string;
  remoteAccess: boolean;
  notes: string;
  url: string;
  address: string;
  physicalAccess: boolean;
  email: string;
  phone: string;
  industry: string;
}

/**
 *
 * STATUS OK
 */
@customElement('d-partner-view')
export class DPartnerView extends EntityContent<PartnerView, PartnerViewEditItem> {
  protected get viewItems(): FormViewItem[] {
    if (this.entityView.featureStates.core) {
      const builder = contentViewBuilder();
      builder.addTextIfNotEmpty('partners_industry', this.entityView.industry, 'm');
      builder.addTextIfNotEmpty('partners_phone', this.entityView.phone, 'm');
      builder.addTextIfNotEmpty('partners_address', this.entityView.address, 'm');
      builder.addTextIfNotEmpty('partners_email', this.entityView.email, 'm');
      builder.addTextIfNotEmpty('partners_url', this.entityView.url, 'm');
      builder.addBullets('partners_access', this.computeAccessDescription());
      builder.addTextVertical('partners_notes', this.entityView.notes, 'm', true);
      return builder.build();
    }
    return [];
  }

  private get contactItems() {
    return this.entityView.contacts.map((item) => {
      return {
        href: item.href,
        label: item.firstName + ' ' + item.lastName,
        accessible: true,
        hasDraft: item.hasDraft,
      };
    });
  }

  private get contractItems() {
    return sortedByName(this.entityView.contracts).map((item) => {
      return {
        locked: item.locked,
        accessible: item.accessible,
        href: item.href,
        label: item.name,
        hasDraft: item.hasDraft,
      };
    });
  }

  protected renderAttachments() {
    if (this.entityView.featureStates.core) {
      return super.renderAttachments();
    }
    return html``;
  }

  renderLists(): TemplateResult<1> {
    let contractActions: ActionInput[] = [];
    let contactActions: ActionInput[] = [];
    if (this.entityView.currentUserHasWriteAccess) {
      contractActions = [
        {
          name: 'Legg til',
          action: 'create-contract',
          slot: 'top-right',
        },
      ];
      contactActions = [
        {
          name: 'Legg til',
          action: 'create-contact',
          slot: 'top-right',
        },
      ];
    }
    return html`<d-list-section
        .actions=${contactActions}
        field="organization_contacts"
        icon="employees"
        .items=${this.contactItems}
        .writeAccess=${this.entityView.currentUserHasWriteAccess}
        .contentStickyTop=${this.contentStickyTop}
        @action=${(e: CustomEvent) => this.onListActionForContact(e.detail)}
      ></d-list-section>
      ${this.entityView.featureStates.core
        ? html`<d-list-section
            .actions=${contractActions}
            field="organization_contracts"
            icon="contracts"
            .items=${this.contractItems}
            .writeAccess=${this.entityView.currentUserHasWriteAccess}
            .contentStickyTop=${this.contentStickyTop}
            @action=${(e: CustomEvent) => this.onListActionForContract(e.detail)}
          >
          </d-list-section>`
        : nothing} `;
  }

  async initializeEditItem() {
    this.editItem = {
      address: this.entityView.address,
      email: this.entityView.email,
      industry: this.entityView.industry,
      notes: this.entityView.notes,
      pages: this.entityView.pages,
      phone: this.entityView.phone,
      physicalAccess: this.entityView.physicalAccess,
      remoteAccess: this.entityView.remoteAccess,
      url: this.entityView.url,
      name: this.entityView.name,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  private editFields(item): SectionField[] {
    const m = this.entityView.featureStates;
    return [
      {
        field: 'name',
        type: 'edit-text',
        class: 'minWidth300',
        autofocus: !this.entityView.isConfirmedEntity,
        selectOnFocus: !this.entityView.isConfirmedEntity,
        markIfEmpty: true,
        placeholder: 'Dette feltet må fylles ut',
      },
      {
        field: 'phone',
        type: 'edit-text',
        class: 'minWidth200',
        condition: m.staffing || m.core,
      },
      {
        field: 'industry',
        type: 'edit-text',
        class: 'minWidth200',
        condition: m.core,
      },
      {
        field: 'address',
        type: 'edit-textarea',
        class: 'fullWidth',
        condition: m.core,
      },
      {
        field: 'email',
        type: 'edit-text',
        class: 'minWidth200',
        condition: m.core,
      },
      {
        field: 'url',
        type: 'edit-text',
        class: 'minWidth200',
        condition: m.core,
      },
      {
        type: 'custom',
        render: () => this.renderEditAccess(item),
        condition: m.core,
      },
      {
        field: 'notes',
        type: 'edit-textarea',
        class: 'fullWidth',
        condition: m.core,
      },
    ];
  }

  renderEditAccess(item: PartnerViewEditItem): TemplateResult<1> {
    return html`
      <d-edit-partner-access
        field="partners_access"
        class="fullWidth"
        ?physicalAccessValue=${item.physicalAccess}
        ?remoteAccessValue=${item.remoteAccess}
        @access-changed=${(e: CustomEvent<{ physical: boolean; remote: boolean }>) => {
          this.editItem = { ...item, physicalAccess: e.detail.physical, remoteAccess: e.detail.remote };
        }}
      >
      </d-edit-partner-access>
    `;
  }

  renderEditItem(item: PartnerViewEditItem): TemplateResult<1> {
    return html` ${this.renderFieldSection('partners', this.editFields(item), item)} `;
  }
  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  private onListActionForContract(action: string) {
    if (action === 'create-contract') {
      const id = uuid();
      const input: CreateEntityInput = {
        entityType: 'contracts',
        entityUuid: id,
        targetUrl: this.entityView.href + '/contracts/' + id + '?edit',
        pageId: 63,
        partnerId: this.entityView.uuid,
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }

  private onListActionForContact(action: string) {
    if (action === 'create-contact') {
      const id = uuid();
      const input: CreateEntityInput = {
        entityType: 'contacts',
        entityUuid: id,
        targetUrl: this.entityView.href + '/contacts/' + id + '?edit',
        partnerId: this.entityView.uuid,
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }

  private computeAccessDescription() {
    const result: string[] = [];
    if (this.entityView.physicalAccess) {
      result.push(commonFieldsByCode()['partners_physicalAccess'].label);
    }
    if (this.entityView.remoteAccess) {
      result.push(commonFieldsByCode()['partners_remoteAccess'].label);
    }
    if (result.length === 0) {
      result.push(commonFieldsByCode()['partners_noAccess'].label);
    }
    return result;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-partner-view': DPartnerView;
  }
}
