import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { NewComputerDialog, NewComputerResult } from './new-computer-dialog.js';
import '../../../../library/editors/components/d-select-dropdown-or-add.js';
import {
  getComputerName,
  type InfosecDataItem,
  type InfosecStorageUnit,
  type InfosecCategory,
  type InfosecDataType,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { uuid } from 'src/utilities/text.js';

export interface DataItemComputerChange {
  uuid?: string;
  newComputer: boolean;
  computerUuid: string;
  newComputerName?: string;
  newComputerType?: string;
  dataType: string;
  category: string;
}

/**
 * A data item stored on a local computer. Either display an existing data item stored on a computer or allows the creation of a new data item on a
 * computer. Also allows a new computer to be registered when creating the new data item.
 *
 * Existing data items can not be changed.
 *
 * A computer is only available if they do not already supply a remote server.
 *
 *
 */
@customElement('d-edit-data-item-computer')
export class DEditDataItemComputer extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;

  /**
   * The label for this data item
   */
  @property({ type: String })
  label = '';

  /**
   * light label display property
   */
  @property({ type: Boolean, attribute: 'light-label' })
  lightLabel = false;

  /**
   * small label display property
   */
  @property({ type: Boolean, attribute: 'small-label' })
  smallLabel = false;

  /**
   * The data item. Either an existing accounting data item or an "empty" new item
   */
  @property({ type: Object })
  dataItem!: InfosecDataItem;

  /**
   * The other data items in this category. Used to limit the available computers to the ones not in use for this type and category.
   */
  @property({ type: Array })
  dataItems: InfosecDataItem[] = [];

  /**
   * The available computers
   */
  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];

  /**
   * The data type. Used for new items.
   */
  @property({ type: Object })
  dataType!: InfosecDataType;

  /**
   * The category. Used for new items.
   */
  @property({ type: Object })
  category!: InfosecCategory;

  private get options() {
    const options = this.computers.map((unit) => {
      return {
        value: unit.uuid,
        text: getComputerName(unit),
      };
    });
    if (this.dataItems.length) {
      return options.filter((option) => {
        const matchingDataItems = this.dataItems.filter((dataItem) => {
          return dataItem.storageUnit === option.value;
        });
        return option.value === this.dataItem.storageUnit || matchingDataItems.length === 0;
      });
    }
    return options;
  }

  _update(property) {
    this[property] = JSON.parse(JSON.stringify(this[property]));
  }

  async _valueChanged(value) {
    this.dataItem.storageUnit = value;
    if (value === 'NEW') {
      const result: NewComputerResult = await NewComputerDialog.open({
        name: '',
        requireNameForServers: this.computers.filter((c) => c.computerType === 'Server').length !== 0,
      });
      if (result.action === 'done') {
        this.dataItem.storageUnit = uuid();
        if (!this.dataItem.uuid) {
          this.dataItem.uuid = uuid();
        }
        this.dispatchEvent(
          new CustomEvent<DataItemComputerChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              newComputer: true,
              newComputerName: result.name,
              newComputerType: result.type,
              computerUuid: this.dataItem.storageUnit,
              dataType: this.dataType.type,
              category: this.category.category,
            },
          }),
        );
      }
    } else {
      if (this.dataItem.uuid === '') {
        this.dataItem.uuid = uuid();
      }
      this.dataItem.storageUnit = value;
      this.dispatchEvent(
        new CustomEvent<DataItemComputerChange>('data-item-changed', {
          composed: true,
          bubbles: true,
          detail: {
            newComputer: false,
            computerUuid: value,
            dataType: this.dataType.type,
            category: this.category.category,
          },
        }),
      );
    }
  }

  _dispatchComputersChanged() {
    this.dispatchEvent(
      new CustomEvent('computers-changed', {
        bubbles: true,
        composed: true,
        detail: this.computers,
      }),
    );
  }

  _dispatchDataItemChanged() {
    this.dispatchEvent(
      new CustomEvent('data-item-updated', {
        bubbles: true,
        composed: true,
        detail: this.dataItem,
      }),
    );
  }

  render() {
    return html`
      <d-select-dropdown-or-add
        theme-page
        ?light-label=${this.lightLabel}
        ?small-label=${this.smallLabel}
        .label=${this.label}
        unselectablePlaceholder
        placeholder="Velg datamaskin"
        firstItemText="Registrer datamaskin"
        additionalItemText="Annen datamaskin"
        .options=${this.options}
        ?disabled=${this.dataItem.storageUnit !== ''}
        .value=${this.dataItem.storageUnit}
        @value-changed=${(e) => this._valueChanged(e.detail.value)}
      ></d-select-dropdown-or-add>
    `;
  }
}

/*
   <d-popup-new-computer
        .show=${this.popup}
        .computers=${this.computers}
        @cancel=${() => this._cancelPopup()}
        @done=${(e) => this._donePopup(e.detail)}
      ></d-popup-new-computer>
 */
declare global {
  interface HTMLElementTagNameMap {
    'd-edit-data-item-computer': DEditDataItemComputer;
  }
}
