/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateStaffGroupCommand
 */
export interface UpdateStaffGroupCommand {
  /**
   *
   * @type {string}
   * @memberof UpdateStaffGroupCommand
   */
  staffGroup: UpdateStaffGroupCommandStaffGroupEnum;
}

/**
 * @export
 */
export const UpdateStaffGroupCommandStaffGroupEnum = {
  G1: 'G1',
  G2: 'G2',
  G3: 'G3',
} as const;
export type UpdateStaffGroupCommandStaffGroupEnum =
  (typeof UpdateStaffGroupCommandStaffGroupEnum)[keyof typeof UpdateStaffGroupCommandStaffGroupEnum];

/**
 * Check if a given object implements the UpdateStaffGroupCommand interface.
 */
export function instanceOfUpdateStaffGroupCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'staffGroup' in value;

  return isInstance;
}

export function UpdateStaffGroupCommandFromJSON(json: any): UpdateStaffGroupCommand {
  return UpdateStaffGroupCommandFromJSONTyped(json, false);
}

export function UpdateStaffGroupCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStaffGroupCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    staffGroup: json['staffGroup'],
  };
}

export function UpdateStaffGroupCommandToJSON(value?: UpdateStaffGroupCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    staffGroup: value.staffGroup,
  };
}
