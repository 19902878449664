import { LocalDate } from 'src/utilities/local-date.js';
import { YearlyByDayOfMonthInstanceRule } from './yearly-by-day-of-month-instance-rule.js';
import { YearlyByNthWeekdayInstanceRule } from './yearly-by-nth-weekday-instance-rule.js';
import { MonthlyByDayOfMonthInstanceRule } from './monthly-by-day-of-month-instance-rule.js';
import { MonthlyByNthWeekdayInstanceRule } from './monthly-by-nth-weekday-instance-rule.js';
import { WeeklyInstanceRule } from './weekly-instance-rule.js';
import { DailyInstanceRule } from './daily-instance-rule.js';
import { SingleInstanceRule } from './single-instance-rule.js';
import type { InstanceRule, RecurrenceRuleOptions } from './instance-rule.js';
import { Frequency } from './instance-rule.js';

export function instanceRuleFromOptions(options: Partial<RecurrenceRuleOptions>): InstanceRule {
  switch (options.freq) {
    case Frequency.YEARLY:
      return fromYearlyOptions(options);
    case Frequency.MONTHLY:
      return fromMonthlyOptions(options);
    case Frequency.WEEKLY:
      return fromWeeklyOptions(options);
    case Frequency.DAILY:
      return fromDailyOptions(options);
  }

  throw new Error('Illegal frequency ' + options.freq);
}

function fromYearlyOptions(
  options: Partial<RecurrenceRuleOptions>,
): YearlyByDayOfMonthInstanceRule | YearlyByNthWeekdayInstanceRule {
  if (options.byMonth !== undefined && options.byMonthDay !== undefined) {
    return new YearlyByDayOfMonthInstanceRule(options.byMonth, options.byMonthDay);
  } else if (options.byMonth !== undefined && options.byWeekDay !== undefined && options.bySetPos !== undefined) {
    return new YearlyByNthWeekdayInstanceRule(options.byMonth, options.byWeekDay[0], options.bySetPos);
  } else if (options.start !== undefined) {
    const s = LocalDate.fromRecurrenceRuleString(options.start);
    return new YearlyByDayOfMonthInstanceRule(s.month(), s.day());
  }
  throw new Error('Illegal state of yearly options ' + JSON.stringify(options));
}

function fromMonthlyOptions(
  options: Partial<RecurrenceRuleOptions>,
): MonthlyByDayOfMonthInstanceRule | MonthlyByNthWeekdayInstanceRule {
  if (options.byMonthDay !== undefined) {
    return new MonthlyByDayOfMonthInstanceRule(options.byMonthDay);
  } else if (options.byWeekDay !== undefined && options.bySetPos !== undefined) {
    return new MonthlyByNthWeekdayInstanceRule(options.byWeekDay[0], options.bySetPos);
  } else if (options.start !== undefined) {
    const s = LocalDate.fromRecurrenceRuleString(options.start);
    return new MonthlyByDayOfMonthInstanceRule(s.day());
  }
  throw new Error('Illegal state of monthly options ' + JSON.stringify(options));
}

function fromWeeklyOptions(options: Partial<RecurrenceRuleOptions>): WeeklyInstanceRule {
  if (options.byWeekDay !== undefined) {
    return new WeeklyInstanceRule(options.byWeekDay);
  }
  throw new Error('Illegal state of weekly options ' + JSON.stringify(options));
}

function fromDailyOptions(options: Partial<RecurrenceRuleOptions>): DailyInstanceRule | SingleInstanceRule {
  if (options.count !== undefined && options.count === 1) {
    if (options.start === undefined) {
      throw new Error('Illegal undefined start option');
    }
    return new SingleInstanceRule(LocalDate.fromRecurrenceRuleString(options.start));
  } else {
    return new DailyInstanceRule();
  }
}
