/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LeavePeriodDay } from './LeavePeriodDay';
import { LeavePeriodDayFromJSON, LeavePeriodDayFromJSONTyped, LeavePeriodDayToJSON } from './LeavePeriodDay';

/**
 *
 * @export
 * @interface LeavePeriod
 */
export interface LeavePeriod {
  /**
   *
   * @type {string}
   * @memberof LeavePeriod
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof LeavePeriod
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof LeavePeriod
   */
  start: string;
  /**
   *
   * @type {string}
   * @memberof LeavePeriod
   */
  end: string;
  /**
   *
   * @type {string}
   * @memberof LeavePeriod
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof LeavePeriod
   */
  endTime?: string;
  /**
   *
   * @type {string}
   * @memberof LeavePeriod
   */
  notes: string;
  /**
   *
   * @type {boolean}
   * @memberof LeavePeriod
   */
  confirmed: boolean;
  /**
   *
   * @type {number}
   * @memberof LeavePeriod
   */
  grade: number;
  /**
   *
   * @type {Array<LeavePeriodDay>}
   * @memberof LeavePeriod
   */
  days: Array<LeavePeriodDay>;
}

/**
 * Check if a given object implements the LeavePeriod interface.
 */
export function instanceOfLeavePeriod(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'type' in value;
  isInstance = isInstance && 'start' in value;
  isInstance = isInstance && 'end' in value;
  isInstance = isInstance && 'notes' in value;
  isInstance = isInstance && 'confirmed' in value;
  isInstance = isInstance && 'grade' in value;
  isInstance = isInstance && 'days' in value;

  return isInstance;
}

export function LeavePeriodFromJSON(json: any): LeavePeriod {
  return LeavePeriodFromJSONTyped(json, false);
}

export function LeavePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeavePeriod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    type: json['type'],
    start: json['start'],
    end: json['end'],
    startTime: !exists(json, 'startTime') ? undefined : json['startTime'],
    endTime: !exists(json, 'endTime') ? undefined : json['endTime'],
    notes: json['notes'],
    confirmed: json['confirmed'],
    grade: json['grade'],
    days: (json['days'] as Array<any>).map(LeavePeriodDayFromJSON),
  };
}

export function LeavePeriodToJSON(value?: LeavePeriod | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    type: value.type,
    start: value.start,
    end: value.end,
    startTime: value.startTime,
    endTime: value.endTime,
    notes: value.notes,
    confirmed: value.confirmed,
    grade: value.grade,
    days: (value.days as Array<any>).map(LeavePeriodDayToJSON),
  };
}
