import { css } from 'lit';

export const internalLinkStyles = css`
  a[data-internal-link-doctype] {
    background-size: 26px auto;
    background-position: 1px 2px;
    background-repeat: no-repeat;
    margin: -6px 0;
    padding: 6px 0 6px 30px;
    font-size: 15px;
    font-family: var(--mainSans);
    font-weight: normal;
    border-bottom: none;
    mix-blend-mode: multiply;
  }

  a[href='restricted'] {
    color: gray;
    cursor: default;
    pointer-events: none;
  }

  a.classified:before {
    content: '';
    display: inline-block;
    position: relative;
    top: 1px;
    width: 20px;
    height: 15px;
    background-image: url(/images/locked.svg);
    background-repeat: no-repeat;
    background-position: -4px -4px;
    background-size: 24px;
  }

  a[data-internal-link-doctype][href=''] {
    padding-left: 30px;
    background-image: url(/images/alert.svg);
    background-size: 32px auto;
    background-position: -2px -1px;
    color: var(--alertColor);
    cursor: pointer;
    pointer-events: none;
  }

  a[data-internal-link-doctype]:not([href='']):hover {
    background-position-y: -128px;
    color: black;
  }

  .html.editable a[data-internal-link-doctype]:hover {
    background-position-y: 2px;
    color: var(--themeColorDarkerOne);
  }

  a[data-internal-link-doctype='pages'] {
    padding-left: 0;
  }

  a[data-internal-link-doctype='assets'] {
    background-image: url(/images/assets-link.svg);
  }

  a[data-internal-link-doctype='constitutionalDocuments'] {
    background-image: url(/images/constitutional-documents-link.svg);
  }

  a[data-internal-link-doctype='contracts'] {
    background-image: url(/images/contracts-link.svg);
  }

  a[data-internal-link-doctype='documents'] {
    background-image: url(/images/documents-link.svg);
  }

  a[data-internal-link-doctype='employees'] {
    background-image: url(/images/employees-link.svg);
  }

  a[data-internal-link-doctype='functions'] {
    background-image: url(/images/functions-link.svg);
  }

  a[data-internal-link-doctype='tasks'] {
    background-image: url(/images/tasks-link.svg);
  }

  a[data-internal-link-doctype='guidelines'] {
    background-image: url(/images/guidelines-link.svg);
  }

  a[data-internal-link-doctype='issues'] {
    background-image: url(/images/issues-link.svg);
  }

  a[data-internal-link-doctype='meetings'] {
    background-image: url(/images/meetings-link.svg);
  }

  a[data-internal-link-doctype='partners'] {
    background-image: url(/images/partners-link.svg);
  }

  a[data-internal-link-doctype='contacts'] {
    background-image: url(/images/employees-link.svg);
  }

  a[data-internal-link-doctype='reports'] {
    background-image: url(/images/reports-link.svg);
  }

  a[data-internal-link-doctype='riskAssessments'] {
    background-image: url(/images/risk-assessments-link.svg);
  }

  a[data-internal-link-doctype='substances'] {
    background-image: url(/images/substances-link.svg);
  }

  a[data-internal-link-doctype='eventOccurrences'] {
    background-image: url(/images/events-link.svg);
  }

  a[data-internal-link-doctype='meetingOccurrences'] {
    background-image: url(/images/meetings-link.svg);
  }
`;
