import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import 'src/content/entity-content';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { AttachmentItem } from 'src/library/lists/d-list-section-attachment.js';
import '../../library/editors/index.js';
import '../../library/fields/index.js';
import '../../library/fields/d-expansion.js';
import '../../library/fields/d-view-html.js';
import '../../library/lists/d-list-section-attachment.js';
import { DataMapElement, DataMapItem } from './data-map-element.js';
import './d-edit-single-related-item.js';
import './d-view-checkpoint.js';
import './d-view-related-items.js';
import type { RiskAssessmentInformation } from './d-computers-page-content-map.js';

export interface ExternalConnection extends DataMapItem {
  riskAssessmentsDisplay: { name: string; riskDisplay: string; href: string }[];
  description: string;
  networks: SelectDropdownOption[];
  unitType: 'externalConnection';
  connectedViaNhn: boolean;
  attachments: AttachmentItem[];
  type: 'Hjemmekontor' | 'Tilkoblet samarbeidspartner';
  name: string;
  employeeUuid: string;
  partnerUuid: string;
  networkUuid: string;
  connectionType: string;
  riskAssessments: string[];
  otherRiskAssessments: string;
  riskAssessmentConcludesOk: boolean;
}

export interface ExternalConnectionEditItem {
  type: 'Hjemmekontor' | 'Tilkoblet samarbeidspartner';
  description: string;
  employeeUuid: string;
  partnerUuid: string;
  networkUuid: string;
  connectionType: string;
  riskAssessments: string[];
  otherRiskAssessments: string;
  riskAssessmentConcludesOk: boolean;
}

/**
 *
 */
@customElement('d-external-connection-view')
export class DExternalConnectionView extends DataMapElement<ExternalConnection, ExternalConnectionEditItem> {
  @property({ type: Boolean })
  hideDetails = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: Boolean })
  onlyNew = false;
  @property({ type: Boolean })
  simplifiedPrivacy = false;
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: Array })
  employees: SelectDropdownOption[] = [];
  @property({ type: Array })
  riskAssessmentsList: RiskAssessmentInformation[] = [];
  @property({ type: Boolean })
  alertsExist = false;
  @property({ type: Boolean })
  writeAccess = false;

  _displayTypeName() {
    if (this.item.type === 'Tilkoblet samarbeidspartner') {
      return 'Tilkoblet partner';
    }
    if (this.item.type === 'Hjemmekontor') {
      return 'Hjemmekontor';
    }
    return '';
  }

  _displayName() {
    return this.item.name;
  }
  _openElement() {
    this.elementOpened = true;
  }
  _isValid() {
    return (
      (this.editItem?.partnerUuid !== undefined &&
        this.editItem?.partnerUuid !== null &&
        this.editItem?.partnerUuid !== '') ||
      (this.editItem?.employeeUuid !== undefined &&
        this.editItem?.employeeUuid !== null &&
        this.editItem?.employeeUuid !== '')
    );
  }

  renderUnitEdit(editItem: ExternalConnectionEditItem) {
    return html`<d-section vertical>
        ${this.item.type === 'Hjemmekontor'
          ? html` <d-edit-single-related-item
              field="externalConnections_employees"
              .items=${this.employees}
              @new-item=${this.onNewEmployee}
              placeholder="Velg person"
              .relatedItem=${editItem.employeeUuid}
              @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  employeeUuid: e.detail.relatedItem,
                };
              }}
            >
            </d-edit-single-related-item>`
          : html`<d-edit-single-related-item
              field="externalConnections_partner"
              .items=${this.partners}
              @new-item=${this.onNewPartner}
              placeholder="Velg samarbeidspartner"
              .relatedItem=${editItem.partnerUuid}
              @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
                e.stopPropagation();
                this.editItem = {
                  ...editItem,
                  partnerUuid: e.detail.relatedItem,
                };
              }}
            >
            </d-edit-single-related-item>`}
        <d-select-dropdown
          theme-page
          field="externalConnections_network"
          .options=${this.item.networks}
          placeholder="Velg lokalt nettverk"
          .value=${editItem.networkUuid}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              networkUuid: e.detail.value,
            };
          }}
        >
        </d-select-dropdown>
        ${this.simplifiedPrivacy
          ? nothing
          : html`
              <d-select-dropdown
                theme-page
                field="externalConnections_connectionType"
                .options="${this.connectionTypes}"
                value="${editItem.connectionType}"
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  e.stopPropagation();
                  this.editItem = {
                    ...editItem,
                    connectionType: e.detail.value,
                  };
                }}
              >
              </d-select-dropdown>
            `}
      </d-section>
      <d-edit-html
        theme-page
        map-element
        .contentStickyTop=${this.contentStickyTop}
        field="externalConnections_description"
        value="${editItem.description}"
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          e.stopPropagation();
          this.editItem = {
            ...editItem,
            description: e.detail.value,
          };
        }}
      >
      </d-edit-html>
      <d-section>
        <d-checkbox
          theme-page
          ?checked="${editItem.riskAssessmentConcludesOk}"
          option-field="externalConnections_riskAssessmentConcludesOk"
          @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              riskAssessmentConcludesOk: e.detail.checked,
            };
          }}
        >
        </d-checkbox>
      </d-section> `;
  }

  renderUnitView() {
    return html`<section>
      <d-section vertical>
        ${this.item.description
          ? html`
              <d-view-html field="externalConnections_description" value="${this.item.description}"> </d-view-html>
            `
          : nothing}
        <d-view-checkpoint
          ?checked="${this.item.riskAssessmentConcludesOk}"
          field="externalConnections_riskAssessmentConcludesOk"
        >
        </d-view-checkpoint>
      </d-section>
      <d-list-section-attachment
        .attachments="${this.item.attachments}"
        class="computers"
        label="Vedlegg"
        page-id="20"
        sort="1"
        .writeAccess=${this.writeAccess}
        theme-page
      >
      </d-list-section-attachment>
    </section>`;
  }

  initializeEditItem(): ExternalConnectionEditItem {
    return {
      employeeUuid: this.item.employeeUuid,
      partnerUuid: this.item.partnerUuid,
      networkUuid: this.item.networkUuid,
      connectionType: this.item.connectionType,
      riskAssessments: this.item.riskAssessments,
      otherRiskAssessments: this.item.otherRiskAssessments,
      riskAssessmentConcludesOk: this.item.riskAssessmentConcludesOk,
      description: this.item.description,
      type: this.item.type,
    };
  }

  protected typeClasses(): string {
    if (this.item.type === 'Hjemmekontor') {
      return ' homeOffice';
    }
    if (this.item.type === 'Tilkoblet samarbeidspartner') {
      return ' externalPartner';
    }

    return '';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-external-connection-view': DExternalConnectionView;
  }
}
