import { html } from 'lit';
import '../../library/elements/d-action.js';
import '../../library/elements/d-section.js';
import '../../library/editors/elements/d-edit-textarea.js';
import { customElement, property } from 'lit/decorators.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';

export interface FeedbackInput {
  message: string;
}
export interface FeedbackResult {
  message: string;
  action: 'cancel' | 'done';
}

/**
 *
 * STATUS OK
 */
@customElement('d-feedback-dialog')
export class DFeedbackDialog extends BaseDialog<FeedbackInput, FeedbackResult> {
  @property({ type: Boolean })
  sending = false;
  @property({ type: String })
  message = '';
  width = 600;
  title = 'Tilbakemelding til TrinnVis';

  protected get calculatedHeaderActions() {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Send', action: 'send', disabled: this.noMessage },
    ];
  }

  private get noMessage() {
    return this.message === undefined || this.message.trim() === '';
  }

  renderBody() {
    return html`
      <d-section topless>
        <d-edit-textarea
          max-rows="50"
          placeholder="Spørsmål, idéer, ros og ris til TrinnVis"
          .value=${this.message}
          autofocus
          @value-changed=${this.onMessageChanged}
        ></d-edit-textarea>
      </d-section>
    `;
  }

  protected fetchResult(detail: string | undefined): FeedbackResult {
    return {
      message: detail === 'send' ? this.message : '',
      action: detail === 'send' ? 'done' : 'cancel',
    };
  }

  protected initializeDialog(input: FeedbackInput) {
    this.message = input.message;
  }

  /**
   * Update message when value is changed
   * @param {CustomEvent} e Custom Event
   */
  private onMessageChanged(e: CustomEvent) {
    this.message = e.detail.value;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-feedback-dialog': DFeedbackDialog;
  }
}
