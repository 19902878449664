import type { NhnViewModel, State } from '../types.js';
import { toList } from './utilities.js';
import type {
  ComputerViewModel,
  ExternalConnectionViewModel,
  NetworkViewModel,
  PersonalDataItemViewModel,
} from '../api';

export function personalDataItemsNotDeleted(state: State): PersonalDataItemViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.personalDataItemsById).filter((e) => !e.deleted);
}

export function computersNotDeleted(state: State): ComputerViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.computersById).filter((e) => !e.deleted);
}

export function externalConnectionsNotDeleted(state: State): ExternalConnectionViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.externalConnectionsById).filter((e) => !e.deleted);
}

export function networksNotDeleted(state: State): NetworkViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.networksById).filter((e) => !e.deleted);
}

export function nhn(state: State): NhnViewModel {
  const defaultValue = {
    partner: '',
    contract: '',
  };
  if (state.organization === undefined) return defaultValue;
  if (state.organization.nhn === undefined) return defaultValue;
  return state.organization.nhn;
}

export function computersDeleted(state: State): ComputerViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.computersById).filter((e) => e.deleted);
}

export function networksDeleted(state: State): NetworkViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.networksById).filter((e) => e.deleted);
}

export function externalConnectionsDeleted(state: State): ExternalConnectionViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.externalConnectionsById).filter((e) => e.deleted);
}

export function personalDataItemsDeleted(state: State): PersonalDataItemViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.personalDataItemsById).filter((e) => e.deleted);
}
