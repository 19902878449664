import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {
  activityCodesAvailable,
  activityCodesCommon,
  businessEntityTypeOptions,
  sectorOptions,
} from 'src/store/selectors';
import '../../library/components/d-activity-codes-container.js';
import '../../library/editors/index.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/elements/d-label.js';
import '../../library/elements/d-section.js';
import '../../library/components/d-field-section';
import type { FeatureStates } from 'src/store/selectors/features';
import { Sector } from 'src/store/types';
import { SectionField } from 'src/library/components/d-field-section';

export interface OrganizationEditItem {
  notes: string;
  email: string;
  url: string;
  fax: string;
  phone: string;
  mailAddress: string;
  officeAddress: string;
  herNumber: string;
  organizationNumber: string;
  businessEntityType: string;
  name: string;
  activityCodes: string[];
  sector: Sector;
}

/**
 *
 */
@customElement('d-organization-edit')
export class DOrganizationEdit extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;
  @property({ type: Object })
  token = {};
  @property({ type: Object })
  editItem!: OrganizationEditItem;
  @property({ type: Array })
  activityCodes = [];
  @property({ type: Object })
  featureStates!: FeatureStates;
  @property({ type: Number })
  contentStickyTop = 0;

  protected onFieldSectionChanged(e) {
    this.editItem = e.detail.value;
    this.fireEditItemChanged();
  }

  protected onFieldAction(e) {
    this[e.detail.value]();
  }

  private editFields(): SectionField[] {
    return [
      {
        field: 'name',
        type: 'edit-text',
        class: 'minWidth300',
      },
      {
        field: 'businessEntityType',
        type: 'edit-dropdown',
        options: businessEntityTypeOptions,
        placeholder: 'Velg',
        class: 'minWidth300',
      },
      {
        field: 'sector',
        type: 'edit-dropdown',
        options: sectorOptions,
        class: 'minWidth300',
      },
      {
        type: 'expansion',
        opened: this.editItem.sector === 'HEALTH',
        condition: this.featureStates.core,
        fields: [
          {
            type: 'custom',
            render: () => this.renderEditActivityCodes(),
          },
        ],
      },
      {
        field: 'organizationNumber',
        type: 'edit-text',
        class: 'minWidth300',
        condition: this.featureStates.core,
      },
      {
        field: 'herNumber',
        type: 'edit-text',
        class: 'minWidth300',
        condition: this.featureStates.core && this.editItem.sector === 'HEALTH',
      },
      {
        field: 'phone',
        type: 'edit-text',
        class: 'minWidth200',
        condition: this.featureStates.core,
      },
      {
        field: 'url',
        type: 'edit-text',
        class: 'minWidth200',
        condition: this.featureStates.core,
      },
      {
        field: 'email',
        type: 'edit-text',
        class: 'minWidth200',
        condition: this.featureStates.core,
      },
      {
        type: 'break',
      },
      {
        field: 'officeAddress',
        type: 'edit-textarea',
        class: 'minWidth300',
        condition: this.featureStates.core,
      },
      {
        field: 'mailAddress',
        type: 'edit-textarea',
        class: 'minWidth300',
        condition: this.featureStates.core,
      },
      {
        type: 'break',
      },
      {
        field: 'notes',
        type: 'edit-html',
        condition: this.featureStates.core,
      },
    ];
  }

  renderEditActivityCodes(): TemplateResult<1> {
    return html`
      <div id="codeContent" class="fullWidth">
        <d-label field="organization_activityCodes"></d-label>
        <d-activity-codes-container
          theme-page
          .codes=${activityCodesAvailable()}
          .commonActivityCodes=${activityCodesCommon()}
          .activityCodes=${this.editItem.activityCodes}
          @activity-codes-changed=${(e: CustomEvent<{ codes: string[] }>) => {
            e.stopPropagation();
            this.editItem = { ...this.editItem, activityCodes: e.detail.codes };
            this.fireEditItemChanged();
          }}
        ></d-activity-codes-container>
      </div>
    `;
  }

  render(): TemplateResult<1> {
    return html`
      <d-field-section
        .entityType=${'organization'}
        .fields=${this.editFields()}
        .item=${this.editItem}
        theme-page
        @item-changed=${(e) => this.onFieldSectionChanged(e)}
        @field-action=${(e) => this.onFieldAction(e)}
      ></d-field-section>
    `;
  }

  private fireEditItemChanged() {
    this.dispatchEvent(
      new CustomEvent<OrganizationEditItem>('edit-item-changed', {
        composed: true,
        bubbles: true,
        detail: this.editItem,
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-edit': DOrganizationEdit;
  }
}
