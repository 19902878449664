import type { State } from '../types.js';
import { toList } from './utilities.js';
import type { RiskAssessmentViewModel } from '../api';
import { createSelector } from '@reduxjs/toolkit';
import { getOrganization } from './organization.js';

export function riskAssessmentsNotDeleted(state: State): RiskAssessmentViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.riskAssessmentsById).filter((e) => !e.deleted);
}

export function riskAssessmentsDeleted(state: State): RiskAssessmentViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.riskAssessmentsById).filter((e) => e.deleted);
}

export const riskAssessmentsNotDeletedWithPrefix = createSelector(
  riskAssessmentsNotDeleted,
  getOrganization,
  function (riskAssessments, organization) {
    return riskAssessments.map(function (item) {
      let name = item.name;
      if (item.relatedSubstance && organization) {
        const substancesByIdElement = organization.substancesById[item.relatedSubstance];
        if (substancesByIdElement !== undefined) {
          name = substancesByIdElement.name + ': ' + item.name;
        }
      }
      return Object.assign({}, item, { name: name });
    });
  },
);

export function toRiskAssessmentViewModel(item: RiskAssessmentViewModel): RiskAssessmentViewModel {
  return Object.assign({}, item);
}
