import { css, html, LitElement } from 'lit';
import '../../library/elements/d-label.js';
import { customElement, property } from 'lit/decorators.js';
import type { SelectCheckboxOption } from 'src/library/editors/elements/d-select-checkbox.js';

/**
 * Liste med relatert innhold?
 *
 *
 */
@customElement('d-view-checklist')
export class DViewChecklist extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .label {
      color: hsl(1, 0%, 40%);
      font-weight: bold;
      margin-bottom: 4px;
    }

    .item {
      padding: 4px 0 4px 26px;
      background: url('/images/x-fat-orange.svg') 0 3px no-repeat;
    }

    .item:before {
      content: 'Ikke oppfylt: ';
    }

    .checked {
      background: url('/images/check-mini-blue.svg') 0 3px no-repeat;
    }

    .checked:before {
      content: '';
    }

    .items d-label {
      display: block;
    }
  `;
  /**
   * Type entity. Brukes for oppslag mot fields sammen med id i options
   */
  @property({ type: String })
  entity = '';
  /**
   * Field for prompt
   */
  @property({ type: String })
  field = '';
  @property({ type: String })
  label = '';
  /**
   * liste med entities som kan være valgt
   */
  @property({ type: Array })
  options: SelectCheckboxOption[] = [];
  /**
   * liste med id for elementene som er checked. Id brukes for oppslag mot fields.
   */
  @property({ type: Array })
  checked: string[] = [];

  _list() {
    if (this.options === undefined) {
      return [];
    }
    return this.options.map((o) => ({
      id: o.value,
      value: o.text,
      checked: this.checked.includes(o.value),
    }));
  }

  _computeClasses(checked) {
    let classes = 'checkpoint';
    if (checked) {
      classes += ' checked';
    }
    return classes;
  }

  render() {
    return html`
      <div class="sectionElementInner">
        <d-label field="${this.field}" label="${this.label}"></d-label>

        <div class="items">
          ${this._list().map(
            (item) =>
              html`<d-label
                allow-wrap
                classes="${this._computeClasses(item.checked)}"
                field="${this.entity}_${item.id}"
              ></d-label>`,
          )}
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-checklist': DViewChecklist;
  }
}
