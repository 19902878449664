import { currentEmployeeUuid } from './organization-employees.js';
import type { EventOccurrenceViewModel } from '../api';
import { createSelector } from '@reduxjs/toolkit';
import { getOrganization } from 'src/store';
import { groupBy, sortBy } from 'lodash';
export const eventOccurrencesNotDeleted = createSelector(
  getOrganization,
  (organization): EventOccurrenceViewModel[] => {
    if (organization === undefined) return [];
    return organization.eventOccurrences ?? [];
  },
);

export interface EventModel {
  name: string;
  eventOccurrences: EventOccurrenceViewModel[];
}

function toEventModel(occurrences: EventOccurrenceViewModel[]) {
  return {
    name: occurrences[0].name ?? '',
    eventOccurrences: sortBy(occurrences, ['date']),
  };
}

export const events = createSelector(eventOccurrencesNotDeleted, currentEmployeeUuid, function (occurrences) {
  const unnamedEvents: { name: string; eventOccurrences: EventOccurrenceViewModel[] }[] = occurrences
    .filter((o) => o.name === '')
    .map((o) => {
      return {
        name: '',
        eventOccurrences: [o],
      };
    });
  const namedOccurrences = occurrences.filter((o) => o.name !== '');
  const groupedOccurrences = groupBy(namedOccurrences, (e) => e.name);
  const namedEvents: { name: string; eventOccurrences: EventOccurrenceViewModel[] }[] = Object.entries(
    groupedOccurrences,
  ).map((group) => toEventModel(group[1]));
  const allEvents = unnamedEvents.concat(namedEvents);
  return sortBy(allEvents, [(e) => e.name.toLowerCase()]);
});
