import { css, html, LitElement, PropertyValues } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

/**
 *
 *
 * STATUS OK
 */
@customElement('d-smooth-resize')
class DSmoothResize extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      overflow: clip;
      transition: all 0.3s;
    }
    #container {
      margin: -1px 0 0 0;
      border: 1px solid transparent;
    }
  `;

  @query('#container')
  container!: HTMLElement;
  @property({ type: Boolean })
  disabled = false;
  private debounceTimer: NodeJS.Timeout = setTimeout(() => this.onResize(), 10);
  private observer = new ResizeObserver(() => {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => this.onResize(), 10);
  });

  render() {
    return html` <div id="container"><slot></slot></div> `;
  }

  onResize() {
    if (!this.disabled) {
      this.style.height = this.container.offsetHeight - 2 + 'px';
    }
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.observer.observe(this.container);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-smooth-resize': DSmoothResize;
  }
}
