import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../library/elements/d-label.js';
import '../../../../library/editors/components/d-list-dropdowns-or-add.js';
import './d-edit-data-item-accounting.js';
import './d-edit-data-items-email-account.js';
import './d-edit-data-items.js';
import './d-edit-data-items-application.js';
import type {
  InfosecAsset,
  InfosecCategory,
  InfosecDataItem,
  InfosecDataType,
  InfosecStorageUnit,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { getComputerName, responsiveFormStyles } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { ResponsiveContainer } from 'src/library/elements/responsive-container.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { AccountingDataItem } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-accounting.js';
import type { DataItemLocationChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-location.js';
import type { DataItemComputerChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-computer.js';
import type { DataItemRemoteServerChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-remote-server.js';
import { uuid } from 'src/utilities/text.js';
import type {
  AddDataItemApplication,
  ApplicationDataItem,
  CloudServiceApplicationDataItem,
  ComputerApplicationDataItem,
  DataItemApplicationChange,
  DataItemApplicationComputerChange,
  DataItemApplicationSupplierChange,
  RemoteServerApplicationDataItem,
} from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-application.js';
import type { NewNameResult } from 'src/library/editors/components/new-name-dialog.js';
import { NewNameDialog } from 'src/library/editors/components/new-name-dialog.js';
import {
  NewComputerDialog,
  NewComputerResult,
} from 'src/pages/computers-page/infosec-procedure/editors/new-computer-dialog.js';

/**
 *
 * Displays the categories of the given data type.
 *
 *
 *
 */
@customElement('d-data-items')
export class DDataItems extends ResponsiveContainer {
  static readonly styles = [
    responsiveFormStyles,
    css`
      :host {
        display: block;
      }

      .flex {
        display: flex;
      }

      d-edit-data-item-accounting {
        display: block;
        width: calc(100% - 58px);
        box-sizing: border-box;
        border: 1px solid var(--borderColorOnGray);
        border-radius: 12px;
        background-color: var(--map-element-background-color);
        padding: 8px 12px 4px 12px;
      }
    `,
  ];

  /**
   * The data type to display
   */
  @property({ type: Object })
  dataType!: InfosecDataType;

  /**
   * All data items for the data type.
   *
   */
  @property({ type: Array })
  dataItems: InfosecDataItem[] = [];

  /**
   * The available cloud services
   */
  @property({ type: Array })
  cloudServices: InfosecStorageUnit[] = [];

  /**
   * The available remote servers
   */
  @property({ type: Array })
  remoteServers: InfosecStorageUnit[] = [];

  /**
   * The available computers
   */
  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];

  /**
   * The available assets
   *
   */
  @property({ type: Array })
  assets: InfosecAsset[] = [];

  /**
   * The available locations
   */
  @property({ type: Array })
  locations: InfosecStorageUnit[] = [];

  /**
   * The available partners.
   */
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];

  _capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  _newItem(category): InfosecDataItem {
    return {
      uuid: '',
      name: '',
      dataType: this.dataType.type,
      category: category,
      personalData: false,
      dataProcessor: '',
      application: '',
      storageUnit: '',
      storageUnitType: '',
      storageLocation: '',
      accessingEmployees: [],
      accessingPartners: [],
      assets: [],
      backupFrequency: '',
      backupResponsible: '',
      noBackupReason: '',
      createdDateTime: '',
    };
  }

  _dataItemsForCategory(category) {
    return this.dataItems.filter((dataItem) => {
      return dataItem.dataType === this.dataType.type && dataItem.category === category;
    });
  }

  _removeAccountingDataItem(uuid) {
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; dataType: string; category: string }>('data-items-removed', {
        bubbles: true,
        composed: true,
        detail: { uuid, dataType: 'managementData', category: 'accounting' },
      }),
    );
  }

  render() {
    return html`
      <table>
        ${this.dataType.categories.map((category) => {
          return this.renderCategory(category);
        })}
      </table>
    `;
  }

  private renderCategoryEditor(category: InfosecCategory) {
    const items = this._dataItemsForCategory(category.category);

    switch (category.category) {
      case 'accounting':
        return this.renderAccountingEditor(category);
      case 'emailAccounts':
        return this.renderEmailAccountEditor(category);
      case 'paperDocuments':
        return this.renderPaperDocuments(category, items);
      case 'remoteDocuments':
        return this.renderRemoteDocuments(category, items);
      case 'localDocuments':
        return this.renderLocalDocuments(category, items);
      case 'assetData':
        return this.renderAssetData(category);
      default:
        return this.renderApplications(category, items);
    }
  }

  private renderCategory(category: InfosecCategory) {
    return html`
      <tr>
        <td>
          <d-label .label=${this._capitalize(category.label)} .field=${category.field}></d-label>
        </td>
        <td>
          <d-label .label=${this._capitalize(category.label)} .field=${category.field}></d-label>
          <div class="editor">${this.renderCategoryEditor(category)}</div>
        </td>
      </tr>
    `;
  }

  private renderEmailAccountEditor(category: InfosecCategory) {
    return html`
      <d-edit-data-items-email-account
        .dataType=${this.dataType}
        .category=${category}
        .dataItems=${this._dataItemsForCategory(category.category)}
      ></d-edit-data-items-email-account>
    `;
  }

  private renderAccountingEditor(category: InfosecCategory) {
    const a = this._dataItemsForCategory(category.category);
    let item: AccountingDataItem | undefined = undefined;
    let uuid: string | undefined = undefined;
    if (a.length > 0) {
      const a0 = a[0];
      uuid = a0.uuid;
      if (a0.storageUnitType === 'cloudServices') {
        item = { type: 'cloudServices', application: a0.application };
      } else if (a0.storageUnitType === 'computers') {
        item = { type: 'computers', computerUuid: a0.storageUnit };
      } else {
        item = { type: 'partners', supplierUuid: a0.dataProcessor };
      }
    }
    return html`
      <div class="flex">
        <d-edit-data-item-accounting
          light-label
          small-label
          .dataType=${this.dataType}
          .category=${category}
          .dataItem=${item}
          .cloudServices=${this.cloudServices}
          .computers=${this.computers}
          .partners=${this.partners}
        ></d-edit-data-item-accounting>
        <d-add-remove-buttons
          .showRemove=${item !== undefined}
          @remove=${() => this._removeAccountingDataItem(uuid)}
        ></d-add-remove-buttons>
      </div>
    `;
  }

  private renderAssetData(category: InfosecCategory) {
    return html`
      <d-edit-data-items
        .dataType=${this.dataType}
        .category=${category}
        .dataItems=${this._dataItemsForCategory(category.category)}
        .cloudServices=${this.cloudServices}
        .remoteServers=${this.remoteServers}
        .computers=${this.computers}
        .locations=${this.locations}
        .assets=${this.assets}
        .partners=${this.partners}
      ></d-edit-data-items>
    `;
  }

  private async onLocationSelected(e: CustomEvent<{ value: string }>, category: InfosecCategory) {
    if (e.detail.value === 'NEW') {
      const result: NewNameResult = await NewNameDialog.open({ title: 'Nytt oppbevaringsssted' });
      if (result.action === 'done') {
        this.dispatchEvent(
          new CustomEvent<DataItemLocationChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              newLocation: true,
              newLocationName: result.name,
              dataType: this.dataType.type,
              category: category.category,
            },
          }),
        );
      }
    } else {
      this.dispatchEvent(
        new CustomEvent<DataItemLocationChange>('data-item-changed', {
          composed: true,
          bubbles: true,
          detail: {
            newLocation: false,
            newLocationName: e.detail.value,
            dataType: this.dataType.type,
            category: category.category,
          },
        }),
      );
    }
  }

  private renderPaperDocuments(category: InfosecCategory, items: InfosecDataItem[]) {
    const options = this.locations.map((location) => {
      return {
        value: location.uuid,
        text: location.name,
      };
    });
    const value = items.map((i) => {
      const o = options.find((option) => option.value === i.storageLocation);
      return {
        value: i.uuid,
        text: o?.text || 'ukjent',
      };
    });
    const usedOptions = value.map((v) => v.text);
    const availableOptions = options.filter((o) => !usedOptions.includes(o.value));

    return html`
      <d-list-dropdowns-or-add
        theme-page
        .label=${''}
        placeholder="Velg oppbevaringssted"
        unselectablePlaceholder
        first-item-text="Registrer oppbevaringssted"
        additional-item-text="Annet oppbevaringssted"
        .options=${availableOptions}
        .value=${value}
        @item-selected=${async (e) => {
          await this.onLocationSelected(e, category);
        }}
        @item-removed=${(e) => {
          this.dispatchEvent(
            new CustomEvent<{ uuid: string; dataType: string; category: string }>('data-items-removed', {
              bubbles: true,
              composed: true,
              detail: { uuid: e.detail.value.value, dataType: this.dataType.type, category: category.category },
            }),
          );
        }}
      ></d-list-dropdowns-or-add>
    `;
  }

  private async onSupplierSelected(e: CustomEvent<{ value: string }>, category: InfosecCategory) {
    if (e.detail.value === 'NEW') {
      const result: NewNameResult = await NewNameDialog.open({ title: 'Ny samarbeidspartner' });
      if (result.action === 'done') {
        this.dispatchEvent(
          new CustomEvent<DataItemRemoteServerChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              newSupplier: true,
              newSupplierName: result.name,
              supplierUuid: uuid(),
              dataType: this.dataType.type,
              category: category.category,
            },
          }),
        );
      }
    } else {
      this.dispatchEvent(
        new CustomEvent<DataItemRemoteServerChange>('data-item-changed', {
          composed: true,
          bubbles: true,
          detail: {
            newSupplier: false,
            supplierUuid: e.detail.value,
            dataType: this.dataType.type,
            category: category.category,
          },
        }),
      );
    }
  }

  private renderRemoteDocuments(category: InfosecCategory, items: InfosecDataItem[]) {
    const options = [...this.partners];
    const value = items.map((i) => {
      const r = this.remoteServers.find((x) => x.uuid === i.storageUnit)?.partnerUuid ?? '';
      const o = options.find((option) => option.value === r);
      return {
        value: i.uuid,
        text: o?.text || 'ukjent',
      };
    });
    const usedOptions = items.map((i) => {
      return this.remoteServers.find((x) => x.uuid === i.storageUnit)?.partnerUuid ?? '';
    });
    const availableOptions = options.filter((o) => !usedOptions.includes(o.value));

    return html`
      <d-list-dropdowns-or-add
        theme-page
        .label=${''}
        placeholder="Velg leverandør av fjernserver"
        unselectablePlaceholder
        first-item-text="Registrer leverandør av fjernserver"
        additional-item-text="Annen leverandør"
        .options=${availableOptions}
        .value=${value}
        @item-selected=${async (e) => {
          await this.onSupplierSelected(e, category);
        }}
        @item-removed=${(e) => {
          this.dispatchEvent(
            new CustomEvent<{ uuid: string; dataType: string; category: string }>('data-items-removed', {
              bubbles: true,
              composed: true,
              detail: { uuid: e.detail.value.value, dataType: this.dataType.type, category: category.category },
            }),
          );
        }}
      ></d-list-dropdowns-or-add>
    `;
  }

  private renderApplications(category: InfosecCategory, items: InfosecDataItem[]) {
    const applicationDataItems: ApplicationDataItem[] = items
      .filter((i) => i.storageUnitType !== '')
      .filter((i) => i.application !== null)
      .map((i) => {
        const b = {
          uuid: i.uuid,
          application: i.application,
        };

        switch (i.storageUnitType) {
          case 'computers':
            return {
              ...b,
              platform: 'computers',
              computerUuid: i.storageUnit,
            } as ComputerApplicationDataItem;
          case 'remoteServers':
            return {
              ...b,
              platform: 'remoteServers',
              supplierUuid: this.remoteServers.find((s) => s.uuid === i.storageUnit)?.partnerUuid ?? '',
            } as RemoteServerApplicationDataItem;
          case 'cloudServices':
            return {
              ...b,
              platform: 'cloudServices',
              supplierUuid: this.cloudServices.find((s) => s.uuid === i.storageUnit)?.partnerUuid ?? '',
            } as CloudServiceApplicationDataItem;
          default:
            throw new Error('Illegal state (E431), unexpected storage unit type ' + JSON.stringify(i));
        }
      });

    return html` <d-edit-data-items-application
      theme-page
      .label=${''}
      .value=${applicationDataItems}
      .category=${category}
      .partners=${this.partners}
      .computers=${this.computers.map((c) => ({ value: c.uuid, text: getComputerName(c) }))}
      @item-removed=${(e) => {
        this.dispatchEvent(
          new CustomEvent<{ uuid: string; dataType: string; category: string }>('data-items-removed', {
            bubbles: true,
            composed: true,
            detail: { uuid: e.detail.value.uuid, dataType: this.dataType.type, category: category.category },
          }),
        );
      }}
      @add-data-item=${(e: CustomEvent<AddDataItemApplication>) => {
        switch (e.detail.storageUnitType) {
          case 'computers': {
            const c: DataItemApplicationComputerChange = e.detail.newComputer
              ? {
                  newComputer: e.detail.newComputer,
                  newComputerName: e.detail.newComputerName ?? '',
                  newComputerType: e.detail.newComputerType ?? '',
                }
              : { newComputer: e.detail.newComputer, computerUuid: e.detail.computerUuid };
            this.dispatchEvent(
              new CustomEvent<DataItemApplicationChange>('data-item-changed', {
                composed: true,
                bubbles: true,
                detail: {
                  application: e.detail.application,

                  storageUnitType: 'computers',

                  ...c,
                  dataType: this.dataType.type,
                  category: category.category,
                },
              }),
            );
            break;
          }
          case 'cloudServices':
          case 'remoteServers': {
            const s: DataItemApplicationSupplierChange = e.detail.newSupplier
              ? { newSupplier: true, newSupplierName: e.detail.newSupplierName, supplierUuid: e.detail.supplierUuid }
              : { newSupplier: false, supplierUuid: e.detail.supplierUuid };
            this.dispatchEvent(
              new CustomEvent<DataItemApplicationChange>('data-item-changed', {
                composed: true,
                bubbles: true,
                detail: {
                  application: e.detail.application,

                  storageUnitType: e.detail.storageUnitType,

                  dataType: this.dataType.type,
                  category: category.category,
                  ...s,
                },
              }),
            );
          }
        }
      }}
    ></d-edit-data-items-application>`;
  }

  private async onComputerSelected(e: CustomEvent<{ value: string }>, category: InfosecCategory) {
    if (e.detail.value === 'NEW') {
      const result: NewComputerResult = await NewComputerDialog.open({
        name: '',
        requireNameForServers: this.computers.filter((c) => c.computerType === 'Server').length !== 0,
      });
      if (result.action === 'done') {
        this.dispatchEvent(
          new CustomEvent<DataItemComputerChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              newComputer: true,
              newComputerName: result.name,
              newComputerType: result.type,
              computerUuid: uuid(),
              dataType: this.dataType.type,
              category: category.category,
            },
          }),
        );
      }
    } else {
      this.dispatchEvent(
        new CustomEvent<DataItemComputerChange>('data-item-changed', {
          composed: true,
          bubbles: true,
          detail: {
            newComputer: false,
            computerUuid: e.detail.value,
            dataType: this.dataType.type,
            category: category.category,
          },
        }),
      );
    }
  }

  private renderLocalDocuments(category: InfosecCategory, items: InfosecDataItem[]) {
    const options = this.computers.map((computer) => {
      return {
        value: computer.uuid,
        text: computer.name,
      };
    });
    const value = items.map((i) => {
      const o = options.find((option) => option.value === i.storageUnit);
      return {
        value: i.uuid,
        text: o?.text || 'ukjent',
      };
    });
    const usedOptions = items.map((v) => v.storageUnit);
    const availableOptions = options.filter((o) => !usedOptions.includes(o.value));
    return html`
      <d-list-dropdowns-or-add
        theme-page
        .label=${''}
        placeholder="Velg datamaskin"
        first-item-text="Registrer datamaskin"
        additional-item-text="Annen datamaskin"
        unselectablePlaceholder
        .options=${availableOptions}
        .value=${value}
        @item-selected=${(e) => this.onComputerSelected(e, category)}
        @item-removed=${(e) => {
          this.dispatchEvent(
            new CustomEvent<{ uuid: string; dataType: string; category: string }>('data-items-removed', {
              bubbles: true,
              composed: true,
              detail: { uuid: e.detail.value.value, dataType: this.dataType.type, category: category.category },
            }),
          );
        }}
      ></d-list-dropdowns-or-add>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-data-items': DDataItems;
  }
}
