export enum DayOfWeek {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}

export const DayOfWeekValues = [
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
  DayOfWeek.SATURDAY,
  DayOfWeek.SUNDAY,
];

/**
 * The number of days in a 400 year cycle.
 */
const DAYS_PER_CYCLE = 146097;

/**
 * The number of days from year zero to year 1970.
 * There are five 400 year cycles from year zero to 2000.
 * There are 7 leap years from 1970 to 2000.
 */
const DAYS_0000_TO_1970 = DAYS_PER_CYCLE * 5 - (30 * 365 + 7);

/**
 * Used instead of js-joda LocalDate because we need to avoid all the checks and validations when
 * creating recurrence instances since we need the speed and we know the source.
 */
export class LocalDate {
  public static useFixedClockAt: LocalDate;
  private static HASH_MULTIPLIER = 31;
  private static DAYS_PER_MONTH: number[] = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  private static PARSED_DATE_FIELDS = 3;
  private static RADIX = 10;
  private static MIN_MONTH_VALUE = 1;
  private static MONTHS_PER_YEAR = 12;
  private static MAX_MONTH_VALUE: number = LocalDate.MONTHS_PER_YEAR;
  private static DAYS_IN_FEBRUARY_FOR_LEAP_YEAR = 29;
  private static HASH_SEED = 17;
  private static YEAR_MULTIPLIER = 10000;
  private static MONTH_MULTIPLIER = 100;
  private static DAYS_PER_WEEK = 7;
  private static MONTH_NAMES = [
    'januar',
    'februar',
    'mars',
    'april',
    'mai',
    'juni',
    'juli',
    'august',
    'september',
    'oktober',
    'november',
    'desember',
  ];
  private static epochDayInitialized = false;
  private static epochDaysLookup: { [n: number]: LocalDate } = {};
  private static minFastEpochDayIndex: number = LocalDate.of(2018, 1, 1).toEpochDay();
  private static maxFastEpochDayIndex: number = LocalDate.of(2030, 12, 31).toEpochDay();
  private readonly __month: number;
  private readonly __day: number;
  private readonly __year: number;

  public constructor(year: number, month: number, day: number) {
    if (!LocalDate.isValid(year, month, day)) {
      throw new Error('Invalid date');
    }
    this.__month = month;
    this.__day = day;
    this.__year = year;
  }

  public static of(year: number, month: number, day: number): LocalDate {
    return new LocalDate(year, month, day);
  }

  public static fromString(date?: string): LocalDate {
    if (date === undefined || date === null || date === '') {
      return LocalDate.now();
    }
    const fields: string[] = date.substring(0, 10).split('-');
    if (fields.length !== LocalDate.PARSED_DATE_FIELDS) {
      throw new Error('Date parse error "' + date + '"');
    }
    const year = LocalDate.parseNumber(fields[0]);
    const month = LocalDate.parseNumber(fields[1]);
    const day = LocalDate.parseNumber(fields[2]);
    return new LocalDate(year, month, day);
  }

  public static fromRecurrenceRuleString(date: string): LocalDate {
    const year = LocalDate.parseNumber(date.substring(0, 4));
    const month = LocalDate.parseNumber(date.substring(4, 6));
    const day = LocalDate.parseNumber(date.substring(6, 8));
    return new LocalDate(year, month, day);
  }

  public static ofEpochDay(epochDay: number): LocalDate {
    if (epochDay >= this.minFastEpochDayIndex && epochDay <= this.maxFastEpochDayIndex) {
      if (!this.epochDayInitialized) {
        this.epochDayInitialize();
      }
      return this.epochDaysLookup[epochDay];
    } else {
      return this.ofEpochDayCalculated(epochDay);
    }
  }

  /**
   * Obtains an instance of LocalDate from the epoch day count.
   *
   * This returns a LocalDate with the specified epoch-day.
   * The {@link ChronoField.EPOCH_DAY} is a elements incrementing count
   * of days where day 0 is 1970-01-01. Negative numbers represent earlier days.
   *
   * @param {number} [epochDay=0] - the Epoch Day to convert, based on the epoch 1970-01-01
   * @return {LocalDate} the local date, not null
   * @throws {AssertionError} if the epoch days exceeds the supported date range
   */
  public static ofEpochDayCalculated(epochDay: number): LocalDate {
    let adjust, adjustCycles, doyEst, yearEst, zeroDay;
    zeroDay = epochDay + DAYS_0000_TO_1970;
    zeroDay -= 60;
    adjust = 0;
    if (zeroDay < 0) {
      adjustCycles = LocalDate.intDiv(zeroDay + 1, DAYS_PER_CYCLE) - 1;
      adjust = adjustCycles * 400;
      zeroDay += -adjustCycles * DAYS_PER_CYCLE;
    }
    yearEst = LocalDate.intDiv(400 * zeroDay + 591, DAYS_PER_CYCLE);
    doyEst =
      zeroDay -
      (365 * yearEst + LocalDate.intDiv(yearEst, 4) - LocalDate.intDiv(yearEst, 100) + LocalDate.intDiv(yearEst, 400));
    if (doyEst < 0) {
      yearEst--;
      doyEst =
        zeroDay -
        (365 * yearEst +
          LocalDate.intDiv(yearEst, 4) -
          LocalDate.intDiv(yearEst, 100) +
          LocalDate.intDiv(yearEst, 400));
    }
    yearEst += adjust;
    const marchDoy0 = doyEst;
    const marchMonth0 = LocalDate.intDiv(marchDoy0 * 5 + 2, 153);
    const month = ((marchMonth0 + 2) % 12) + 1;
    const dom = marchDoy0 - LocalDate.intDiv(marchMonth0 * 306 + 5, 10) + 1;
    yearEst += LocalDate.intDiv(marchMonth0, 10);
    const year = yearEst;
    return new LocalDate(year, month, dom);
  }

  public static now(): LocalDate {
    if (this.useFixedClockAt !== undefined) {
      return this.useFixedClockAt;
    }
    const d = new Date();
    return LocalDate.fromDate(d);
  }

  public static fromDate(date: Date) {
    return LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  public static dates(start: LocalDate, endNotIncluded: LocalDate): LocalDate[] {
    const s = start.toEpochDay();
    const e = endNotIncluded.toEpochDay();

    const l: LocalDate[] = [];
    for (let n = s; n < e; n += 1) {
      l.push(LocalDate.ofEpochDay(n));
    }
    return l;
  }

  private static isValid(y: number, m: number, d: number): boolean {
    return LocalDate.isMonthValid(m) && LocalDate.isDayValid(y, m, d);
  }

  private static isMonthValid(m: number): boolean {
    return !(m < LocalDate.MIN_MONTH_VALUE || m > LocalDate.MAX_MONTH_VALUE);
  }

  private static isDayValid(y: number, m: number, d: number): boolean {
    return (
      !(d < 1 || d > LocalDate.DAYS_PER_MONTH[m]) &&
      !(m === 2 && d === LocalDate.DAYS_IN_FEBRUARY_FOR_LEAP_YEAR && !LocalDate.isLeapYear(y))
    );
  }

  private static isLeapYear(y: number): boolean {
    if (y % 400 === 0) {
      return true;
    }
    if (y % 100 === 0) {
      return false;
    }
    return y % 4 === 0;
  }

  private static intDiv(x: number, y: number): number {
    const r = x / y;
    return LocalDate.roundDown(r);
  }

  private static weeksForYear(wby: number): number {
    const date = LocalDate.of(wby, 1, 1);
    // 53 weeks if standard year starts on Thursday, or Wed in a leap year
    if (
      date.dayOfWeek() === DayOfWeek.THURSDAY ||
      (date.dayOfWeek() === DayOfWeek.WEDNESDAY && LocalDate.isLeapYear(wby))
    ) {
      return 53;
    }
    return 52;
  }

  /**
   *
   * @param {number} r
   * @returns {number}
   */
  private static roundDown(r: number): number {
    return Math.trunc(r);
  }

  /**
   *
   * @param {number} x
   * @param {number} y
   * @returns {number}
   */
  private static floorDiv(x: number, y: number): number {
    return Math.floor(x / y);
  }

  /**
   *
   * @param {number} x
   * @param {number} y
   * @returns {number}
   */
  private static floorMod(x: number, y: number): number {
    return x - LocalDate.floorDiv(x, y) * y;
  }

  /**
   *
   * @param {number} x
   * @param {number} y
   * @returns {number}
   */
  private static intMod(x: number, y: number): number {
    const r = x - LocalDate.intDiv(x, y) * y;
    return LocalDate.roundDown(r);
  }

  /**
   * Parse the string to a number, ensuring it is not NaN
   * @param s
   */
  private static parseNumber(s: string): number {
    const r = parseInt(s, LocalDate.RADIX);
    if (isNaN(r)) {
      throw new Error('Illegal state (E921), not a number ' + s);
    }
    return r;
  }

  private static epochDayInitialize(): void {
    const s = this.minFastEpochDayIndex;
    const e = this.maxFastEpochDayIndex;

    for (let i = s; i <= e; i++) {
      this.epochDaysLookup[i] = LocalDate.ofEpochDayCalculated(i);
    }
    this.epochDayInitialized = true;
  }

  public month(): number {
    return this.__month;
  }

  public day(): number {
    return this.__day;
  }

  public year(): number {
    return this.__year;
  }

  public next(): LocalDate {
    if (LocalDate.isValid(this.__year, this.__month, this.__day + 1)) {
      return new LocalDate(this.__year, this.__month, this.__day + 1);
    } else if (LocalDate.isValid(this.__year, this.__month + 1, 1)) {
      return new LocalDate(this.__year, this.__month + 1, 1);
    } else {
      return new LocalDate(this.__year + 1, 1, 1);
    }
  }

  public isAfter(b: LocalDate): boolean {
    return this.compareTo(b) > 0;
  }

  public isBefore(b: LocalDate): boolean {
    return this.compareTo(b) < 0;
  }

  public isSameOrAfter(b: LocalDate): boolean {
    return this.compareTo(b) >= 0;
  }

  public isSameOrBefore(b: LocalDate): boolean {
    return this.compareTo(b) <= 0;
  }

  public isSame(b: LocalDate): boolean {
    return this.compareTo(b) === 0;
  }

  public compareTo(that: LocalDate): number {
    const thisAsInteger = this.asInteger();
    const thatAsInteger = that.asInteger();
    if (thisAsInteger < thatAsInteger) {
      return -1;
    }
    if (thisAsInteger > thatAsInteger) {
      return +1;
    }
    return 0;
  }

  /**
   * Outputs this date as a String, such as 03.12.2007.
   *
   * @return {string} a string representation of this date, not null
   */
  public toStringForDisplay(): string {
    const yearString = this.yearToString();
    const monthString = this.monthToString();
    const dayString = this.dayToString();

    return dayString + '.' + monthString + '.' + yearString;
  }

  /**
   * Outputs this date as a String, such as 03.12.2007.
   *
   * 'dddd, D. MMMM'
   * @return {string} a string representation of this date, not null
   */

  public toStringForDisplayWithDayOfWeek(): string {
    const dayString = '' + this.__day;
    return this.getDayOfWeekString() + ' ' + dayString + '. ' + this.nameOfMonth();
  }

  public getDayOfWeekString() {
    const weekdays = ['mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag', 'søndag'];
    return weekdays[this.dayOfWeek().valueOf()];
  }

  public toStringForDisplayWithYear(): string {
    const dayString = '' + this.__day;

    return dayString + '. ' + this.nameOfMonth() + ' ' + this.yearToString();
  }

  public toStringForDisplayWithDayOfWeekAndYear(): string {
    const weekdays = ['mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag', 'søndag'];
    const dayString = '' + this.__day;
    const dayOfWeekString = weekdays[this.dayOfWeek().valueOf()];

    return dayOfWeekString + ' ' + dayString + '. ' + this.nameOfMonth() + ' ' + this.yearToString();
  }

  public toStringForDisplayWithDayAndMonth(): string {
    const dayString = '' + this.__day;

    return dayString + '. ' + this.nameOfMonth();
  }

  /**
   * The week day ordinal. Number between 0 and 6 with monday as 0.
   */
  public weekDayOrdinal(): number {
    return this.dayOfWeek().valueOf();
  }

  public weekDayThreeLetters(): string {
    const weekdays = ['man', 'tir', 'ons', 'tor', 'fre', 'lør', 'søn'];

    return weekdays[this.dayOfWeek().valueOf()];
  }

  public weekDayOneLetter(): string {
    const weekdays = ['m', 't', 'o', 't', 'f', 'l', 's'];

    return weekdays[this.dayOfWeek().valueOf()];
  }

  public nameOfMonth(): string {
    return LocalDate.MONTH_NAMES[this.__month - 1];
  }

  public daysInMonth(): number {
    if (this.__month === 2) {
      if (LocalDate.isLeapYear(this.__year)) {
        return 29;
      }
      return 28;
    }
    return LocalDate.DAYS_PER_MONTH[this.__month];
  }

  public daysInYear(): number {
    if (LocalDate.isLeapYear(this.__year)) {
      return 366;
    }
    return 365;
  }

  /**
   * Outputs this date as a String, such as 2007-12-03.
   * The output will be in the ISO-8601 format yyyy-MM-dd.
   *
   * @return {string} a string representation of this date, not null
   */
  public toString(): string {
    const yearString = this.yearToString();
    const monthString = this.monthToString();
    const dayString = this.dayToString();

    return yearString + '-' + monthString + '-' + dayString;
  }

  /**
   * Outputs this date as a String, such as 20071203.
   * The output will be in the format yyyyMMdd.
   *
   * @return {string} a string representation of this date, not null
   */
  public toStringForRecurrenceRule(): string {
    const yearString = this.yearToString();
    const monthString = this.monthToString();
    const dayString = this.dayToString();

    return yearString + monthString + dayString;
  }

  public equals(that: LocalDate): boolean {
    if (that === this) {
      return true;
    }
    if (that === null) {
      return false;
    }
    if (that.constructor !== this.constructor) {
      return false;
    }
    return this.__month === that.__month && this.__day === that.__day && this.__year === that.__year;
  }

  public hashCode(): number {
    let hash: number = LocalDate.HASH_SEED;
    hash = LocalDate.HASH_MULTIPLIER * hash + this.__month;
    hash = LocalDate.HASH_MULTIPLIER * hash + this.__day;
    hash = LocalDate.HASH_MULTIPLIER * hash + this.__year;
    return hash;
  }

  public asInteger(): number {
    return this.__year * LocalDate.YEAR_MULTIPLIER + this.__month * LocalDate.MONTH_MULTIPLIER + this.__day;
  }

  public minusDays(daysToSubtract: number): LocalDate {
    return this.plusDays(-daysToSubtract);
  }

  public plusDays(daysToAdd: number): LocalDate {
    if (daysToAdd === 0) {
      return this;
    }
    const mjDay = this.toEpochDay() + daysToAdd;
    return LocalDate.ofEpochDay(mjDay);
  }

  public plusYears(i: number): LocalDate {
    if (LocalDate.isValid(this.__year + i, this.__month, this.__day)) {
      return new LocalDate(this.__year + i, this.__month, this.__day);
    } else {
      return new LocalDate(this.__year + i, this.__month, LocalDate.DAYS_PER_MONTH[2] - 1);
    }
  }

  public plusWeeks(frequency: number): LocalDate {
    return this.plusDays(frequency * LocalDate.DAYS_PER_WEEK);
  }

  public plusMonths(frequency: number): LocalDate {
    const targetMonth: number = this.__month + frequency;
    let addYears: number = (targetMonth / LocalDate.MONTHS_PER_YEAR) | 0;
    let m: number = targetMonth % LocalDate.MONTHS_PER_YEAR;
    if (m === 0) {
      addYears--;
      m = LocalDate.MONTHS_PER_YEAR;
    }
    const y: number = this.__year + addYears;
    let d: number = this.__day;
    let daysInMonth: number = LocalDate.DAYS_PER_MONTH[m];
    if (m === 2 && !LocalDate.isLeapYear(y)) {
      daysInMonth = 28;
    }
    if (d > daysInMonth) {
      d = daysInMonth;
    }
    return new LocalDate(y, m, d);
  }

  public toStringForDisplayWeekAndYear(): string {
    const thursdayOfWeek = this.withPreviousOrSame(DayOfWeek.MONDAY).plusDays(3);
    return this.isoWeek() + ' ' + thursdayOfWeek.yearToString();
  }

  /**
   * Converts this date to the Epoch Day.
   *
   * The Epoch Day count is a elements incrementing count of days where day 0 is 1970-01-01 (ISO).
   * This definition is the same for all chronologies, enabling conversion.
   *
   * @return the Epoch Day equivalent to this date
   */
  public toEpochDay(): number {
    const year = this.__year;
    const month = this.__month;
    let total = 0;
    total += 365 * year;
    if (year >= 0) {
      total += LocalDate.intDiv(year + 3, 4) - LocalDate.intDiv(year + 99, 100) + LocalDate.intDiv(year + 399, 400);
    } else {
      total -= LocalDate.intDiv(year, -4) - LocalDate.intDiv(year, -100) + LocalDate.intDiv(year, -400);
    }
    total += LocalDate.intDiv(367 * month - 362, 12);
    total += this.__day - 1;
    if (month > 2) {
      total--;
      if (!LocalDate.isLeapYear(year)) {
        total--;
      }
    }
    return total - DAYS_0000_TO_1970;
  }

  public withFirstInMonth(dayOfWeek: DayOfWeek): LocalDate {
    const temp = LocalDate.of(this.__year, this.__month, 1);
    const curDow = temp.dayOfWeek();
    const dowDiff = LocalDate.intMod(dayOfWeek.valueOf() - curDow.valueOf() + 7, 7);
    return temp.plusDays(dowDiff);
  }

  public withLastInMonth(dayOfWeek: DayOfWeek): LocalDate {
    const maxDaysInMonth =
      LocalDate.DAYS_PER_MONTH[this.__month] + (LocalDate.isLeapYear(this.__year) && this.__month === 2 ? 0 : -1);

    const temp = LocalDate.of(this.__year, this.__month, maxDaysInMonth);
    const curDow = temp.dayOfWeek();
    let daysDiff = dayOfWeek.valueOf() - curDow.valueOf();
    daysDiff = daysDiff === 0 ? 0 : daysDiff > 0 ? daysDiff - 7 : daysDiff;

    return temp.plusDays(daysDiff);
  }

  public withPreviousOrSame(dayOfWeek: DayOfWeek): LocalDate {
    const curDow = this.dayOfWeek();
    let daysDiff = dayOfWeek.valueOf() - curDow.valueOf();
    daysDiff = daysDiff === 0 ? 0 : daysDiff > 0 ? daysDiff - 7 : daysDiff;

    return this.plusDays(daysDiff);
  }

  public until(date: LocalDate): number {
    return date.toEpochDay() - this.toEpochDay();
  }

  public inRange(start: LocalDate, end: LocalDate) {
    return this.isSameOrAfter(start) && this.isSameOrBefore(end);
  }

  /**
   * Gets the day-of-week field, which is an enum {@link DayOfWeek}.
   *
   * This method returns the enum {@link DayOfWeek} for the day-of-week.
   * This avoids confusion as to what `int` values mean.
   * If you need access to the primitive `int` value then the enum
   * provides the {@link DayOfWeek.value} int value.
   *
   * Additional information can be obtained from the {@link DayOfWeek}.
   * This includes textual names of the values.
   *
   * @return {DayOfWeek} the day-of-week, not null
   */

  public dayOfWeek(): DayOfWeek {
    return LocalDate.floorMod(this.toEpochDay() + 3, 7);
  }

  public weekOfYear(): number {
    // current week's Thursday
    const curWeek = new LocalDate(this.__year, this.__month, this.__day)
      .withPreviousOrSame(DayOfWeek.MONDAY)
      .plusDays(3);

    // Get year's first week's Thursday
    const firstWeek = new LocalDate(this.__year, 1, 4).withPreviousOrSame(DayOfWeek.MONDAY).plusDays(3);

    const week = (curWeek.toEpochDay() - firstWeek.toEpochDay()) / 7 + 1;

    if (week == 0) {
      return LocalDate.weeksForYear(firstWeek.year() - 1);
    } else if (week == 53 && LocalDate.weeksForYear(firstWeek.year()) == 52) {
      return 1;
    } else {
      return week;
    }
  }

  public isoWeek(): number {
    const weekStart = this.plusDays(this.dayOfWeek().valueOf() * -1);
    const yearStart = LocalDate.fromString(this.year() + '-01-01');
    if (weekStart.year() !== yearStart.year() && yearStart.dayOfWeek().valueOf() < 4) {
      return yearStart.weekOfYear();
    }
    if (weekStart.plusWeeks(1).year() !== yearStart.year()) {
      const nexYearStart = yearStart.plusYears(1);
      if (nexYearStart.dayOfWeek().valueOf() > 0 && nexYearStart.dayOfWeek().valueOf() < 4) {
        return nexYearStart.weekOfYear();
      }
    }
    return weekStart.weekOfYear();
  }

  public daysOfWeekWithinYear(): number {
    const weekStart = this.plusDays(this.dayOfWeek().valueOf() * -1);
    const yearStart = LocalDate.fromString(this.year() + '-01-01');
    if (weekStart.year() !== yearStart.year()) {
      return 7 - yearStart.dayOfWeek().valueOf();
    }
    if (weekStart.plusWeeks(1).year() !== yearStart.year()) {
      const nexYearStart = yearStart.plusYears(1);
      if (nexYearStart.dayOfWeek().valueOf() > 0) {
        return nexYearStart.dayOfWeek().valueOf();
      }
    }
    return 7;
  }

  private yearToString(): string {
    let yearString;
    const yearValue = this.__year;
    const absYear = Math.abs(yearValue);

    if (absYear < 1000) {
      if (yearValue < 0) {
        yearString = '-' + ('' + (yearValue - 10000)).slice(-4);
      } else {
        yearString = ('' + (yearValue + 10000)).slice(-4);
      }
    } else if (yearValue > 9999) {
      yearString = '+' + yearValue;
    } else {
      yearString = '' + yearValue;
    }
    return yearString;
  }

  private monthToString(): string {
    return this.__month < 10 ? '0' + this.__month : '' + this.__month;
  }

  private dayToString(): string {
    return this.__day < 10 ? '0' + this.__day : '' + this.__day;
  }
}

export function mondayThisWeek(): LocalDate {
  return LocalDate.now().withPreviousOrSame(DayOfWeek.MONDAY);
}

export function compareLocalDate(a?: LocalDate, b?: LocalDate): number {
  return a !== undefined || b !== undefined ? (a === undefined ? -1 : b === undefined ? 1 : a.compareTo(b)) : 0;
}
