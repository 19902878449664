/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Update the user auth provider.
 * @export
 * @interface LinkAuthUpdateMessage
 */
export interface LinkAuthUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof LinkAuthUpdateMessage
   */
  redirect: string;
  /**
   *
   * @type {string}
   * @memberof LinkAuthUpdateMessage
   */
  provider: LinkAuthUpdateMessageProviderEnum;
}

/**
 * @export
 */
export const LinkAuthUpdateMessageProviderEnum = {
  Oidc: 'oidc',
  Vipps: 'vipps',
  Criipto: 'criipto',
  Admin: 'admin',
} as const;
export type LinkAuthUpdateMessageProviderEnum =
  (typeof LinkAuthUpdateMessageProviderEnum)[keyof typeof LinkAuthUpdateMessageProviderEnum];

/**
 * Check if a given object implements the LinkAuthUpdateMessage interface.
 */
export function instanceOfLinkAuthUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'redirect' in value;
  isInstance = isInstance && 'provider' in value;

  return isInstance;
}

export function LinkAuthUpdateMessageFromJSON(json: any): LinkAuthUpdateMessage {
  return LinkAuthUpdateMessageFromJSONTyped(json, false);
}

export function LinkAuthUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkAuthUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    redirect: json['redirect'],
    provider: json['provider'],
  };
}

export function LinkAuthUpdateMessageToJSON(value?: LinkAuthUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    redirect: value.redirect,
    provider: value.provider,
  };
}
