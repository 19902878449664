import { css, html } from 'lit';
import { DPrepareBehavior } from './d-prepare-behavior.js';
import './d-robot-header.js';
import { customElement, property } from 'lit/decorators.js';

/**
 *
 */
@customElement('d-prepare-cancel')
export class DPrepareCancel extends DPrepareBehavior {
  static readonly styles = [
    ...super.styles,
    css`
      :host {
        display: block;
        background: var(--outskirts-background-color);
        min-height: 100vh;
      }
    `,
  ];
  @property({ type: String })
  lastPage = '';
  @property({ type: String })
  ownerEmail = '';
  @property({ type: String })
  back = '';

  private get robotMessage() {
    return `Velkommen tilbake senere! Innloggingsinformasjon er sendt til ${this.ownerEmail}. Når du vil fortsette å sette opp din trinnviskonto, går du til trinnvis.no og trykker på Min TrinnVis øverst til høyre.`;
  }

  render() {
    return html`
      <d-robot-header .message=${this.robotMessage}></d-robot-header>
      <div class="outskirtsContent">
        <form id="accountConfigCancelForm">
          <div class="buttons">
            <button @click=${(e) => this._backward(e)} type="button">Fortsett utfylling</button>
            <button @click=${(e) => this.onLogOut(e)} type="button">Logg ut</button>
            <button @click=${(e) => this.onExit(e)} type="button">Avslutt, forbli innlogget</button>
          </div>
        </form>
      </div>
    `;
  }

  private onLogOut(e) {
    e.preventDefault();
    this.dispatchEvent(new CustomEvent('request-logout', { bubbles: true, composed: true }));
  }

  private onExit(e) {
    e.preventDefault();
    location.href = 'https://trinnvis.no';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-prepare-cancel': DPrepareCancel;
  }
}
