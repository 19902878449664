/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RepeatMeetingOccurrenceCommand
 */
export interface RepeatMeetingOccurrenceCommand {
  /**
   * Utgangspunktet for repetisjonen. Et eksisterende møte.
   * @type {string}
   * @memberof RepeatMeetingOccurrenceCommand
   */
  uuid: string;
  /**
   * Liste med nye datoer enten "2024-02-12" eller "2024-02-12 13:45 120". Kan inneholde tid, ellers blir møte "heldags". Dersom tid er inkludert skal også varighet inkluderes som minutter
   * @type {Array<string>}
   * @memberof RepeatMeetingOccurrenceCommand
   */
  dateTimesToAdd: Array<string>;
  /**
   * Liste med instanser som skal slettes
   * @type {Array<string>}
   * @memberof RepeatMeetingOccurrenceCommand
   */
  occurrencesToRemove: Array<string>;
}

/**
 * Check if a given object implements the RepeatMeetingOccurrenceCommand interface.
 */
export function instanceOfRepeatMeetingOccurrenceCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'dateTimesToAdd' in value;
  isInstance = isInstance && 'occurrencesToRemove' in value;

  return isInstance;
}

export function RepeatMeetingOccurrenceCommandFromJSON(json: any): RepeatMeetingOccurrenceCommand {
  return RepeatMeetingOccurrenceCommandFromJSONTyped(json, false);
}

export function RepeatMeetingOccurrenceCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RepeatMeetingOccurrenceCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    dateTimesToAdd: json['dateTimesToAdd'],
    occurrencesToRemove: json['occurrencesToRemove'],
  };
}

export function RepeatMeetingOccurrenceCommandToJSON(value?: RepeatMeetingOccurrenceCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    dateTimesToAdd: value.dateTimesToAdd,
    occurrencesToRemove: value.occurrencesToRemove,
  };
}
