/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkSchedule } from './WorkSchedule';
import { WorkScheduleFromJSON, WorkScheduleFromJSONTyped, WorkScheduleToJSON } from './WorkSchedule';

/**
 *
 * @export
 * @interface WorkSchedulesUpdateMessage
 */
export interface WorkSchedulesUpdateMessage {
  /**
   *
   * @type {Array<WorkSchedule>}
   * @memberof WorkSchedulesUpdateMessage
   */
  workSchedules: Array<WorkSchedule>;
}

/**
 * Check if a given object implements the WorkSchedulesUpdateMessage interface.
 */
export function instanceOfWorkSchedulesUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'workSchedules' in value;

  return isInstance;
}

export function WorkSchedulesUpdateMessageFromJSON(json: any): WorkSchedulesUpdateMessage {
  return WorkSchedulesUpdateMessageFromJSONTyped(json, false);
}

export function WorkSchedulesUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WorkSchedulesUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    workSchedules: (json['workSchedules'] as Array<any>).map(WorkScheduleFromJSON),
  };
}

export function WorkSchedulesUpdateMessageToJSON(value?: WorkSchedulesUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    workSchedules: (value.workSchedules as Array<any>).map(WorkScheduleToJSON),
  };
}
