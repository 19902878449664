/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateModulesAccountCommand
 */
export interface CreateModulesAccountCommand {
  /**
   *
   * @type {Array<string>}
   * @memberof CreateModulesAccountCommand
   */
  features: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateModulesAccountCommand
   */
  organizationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CreateModulesAccountCommand
   */
  organizationName?: string;
  /**
   *
   * @type {string}
   * @memberof CreateModulesAccountCommand
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CreateModulesAccountCommand
   */
  lastName: string;
}

/**
 * Check if a given object implements the CreateModulesAccountCommand interface.
 */
export function instanceOfCreateModulesAccountCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'features' in value;
  isInstance = isInstance && 'firstName' in value;
  isInstance = isInstance && 'lastName' in value;

  return isInstance;
}

export function CreateModulesAccountCommandFromJSON(json: any): CreateModulesAccountCommand {
  return CreateModulesAccountCommandFromJSONTyped(json, false);
}

export function CreateModulesAccountCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateModulesAccountCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    features: json['features'],
    organizationNumber: !exists(json, 'organizationNumber') ? undefined : json['organizationNumber'],
    organizationName: !exists(json, 'organizationName') ? undefined : json['organizationName'],
    firstName: json['firstName'],
    lastName: json['lastName'],
  };
}

export function CreateModulesAccountCommandToJSON(value?: CreateModulesAccountCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    features: value.features,
    organizationNumber: value.organizationNumber,
    organizationName: value.organizationName,
    firstName: value.firstName,
    lastName: value.lastName,
  };
}
