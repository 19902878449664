import { css, html, LitElement, nothing } from 'lit';
import '../../library/fields/d-view-text';
import '../../library/fields/d-view-html';
import '../../library/lists/d-list-section';
import '../../library/lists/d-list-section-item';
import '../../library/elements/d-section';
import '../../library/components/d-field-section';
import { customElement, property } from 'lit/decorators.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities';
import type { FeatureStates } from 'src/store/selectors/features';
import { Sector } from 'src/store/types';
import { SectionField } from 'src/library/components/d-field-section';
import { sectorOptions } from 'src/store';
import { SingleEmployee } from 'src/pages/organization-page/organization-page-view-model';

export interface OrganizationView {
  sector: Sector;
  url?: string;
  notes?: string;
  email?: string;
  fax?: string;
  phone?: string;
  mailAddress?: string;
  officeAddress?: string;
  herNumber?: string;
  organizationNumber?: string;
  businessEntityType?: string;
  activityCodes: string[];
  sectorDisplayText: string;
  activityCodesDisplayText: string;
  name: string;
}

/**
 *
 */
@customElement('d-organization-view')
export class DOrganizationView extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    d-list-section {
      margin-bottom: 6px;
    }
  `;
  @property({ type: Number })
  pageId = 104;
  @property({ type: Object })
  organization!: OrganizationView;
  @property({ type: Boolean })
  hideHerId = false;
  @property({ type: Boolean })
  hideActivityCodes = false;
  @property({ type: Boolean })
  hideProfessions = false;
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Array })
  constitutionalDocuments: ListSectionItemInput[] = [];
  @property({ type: Object })
  singleEmployee: SingleEmployee | undefined = undefined;
  @property({ type: Array })
  partners: ListSectionItemInput[] = [];
  @property({ type: Array })
  functions: any[] = [];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: Object })
  featureStates!: FeatureStates;
  employeeListConfig = {
    sortBy: 'name',
    filter: 'status,TERMINATED,Sluttet',
    columns: 'name',
    columnWidths: '200',
    options: '',
    labels: {
      profession: 'yrke',
      associationType: 'tilknytning',
    },
  };

  private singleEmployeeValue() {
    if (this.singleEmployee) {
      return this.singleEmployee.profession + ' ' + this.singleEmployee.name;
    }
    return '';
  }

  private viewFields(): SectionField[] {
    return [
      {
        field: 'sector',
        type: 'view-text',
        options: sectorOptions,
        class: 'minWidth300',
      },
      {
        field: 'activityCodesDisplayText',
        fieldForLabel: 'activityCodes',
        type: 'view-text',
        class: 'minWidth300',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: 'businessEntityType',
        type: 'view-text',
        class: 'minWidth300',
        noValueValue: 'Ikke angitt',
      },
      {
        field: 'organizationNumber',
        type: 'view-text',
        class: 'minWidth300',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: 'herNumber',
        type: 'view-text',
        class: 'minWidth300',
        hideIfEmpty: true,
        condition: this.featureStates.core && this.organization.sector === 'HEALTH',
      },
      {
        field: 'phone',
        type: 'view-text',
        class: 'minWidth200',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: 'url',
        type: 'view-text',
        class: 'minWidth200',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: 'email',
        type: 'view-text',
        class: 'minWidth200',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        type: 'break',
      },
      {
        field: 'officeAddress',
        type: 'view-text',
        class: 'minWidth300',
        noWrapLabel: true,
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: 'mailAddress',
        type: 'view-text',
        class: 'minWidth300',
        noWrapLabel: true,
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        type: 'break',
      },
      {
        field: '',
        label: this.singleEmployee ? this.singleEmployee.associationType : '',
        value: () => this.singleEmployeeValue(),
        type: 'view-text',
        class: 'fullWidth',
        action: {
          name: 'Mer om meg',
          href: this.singleEmployee ? this.singleEmployee.href : '',
        },
        condition: this.singleUserVersion,
      },
      {
        field: 'notes',
        type: 'view-html',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
    ];
  }

  render() {
    return html`
      <d-field-section
        .entityType=${'organization'}
        .fields=${this.viewFields()}
        .item=${this.organization}
        theme-page
      ></d-field-section>
      ${this.featureStates.core
        ? html` <d-list-section
            field="organization_constitutionalDocuments"
            icon="constitutionalDocuments"
            write-access="${this.writeAccess}"
            .items=${this.constitutionalDocuments}
            theme-page
            .contentStickyTop=${this.contentStickyTop}
          >
          </d-list-section>`
        : nothing}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-view': DOrganizationView;
  }
}
