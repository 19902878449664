import type {
  AssetViewModel,
  CompleteOrganizationViewModel,
  ComputerViewModel,
  ConstitutionalDocumentViewModel,
  ContactPersonViewModel,
  ContractViewModel,
  DocumentViewModel,
  EmployeeViewModel,
  EventOccurrenceViewModel,
  ExternalConnectionViewModel,
  FunctionViewModel,
  GuidelineViewModel,
  IssueViewModel,
  MeetingOccurrenceViewModel,
  MeetingViewModel,
  NetworkViewModel,
  OrganizationViewModel,
  PartnerViewModel,
  PersonalDataItemViewModel,
  ReportViewModel,
  RiskAssessmentViewModel,
  SubstanceViewModel,
  TaskViewModel,
} from '../api';
import type {
  DAction,
  DeleteItemAction,
  EmployeeViewModelWithName,
  EntityViewModel,
  OrganizationState,
  State,
  UpdateItemAction,
} from '../types.js';
import { assertIsDefined } from 'src/lib';

function flatMap<T, U>(array: T[], callbackfn: (value: T, index: number, array: T[]) => U[]): U[] {
  return Array.prototype.concat(...array.map(callbackfn));
}

const mappingFunctions = {
  employees: function (e: EmployeeViewModel): EmployeeViewModelWithName {
    let name = e.firstName + ' ' + e.lastName;
    if (name.trim().length === 0) {
      name = '';
    }
    return {
      ...e,
      name: name.trim(),
      gender: e.gender ?? '',
      tutorial_state_1: e.tutorialStates?.find((t) => t.id === 1),
      tutorial_state_2: e.tutorialStates?.find((t) => t.id === 2),
    };
  },
  functions: function (e: FunctionViewModel): FunctionViewModel {
    return Object.assign({}, e, { tasks: undefined });
  },
  assets: function (e: AssetViewModel): AssetViewModel {
    return {
      ...e,
      radiation: e.radiation ?? false,
      radiationType: e.radiationType ?? 'NONE',
      dsaRegistered: e.dsaRegistered ?? false,
      dsaApproved: e.dsaApproved ?? false,
    };
  },
  substances: function (e: SubstanceViewModel): SubstanceViewModel {
    return e;
  },
  issues: function (e: IssueViewModel): IssueViewModel {
    return { ...e, relateToMaritimeHealthCertificate: e.relateToMaritimeHealthCertificate ?? false };
  },
  partners: function (e: PartnerViewModel): PartnerViewModel {
    return { ...e, contacts: undefined };
  },
  constitutionalDocuments: function (e: ConstitutionalDocumentViewModel): ConstitutionalDocumentViewModel {
    return e;
  },
  computers: function (e: ComputerViewModel): ComputerViewModel {
    return e;
  },
  contracts: function (e: ContractViewModel): ContractViewModel {
    return e;
  },
  guidelines: function (e: GuidelineViewModel): GuidelineViewModel {
    return e;
  },
  networks: function (e: NetworkViewModel): NetworkViewModel {
    return e;
  },
  documents: function (e: DocumentViewModel): DocumentViewModel {
    return e;
  },
  externalConnections: function (e: ExternalConnectionViewModel): ExternalConnectionViewModel {
    return e;
  },
  riskAssessments: function (e: RiskAssessmentViewModel): RiskAssessmentViewModel {
    return e;
  },
  reports: function (e: ReportViewModel): ReportViewModel {
    return e;
  },
  meetings: function (e: MeetingViewModel): MeetingViewModel {
    return e;
  },
  contacts: function (e: ContactPersonViewModel): ContactPersonViewModel {
    return Object.assign({}, e, {
      name: e.firstName + ' ' + e.lastName,
    });
  },
  tasks: function (e: TaskViewModel): TaskViewModel {
    return Object.assign({}, e, {
      assets: e.assets ?? [],
    });
  },
  personalDataItems: function (e: PersonalDataItemViewModel): PersonalDataItemViewModel {
    return e;
  },
};

function updateMappedItem(
  state: OrganizationState,
  entityType: string,
  entityUuid: string,
  m: { item: EntityViewModel },
  x: Record<string, unknown> = {},
): OrganizationState {
  x = x || {};
  const newState = Object.assign({}, state);
  switch (entityType) {
    case 'assets': {
      const v = Object.assign({}, state.assetsById);
      if (m.item) {
        const f = mappingFunctions.assets;
        v[entityUuid] = Object.assign(f(m.item as AssetViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.assetsById = v;
      break;
    }
    case 'substances': {
      const v = Object.assign({}, state.substancesById);
      if (m.item) {
        const f = mappingFunctions.substances;
        const s = v[entityUuid];
        if (
          s !== undefined &&
          s.uploadStatus === 'VERIFIED' &&
          'uploadStatus' in m.item &&
          m.item.uploadStatus === 'PENDING'
        ) {
          console.log('Already updated');
        } else {
          v[entityUuid] = Object.assign(f(m.item as SubstanceViewModel), x);
        }
      } else {
        delete v[entityUuid];
      }
      newState.substancesById = v;
      break;
    }
    case 'employees': {
      const v = Object.assign({}, state.employeesById);
      if (m.item) {
        const f = mappingFunctions.employees;
        v[entityUuid] = Object.assign(f(m.item as EmployeeViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.employeesById = v;
      break;
    }
    case 'functions': {
      const v = Object.assign({}, state.functionsById);
      if (m.item) {
        const f = mappingFunctions.functions;
        v[entityUuid] = Object.assign(f(m.item as FunctionViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.functionsById = v;
      break;
    }
    case 'issues': {
      const v = Object.assign({}, state.issuesById);
      if (m.item) {
        const f = mappingFunctions.issues;
        v[entityUuid] = Object.assign(f(m.item as IssueViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.issuesById = v;
      break;
    }
    case 'partners': {
      const v = Object.assign({}, state.partnersById);
      if (m.item) {
        const f = mappingFunctions.partners;
        v[entityUuid] = Object.assign(f(m.item as PartnerViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.partnersById = v;
      break;
    }
    case 'constitutionalDocuments': {
      const v = Object.assign({}, state.constitutionalDocumentsById);
      if (m.item) {
        const f = mappingFunctions.constitutionalDocuments;
        v[entityUuid] = Object.assign(f(m.item as ConstitutionalDocumentViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.constitutionalDocumentsById = v;
      break;
    }
    case 'computers': {
      const v = Object.assign({}, state.computersById);
      if (m.item) {
        const f = mappingFunctions.computers;
        v[entityUuid] = Object.assign(f(m.item as ComputerViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.computersById = v;
      break;
    }
    case 'contracts': {
      const v = Object.assign({}, state.contractsById);
      if (m.item) {
        const f = mappingFunctions.contracts;
        v[entityUuid] = Object.assign(f(m.item as ContractViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.contractsById = v;
      break;
    }
    case 'guidelines': {
      const v = Object.assign({}, state.guidelinesById);
      if (m.item) {
        const f = mappingFunctions.guidelines;
        v[entityUuid] = Object.assign(f(m.item as GuidelineViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.guidelinesById = v;
      break;
    }
    case 'networks': {
      const v = Object.assign({}, state.networksById);
      if (m.item) {
        const f = mappingFunctions.networks;
        v[entityUuid] = Object.assign(f(m.item as NetworkViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.networksById = v;
      break;
    }
    case 'documents': {
      const v = Object.assign({}, state.documentsById);
      if (m.item) {
        const f = mappingFunctions.documents;
        v[entityUuid] = Object.assign(f(m.item as DocumentViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.documentsById = v;
      break;
    }
    case 'externalConnections': {
      const v = Object.assign({}, state.externalConnectionsById);
      if (m.item) {
        const f = mappingFunctions.externalConnections;
        v[entityUuid] = Object.assign(f(m.item as ExternalConnectionViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.externalConnectionsById = v;
      break;
    }
    case 'riskAssessments': {
      const v = Object.assign({}, state.riskAssessmentsById);
      if (m.item) {
        const f = mappingFunctions.riskAssessments;
        v[entityUuid] = Object.assign(f(m.item as RiskAssessmentViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.riskAssessmentsById = v;
      break;
    }
    case 'reports': {
      const v = Object.assign({}, state.reportsById);
      if (m.item) {
        const f = mappingFunctions.reports;
        v[entityUuid] = Object.assign(f(m.item as ReportViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.reportsById = v;
      break;
    }
    case 'meetings': {
      const v = Object.assign({}, state.meetingsById);
      if (m.item) {
        const f = mappingFunctions.meetings;
        v[entityUuid] = Object.assign(f(m.item as MeetingViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.meetingsById = v;
      break;
    }
    case 'contacts': {
      const v = Object.assign({}, state.contactsById);
      if (m.item) {
        const f = mappingFunctions.contacts;
        v[entityUuid] = Object.assign(f(m.item as ContactPersonViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.contactsById = v;
      break;
    }
    case 'tasks': {
      const v = Object.assign({}, state.tasksById);
      if (m.item) {
        const f = mappingFunctions.tasks;
        v[entityUuid] = Object.assign(f(m.item as TaskViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.tasksById = v;
      break;
    }
    case 'personalDataItems': {
      const v = Object.assign({}, state.personalDataItemsById);
      if (m.item) {
        const f = mappingFunctions.personalDataItems;
        v[entityUuid] = Object.assign(f(m.item as PersonalDataItemViewModel), x);
      } else {
        delete v[entityUuid];
      }
      newState.personalDataItemsById = v;
      break;
    }
    case 'eventOccurrences': {
      const s = state.eventOccurrences.filter((z) => z.uuid !== entityUuid);
      if (m.item) {
        s.push(m.item as EventOccurrenceViewModel);
      }
      newState.eventOccurrences = s;
      break;
    }
    case 'meetingOccurrences': {
      const s = state.meetingOccurrences.filter((z) => z.uuid !== entityUuid);
      if (m.item) {
        s.push(m.item as MeetingOccurrenceViewModel);
      }
      newState.meetingOccurrences = s;
      break;
    }
  }

  return newState;
}

function updateLevelOne(p: string[], state: OrganizationState, m: { item: EntityViewModel }): OrganizationState {
  const entityType = p[3];
  const entityUuid = p[4];
  return updateMappedItem(state, entityType, entityUuid, m);
}

function updateLevelTwo(p: string[], state: OrganizationState, m: { item: EntityViewModel }): OrganizationState {
  const entityType = p[3];
  const entityUuid = p[4];
  const childEntityType = p[5];
  const childEntityUuid = p[6];

  let x = {};
  if (entityType === 'partners') {
    x = { partnerUuid: entityUuid };
  } else if (entityType === 'functions') {
    x = {};
  }

  return updateMappedItem(state, childEntityType, childEntityUuid, m, x);
}

function updateLevelZero(state: OrganizationState, m: { item: EntityViewModel }): OrganizationState {
  const o = m.item as unknown as OrganizationViewModel;
  return Object.assign({}, state, m.item, {
    tutorial_state_1: o.tutorialStates?.find((t) => t.id === 1),
  });
}

function organizationReducer(
  state: OrganizationState | undefined,
  action: UpdateItemAction,
): OrganizationState | undefined {
  if (state) {
    const m = action.message;
    const p = m.path.split('/');

    if (p.length === 3) {
      return updateLevelZero(state, m);
    } else if (p.length === 5) {
      return updateLevelOne(p, state, m);
    } else if (p.length === 7) {
      return updateLevelTwo(p, state, m);
    }
  }
  return state;
}

function organizationReducerForDelete(
  state: OrganizationState | undefined,
  action: DeleteItemAction,
): OrganizationState | undefined {
  if (state) {
    const m = action.message;
    const p = m.path.split('/');
    const entityType = p[3];

    if (p.length === 5 && entityType === 'substances') {
      const newState = { ...state };
      const entityUuid = p[4];

      const v = { ...state.substancesById };
      delete v[entityUuid];
      newState.substancesById = v;

      return newState;
    } else if (entityType === 'meetingOccurrences') {
      const newState = { ...state };
      const entityUuid = p[4];

      newState.meetingOccurrences = newState.meetingOccurrences.filter((x) => x.uuid !== entityUuid);
      return newState;
    } else if (entityType === 'eventOccurrences') {
      const newState = { ...state };
      const entityUuid = p[4];

      newState.eventOccurrences = newState.eventOccurrences.filter((x) => x.uuid !== entityUuid);
      return newState;
    }
  }
  return state;
}

interface WithUuid {
  uuid?: string;
}

function normalizeCollection<T extends WithUuid, U extends T>(
  l: T[],
  mappingFunction: (arg: T) => U,
): { [uuid: string]: U } {
  const result: { [key: string]: U } = {};
  l.forEach((item) => {
    if (item.uuid) {
      result[item.uuid] = mappingFunction(item);
    }
  });
  return result;
}

function flattenContacts(organization: CompleteOrganizationViewModel): ContactPersonViewModel[] {
  const partners = organization.partners || new Array<PartnerViewModel>();
  return flatMap(partners, function (p: PartnerViewModel): ContactPersonViewModel[] {
    const contacts = p.contacts ?? [];
    return contacts.map(function (c: ContactPersonViewModel) {
      return Object.assign({}, c, { partnerUuid: p.uuid });
    });
  });
}

function flattenTasks(organization: CompleteOrganizationViewModel): TaskViewModel[] {
  const functions: Array<FunctionViewModel> = organization.functions || new Array<FunctionViewModel>();
  return flatMap(functions, function (p: FunctionViewModel): TaskViewModel[] {
    return p.tasks ?? [];
  });
}

function normalizeOrganization(organization: CompleteOrganizationViewModel): OrganizationState {
  return {
    pending: false,
    activityCodes: organization.activityCodes ?? [],
    address: organization.address ?? '',
    businessEntityType: organization.businessEntityType ?? '',
    email: organization.email ?? '',
    fax: organization.fax ?? '',
    herNumber: organization.herNumber ?? '',
    href: organization.href ?? '',
    invoiceAddress: organization.invoiceAddress ?? '',
    invoiceLocality: organization.invoiceLocality ?? '',
    invoicePostcode: organization.invoicePostcode ?? '',
    invoiceReceiver: organization.invoiceReceiver ?? '',
    invoiceReference: organization.invoiceReference ?? '',
    invoiceSendMethod: organization.invoiceSendMethod || 'MANUAL',
    invoiceOrganizationNumber: organization.invoiceOrganizationNumber ?? '',
    template: organization.template || 'COMMON',
    mailAddress: organization.mailAddress ?? '',
    mailPostcode: organization.mailPostcode ?? '',
    name: organization.name ?? '',
    notes: organization.notes ?? '',
    officeAddress: organization.officeAddress ?? '',
    officePostcode: organization.officePostcode ?? '',
    organizationId: organization.organizationId,
    organizationNumber: organization.organizationNumber ?? '',
    ownerEmail: organization.ownerEmail ?? '',
    phone: organization.phone ?? '',
    reference: organization.reference ?? '',
    settingsHref: organization.settingsHref ?? '',
    specialTerms: organization.specialTerms ?? 'NONE',
    sector: organization.sector,
    type: organization.type ?? '',
    url: organization.url ?? '',
    vacationSummaryShared: organization.vacationSummaryShared,
    vacationSummaryRecipient: organization.vacationSummaryRecipient,
    vacationSummaryNotes: organization.vacationSummaryNotes ?? '',
    vacationSummaryEmployees: organization.vacationSummaryEmployees ?? [],
    singleUser: organization.singleUser == undefined ? false : organization.singleUser,
    staffingCalendarAccessList: organization.staffingCalendarAccessList ?? [],
    leavePeriodEditRestriction: organization.leavePeriodEditRestriction ?? false,
    employeesById: normalizeCollection(organization.employees ?? [], mappingFunctions.employees),
    functionsById: normalizeCollection(organization.functions ?? [], mappingFunctions.functions),
    assetsById: normalizeCollection(organization.assets ?? [], mappingFunctions.assets),
    substancesById: normalizeCollection(organization.substances ?? [], mappingFunctions.substances),
    personalDataItemsById: normalizeCollection(
      organization.personalDataItems ?? [],
      mappingFunctions.personalDataItems,
    ),
    computersById: normalizeCollection(organization.computers ?? [], mappingFunctions.computers),
    issuesById: normalizeCollection(organization.issues ?? [], mappingFunctions.issues),
    partnersById: normalizeCollection(organization.partners ?? [], mappingFunctions.partners),
    constitutionalDocumentsById: normalizeCollection(
      organization.constitutionalDocuments ?? [],
      mappingFunctions.constitutionalDocuments,
    ),
    contractsById: normalizeCollection(organization.contracts ?? [], mappingFunctions.contracts),
    guidelinesById: normalizeCollection(organization.guidelines ?? [], mappingFunctions.guidelines),
    networksById: normalizeCollection(organization.networks ?? [], mappingFunctions.networks),
    documentsById: normalizeCollection(organization.documents ?? [], mappingFunctions.documents),
    externalConnectionsById: normalizeCollection(
      organization.externalConnections ?? [],
      mappingFunctions.externalConnections,
    ),
    riskAssessmentsById: normalizeCollection(organization.riskAssessments ?? [], mappingFunctions.riskAssessments),
    reportsById: normalizeCollection(organization.reports ?? [], mappingFunctions.reports),
    contactsById: normalizeCollection(flattenContacts(organization), mappingFunctions.contacts),
    tasksById: normalizeCollection(flattenTasks(organization), mappingFunctions.tasks),
    meetingsById: normalizeCollection(organization.meetings ?? [], mappingFunctions.meetings),
    tutorial_1: organization.tutorials?.find((t) => t.id === 1) || {
      id: 1,
      participants: [],
      dueDate: undefined,
      comment: '',
    },
    tutorial_2: organization.tutorials?.find((t) => t.id === 2) || {
      id: 2,
      participants: [],
      dueDate: undefined,
      comment: '',
    },
    tutorial_state_1: organization.tutorialStates?.find((t) => t.id === 1),
    eventOccurrences: organization.eventOccurrences,
    meetingOccurrences: organization.meetingOccurrences,
    features: organization.features,
  };
}

export function reducer(state: State | undefined, action: DAction): State {
  console.log('Reducer: ' + action.type);
  if (state === undefined) {
    throw new Error('Illegal state (E398)');
  }
  switch (action.type) {
    case 'LOADING_ORGANIZATION':
      return { ...state, error: false, loading: true, loaded: false };

    case 'LOADED_CACHED_ORGANIZATION': {
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        error: false,
        organization: action.organization,
        id: action.id,
        token: action.token,
        username: action.username,
        key: action.key,
        user: action.user,
      });
    }
    case 'LOADED_ORGANIZATION': {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        organization: {
          ...normalizeOrganization(action.organization),
          eventOccurrences: state.organization?.eventOccurrences ?? [],
          meetingOccurrences: state.organization?.meetingOccurrences ?? [],
        },
        id: action.id,
        token: action.token,
        username: action.username,
        key: action.key,
        user: action.user,
      };
    }
    case 'LOADED_ERROR':
      return { ...state, error: true, loading: false };

    case 'UPDATE_PATH':
      return {
        ...state,
        path: action.path,
        queryParams: action.queryParams,
      };

    case 'LOADED_PAGE_GROUPS':
      return { ...state, pageGroups: action.pageGroups };

    case 'LOADED_EVENT_OCCURRENCES':
      assertIsDefined(state.organization);
      return {
        ...state,
        organization: { ...state.organization, eventOccurrences: action.eventOccurrences },
      };

    case 'LOADED_MEETING_OCCURRENCES':
      assertIsDefined(state.organization);
      return {
        ...state,
        organization: { ...state.organization, meetingOccurrences: action.meetingOccurrences },
      };

    case 'LOADED_REFERENCES':
      return { ...state, references: action.references };

    case 'UPDATE_ITEM':
      return { ...state, organization: organizationReducer(state.organization, action) };

    case 'DELETE_ITEM':
      return { ...state, organization: organizationReducerForDelete(state.organization, action) };

    case 'UPDATE_USER_PROPERTIES':
      assertIsDefined(state.user);
      return { ...state, user: { ...state.user, ...action.properties } };

    case 'UPDATE_POPUP_OPEN':
      return { ...state, popupOpen: action.popupOpen };

    case 'UPDATE_CURRENT_EDIT_LEVEL':
      return { ...state, currentEditLevel: action.currentEditLevel };

    case 'UPDATE_HELP_VIEWER_OPEN':
      return { ...state, helpViewerOpen: action.helpViewerOpen };

    case 'UPDATE_TUTORIAL_VIEWER_OPEN':
      return { ...state, tutorialViewerOpen: action.tutorialViewerOpen };

    case 'UPDATE_CURRENT_HELP_PAGE':
      return {
        ...state,
        currentHelpPage: action.currentHelpPage,
        helpViewerOpen: true,
        tutorialViewerOpen: false,
      };

    case 'UPDATE_CURRENT_TUTORIAL_ID':
      return {
        ...state,
        currentTutorialId: action.currentTutorialId,
        helpViewerOpen: false,
        tutorialViewerOpen: true,
      };

    case 'UPDATE_SELECTED_START_TASK':
      return { ...state, selectedStartTask: action.selectedStartTask };

    case 'UPDATE_CALENDAR_WEEK':
      return { ...state, weekStart: action.weekStart };

    case 'UPDATE_CALENDAR_SINGLE_USER_VIEW':
      return { ...state, singleUserView: action.singleUserView };

    case 'UPDATE_STAFFING_CALENDAR_YEAR':
      return { ...state, staffingCalendarYear: action.staffingCalendarYear };

    case 'UPDATE_USER':
      return { ...state, user: action.user };
    case 'SET_TODAY':
      return { ...state, today: action.today };
  }
  console.log('no action ');
  return state;
}
