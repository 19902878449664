import { html, LitElement, nothing, PropertyValueMap } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import '../fields/d-closer.js';
import '../elements/d-wrap.js';
import '../elements/d-action.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';

/**
 *
 * @fires close The dialog event when user closes the popup.
 *
 * STATUS OK
 */

@customElement('d-popup')
export class DPopup extends LitElement {
  static readonly styles = BaseDialog.styles;
  @property({ type: Boolean })
  show = false;
  @property({ type: Number, attribute: 'width' })
  width = 600;
  @property({ type: String })
  title = '';
  @property({ type: String })
  subtitle = '';
  @property({ type: String })
  subtitleSecond = '';
  @property({ type: Boolean })
  cancelOnOutsideClick = false;
  @property({ type: Array })
  headerActions: ActionInput[] = [];
  @property({ type: Array })
  footerActions: ActionInput[] = [];
  @query('dialog')
  private popupElement: HTMLDialogElement | undefined;

  get _getHeaderTemplate() {
    return html`
      ${this.headerActions?.length
        ? html`
            <div class="header-actions">
              ${this.headerActions.map((a) => {
                return html` <d-action
                  @click=${() => this._dispatch(a.disabled, a.action)}
                  ?disabled=${a.disabled}
                  ?delete=${a.action === 'delete'}
                  >${a.name}</d-action
                >`;
              })}
            </div>
          `
        : nothing}
      <d-wrap class="title" baseline tight>
        <div class="main-title">${this.title}</div>
        <d-wrap baseline tight class="sub-title">
          <div>${this.subtitle}</div>
          <div>${this.subtitleSecond}</div>
        </d-wrap>
      </d-wrap>
    `;
  }

  get _getBodyTemplate() {
    return html`<slot></slot>`;
  }

  get _getFooterTemplate() {
    return html`
      ${this.footerActions?.length
        ? html`
            <div class="footer-actions">
              ${this.footerActions.map((a) => {
                return html` <d-action
                  @click=${() => this._dispatch(a.disabled, a.action)}
                  ?disabled=${a.disabled}
                  ?delete=${a.action === 'delete'}
                  >${a.name}</d-action
                >`;
              })}
            </div>
          `
        : nothing}
    `;
  }

  private get hideCloser() {
    return this.headerActions?.length || this.footerActions?.length;
  }

  private get cardWidthStyle() {
    return 'width: ' + this.width + 'px';
  }

  _dispatch(disabled, action) {
    if (!disabled) {
      this.dispatchEvent(new CustomEvent('action', { bubbles: true, composed: true, detail: action }));
    }
  }

  onClick(event) {
    if (
      this.shadowRoot &&
      this.popupElement &&
      event.target === this.popupElement &&
      (this.cancelOnOutsideClick || !this.hideCloser)
    ) {
      this.popupElement.close();
    }
  }

  onClose() {
    this.show = false;
    this.dispatchEvent(new CustomEvent('close', { bubbles: true, composed: true }));
  }

  async showModal() {
    await this.updateComplete;
    if (this.popupElement) {
      this.popupElement.showModal();
    }
  }

  render() {
    return html`
      <dialog id="popup" style="${this.cardWidthStyle}" @click=${this.onClick} @close=${this.onClose}>
        ${this.hideCloser ? nothing : html` <d-closer @click=${() => (this.show = false)}></d-closer> `}
        <div class="header">${this._getHeaderTemplate}</div>
        <div class="body">${this._getBodyTemplate}</div>
        <div class="footer">${this._getFooterTemplate}</div>
      </dialog>
    `;
  }

  toggleShow() {
    if (this.shadowRoot && this.popupElement) {
      if (this.show) {
        this.popupElement.showModal();
      } else {
        this.popupElement.close();
      }
    }
  }

  close() {
    if (this.shadowRoot) {
      const popup = this.shadowRoot.getElementById('popup') as HTMLDialogElement;
      if (popup) {
        popup.close();
      }
    }
  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('show')) {
      this.toggleShow();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-popup': DPopup;
  }
}
