import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sortBy } from 'lodash';
import type { EmployeeForStaffingCalendar } from 'src/store';
import { holidaysForStaffingCalendar } from 'src/store';
import { DayOfWeek, LocalDate } from 'src/utilities/local-date.js';
import 'src/library/elements/d-tooltip.js';

export type SumType = '' | 'full' | 'cropped' | 'workdays';

const _holidays = holidaysForStaffingCalendar();

interface PeriodInfo {
  secondaryDaysCount: string;
  explainCropped: boolean;
  days: number;
  dates: string;
  daysText: string;
}

/**
 *
 */
@customElement('d-list-leave-periods')
export class DListLeavePeriods extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .row {
      padding: 4px 0;
    }

    .empty {
      font-style: italic;
    }

    .total {
      font-weight: bold;
    }

    .dates {
      display: inline-block;
      width: 200px;
    }

    .days {
      display: inline-block;
    }

    @media only screen and (max-width: 400px) {
      .dates {
        display: inline-block;
        width: 180px;
      }
    }

    .maxWidth400 .dates {
      display: inline-block;
      width: 180px;
    }
  `;
  @property({ type: Object })
  employee!: EmployeeForStaffingCalendar;
  @property({ type: String })
  start = '';
  @property({ type: String })
  end = '';
  @property({ type: String })
  type = '';
  @property({ type: Boolean })
  cropped = false;
  @property({ type: String })
  sumType: SumType = 'full';
  @property({ type: String })
  secondarySumType: SumType = '';
  sumOfDays(arr: PeriodInfo[]) {
    let sum = 0;
    arr.forEach((item) => {
      sum += item.days;
    });
    return sum;
  }
  _typeName(type: string, periods: PeriodInfo[]) {
    let name;
    if (type === 'sickSelf') {
      name = 'egenmelding';
      if (periods.length !== 1) {
        name += 'er';
      }
    } else if (type === 'vacation') {
      name = 'ferieperiode';
      if (periods.length !== 1) {
        name += 'r';
      }
    } else {
      name = 'periode';
      if (periods.length !== 1) {
        name += 'r';
      }
    }
    return name;
  }

  _daysNameTotal(arr: PeriodInfo[], type: string) {
    let name;
    let sum = 0;
    arr.forEach(function (item) {
      sum += item.days;
    });
    if (type === 'sickSelf') {
      name = 'dag';
    } else if (type === 'sickChildren') {
      name = 'omsorgsdag';
    } else if (type === 'vacation') {
      name = 'feriedag';
    } else {
      name = 'dag';
    }
    if (sum !== 1) {
      name += 'er';
    }
    return name;
  }

  _periodInRange(periodStart: string, periodEnd: string, rangeStart: string, rangeEnd: string) {
    return (
      (LocalDate.fromString(periodStart).isSameOrBefore(LocalDate.fromString(rangeEnd)) &&
        LocalDate.fromString(periodStart).isSameOrAfter(LocalDate.fromString(rangeStart))) ||
      (LocalDate.fromString(periodEnd).isSameOrAfter(LocalDate.fromString(rangeStart)) &&
        LocalDate.fromString(periodStart).isSameOrBefore(LocalDate.fromString(rangeEnd)))
    );
  }

  _formatDate(start: string, end: string) {
    if (end) {
      if (LocalDate.fromString(start).isSame(LocalDate.fromString(end))) {
        return LocalDate.fromString(start).toStringForDisplay();
      }
      return LocalDate.fromString(start).toStringForDisplay() + ' – ' + LocalDate.fromString(end).toStringForDisplay();
    }
    if (start) {
      return LocalDate.fromString(start).toStringForDisplay();
    }
    return '';
  }

  _holidaysCount(start: string, end: string) {
    const daysCount = LocalDate.fromString(end).toEpochDay() - LocalDate.fromString(start).toEpochDay() + 1;
    let holidaysCount = 0;
    for (let i = 0; i < daysCount; i++) {
      const day = LocalDate.fromString(start).plusDays(i).toString();
      if (LocalDate.fromString(day).dayOfWeek() === DayOfWeek.SUNDAY || _holidays[day]) {
        holidaysCount += 1;
      }
    }
    return holidaysCount;
  }

  _getPeriods(
    type: string,
    rangeStart: string,
    rangeEnd: string,
    cropped: boolean,
    sumType: SumType,
    secondarySumType: SumType,
  ) {
    const employee = this.employee;
    let periods = employee.leavePeriods.filter(
      (item) => this._periodInRange(item.start, item.end, rangeStart, rangeEnd) && item.type === type && item.confirmed,
    );
    periods = sortBy(periods, (e) => e.start);
    return periods.map((item) => {
      //Define full period
      const fullPeriodStart = item.start;
      const fullPeriodEnd = item.end;
      const fullPeriodDaysCount =
        LocalDate.fromString(fullPeriodEnd).toEpochDay() - LocalDate.fromString(fullPeriodStart).toEpochDay() + 1; //Define cropped period
      let croppedPeriodStart = item.start;
      if (LocalDate.fromString(item.start).isBefore(LocalDate.fromString(rangeStart))) {
        croppedPeriodStart = rangeStart;
      }
      let croppedPeriodEnd = item.end;
      if (LocalDate.fromString(item.end).isAfter(LocalDate.fromString(rangeEnd))) {
        croppedPeriodEnd = rangeEnd;
      }
      const croppedPeriodDaysCount =
        LocalDate.fromString(croppedPeriodEnd).toEpochDay() - LocalDate.fromString(croppedPeriodStart).toEpochDay() + 1; //Define cropped period workdays
      const croppedPeriodWorkdaysCount =
        croppedPeriodDaysCount - this._holidaysCount(croppedPeriodStart, croppedPeriodEnd); //Determine display values

      let displayStart = fullPeriodStart;
      let displayEnd = fullPeriodEnd;
      let displayDaysCount = fullPeriodDaysCount;
      let displaySecondaryDaysCount = '';

      if (cropped) {
        displayStart = croppedPeriodStart;
        displayEnd = croppedPeriodEnd;
      }

      if (sumType === 'cropped') {
        displayDaysCount = croppedPeriodDaysCount;
      } else if (sumType === 'workdays') {
        displayDaysCount = croppedPeriodWorkdaysCount;
      }

      if (secondarySumType === 'full' && fullPeriodDaysCount !== displayDaysCount) {
        displaySecondaryDaysCount = '(' + fullPeriodDaysCount + ')';
      }
      if (secondarySumType === 'cropped' && croppedPeriodDaysCount !== displayDaysCount) {
        displaySecondaryDaysCount = '(' + croppedPeriodDaysCount + ')';
      }
      if (secondarySumType === 'workdays' && croppedPeriodWorkdaysCount !== displayDaysCount) {
        displaySecondaryDaysCount = '(' + croppedPeriodWorkdaysCount + ')';
      }

      let daysText = displayDaysCount + ' dag';
      if (displayDaysCount !== 1) {
        daysText += 'er';
      }

      let explainCropped = false;
      if (!cropped && displaySecondaryDaysCount) {
        explainCropped = true;
      }

      return {
        dates: this._formatDate(displayStart, displayEnd),
        days: displayDaysCount,
        daysText: daysText,
        secondaryDaysCount: displaySecondaryDaysCount,
        explainCropped: explainCropped,
      };
    });
  }

  render() {
    const periods: PeriodInfo[] = this._getPeriods(
      this.type,
      this.start,
      this.end,
      this.cropped,
      this.sumType,
      this.secondarySumType,
    );

    if (periods.length === 0) {
      return html`<div class="row empty">Ingen</div>`;
    }

    const optionalVacationTooltip =
      this.type !== 'vacation'
        ? nothing
        : html`<d-tooltip
            content="Lørdag telles som feriedag. Søndager og offentlige fridager telles ikke som feriedager."
          >
          </d-tooltip>`;
    return html`<div>
      <div class="row total">
        <span class="dates">${periods.length} ${this._typeName(this.type, periods)}</span>
        <span class="days"
          >${this.sumOfDays(periods)} ${this._daysNameTotal(periods, this.type)} ${optionalVacationTooltip}
        </span>
      </div>
      ${this.renderPeriodRows(periods)}
    </div>`;
  }

  private renderPeriodRows(periods: PeriodInfo[]) {
    return periods.map(
      (period) =>
        html` <div class="row">
          <span class="dates">${period.dates}</span>
          <span class="days">
            ${period.daysText} ${period.secondaryDaysCount}
            ${!period.explainCropped
              ? nothing
              : html`<d-tooltip
                  content="Fraværsperioden er bare delvis innenfor det angitte tidsrommet.
                                Tallet i parentes viser totalt antall dager i denne fraværsperioden."
                >
                </d-tooltip>`}
          </span>
        </div>`,
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-leave-periods': DListLeavePeriods;
  }
}
