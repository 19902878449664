import type { ReportView, ReportViewEditItem } from 'src/content/reports/d-report-view.js';
import {
  fetchDraft,
  fetchRevisions,
  pagesAsSelectTagOptions,
  toAttachmentItems,
} from 'src/models/factory-utilities.js';
import { currentEmployeeUuid, currentUserUuid } from 'src/store';
import { BASE_PATH, EmployeeViewModelStatusEnum } from 'src/store/api';
import { employeesNotDeleted, getOrganization, key, toReportViewModel } from 'src/store/selectors';
import type { State } from 'src/store/types';
import { LocalDate } from 'src/utilities/local-date';
import { dateTimeDescription } from 'src/store/utilities';
import { assertIsDefined } from 'src/lib';
import { getMeetingMessageRecipients } from 'src/models/content/build-meeting-occurrence-view';
import { CommonDataEntityView } from 'src/content/entity-content';

export async function buildReportView(
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<ReportView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const availablePersons = employeesNotDeleted(state)
    .filter((e) => e.status !== EmployeeViewModelStatusEnum.Terminated && e.isConfirmedEntity)
    .map((p) => ({ value: p.uuid, text: p.name }));
  const availablePages = pagesAsSelectTagOptions(state);
  const item = organization.reportsById[uuid];
  assertIsDefined(item);

  const entity = toReportViewModel(item, organization);

  const resultItem: ReportView = {
    ...commonData,
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess:
      entity.classification === 'NONE' || (entity.accessControl ?? []).includes(currentUserUuid(state) ?? ''),
    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: entity.helpContent ?? '',
    href: currentParent + '/reports/' + uuid,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/reports/' + uuid + '.pdf?key=' + key(state),

    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'reports',
    name: entity.name ?? '',
    classification: entity.classification ?? 'NONE',
    accessControl: entity.accessControl ?? [],
    revisions: await fetchRevisions(
      state.token ?? '',
      'reports',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
    ),
    attachments: toAttachmentItems('reports', entity.uuid, entity.attachments),
    content: entity.content ?? '',
    reportDate: entity.reportDate ?? LocalDate.now().toString(),
    writtenByName: availablePersons.find((x) => x.value === entity.employeeUuid)?.text ?? '',
    availablePages: availablePages,
    availablePersons: availablePersons,
    pages: (entity.pages ?? []).map((x) => '' + x),
    employee: entity.employeeUuid ?? currentEmployeeUuid(state) ?? '',
    hasRelatedMeetingEvent:
      entity.meetingOccurrenceUuid !== undefined &&
      entity.meetingOccurrenceUuid !== null &&
      entity.meetingOccurrenceUuid !== '',
    today: LocalDate.fromString(state.today),
    isMeetingReport:
      entity.meetingOccurrenceUuid !== undefined &&
      entity.meetingOccurrenceUuid !== null &&
      entity.meetingOccurrenceUuid !== '',
    deletable: true,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(state.token ?? '', 'reports', uuid, organization.organizationId);
      return command.draft as ReportViewEditItem;
    },
    currentEmployeeUuid: currentEmployeeUuid(state) ?? '',
  };
  if (resultItem.isMeetingReport) {
    const meetingOccurrence = organization.meetingOccurrences.find((m) => m.uuid === entity.meetingOccurrenceUuid);
    assertIsDefined(meetingOccurrence);
    const responsibleFunction = organization.functionsById[meetingOccurrence.responsibleUuid ?? ''];
    const responsibleEmployeeUuid = responsibleFunction?.employees[0];
    const messageRecipients = getMeetingMessageRecipients(
      state,
      responsibleEmployeeUuid,
      meetingOccurrence.participants ?? [],
      meetingOccurrence.externalParticipants,
    );
    resultItem.meetingData = {
      uuid: meetingOccurrence.uuid,
      name: meetingOccurrence.name || '',
      messageRecipients,
    };
    resultItem.name = 'Referat fra ' + meetingOccurrence.name;
    resultItem.meetingOccurrenceDisplay = dateTimeDescription(
      meetingOccurrence.date,
      meetingOccurrence.time,
      meetingOccurrence.durationMinutes,
    );
    resultItem.secondaryLabel = meetingOccurrence.name?.toLowerCase().includes('medarbeidersamtale')
      ? 'med ' + organization.employeesById[meetingOccurrence.participants[0]].name
      : '';
  }
  return resultItem;
}
