import { html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { isInvalidPeriod } from 'src/store';
import type { EmployeeForStaffingCalendar } from 'src/store';
import { BaseDialog, DialogCancelResult, DialogDeleteResult } from 'src/library/components/BaseDialog.js';
import type { LeavePeriodDay } from 'src/store/api';
import type { DEditPeriods, PeriodEditItem } from 'src/pages/d-edit-periods';
import './d-edit-periods';

export interface EditPeriodResult {
  type: string;
  confirmed: boolean;
  /**
   * The start date or date and time
   */
  start: string;
  /**
   * The end date or date and time
   */
  end: string;
  notes: string;
  employeeUuid: string;
  newPeriod: boolean;
  periodId?: string;
  /**
   * Used for sickLeave. Otherwise, use 100.
   */
  grade: number;
  days: LeavePeriodDay[];
}

export interface EditPeriodsInput {
  type: string;
  application: 'timekeeping' | '';
  newPeriod: boolean;
  employee: EmployeeForStaffingCalendar;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  confirmed: boolean;
  notes: string;
  grade: number;
  days: LeavePeriodDay[];
  currentUserHasAccess: boolean;
  leavePeriodEditRestriction: boolean;
}

export type EditPeriodsResult =
  | DialogCancelResult
  | DialogDeleteResult
  | {
      action: 'done';
      type: string;
      confirmed: boolean;

      /**
       * The end date or date and time
       */
      end: string;

      /**
       * The start date or date and time
       */
      start: string;
      notes: string;

      grade: number;

      days: LeavePeriodDay[];
    };

/**
 *
 * FIX must sync with master. Calculations have changed
 * Hente ny funksjonalitet, vise info og alerts for fraværstyper
 *
 * USAGE:
 *    d-timekeeping-list-section
 *    d-staffing-calendar-table
 *    d-staffing-employee-day-popup
 *
 */
@customElement('edit-periods-dialog')
export class EditPeriodsDialog extends BaseDialog<EditPeriodsInput, EditPeriodsResult> {
  static readonly styles = [...BaseDialog.styles];
  @property({ type: String })
  application: 'timekeeping' | '' = '';
  @property({ type: Object })
  editItem: PeriodEditItem = {
    type: 'vacation',
    confirmed: false,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    notes: '',
    periodId: '',
    grade: 100,
    days: [],
  };
  @property({ type: Boolean })
  newPeriod = false;
  @property({ type: Object })
  employee!: EmployeeForStaffingCalendar;
  @property({ type: Number })
  width = 700;
  @property({ type: Boolean })
  currentUserHasAccess = false;
  @property({ type: Boolean })
  leavePeriodEditRestriction = false;
  @query('d-edit-periods')
  editPeriodsElm!: DEditPeriods;
  protected get calculatedTitle() {
    let employeeName = '';
    if (this.employee) {
      employeeName = this.employee.name;
    }

    if (this.application === 'timekeeping') {
      let typeTerm = 'avspasering';
      if (this.editItem.type === 'plusTime') {
        typeTerm = 'plusstid';
      }
      if (this.newPeriod) {
        return 'Ny ' + typeTerm + ' for ' + employeeName;
      }
      return 'Rediger ' + typeTerm + ' for ' + employeeName;
    }
    if (this.newPeriod) {
      return 'Ny fraværsperiode for ' + employeeName;
    }
    return 'Rediger fraværsperiode for ' + employeeName;
  }

  protected get calculatedHeaderActions() {
    return this.newPeriod
      ? [
          { name: 'Avbryt', action: 'close' },
          { name: 'Ferdig', action: 'save', disabled: this.periodIsInvalid },
        ]
      : [
          { name: 'Slett', action: 'delete' },
          { name: 'Avbryt', action: 'close' },
          { name: 'Ferdig', action: 'save' },
        ];
  }

  get periodIsInvalid() {
    return isInvalidPeriod(
      this.editItem.startDate,
      this.editItem.startTime,
      this.editItem.endDate,
      this.editItem.endTime,
    );
  }
  protected fetchResult(detail: string | undefined): EditPeriodsResult {
    if (detail === 'save') {
      return {
        action: 'done',
        end: this.editItem.endDate + (this.editItem.endTime === 'NONE' ? '' : ' ' + this.editItem.endTime),
        start: this.editItem.startDate + (this.editItem.startTime === 'NONE' ? '' : ' ' + this.editItem.startTime),
        type: this.editItem.type,
        confirmed: this.editItem.confirmed,
        notes: this.editItem.notes,
        grade: this.editItem.grade,
        days: this.editPeriodsElm.calculatedDays,
      };
    }

    if (detail === 'delete') {
      return {
        action: 'delete',
      };
    }

    return {
      action: 'cancel',
    };
  }

  protected initializeDialog(input: EditPeriodsInput) {
    this.editItem = {
      type: input.type,
      confirmed: input.confirmed,
      startDate: input.startDate,
      startTime: input.startTime === '' ? 'NONE' : input.startTime,
      endDate: input.endDate,
      endTime: input.endTime === '' ? 'NONE' : input.endTime,
      notes: input.notes,
      periodId: '',
      grade: input.grade,
      days: input.days,
    };
    this.employee = input.employee;
    this.application = input.application;
    this.newPeriod = input.newPeriod;
    this.currentUserHasAccess = input.currentUserHasAccess;
    this.leavePeriodEditRestriction = input.leavePeriodEditRestriction;
  }

  protected renderBody() {
    return html`
      <d-edit-periods
        .application=${this.application}
        .employee=${this.employee}
        .newPeriod=${this.newPeriod}
        .currentUserHasAccess=${this.currentUserHasAccess}
        .leavePeriodEditRestriction=${this.leavePeriodEditRestriction}
        .editItem=${this.editItem}
        .days=${this.editItem.days}
        @edit-item-changed=${(e: CustomEvent<PeriodEditItem>) => (this.editItem = e.detail)}
      ></d-edit-periods>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'edit-periods-dialog': EditPeriodsDialog;
  }
}
