import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../library/elements/d-menu.js';
import './d-infosec-view-header.js';
import './editors/d-data-items.js';
import '../../../library/editors/components/d-select-add.js';
import { dataTypes, InfosecAsset, sortSelectedCategories } from './defaults.js';
import type {
  InfosecDataType,
  InfosecDataItem,
  InfosecStorageUnit,
  InfosecNetwork,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import './editors/d-edit-computers-network.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';

/**
 *
 */
@customElement('d-infosec-data')
export class DInfosecData extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }
      d-data-items {
        margin-top: -1px;
      }
    `,
  ];
  @property({ type: String })
  type = 'patientData';
  @property({ type: Array })
  dataItems: InfosecDataItem[] = [];
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  remoteServers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  cloudServices: InfosecStorageUnit[] = [];
  @property({ type: Array })
  locations: InfosecStorageUnit[] = [];
  @property({ type: Array })
  assets: InfosecAsset[] = [];
  @property({ type: Array })
  networks: InfosecNetwork[] = [];

  private get menuItems() {
    return dataTypes.map((dataType) => {
      return {
        value: dataType.type,
        text: this._capitalize(dataType.name),
      };
    });
  }

  _capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const dataType: InfosecDataType = dataTypes.filter((type) => {
      return type.type === this.type;
    })[0];
    return html`
      <d-menu
        small
        .items=${this.menuItems}
        .value=${this.type}
        @value-changed=${(e) => (this.type = e.detail.value)}
      ></d-menu>
      <d-infosec-view-header .title=${this._capitalize(dataType.name)} .info=${dataType.text}></d-infosec-view-header>
      <d-data-items
        .dataType=${dataType}
        .dataItems=${this.dataItems}
        .cloudServices=${this.cloudServices}
        .remoteServers=${this.remoteServers}
        .computers=${this.computers}
        .assets=${this.assets}
        .locations=${this.locations}
        .partners=${this.partners}
      ></d-data-items>
    `;
  }

  protected firstUpdated() {
    this.dataItems = sortSelectedCategories(this.dataItems);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-infosec-data': DInfosecData;
  }
}
