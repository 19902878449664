import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';

import '../library/fields/d-spinner-robot.js';
import { customElement, property, query, state } from 'lit/decorators.js';
import type { PageViewModel } from '../store/api';
import '../library/fields/d-expansion.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import _ from 'lodash';

export interface HelpPageModel {
  href: string;
  content: string;
  tags: string[];
  title: string;
}

/**
 *
 *
 *
 */
@customElement('d-help-viewer')
export class DHelpViewer extends LitElement {
  static readonly styles = css`
    :host {
      position: relative;
      display: block;
      background: white;
    }

    .header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      width: 100%;
      min-height: 42px;
      background: white;
      box-sizing: border-box;
      z-index: 1;
      opacity: 1;
      box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.3);
      transition: all 0.5s;
    }

    .header.no-shadow {
      box-shadow: 0 0 10px hsla(0, 0%, 0%, 0);
    }

    .buttonsWrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 6px;
      transition: all 0.5s 0.5s;
    }

    .buttonsWrapper > div:first-child {
      display: flex;
    }

    .buttonsWrapper > div > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      cursor: pointer;
      z-index: 3;
    }

    .buttonsWrapper > div > div svg {
      width: 15px;
      height: 15px;
    }

    .buttonsWrapper > div > div[disabled] {
      cursor: default;
      opacity: 0.4;
    }

    .closer {
      width: 42px;
    }

    .helpPrev,
    .helpNext {
      width: 42px;
    }

    .helpPrev {
      transform: scaleX(-1);
    }

    #searchWrapper {
      position: relative;
      margin: 6px 20px 12px 20px;
      width: calc(100% - 40px);
    }

    #helpSearchInput {
      width: 100%;
      -webkit-appearance: none;
      background: hsl(0, 0%, 94%);
      box-shadow: none;
      border: 1px solid hsl(0, 0%, 90%);
      padding: 3px 10px;
      border-radius: 15px;
      font-family: var(--mainSans);
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      box-sizing: border-box;
      resize: none;
      color: black;
    }

    #searchWrapper .clearSearch {
      display: none;
      width: 32px;
      height: 32px;
      position: absolute;
      top: 0;
      right: 0;
      background: url(/images/x-thin-black.svg) 50% 50% no-repeat;
      background-size: 20px 20px;
      opacity: 0.4;
      cursor: pointer;
    }

    #searchWrapper .clearSearch:hover {
      opacity: 1;
    }

    #searchWrapper.hasContent .clearSearch {
      display: block;
    }

    .menu {
      padding-bottom: 70px;
      line-height: 140%;
    }

    .menuItem {
      display: block;
      padding: 4px 20px;
      color: var(--themeColor);
      cursor: pointer;
      text-decoration: none;
    }

    :host(:not([touch])) .menuItem:hover {
      color: black;
    }

    .helpHome {
      display: flex;
      align-items: center;
      padding: 0 12px;
      color: var(--themeColor);
      cursor: pointer;
    }

    .buttonsWrapper > div > div:not([disabled]):hover svg rect,
    .buttonsWrapper > div > div:not([disabled]):hover svg polygon {
      fill: black;
    }

    :host(:not([touch])) .helpHome:hover {
      color: black;
    }

    #supportInfo {
      margin: 20px;
      font-family: var(--mainSerif);
    }

    #supportInfo a {
      color: var(--themeColor);
    }

    :host(:not([touch])) #supportInfo a:hover {
      color: black;
    }

    .businessCard {
      display: flex;
      align-items: center;
    }

    .businessCard:before {
      flex: none;
      content: '';
      width: 96px;
      height: 96px;
      margin-right: 12px;
      border-radius: 50%;
      background: silver;
    }

    .businessCard.hege:before {
      background: url(/images/hege.png) 50% 50% no-repeat;
      background-size: contain;
    }

    .businessCard p {
      margin: 0;
      font-size: 16px;
      line-height: 160%;
    }

    #content {
      padding: 0 20px 70px 20px;
      font-family: var(--mainSerif);
      line-height: 160%;
      color: hsl(0, 0%, 20%);
    }

    #content figure,
    #content img {
      margin-top: 1.2em;
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px) !important;
      height: auto;
    }

    #content figure:first-child,
    #content img:first-child {
      margin-top: 0;
    }

    #content img {
      margin-bottom: 1em;
      border-top: 1px solid hsl(1, 0%, 80%);
      border-bottom: 1px solid hsl(1, 0%, 80%);
    }

    #content figure img {
      margin: 0;
      width: 100% !important;
      height: auto;
    }

    #content figcaption {
      font-family: var(--mainSans);
      font-size: 14px;
      line-height: 150%;
      padding-top: 6px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 1em;
    }

    #content p,
    #content ul,
    #content ol {
      font-size: 16px;
      margin: 0.5em 0;
    }

    #content ul,
    #content ol {
      padding-left: 24px;
    }

    #content ul li,
    #content ol li {
      margin-bottom: 0.3em;
    }

    #content li ul,
    #content li ol {
      margin-top: 0.3em;
    }

    #content h1 {
      font-size: 28px;
      line-height: 130%;
      font-weight: 500;
      margin-bottom: 0.5em;
    }

    #content h2 {
      font-size: 20px;
      line-height: 130%;
      font-weight: 500;
      margin-top: 1em;
      margin-bottom: 0.5em;
    }

    #content a {
      color: var(--themeColor);
    }

    #content a.appLink {
      font-family: var(--mainSans);
      font-size: 15.5px;
      font-weight: 500;
    }

    #content strong,
    #content b {
      font-weight: 500;
    }

    body:not(.touch) #content a:hover {
      color: black;
    }

    #content hr {
      border-width: 0;
    }

    #content .attention {
      position: relative;
      margin: 30px 0 20px 15px;
      padding: 12px 20px 6px 20px;
      background: hsl(196, 83%, 90%);
      font-family: var(--mainSans);
      line-height: 160%;
      border-radius: 6px;
    }

    #content .attention p {
      font-size: 15px;
      margin-bottom: 8px;
    }

    #content .attention:before {
      content: '!';
      display: block;
      position: absolute;
      left: -15px;
      top: -15px;
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: hsl(196, 83%, 49%);
      padding-top: 3px;
      text-align: center;
      font-size: 26px;
      line-height: 100%;
      font-weight: 700;
      color: white;
    }

    #appContent a[href^="https://trinnvis.no/hjelp"]
    {
      font-weight: 500;
      margin-left: 10px;
      color: var(--themeColor);
    }

    #appContent a[href^="https://trinnvis.no/hjelp"]:before
    {
      content: '? ';
      display: inline-block;
      position: relative;
      top: 1px;
      width: 20px;
      height: 20px;
      top: 1px;
      border-radius: 50%;
      background: var(--themeColor);
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: white;
      text-align: center;
      margin-right: 4px;
      box-sizing: border-box;
    }
    #appContent .outskirts a[href^="https://trinnvis.no/hjelp"]
    {
      margin-left: 0;
    }

    #appContent .outskirts a[href^="https://trinnvis.no/hjelp"]:before
    {
      display: none;
    }

    :host(:not([touch])) #appContent a[href^="https://trinnvis.no/hjelp"]:hover
    {
      color: var(--themeColorDarkerTwo);
    }

    :host(:not([touch])) #appContent .outskirts a[href^="https://trinnvis.no/hjelp"]:hover
    {
      color: black;
      border-bottom-color: black;
    }

    :host(:not([touch])) #appContent a[href^="https://trinnvis.no/hjelp"]:hover:before
    {
      background: var(--themeColorDarkerTwo);
    }

    #content .attention {
      position: relative;
      margin: 30px 0 20px 15px;
      padding: 12px 20px 6px 20px;
      background: hsl(196, 83%, 90%);
      font-family: var(--mainSans);
      line-height: 160%;
      border-radius: 6px;
    }

    #content .attention p {
      font-size: 15px;
      margin-bottom: 8px;
    }

    #content .attention:before {
      content: '!';
      display: block;
      position: absolute;
      left: -15px;
      top: -15px;
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: hsl(196, 83%, 49%);
      padding-top: 3px;
      text-align: center;
      font-size: 26px;
      line-height: 100%;
      font-weight: 700;
      color: white;
    }
  `;
  @query('#content')
  content!: HTMLDivElement;
  @query('#helpPrev')
  helpPrev!: HTMLDivElement;
  @query('#helpNext')
  helpNext!: HTMLDivElement;
  @property({ type: Boolean })
  show = false;
  @property({ type: Boolean })
  showContent = false;
  @property({ type: Array })
  helpPages: HelpPageModel[] = [];
  @property({ type: Boolean })
  menuOpen = false;
  /**
   * The pages list. Used to verify if the link is to a valid pageId for the user.
   *
   */
  @property({ type: Array })
  pages: PageViewModel[] = [];
  /**
   * Oppslag av alle elementer i systemet. Brukes for å lage link til enkeltelement. Med riktig kontekst.
   */
  @property({ type: Object })
  itemsByTypeAndId: any = {};
  /**
   * Er dette enkeltbrukerversjon? Brukes for å lage riktig kontekts og link til elementer.
   */
  @property({ type: Boolean })
  singleUserVersion = false;
  /**
   * Gjeldende organisasjon. Brukes for å lage riktige linker
   */
  @property({ type: Number })
  organizationId = 0;
  @state()
  historyNav = false;
  @state()
  history: string[] = [];
  @state()
  historyIndex = 0;
  @state()
  searchString = '';

  private _currentHelpPage = '';

  @property({ type: String })
  get currentHelpPage(): string {
    return this._currentHelpPage;
  }

  set currentHelpPage(value: string) {
    if (this._currentHelpPage != value) {
      this._currentHelpPage = value;
      this._pageChange(value);
    }
  }

  get filteredHelpMenu(): HelpPageModel[] {
    const searchQuery = this.searchString.toLowerCase();
    if (searchQuery.length > 2) {
      const temp = this.helpPages
        .map((page) => {
          let sort = 0;
          for (const item of page.tags) {
            if (searchQuery === item) {
              sort += 4;
            }
            if (searchQuery.indexOf(item) > -1) {
              sort += 3;
            }
            if (item.indexOf(searchQuery) > -1) {
              sort += 2;
            }
          }
          if (page.title.toLowerCase().indexOf(searchQuery) > -1) {
            sort += 4;
          }
          if (page.content.toLowerCase().indexOf(searchQuery) > -1) {
            sort += 1;
          }
          return { sort, page };
        })
        .filter((p) => p.sort > 0);

      return _.sortBy(temp, ['sort']).map((p) => p.page);
    } else {
      return this.helpPages;
    }
  }

  private get currentHelpPageModel(): HelpPageModel | undefined {
    return this.helpPages.find((item) => item.href === 'https://trinnvis.no/hjelp/' + this._currentHelpPage + '/');
  }

  searchWrapperClass(searchString: string) {
    if (searchString) {
      return 'hasContent';
    }
    return '';
  }

  _clearSearch() {
    this.searchString = '';
  }

  _setPage(page: string) {
    if (!this.historyNav) {
      const history = [...this.history];
      if (history.length) {
        history.length = this.historyIndex + 1;
      }
      history.push(page);
      this.history = history;
      this.historyIndex = history.length - 1;
    }
    if (page) {
      this.menuOpen = false;
    } else {
      this.menuOpen = true;
    }
    this.historyNav = false;
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: window.scrollX,
        behavior: 'auto',
      });
    }, 0);
  }

  _pageChange(page: string) {
    if (this.helpPages.length) {
      this._setPage(page);
    }
  }

  _prevDisabled(history: any[] | History, index: number) {
    return !history?.length || index === 0;
  }

  _nextDisabled(history, index) {
    return !history?.length || index > history.length - 2;
  }

  _prev() {
    if (!this.helpPrev.hasAttribute('disabled')) {
      this.historyNav = true;
      const prevPage = this.history[this.historyIndex - 1];
      if (prevPage === '') {
        this.menuOpen = true;
      }
      this.historyIndex = this.historyIndex - 1;
      this.fireUpdateHelpPage(prevPage);
    }
  }

  _next() {
    if (!this.helpNext.hasAttribute('disabled')) {
      this.historyNav = true;
      const nextPage = this.history[this.historyIndex + 1];
      this.historyIndex = this.historyIndex + 1;
      this.fireUpdateHelpPage(nextPage);
    }
  }

  _linkClick(event) {
    event.preventDefault();
    this.menuOpen = false;
    if (event.target.nodeName.toLowerCase() === 'a') {
      if (event.target.href.indexOf('https://trinnvis.no/hjelp/') > -1) {
        const newPage = event.target.href
          .substring(0, event.target.href.length - 1)
          .replace('https://trinnvis.no/hjelp/', '');

        this.fireUpdateHelpPage(newPage);
      }
      if (event.target.href.indexOf('https://app.trinnvis.no/p/') > -1) {
        const n = Number(event.target.href.replace('https://app.trinnvis.no/p/', ''));
        this.fireOpenPage(n);
      }
    }
  }

  _match(helpMenu: string | any[]) {
    return helpMenu.length > 0;
  }

  _headerClasses(menuOpen: boolean) {
    if (menuOpen) {
      return 'no-shadow';
    }
    return '';
  }

  _isHome(page) {
    return page === '';
  }

  _toggleMenu() {
    this.menuOpen = !this.menuOpen;
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: window.scrollX,
        behavior: 'auto',
      });
    }, 0);
  }

  _close() {
    this.dispatchEvent(new CustomEvent<{ value: boolean }>('close-viewer', { bubbles: true, composed: true }));
    setTimeout(() => {
      this.dispatchEvent(
        new CustomEvent<{ value: boolean }>('toggle-help', { bubbles: true, composed: true, detail: { value: false } }),
      );
    }, 500);
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.menuOpen = this._currentHelpPage === '';
  }

  render() {
    return html`
      <div class="header ${this._headerClasses(this.menuOpen)}">
        <div class="buttonsWrapper">
          <div>
            <div id="closer" class="closer" @click=${this._close}>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 10 10"
                enable-background="new 0 0 10 10"
              >
                <rect
                  class="closerElement"
                  x="4.214"
                  y="-1.285"
                  transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 4.9988 12.0706)"
                  fill="hsl(196, 83%, 49%)"
                  width="1.571"
                  height="12.571"
                ></rect>
                <rect
                  class="closerElement"
                  x="4.215"
                  y="-1.286"
                  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.0708 5)"
                  fill="hsl(196, 83%, 49%)"
                  width="1.571"
                  height="12.571"
                ></rect>
              </svg>
            </div>
            <div
              id="helpPrev"
              class="helpPrev"
              @click=${this._prev}
              ?disabled=${this._prevDisabled(this.history, this.historyIndex)}
            >
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 10 10"
                enable-background="new 0 0 10 10"
              >
                <polygon fill="hsl(196, 83%, 49%)" points="3.1,0 1.9,1.1 5.8,5 1.9,8.9 3.1,10 8.1,5 "></polygon>
              </svg>
            </div>
            <div
              id="helpNext"
              class="helpNext"
              @click=${this._next}
              ?disabled=${this._nextDisabled(this.history, this.historyIndex)}
            >
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 10 10"
                enable-background="new 0 0 10 10"
              >
                <polygon fill="hsl(196, 83%, 49%)" points="3.1,0 1.9,1.1 5.8,5 1.9,8.9 3.1,10 8.1,5 "></polygon>
              </svg>
            </div>
          </div>
          ${this._isHome(this._currentHelpPage)
            ? nothing
            : html`<div id="helpHome" class="helpHome" @click=${this._toggleMenu}>
                <span>${this.menuOpen ? 'Skjul meny' : 'Vis meny'}</span>
              </div>`}
        </div>
      </div>
      <d-expansion ?opened=${this.menuOpen}>
        <div class="menu">
          <div id="searchWrapper" class="${this.searchWrapperClass(this.searchString)}">
            <input
              id="helpSearchInput"
              type="text"
              placeholder="Søk i brukerstøtte"
              @input=${this._onSearchStringChange}
              .value="${this.searchString}"
            />
            <div class="clearSearch" @click=${this._clearSearch}></div>
          </div>
          ${this.filteredHelpMenu.map(
            (item) => html`<a class="menuItem" href="${item.href}" @click=${this._linkClick}>${item.title}</a>`,
          )}
          ${this._match(this.filteredHelpMenu)
            ? nothing
            : html`<div id="supportInfo">
                <div class="businessCard hege">
                  <p>
                    Finner du det ikke? <br />Jeg hjelper deg gjerne! <br />
                    <a href="mailto:hege@trinnvis.no">hege@trinnvis.no</a> <br />
                    Telefon <a href="tel:48595383">485 95 383</a>
                  </p>
                </div>
              </div>`}
        </div>
      </d-expansion>
      <d-expansion ?opened=${!this.menuOpen}>
        <div id="content" class="help-content" @click=${this._linkClick}>${this.renderCurrentHelpContent()}</div>
      </d-expansion>
    `;
  }

  _onSearchStringChange(event) {
    this.searchString = event.target.value;
  }

  protected willUpdate(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('currentHelpPage') && this.content) {
      this._pageChange(this._currentHelpPage);
    }
  }

  private fireUpdateHelpPage(page: string) {
    this.dispatchEvent(
      new CustomEvent<{ page: string }>('update-help', { bubbles: true, composed: true, detail: { page: page } }),
    );
  }

  /**
   * Fire to open the selected page in the main application view. The pageId is the template id for the page.
   * @param n
   * @private
   */
  private fireOpenPage(n: number) {
    this.dispatchEvent(
      new CustomEvent<{ pageId: number }>('open-page', { bubbles: true, composed: true, detail: { pageId: n } }),
    );
  }

  private renderCurrentHelpContent() {
    const m = this.currentHelpPageModel;
    return m === undefined
      ? nothing
      : unsafeHTML(
          m.content.replaceAll('href="https://app.trinnvis.no/p', 'class="appLink" href="https://app.trinnvis.no/p'),
        );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-help-viewer': DHelpViewer;
  }
}
