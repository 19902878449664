/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EmailAuthorizeCommand
 */
export interface EmailAuthorizeCommand {
  /**
   *
   * @type {string}
   * @memberof EmailAuthorizeCommand
   */
  email: string;
}

/**
 * Check if a given object implements the EmailAuthorizeCommand interface.
 */
export function instanceOfEmailAuthorizeCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'email' in value;

  return isInstance;
}

export function EmailAuthorizeCommandFromJSON(json: any): EmailAuthorizeCommand {
  return EmailAuthorizeCommandFromJSONTyped(json, false);
}

export function EmailAuthorizeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailAuthorizeCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
  };
}

export function EmailAuthorizeCommandToJSON(value?: EmailAuthorizeCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
  };
}
