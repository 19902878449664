/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FunctionAssignment
 */
export interface FunctionAssignment {
  /**
   *
   * @type {string}
   * @memberof FunctionAssignment
   */
  functionUuid?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionAssignment
   */
  employeeUuid?: string;
}

/**
 * Check if a given object implements the FunctionAssignment interface.
 */
export function instanceOfFunctionAssignment(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function FunctionAssignmentFromJSON(json: any): FunctionAssignment {
  return FunctionAssignmentFromJSONTyped(json, false);
}

export function FunctionAssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): FunctionAssignment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    functionUuid: !exists(json, 'functionUuid') ? undefined : json['functionUuid'],
    employeeUuid: !exists(json, 'employeeUuid') ? undefined : json['employeeUuid'],
  };
}

export function FunctionAssignmentToJSON(value?: FunctionAssignment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    functionUuid: value.functionUuid,
    employeeUuid: value.employeeUuid,
  };
}
