import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../fields/d-expansion';
import '../../elements/d-tooltip.js';
import '../../elements/d-label.js';
import './d-checkbox.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';

export interface SelectCheckboxOption {
  value: string;
  text: string;
  disabled?: boolean;
}

/**
 *
 * @fires value-changed - Dispatched immediately after changes by the user
 *
 * STATUS OK
 */
@customElement('d-select-checkbox')
export class DSelectCheckbox extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      .options {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -4px;
      }

      :host([vertical][bordered]) .options {
        border-bottom: 1px solid var(--borderColor);
        margin-bottom: 0;
      }

      :host([vertical]) .options,
      :host([checklist]) .options {
        display: block;
      }

      d-checkbox {
        margin-bottom: 4px;
      }

      :host([checklist]) d-checkbox {
        margin: 0;
        padding: 0;
      }

      :host([vertical][bordered]) d-checkbox {
        border-top: 1px solid var(--borderColor);
        margin-bottom: 0;
        padding: 12px 0;
      }

      :host([vertical][theme-page]) .options,
      :host([vertical][theme-page]) d-checkbox {
        border-color: var(--borderColorOnGray);
      }

      @media (hover: hover) {
        :host([checklist]) d-checkbox:not([disabled]):hover {
          background-color: hsla(0, 0%, 0%, 0.05);
        }
      }
    `,
  ];
  @property({ type: Array })
  value: string[] = [];
  @property({ type: Array })
  options: SelectCheckboxOption[] = [];
  @property({ type: Boolean, reflect: true })
  vertical = false;
  @property({ type: Boolean, reflect: true })
  outskirts = false;
  @property({ type: Boolean, attribute: 'bordered', reflect: true })
  bordered = false;
  @property({ type: Boolean, reflect: true })
  checklist = false;
  @property({ type: Boolean })
  opened = true;

  private get currentValues(): string[] {
    return this.value ?? [];
  }

  renderContent() {
    return html`
      <d-expansion ?opened=${this.opened}>
        <div class="options">
          ${this.options.map(
            (item) =>
              html` <d-checkbox
                .optionLabel=${item.text}
                .wrapLabel=${this.wrapLabel}
                ?outskirts=${this.outskirts}
                ?theme-page=${this.themePage}
                .checklist=${this.checklist}
                ?disabled=${item.disabled}
                ?checked=${this.currentValues.includes(item.value)}
                @checked-changed=${() => {
                  this.toggleSelect(item.value);
                }}
              ></d-checkbox>`,
          )}
        </div>
      </d-expansion>
    `;
  }

  private toggleSelect(item: string) {
    if (this.currentValues.includes(item)) {
      this.value = this.currentValues.filter((x) => x !== item);
    } else {
      this.value = [...this.currentValues, item];
    }
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-select-checkbox': DSelectCheckbox;
  }
}
