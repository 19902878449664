import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import '../library/elements/d-label.js';
import '../library/elements/d-section.js';
import '../library/editors/elements/d-select-date.js';
import '../library/editors/elements/d-select-dropdown.js';
import '../library/editors/elements/d-edit-textarea.js';
import '../library/elements/d-wrap.js';
import '../library/editors/elements/d-edit-number.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { BaseDialog, DialogCancelResult, DialogDeleteResult } from 'src/library/components/BaseDialog.js';
import { TimekeepingCorrection, TimekeepingCorrectionCorrectionTypeEnum } from 'src/store/api';

export interface EditTimekeepingCorrectionInput {
  newItem: boolean;
  day: string;
  hours: number;
  type: string;
  notes: string;
  employeeName: string;
  corrections: TimekeepingCorrection[];
}

export type EditTimekeepingCorrectionResult =
  | DialogCancelResult
  | DialogDeleteResult
  | {
      action: 'done';
      type: TimekeepingCorrectionCorrectionTypeEnum;
      hours: number;
      date: string;
      notes: string;
    };

/**
 *
 * USAGE:
 *    d-timekeeping-list-section
 *
 */
@customElement('edit-timekeeping-correction-dialog')
export class EditTimekeepingCorrectionDialog extends BaseDialog<
  EditTimekeepingCorrectionInput,
  EditTimekeepingCorrectionResult
> {
  static readonly styles = [
    ...BaseDialog.styles,
    css`
      d-edit-number {
        width: 80px;
      }
    `,
  ];
  @property({ type: Boolean })
  newItem = false;
  @property({ type: String })
  day = '';
  @property({ type: Number })
  hours = 0;
  @property({ type: String })
  type = 'minus';
  @property({ type: String })
  notes = '';
  @property({ type: String })
  employeeName = '';
  @property({ type: Array })
  corrections: TimekeepingCorrection[] = [];
  width = 460;
  private typeOptions = [
    { value: 'minus', text: 'Timer til fradrag' },
    { value: 'plus', text: 'Timer i tillegg' },
  ];

  protected get calculatedHeaderActions() {
    const p = [
      { name: 'avbryt', action: 'cancel' },
      { name: 'ferdig', action: 'save' },
    ];
    if (this.newItem) {
      return p;
    }
    return [{ name: 'slett', action: 'delete' }, ...p];
  }

  protected get calculatedTitle(): string {
    return 'Timeavregning for ' + this.employeeName;
  }

  private get disabledPeriods() {
    return this.corrections
      .filter((c) => this.newItem || c.date !== this.day)
      .map((item) => ({
        start: LocalDate.fromString(item.date),
        end: LocalDate.fromString(item.date),
      }));
  }

  _getFirstAvailableDay(defaultDate: string) {
    const day = LocalDate.fromString(defaultDate);
    if (this.disabledPeriods?.length) {
      const disabledDays = this.disabledPeriods.map((item) => item.start);
      if (disabledDays.find((d) => d.isSame(day)) !== undefined) {
        let dayDate = day;
        for (let i = 0; i < 100; i++) {
          dayDate = dayDate.plusDays(1);
          if (disabledDays.find((d) => d.isSame(dayDate)) === undefined) {
            return dayDate;
          }
        }
      }
      return day;
    }
    return day;
  }
  renderBody() {
    return html`
      <d-section>
        <d-wrap>
          <d-select-date
            in-dialog
            label="Dato"
            .value=${this.day}
            .disabledPeriods=${this.disabledPeriods}
            inline-label
            light-label
            @value-changed=${this.onDateChanged}
          ></d-select-date>
          <d-wrap>
            <d-select-dropdown
              .options=${this.typeOptions}
              .value=${this.type}
              @value-changed=${this.onTypeChanged}
            ></d-select-dropdown>
            <d-edit-number
              select-on-focus
              .value=${this.hours}
              min="0"
              max="999"
              @value-changed=${this.onHoursChanged}
            ></d-edit-number>
          </d-wrap>
        </d-wrap>
      </d-section>
      <d-section>
        <d-edit-textarea
          label="Notater"
          value="${this.notes}"
          placeholder="F.eks «Utbetalt»"
          @value-changed=${this.onNotesChanged}
        ></d-edit-textarea>
      </d-section>
    `;
  }

  protected fetchResult(detail: string | undefined): EditTimekeepingCorrectionResult {
    if (detail === 'save') {
      return {
        action: 'done',
        type:
          this.type === 'minus'
            ? TimekeepingCorrectionCorrectionTypeEnum.Minus
            : TimekeepingCorrectionCorrectionTypeEnum.Plus,
        hours: this.hours,
        date: this.day,
        notes: this.notes,
      };
    } else if (detail === 'delete') {
      return {
        action: 'delete',
      };
    } else {
      return {
        action: 'cancel',
      };
    }
  }

  protected initializeDialog(input: EditTimekeepingCorrectionInput) {
    this.newItem = input.newItem;
    this.type = input.type;
    this.notes = input.notes;
    this.corrections = input.corrections;
    this.hours = input.hours;
    this.employeeName = input.employeeName;
    this.day = input.newItem ? this._getFirstAvailableDay(input.day).toString() : input.day;
  }

  private onDateChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.day = e.detail.value;
  }
  private onTypeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.type = e.detail.value;
  }
  private onHoursChanged(e: CustomEvent<{ value: number }>) {
    e.stopPropagation();
    this.hours = e.detail.value;
  }
  private onNotesChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.notes = e.detail.value;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'edit-timekeeping-correction-dialog': EditTimekeepingCorrectionDialog;
  }
}
