/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AccountStatusUpdateMessage
 */
export interface AccountStatusUpdateMessage {
  /**
   *
   * @type {boolean}
   * @memberof AccountStatusUpdateMessage
   */
  closed?: boolean;
  /**
   *
   * @type {string}
   * @memberof AccountStatusUpdateMessage
   */
  expires?: string;
}

/**
 * Check if a given object implements the AccountStatusUpdateMessage interface.
 */
export function instanceOfAccountStatusUpdateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AccountStatusUpdateMessageFromJSON(json: any): AccountStatusUpdateMessage {
  return AccountStatusUpdateMessageFromJSONTyped(json, false);
}

export function AccountStatusUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccountStatusUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    closed: !exists(json, 'closed') ? undefined : json['closed'],
    expires: !exists(json, 'expires') ? undefined : json['expires'],
  };
}

export function AccountStatusUpdateMessageToJSON(value?: AccountStatusUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    closed: value.closed,
    expires: value.expires === undefined ? undefined : value.expires,
  };
}
