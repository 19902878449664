import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../library/elements/d-label.js';

export interface RelatedItem {
  name: string;
  href: string;
  riskDisplay?: string;
}

/**
 *
 */
@customElement('d-view-related-items')
export class DViewRelatedItems extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .item {
      display: flex;
    }

    .name {
      flex: 1;
      padding-right: 10px;
    }

    a {
      padding: 3px 0;
      display: inline-block;
      color: var(--linkColorGray);
      text-decoration: none;
      cursor: pointer;
    }

    a:hover {
      color: black;
    }
  `;
  @property({ type: String })
  field = '';
  @property({ type: Array })
  items: RelatedItem[] = [];

  render() {
    return html`
      <div class="sectionElementInner">
        ${this.field !== '' ? html` <d-label field="${this.field}"></d-label> ` : nothing}
        <div class="items">
          ${this.items.map(
            (item) =>
              html`<a class="item" href="${item.href}">
                <span class="name">${item.name}</span>
              </a>`,
          )}
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-related-items': DViewRelatedItems;
  }
}
