import { css, html, LitElement } from 'lit';
import '../library/fields/d-view-text.js';
import '../library/elements/d-label.js';
import '../library/elements/d-action.js';
import '../library/elements/d-wrap.js';
import '../library/fields/d-view-html.js';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import type { Revision } from 'src/content/entity-content.js';
import { LocalDateTime } from 'src/utilities/local-date-time.js';
import { htmlContentStyles } from 'src/library/html-content-styles.js';
import { DocForLinking, convertInternalLinks } from 'src/models/internal-linking.js';
import { styleMap } from 'lit/directives/style-map.js';

/**
 * STATUS OK
 */
@customElement('d-entity-diff')
export class DEntityDiff extends LitElement {
  static readonly styles = [
    htmlContentStyles,
    css`
      :host {
        display: block;
      }

      .header {
        overflow: auto;
        position: -webkit-sticky;
        position: sticky;
        padding: 10px 0;
        top: 0;
        background: white;
        z-index: 2;
        border-top: 1px solid var(--borderColor);
        border-bottom: 1px solid var(--borderColor);
      }

      .header h2 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 4px;
        display: inline-block;
      }

      d-label {
        display: block;
        margin-top: 8px;
      }

      .details {
        padding-top: 8px;
      }

      .diffColorInfo {
        padding-top: 8px;
        font-family: var(--mainSerif);
        font-weight: 300;
        display: flex;
        flex-wrap: wrap;
      }

      .diffColorInfo div {
        margin-top: 6px;
        margin-right: 6px;
      }

      .html {
        background-color: var(--backgroundLightGray);
        padding: 12px 16px;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
      }

      .diff-html-removed {
        background-color: #fad1e3;
      }
      .diff-html-added {
        background-color: #c5eeb2;
      }

      #revisionFieldWrapper {
        padding: 20px 0;
      }

      #revisionField {
        min-height: 38px;
        position: relative;
        max-width: 724px;
        box-sizing: border-box;
        padding: 16px 20px 14px 20px;
        border-radius: 20px;
        word-wrap: normal;
      }

      #revisionField.templateDeleted {
        background-color: hsl(20, 100%, 96%);
        border: 1px solid hsl(20, 100%, 90%);
      }
    `,
  ];
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Object })
  revision!: Revision;
  @property({ type: Boolean })
  templateDeleted = false;
  @property({ type: Boolean })
  requestOnFlight = false;
  @property({ type: Array })
  docsForLinking: DocForLinking[] = [];
  @property({ type: Number })
  contentStickyTop = 0;
  private promptsForEdit = {
    restoreName: 'Gjenopprett',
    title: 'Tidligere versjon',
    addedDescription: 'Tekst markert med grønt finnes bare i denne versjonen, og blir lagt til hvis du gjenoppretter.',
    removedDescription: 'Tekst markert med rødt finnes ikke i denne versjonen, og blir slettet hvis du gjenoppretter.',
  };
  private promptsForSuggestion = {
    restoreName: 'Oppdater',
    title: 'Foreslått oppdatering',
    addedDescription: 'Tekst markert med grønt finnes bare i denne oppdateringen, og blir lagt til hvis du oppdaterer.',
    removedDescription: 'Tekst markert med rødt finnes ikke i denne oppdateringen, og blir slettet hvis du oppdaterer.',
  };

  _revisionChangedBy(x, singleUserVersion) {
    if (singleUserVersion && x !== 'TrinnVis-teamet') {
      return 'meg';
    }
    return x;
  }

  _formatTime(date) {
    return LocalDateTime.fromString(date).toStringForDisplay();
  }

  _isSuggested(revision) {
    return revision && revision.status === 'SUGGESTED';
  }

  _restore() {
    if (!this.requestOnFlight && this.revision) {
      this.dispatchEvent(
        new CustomEvent<{ name: string; content: string; status: string }>('restore-revision', {
          bubbles: true,
          composed: true,
          detail: {
            name: this.revision.name,
            content: this.revision.content,
            status: this.revision.status,
          },
        }),
      );
    }
  }

  _keep() {
    this.dispatchEvent(new CustomEvent('keep', { bubbles: true, composed: true }));
  }

  render() {
    if (this.templateDeleted) {
      return html`
        <div id="revisionFieldWrapper">
          <div id="revisionField" class="templateDeleted">
            <div class="revisionHeader">
              <span class="title">Foreslått sletting</span>
              <span class="noWrap">${this._formatTime(this.revision.dateTime)}</span>
              <span class="noWrap">av ${this.revision.changedBy}</span>
            </div>
            <d-view-html class="systemText" value="${this.revision.content}"></d-view-html>
            <d-action @click=${() => this._keep()}>Avbryt</d-action>
          </div>
        </div>
      `;
    } else if (this._isSuggested(this.revision)) {
      return this.renderDifferences(this.promptsForSuggestion);
    } else {
      return this.renderDifferences(this.promptsForEdit);
    }
  }

  private renderDifferences(prompts: {
    removedDescription: string;
    title: string;
    restoreName: string;
    addedDescription: string;
  }) {
    const headerStyles = { top: this.contentStickyTop - 1 + 'px' };
    return html`
      <div>
        <div class="version">
          <div class="header" style=${styleMap(headerStyles)}>
            <d-wrap split-reverse>
              <div>
                <d-action @click=${() => this._keep()}>Avbryt</d-action>
                <d-action ?disabled="${this.requestOnFlight}" @click=${() => this._restore()}
                  >${prompts.restoreName}</d-action
                >
              </div>
              <h2>${prompts.title}</h2>
            </d-wrap>
            <div class="details">
              <span class="noWrap">${this._formatTime(this.revision.dateTime)}</span>
              <span class="noWrap">av ${this._revisionChangedBy(this.revision.changedBy, this.singleUserVersion)}</span>
            </div>
            <div class="diffColorInfo">
              <div>
                <span class="diff-html-added">${prompts.addedDescription}</span>
              </div>
              <div>
                <span class="diff-html-removed">${prompts.removedDescription}</span>
              </div>
              <div>
                <span>Tekst uten markering blir ikke endret.</span>
              </div>
            </div>
          </div>

          <d-label label="Navn"></d-label>
          <div class="html">${unsafeHTML(this.revision.differences.name)}</div>
          <d-label label="Innhold"></d-label>
          <div class="html">
            ${unsafeHTML(convertInternalLinks(this.revision.differences.content, this.docsForLinking))}
          </div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-entity-diff': DEntityDiff;
  }
}
