import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

/**
 *
 * STATUS OK
 */
@customElement('d-level-touch-slider')
export class DLevelTouchSlider extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;
  @property({ type: Boolean })
  enabled = false;
  @property({ type: Number })
  triggerpoint = 200;
  @property({ type: Boolean, attribute: 'trigger-without-release' })
  triggerWithoutRelease = false;
  @property({ type: Number })
  speed = 300;
  @state()
  private touchScroll = false;
  @state()
  private initialTop = 0;
  @state()
  private touchStartPos = 0;

  constructor() {
    super();
    this.addEventListener('touchstart', this.onTouchStart);
    this.addEventListener('touchmove', this.onTouchMove);
    this.addEventListener('touchend', this.onTouchEnd);
    this.addEventListener('touchcancel', this.onTouchEnd);
  }

  private get topStyleValue(): number {
    const defaultView = this.ownerDocument.defaultView;
    if (defaultView && defaultView.getComputedStyle) {
      const top = defaultView.getComputedStyle(this, null).getPropertyValue('top');
      return Number(top.replace('px', ''));
    }
    return 0;
  }

  private setTouchScroll() {
    this.touchScroll = true;
    this.style.touchAction = 'none';
    this.style.transition = 'top 0s';
  }

  private unsetTouchScroll() {
    this.touchScroll = false;
    this.removeAttribute('style');
  }

  private onTouchStart(e: TouchEvent) {
    if (this.enabled && window.scrollY === 0) {
      this.touchStartPos = e.touches[0].clientY;
      this.initialTop = this.topStyleValue;
    }
  }

  private onTouchMove(e: TouchEvent) {
    if (this.enabled) {
      const pos = e.touches[0].clientY;
      if (pos > this.touchStartPos) {
        this.setTouchScroll();
      }
      if (this.touchScroll) {
        const touchDistance = (this.touchStartPos - pos) * -1;
        if (this.initialTop + touchDistance > this.triggerpoint && this.triggerWithoutRelease) {
          this.fireTriggered();
          this.unsetTouchScroll();
        } else {
          this.style.top = this.initialTop + touchDistance + 'px';
        }
      }
    }
  }

  private fireTriggered() {
    this.dispatchEvent(
      new CustomEvent('triggered', {
        composed: true,
        bubbles: true,
      }),
    );
  }

  private onTouchEnd() {
    if (this.enabled && !this.triggerWithoutRelease) {
      const currentTop = this.topStyleValue;
      if (this.touchScroll && currentTop > this.initialTop) {
        this.style.transition = 'top ' + this.speed * 0.001 + 's';
        if (currentTop - this.initialTop > this.triggerpoint) {
          const height = document.documentElement.clientHeight + 10;
          this.style.top = height + 'px';
          setTimeout(() => {
            this.fireTriggered();
          }, this.speed);
        } else {
          this.style.top = '0px';
        }
      }
    }
    setTimeout(() => {
      this.unsetTouchScroll();
    }, this.speed);
  }

  render() {
    return html`<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-level-touch-slider': DLevelTouchSlider;
  }
}
