import type { EmployeeViewModelWithName, OrganizationState, State } from '../types.js';
import type { ContractViewModel, EmployeeViewModel, FunctionViewModel } from '../api';
import { createSelector } from '@reduxjs/toolkit';
import { _sortByFirstAndLastName, _sortByName, _sortByValue, groupBy, isCurrentUser, toList } from './utilities.js';
import { getCurrentUserEmail } from './session.js';
import { getOrganization } from './organization.js';
import { displayName, entityNameForLists, joinWithAnd } from 'src/utilities/text';
import type { ListSectionItemInput } from 'src/library/lists/utilities';
import { contractsNotDeleted } from 'src/store';
import { FeatureStates } from 'src/store/selectors/features';
import _ from 'lodash';
import { EmployeeGroup } from 'src/pages/employees-page/d-employees-page-content';

export function _filterByEmployeeId(list: ContractViewModel[], uuid?: string): ContractViewModel[] {
  if (!list || !uuid) {
    return [];
  }

  return list
    .filter((item) => {
      if (!item.employees) {
        return false;
      }
      return item.employees.includes(uuid);
    })
    .sort(_sortByName);
}

function getEmployeesById(state: State): { [uuid: string]: EmployeeViewModel } {
  return state.organization !== undefined ? state.organization.employeesById : {};
}

const getEmployees = createSelector(getEmployeesById, (employeesById): EmployeeViewModel[] => toList(employeesById));

export const getEmployeesWithForAccessControl = createSelector(
  getEmployees,
  getCurrentUserEmail,
  (employees, currentUserEmail) =>
    employees
      .filter((e) => !e.deleted)
      .filter((e) => e.isConfirmedEntity)
      .filter((e) => e.status !== 'TERMINATED')
      .map((e) => ({
        id: e.uuid,
        value: e.firstName + ' ' + e.lastName,
        disabled: isCurrentUser(e.email, currentUserEmail),
      }))
      .sort(_sortByValue),
);

export const currentEmployeeUuid = createSelector(getEmployees, getCurrentUserEmail, (employees, currentUserEmail) => {
  const find = employees
    .filter(function (e) {
      return !e.deleted;
    })
    .filter(function (e) {
      return e.status !== 'TERMINATED';
    })
    .filter(function (e) {
      return isCurrentUser(e.email, currentUserEmail);
    });

  if (find.length === 1) {
    return find[0].uuid;
  } else {
    return undefined;
  }
});

export const currentEmployeeAsViewModel = createSelector(
  getEmployees,
  getCurrentUserEmail,
  (employees, currentUserEmail) => {
    const find = employees
      .filter(function (e) {
        return !e.deleted;
      })
      .filter(function (e) {
        return e.status !== 'TERMINATED';
      })
      .filter(function (e) {
        return isCurrentUser(e.email, currentUserEmail);
      });

    if (find.length === 1) {
      return find[0];
    }

    return undefined;
  },
);

export const isCurrentUserEmployee = createSelector(currentEmployeeUuid, function (uuid) {
  return uuid !== null && uuid !== undefined && uuid !== '';
});

export const getEmployeesAsUsers = createSelector(getEmployees, function (employees) {
  return employees
    .filter(function (e) {
      return !e.deleted;
    })
    .filter(function (e) {
      return e.status !== 'TERMINATED';
    })
    .map(function (e) {
      return {
        name: e.firstName + ' ' + e.lastName,
        accessLevel: e.accessLevel,
        uuid: e.uuid,
        email: e.email,
      };
    })
    .sort(_sortByName);
});

export const getEmployeesWithAccessForAdministrator = createSelector(getEmployees, function (employees) {
  return employees
    .filter(function (e) {
      return !e.deleted;
    })
    .filter(function (e) {
      return e.status !== 'TERMINATED';
    })
    .filter(function (e) {
      return e.accessLevel !== 'NONE';
    })
    .filter(function (e) {
      return e.email;
    })
    .map(function (e) {
      return {
        name: e.firstName + ' ' + e.lastName,
        firstName: e.firstName,
        lastName: e.lastName,
        uuid: e.uuid,
        email: e.email,
      };
    })
    .sort(_sortByName);
});
interface EmployeePeriod {
  start: string;
  startTime?: string;
  end: string;
  endTime?: string;
  type: string;
  grade: number;
  subPeriods?: EmployeePeriod[];
  confirmed: boolean;
  notes: string;
  id?: string;
}

export const employeesNotDeleted = createSelector(getOrganization, (organization): EmployeeViewModelWithName[] => {
  if (organization === undefined) {
    return [];
  }
  return toList(organization.employeesById)
    .filter(function (e) {
      return !e.deleted;
    })
    .sort(_sortByFirstAndLastName);
});

export const employeesNotTerminatedCount = createSelector(employeesNotDeleted, (employees): number => {
  return employees.filter((e) => e.status !== 'TERMINATED').length;
});

export function employeesDeleted(state: State): EmployeeViewModelWithName[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.employeesById).filter(function (e) {
    return e.deleted;
  });
}

export const employeeNamesById = createSelector(getOrganization, function (organization) {
  const result: { [uuid: string]: string } = {};

  if (organization) {
    toList(organization.employeesById).forEach(function (e) {
      result[e.uuid] = e.firstName + ' ' + e.lastName;
    });
  }
  return result;
});

export function employeeNamesAsText(item: { employees?: string[] }, o: OrganizationState): string {
  if (item.employees === undefined) {
    return 'Ingen';
  }
  const a = item.employees
    .map((id) => o.employeesById[id])
    .filter((e) => e && !e.deleted)
    .map((e) => e.name)
    .sort();

  if (a.length === 0) {
    return 'Ingen';
  } else {
    return a.join(', ');
  }
}

export function employeeShortNamesAndIAsText(employees: string[], state: State): string {
  const names: string[] = employeesShortNamesAndI(state)
    .filter((e) => {
      return employees.includes(e.uuid);
    })
    .map((e) => {
      return e.name;
    });
  return joinWithAnd(names);
}

export const employeesShortNames = createSelector(employeesNotDeleted, function (employees: EmployeeViewModel[]): {
  uuid: string;
  status: string;
  name: string;
}[] {
  const result: { uuid: string; status: string; name: string }[] = [];
  employees.forEach(function (e) {
    let status = '';
    if (e.status) {
      status = e.status;
    }
    let firstName = e.firstName ?? '';
    const firstNames = firstName.split(' ');
    if (firstNames.length > 1) {
      firstName = firstNames[0];
      for (let i = 0; i < firstNames.length; i++) {
        if (i !== 0 && firstNames[i]) {
          firstName += ' ' + firstNames[i].substring(0, 1) + '.';
        }
      }
    }
    let shortName = firstName;
    const othersWithSameFirstName = employees.filter(function (employee) {
      return firstName === employee.firstName && e.uuid !== employee.uuid;
    });
    if (othersWithSameFirstName.length) {
      shortName = firstName + ' ' + e.lastName.substring(0, 1) + '.';
      const othersWithSameFirstNameAndLastInitial = othersWithSameFirstName.filter(function (employee) {
        return e.lastName.startsWith(employee.lastName.substring(0, 1));
      });
      if (othersWithSameFirstNameAndLastInitial.length) {
        shortName = firstName + ' ' + e.lastName;
      }
    }
    result.push({ uuid: e.uuid, status: status, name: shortName });
  });
  return result;
});

export interface ShortNameEntry {
  uuid: string;
  status: string;
  name: string;
}

function employeesShortNamesAndCurrentUserPronoun(
  employeesShortNames: ShortNameEntry[],
  currentEmployeeUuid: string,
  currentUserPronoun: string,
): ShortNameEntry[] {
  if (currentEmployeeUuid === undefined) {
    return employeesShortNames;
  }
  const result = employeesShortNames.filter(function (item) {
    return item.uuid !== currentEmployeeUuid;
  });
  const currentEmployee = employeesShortNames.filter(function (item) {
    return item.uuid === currentEmployeeUuid;
  })[0];
  result.push({
    uuid: currentEmployeeUuid,
    status: currentEmployee.status,
    name: currentUserPronoun,
  });
  return result;
}

export const employeesShortNamesAndI = createSelector(
  employeesShortNames,
  currentEmployeeUuid,
  function (employeesShortNames: ShortNameEntry[], currentEmployeeUuid): ShortNameEntry[] {
    if (currentEmployeeUuid === undefined) {
      return employeesShortNames;
    }
    return employeesShortNamesAndCurrentUserPronoun(employeesShortNames, currentEmployeeUuid, 'jeg');
  },
);

export const employeesShortNamesAndMe = createSelector(
  employeesShortNames,
  currentEmployeeUuid,
  function (
    employeesShortNames: { uuid: string; status: string; name: string }[],
    currentEmployeeUuid,
  ): { uuid: string; status: string; name: string }[] {
    if (currentEmployeeUuid === undefined) {
      return employeesShortNames;
    }
    return employeesShortNamesAndCurrentUserPronoun(employeesShortNames, currentEmployeeUuid, 'meg');
  },
);

export const currentEmployeeShortName = createSelector(
  employeesShortNames,
  currentEmployeeUuid,
  function (employees: { uuid: string; status: string; name: string }[], currentEmployeeUuid): string | undefined {
    const names = employees.filter(function (e) {
      return e.uuid === currentEmployeeUuid;
    });
    if (names.length) {
      return names[0].name;
    }
    return undefined;
  },
);

export function employeeContractsAsListItems(state: State, href: string, uuid: string): ListSectionItemInput[] {
  const current = currentEmployeeUuid(state);
  return contractsNotDeleted(state)
    .filter((c) => c.employees?.includes(uuid))
    .map((c) => ({
      href: href + '/contracts/' + c.uuid,
      label: c.name ?? '',
      locked: c.classification !== 'NONE',
      accessible: c.classification === 'NONE' || (current !== undefined && (c.accessControl ?? []).includes(current)),
      hasDraft: c.hasDraft,
    }));
}

/**
 * Groups employees based on association to the organization. The terminated employees are show seperated in a filtered list.
 *
 * For employees the assigned functions are added as related items.
 *
 * @param employees
 */

function relatedFunctions(employeeId: string, functions: FunctionViewModel[]): string[] {
  const result = functions
    .filter((f) => f.type !== 'COMMON')
    .filter((f) => f.employees.includes(employeeId))
    .map((f) => displayName(f.name));
  return result.sort((a, b) => a.localeCompare(b));
}
function toGroupedEmployees(
  hrefPrefix: string,
  employees: EmployeeViewModelWithName[],
  functionsNotDeleted: FunctionViewModel[],
): EmployeeGroup[] {
  const groupedEmployees = groupBy(employees, (e) =>
    e.status === 'TERMINATED' ? 'TERMINATED' : (e.associationType ?? '').toLocaleLowerCase(),
  );
  const result: EmployeeGroup[] = _.sortBy(groupedEmployees, [
    (g) => (g.group === 'TERMINATED' ? 1 : 0),
    (g) => g.group === '',
    (g) => g.group,
  ]).map((g) => {
    return {
      associationTypeName:
        g.group === 'TERMINATED' ? 'Sluttet' : g.items[0].associationType ?? 'Tilknytning ikke angitt',
      terminated: g.group === 'TERMINATED',
      employees: g.items.map((e) => {
        let href = hrefPrefix + 'employees/' + e.uuid;
        if (!e.isConfirmedEntity) {
          href += '?edit';
        }
        return {
          uuid: e.uuid,
          name: entityNameForLists(e.name, e.draftName, e.isConfirmedEntity),
          profession: e.profession ?? '',
          accessControl: [],
          classification: 'NONE',
          locked: false,
          accessible: true,
          href,
          functions: relatedFunctions(e.uuid, functionsNotDeleted),
          hasDraft: e.hasDraft,
        };
      }),
    };
  });
  return result;
}

export function employeeGroupsAsListItems(
  hrefPrefix: string,
  employees: EmployeeViewModelWithName[],
  functions: FunctionViewModel[],
  featureStates: FeatureStates,
) {
  const employeeGroups = toGroupedEmployees(hrefPrefix, employees, functions);
  return employeeGroups.map((group) => {
    const result: ListSectionItemInput = {
      label: group.associationTypeName,
      accessible: false,
      items: group.employees.map((employee) => ({
        label: employee.name,
        labelItems: featureStates.core ? employee.functions : [],
        href: employee.href,
        accessible: true,
        hasDraft: employee.hasDraft,
      })),
    };
    if (group.terminated) {
      result.sublistToggle = true;
      result.sublistHidden = true;
    }
    return result;
  });
}

export function employeeOptions(state: State, selected: string | undefined = undefined) {
  const options = employeesNotDeleted(state)
    .filter((e) => {
      return e.status !== 'TERMINATED' && e.isConfirmedEntity;
    })
    .map((e) => {
      return { value: e.uuid, text: e.name };
    });
  if (selected) {
    const selectedIncluded = options.find((o) => {
      return o.value === selected;
    });
    if (!selectedIncluded) {
      const selectedEmployee = employeesNotDeleted(state)
        .concat(employeesDeleted(state))
        .find((e) => {
          return e.uuid === selected;
        });
      if (selectedEmployee) {
        options.push({ value: selectedEmployee.uuid, text: selectedEmployee.name });
      }
    }
  }
  return options;
}
