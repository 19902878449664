/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvoiceProduct } from './InvoiceProduct';
import { InvoiceProductFromJSON, InvoiceProductFromJSONTyped, InvoiceProductToJSON } from './InvoiceProduct';

/**
 *
 * @export
 * @interface InvoiceMessage
 */
export interface InvoiceMessage {
  /**
   *
   * @type {string}
   * @memberof InvoiceMessage
   */
  expires?: string;
  /**
   *
   * @type {Array<InvoiceProduct>}
   * @memberof InvoiceMessage
   */
  products?: Array<InvoiceProduct>;
  /**
   *
   * @type {string}
   * @memberof InvoiceMessage
   */
  comment?: string;
}

/**
 * Check if a given object implements the InvoiceMessage interface.
 */
export function instanceOfInvoiceMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function InvoiceMessageFromJSON(json: any): InvoiceMessage {
  return InvoiceMessageFromJSONTyped(json, false);
}

export function InvoiceMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    expires: !exists(json, 'expires') ? undefined : json['expires'],
    products: !exists(json, 'products') ? undefined : (json['products'] as Array<any>).map(InvoiceProductFromJSON),
    comment: !exists(json, 'comment') ? undefined : json['comment'],
  };
}

export function InvoiceMessageToJSON(value?: InvoiceMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    expires: value.expires === undefined ? undefined : value.expires,
    products: value.products === undefined ? undefined : (value.products as Array<any>).map(InvoiceProductToJSON),
    comment: value.comment,
  };
}
