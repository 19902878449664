import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 *
 * STATUS OK
 */
@customElement('d-progress-bar')
export class DProgressBar extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      height: 12px;
      background-color: hsla(0, 0%, 0%, 0.1);
      border-radius: 6px;
      overflow: hidden;
    }

    #progress {
      height: 100%;
      background-color: var(--themeColorDarkerOne);
      transition: all 0.5s;
    }
  `;

  private progressStyle(total, part) {
    return 'width: ' + (part / total) * 100 + '%;';
  }

  @property({ type: Number })
  total = 0;
  @property({ type: Number })
  partNumber = 0;
  render() {
    return html` <div id="progress" style="${this.progressStyle(this.total, this.partNumber)}"></div> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-progress-bar': DProgressBar;
  }
}
