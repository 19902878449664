import '../elements/d-tooltip.js';
import { LabeledElement } from '../abstracts/labeled-element.js';
import { customElement, property } from 'lit/decorators.js';
import { html } from 'lit-html';
import { css } from 'lit';

export interface Checklist {
  description: string;
  groups: { description: string; items: string[] }[];
}

/**
 *
 * STATUS OK
 */
@customElement('d-checklist')
export class DChecklist extends LabeledElement {
  static readonly styles = [
    super.styles[0],
    css`
      .description {
        margin-bottom: 10px;
        font-weight: 500;
      }

      .description-group {
        font-weight: 600;
      }

      ul {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 30px;
        -webkit-padding-start: 30px;
        line-height: 150%;
        list-style-type: none;
      }

      li {
        padding: 6px 30px;
        background: url('/images/check-mini-gray.svg') 0 5px no-repeat;
        background-size: 24px 24px;
      }
    `,
  ];

  @property({ type: Object })
  checklist!: Checklist;

  classes = 'big';

  renderContent() {
    return html`
      <p class="systemText description description-list">${this.checklist.description}</p>
      ${this.checklist.groups.map((g) => this.renderGroup(g))}
    `;
  }

  renderGroup(group: { description: string; items: string[] }) {
    return html` <p class="systemText description description-group">${group.description}</p>
      <ul>
        ${group.items.map((item) => html` <li>${item}</li>`)}
      </ul>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-checklist': DChecklist;
  }
}
