/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FunctionRotationUpdateMessage
 */
export interface FunctionRotationUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof FunctionRotationUpdateMessage
   */
  employee?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionRotationUpdateMessage
   */
  day1?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionRotationUpdateMessage
   */
  day2?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionRotationUpdateMessage
   */
  day3?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionRotationUpdateMessage
   */
  day4?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionRotationUpdateMessage
   */
  day5?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionRotationUpdateMessage
   */
  day6?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionRotationUpdateMessage
   */
  day7?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionRotationUpdateMessage
   */
  startDate?: string;
}

/**
 * Check if a given object implements the FunctionRotationUpdateMessage interface.
 */
export function instanceOfFunctionRotationUpdateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function FunctionRotationUpdateMessageFromJSON(json: any): FunctionRotationUpdateMessage {
  return FunctionRotationUpdateMessageFromJSONTyped(json, false);
}

export function FunctionRotationUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FunctionRotationUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    employee: !exists(json, 'employee') ? undefined : json['employee'],
    day1: !exists(json, 'day1') ? undefined : json['day1'],
    day2: !exists(json, 'day2') ? undefined : json['day2'],
    day3: !exists(json, 'day3') ? undefined : json['day3'],
    day4: !exists(json, 'day4') ? undefined : json['day4'],
    day5: !exists(json, 'day5') ? undefined : json['day5'],
    day6: !exists(json, 'day6') ? undefined : json['day6'],
    day7: !exists(json, 'day7') ? undefined : json['day7'],
    startDate: !exists(json, 'startDate') ? undefined : json['startDate'],
  };
}

export function FunctionRotationUpdateMessageToJSON(value?: FunctionRotationUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    employee: value.employee,
    day1: value.day1,
    day2: value.day2,
    day3: value.day3,
    day4: value.day4,
    day5: value.day5,
    day6: value.day6,
    day7: value.day7,
    startDate: value.startDate === undefined ? undefined : value.startDate,
  };
}
