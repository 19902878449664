import { css, html, LitElement, nothing, PropertyValues } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import 'src/library/editors/elements/d-edit-text';
import 'src/library/fields/d-view-info';
import 'src/library/elements/d-section';
import 'src/library/fields/d-spinner-robot';

import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { isEmailValid } from 'src/store';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { Suggestion } from 'src/library/editors/components/d-edit-select-text';
import 'src/library/editors/components/d-edit-select-text';
import { SubmitEvent } from 'happy-dom';
import type { GestureType } from 'src/library/fields/d-spinner-robot';

export interface OrganizationForSignup {
  id: number;
  name: string;
  modules: string[];
}

export interface UserForSignup {
  email: string;
  firstName: string;
  lastName: string;
  organizations: OrganizationForSignup[];
}

/**
 *
 */
@customElement('d-signup')
export class DSignup extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      min-height: 100vh;
      box-sizing: border-box;
      background: var(--outskirts-background-color);
      -webkit-animation: 2s ease 0s normal forwards 1 fadein;
      animation: 2s ease 0s normal forwards 1 fadein;
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }
      33% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes fadein {
      0% {
        opacity: 0;
      }
      33% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .wrapper {
      max-width: 500px;
      padding-top: 60px;
      margin: 0 auto;
    }

    .logo {
      width: 146px;
      height: 37px;
      background: url(/images/trinnvis-logo.svg) 50% 50% no-repeat;
      background-size: contain;
      margin: 0 auto;
      margin-bottom: 14px;
    }

    d-view-info {
      margin: 8px 0;
    }

    .submit {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }

    input {
      border: none;
      box-shadow: none;
      padding: 5px 10px 6px 10px;
      resize: none;
      border-radius: 0;
      appearance: none;
      writing-mode: horizontal-tb;
      width: 100%;
      box-sizing: border-box;
      font-family: var(--mainSans), sans-serif;
      font-weight: 200;
      font-size: 15px;
      line-height: 160%;
      color: black;
    }

    input[type='email'],
    input[type='password'] {
      font-family: var(--mainSans);
      font-size: 15px;
      text-align: center;
      border-radius: 4px;
    }

    input[type='submit'] {
      width: auto;
      padding: 8px 12px;
      display: inline-block;
      font-size: 11px;
      letter-spacing: 2px;
      font-weight: 500;
      color: white;
      background-color: hsla(0, 0%, 0%, 0.2);
      border-radius: 4px;
      cursor: pointer;
    }

    input[type='submit'][disabled] {
      opacity: 0.6;
      cursor: default;
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    .button {
      width: auto;
      padding: 8px 12px;
      display: inline-block;
      font-size: 15px;
      font-weight: 200;
      color: white;
      background-color: hsla(0, 0%, 0%, 0.2);
      border-radius: 4px;
      cursor: pointer;
    }

    a {
      color: var(--linkColorGray);
      text-decoration: none;
      cursor: pointer;
    }

    @media (hover: hover) {
      input[type='submit']:not([disabled]):hover,
      .button:hover {
        background-color: hsla(0, 0%, 0%, 0.3);
      }
    }

    .organizations {
      margin-bottom: 12px;
    }

    .organizations > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-top: 1px solid var(--borderColorTransparent);
      padding: 6px 0 5px 0;
      color: var(--linkColorGray);
      cursor: pointer;
    }

    .organizations > div > div {
      padding: 3px 0;
    }

    .organizations > div > div:last-child {
      flex-grow: 1;
      text-align: right;
    }

    @media (hover: hover) {
      .organizations > div:hover {
        color: black;
        background-color: hsla(0, 0%, 100%, 0.1);
      }
    }

    .organizations > div:last-child {
      border-bottom: 1px solid var(--borderColorTransparent);
    }

    d-spinner-robot > div {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 200;
      color: white;
    }
  `;
  @property({ type: Array })
  modules: SelectDropdownOption[] = [];
  @property({ type: String })
  requestedModule = 'meetings';
  @property({ type: String })
  email = '';
  @property({ type: Object })
  currentUser: UserForSignup | undefined = undefined;
  @property({ type: String })
  submittedEmail = '';
  @property({ type: Boolean })
  emailExists = false;
  @property({ type: String })
  firstName = '';
  @property({ type: String })
  lastName = '';
  @property({ type: String })
  organizationName = '';
  @property({ type: String })
  organizationNumber = '';
  @state()
  loginSelected = false;
  @state()
  addOrganization = false;
  @state()
  suggestedOrganizations: Suggestion[] = [];

  private emailExistsMessage = `<p>Epostadressen ${this.submittedEmail} er allerede registrert som brukernavn i TrinnVis.</p>
        <p>Vil du logge inn som denne brukeren, eller velge en annen epostadresse?</p>`;

  @state()
  private loading = false;

  private get organizationsMessage() {
    if (this.currentUser?.organizations.length === 1) {
      return `<p>Du er allerede registrert med en virksomhet.</p>
        <p>Trykk på virksomhetsnavnet for å gå til denne virksomheten, eller legg til en ny virksomhet.</p>`;
    }
    return `<p>Du er allerede registrert med flere virksomheter.</p>
        <p>Velg en av virksomhetene i listen, eller legg til en ny virksomhet.</p>`;
  }

  private get loggedInAsOtherMessage() {
    if (this.emailExists) {
      return `<p>Du er innlogget i TrinnVis som ${this.currentUser?.email ?? ''}.</p>
      <p>Epostadressen ${this.submittedEmail} er også registrert som brukernavn i TrinnVis.</p>
      <p>Vil du forsette som ${this.currentUser?.email ?? ''}, eller vil du logge deg inn som ${this.submittedEmail}? </p>`;
    }
    return `<p>Du er innlogget i TrinnVis som ${this.currentUser?.email ?? ''}.</p>
        <p>Vil du forsette som denne brukeren, eller vil du lage en ny bruker med epostadressen du har oppgitt?</p>`;
  }

  private get requestedModuleName() {
    return (
      this.modules.find((m) => {
        return m.value === this.requestedModule;
      })?.text ?? ''
    );
  }

  private get emailFormValid() {
    return isEmailValid(this.email);
  }

  private get organizationFormValid() {
    return (
      !isEmptyOrInvalidString(this.firstName) &&
      !isEmptyOrInvalidString(this.lastName) &&
      !isEmptyOrInvalidString(this.organizationName)
    );
  }

  renderLogin() {
    return html`<d-login @request-login=${this.onRequestLogin}
      ><slot slot="usernameInput" name="usernameInput"></slot> <slot slot="passwordInput" name="passwordInput"></slot
    ></d-login> `;
  }

  renderSubmitEmail() {
    return html`
      <form @submit=${this.submitEmail} @keyup=${this.onEmailFormKeyup}>
        <d-section borderless topless>
          <d-edit-text
            outskirts
            system-content
            autofocus
            class="fullWidth"
            label="Epost"
            .value=${this.email}
            @value-changed=${(e) => {
              this.email = e.detail.value;
            }}
          ></d-edit-text>
          <div class="submit">
            <input type="submit" value="REGISTRER" ?disabled="${!this.emailFormValid}" />
          </div>
        </d-section>
      </form>
    `;
  }

  renderOrganizations() {
    return html` <d-section borderless topless>
      <d-edit-text outskirts system-content class="fullWidth" label="Epost" .value=${this.email} disabled></d-edit-text>
      <d-view-info .content=${this.organizationsMessage}></d-view-info>
      <div class="organizations fullWidth">
        ${this.currentUser?.organizations.map((o) => {
          return html`<div>
            <div><a href="/account/${o.id}/9772">${o.name}</a></div>
            <div>${this.organizationNote(o)}</div>
          </div>`;
        })}
      </div>
      <div class="buttons">
        <div class="button" @click=${() => this.selectAddOrganization()}>Legg til en ny virksomhet</div>
      </div>
    </d-section>`;
  }

  loggedInAsSame() {
    if (this.currentUser?.organizations.length && !this.addOrganization) {
      return this.renderOrganizations();
    }
    return this.renderSubmitOrganization();
  }

  loggedInAsOther() {
    if (this.emailExists) {
      return html`
        <d-section borderless topless>
          <d-edit-text
            outskirts
            system-content
            class="fullWidth"
            label="Epost"
            .value=${this.email}
            disabled
          ></d-edit-text>
          <d-view-info .content=${this.loggedInAsOtherMessage}></d-view-info>
          <div class="buttons">
            <div class="button" @click=${() => this.selectCurrentUser()}>
              Fortsett som ${this.currentUser?.email ?? ''}
            </div>
            <div class="button" @click=${() => this.selectLoginAsSubmitted()}>Logg inn som ${this.submittedEmail}</div>
          </div>
        </d-section>
      `;
    }
    return html`
      <d-section borderless topless>
        <d-edit-text
          outskirts
          system-content
          class="fullWidth"
          label="Epost"
          .value=${this.email}
          disabled
        ></d-edit-text>
        <d-view-info .content=${this.loggedInAsOtherMessage}></d-view-info>
        <div class="buttons">
          <div class="button" @click=${() => this.selectCurrentUser()}>
            Fortsett som ${this.currentUser?.email ?? ''}
          </div>
          <div class="button" @click=${() => this.selectLogout()}>Lag ny bruker med ${this.submittedEmail}</div>
        </div>
      </d-section>
    `;
  }

  renderLoggedIn() {
    if (this.email === this.currentUser?.email) {
      return this.loggedInAsSame();
    }
    return this.loggedInAsOther();
  }

  renderSubmitOrganization() {
    const gesture: GestureType[] = ['roll', 'happy'];
    console.log('this.currentUser', this.currentUser);
    return html`
      <form @submit=${this.submitOrganization} @keyup=${this.onOrganizationFormKeyup}>
        <d-section borderless topless>
          <d-edit-text
            outskirts
            system-content
            disabled
            class="fullWidth"
            label="Epost"
            .value=${this.submittedEmail}
          ></d-edit-text>
          ${this.loading
            ? html` <d-spinner-robot style="margin-top: 200px" .gesture=${gesture}>
                  <div>Oppretter konto</div> </d-spinner-robot
                >\`,`
            : html` <d-edit-text
                  outskirts
                  system-content
                  autofocus
                  label="Fornavn"
                  .value=${this.firstName}
                  ?disabled="${(this.currentUser?.email === this.submittedEmail &&
                    this.currentUser?.firstName !== '') ||
                  this.loading}"
                  @value-changed=${(e) => {
                    this.firstName = e.detail.value;
                  }}
                ></d-edit-text>
                <d-edit-text
                  outskirts
                  system-content
                  label="Etternavn"
                  .value=${this.lastName}
                  ?disabled="${this.currentUser?.email === this.submittedEmail && this.currentUser?.lastName !== ''}"
                  @value-changed=${(e) => {
                    this.lastName = e.detail.value;
                  }}
                ></d-edit-text>
                <d-edit-select-text
                  outskirts
                  ?disabled="${this.loading}"
                  system-content
                  ?autofocus="${this.currentUser?.email === this.submittedEmail}"
                  class="fullWidth"
                  label="Navn på virksomheten"
                  .value=${this.organizationName}
                  .items=${this.suggestedOrganizations}
                  @value-changed=${(e) => {
                    this.organizationName = e.detail.value;
                    this.organizationNumber = '';
                  }}
                  @value-selected=${(e) => {
                    this.organizationName = e.detail.value;
                    this.organizationNumber = e.detail.dataValue;
                  }}
                ></d-edit-select-text>
                <div class="buttons">
                  <div class="submit">
                    <input type="submit" value="FERDIG" ?disabled="${!this.organizationFormValid}" />
                  </div>
                  ${(this.currentUser?.organizations?.length ?? 0) > 0
                    ? html`<div class="button" style="margin-top:10px;" @click=${() => (this.addOrganization = false)}>
                        Tilbake
                      </div>`
                    : nothing}
                </div>`}
        </d-section>
      </form>
    `;
  }

  renderEmailExists() {
    return html`
      <d-section borderless topless>
        <d-edit-text
          outskirts
          system-content
          class="fullWidth"
          label="Epost"
          .value=${this.email}
          disabled
        ></d-edit-text>
        <d-view-info .content=${this.emailExistsMessage}></d-view-info>
        <div class="buttons">
          <div class="button" @click=${() => this.selectLoginAsSubmitted()}>Logg inn som ${this.submittedEmail}</div>
          <div class="button" @click=${() => this.selectResetEmail()}>Velg en annen epostadresse</div>
        </div>
      </d-section>
    `;
  }

  renderNotLoggedIn() {
    if (this.emailExists) {
      return this.renderEmailExists();
    }
    return this.renderSubmitOrganization();
  }

  renderEmailSubmitted() {
    if (this.currentUser) {
      return this.renderLoggedIn();
    }
    return this.renderNotLoggedIn();
  }

  render() {
    if (this.loginSelected) {
      return this.renderLogin();
    }
    if (this.currentUser) {
      this.email = this.currentUser.email;
      this.submittedEmail = this.currentUser.email;
      return html`
        <div class="wrapper">
          <div class="logo"></div>
          ${this.renderLoggedIn()}
        </div>
      `;
    }
    return html`
      <div class="wrapper">
        <div class="logo"></div>
        ${!this.submittedEmail ? html` ${this.renderSubmitEmail()} ` : html` ${this.renderEmailSubmitted()} `}
      </div>
    `;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    if (
      _changedProperties.has('submittedEmail') &&
      this.submittedEmail &&
      this.submittedEmail === this.currentUser?.email
    ) {
      this.firstName = this.currentUser.firstName;
      this.lastName = this.currentUser.lastName;
    }
    if (_changedProperties.has('organizationName')) {
      this.setOrganizationList(this.organizationName);
    }
  }

  protected willUpdate() {
    this.checkForOptions();
  }

  private onRequestLogin(e: CustomEvent<{ user: { username: string; password: string }; loginFailed: () => void }>) {
    e.preventDefault();
    e.stopPropagation();
    const params = {
      email: e.detail.user.username,
      emailExists: 'true',
      submittedEmail: e.detail.user.username,
      module: 'meetings',
      serviceType: '',
    };

    const p = new URLSearchParams(params).toString();

    this.dispatchEvent(
      new CustomEvent('request-login', {
        bubbles: true,
        composed: true,
        detail: { ...e.detail, target: '/signup?' + p },
      }),
    );
  }

  private organizationNote(o: OrganizationForSignup) {
    if (o.modules.includes('complete') || o.modules.includes(this.requestedModule)) {
      return this.requestedModuleName + ' er inkludert';
    }
    return 'Legg til ' + this.requestedModuleName;
  }

  private submitEmail(e: SubmitEvent) {
    e.preventDefault();
    if (this.emailFormValid) {
      console.log('submitUser ' + this.email);
      const o = {
        email: this.email,
        module: this.requestedModule,
        serviceType: '',
      };

      this.dispatchEvent(
        new CustomEvent<{ email: string; module: string; serviceType: string }>('create-user', {
          bubbles: true,
          composed: true,
          detail: o,
        }),
      );
    }
  }

  private onEmailFormKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      // event.preventDefault();
      // this.submitEmail();
    }
  }

  private selectAddOrganization() {
    this.addOrganization = true;
  }

  private selectCurrentUser() {
    if (this.currentUser) {
      this.email = this.currentUser.email;
      this.submittedEmail = this.email;
    }
  }

  private selectLoginAsSubmitted() {
    this.loginSelected = true;
  }

  private selectLogout() {
    this.currentUser = undefined;
  }

  private selectResetEmail() {
    this.email = '';
    this.submittedEmail = '';
  }

  private submitOrganization(e: SubmitEvent) {
    e.preventDefault();
    this.loading = true;
    console.log('submitOrganization ' + this.organizationName + '|' + this.organizationNumber);
    const o = {
      organizationNumber: this.organizationNumber !== '' ? this.organizationNumber : undefined,
      organizationName: this.organizationNumber === '' ? this.organizationName : undefined,
      module: this.requestedModule,
      firstName: this.firstName,
      lastName: this.lastName,
    };

    this.dispatchEvent(
      new CustomEvent<{
        organizationNumber?: string;
        organizationName?: string;
        module: string;
        lastName: string;
        firstName: string;
      }>('create-organization', { bubbles: true, composed: true, detail: o }),
    );
    setTimeout(() => {
      this.loading = false;
    }, 5000);
  }

  private onOrganizationFormKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      // event.preventDefault();
      // this.submitOrganization(event);
    }
  }

  private fixName(text: string): string {
    if (text) {
      const orgforms = ['AS', 'ANS', 'ASA', 'BA', 'DA', 'ENK'];
      const arr = text.split(' ');
      const capitalized = arr.map((item) => {
        if (!orgforms.includes(item)) {
          return item[0] + item.substring(1).toLowerCase();
        }
        if (item === '&') {
          return item;
        }
        return item.toLowerCase();
      });
      const arr2 = capitalized.join(' ').split('.');
      const result = arr2.map((item) => {
        if (item[0]) {
          return item[0].toUpperCase() + item.substring(1);
        }
        return item;
      });
      return result.join('.').replaceAll('&amp;', '&');
    }
    return '';
  }

  private setOrganizationList(name: string): void {
    const result: Suggestion[] = [];
    if (name) {
      fetch('https://data.brreg.no/enhetsregisteret/api/enheter?size=200&navn=' + name)
        .then((response) => response.json())
        .then((data) => {
          if (data._embedded) {
            data._embedded.enheter.forEach((item) => {
              if (item.navn.toLowerCase().includes(name.toLowerCase())) {
                const name = this.fixName(item.navn);
                const displayName = [
                  name,
                  item?.forretningsadresse?.postnummer ?? '',
                  item?.forretningsadresse?.poststed ?? '',
                ]
                  .join(' ')
                  .trim();
                result.push({
                  name: displayName,
                  type: '',
                  dataValue: item.organisasjonsnummer,
                });
              }
            });
            this.suggestedOrganizations = result;
          } else {
            this.suggestedOrganizations = [];
          }
        });
    } else {
      this.suggestedOrganizations = [];
    }
  }

  private checkForOptions() {
    const query = location.search.slice(1);
    const params = new URLSearchParams(query);

    let parametersFound = false;

    if (params.has('email')) {
      parametersFound = true;
      this.email = params.get('email') ?? '';
    }
    if (params.has('submittedEmail')) {
      parametersFound = true;
      this.submittedEmail = params.get('submittedEmail') ?? '';
    }

    if (params.has('module')) {
      parametersFound = true;
      this.requestedModule = params.get('module') ?? '';
    }
    if (params.has('emailExists')) {
      parametersFound = true;
      this.emailExists = params.get('emailExists') === 'true';
    }

    if (parametersFound) {
      history.replaceState({}, '', location.protocol + '//' + location.host + location.pathname);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-signup': DSignup;
  }
}
