import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../components/d-popup.js';
import '../fields/d-view-info.js';

/**
 *
 * Shows a '?' that displays the tooltip when clicked. If the content of the tooltip is a link to a help page, then
 * the help viewer is opened on that page.
 *
 * STATUS OK
 */
@customElement('d-tooltip')
export class DTooltip extends LitElement {
  static readonly styles = css`
    :host {
      position: relative;
      display: inline-block;
      text-align: left;
    }

    .trigger {
      display: inline-block;
      position: relative;
      top: -3px;
      width: 16px;
      height: 16px;
      margin-left: -4px;
      color: var(--themeColor);
      font-size: 15px;
      line-height: inherit;
      font-weight: 800;
      text-align: center;
      cursor: pointer;
      mix-blend-mode: multiply;
    }

    .trigger:hover,
    .triggern:active {
      color: black;
    }
  `;
  @property({ type: String })
  content = '';
  @property({ type: Boolean })
  open = false;

  _iconClick(event) {
    event.preventDefault();
    const matches = RegExp(/(href="https:\/\/trinnvis\.no\/hjelp\/)(.*?)(\/")/).exec(this.content);
    if (matches) {
      const helpPage = matches[2];
      this.dispatchEvent(
        new CustomEvent<{ page: string }>('update-help', { bubbles: true, composed: true, detail: { page: helpPage } }),
      );
    }
  }

  _close(e) {
    e.preventDefault();
    if (e.target.id === 'closer' || e.target.id === 'cover') {
      this.open = false;
    }
  }

  render() {
    return html`
      ${this.isLink()
        ? html`<a class="trigger" @click=${(e) => this._iconClick(e)}>?</a>`
        : html`<span class="trigger" @click="${this.onOpen}">?</span>`}
      <d-popup
        .show=${this.open}
        @close=${(e) => {
          e.stopPropagation();
          this.open = false;
        }}
      >
        <d-view-info .content=${this.content}></d-view-info>
      </d-popup>
    `;
  }

  private isLink(): boolean {
    return this.content?.startsWith('<p><a href="https://trinnvis.no/hjelp') === true;
  }

  private onOpen(event) {
    event.preventDefault();
    this.open = true;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-tooltip': DTooltip;
  }
}
