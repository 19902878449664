import { css, html, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import '../../library/elements/d-tooltip.js';
import '../../library/elements/d-label.js';
import './d-risk-gradient.js';

/**
 *
 * STATUS OK
 */
@customElement('d-edit-probability')
export class DEditProbability extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    d-risk-gradient {
      height: 4px;
    }

    .riskVisualizer table {
      margin-top: 0;
      margin-bottom: -12px;
    }

    .riskVisualizer table td.selected {
      color: black;
      font-weight: bold;
    }

    .riskVisualizer table td {
      cursor: pointer;
      width: 25%;
      text-align: center;
      color: silver;
      padding: 10px 4px 20px 4px;
      min-height: 40px;
      line-height: 15px;
      border: 0;
      font-family: inherit;
      font-size: 100%;
      font-style: inherit;
      font-weight: inherit;
      margin: 0;
      outline: 0;
      vertical-align: top;
    }

    .riskVisualizer table td:hover,
    .riskVisualizer table td:active {
      color: black;
    }

    .riskVisualizer table td.selected:hover,
    :host .riskVisualizer table td.selected:active {
      color: black;
    }
  `;
  @query('#probMask')
  probMask!: HTMLDivElement;
  /**
   * The value. A number between 1 and 4. Use 0 for unknown.
   */
  @property({ type: Number })
  value = 0;

  _selectOne() {
    this.value = 1;
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  _selectTwo() {
    this.value = 2;
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  _selectThree() {
    this.value = 3;
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  _selectFour() {
    this.value = 4;
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  _maskStyle(value) {
    this.probMask.style.width = 100 - value * 25 + '%';
  }

  _computeClass(value, n) {
    return value === n ? 'selected' : '';
  }

  handleIndexClicked(e: CustomEvent<{ index: number }>) {
    this.value = e.detail.index;
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  render() {
    return html`
      <div class="sectionElementInner">
        <d-label field="riskAssessments_probability"></d-label>

        <div class="riskVisualizer">
          <d-risk-gradient .value=${this.value * 4} @index-clicked=${this.handleIndexClicked}></d-risk-gradient>
          <table border="0" cellpadding="0" cellspacing="0" class="riskLabels" width="100%" role="presentation">
            <tbody>
              <tr>
                <td class="${this._computeClass(this.value, 1)}" @click=${() => this._selectOne()}>Lav</td>
                <td class="${this._computeClass(this.value, 2)}" @click=${() => this._selectTwo()}>Mode­rat</td>
                <td class="${this._computeClass(this.value, 3)}" @click=${() => this._selectThree()}>Høy</td>
                <td class="${this._computeClass(this.value, 4)}" @click=${() => this._selectFour()}>Svært høy</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-probability': DEditProbability;
  }
}
