/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateTemplateMessage
 */
export interface UpdateTemplateMessage {
  /**
   *
   * @type {number}
   * @memberof UpdateTemplateMessage
   */
  groups?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateTemplateMessage
   */
  index?: number;
}

/**
 * Check if a given object implements the UpdateTemplateMessage interface.
 */
export function instanceOfUpdateTemplateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UpdateTemplateMessageFromJSON(json: any): UpdateTemplateMessage {
  return UpdateTemplateMessageFromJSONTyped(json, false);
}

export function UpdateTemplateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTemplateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    groups: !exists(json, 'groups') ? undefined : json['groups'],
    index: !exists(json, 'index') ? undefined : json['index'],
  };
}

export function UpdateTemplateMessageToJSON(value?: UpdateTemplateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    groups: value.groups,
    index: value.index,
  };
}
