import { BaseInstanceRule } from './base-instance-rule.js';

export class DailyInstanceRule extends BaseInstanceRule {
  public matches(): boolean {
    return true;
  }

  public optionsToString(): string {
    return 'FREQ=DAILY;INTERVAL=1';
  }

  public scheduleDescription(): string {
    return 'Hver dag';
  }
}
