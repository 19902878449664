import { css, html, LitElement } from 'lit';
import './d-select-organization.js';
import { customElement, property } from 'lit/decorators.js';
import type { OrganizationReference } from '../../store/api';
import type { User } from '../../store/types.js';

/**
 *
 * STATUS OK
 */
@customElement('d-organization-organizations')
export class DOrganizationOrganizations extends LitElement {
  @property({ type: Array })
  organizations: OrganizationReference[] = [];
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Object })
  user!: User;

  static readonly styles = css`
    :host {
      display: block;
    }
  `;

  render() {
    return html`
      <d-select-organization
        .organizations=${this.organizations}
        .organizationId=${this.organizationId}
        .user=${this.user}
      >
      </d-select-organization>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-organizations': DOrganizationOrganizations;
  }
}
