import { css, html, PropertyValueMap } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

import '../../elements/d-label.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';

/**
 *
 *
 *  * Reference:
 *  * The Cleanest Trick for Autogrowing Textareas
 *  * https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
 *
 *
 *  https://github.com/meistudioli/msc-stretch-textarea/blob/main/mjs/wc-msc-stretch-textarea.js
 *
 * @fires value-changed - Dispatched immediately after changes by the user
 *
 * STATUS OK
 */
@customElement('d-edit-textarea')
export class DEditTextarea extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      .grow-wrap {
        display: grid;
      }

      .grow-wrap::after {
        content: attr(data-replicated-value) ' ';
        white-space: pre-wrap;
        visibility: hidden;
      }

      .grow-wrap > textarea {
        overflow: hidden;
        min-height: 45px;
        height: auto !important;
      }

      .grow-wrap > textarea,
      .grow-wrap::after {
        grid-area: 1 / 1 / 2 / 2;
        padding: 8px 10px 10px;
        font: inherit;
      }

      :host([toolbar]) textarea {
        box-shadow: none;
        background-color: hsl(0, 0%, 92%);
        border-radius: 4px;
      }
    `,
  ];
  @query('#text')
  textarea!: HTMLTextAreaElement;
  @property({ type: String })
  placeholder = '';
  @property({ type: Number })
  rows = 2;
  @property({ type: Number })
  maxRows = 7;
  @property({ type: String })
  value = '';
  /**
   * Should have input focus when the page loads.
   */
  @property({ type: Boolean })
  autofocus = false;
  @query('div')
  private _growWrapEl?: HTMLDivElement;

  focus() {
    this.textarea.focus();
  }

  inputHandler(event) {
    this.value = event.target.value;

    if (this._growWrapEl) {
      //   this._growWrapEl.dataset.replicatedValue = this.value;
    }
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  renderContent() {
    return html`
      <div class="grow-wrap" data-replicated-value="${this.value}">
        <textarea
          .max-rows="${this.maxRows}"
          .rows="${this.rows}"
          placeholder="${this.placeholder}"
          name="text"
          id="text"
          @input=${this.inputHandler}
          .value="${this.value}"
        >
        </textarea>
      </div>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (this.autofocus) {
      this.focus();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-textarea': DEditTextarea;
  }
}
