import type { RiskAssessmentViewModel } from '../../store/api';
import type { State } from '../../store/types.js';

import { activityCodesAvailable, BASE_PATH, key, riskAssessmentsNotDeleted } from '../../store';
import { LocalDate } from '../../utilities/local-date.js';
import { entityNameForLists } from 'src/utilities/text';
import { RiskAssessmentsPageViewModel } from 'src/pages/risk-assessments-page/d-risk-assessments-page-content';
import { calculateUpdateStatus } from 'src/models/resolvers/calculate-update-status';
import { riskLevelFromRisk } from 'src/models/risk';
import { sortBy } from 'lodash';
import { ListSectionItemInput, RiskLevels } from 'src/library/lists/utilities';
import { AbstractPageView } from 'src/pages/abstract-page-view';

function riskAssessments(list: RiskAssessmentViewModel[], hrefPrefix: string): ListSectionItemInput[] {
  return list.map((g) => {
    let href = hrefPrefix + 'riskAssessments/' + g.uuid;
    if (!g.isConfirmedEntity || !g.name) {
      href += '?edit';
    }
    let riskAssessmentRisk: RiskLevels = undefined;
    if (g.consequence && g.probability) {
      riskAssessmentRisk = riskLevelFromRisk(g.consequence * g.probability);
    }
    return {
      locked: false,
      accessible: true,
      updateStatus: calculateUpdateStatus(g, LocalDate.now()),
      hasDraft: g.hasDraft,
      label: entityNameForLists(g.name, g.draftName, g.isConfirmedEntity),
      secondaryLabel: '',
      href,
      riskAssessmentRisk,
    };
  });
}

export function riskAssessmentsPageView(
  hrefPrefix: string,
  viewModel: AbstractPageView,
  state: State,
): RiskAssessmentsPageViewModel {
  const o = state.organization;
  if (o === undefined) {
    throw new Error('Illegal state (E151), organization not found');
  }
  return {
    ...viewModel,
    type: 'risk-assessments-page',
    icon: 'riskAssessments',
    href: hrefPrefix,
    availableActivityCodes: activityCodesAvailable(),
    pdfHref: BASE_PATH + '/organizations/' + o.organizationId + '/overview.pdf?key=' + key(state),
    riskAssessments: sortBy(riskAssessments(riskAssessmentsNotDeleted(state), hrefPrefix), ['label']),
  };
}
