import { css, html, LitElement, PropertyValues } from 'lit';
import { property, customElement, state, query } from 'lit/decorators.js';

/**
 *
 * STATUS OK
 */
@customElement('d-mobile-app-navigation')
export class DMobileAppNavigation extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    .navigate {
      position: fixed;
      bottom: 20px;
      width: 40px;
      height: 40px;
      box-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0.3);
      border-radius: 20px;
      background: white;
      z-index: 60;
    }
    .navigate:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-top: 3px solid var(--themeColor);
      border-left: 3px solid var(--themeColor);
      position: absolute;
      top: 14px;
    }
    .navigate[disabled]:after {
      opacity: 0.5;
    }
    #prev {
      left: 20px;
    }
    #prev:after {
      transform: rotate(-45deg);
      left: 15px;
    }
    #next {
      left: 70px;
    }
    #next:after {
      transform: rotate(135deg);
      left: 12px;
    }
    .ui-toggle {
      box-sizing: border-box;
      width: 144px;
      height: 40px;
      position: fixed;
      bottom: 20px;
      left: calc(50vw - 72px);
      border-radius: 20px;
      padding: 10px 0 0 0;
      background: var(--themeColor);
      font-size: 16px;
      text-align: center;
      color: white;
      z-index: 60;
    }
  `;

  @property({ type: String })
  currentPath = '';
  @state()
  historyNav = false;
  @state()
  history: string[] = [];
  @state()
  historyIndex = 0;
  @query('#prev')
  prevElm!: HTMLElement;
  @query('#next')
  nextElm!: HTMLElement;

  private getHistory() {
    if (sessionStorage.getItem('history')) {
      const sessionHistory = sessionStorage.getItem('history');
      if (Array.isArray(sessionHistory)) {
        this.history = sessionHistory;
      }
    }
    if (sessionStorage.getItem('historyIndex')) {
      this.historyIndex = Number(sessionStorage.getItem('historyIndex'));
    }
  }

  private setHistory() {
    sessionStorage.setItem('history', JSON.stringify(this.history));
    sessionStorage.setItem('historyIndex', JSON.stringify(this.historyIndex));
  }

  private onPathChanged() {
    if (!this.historyNav) {
      this.getHistory();
      if (this.historyIndex < this.history.length - 1) {
        this.history.length = this.historyIndex + 1;
      }
      this.history.push(this.currentPath);
      this.historyIndex = this.history.length - 1;
      this.setHistory();
    }
    this.historyNav = false;
  }

  private prev() {
    if (!this.prevElm.hasAttribute('disabled')) {
      this.historyNav = true;
      window.history.back();
      if (sessionStorage.getItem('historyIndex')) {
        this.historyIndex = Number(sessionStorage.getItem('historyIndex'));
      }
      this.historyIndex = this.historyIndex - 1;
      sessionStorage.setItem('historyIndex', JSON.stringify(this.historyIndex));
    }
  }

  private next() {
    if (!this.nextElm.hasAttribute('disabled')) {
      this.historyNav = true;
      window.history.forward();
      if (sessionStorage.getItem('historyIndex')) {
        this.historyIndex = Number(sessionStorage.getItem('historyIndex'));
      }
      this.historyIndex = this.historyIndex + 1;
      sessionStorage.setItem('historyIndex', JSON.stringify(this.historyIndex));
    }
  }

  private get prevDisabled() {
    return !this.history.length || this.historyIndex === 0;
  }

  private get nextDisabled() {
    return !this.history.length || this.historyIndex > this.history.length - 2;
  }

  showSimplifiedUi() {
    this.dispatchEvent(
      new CustomEvent<{ isAppPlatform: boolean; isSimplifiedUi: boolean }>('update-ui-settings', {
        composed: true,
        bubbles: true,
        detail: {
          isAppPlatform: true,
          isSimplifiedUi: true,
        },
      }),
    );
  }

  render() {
    return html`
      <div id="prev" class="navigate" ?disabled=${this.prevDisabled} @click=${() => this.prev()}></div>
      <div id="next" class="navigate" ?disabled=${this.nextDisabled} @click=${() => this.next()}></div>
      <div class="ui-toggle" @click=${() => this.showSimplifiedUi()}>Vis hurtigvalg</div>
    `;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    if (_changedProperties.has('currentPath') && this.currentPath) {
      this.onPathChanged();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-mobile-app-navigation': DMobileAppNavigation;
  }
}
