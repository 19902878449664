import { css } from 'lit';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { sortedByNumberAndName } from 'src/store/utilities.js';

export type InfosecDataTypes = 'patientData' | 'managementData' | 'publicInformationAndCommunication';

export type InfosecCategories =
  | 'medicalRecordsSystems'
  | 'patientDialogSystems'
  | 'accounting'
  | 'managementSystems'
  | 'generalCloudServices'
  | 'remoteDocuments'
  | 'localDocuments'
  | 'paperDocuments'
  | 'assetData'
  | 'webServices'
  | 'emailServices'
  | 'emailAccounts'
  | 'socialMedia'
  | '';

export type InfosecDefaultApplicationTypes = 'cloud' | 'standalone' | 'any';

export type InfosecStorageUnitTypes = '' | 'computers' | 'remoteServers' | 'cloudServices' | 'assets' | 'locations';

export interface InfosecApplicationOption {
  type: InfosecDefaultApplicationTypes;
  value: string;
  text: string;
  provider: string;
}

export interface InfosecCategory {
  category: InfosecCategories;
  label: string;
  labelSingular?: string;
  field: string;
  applicationOptions?: InfosecApplicationOption[];
  applicationPlaceholder?: string;
  applicationAddText?: string;
}

export interface InfosecDataType {
  type: InfosecDataTypes;
  name: string;
  text: string;
  categories: InfosecCategory[];
}

export interface InfosecDataItem {
  uuid: string;
  name: string;
  dataType: string;
  category: string;
  personalData: boolean;
  dataProcessor: string;
  application: string;
  accessingEmployees: string[];
  accessingPartners: string[];
  assets: string[];
  storageUnitType: InfosecStorageUnitTypes;
  storageUnit: string;
  storageLocation: string;
  backupFrequency: string;
  backupResponsible: string;
  noBackupReason: string;
  createdDateTime: string;
}

export interface InfosecStorageUnit {
  uuid: string;
  name: string;
  number?: string;
  unitType: InfosecStorageUnitTypes;
  computerType: string;
  application: string;
  partnerUuid: string;
  networkUuid: string;
}

export interface InfosecNetwork {
  uuid: string;
  name: string;
  type: string;
  connectionType: string;
}

export interface InfosecAsset {
  uuid: string;
  name: string;
  number: string;
}

export interface InfosecExternalConnectionPartner {
  uuid: string;
  type: 'Tilkoblet samarbeidspartner';
  connectionType: string;
  networkUuid: string;
  partnerUuid: string;
}

export interface InfosecExternalConnectionEmployee {
  uuid: string;
  type: 'Hjemmekontor';
  connectionType: string;
  networkUuid: string;
  employeeUuid: string;
}

export interface InfosecExternalConnectionEmpty {
  uuid: string;
  type: '';
  connectionType: string;
  networkUuid: string;
  employeeUuid: string;
  partnerUuid: string;
}

export type InfosecExternalConnection =
  | InfosecExternalConnectionEmployee
  | InfosecExternalConnectionPartner
  | InfosecExternalConnectionEmpty;

export const responsiveFormStyles = css`
  table {
    position: relative;
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid var(--borderColorOnGray);
  }

  td {
    width: auto;
    vertical-align: top;
    border-top: 1px solid var(--borderColorOnGray);
    padding: 12px 0 8px 0;
  }

  td:first-child {
    text-align: left;
    padding-right: 20px;
    display: none;
  }

  :host(.width600) td:first-child {
    display: table-cell;
  }

  td:last-child {
    padding-bottom: 12px;
  }

  d-label {
    margin-bottom: 8px;
  }

  td:first-child d-label {
    padding-top: 4px;
  }

  td:last-child d-label {
    padding-top: 0;
  }

  :host(.width600) td:last-child d-label {
    display: none;
  }

  td > * {
    display: block;
  }

  .editor {
    width: 100%;
  }
`;

export const computerTypeOptions = [
  {
    value: 'Server',
    text: 'Server',
  },
  {
    value: 'Arbeidsstasjon',
    text: 'Arbeidsstasjon/klient',
  },
  {
    value: 'mobile',
    text: 'Bærbar enhet',
  },
];

export const applicationPlatformOptions = [
  {
    value: 'cloudServices',
    text: 'Skytjeneste',
  },
  {
    value: 'remoteServers',
    text: 'Fjernserver',
  },
  {
    value: 'computers',
    text: 'Lokal datamaskin',
  },
];

export const networkTypeOptions = [
  { value: 'CABLE', text: 'Kablet nettverk' },
  { value: 'WIFI', text: 'Trådløst nettverk' },
  { value: 'BOTH', text: 'Kablet og trådløst' },
];

export const internetConnectionTypeOptions = [
  { value: 'NONE', text: 'Ingen internettforbindelse' },
  {
    value: 'NHN',
    text: 'Tilkoblet helsenettet med Norsk helsenett som internettleverandør',
  },
  {
    value: 'NHNotherISP',
    text: 'Tilkoblet helsenettet med selvvalgt internettleverandør',
  },
  { value: 'OTHER', text: 'Tilkoblet Internett utenfor helsenettet' },
];

export const applications: { [keys: string]: InfosecApplicationOption[] } = {
  medicalRecordsSystems: [
    {
      type: 'any',
      value: 'cgm',
      text: 'CGM Journal',
      provider: 'CompuGroup Medical Norway AS',
    },
    {
      type: 'cloud',
      value: 'egHano',
      text: 'EG Hano',
      provider: 'EG Hano AS',
    },
    {
      type: 'any',
      value: 'extensor',
      text: 'Extensor',
      provider: 'Extensor AS',
    },
    {
      type: 'cloud',
      value: 'hoveTotal',
      text: 'Hove Total',
      provider: 'Hove Medical Systems AS',
    },
    {
      type: 'cloud',
      value: 'infodocSky',
      text: 'Infodoc Sky',
      provider: 'Infodoc AS',
    },
    {
      type: 'standalone',
      value: 'infodocPlenario',
      text: 'Infodoc Plenario',
      provider: 'Infodoc AS',
    },
    {
      type: 'standalone',
      value: 'opusDental',
      text: 'Opus Dental',
      provider: 'Opus Systemer AS',
    },
    {
      type: 'cloud',
      value: 'opusCloud',
      text: 'Opus Cloud',
      provider: 'Opus Systemer AS',
    },
    {
      type: 'standalone',
      value: 'orthodontis',
      text: 'Orthodontis',
      provider: 'Orthodontis AS',
    },
    {
      type: 'cloud',
      value: 'patientSky',
      text: 'PatientSky',
      provider: 'PatientSky AS',
    },
    {
      type: 'cloud',
      value: 'physica',
      text: 'Physica',
      provider: 'Aspit AS',
    },
    {
      type: 'cloud',
      value: 'pridokEpj',
      text: 'Pridok EPJ',
      provider: 'Pridok AS',
    },
    {
      type: 'cloud',
      value: 'psykbase',
      text: 'Psykbase',
      provider: 'Aspit AS',
    },
    {
      type: 'standalone',
      value: 'systemX',
      text: 'System X',
      provider: 'Hove Medical Systems AS',
    },
    {
      type: 'cloud',
      value: 'webMed',
      text: 'WebMed',
      provider: 'WebMed EpJ AS',
    },
    {
      type: 'cloud',
      value: 'zilke',
      text: 'Zilke',
      provider: 'Go on Software AS',
    },
  ],
  patientDialogSystems: [
    {
      type: 'cloud',
      value: 'DDFL',
      text: 'Digital Dialog Fastlege (DDFL)',
      provider: 'Norsk helsenett',
    },
    {
      type: 'cloud',
      value: 'helseRespons',
      text: 'HelseRespons',
      provider: 'WTW AS',
    },
    {
      type: 'cloud',
      value: 'patientSky',
      text: 'PatientSky',
      provider: 'PatientSky AS',
    },
  ],
  accountingServices: [
    {
      type: 'cloud',
      value: 'conta',
      text: 'Conta',
      provider: 'Conta AS',
    },
    {
      type: 'cloud',
      value: 'fiken',
      text: 'Fiken',
      provider: 'Fiken AS',
    },
    {
      type: 'cloud',
      value: 'praktikertjenesten',
      text: 'Praktikertjenesten',
      provider: 'Praktikertjenesten AS',
    },
    {
      type: 'cloud',
      value: 'tannlegeregnskap',
      text: 'Tannlegeregnskap',
      provider: 'Tannlegeregnskap AS',
    },
    {
      type: 'cloud',
      value: 'tripletex',
      text: 'Tripletex',
      provider: 'Tripletex AS',
    },
    {
      type: 'cloud',
      value: 'visma',
      text: 'Visma',
      provider: 'Visma AS',
    },
  ],
  managementSystems: [
    {
      type: 'cloud',
      value: 'convene',
      text: 'Convene',
      provider: 'Convene AS',
    },
    {
      type: 'cloud',
      value: 'dropbox',
      text: 'Dropbox',
      provider: 'Dropbox',
    },
    {
      type: 'cloud',
      value: 'googleDocs',
      text: 'Google Docs',
      provider: 'Google',
    },
    {
      type: 'cloud',
      value: 'googleDrive',
      text: 'Google Drive',
      provider: 'Google',
    },
    {
      type: 'cloud',
      value: 'iCloud',
      text: 'iCloud',
      provider: 'Apple',
    },
    {
      type: 'cloud',
      value: 'infoskjermen',
      text: 'Infoskjermen',
      provider: 'Favo AS',
    },
    {
      type: 'cloud',
      value: 'oneDrive',
      text: 'OneDrive',
      provider: 'Microsoft',
    },
    {
      type: 'cloud',
      value: 'simployer',
      text: 'Simployer',
      provider: 'Simployer AS',
    },
    {
      type: 'cloud',
      value: 'sticos',
      text: 'Sticos',
      provider: 'Sticos AS',
    },
    {
      type: 'cloud',
      value: 'tidsbanken',
      text: 'Tidsbanken',
      provider: 'Tidsbanken AS',
    },
    {
      type: 'cloud',
      value: 'trinnvis',
      text: 'TrinnVis',
      provider: 'Trinnvis AS',
    },
    {
      type: 'cloud',
      value: 'tritt',
      text: 'Tritt',
      provider: 'Tritt AS',
    },
  ],
  emailServices: [
    {
      type: 'cloud',
      value: 'Domeneshop',
      text: 'Domeneshop',
      provider: 'Domeneshop AS',
    },
    {
      type: 'cloud',
      value: 'Norsk helsenett',
      text: 'Norsk helsenett',
      provider: 'Norsk helsenett',
    },
    {
      type: 'cloud',
      value: 'One.com',
      text: 'One.com',
      provider: 'One.com',
    },
    {
      type: 'cloud',
      value: 'PRO ISP',
      text: 'PRO ISP',
      provider: 'PRO ISP AS',
    },
    {
      type: 'cloud',
      value: 'SYSE',
      text: 'SYSE',
      provider: 'SYSE AS',
    },
    {
      type: 'cloud',
      value: 'Uniweb',
      text: 'Uniweb',
      provider: 'Uniweb.no AS',
    },
    {
      type: 'cloud',
      value: 'Webhuset',
      text: 'Webhuset',
      provider: 'Webhuset AS',
    },
  ],
  webServices: [
    {
      type: 'cloud',
      value: 'Domeneshop',
      text: 'Domeneshop',
      provider: 'Domeneshop AS',
    },
    {
      type: 'cloud',
      value: 'Norsk helsenett',
      text: 'Norsk helsenett',
      provider: 'Norsk helsenett',
    },
    {
      type: 'cloud',
      value: 'One.com',
      text: 'One.com',
      provider: 'One.com',
    },
    {
      type: 'cloud',
      value: 'PRO ISP',
      text: 'PRO ISP',
      provider: 'PRO ISP AS',
    },
    {
      type: 'cloud',
      value: 'SYSE',
      text: 'SYSE',
      provider: 'SYSE AS',
    },
    {
      type: 'cloud',
      value: 'Uniweb',
      text: 'Uniweb',
      provider: 'Uniweb.no AS',
    },
    {
      type: 'cloud',
      value: 'Webhuset',
      text: 'Webhuset',
      provider: 'Webhuset AS',
    },
  ],
  socialMedia: [
    {
      type: 'cloud',
      value: 'a',
      text: 'Facebook',
      provider: 'Facebook',
    },
    {
      type: 'cloud',
      value: 'b',
      text: 'LinkedIn',
      provider: 'LinkedIn',
    },
    {
      type: 'cloud',
      value: 'c',
      text: 'Twitter',
      provider: 'Twitter',
    },
    {
      type: 'cloud',
      value: 'd',
      text: 'YouTube',
      provider: 'YouTube',
    },
    {
      type: 'cloud',
      value: 'e',
      text: 'Instagram',
      provider: 'Instagram',
    },
    {
      type: 'cloud',
      value: 'f',
      text: 'TikTok',
      provider: 'TikTok',
    },
  ],
};

export const categories: { [keys: string]: InfosecCategory } = {
  medicalRecordsSystems: {
    category: 'medicalRecordsSystems',
    label: 'journalsystem',
    field: '',
    applicationPlaceholder: 'Velg journalsystem',
    applicationAddText: 'Annet journalsystem',
    applicationOptions: applications.medicalRecordsSystems,
  },
  patientDialogSystems: {
    category: 'patientDialogSystems',
    label: 'pasientdialogsystem',
    field: 'infosec_pasientDialogSystem',
    applicationPlaceholder: 'Velg dialogsystem',
    applicationAddText: 'Annet dialogsystem',
    applicationOptions: applications.patientDialogSystems,
  },
  assetData: {
    category: 'assetData',
    label: 'data for medisinsk utstyr',
    field: '',
  },
  managementSystems: {
    category: 'managementSystems',
    label: 'data i nettbaserte tjenester',
    labelSingular: 'data i nettbasert tjeneste',
    field: '',
    applicationPlaceholder: 'Velg tjeneste',
    applicationAddText: 'Annen tjeneste',
    applicationOptions: applications.managementSystems,
  },
  accounting: {
    category: 'accounting',
    label: 'regnskapsdata',
    field: 'infosec_accounting',
    applicationPlaceholder: 'Velg tjeneste',
    applicationAddText: 'Annen tjeneste',
    applicationOptions: applications.accountingServices,
  },
  generalCloudServices: {
    category: 'generalCloudServices',
    label: 'data i skylagringstjenester',
    labelSingular: 'data i skylagringstjeneste',
    field: '',
    applicationPlaceholder: 'Velg skylagringstjeneste',
    applicationAddText: 'Annen skylagringstjeneste',
    applicationOptions: applications.generalCloudServices,
  },
  localDocuments: {
    category: 'localDocuments',
    label: 'lokale digitale dokumenter',
    field: '',
  },
  remoteDocuments: {
    category: 'remoteDocuments',
    label: 'dokumenter på fjernserver',
    field: '',
  },
  paper: {
    category: 'paperDocuments',
    label: 'papirdokumenter',
    field: '',
  },
  emailAccounts: {
    category: 'emailAccounts',
    label: 'upersonlige epostkontoer',
    labelSingular: 'upersonlig epostkonto',
    field: 'infosec_impersonalEmailAccounts',
  },
  emailServices: {
    category: 'emailServices',
    label: 'eposttjenester',
    labelSingular: 'eposttjeneste',
    field: 'infosec_emailServices',
    applicationPlaceholder: 'Velg eposttjeneste',
    applicationAddText: 'Annen eposttjeneste',
    applicationOptions: applications.emailServices,
  },
  webServices: {
    category: 'webServices',
    label: 'webtjenester',
    labelSingular: 'webtjeneste',
    field: '',
    applicationPlaceholder: 'Velg webtjeneste',
    applicationAddText: 'Annen webtjeneste',
    applicationOptions: applications.webServices,
  },
  socialMedia: {
    category: 'socialMedia',
    label: 'sosiale medier',
    labelSingular: 'sosialt medium',
    field: '',
    applicationPlaceholder: 'Velg sosialt medium',
    applicationAddText: 'Annet sosialt medium',
    applicationOptions: applications.socialMedia,
  },
};

export const dataTypes: InfosecDataType[] = [
  {
    type: 'patientData',
    name: 'pasientdata',
    text: `
<p>Registrer alle systemer som lagrer eller behandler pasientdata.</p>
<p><em>Ikke registrer samme system flere ganger, selv om det brukes flere steder. 
Et lokalt journalsystem er f.eks installert på en server, men brukes på flere arbeidsstasjoner.<em></p>
`,
    categories: [
      categories.medicalRecordsSystems,
      categories.patientDialogSystems,
      categories.assetData,
      categories.localDocuments,
      categories.remoteDocuments,
      categories.paper,
    ],
  },
  {
    type: 'managementData',
    name: 'interne virksomhetsdata',
    text: `
<p>Registrer alle systemer som lagrer eller behandler virksomhetsdata.</p>`,
    categories: [
      categories.accounting,
      categories.managementSystems,
      categories.localDocuments,
      categories.remoteDocuments,
      categories.paper,
    ],
  },
  {
    type: 'publicInformationAndCommunication',
    name: 'kommunikasjonsdata',
    text: `
<p>Registrer alle systemer som lagrer eller behandler kommunikasjonsdata.</p>`,
    categories: [categories.emailAccounts, categories.emailServices, categories.webServices, categories.socialMedia],
  },
];

export const backupFrequencyOptions = [
  {
    value: 'never',
    text: 'Aldri',
  },
  {
    value: 'weeklyOrLess',
    text: 'Ukentlig eller sjeldnere',
  },
  {
    value: 'lessThanDaily',
    text: 'Flere ganger i uken',
  },
  {
    value: 'daily',
    text: 'Daglig',
  },
  {
    value: 'moreThanDaily',
    text: 'Flere ganger om dagen',
  },
  {
    value: 'continuously',
    text: 'Kontinuerlig',
  },
];

export const noBackupReasonOptions = [
  {
    value: 'unimportantData',
    text: 'Ikke viktige data',
  },
  {
    value: 'otherStorage',
    text: 'Data lagres andre steder',
  },
];

export const purposeAndLegalBasisDefaults = {
  UNDEFINED: {
    purpose: '',
    legalBasis: '',
  },
  medicalRecordsSystems: {
    purpose:
      'Vi behandler nødvendige og relevante person- og helseopplysninger om pasienter ' +
      'for å gi helsehjelp og føre pasientjournal, oppfylle lovpålagt meldeplikt eller ' +
      'opplysningsplikt, administrere timeavtaler og ta betalt for helsehjelpen. ' +
      'Vi behandler også personopplysninger om pasienter for å ivareta allmenne folkehelsehensyn ' +
      'i forbindelse med utbrudd av smittsom allmennfarlig sykdom, gi smittevernveiledning, ' +
      'og overholde lovpålagt varslings- og meldeplikt.',
    legalBasis:
      'GDPR artikkel 6 nr. 1 c) og artikkel 9 nr. 2 h) for å overholde plikten til å føre ' +
      'pasientjournal, administrere timeavtaler og oppgjør, og oppfylle lovpålagt meldeplikt eller ' +
      'opplysningsplikt, etter helsepersonelloven og pasientjournalloven med forskrifter, ' +
      'og overholde arkivplikt for pasientjournaler etter pasientjournalforskriften. ' +
      'GDPR artikkel 6 nr. 1 c) og e) og artikkel 9 nr. 2 i) for å ivareta allmenne folkehelsehensyn ' +
      'i forbindelse med utbrudd av smittsom sykdom eller allmennfarlig smittsom sykdom etter ' +
      'smittevernloven og MSIS-forskriften.',
  },
  assetData: {
    purpose:
      'Diagnostisere og overvåke sykdom. ' +
      'Behandle helseopplysninger på en måte som gir pasienter og brukere ' +
      'helsehjelp av god kvalitet.  Relevante og nødvendige opplysninger skal være ' +
      'tilgjengelige for helsepersonell på en rask og effektiv måte. ' +
      'Personvern og pasientsikkerhet skal ivaretas.',
    legalBasis:
      'Lov om medisinsk utstyr \nLov om behandling av helseopplysninger ' +
      'ved ytelse av helsehjelp (Pasientjournalloven)',
  },
  patientDialogSystems: {
    purpose:
      'Sikker dialog mellom pasient og helsepersonell. ' +
      'Behandle helseopplysninger på en måte som gir pasienter og brukere ' +
      'helsehjelp av god kvalitet.  Relevante og nødvendige opplysninger skal være ' +
      'tilgjengelige for helsepersonell på en rask og effektiv måte. ' +
      'Personvern og pasientsikkerhet skal ivaretas.',
    legalBasis: '',
  },
  employeeRecords: {
    purpose:
      'Vi behandler personopplysninger om personalet for å gjennomføre arbeidsavtaler, ' +
      'følge opp sykefravær og overholde våre lovpålagte plikter som arbeidsgiver. ' +
      'I forbindelse med koronapandemien behandler vi også personopplysninger for å forebygge og ' +
      'redusere utbrudd av koronaviruset på arbeidsplassen, tilrettelegge for risikogrupper og kreve ' +
      'refusjon fra NAV for koronarelatert sykefravær.',
    legalBasis:
      'GDPR artikkel 6 nr. 1 b) for å overholde arbeidsavtaler med personalet. ' +
      'GDPR artikkel 6 nr. 1 c) for å overholde lovpålagte plikter for arbeidsgivere etter ' +
      'arbeidsmiljøloven, innrapportere lønn og skattetrekksopplysninger til offentlige myndigheter ' +
      'etter a-meldingsloven og skattebetalingsloven, og overholde plikten til å oppbevare ' +
      'lønnsbilag og annen dokumentasjon i regnskapet etter bokføringsloven. GDPR artikkel 9 nr. 2 b) ' +
      '(sensitive personopplysninger) for å overholde plikter etter arbeidsmiljøloven og ' +
      'folketrygdloven med forskrifter.',
  },
  customerRecords: {
    treatments: [],
    purpose: '',
    legalBasis: '',
  },
  OTHER: {
    treatments: [],
    purpose: '',
    legalBasis: '',
  },
};

export const messagingTypes = [
  { value: 'aspitMeldingsmodul', text: 'Aspit meldingsmodul' },
  { value: 'cgmEportal', text: 'CGM ePortal' },
  { value: 'dipsCommunicator', text: 'Dips Communicator' },
  { value: 'infodocESentral', text: 'Infodoc eSentral' },
  { value: 'mediLink', text: 'MediLink' },
  { value: 'primeMonitor', text: 'Prime Monitor' },
  { value: 'vismaSamhandlingLink', text: 'Visma samhandling link' },
  { value: 'OTHER', text: 'Annen, spesifiser...' },
];

export function changeOrAddDataItem(dataItems: InfosecDataItem[], changedItem: InfosecDataItem) {
  const changedDataItems: InfosecDataItem[] = [];
  let newItem = true;
  dataItems.forEach((dataItem) => {
    if (dataItem.uuid === changedItem.uuid) {
      changedDataItems.push(changedItem);
      newItem = false;
    } else {
      changedDataItems.push(dataItem);
    }
  });
  if (newItem) {
    changedDataItems.push(changedItem);
  }
  return changedDataItems.filter((dataItem) => {
    return dataItem.uuid !== '';
  });
}

export function getCategoryName(dataItem) {
  let result = '';
  const dataType = dataTypes.filter((type) => {
    return type.type === dataItem.dataType;
  });
  if (dataType?.length) {
    const category = dataType[0].categories.filter((category) => {
      return category.category === dataItem.category;
    });
    if (category?.length) {
      result = category[0].label;
    }
  }
  return result;
}

export function getDataTypeName(dataItem) {
  return (
    dataTypes.filter((type) => {
      return type.type === dataItem.dataType;
    })[0]?.name || 'Annet'
  );
}

export function sortSelectedCategories(list: InfosecDataItem[]): InfosecDataItem[] {
  let dataItems: InfosecDataItem[] = [];
  dataTypes.forEach((dataType) => {
    dataType.categories.forEach((category) => {
      dataItems = dataItems.concat(
        list.filter((item) => {
          return dataType.type === item.dataType && category.category === item.category;
        }),
      );
    });
  });
  return dataItems;
}

export function getComputerName(computer) {
  let text = computer.name;
  if (computer.name === '' && computer.computerType && computer.computerType !== '') {
    const computerTypes = computerTypeOptions.filter((item) => {
      return item.value === computer.computerType;
    });
    if (computerTypes.length) {
      text = computerTypes[0].text;
    }
  }
  return text;
}

export function dataItemLabels(
  dataItem: InfosecDataItem,
  computers: InfosecStorageUnit[],
  cloudServices: InfosecStorageUnit[],
  remoteServers: InfosecStorageUnit[],
  partners: SelectDropdownOption[],
  assets: InfosecAsset[],
): { primary: string; secondary: string; contextual: string } {
  // item name
  let name = '';
  if (dataItem.name) {
    name = dataItem.name;
  }
  // application name
  let applicationName = '';
  if (dataItem.application && applications[dataItem.category]) {
    const defaultApplications = applications[dataItem.category].filter((item) => {
      return item.value === dataItem.application;
    });
    if (defaultApplications.length) {
      applicationName = defaultApplications[0].text;
    } else if (dataItem.application.includes('CUSTOM-')) {
      applicationName = dataItem.application.replace('CUSTOM-', '');
    } else {
      applicationName = dataItem.application;
    }
  }
  // partner name
  let partnerName = '';
  let partnerUuid = '';
  if (dataItem.dataProcessor) {
    partnerUuid = dataItem.dataProcessor;
  }
  if (dataItem.storageUnit) {
    const storageUnits: InfosecStorageUnit[] = computers.concat(cloudServices, remoteServers).filter((unit) => {
      return unit.uuid === dataItem.storageUnit;
    });
    if (storageUnits.length) {
      partnerUuid = storageUnits[0].partnerUuid;
    }
  }
  if (partnerUuid) {
    const p = partners.filter((partner) => {
      return partner.value === partnerUuid;
    });
    if (p.length) {
      partnerName = p[0].text;
    }
  }
  // computer name
  let computerName = '';
  if (dataItem.storageUnitType === 'computers') {
    const c = computers.filter((item) => {
      return item.uuid === dataItem.storageUnit;
    });
    if (c.length) {
      computerName = getComputerName(c[0]);
    }
  }
  // asset names
  let assetNames = '';
  if (dataItem.category === 'assetData') {
    const a = assets.filter((item) => {
      return dataItem.assets.includes(item.uuid);
    });
    if (a.length) {
      sortedByNumberAndName(a).forEach((item) => {
        assetNames += item.name + ' ' + item.number + ', ';
      });
      assetNames = assetNames.slice(0, -2);
    }
  }
  // location
  let locationName = '';
  if (dataItem.category === 'paperDocuments') {
    locationName = dataItem.storageLocation;
  }
  // type
  let type = '';
  if (dataItem.dataType === 'patientData') {
    type = 'pasientdata';
    if (dataItem.category === 'medicalRecordsSystems') {
      type = 'pasientjournal';
    }
    if (dataItem.category === 'patientDialogSystems') {
      type = 'pasientdialogsystem';
    }
    if (dataItem.category === 'assetData') {
      type = 'utstyrsdata';
    }
  }
  if (dataItem.dataType === 'managementData') {
    type = 'virksomhetsdata';
    if (dataItem.category === 'accounting') {
      type = 'regnskapsdata';
    }
  }
  if (dataItem.category === 'emailAccounts') {
    type = 'upersonlig epostkonto';
  }
  if (dataItem.category === 'emailServices') {
    type = 'eposttjeneste';
  }
  if (dataItem.category === 'webServices') {
    type = 'webtjeneste';
  }
  if (dataItem.category === 'socialMedia') {
    type = 'sosialt medium';
  }
  // medium
  let medium = '';
  let before = false;
  if (dataItem.storageUnitType === 'computers') {
    medium = 'lokale ';
    before = true;
    if (dataItem.category === 'medicalRecordsSystems') {
      medium = 'lokal ';
    }
  }
  if (dataItem.storageUnitType === 'cloudServices') {
    medium = ' i skytjeneste';
  }
  if (dataItem.storageUnitType === 'remoteServers') {
    medium = ' på fjernserver';
  }
  if (dataItem.category === 'paperDocuments') {
    medium = ' på papir';
  }
  if (
    dataItem.category === 'patientDialogSystems' ||
    dataItem.category === 'emailAccounts' ||
    dataItem.category === 'emailServices' ||
    dataItem.category === 'webServices' ||
    dataItem.category === 'socialMedia'
  ) {
    medium = '';
  }
  let mediumAddition = type + medium;
  if (before) {
    mediumAddition = medium + type;
  }
  let primary = '';
  if (name) {
    primary = name;
  } else if (applicationName) {
    primary = applicationName;
  } else if (partnerName) {
    primary = partnerName;
  } else if (computerName) {
    primary = computerName;
  } else if (assetNames) {
    primary = assetNames;
  } else if (locationName) {
    primary = locationName;
  }
  if (!primary) {
    primary = dataItem.uuid;
  }
  return {
    primary: primary,
    secondary: mediumAddition,
    contextual: type,
  };
}
