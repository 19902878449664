import { css, html } from 'lit';
import '../../library/elements/d-section.js';
import '../../library/elements/d-wrap.js';
import '../../library/fields/d-view-info.js';
import { customElement, property } from 'lit/decorators.js';
import { BaseDialog, DialogCancelResult } from 'src/library/components/BaseDialog.js';
import { EmployeeViewModelStaffGroupEnum } from 'src/store/api';

export interface EmployeeWithStaffGroup {
  uuid: string;
  name: string;
  staffGroup: EmployeeViewModelStaffGroupEnum;
  treatmentProvider?: boolean;
  treatmentHelper?: boolean;
}

export interface StaffingGroupsInput {
  employees: EmployeeWithStaffGroup[];
}

export type StaffingGroupsResult =
  | DialogCancelResult
  | {
      action: 'done';
      changedEmployees: { uuid: string; staffGroup: EmployeeViewModelStaffGroupEnum }[];
    };

/**
 *
 * USAGE:
 *    d-staffing-calendar-table
 *    d-staffing-calendar
 *
 */

@customElement('staffing-groups-dialog')
export class StaffingGroupsDialog extends BaseDialog<StaffingGroupsInput, StaffingGroupsResult> {
  static readonly styles = [
    ...BaseDialog.styles,
    css`
      .option {
        display: inline-block;
        width: 29px;
        height: 29px;
        box-sizing: border-box;
        cursor: pointer;
        margin-right: 10px;
        background-color: hsla(196, 83%, 49%, 1);
      }

      .option:nth-child(2) {
        background-color: hsla(196, 83%, 49%, 0.6);
      }

      .option:nth-child(3) {
        background-color: hsla(196, 83%, 49%, 0.2);
      }

      .option:nth-child(1).group-1,
      .option:nth-child(2).group-2,
      .option:nth-child(3).group-3 {
        border: 2px solid hsla(0, 0%, 0%, 1);
      }

      .option:hover {
        border: 2px solid hsla(0, 0%, 0%, 1);
      }
    `,
  ];
  @property({ type: Array })
  employees: EmployeeWithStaffGroup[] = [];

  headerActions = [
    { name: 'Avbryt', action: 'cancel' },
    { name: 'Ferdig', action: 'save' },
  ];
  width = 500;
  title = 'Grupper';
  private info =
    '<p> For å gi bedre oversikt over bemanningen kan personalet sorteres i grupper, f.eks etter hvem ' +
    'som er pasientbehandlere og hvem som er hjelpepersonell. Grafen nederst i kalenderen viser hvor mange i hver ' +
    'gruppe som er til stede hver dag. Du kan redigere gruppefordelingen ved å trykke på gruppefargen for hver ' +
    'person nedenfor.</p>';
  private originalEmployees: EmployeeWithStaffGroup[] = [];

  _optionClasses(group: EmployeeViewModelStaffGroupEnum) {
    return 'option group-' + this.groupToNumber(group);
  }

  groupToNumber(g: EmployeeViewModelStaffGroupEnum): number {
    switch (g) {
      case 'G1':
        return 1;
      case 'G2':
        return 2;
      case 'G3':
        return 3;
    }
  }

  _setStaffGroup(uuid: string, group: EmployeeViewModelStaffGroupEnum) {
    this.employees = this.employees.map((e) => {
      return {
        ...e,
        staffGroup: e.uuid === uuid ? group : e.staffGroup,
      };
    });
  }

  renderBody() {
    return html`
      <d-section>
        <d-view-info .content=${this.info}> </d-view-info>
      </d-section>
      ${this.employees.map(
        (employee) => html`
          <d-section>
            <d-wrap center>
              <div
                class="${this._optionClasses(employee.staffGroup)}"
                @click=${() => this._setStaffGroup(employee.uuid, EmployeeViewModelStaffGroupEnum.G1)}
              ></div>
              <div
                class="${this._optionClasses(employee.staffGroup)}"
                @click=${() => this._setStaffGroup(employee.uuid, EmployeeViewModelStaffGroupEnum.G2)}
              ></div>
              <div
                class="${this._optionClasses(employee.staffGroup)}"
                @click=${() => this._setStaffGroup(employee.uuid, EmployeeViewModelStaffGroupEnum.G3)}
              ></div>
              <div>${employee.name}</div>
            </d-wrap>
          </d-section>
        `,
      )}
    `;
  }

  protected fetchResult(detail: string | undefined): StaffingGroupsResult {
    if (detail === 'save') {
      return {
        action: 'done',
        changedEmployees: this.employees
          .filter((e) => {
            const n = this.originalEmployees.find((x) => x.uuid === e.uuid);
            if (n === undefined) {
              throw new Error('Illegal state (E531), original employee not found for ' + e.uuid);
            }
            return n.staffGroup !== e.staffGroup;
          })
          .map((e) => {
            return { uuid: e.uuid, staffGroup: e.staffGroup };
          }),
      };
    }

    return {
      action: 'cancel',
    };
  }

  protected initializeDialog(input: StaffingGroupsInput) {
    this.originalEmployees = input.employees;
    this.employees = input.employees;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'staffing-groups-dialog': StaffingGroupsDialog;
  }
}
