/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ClearDraftCommand
 */
export interface ClearDraftCommand {
  /**
   *
   * @type {string}
   * @memberof ClearDraftCommand
   */
  entityType: string;
  /**
   * Normally an uuid, but for events can include date suffix
   * @type {string}
   * @memberof ClearDraftCommand
   */
  entityUuid: string;
}

/**
 * Check if a given object implements the ClearDraftCommand interface.
 */
export function instanceOfClearDraftCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'entityType' in value;
  isInstance = isInstance && 'entityUuid' in value;

  return isInstance;
}

export function ClearDraftCommandFromJSON(json: any): ClearDraftCommand {
  return ClearDraftCommandFromJSONTyped(json, false);
}

export function ClearDraftCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClearDraftCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entityType: json['entityType'],
    entityUuid: json['entityUuid'],
  };
}

export function ClearDraftCommandToJSON(value?: ClearDraftCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entityType: value.entityType,
    entityUuid: value.entityUuid,
  };
}
