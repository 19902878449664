import { css, html, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/elements/d-label.js';
import * as dabihStore from 'src/store';
import type { SelectDropdownOption } from '../../library/editors/elements/d-select-dropdown.js';

/**
 *
 */
@customElement('d-edit-single-related-item')
export class DEditSingleRelatedItem extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    d-label {
      padding-bottom: 6px;
    }

    .relations-editor form {
      display: flex;
    }

    .relations-editor form * {
      margin: 0;
    }

    .relations-editor .items .unrelate {
      flex: none;
      width: 12px;
      height: 100%;
      background: transparent url(/images/unrelate.svg) 100% 8px no-repeat;
      text-indent: -1000px;
      overflow: hidden;
      cursor: pointer;
    }

    .relations-editor input[type='text'] {
      flex: 1;
      height: calc(var(--inputElementHeight) + 2px);
      box-sizing: border-box;
      border: 1px solid var(--borderColor);
      border-top-left-radius: var(--inputElementBorderRadius);
      border-bottom-left-radius: var(--inputElementBorderRadius);
      padding: 4px 8px 5px 9px;
      font-family: var(--mainSerif), serif;
      font-size: 15px;
      outline: none;
    }

    .relations-editor div#cancel-new {
      flex: none;
      border-top: 1px solid hsl(0, 0%, 88%);
      border-bottom: 1px solid hsl(0, 0%, 88%);
      padding: 8px 8px 5px 10px;
      color: hsl(0, 0%, 50%);
      background-color: white;
      margin-left: -1px;
      font-family: var(--small) sans-serif;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 11px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .relations-editor div#cancel-new:hover {
      color: black;
    }

    .relations-editor input[type='submit'] {
      flex: none;
      width: auto;
      border: 1px solid var(--themeColorDarkerOne);
      border-left: none;
      border-top-right-radius: var(--inputElementBorderRadius);
      border-bottom-right-radius: var(--inputElementBorderRadius);
      background-color: var(--themeColor);
      padding: 5px 8px 6px 8px;
      color: white;
      font-family: var(--small) sans-serif;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 11px;
      text-transform: uppercase;
      box-shadow: none;
      cursor: pointer;
    }

    .relations-editor input[type='submit']:hover {
      background-color: var(--themeColorDarkerOne);
    }

    .relations-editor input[type='submit']:disabled,
    .relations-editor input[type='submit']:disabled:hover {
      cursor: default;
      background-color: var(--themeColor);
      opacity: 0.5;
    }
  `;
  @property({ type: String })
  field = '';
  @property({ type: String })
  placeholder = '';
  @property({ type: Array })
  items: SelectDropdownOption[] = [];
  @property({ type: String })
  relatedItem = '';
  @property({ type: String })
  newItem = '';
  @property({ type: Boolean })
  isNew = false;
  @query('.new-input')
  input!: HTMLInputElement;

  private get newIsEmpty() {
    return this.newItem === undefined || this.newItem == null || this.newItem.length <= 0;
  }

  async onValueChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    if (e.detail.value === 'NEW') {
      this.isNew = true;
      this.newItem = '';
      await this.updateComplete;
      this.input.focus();
    } else {
      this.relatedItem = e.detail.value;
      this.dispatchEvent(
        new CustomEvent<{ relatedItem: string }>('related-item-changed', {
          bubbles: true,
          composed: true,
          detail: {
            relatedItem: this.relatedItem,
          },
        }),
      );
    }
  }

  _cancelNew(event) {
    event.preventDefault();
    this.isNew = false;
    this.newItem = '';
  }

  async _saveNew(event) {
    const uuid = dabihStore.createUuid();
    event.preventDefault();
    this.items = [
      ...this.items,
      {
        value: uuid,
        text: this.newItem,
      },
    ];
    await this.updateComplete;
    this.relatedItem = uuid;
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; name: string }>('new-item', {
        bubbles: true,
        composed: true,
        detail: {
          uuid: uuid,
          name: this.newItem,
        },
      }),
    );
    this.isNew = false;
    this.newItem = '';
    this.dispatchEvent(
      new CustomEvent<{ relatedItem: string }>('related-item-changed', {
        bubbles: true,
        composed: true,
        detail: {
          relatedItem: this.relatedItem,
        },
      }),
    );
  }

  onInput(event: InputEvent) {
    this.newItem = (event.target as HTMLInputElement).value;
  }

  render() {
    console.log(this.items);
    console.log(this.relatedItem);
    return html`
      <div class="relations-editor">
        <d-label .field=${this.field}></d-label>
        ${this.isNew
          ? html`<form @submit=${(e) => this._saveNew(e)}>
              <input class="new-input" placeholder="Navn" type="text" value="${this.newItem}" @input=${this.onInput} />
              <div id="cancel-new" @click=${(e) => this._cancelNew(e)}>Avbryt</div>
              <input ?disabled=${this.newIsEmpty} type="submit" value="OK" />
            </form>`
          : html`<d-select-dropdown
              theme-page
              deselectable
              .options=${[...this.items, { value: 'NEW', text: 'Opprett ny' }]}
              .placeholder=${this.placeholder}
              .value=${this.relatedItem}
              @value-changed=${this.onValueChanged}
            >
            </d-select-dropdown>`}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-single-related-item': DEditSingleRelatedItem;
  }
}
