import '../elements/d-tooltip.js';
import { LabeledElement } from '../abstracts/labeled-element.js';
import { customElement, property } from 'lit/decorators.js';
import { html } from 'lit-html';
import { css } from 'lit';

/**
 *
 * STATUS OK
 */
@customElement('d-bullets')
export class DBullets extends LabeledElement {
  static readonly styles = [...LabeledElement.styles, css``];

  @property({ type: Array })
  value: string[] = [];

  renderContent() {
    return html`${this.value.map((e) => html`<d-label classes="bullet" .label=${e}></d-label>`)} `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-bullets': DBullets;
  }
}
