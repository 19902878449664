import { css, html, LitElement } from 'lit';
import './d-prepare-step-0.js';
import './d-prepare-step-1.js';
import './d-prepare-cancel.js';
import './d-prepare-finish.js';
import '../../library/components/d-activity-codes.js';
import { activityCodesAvailable, activityCodesCommon } from 'src/store';
import { customElement, property } from 'lit/decorators.js';
import type { AccountStateEmployeeViewModel, AccountStateViewModel, OrganizationReference } from 'src/store/api';
import { choose } from 'lit/directives/choose.js';
import { produce } from 'immer';
import type { PrepareEmployeeRowItem } from 'src/outskirts/account/d-prepare-employee-row.js';

/**
 *
 */
@customElement('d-prepare')
export class DPrepare extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      background: var(--themeColor);
      min-height: 100vh;
    }
  `;

  @property({ type: Object })
  user!: { username: string };

  @property({ type: String })
  page = 'step0';
  @property({ type: String })
  lastPage = 'step0';
  @property({ type: Boolean })
  singleUser = false;
  @property({ type: Boolean })
  accountIsReady = false;

  @property({ type: Object })
  userOrganization!: OrganizationReference;

  get state(): AccountStateViewModel {
    const s = this.userOrganization.state;
    if (s === undefined) {
      throw new Error('Illegal state (E401), pending organization is missing state');
    }
    return s;
  }

  _stringIsEmpty(value) {
    return value ? value.trim().length === 0 : true;
  }

  render() {
    return html`
      <div class="outskirts">
        ${choose(
          this.page,
          [
            [
              'step0',
              () =>
                html`<d-prepare-step-0
                  .activityCodes=${this.state.activityCodes ?? []}
                  .employees=${this.state.employees.map(
                    (e): PrepareEmployeeRowItem => ({
                      uuid: e.uuid,
                      firstName: e.firstName ?? '',
                      lastName: e.lastName ?? '',
                      associationType: e.associationType ?? '',
                      profession: e.profession ?? '',
                      email: e.email ?? '',
                      accessLevel: e.accessLevel ?? 'NONE',
                    }),
                  )}
                  id="step0"
                  .sector=${this.userOrganization.sector}
                  .singleUser=${this.singleUser}
                  .ownerEmail=${this.user.username}
                  .codes=${activityCodesAvailable()}
                  .commonActivityCodes=${activityCodesCommon()}
                  @activity-codes-changed=${this.onActivityCodesChanged}
                  @single-user-changed=${this.onSingleUserChanged}
                  @employees-changed=${this.onEmployeesChanged}
                  @go-cancel=${this.onGoCancel}
                  @go-finish=${this.onGoFinish}
                  @next-step=${this.onNextStep}
                ></d-prepare-step-0>`,
            ],
            [
              'step1',
              () =>
                html`<d-prepare-step-1
                  id="step1"
                  .singleUser=${this.singleUser}
                  .state=${this.state}
                  @state-changed=${this.onStateChanged}
                  @next-step=${this.onNextStep}
                  @go-finish=${this.onGoFinish}
                >
                </d-prepare-step-1>`,
            ],
            [
              'finish',
              () =>
                html`<d-prepare-finish
                  id="finish"
                  .singleUser=${this.singleUser}
                  @prepare-done=${() => this.onPrepareDone()}
                  .state=${this.state}
                  .accountIsReady=${this.accountIsReady}
                >
                </d-prepare-finish>`,
            ],
            [
              'cancel',
              () =>
                html`<d-prepare-cancel
                  id="cancel"
                  .back=${this.lastPage}
                  .ownerEmail=${this.user.username}
                  @next-step=${this.onNextStep}
                >
                </d-prepare-cancel>`,
            ],
          ],
          () => html`<h1>Error ${this.page}</h1>`,
        )}
      </div>
    `;
  }

  private isEmployeeEmpty(item: AccountStateEmployeeViewModel): boolean {
    return this._stringIsEmpty(item.firstName) && this._stringIsEmpty(item.lastName);
  }

  private async onActivityCodesChanged(e: CustomEvent<{ codes: string[] }>) {
    e.stopPropagation();
    this.userOrganization = produce(this.userOrganization, (draft) => {
      if (draft.state === undefined) {
        throw new Error('Illegal state (E402), pending organization is missing state');
      }
      draft.state.activityCodes = e.detail.codes;
    });
    await this.fireSaveState(false);
  }

  private async onSingleUserChanged(e: CustomEvent<{ checked: boolean }>) {
    e.stopPropagation();
    this.userOrganization = produce(this.userOrganization, (draft) => {
      if (draft.state === undefined) {
        throw new Error('Illegal state (E404), pending organization is missing state');
      }
      draft.state.singleUser = e.detail.checked;
    });
    await this.fireSaveState(false);
  }
  private async onEmployeesChanged(e: CustomEvent<{ employees: PrepareEmployeeRowItem[] }>) {
    e.stopPropagation();
    this.userOrganization = produce(this.userOrganization, (draft) => {
      if (draft.state === undefined) {
        throw new Error('Illegal state (E403), pending organization is missing state');
      }
      draft.state.employees = e.detail.employees;
    });
    await this.fireSaveState(false);
  }

  private async onStateChanged(e: CustomEvent<AccountStateViewModel>) {
    e.stopPropagation();
    this.userOrganization = produce(this.userOrganization, (draft) => {
      if (draft.state === undefined) {
        throw new Error('Illegal state (E403), pending organization is missing state');
      }
      draft.state = e.detail;
    });
    await this.fireSaveState(false);
  }

  private onPrepareDone() {
    window.location.href = '/account/' + this.userOrganization.id + '/695944';
  }

  private onGoCancel() {
    this.lastPage = this.page;
    this.page = 'cancel';
  }

  private async onGoFinish() {
    this.userOrganization = produce(this.userOrganization, (draft) => {
      if (draft.state === undefined) {
        throw new Error('Illegal state (E405), pending organization is missing state');
      }
      draft.state.pending = false;
    });
    this.lastPage = this.page;
    this.page = 'finish';
    const handler = () => {
      console.log('Account prepared');
      this.accountIsReady = true;
    };
    await this.fireSaveState(true, handler);
    console.log('Waiting for account preparation on server');
  }
  private async onNextStep(e: CustomEvent<{ current: string; next: string }>) {
    await this.fireSaveState(true);
    this.page = e.detail.next;
  }

  private async fireSaveState(saveToServer: boolean, afterSave: (() => void) | undefined = undefined) {
    if (this.userOrganization.id !== undefined && this.userOrganization.state !== undefined) {
      if (saveToServer) {
        this.cleanState();
      }
      this.dispatchEvent(
        new CustomEvent<{
          organizationId: string;
          state: AccountStateViewModel;
          saveToServer: boolean;
          afterSave: (() => void) | undefined;
        }>('save-prepare-state', {
          bubbles: true,
          composed: true,
          detail: {
            organizationId: '' + this.userOrganization.id,
            state: this.state,
            saveToServer,
            afterSave,
          },
        }),
      );
    }
  }

  private cleanState() {
    this.userOrganization = produce(this.userOrganization, (draft) => {
      const state1 = draft.state;
      if (state1) {
        state1.employees = state1.employees.filter((e) => !this.isEmployeeEmpty(e));
        const ownerEmployeeUuid = state1.employees[0].uuid;
        const employeeUuids = state1.employees.map((e) => e.uuid);
        Object.keys(state1)
          .filter((k) => k.endsWith('Function'))
          .filter((k) => !employeeUuids.includes(state1[k]))
          .forEach((k) => {
            state1[k] = ownerEmployeeUuid;
          });
      }
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-prepare': DPrepare;
  }
}
