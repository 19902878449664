import { css, html, LitElement, nothing, TemplateResult } from 'lit';
import { property } from 'lit/decorators.js';
import '../../library/fields/d-expansion.js';
import '../../library/elements/d-action.js';

/**
 * Common functionality and presentation for report sections
 *
 * USAGE:
 *
 *
 */
export class ReportSection extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
        overflow: hidden;
      }

      #header {
        cursor: pointer;
        transition: all 0.5s;
      }

      :host([opened]) #header {
        cursor: default;
        margin-bottom: 12px;
        transition: all 0.5s;
      }

      #header h2 {
        display: inline-block;
        font-size: 15px;
        font-weight: 500;
        color: var(--themeColor);
        transition:
          font-size 0.5s linear,
          font-weight 0.5s linear;
        margin: 0;
      }

      body:not(.touch) #header:hover h2,
      #header:active h2 {
        color: hsla(0, 0%, 0%, 0.8);
        transition:
          font-size 0.5s linear,
          font-weight 0.5s linear;
      }

      :host([opened]) #header h2 {
        font-size: 18px;
        font-weight: bold;
        color: hsla(0, 0%, 0%, 0.8);
        transition:
          font-size 0.5s linear,
          font-weight 0.5s linear;
      }
    `,
  ];

  private readonly label: string;

  constructor(label: string) {
    super();
    this.label = label;
  }

  @property({ type: Boolean, reflect: true })
  opened = false;

  private onOpen() {
    this.opened = true;
  }
  private onClose() {
    this.opened = false;
  }

  render(): TemplateResult<1> {
    return html`
      <div id="header">
        <h2 @click=${this.onOpen}>${this.label}</h2>
        ${this.opened ? html`<d-action @click=${this.onClose}>Lukk</d-action>` : nothing}
      </div>
      <d-expansion ?opened=${this.opened}> ${this.renderContent()} </d-expansion>
    `;
  }

  protected renderContent(): TemplateResult<1> {
    return html``;
  }
}
