import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import 'src/utilities/display-alert';
import '../../content/d-entity-header.js';
import '../../library/components/d-help-section.js';
import '../../library/elements/d-action.js';
import { PageViewModel } from 'src/store/api';
import type { AbstractPageView } from 'src/pages/abstract-page-view';
import type { ListSectionItemInput } from 'src/library/lists/utilities';
import { ActivityCode } from 'src/pages/organization-page/organization-page-view-model';

export interface PartnersPageViewModel extends AbstractPageView {
  type: 'partners-page';
  icon: 'partners';
  availableActivityCodes: ActivityCode[];
  partnersAndContacts: ListSectionItemInput[];
  pdfHref: string;
}

/**
 *
 */
@customElement('d-partners-page-content')
export class DPartnersPageContent extends PageContent<PartnersPageViewModel> {
  @property({ type: Array })
  pages: PageViewModel[] = [];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: Object })
  token = {};
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: String })
  key = '';
  @property({ type: Number })
  organizationId = 0;

  onShareContent() {
    this.dispatchEvent(new CustomEvent('open-send-message', { bubbles: true, composed: true, detail: {} }));
  }

  renderContent() {
    return html`
      <d-list-section
        no-header
        field="organization_partners"
        write-access="${this.writeAccess}"
        theme-page
        .items=${this.pageView.partnersAndContacts}
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-partners-page-content': DPartnersPageContent;
  }
}
