import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import type { ActionInput } from 'src/library/elements/d-action.js';

/**
 * Base class for lists of events.
 * Contains common functionality for
 * d-calendar-list-section
 * d-todo-list-section
 * d-list-section-task-events
 *
 *
 */

export interface EventItem {
  eventCurrentUser?: boolean;
  rightLabel?: string;
}

export class EventList<T extends EventItem> extends LitElement {
  @property({ type: Boolean })
  multipleEmployees = true;
  @property({ type: Boolean })
  showAll = false;
  @property({ type: Boolean })
  showNotes = false;
  @property({ type: Number })
  contentStickyTop = 0;
  _eventsWithFilterProperties(items: T[]): (T & { hidden: boolean; rightLabel: string })[] {
    return items.map((item) => {
      const hidden = !item.eventCurrentUser && !this.showAll;
      let rightLabel = '';
      if (this.multipleEmployees && item.rightLabel) {
        rightLabel = item.rightLabel;
      }
      return {
        ...item,
        hidden: hidden,
        rightLabel: rightLabel,
      };
    });
  }

  _hasNotes(items) {
    let result = false;
    items.forEach((item) => {
      if (item.notes && item.notes !== '') {
        result = true;
      }
    });
    return result;
  }

  _actions(hasNotes) {
    const actions: ActionInput[] = [];
    if (hasNotes) {
      let toggleShowNotesLabel = 'Vis notater';
      if (this.showNotes) {
        toggleShowNotesLabel = 'Skjul notater';
      }
      actions.push({
        name: toggleShowNotesLabel,
        action: 'toggleShowNotes',
        slot: 'top-right',
      });
    }
    if (this.multipleEmployees) {
      let toggleshowAllLabel = 'Vis alles';
      if (this.showAll) {
        toggleshowAllLabel = 'Vis bare mine';
      }
      actions.push({
        name: toggleshowAllLabel,
        action: 'toggleShowAll',
        slot: 'top-right',
      });
    }
    return actions;
  }

  _fireAction(action) {
    if (action === 'toggleShowNotes') {
      this.showNotes = !this.showNotes;
    }
    if (action === 'toggleShowAll') {
      this.showAll = !this.showAll;
    }
  }
}
