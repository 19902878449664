import type { State } from 'src/store/types';
import {
  BASE_PATH,
  currentPageId,
  employeesNotDeleted,
  getOrganizationId,
  getPartnersAndContacts,
  key,
  partnersNotDeleted,
} from 'src/store';
import { sortBy } from 'lodash';
import { PeoplePageViewModel } from 'src/pages/people-page/d-people-page-content';
import { entityNameForLists } from 'src/utilities/text';
import { getUsersForAccess } from 'src/models/application-view-model';
import { AbstractPageView } from 'src/pages/abstract-page-view';

export function peoplePageView(hrefPrefix: string, viewModel: AbstractPageView, state: State): PeoplePageViewModel {
  const o = state.organization;

  if (o === undefined) {
    throw new Error('Illegal state (E151), organization not found');
  }

  const employees = employeesNotDeleted(state).map((e) => {
    return {
      label: entityNameForLists(e.name, e.draftName, e.isConfirmedEntity),
      accessible: true,
      href: '/account/' + getOrganizationId(state) + '/' + currentPageId(state) + '/employees/' + e.uuid,
    };
  });

  const availablePartners = partnersNotDeleted(state)
    .filter((p) => {
      return p.templateId === null || !p.contentLastModifiedWasCreate;
    })
    .map((p) => {
      return { value: p.uuid, text: p.name };
    });

  return {
    ...viewModel,
    type: 'people-page',
    icon: 'employees',
    href: hrefPrefix,
    pdfHref: BASE_PATH + '/organizations/' + o.organizationId + '/overview.pdf?key=' + key(state),
    employees: sortBy(employees, ['label']),
    partnersAndContacts: getPartnersAndContacts(state),
    partners: sortBy(availablePartners, ['text']),
    userEmails: getUsersForAccess(state)
      .map((e) => e.email ?? '')
      .filter((x) => x !== ''),
  };
}
