import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import 'src/utilities/display-alert';
import '../../content/d-entity-header.js';
import '../../library/components/d-help-section.js';
import '../../library/elements/d-action.js';
import { PageViewModel } from 'src/store/api';
import type { AbstractPageView } from 'src/pages/abstract-page-view';
import type { ListSectionItemInput } from 'src/library/lists/utilities';
import { ActionInput } from 'src/library/elements/d-action';
import {
  DNewContactAndPartnerDialog,
  NewContactAndPartnerDialogResult,
} from 'src/layout/parts/d-new-contact-and-partner-dialog';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { CreateEntityInput } from 'src/layout/parts/d-new-document';
import { uuid } from 'src/utilities/text';

export interface PeoplePageViewModel extends AbstractPageView {
  type: 'people-page';
  icon: 'employees';
  employees: ListSectionItemInput[];
  partnersAndContacts: ListSectionItemInput[];
  userEmails: string[];
  partners: SelectDropdownOption[];
  pdfHref: string;
}

/**
 *
 */
@customElement('d-people-page-content')
export class DPeoplePageContent extends PageContent<PeoplePageViewModel> {
  static readonly styles = [
    ...super.styles,
    css`
      d-list-section {
        margin-bottom: 6px;
      }
    `,
  ];

  @property({ type: Array })
  pages: PageViewModel[] = [];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: Object })
  token = {};
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: String })
  key = '';

  private get employeeActions(): ActionInput[] {
    if (this.writeAccess) {
      return [
        {
          name: 'Legg til',
          action: 'create-employee',
          slot: 'top-right',
        },
      ];
    }
    return [];
  }

  private get partnerAndContactActions(): ActionInput[] {
    if (this.writeAccess) {
      return [
        {
          name: 'Legg til samarbeidspartner',
          action: 'create-partner',
        },
        {
          name: 'Legg til kontaktperson',
          action: 'create-contact',
        },
      ];
    }
    return [];
  }

  onShareContent() {
    this.dispatchEvent(new CustomEvent('open-send-message', { bubbles: true, composed: true, detail: {} }));
  }

  renderContent() {
    return html`
      <d-list-section
        theme-page
        .addItemText=${'Legg til person'}
        .items=${this.pageView.employees}
        icon="employees"
        label="Personale"
        .contentStickyTop=${this.contentStickyTop}
        .actions=${this.employeeActions}
        @action=${this.onAction}
      >
      </d-list-section>
      <d-list-section
        theme-page
        .items=${this.pageView.partnersAndContacts}
        icon="employees"
        label="Samarbeidspartnere"
        sublabel="og kontaktpersoner"
        .contentStickyTop=${this.contentStickyTop}
        .topRightMoreActions=${this.partnerAndContactActions}
        .topRightMoreActionsPrompt=${'Legg til'}
        @action=${this.onAction}
      >
      </d-list-section>
    `;
  }

  private async onAction(e) {
    e.stopPropagation();
    console.log('onAction', e.detail);
    if (e.detail === 'create-contact') {
      const result: NewContactAndPartnerDialogResult = await DNewContactAndPartnerDialog.open({
        userEmails: this.pageView.userEmails,
        partners: this.pageView.partners,
      });
      return result.action;
    }
    if (e.detail === 'create-employee') {
      const id = uuid();
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: {
            entityType: 'employees',
            entityUuid: id,
            targetUrl: this.pageView.href + 'employees/' + id + '?edit',
          },
        }),
      );
    }
    if (e.detail === 'create-partner') {
      console.log('onAction', 'PARTNER');
      const id = uuid();
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: {
            entityType: 'partners',
            entityUuid: id,
            pageId: 357, // TODO: skal ikke trenge noen sideId
            targetUrl: this.pageView.href + 'partners/' + id + '?edit',
          },
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-people-page-content': DPeoplePageContent;
  }
}
