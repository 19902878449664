import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../library/editors/elements/d-edit-text.js';
import '../../../../library/editors/components/d-select-dropdown-or-add.js';
import '../../../../library/editors/elements/d-add-remove-buttons.js';
import { NewAssetDialog, NewAssetResult } from './new-asset-dialog.js';
import { NewComputerDialog, NewComputerResult } from './new-computer-dialog.js';
import type {
  InfosecDataItem,
  InfosecStorageUnit,
  InfosecAsset,
  InfosecDataType,
  InfosecCategory,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { uuid } from 'src/utilities/text.js';
import { getComputerName } from 'src/pages/computers-page/infosec-procedure/defaults.js';

import _ from 'lodash';

export interface DataItemAssetChange {
  uuid?: string;
  assets: string[];
  name: string;
  newAsset: boolean;
  newAssetName?: string;
  newAssetNumber?: string;
  newAssetUuid?: string;
  newComputer: boolean;
  newComputerName?: string;
  newComputerType?: string;
  computerUuid?: string;
  dataType: string;
  category: string;
}

/**
 * A data item storing information about assets. Either display an existing data item or allows the creation of a new
 * data item related to one or more assets. Also allows a new computer to be registered when creating the new data item.
 * Also allows a new asset to be created and associated with this data item.
 *
 * Can optionally be stored on a computer. Can also be named.
 *
 *
 */
@customElement('d-edit-data-item-asset')
export class DEditDataItemAsset extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
        flex: 1;
      }
      d-edit-text,
      d-select-dropdown-or-add {
        margin-bottom: 12px;
      }
      :host([small-label]) d-edit-text,
      :host([small-label]) d-select-dropdown-or-add {
        margin-bottom: 8px;
      }
      d-label {
        margin-bottom: 6px;
      }
      .assets-wrapper {
        flex: none;
        display: flex;
      }
      .assets-wrapper + .assets-wrapper {
        margin-top: 6px;
      }
      .assets-wrapper > * {
        flex: none;
      }
      .assets-wrapper > *:first-child {
        flex: 1;
      }
    `,
  ];
  /**
   * The available assets
   */
  @property({ type: Array })
  assets: InfosecAsset[] = [];

  /**
   * The available computers
   */
  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];

  /**
   * The data item. Either an existing item or a new "empty"
   */
  @property({ type: Object })
  dataItem!: InfosecDataItem;

  /**
   * light label display property
   */
  @property({ type: Boolean, attribute: 'light-label' })
  lightLabel = false;

  /**
   * small label display property
   */
  @property({ type: Boolean, attribute: 'small-label' })
  smallLabel = false;

  /**
   * The data type. Used for new items.
   */
  @property({ type: Object })
  dataType!: InfosecDataType;

  /**
   * The category. Used for new items.
   */
  @property({ type: Object })
  category!: InfosecCategory;

  /**
   * dispatch data-item-changed event. Debounced for use with editing of text field name.
   * @private
   */
  private fireDebouncedNameChanged = _.debounce((item: InfosecDataItem) => {
    this.dispatchEvent(
      new CustomEvent<DataItemAssetChange>('data-item-changed', {
        composed: true,
        bubbles: true,
        detail: {
          uuid: item.uuid,
          assets: item.assets,
          name: item.name,
          computerUuid: item.storageUnit,
          newAsset: false,
          newComputer: false,
          dataType: this.dataType.type,
          category: this.category.category,
        },
      }),
    );
  }, 1400);

  private get hasAssets() {
    return (
      this.dataItem.assets.filter((asset) => {
        return asset !== '';
      }).length > 0
    );
  }

  _assetOptions(assets, asset) {
    return this.assets
      .filter((item) => {
        return !assets.includes(item.uuid) || item.uuid === asset;
      })
      .map((item) => {
        return {
          value: item.uuid,
          text: item.name + ' ' + item.number,
        };
      });
  }

  _computerOptions() {
    return this.computers.map((computer) => {
      return {
        value: computer.uuid,
        text: getComputerName(computer),
      };
    });
  }

  _dataItemNameChanged(value) {
    let dataItemUuid = this.dataItem.uuid;
    if (dataItemUuid === '') {
      dataItemUuid = uuid();
    }
    this.dataItem = {
      ...this.dataItem,
      name: value,
      uuid: dataItemUuid,
    };
    this._dispatchDataItemUpdated();
    this.fireDebouncedNameChanged(this.dataItem);
  }

  async _assetValueChanged(assetIndex, value) {
    if (value === 'NEW') {
      const result: NewAssetResult = await NewAssetDialog.open({ name: '', number: '' });
      if (result.action === 'done') {
        const assetUuid = uuid();
        this.assets = [
          ...this.assets,
          {
            uuid: assetUuid,
            name: result.name,
            number: result.number,
          },
        ];
        let dataItemUuid = this.dataItem.uuid;
        if (dataItemUuid === '') {
          dataItemUuid = uuid();
        }

        this.dataItem = {
          ...this.dataItem,
          uuid: dataItemUuid,
          assets: [...this.dataItem.assets, assetUuid],
        };
        this._dispatchDataItemUpdated();
        this.dispatchEvent(
          new CustomEvent<DataItemAssetChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              uuid: this.dataItem.uuid,
              assets: this.dataItem.assets,
              name: this.dataItem.name,
              computerUuid: this.dataItem.storageUnit,
              newAsset: true,
              newAssetName: result.name,
              newAssetNumber: result.number,
              newAssetUuid: assetUuid,
              newComputer: false,
              dataType: this.dataType.type,
              category: this.category.category,
            },
          }),
        );
      }
    } else {
      let dataItemUuid = this.dataItem.uuid;
      if (dataItemUuid === '') {
        dataItemUuid = uuid();
      }
      const assets = [...this.dataItem.assets, value];
      this.dataItem = {
        ...this.dataItem,
        uuid: dataItemUuid,
        assets: assets,
      };
      this._dispatchDataItemUpdated();
      this.dispatchEvent(
        new CustomEvent<DataItemAssetChange>('data-item-changed', {
          composed: true,
          bubbles: true,
          detail: {
            uuid: this.dataItem.uuid,
            assets: this.dataItem.assets,
            name: this.dataItem.name,
            computerUuid: this.dataItem.storageUnit,
            newAsset: false,
            newComputer: false,
            dataType: this.dataType.type,
            category: this.category.category,
          },
        }),
      );
    }
  }

  async _computerValueChanged(value) {
    if (value === 'NEW') {
      const result: NewComputerResult = await NewComputerDialog.open({
        name: '',
        requireNameForServers: this.computers.filter((c) => c.computerType === 'Server').length !== 0,
      });
      if (result.action === 'done') {
        let dataItemUuid = this.dataItem.uuid;
        if (dataItemUuid === '') {
          dataItemUuid = uuid();
        }
        this.dataItem = {
          ...this.dataItem,
          uuid: dataItemUuid,
          storageUnit: uuid(),
        };
        this._dispatchDataItemUpdated();
        this.dispatchEvent(
          new CustomEvent<DataItemAssetChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              uuid: this.dataItem.uuid,
              assets: this.dataItem.assets,
              name: this.dataItem.name,
              computerUuid: this.dataItem.storageUnit,
              newAsset: false,
              newComputer: true,
              newComputerName: result.name,
              newComputerType: result.type,
              dataType: this.dataType.type,
              category: this.category.category,
            },
          }),
        );
      }
    } else {
      let dataItemUuid = this.dataItem.uuid;
      if (dataItemUuid === '') {
        dataItemUuid = uuid();
      }
      this.dataItem = {
        ...this.dataItem,
        uuid: dataItemUuid,
        storageUnit: value,
      };
    }
    this._dispatchDataItemUpdated();
    this.dispatchEvent(
      new CustomEvent<DataItemAssetChange>('data-item-changed', {
        composed: true,
        bubbles: true,
        detail: {
          uuid: this.dataItem.uuid,
          assets: this.dataItem.assets,
          name: this.dataItem.name,
          computerUuid: this.dataItem.storageUnit,
          newAsset: false,
          newComputer: false,
          dataType: this.dataType.type,
          category: this.category.category,
        },
      }),
    );
  }

  _removeAsset(assetIndex) {
    const assets = this.dataItem.assets.filter((asset, index) => {
      return index !== assetIndex;
    });
    this.dataItem = {
      ...this.dataItem,
      assets: assets,
    };
    this._dispatchDataItemUpdated();
    this.dispatchEvent(
      new CustomEvent<DataItemAssetChange>('data-item-changed', {
        composed: true,
        bubbles: true,
        detail: {
          uuid: this.dataItem.uuid,
          assets: this.dataItem.assets,
          name: this.dataItem.name,
          computerUuid: this.dataItem.storageUnit,
          newAsset: false,
          newComputer: false,
          dataType: this.dataType.type,
          category: this.category.category,
        },
      }),
    );
  }

  _addAsset() {
    const assets = this.dataItem.assets;
    assets.push('');
    this.dataItem = {
      ...this.dataItem,
      assets: assets,
    };
    this._dispatchDataItemUpdated();
  }

  _dispatchDataItemUpdated() {
    const dataItem = {
      ...this.dataItem,
      assets: this.dataItem.assets.filter((asset) => {
        return asset !== '';
      }),
    };
    this.dispatchEvent(
      new CustomEvent('data-item-updated', {
        bubbles: true,
        composed: true,
        detail: dataItem,
      }),
    );
  }

  render() {
    const assets1 = [...this.dataItem.assets, ''];
    return html`
      <div>
        <d-label ?light=${this.lightLabel} ?small=${this.smallLabel} label="Tilknyttet utstyrsenhet"></d-label>
        ${assets1.map((asset, assetIndex) => {
          return html`
            <div class="assets-wrapper">
              <d-select-dropdown-or-add
                theme-page
                unselectablePlaceholder
                placeholder="Velg utstyrsenhet"
                firstItemText="Ny utstyrsenhet"
                additionalItemText="Annen utstyrsenhet"
                ?disabled=${asset !== ''}
                .options=${asset === ''
                  ? this._assetOptions(assets1, asset)
                  : this.assets.map((item) => {
                      return {
                        value: item.uuid,
                        text: item.name + ' ' + item.number,
                      };
                    })}
                .value=${asset}
                @value-changed=${(e) => this._assetValueChanged(assetIndex, e.detail.value)}
              ></d-select-dropdown-or-add>
              <d-add-remove-buttons
                ?showRemove=${assets1.length > 2 && assetIndex !== assets1.length - 1}
                ?showAdd=${false}
                @remove=${() => this._removeAsset(assetIndex)}
                @add=${() => this._addAsset()}
              ></d-add-remove-buttons>
            </div>
          `;
        })}
      </div>
      <d-expansion ?opened="${this.hasAssets}">
        <d-edit-text
          theme-page
          ?light-label=${this.lightLabel}
          ?small-label=${this.smallLabel}
          label="Programvare"
          placeholder="Eventuelt navn på programvare"
          .value=${this.dataItem.name}
          @value-changed=${(e) => this._dataItemNameChanged(e.detail.value)}
        ></d-edit-text>
        <d-select-dropdown-or-add
          theme-page
          unselectablePlaceholder
          ?light-label=${this.lightLabel}
          ?small-label=${this.smallLabel}
          label="Data lagres også på"
          placeholder="Velg datamaskin"
          firstItemText="Registrer datamaskin"
          additionalItemText="Annen datamaskin"
          .options=${this._computerOptions()}
          .value=${this.dataItem.storageUnit}
          @value-changed=${(e) => this._computerValueChanged(e.detail.value)}
        ></d-select-dropdown-or-add>
      </d-expansion>
    `;
  }
}

/*
 <d-popup-new-asset
        .show=${this.popupAsset}
        @cancel=${() => this._cancelPopupAsset()}
        @done=${(e) => this._donePopupAsset(e.detail)}
      ></d-popup-new-asset>
      <d-popup-new-computer
        .show=${this.popupComputer}
        @cancel=${() => this._cancelPopupComputer()}
        @done=${(e) => this._donePopupComputer(e.detail)}
      ></d-popup-new-computer>
 */

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-data-item-asset': DEditDataItemAsset;
  }
}
