import { css, html, nothing, TemplateResult } from 'lit';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';

/**
 * A class with a table shell for making responsive layouts with pairs, e.g. labels and editors.
 * If horizontal space is sufficient, the pairs are displayed in two columns.
 * If not, the pairs are displayed in one column.
 *
 * Elements extended from this class should have a table row for each pair.
 * Each row should have to cells; the first containing the first part of the pair (e.g. a label);
 * the second containing both parts (e.g. both a label and an editor:
 *
 * <tr>
 *     <td>
 *         <d-label></d-label>
 *     </td>
 *     <td>
 *         <d-label></d-label>
 *         <d-select-dropdown></d-select-dropdown>
 *     </td>
 * </tr>
 **
 */
export class ResponsiveTable extends ResponsiveElement {
  static readonly styles = [
    css`
      :host {
        display: flex;
        justify-content: center;
        overflow: hidden;
      }

      :host(.wrap) {
        display: block;
      }

      table {
        position: relative;
        table-layout: fixed;
        border-collapse: collapse;
      }

      td {
        width: auto;
        vertical-align: top;
        padding-bottom: 10px;
      }

      td:first-child {
        text-align: right;
        padding-right: 4px;
      }

      td > * {
        display: inline-block;
      }

      td:first-child d-label {
        margin-top: 4px;
      }

      td:last-child > *:first-child {
        padding-top: 4px;
        display: none;
      }

      :host(.wrap) td {
        padding-bottom: 6px;
      }

      :host(.wrap) td:last-child > *:first-child {
        display: block;
      }
    `,
  ];

  onResize() {
    if (this.shadowRoot) {
      const container = this.shadowRoot.host as HTMLElement;
      const table = this.shadowRoot.getElementById('table');
      if (table) {
        if (container.offsetWidth < table.offsetWidth) {
          const td = table.getElementsByTagName('td')[0] as HTMLElement;
          table.style.left = '-' + td.offsetWidth + 'px';
          container.classList.add('wrap');
        } else {
          table.style.left = '0';
          container.classList.remove('wrap');
        }
      }
    }
  }

  render() {
    return html`
      <table id="table">
        ${this.renderTableContent()}
      </table>
    `;
  }

  protected renderTableContent(): TemplateResult | typeof nothing {
    return nothing;
  }
}
