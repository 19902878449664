import { html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import type { EmployeeForStaffingCalendar } from 'src/store';
import { defaultWorkEndTime, defaultWorkStartTime, defaultWorkWeek } from 'src/store';
import { LocalDate } from 'src/utilities/local-date.js';

import '../../library/editors/components/d-select-time.js';
import '../../library/editors/elements/d-checkbox.js';
import '../../library/editors/elements/d-select-date.js';
import '../../library/elements/d-action.js';
import '../../library/elements/d-label.js';
import '../../library/elements/d-section.js';
import '../../library/fields/d-expansion.js';
import '../../library/fields/d-view-info.js';

import './d-edit-work-schedule.js';
import { cloneDeep } from 'lodash';
import { BaseDialog, DialogCancelResult } from 'src/library/components/BaseDialog.js';
import { produce } from 'immer';
import type { WorkSchedule } from 'src/store/api';
import { WorkScheduleRestPeriodEnum } from 'src/store/api';

export interface EditWorkSchedulesInput {
  employee: EmployeeForStaffingCalendar;
  defaultDate: string;
}

export type EditWorkSchedulesResult =
  | DialogCancelResult
  | {
      action: 'done';
      workSchedules: WorkSchedule[];
    };

/**
 *
 * USAGE:
 *    d-employee-view
 *    d-staffing-calendar-table
 *
 */
@customElement('edit-work-schedules-dialog')
export class EditWorkSchedulesDialog extends BaseDialog<EditWorkSchedulesInput, EditWorkSchedulesResult> {
  @query('#schedules')
  schedulesElement!: HTMLElement;
  @property({ type: Object })
  employee!: EmployeeForStaffingCalendar;
  @property({ type: Array })
  schedules: WorkSchedule[] = [];
  @property({ type: String })
  day = '';
  @property({ type: Boolean })
  show = false;
  headerActions = [
    { name: 'Avbryt', action: 'cancel' },
    { name: 'Ferdig', action: 'done' },
  ];
  width = 720;

  protected get calculatedTitle() {
    return 'Rediger arbeidsmønster for ' + this.employee.name;
  }

  private get schedulesForEditor() {
    return this.schedules.map((s) => {
      return {
        start: s.start,
        holidays: s.holidaysAreTimeOff,
        workHoursType: s.workHoursDefinedPerDay ? 'custom' : 'default',
        restPeriod: s.restPeriod,
        workDayStart: s.workDayStartTime,
        workDayEnd: s.workDayEndTime,
        workWeeks: s.workWeeks.map((week) => {
          return [week.monday, week.tuesday, week.wednesday, week.thursday, week.friday, week.saturday, week.sunday];
        }),
      };
    });
  }

  private get nextAvailableDate() {
    const scheduleStarts = this.schedules.map((s) => {
      return LocalDate.fromString(s.start).toString();
    });
    let start = LocalDate.fromString(this.day).toString();
    while (scheduleStarts.includes(start)) {
      start = LocalDate.fromString(start).plusDays(1).toString();
    }
    return start;
  }

  _scrollToTop() {
    // this.schedulesElement.scrollTop = 0;
  }

  _new() {
    this.schedules = [
      ...this.schedules,
      {
        start: this.nextAvailableDate,
        holidaysAreTimeOff: true,
        workHoursDefinedPerDay: false,
        restPeriod: WorkScheduleRestPeriodEnum.WithoutPay,
        workDayStartTime: defaultWorkStartTime,
        workDayEndTime: defaultWorkEndTime,
        workWeeks: [defaultWorkWeek],
      },
    ];
    /*
    const element = this.schedulesElement;
    setTimeout(function () {
      const frameHeight = element.offsetHeight;
      const contentHeight = element.scrollHeight;
      let scrollPos = element.scrollTop;
      const scroll = function () {
        if (scrollPos >= contentHeight - frameHeight) {
          clearInterval(scrollInterval);
        }
        scrollPos += 10;
        element.scrollTop = scrollPos;
      };
      const scrollInterval = setInterval(scroll, 5);
    }, 100);

     */
  }

  renderBody() {
    return html`
      <d-section>
        <d-view-info info-list field="staffing_editWorkHours"></d-view-info>
      </d-section>
      ${this.schedules.map(
        (schedule, index) => html`
          <d-section>
            <d-wrap split-reverse>
              <d-action @click=${() => this.onScheduleDelete(index)}>Slett periode</d-action>
              <d-edit-work-schedule
                .schedule=${schedule}
                .schedules=${this.schedules}
                @schedule-changed=${(e) => this.onScheduleChanged(e, index)}
              ></d-edit-work-schedule>
            </d-wrap>
          </d-section>
        `,
      )}
      <d-section>
        <d-wrap right>
          <d-action @click=${() => this._new()}>Ny periode</d-action>
        </d-wrap>
      </d-section>
    `;
  }

  protected fetchResult(detail: string | undefined): EditWorkSchedulesResult {
    if (detail === 'done') {
      return {
        action: 'done',
        workSchedules: this.schedules,
      };
    }
    return {
      action: 'cancel',
    };
  }

  protected initializeDialog(input: EditWorkSchedulesInput) {
    this.employee = input.employee;
    this.day = input.defaultDate;
    this.schedules = cloneDeep(input.employee.workSchedules ?? []);
  }

  private onScheduleDelete(index: number) {
    this.schedules = produce(this.schedules, (draft) => {
      draft.splice(index, 1);
    });
  }

  private onScheduleChanged(e: CustomEvent<{ schedule: WorkSchedule }>, index: number) {
    e.stopPropagation();
    this.schedules = produce(this.schedules, (draft) => {
      draft[index] = e.detail.schedule;
    });
    this.fireSchedulesChanged();
  }

  private fireSchedulesChanged() {
    this.dispatchEvent(
      new CustomEvent<{ schedules: WorkSchedule[] }>('schedules-changed', {
        composed: true,
        bubbles: true,
        detail: { schedules: this.schedules },
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'edit-work-schedules-dialog': EditWorkSchedulesDialog;
  }
}
