import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 *
 * STATUS OK
 */
@customElement('d-closer')
export class DCloser extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 6px;
      right: 6px;
      width: 24px;
      height: 24px;
      opacity: 0.4;
      cursor: pointer;
    }

    :host(:hover) {
      opacity: 1;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  `;

  @property({ type: String })
  color = 'black';

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
        <rect
          x="-1.29"
          y="4.21"
          width="12.57"
          height="1.57"
          fill="${this.color}"
          transform="translate(-2.07 5) rotate(-45)"
        />
        <rect
          x="4.21"
          y="-1.29"
          width="1.57"
          height="12.57"
          fill="${this.color}"
          transform="translate(-2.07 5) rotate(-45)"
        />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-closer': DCloser;
  }
}
