import type { ActionCreator } from 'redux';
import type { UpdateStaffingCalendarYearAction } from '../types';
import { ThunkResult, updateOrganizationProperties } from './actions';

export const updateStaffingCalendarYear: ActionCreator<UpdateStaffingCalendarYearAction> = (value: string) => {
  return {
    type: 'UPDATE_STAFFING_CALENDAR_YEAR',
    staffingCalendarYear: value,
  };
};

export function updateStaffingCalendarAccessList(employeeUuids: string[]): ThunkResult<Promise<void>> {
  const p = {
    staffingCalendarAccessList: employeeUuids,
  };
  return updateOrganizationProperties(p);
}
