import { createSelector } from '@reduxjs/toolkit';
import type { OrganizationState } from '../types.js';
import { getOrganization, organizationName } from './organization.js';
import { _pageName, getPages, PageViewModelExtended } from './pages.js';
import { splitPath } from './path.js';
import { itemsByType } from './utilities.js';

function _addPagePaths(
  pageId: number,
  e: { name: string; href: string }[],
  pages: PageViewModelExtended[],
  basePath: string,
): void {
  e.push({
    name: _pageName(pageId, pages),
    href: basePath + '/' + pageId,
  });
}

function itemsByTypeElement(organization: OrganizationState, entityType: string, entityId: string) {
  if (entityType === 'eventOccurrences') return undefined;
  if (entityType === 'meetingOccurrences') return undefined;
  return itemsByType(organization, entityType)[entityId];
}

function _entity(s: string[], e: { name: string; href: string }[], organization: OrganizationState): void {
  const entityType = s.shift();
  if (entityType !== undefined) {
    const shift = s.shift();
    if (shift !== undefined) {
      const entityId = shift.split(':')[0];
      const entityInstance = shift.split(':')[1];

      if (entityId === 'new' || entityType === 'tutorials') {
        e.push({
          name: '...',
          href: '',
        });
      } else {
        let basePath = '';
        if (e.length > 0) {
          basePath = e[e.length - 1].href;
        }

        const item = itemsByTypeElement(organization, entityType, entityId);

        if (item) {
          let name = '';
          if ('name' in item) {
            name = item.name ?? '';
          }
          if (name === '' && 'lastName' in item && 'firstName' in item) {
            name = item.firstName + ' ' + item.lastName;
          }
          if (entityType === 'events' && 'taskUuid' in item && item.taskUuid && !item.doneDate) {
            const tasksByIdElement = organization.tasksById[item.taskUuid];
            if (tasksByIdElement) {
              name = tasksByIdElement.name ?? '';
            }
          }
          e.push({
            name: name,
            href: basePath + '/' + entityType + '/' + entityId + (entityInstance ? ':' + entityInstance : ''),
          });
        }
      }
    }
  }
}

export const breadCrumbPathsComplete = createSelector(
  splitPath,
  organizationName,
  getPages,
  getOrganization,
  function (segments, orgName, pages, organization) {
    const basePath = segments.slice(0, 3).join('/');
    const firstPageId = 695944;

    const e: { name: string; href: string }[] = [];

    if (pages && organization) {
      // Add home page
      e.push({
        name: orgName,
        href: basePath + '/' + firstPageId,
      });

      // Add page name
      const pageId = +segments[3];
      _addPagePaths(pageId, e, pages, basePath);

      // Add entities
      const s = segments.slice(4);
      while (s.length > 0) {
        _entity(s, e, organization);
      }
    }

    return e;
  },
);

export const breadCrumbSelectedName = createSelector(breadCrumbPathsComplete, function (paths) {
  return paths.length > 0 ? paths[paths.length - 1].name : '';
});
