/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AccountStateEmployeeViewModel
 */
export interface AccountStateEmployeeViewModel {
  /**
   *
   * @type {string}
   * @memberof AccountStateEmployeeViewModel
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateEmployeeViewModel
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateEmployeeViewModel
   */
  associationType?: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateEmployeeViewModel
   */
  profession?: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateEmployeeViewModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AccountStateEmployeeViewModel
   */
  accessLevel?: AccountStateEmployeeViewModelAccessLevelEnum;
  /**
   *
   * @type {string}
   * @memberof AccountStateEmployeeViewModel
   */
  uuid: string;
}

/**
 * @export
 */
export const AccountStateEmployeeViewModelAccessLevelEnum = {
  None: 'NONE',
  Readonly: 'READONLY',
  User: 'USER',
  Assets: 'ASSETS',
} as const;
export type AccountStateEmployeeViewModelAccessLevelEnum =
  (typeof AccountStateEmployeeViewModelAccessLevelEnum)[keyof typeof AccountStateEmployeeViewModelAccessLevelEnum];

/**
 * Check if a given object implements the AccountStateEmployeeViewModel interface.
 */
export function instanceOfAccountStateEmployeeViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;

  return isInstance;
}

export function AccountStateEmployeeViewModelFromJSON(json: any): AccountStateEmployeeViewModel {
  return AccountStateEmployeeViewModelFromJSONTyped(json, false);
}

export function AccountStateEmployeeViewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccountStateEmployeeViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    associationType: !exists(json, 'associationType') ? undefined : json['associationType'],
    profession: !exists(json, 'profession') ? undefined : json['profession'],
    email: !exists(json, 'email') ? undefined : json['email'],
    accessLevel: !exists(json, 'accessLevel') ? undefined : json['accessLevel'],
    uuid: json['uuid'],
  };
}

export function AccountStateEmployeeViewModelToJSON(value?: AccountStateEmployeeViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    firstName: value.firstName,
    lastName: value.lastName,
    associationType: value.associationType,
    profession: value.profession,
    email: value.email,
    accessLevel: value.accessLevel,
    uuid: value.uuid,
  };
}
