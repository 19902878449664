import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LocalDate } from 'src/utilities/local-date';
import '../elements/d-action';
import '../elements/d-label';
import './d-positioner';
import { holidaysForStaffingCalendar } from 'src/store';
import { classMap } from 'lit/directives/class-map.js';
import { PropertyValues } from 'lit/development';
import { numberWord } from 'src/utilities/text';
import { Occurrence } from 'src/library/lists/d-occurrences-list';

export interface CalendarEvent {
  /**
   * The occurrenceUuid may be either an eventOccurrenceUuid or a meetingOccurrenceUuid depending on the context.
   */
  occurrenceUuid: string;
  dateTime: string;
  href?: string;
  classified: boolean;
  restricted: boolean;
  assignees?: string;
}

export interface CalendarDay {
  dayOfMonth: number;
  date: string;
  disabled?: boolean;
}

export interface CalendarMonth {
  name: string;
  days: CalendarDay[];
  disabled?: boolean;
}

@customElement('d-calendar-year')
export class DCalendarYear extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      font-size: 10px;
      line-height: 120%;
      color: hsl(0, 0%, 30%);
    }

    :host * {
      box-sizing: border-box;
      line-height: 120%;
      user-select: none;
    }

    .year {
      width: fit-content;
    }

    .year-name {
      padding-bottom: 0.8em;
      text-align: center;
      font-size: 1.4em;
    }

    .months {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -0.65em 0 -0.65em;
      padding-bottom: 1.2em;
      overflow: hidden;
    }

    .month {
      width: 17em;
      margin: 0 0.65em 0 0.65em;
      padding-top: 1.4em;
    }

    .month-name {
      font-size: 1.4em;
      font-weight: 500;
      padding-bottom: 0.7em;
    }

    .month-name:first-letter {
      text-transform: uppercase;
    }

    .weekdays {
      display: flex;
      margin: -0.25em;
    }

    .weekdays > div {
      flex: 1;
      margin: 0.25em;
      width: 2em;
      height: 1.6em;
      font-size: 0.9em;
      color: hsl(0, 0%, 50%);
      text-align: center;
    }

    .days {
      display: flex;
      flex-wrap: wrap;
      margin: -0.25em;
      overflow: hidden;
    }

    .day {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0.25em;
      width: 2em;
      height: 2em;
      border-radius: 0;
      text-align: center;
      overflow: hidden;
      transition: all 0.3s;
    }

    :host([edit]) .day {
      cursor: pointer;
    }

    .day.spacer,
    .day[disabled],
    .day.restricted,
    :host([edit]) .day.meeting-initialized {
      cursor: default;
    }

    .day:not(.spacer) {
      background: hsla(0, 0%, 0%, 0.06);
      color: hsla(0, 0%, 0%, 0.8);
    }

    .day[disabled] {
      opacity: 0.5;
    }

    .day.occurrence,
    .day.multiple-occurrences {
      border-radius: 50%;
      transform: scale(1.15);
      color: white;
      cursor: pointer;
    }

    .day.occurrence {
      background: var(--themeColor);
    }

    .day.occurrence.classified {
      background: var(--alertColor);
    }

    .day.occurrence.restricted {
      background: red;
      cursor: default;
    }

    .day.multiple-occurrences {
      background: var(--themeColorDarkerTwo);
    }

    .day.occurrence.meeting-initialized {
      border: 1px solid black;
    }

    .hover {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0.3em;
      background: hsla(0, 0%, 0%, 0.1);
      opacity: 0;
    }

    .day.spacer .hover,
    .day[disabled] .hover,
    .day.restricted .hover,
    :host([edit]) .day.meeting-initialized .hover {
      display: none;
    }

    @media (hover: hover) {
      :host([edit]) .day:hover .hover,
      .day.occurrence:hover .hover {
        opacity: 1;
      }
    }

    .day:not(.spacer) .day-name {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 1em;
      font-weight: 200;
    }

    @media (hover: hover) {
      :host([edit]) .occurrence:not([disabled]) .day-name:hover {
        text-decoration: line-through;
      }
      :host([edit]) .occurrence.restricted .day-name:hover,
      :host([edit]) .occurrence.meeting-initialized .day-name:hover {
        text-decoration: none;
      }
    }

    .day.holiday > .day-name {
      color: red;
    }

    .year-header {
      position: sticky;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-top: 1px solid var(--borderColor);
      border-bottom: 1px solid var(--borderColor);
      margin-top: -1px;
      background: white;
      padding: 12px 0;
      font-size: 14px;
      color: var(--text-color);
      z-index: 1;
    }

    .year-header > * {
      margin-right: 12px;
    }

    .legend {
      display: flex;
      align-items: center;
      margin: 2px 10px 2px 0;
      font-size: 15px;
    }

    .legend:before {
      content: '';
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      width: 18px;
      height: 18px;
      margin-right: 6px;
      border-radius: 50%;
    }

    .legend.meeting-initialized:before {
      border: 1px solid black;
    }

    .legend.classified:before {
      background: var(--alertColor);
    }

    .legend.restricted:before {
      background: red;
    }

    .legend.multiple-occurrences:before {
      background: var(--themeColorDarkerTwo);
    }

    d-calendar-year {
      margin: 12px 0;
    }

    #popup {
      position: relative;
      padding: 0.4em;
      background: white;
      border-radius: 0.4em;
      font-size: 10px;
      font-weight: 200;
    }

    #popup > div.occurrence {
      background-color: var(--themeColor);
      font-size: 1.2em;
      color: white;
      padding: 0.4em 0.6em 0.3em 0.6em;
      border-radius: 0.2em;
    }

    #popup > div.occurrence:not(.restricted):not(.meeting-initialized) {
      cursor: pointer;
    }

    :host(:not([edit])) #popup > div.occurrence.meeting-initialized:not(.restricted) {
      cursor: pointer;
    }

    #popup > div.occurrence + div.occurrence {
      margin-top: 0.4em;
    }

    #popup > div.occurrence:first-letter {
      text-transform: uppercase;
    }

    #popup > div.occurrence.meeting-initialized {
      border: 1px solid black;
    }

    #popup > div.occurrence.classified {
      background-color: var(--alertColor);
    }

    #popup > div.occurrence.restricted {
      background-color: red;
    }

    @media (hover: hover) {
      :host([edit]) #popup > div.occurrence:not(.restricted):not(.meeting-initialized):hover,
      :host(:not([edit])) #popup > div.occurrence:not(.restricted):hover {
        background-color: var(--themeColorDarkerOne);
      }
      :host #popup > div.occurrence.classified:not(.restricted):not(.meeting-initialized):hover {
        background-color: var(--alertColorDarkerOne);
      }
      :host([edit]) #popup > div.occurrence:not(.restricted):not(.meeting-initialized):hover {
        text-decoration: line-through;
      }
    }

    .backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    @media only screen and (max-width: 600px) {
      :host {
        font-size: 4.8vw;
      }

      .month-name {
        font-size: 17px;
      }

      .weekdays > div {
        font-size: 13px;
      }

      .day-name {
        font-size: 15px;
        font-weight: 300;
      }
    }
  `;

  @property({ type: Boolean, reflect: true })
  edit = false;
  @property({ type: Number })
  year = 2023;
  @property({ type: Array })
  occurrences: Occurrence[] = [];
  @property({ type: String })
  addText = '';
  @property({ type: String })
  disableBefore = '';
  @property({ type: String })
  baseHref = '';
  @property({ type: String })
  meetingUuid = '';
  @property({ type: String })
  interviewedEmployeeUuid = '';
  @property({ type: Number })
  tellMonthPosition = 0;
  @property({ type: Array })
  selectorOccurrences: Occurrence[] = [];
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: String })
  eventTerm = 'oppgave';
  @property({ type: Object })
  clickTarget: HTMLElement | undefined = undefined;

  private holidays = holidaysForStaffingCalendar();

  private get occurrenceCountText() {
    let result = numberWord(this.occurrences.length) + ' ' + this.eventTerm;
    if (this.occurrences.length !== 1) {
      result += 'r';
    } else if (this.eventTerm === 'møte') {
      result = result.replace('én', 'ett');
    }
    return result[0].toUpperCase() + result.substring(1);
  }

  private get yearHasSameDayOccurrences() {
    let result = false;
    this.occurrences.forEach((o) => {
      const dayOccurrences = this.occurrences.filter((o2) => {
        return o2.dateTime.split(' ')[0] === o.dateTime.split(' ')[0];
      });
      if (dayOccurrences.length > 1) {
        result = true;
      }
    });
    return result;
  }

  dayOccurrences(date: string): Occurrence[] | undefined {
    const dayOccurrences = this.occurrences.filter((e) => {
      return e.dateTime.split(' ')[0] === date;
    });
    if (dayOccurrences.length) {
      return dayOccurrences;
    }
    return undefined;
  }

  formatDateTime(dateTime: string) {
    const dateTimeArr = dateTime.split(' ');
    const date = LocalDate.fromString(dateTimeArr[0]).toStringForDisplayWithDayOfWeek();
    let time = '';
    if (dateTimeArr.length > 1) {
      time = ' kl. ' + dateTimeArr[1].replace(':', '.');
    }
    return date + time;
  }

  monthDays(monthStart: LocalDate) {
    const result: CalendarDay[] = [];
    const n = monthStart.dayOfWeek();
    for (let i = 0; i < n; i++) {
      result.push({ dayOfMonth: 0, date: '' });
    }
    for (let dayOfMonth = 1; dayOfMonth <= monthStart.daysInMonth(); dayOfMonth++) {
      const dateObj = monthStart.plusDays(dayOfMonth - 1);
      let disabled = false;
      if (this.disableBefore && dateObj.isBefore(LocalDate.fromString(this.disableBefore))) {
        disabled = true;
      }
      result.push({ dayOfMonth, date: dateObj.toString(), disabled });
    }
    return result;
  }

  months(year) {
    const result: CalendarMonth[] = [];
    const monthsCount = 12;
    let monthStart = LocalDate.fromString(year + '-01-01');
    for (let i = 0; i < monthsCount; i++) {
      result.push({
        name: monthStart.nameOfMonth(),
        days: this.monthDays(monthStart),
      });
      monthStart = monthStart.plusMonths(1);
    }
    return result;
  }

  resetPopup() {
    this.clickTarget = undefined;
    this.selectorOccurrences = [];
  }

  occurrenceClassMap(occurrence: Occurrence, type) {
    let meetingInitialized = false;
    if (occurrence.meetingStatus === 'NOTICE_SENT' || occurrence.meetingStatus === 'REPORT_WRITTEN') {
      meetingInitialized = true;
    }
    return {
      classified: occurrence.classified,
      restricted: occurrence.restricted,
      'meeting-initialized': meetingInitialized,
      type,
    };
  }

  dayClassMap(day) {
    let occurrence = false;
    let multipleOccurrences = false;
    let classified = false;
    let restricted = false;
    let meetingInitialized = false;
    const dayOccurrences = this.dayOccurrences(day.date);
    if (dayOccurrences) {
      if (dayOccurrences.length > 1) {
        multipleOccurrences = true;
      } else {
        occurrence = true;
        classified = dayOccurrences[0].classified;
        restricted = dayOccurrences[0].restricted;
        meetingInitialized =
          dayOccurrences[0].meetingStatus === 'NOTICE_SENT' || dayOccurrences[0].meetingStatus === 'REPORT_WRITTEN';
      }
    }
    return {
      spacer: day.dayOfMonth === 0,
      holiday: this.holidays[day.date] !== undefined,
      occurrence,
      'multiple-occurrences': multipleOccurrences,
      classified,
      restricted,
      'meeting-initialized': meetingInitialized,
    };
  }

  private get yearHasClassified() {
    return (
      this.occurrences.filter((o) => {
        return o.classified && !o.restricted;
      }).length > 0
    );
  }

  private get yearHasRestricted() {
    return (
      this.occurrences.filter((o) => {
        return o.restricted;
      }).length > 0
    );
  }

  private get yearHasMeetingInitialized() {
    return (
      this.occurrences.filter((o) => {
        return o.meetingStatus === 'NOTICE_SENT' || o.meetingStatus === 'REPORT_WRITTEN';
      }).length > 0
    );
  }

  private isDeletable(occurrence: Occurrence) {
    return (
      !occurrence.restricted &&
      !occurrence.disabled &&
      occurrence.meetingStatus !== 'NOTICE_SENT' &&
      occurrence.meetingStatus !== 'REPORT_WRITTEN'
    );
  }

  showSameDayOccurrencesSelector(occurrences, target) {
    this.selectorOccurrences = occurrences;
    this.clickTarget = target;
  }

  dispatchDayClicked(date) {
    this.dispatchEvent(
      new CustomEvent('day-clicked', {
        bubbles: true,
        composed: true,
        detail: { value: date },
      }),
    );
  }

  navigate(href) {
    this.dispatchEvent(
      new CustomEvent('navigate', {
        bubbles: true,
        composed: true,
        detail: { href },
      }),
    );
  }

  onDayClick(e, day) {
    if (!day.disabled) {
      const dayOccurrences = this.dayOccurrences(day.date);
      if (dayOccurrences) {
        if (dayOccurrences.length > 1) {
          const target = e.target;
          this.showSameDayOccurrencesSelector(dayOccurrences, target);
        } else {
          if (this.edit) {
            if (this.isDeletable(dayOccurrences[0])) {
              this.dispatchDayClicked(day.date);
            }
          } else {
            this.navigate(dayOccurrences[0].href);
          }
        }
      } else {
        this.dispatchDayClicked(day.date);
      }
    }
  }

  onOccurrenceClick(occurrence) {
    if (this.edit) {
      if (this.isDeletable(occurrence)) {
        this.dispatchEvent(
          new CustomEvent('occurrence-clicked', {
            bubbles: true,
            composed: true,
            detail: { value: occurrence.dateTime },
          }),
        );
      }
    } else {
      this.navigate(occurrence.href);
    }
    this.resetPopup();
  }

  renderSameDayOccurrencesSelector(occurrences) {
    if (occurrences.length) {
      return html` <div id="popup">
        ${occurrences.map((occurrence) => {
          return html`
            <div
              class="occurrence ${classMap(this.occurrenceClassMap(occurrence, 'edit'))}"
              @click=${() => {
                this.onOccurrenceClick(occurrence);
              }}
            >
              ${this.formatDateTime(occurrence.dateTime)}
            </div>
          `;
        })}
      </div>`;
    }
    return nothing;
  }

  renderWeekdays() {
    return html`<div class="weekdays">
      <div>M</div>
      <div>T</div>
      <div>O</div>
      <div>T</div>
      <div>F</div>
      <div>L</div>
      <div>S</div>
    </div>`;
  }

  renderMonthDays(days: CalendarDay[]) {
    return html`<div class="days">
      ${days.map((day) => {
        return html`
          <div
            class="day ${classMap(this.dayClassMap(day))}"
            ?disabled="${day.disabled}"
            @click=${(e: CustomEvent) => this.onDayClick(e, day)}
          >
            ${day.dayOfMonth
              ? html`
                  <div class="hover"></div>
                  <div class="day-name">${day.dayOfMonth}</div>
                `
              : nothing}
          </div>
        `;
      })}
    </div>`;
  }

  render() {
    return html`
      <div id="header" class="year-header" style="top:${this.contentStickyTop - 1}px">
        <d-label big .label=${this.year + ''} .sublabel=${this.occurrenceCountText}></d-label>
        ${this.yearHasSameDayOccurrences ? html` <div class="legend multiple-occurrences">Flere ${this.eventTerm}r på samme dag</div> ` : nothing}
        ${this.yearHasClassified ? html` <div class="legend classified">Begrenset tilgang</div> ` : nothing}
        ${this.yearHasRestricted ? html` <div class="legend restricted">Ingen tilgang</div> ` : nothing}
        ${this.yearHasMeetingInitialized ? html` <div class="legend meeting-initialized">Møte med innkalling eller referat${this.edit ? ' (kan ikke slettes her)' : ''}</div> ` : nothing}
      </div>
      <div class="months">
        ${this.months(this.year).map((month, index) => {
          return html` <div class="month" id=${'month-' + (index + 1)} ?disabled="${month.disabled}">
            <div class="month-name">${month.name}</div>
            ${this.renderWeekdays()} ${this.renderMonthDays(month.days)}
          </div>`;
        })}
      </div>
      </div>
      <d-positioner .target=${this.clickTarget} @hidden=${() => this.resetPopup()}>
        ${this.renderSameDayOccurrencesSelector(this.selectorOccurrences)}
      </d-positioner>`;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    if (_changedProperties.has('tellMonthPosition') && this.tellMonthPosition) {
      setTimeout(() => {
        const headerElm = this.shadowRoot?.getElementById('header') as HTMLElement;
        const currentMonthElm = this.shadowRoot?.getElementById('month-' + this.tellMonthPosition) as HTMLElement;
        if (headerElm && currentMonthElm) {
          const headerHeight = headerElm.getBoundingClientRect().height;
          const top = currentMonthElm.getBoundingClientRect().top;
          this.dispatchEvent(
            new CustomEvent('current-month-position', {
              bubbles: true,
              composed: true,
              detail: { value: top - headerHeight },
            }),
          );
        }
      }, 0);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-calendar-year': DCalendarYear;
  }
}
