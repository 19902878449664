import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 *
 * @fires value-changed - Dispatched immediately after changes by the user
 *
 */
@customElement('d-rating')
export class DRating extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 2px;
    }
    div {
      display: inline-block;
      cursor: pointer;
    }
    div span {
      margin: 5em 1em;
      position: relative;
      display: inline-block;
      width: 0;
      height: 0;
      border-right: 10em solid transparent;
      border-bottom: 7em solid hsl(0, 0%, 80%);
      border-left: 10em solid transparent;
      transform: rotate(35deg);
      opacity: 0.7;
    }
    div span:before {
      border-bottom: 8em solid hsl(0, 0%, 80%);
      border-left: 3em solid transparent;
      border-right: 3em solid transparent;
      position: absolute;
      height: 0;
      width: 0;
      top: -4.5em;
      left: -6.5em;
      display: block;
      content: '';
      transform: rotate(-35deg);
    }
    div span:after {
      position: absolute;
      display: block;
      top: 0.3em;
      left: -10.5em;
      width: 0;
      height: 0;
      border-right: 10em solid transparent;
      border-bottom: 7em solid hsl(0, 0%, 80%);
      border-left: 10em solid transparent;
      transform: rotate(-70deg);
      content: '';
    }
    div:hover span {
      opacity: 1;
    }
    div[selected] span,
    div[selected] span:before,
    div[selected] span:after {
      border-bottom-color: var(--themeColor);
      opacity: 1;
    }
  `;
  @property({ type: Number })
  value = 0;

  _rate(index) {
    this.value = index + 1;
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  render() {
    return html`
      ${Array(5)
        .fill(0)
        .map(
          (n, index) =>
            html`<div ?selected=${this.value > index} @click=${() => this._rate(index)}><span></span></div>`,
        )}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-rating': DRating;
  }
}
