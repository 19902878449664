import { css, html, LitElement } from 'lit';
import '../../library/fields/d-view-text.js';
import { customElement } from 'lit/decorators.js';
import { leavePeriodTypes } from '../../store/selectors';
import { leavePeriodStyles } from 'src/library/leave-period-styles.js';

/**
 *
 */
@customElement('d-calendar-legend')
export class DCalendarLegend extends LitElement {
  static readonly styles = [
    leavePeriodStyles,
    css`
      :host {
        display: flex;
        flex-wrap: wrap;
        color: hsl(1, 0%, 40%);
      }

      div {
        margin-right: 14px;
        flex: none;
        font-size: 13px;
        margin-bottom: 12px;
      }

      div:before {
        display: inline-block;
        content: ' ';
        width: 20px;
        height: 20px;
        margin-right: 6px;
        position: relative;
        top: 6px;
      }

      .workDay:before {
        background-color: white;
        border: 1px solid hsla(1, 0%, 0%, 0.15);
        box-sizing: border-box;
      }

      .partTime:before {
        background: white url(/images/trans15.svg) 10px -1px no-repeat;
        background-size: 32px 32px;
        border: 1px solid hsla(1, 0%, 0%, 0.15);
        box-sizing: border-box;
      }

      .regularOff:before {
        background-color: hsla(1, 0%, 0%, 0.15);
      }

      div.suggestion:before {
        background-image: url(/images/hatched.png);
        background-size: 30px 30px;
      }

      div.plusTime:before {
        background-image: linear-gradient(
          to bottom,
          var(--themeColorDarkerOne) 4px,
          hsl(196, 83%, 90%) 4px,
          hsla(0, 0%, 100%, 0)
        );
      }
    `,
  ];

  render() {
    return html`
      <div class="workDay">Vanlig arbeidstid</div>
      <div class="plusTime">Plusstid</div>

      ${leavePeriodTypes.map((type) => html`<div class="${type.value}">${type.text}</div>`)}

      <div class="vacation suggestion">Ubekreftede fraværsperioder vises skravert</div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-calendar-legend': DCalendarLegend;
  }
}
