import { css, html, LitElement, nothing } from 'lit';

import '../editors/elements/d-checkbox.js';
import './d-common-activity-codes.js';
import './d-activity-codes.js';
import type { ActivityCode, ActivityCodeCommon } from 'src/store';
import { customElement, property, state } from 'lit/decorators.js';
import '../fields/d-expansion.js';
import '../elements/d-action.js';

/**
 *
 * STATUS OK
 */
@customElement('d-activity-codes-container')
export class DActivityCodesContainer extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      margin: 6px 0;
    }

    d-action {
      margin-left: -8px;
    }
  `;
  @property({ type: Array })
  codes: ActivityCode[] = [];
  @property({ type: Array })
  commonActivityCodes: ActivityCodeCommon[] = [];
  @property({ type: Array })
  activityCodes: string[] = [];
  @property({ type: Boolean, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean, reflect: true })
  outskirts = false;
  @state()
  private showAll = false;

  get hasOnlyCommonCodes() {
    let result = true;
    let flattenedCommonCodes: string[] = [];

    const flattenCommonCodes = (commonCodes) => {
      commonCodes.forEach((i) => {
        flattenedCommonCodes = flattenedCommonCodes.concat(i.code);
        if (i.codes) {
          flattenCommonCodes(i.codes);
        }
      });
    };

    flattenCommonCodes(this.commonActivityCodes);
    this.activityCodes.forEach((i) => {
      if (!flattenedCommonCodes.includes(i)) {
        result = false;
      }
    });
    return result;
  }

  findSelfAndDescendants(code: string) {
    const result: string[] = [];

    const addCodes = function (a: ActivityCode[], include: boolean) {
      a.forEach(function (i) {
        const n = include || i.code === code;
        if (n) {
          result.push(i.code);
        }
        if (i.codes) {
          addCodes(i.codes, n);
        }
      });
    };

    addCodes(this.codes, false);
    return result;
  }

  _showAllCodes(hasOnlyCommonCodes: boolean, showAll: boolean) {
    if (!hasOnlyCommonCodes) {
      return true;
    }
    return showAll;
  }

  render() {
    const b = this._showAllCodes(this.hasOnlyCommonCodes, this.showAll);
    return html`
      <d-expansion ?opened=${!b}>
        <d-common-activity-codes
          @activity-codes-changed=${this.onActivityCodesChanged}
          .activityCodes=${this.activityCodes}
          .commonActivityCodes=${this.commonActivityCodes}
          ?theme-page=${this.themePage}
          .outskirts=${this.outskirts}
        ></d-common-activity-codes>
      </d-expansion>

      <d-expansion ?opened=${b}>
        ${this.codes.map(
          (item) =>
            html` <d-activity-codes
              @activity-code-changed=${this.onActivityCodeChanged}
              .codes=${this.activityCodes}
              .data=${item}
              ?theme-page=${this.themePage}
              .outskirts=${this.outskirts}
            ></d-activity-codes>`,
        )}
      </d-expansion>

      ${this.hasOnlyCommonCodes
        ? html`
            <d-action ?outskirts="${this.outskirts}" @click=${this.toggle}>
              ${this.showAll ? nothing : html` <span>Vis alle tjenestetyper</span> `}
              ${!this.showAll ? nothing : html` <span>Vis bare vanligste tjenestetyper</span> `}
            </d-action>
          `
        : nothing}
    `;
  }

  private onActivityCodeChanged(e: CustomEvent<{ code: string; active: boolean }>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.detail.active) {
      this.activityCodes = [...this.activityCodes, e.detail.code];
    } else {
      const codesToDelete = this.findSelfAndDescendants(e.detail.code);
      this.activityCodes = this.activityCodes.filter((c) => !codesToDelete.includes(c));
    }
    this.dispatchEvent(
      new CustomEvent<{ codes: string[] }>('activity-codes-changed', {
        bubbles: true,
        composed: true,
        detail: {
          codes: this.activityCodes,
        },
      }),
    );
  }

  private onActivityCodesChanged(e: CustomEvent<{ codes: string[] }>) {
    e.stopPropagation();
    this.activityCodes = e.detail.codes;
    this.dispatchEvent(
      new CustomEvent<{ codes: string[] }>('activity-codes-changed', {
        bubbles: true,
        composed: true,
        detail: {
          codes: this.activityCodes,
        },
      }),
    );
  }

  private toggle() {
    this.showAll = !this.showAll;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-activity-codes-container': DActivityCodesContainer;
  }
}
