import { createSelector } from '@reduxjs/toolkit';
import type { OrganizationState, State } from '../types.js';
import { _sortByName, toList } from './utilities.js';
import { currentEmployeeUuid, employeeNamesAsText, employeesShortNamesAndI } from './organization-employees.js';
import { LocalDate, mondayThisWeek } from 'src/utilities/local-date.js';
import { getOrganization, getOrganizationId, singleUserVersion } from './organization.js';
import type { FunctionViewModel, TaskViewModel } from '../api';
import { currentEntityId, currentEntityType, currentPageId } from './path.js';
import { RecurrenceRule } from '../recurrence-rule/recurrence-rule.js';
import { joinWithAnd } from 'src/utilities/text.js';

export interface StartTaskInstance {
  uuid: string;
  name: string;
  time: LocalDate;
  weeksFromNow: number;
  timeDisplay: string;
  executed: boolean;
  functionName: string;
  employeeName: string;
  myTask: boolean;
  href: string;
  helpContent: string;
  procedures: string;
  relatedItems?: { href: string; name: string }[];
}

export interface SingleFunctionTemplateIds {
  generalManager: number;
  hrManager: number;
  financialManager: number;
  hseManager: number;
  safetyRepresentative: number;
  infectionControlOfficer: number;
  radiationProtectionCoordinator: number;
  academicSupervisor: number;
  equipmentManager: number;
  ictResponsible: number;
  qualityManager: number;
}

export const singleFunctionTemplateIds: SingleFunctionTemplateIds = {
  generalManager: 179,
  hrManager: 181,
  financialManager: 183,
  hseManager: 193,
  safetyRepresentative: 13962,
  infectionControlOfficer: 7956,
  radiationProtectionCoordinator: 6345,
  academicSupervisor: 186,
  equipmentManager: 190,
  ictResponsible: 195,
  qualityManager: 199,
};

export const functionsNotDeleted = createSelector(getOrganization, (organization): FunctionViewModel[] => {
  if (organization === undefined) {
    return [];
  }
  return toList(organization.functionsById).filter((e) => !e.deleted);
});

export const functionsWithEmployeeNames = createSelector(
  getOrganization,
  employeesShortNamesAndI,
  (
    organization,
    employeesShortNamesAndIList,
  ): {
    uuid: string;
    type: string;
    name: string;
    employees: string[];
  }[] => {
    if (organization === undefined) return [];
    const result: { uuid: string; type: string; name: string; employees: string[] }[] = [];
    const functions = toList(organization.functionsById).filter((e) => !e.deleted && e.isConfirmedEntity);
    functions.forEach(function (item) {
      const namesList: string[] = [];
      employeesShortNamesAndIList.forEach(function (nameItem) {
        if (item.employees.includes(nameItem.uuid) && nameItem.name !== '') {
          namesList.push(nameItem.name);
        }
      });
      let namesString: string;
      if (namesList.length) {
        namesString = ' (' + joinWithAnd(namesList) + ')';
      } else {
        namesString = ' (ingen)';
      }
      result.push({
        uuid: item.uuid,
        type: item.type,
        name: item.name + namesString,
        employees: item.employees,
      });
    });
    return result;
  },
);

export const singleFunctionsWithEmployeeNames = createSelector(
  functionsWithEmployeeNames,
  (
    functions,
  ): {
    uuid: string;
    type: string;
    name: string;
    employees: string[];
  }[] => {
    return functions.filter(function (item) {
      return item.type === 'SINGLE';
    });
  },
);

function functionDeleted(organization: OrganizationState | undefined, functionUuid: string | undefined): boolean {
  return organization === undefined || functionUuid === undefined || !(functionUuid in organization.functionsById)
    ? true
    : organization.functionsById[functionUuid].deleted;
}

export const tasksNotDeleted = createSelector(getOrganization, (organization): TaskViewModel[] => {
  if (organization === undefined) return [];
  return toList(organization.tasksById).filter((e) => !e.deleted && !functionDeleted(organization, e.functionUuid));
});
createSelector(getOrganization, (organization): TaskViewModel[] => {
  if (organization === undefined) return [];
  return toList(organization.tasksById).filter(
    (e) => !e.deleted && !e.locked && !functionDeleted(organization, e.functionUuid),
  );
});
export function tasksDeleted(state: State): TaskViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.tasksById).filter((e) => e.deleted);
}

const functionNamesById = createSelector(getOrganization, (organization) => {
  const result: { [uuid: string]: string } = {};

  if (organization) {
    toList(organization.functionsById).forEach((e) => {
      result[e.uuid] = e.name !== undefined ? e.name : '';
    });
  }
  return result;
});

export const tasksNotDeletedWithFunctionName = createSelector(
  tasksNotDeleted,
  functionNamesById,
  singleUserVersion,
  (tasks, names, singleUser) =>
    tasks.map(function (t) {
      let functionPrefix = '';
      if (!singleUser) {
        functionPrefix = names[t.functionUuid] + ': ';
      }
      return Object.assign({}, t, {
        name: functionPrefix + t.name,
        functionUuid: t.functionUuid,
      });
    }),
);

function compareProperty(a?: string, b?: string): number {
  return a || b ? (!a ? -1 : !b ? 1 : a.localeCompare(b)) : 0;
}

export const tasksForAssets = createSelector(
  getOrganizationId,
  tasksNotDeletedWithFunctionName,
  functionsNotDeleted,
  (organizationId, tasks, functions) => {
    const isMainAssetFunction = (functionUuid: string): boolean => {
      const f = functions.filter((item) => item.uuid === functionUuid)[0];
      if (f) {
        return f.templateId === 190;
      }
      return false;
    };

    const isOtherAssetFunction = (functionUuid: string): boolean => {
      const f = functions.filter((item) => item.uuid === functionUuid)[0];
      if (f) {
        return f.pages !== undefined && f.pages.includes(355);
      }
      return false;
    };

    const href = (task: TaskViewModel): string => {
      const page = (functionUuid: string): number => {
        const parentFunction = functions.filter((item) => item.uuid === functionUuid)[0];
        if (parentFunction && parentFunction.pages !== undefined && parentFunction.pages.length > 0) {
          return parentFunction.pages[0];
        }
        throw Error('Illegal state (E901)');
      };

      if (task.pages !== undefined && task.pages.includes(355)) {
        return '/account/' + organizationId + '/355/tasks/' + task.uuid;
      } else if (isMainAssetFunction(task.functionUuid) || isOtherAssetFunction(task.functionUuid)) {
        return '/account/' + organizationId + '/355/functions/' + task.functionUuid + '/tasks/' + task.uuid;
      } else {
        return (
          '/account/' +
          organizationId +
          '/' +
          page(task.functionUuid) +
          '/functions/' +
          task.functionUuid +
          '/tasks/' +
          task.uuid
        );
      }
    };

    return tasks
      .map((item) => {
        let sort = 9;
        if (item.assets.length) {
          sort = 1;
        } else if (isMainAssetFunction(item.functionUuid)) {
          sort = 2;
        } else if (isOtherAssetFunction(item.functionUuid)) {
          sort = 3;
        } else if (item.pages !== undefined && item.pages.includes(355)) {
          sort = 4;
        }
        return { ...item, href: href(item), sort };
      })
      .sort(_sortByName)
      .sort((a, b) => a.sort - b.sort);
  },
);
export const commonFunctionUuid = createSelector(functionsNotDeleted, (functions) => {
  const commonFunction = functions.filter((item) => item.templateId === 429)[0];
  if (commonFunction) {
    return commonFunction.uuid;
  }
});
createSelector(
  functionsNotDeleted,
  // Returns function Utstyrsansvarlig if not deleted, else Allmenn funksjon
  (functions) => {
    const assetFunction = functions.filter((item) => item.templateId === 190)[0];
    if (assetFunction) {
      return assetFunction.uuid;
    }
    const commonFunction = functions.filter((item) => item.templateId === 429)[0];
    if (commonFunction) {
      return commonFunction.uuid;
    }
    return functions.length > 0 ? functions[0].uuid : '';
  },
);
createSelector(functionsNotDeleted, currentEmployeeUuid, (functions, uuid) =>
  functions.filter((f) => uuid !== undefined && f.employees !== undefined && f.employees.includes(uuid)),
);
const functionsWithTaskCount = createSelector(getOrganization, (m) => {
  if (m === undefined) {
    return [];
  }
  const withTaskCount = function (e: FunctionViewModel): FunctionViewModel & { taskCount: number } {
    return Object.assign({}, e, {
      taskCount: toList(m.tasksById)
        .filter(function (c) {
          return !c.deleted;
        })
        .filter(function (c) {
          return c.functionUuid === e.uuid || c.responsibleFunctionUuid === e.uuid;
        }).length,
    });
  };

  return toList(m.functionsById).map(withTaskCount);
});

export const functionsDeletedWithTaskCount = createSelector(functionsWithTaskCount, (functions) =>
  functions.filter((c) => c.deleted),
);

export const functionsNotDeletedWithTaskCount = createSelector(functionsWithTaskCount, (functions) =>
  functions.filter((c) => !c.deleted),
);

export const currentEmployeeFunctions = createSelector(
  functionsNotDeleted,
  currentEmployeeUuid,
  (functions, employeeId) => {
    const list: string[] = [];
    for (const item of functions) {
      if (employeeId !== undefined && item.employees !== undefined && item.employees.includes(employeeId)) {
        list.push(item.uuid);
      }
    }
    return list;
  },
);

export function toFunctionViewModel(
  item: FunctionViewModel,
  o: OrganizationState,
): FunctionViewModel & { assignedEmployeeNames: string } {
  return Object.assign({}, item, {
    assignedEmployeeNames: item.type === 'COMMON' ? '' : employeeNamesAsText(item, o),
  });
}

export function toTaskViewModel(item: TaskViewModel, o: OrganizationState): TaskViewModel & { functionName: string } {
  const functionName = item.functionUuid ? o.functionsById[item.functionUuid].name : '';
  return Object.assign({}, item, {
    functionName: functionName === undefined ? '' : functionName,
  });
}
function undefinedToEmpty(s?: string): string {
  if (s) return s;
  else return '';
}

function compareSortAndName(a: { sort?: string; name?: string }, b: { sort?: string; name?: string }): number {
  return compareProperty(a.sort, b.sort) || compareProperty(a.name, b.name);
}

export interface FunctionWithTasksForPageViewModel extends FunctionViewModel {
  tasks: TaskViewModel[];
  sort: string;
  assignedNames: string;
  href: string;
  onPage: boolean;
}

function _functionsForPage(
  organization: OrganizationState,
  functions: FunctionViewModel[],
  tasks: TaskViewModel[],
  pageId: number,
): FunctionWithTasksForPageViewModel[] {
  if (organization === undefined) {
    return [];
  }
  const functionsForPage: FunctionWithTasksForPageViewModel[] = [];
  functions.forEach(function (functionItem) {
    const result: FunctionWithTasksForPageViewModel = Object.assign({}, functionItem, {
      tasks: [],
      sort: '',
      assignedNames: '',
      href: '/account/' + organization.organizationId + '/' + pageId + '/functions/' + functionItem.uuid,
      onPage: false,
    });

    let functionHasTask = false;
    let functionHasRelatedTask = false;
    let functionIsResponsible = false;
    if (functionItem.type !== 'COMMON' && functionItem.pages.includes(pageId)) {
      result.onPage = true;
    }
    if (result.type === 'COMMON') {
      result.name = 'Fellesansvar';
      result.assignedNames = 'Alle';
    } else {
      result.assignedNames = employeeNamesAsText(functionItem, organization);
    }
    tasks.forEach(function (taskItem) {
      let taskIsRelated = false;
      // if task has current function as responsible
      if (taskItem.responsibleFunctionUuid && taskItem.responsibleFunctionUuid === functionItem.uuid) {
        functionIsResponsible = true;
      }
      // if task is assigned to current function
      if (taskItem.functionUuid === functionItem.uuid) {
        functionHasTask = true;
        let taskHasResponsibleFunction = false;
        if (taskItem.responsibleFunctionUuid) {
          const functionViewModel = organization.functionsById[taskItem.responsibleFunctionUuid];
          if (functionViewModel === undefined) {
            console.error('Undefined responsible function ' + taskItem.responsibleFunctionUuid);
          } else {
            taskHasResponsibleFunction = true;
            // If responsible function for current task belongs to page
            if (functionViewModel.pages.includes(pageId)) {
              taskIsRelated = true;
              functionHasRelatedTask = true;
            }
          }
        }
        // Tasks assigned to the common function that have no responsible function should display on the page Ledelse
        if (functionItem.type === 'COMMON' && !taskHasResponsibleFunction && pageId === 357) {
          taskIsRelated = true;
          functionHasRelatedTask = true;
        }
        // If task is set to show on page
        const tasksByIdElement = organization.tasksById[taskItem.uuid];
        if (tasksByIdElement?.pages?.includes(pageId)) {
          taskIsRelated = true;
          functionHasRelatedTask = true;
        }
        if (result.onPage || taskIsRelated) {
          result.tasks.push(taskItem);
        }
      }
    });
    if (!functionHasTask) {
      result.sort = 'G';
      if (result.type === 'ROTATED') {
        result.sort = 'F';
      }
      if (result.type === 'SINGLE') {
        result.sort = 'E';
      }
    }
    if (result.onPage) {
      result.sort = 'D';
      if (result.type === 'ROTATED') {
        result.sort = 'C';
      }
      if (result.type === 'SINGLE') {
        if (functionIsResponsible) {
          result.sort = 'A';
        } else {
          result.sort = 'B';
        }
      }
    } else {
      result.sort = 'K';
      if (result.type === 'ROTATED') {
        result.sort = 'J';
      }
      if (result.type === 'SINGLE') {
        if (functionIsResponsible) {
          result.sort = 'H';
        } else {
          result.sort = 'I';
        }
      }
      if (result.type === 'COMMON') {
        result.sort = 'L';
      }
    }
    if (result.onPage || functionHasRelatedTask) {
      result.tasks.sort(function (a, b) {
        return undefinedToEmpty(a.name).toLowerCase().localeCompare(undefinedToEmpty(b.name).toLowerCase());
      });
      functionsForPage.push(result);
    }
  });
  return functionsForPage.sort(compareSortAndName);
}

function _isPageTask(task: TaskViewModel, pageId: number, organization: OrganizationState): boolean {
  if (!task || !pageId || !organization) {
    return false;
  }
  const assignedFunctionPages = organization.functionsById[task.functionUuid].pages;
  const assignedToCommon = organization.functionsById[task.functionUuid].type === 'COMMON';
  let hasResponsibleFunction = false;
  let responsibleFunctionPages: number[] = [];
  if (task.responsibleFunctionUuid) {
    hasResponsibleFunction = true;
    const responsibleFunction = organization.functionsById[task.responsibleFunctionUuid];
    if (responsibleFunction) {
      responsibleFunctionPages = responsibleFunction.pages;
    }
  }
  return (
    (assignedFunctionPages.includes(pageId) && !assignedToCommon) ||
    (assignedToCommon && !hasResponsibleFunction && pageId === 357) ||
    responsibleFunctionPages.includes(pageId) ||
    (task.pages !== undefined && task.pages.includes(pageId))
  );
}

function _isPageStartTask(task: TaskViewModel, pageId: number, organization: OrganizationState): boolean {
  if (!task || !pageId || !organization) {
    return false;
  }
  const assignedFunctionPages = organization.functionsById[task.functionUuid].pages;
  return assignedFunctionPages.includes(pageId) || (task.pages !== undefined && task.pages.includes(pageId));
}
function _startTaskDate(task: TaskViewModel, startTaskInstances: StartTaskInstance[]): string {
  if (task.locked) {
    return startTaskInstances
      .filter(function (item) {
        return item.uuid === task.uuid;
      })[0]
      .time.toString();
  }
  return '';
}
function getStartTaskInstances(
  organizationId: number | null,
  tasks: TaskViewModel[],
  singleUser: boolean,
  functions: FunctionViewModel[],
  employeeUuid: string | undefined,
  employeeNames: { uuid: string; name: string }[],
  startAsMondayThisWeek: LocalDate,
) {
  const t: StartTaskInstance[] = [];
  tasks.forEach((task) => {
    let time: LocalDate | undefined = undefined;
    let weeksFromNow = -1;
    let executed = false;
    let add = false;
    if (task.locked && task.rrule && RecurrenceRule.hasStartDate(task.rrule)) {
      const rrule = RecurrenceRule.fromString(task.rrule);
      time = rrule.startDate();
      const daysFromNow = startAsMondayThisWeek.until(time);
      if (daysFromNow === 0) {
        weeksFromNow = 0;
      } else {
        weeksFromNow = daysFromNow / 7;
      }
      add = true;
    } else if (task.lastExecuted) {
      time = LocalDate.fromString(task.lastExecuted);
      executed = true;
      add = true;
    }
    if (add) {
      const functionForTask = functions.find((f) => f.uuid === task.functionUuid);
      if (functionForTask && time !== undefined) {
        let functionName = '';
        let employeeName = '';
        if (!singleUser) {
          functionName = functionForTask.name ?? '';
          if (functionForTask.employees.length > 0) {
            const uuid = functionForTask.employees[0];
            const e = employeeNames.find(function (item) {
              return item.uuid === uuid;
            });
            if (e) {
              employeeName = e.name;
            }
          }
        }
        const functionPage = functionForTask.pages[0];
        let myTask = false;
        if (functionForTask.employees[0] === employeeUuid) {
          myTask = true;
        }
        const timeDisplay = 'Uke ' + ('00' + time.weekOfYear()).slice(-2) + ' ' + time.year();
        const href =
          '/account/' + organizationId + '/' + functionPage + '/functions/' + task.functionUuid + '/tasks/' + task.uuid;
        t.push({
          uuid: task.uuid,
          name: task.name !== undefined ? task.name : '',
          time: time,
          weeksFromNow: weeksFromNow,
          timeDisplay: timeDisplay,
          executed: executed,
          functionName: functionName,
          employeeName: employeeName,
          myTask: myTask,
          href: href,
          helpContent: task.helpContent ?? '',
          procedures: task.procedures ?? '',
        });
      }
    }
  });
  t.sort((a: StartTaskInstance, b: StartTaskInstance) => a.time.asInteger() - b.time.asInteger());
  return t;
}

export const startTasks = createSelector(
  getOrganizationId,
  tasksNotDeleted,
  singleUserVersion,
  functionsNotDeleted,
  currentEmployeeUuid,
  employeesShortNamesAndI,
  mondayThisWeek,
  (organizationId, tasks, singleUser, functions, currentEmployeeUuid, employeeNames, mondayThisWeek) => {
    return getStartTaskInstances(
      organizationId,
      tasks,
      singleUser,
      functions,
      currentEmployeeUuid,
      employeeNames,
      mondayThisWeek,
    );
  },
);

export const startTasksForCurrentPage = createSelector(
  getOrganization,
  getOrganizationId,
  tasksNotDeleted,
  singleUserVersion,
  functionsNotDeleted,
  currentEmployeeUuid,
  employeesShortNamesAndI,
  mondayThisWeek,
  currentPageId,
  startTasks,
  (
    organization,
    organizationId,
    tasks,
    singleUser,
    functions,
    currentEmployeeUuid,
    employeeNames,
    mondayThisWeek,
    pageId,
    startTaskInstances,
  ) => {
    if (organization === undefined || pageId === undefined) {
      return [];
    }
    const t = tasks
      .filter(function (task) {
        return _isPageStartTask(task, pageId, organization) && (task.locked || task.lastExecuted);
      })
      .sort(function (a, b) {
        const dateA = _startTaskDate(a, startTaskInstances);
        const dateB = _startTaskDate(b, startTaskInstances);
        return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
      });
    return getStartTaskInstances(
      organizationId,
      t,
      singleUser,
      functions,
      currentEmployeeUuid,
      employeeNames,
      mondayThisWeek,
    );
  },
);
createSelector(
  currentEntityId,
  currentEntityType,
  getOrganization,
  tasksNotDeleted,
  (id, type, organization, tasks) => {
    if (organization === undefined) {
      return [];
    }

    if (id === undefined || id === 'new') {
      return [];
    }

    if (type !== 'functions') {
      return [];
    }

    return tasks.filter((t) => t.functionUuid === id).map((t) => toTaskViewModel(t, organization));
  },
);

export const functionsWithTasksForCurrentPage = createSelector(
  getOrganization,
  functionsNotDeleted,
  tasksNotDeleted,
  currentPageId,
  (organization, functions, tasks, pageId) => {
    if (organization === undefined || pageId === undefined) {
      return [];
    }
    const regularTasks = tasks.filter(function (item) {
      return !item.locked;
    });
    return _functionsForPage(organization, functions, regularTasks, pageId);
  },
);

export const tasksForCurrentPage = createSelector(
  tasksNotDeleted,
  currentPageId,
  getOrganization,
  (tasks, pageId, organization): TaskViewModel[] => {
    if (organization === undefined || pageId === undefined) {
      return [];
    }
    return tasks
      .filter(function (task) {
        return _isPageTask(task, pageId, organization) && !task.locked;
      })
      .sort(function (a, b) {
        if (!a.name || !b.name) {
          return 0;
        }
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
  },
);
