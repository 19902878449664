import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../library/editors/elements/d-checkbox.js';
import '../../library/editors/elements/d-select-dropdown.js';

export type ReminderType =
  | 'NONE'
  | 'AT_START'
  | 'ONE_HOUR_BEFORE'
  | 'TWO_HOURS_BEFORE'
  | 'THREE_HOURS_BEFORE'
  | 'FIVE_HOURS_BEFORE';

/**
 *
 * STATUS OK
 */
@customElement('d-edit-reminder')
export class DEditReminder extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      flex-direction: row;
    }

    d-select-dropdown {
      width: 160px;
      margin-top: 0;
      margin-left: 10px;
    }
  `;

  private static reminderOptions = [
    { value: 'AT_START', text: 'Ved start' },
    { value: 'ONE_HOUR_BEFORE', text: 'Én time før' },
    { value: 'TWO_HOURS_BEFORE', text: 'To timer før' },
    { value: 'THREE_HOURS_BEFORE', text: 'Tre timer før' },
    { value: 'FIVE_HOURS_BEFORE', text: 'Fem timer før' },
  ];

  @property({ type: String })
  value: ReminderType = 'NONE';
  @property({ type: Boolean })
  disabled = false;

  private get reminder() {
    return this.value !== 'NONE' && !this.disabled;
  }

  private onCheckChanged(e: CustomEvent<{ checked: boolean }>) {
    e.stopPropagation();
    if (e.detail.checked) {
      this.value = 'AT_START';
    } else {
      this.value = 'NONE';
    }

    this.dispatchEvent(
      new CustomEvent<{ value: ReminderType }>('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  private onOptionChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.value = e.detail.value as ReminderType;
    this.dispatchEvent(
      new CustomEvent<{ value: ReminderType }>('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  render() {
    return html`
      <d-checkbox
        ?checked=${this.reminder}
        ?disabled=${this.disabled}
        option-field="tasks_reminder"
        @checked-changed=${this.onCheckChanged}
      ></d-checkbox>

      ${this.reminder
        ? html`<d-select-dropdown
            .value=${this.value}
            .options=${DEditReminder.reminderOptions}
            @value-changed=${this.onOptionChanged}
          ></d-select-dropdown> `
        : nothing}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-reminder': DEditReminder;
  }
}
