/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SendMessageCommand
 */
export interface SendMessageCommand {
  /**
   *
   * @type {string}
   * @memberof SendMessageCommand
   */
  subject?: string;
  /**
   *
   * @type {string}
   * @memberof SendMessageCommand
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof SendMessageCommand
   */
  url?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SendMessageCommand
   */
  employees?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SendMessageCommand
   */
  messageType?: SendMessageCommandMessageTypeEnum;
}

/**
 * @export
 */
export const SendMessageCommandMessageTypeEnum = {
  Sms: 'SMS',
  Email: 'EMAIL',
} as const;
export type SendMessageCommandMessageTypeEnum =
  (typeof SendMessageCommandMessageTypeEnum)[keyof typeof SendMessageCommandMessageTypeEnum];

/**
 * Check if a given object implements the SendMessageCommand interface.
 */
export function instanceOfSendMessageCommand(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SendMessageCommandFromJSON(json: any): SendMessageCommand {
  return SendMessageCommandFromJSONTyped(json, false);
}

export function SendMessageCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendMessageCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    subject: !exists(json, 'subject') ? undefined : json['subject'],
    message: !exists(json, 'message') ? undefined : json['message'],
    url: !exists(json, 'url') ? undefined : json['url'],
    employees: !exists(json, 'employees') ? undefined : json['employees'],
    messageType: !exists(json, 'messageType') ? undefined : json['messageType'],
  };
}

export function SendMessageCommandToJSON(value?: SendMessageCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    subject: value.subject,
    message: value.message,
    url: value.url,
    employees: value.employees,
    messageType: value.messageType,
  };
}
