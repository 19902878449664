import { RiskAssessmentView, RiskAssessmentViewEditItem } from 'src/content/risk-assessments/d-risk-assessment-view.js';
import {
  fetchDraft,
  fetchRevisions,
  pagesAsSelectTagOptions,
  toAttachmentItems,
} from 'src/models/factory-utilities.js';
import { BASE_PATH, EmployeeViewModelStatusEnum, RiskAssessmentViewModel } from 'src/store/api';
import { employeesNotDeleted, getOrganization, key, toRiskAssessmentViewModel, writeAccess } from 'src/store/selectors';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { commonFieldsByCode } from 'src/store/fields.js';
import { assertIsDefined } from 'src/lib';
import { shortenPath } from 'src/utilities/path';
import { CommonDataEntityView } from 'src/content/entity-content';

function informationSecurityDescription(entity: RiskAssessmentViewModel) {
  if (!entity.violatesInformationSecurityRequirements) {
    return 'Ingen';
  }

  const v: string[] = [];

  if (entity.violatesConfidentialityRequirement) {
    v.push(commonFieldsByCode()['riskAssessments_violatesConfidentialityRequirement'].label);
  }
  if (entity.violatesIntegrityRequirement) {
    v.push(commonFieldsByCode()['riskAssessments_violatesIntegrityRequirement'].label);
  }
  if (entity.violatesAvailabilityRequirement) {
    v.push(commonFieldsByCode()['riskAssessments_violatesAvailabilityRequirement'].label);
  }
  if (entity.violatesNonRepudiationRequirement) {
    v.push(commonFieldsByCode()['riskAssessments_violatesNonRepudiationRequirement'].label);
  }

  if (v.length === 0) {
    return 'Ingen';
  }

  return v.join(', ');
}

export async function buildRiskAssessmentView(
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<RiskAssessmentView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const employeeDisplayName = (id: string | undefined) => {
    if (id === undefined) {
      return '';
    }
    const e = state.organization?.employeesById[id];
    if (e === undefined) {
      return '';
    }

    return e.name;
  };
  const item = organization.riskAssessmentsById[uuid];
  assertIsDefined(item);

  const entity = toRiskAssessmentViewModel(item);
  const substance = entity.relatedSubstance ? organization.substancesById[entity.relatedSubstance] : undefined;

  return {
    ...commonData,
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess: true,
    currentUserHasWriteAccess: writeAccess(state),
    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: entity.helpContent ?? '',
    href: currentParent + '/riskAssessments/' + uuid,
    pdfHref:
      BASE_PATH +
      '/organizations/' +
      organization.organizationId +
      '/riskAssessments/' +
      uuid +
      '.pdf?key=' +
      key(state),
    createdFromTemplate: entity.createdFromTemplate,
    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'riskAssessments',
    name: entity.name ?? '',
    revisions: await fetchRevisions(
      state.token ?? '',
      'riskAssessments',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
    ),
    attachments: toAttachmentItems('riskAssessments', entity.uuid, entity.attachments),
    responsibleByDisplayName: employeeDisplayName(entity.responsibleBy),
    determinedByDisplayName: employeeDisplayName(entity.determinedBy),
    consequence: entity.consequence ?? 0,
    probability: entity.probability ?? 0,
    measures: entity.measures ?? '',
    substanceRelated: substance !== undefined,
    relatedSubstanceName: substance?.name ?? '',
    relatedSubstanceHref: substance
      ? shortenPath(currentParent + '/riskAssessments/' + uuid + '/substances/' + substance?.uuid)
      : '',
    informationSecurityDescription: informationSecurityDescription(entity),
    riskDescription: entity.riskDescription ?? '',
    description: entity.description ?? '',
    assessmentDate: entity.assessmentDate ?? '',
    availablePages: pagesAsSelectTagOptions(state).map((p) => ({ ...p, disabled: p.value === '279' })),
    pages: (entity.pages ?? []).map((x) => '' + x),
    violatesInformationSecurityRequirements: entity.violatesInformationSecurityRequirements === true,
    violatesConfidentialityRequirement: entity.violatesConfidentialityRequirement === true,
    violatesIntegrityRequirement: entity.violatesIntegrityRequirement === true,
    violatesNonRepudiationRequirement: entity.violatesNonRepudiationRequirement === true,
    violatesAvailabilityRequirement: entity.violatesAvailabilityRequirement === true,
    responsibleBy: entity.responsibleBy ?? '',
    determinedBy: entity.determinedBy ?? '',
    today: LocalDate.fromString(state.today),
    availableEmployees: employeesNotDeleted(state)
      .filter((e) => e.status !== EmployeeViewModelStatusEnum.Terminated && e.isConfirmedEntity)
      .map((p) => ({ value: p.uuid, text: p.name })),
    deletable: true,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(state.token ?? '', 'riskAssessments', uuid, organization.organizationId);
      return command.draft as RiskAssessmentViewEditItem;
    },
  };
}
