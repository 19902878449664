import { css, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { LocalDate } from 'src/utilities/local-date.js';
import '../../library/editors/elements/d-select-date.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/editors/elements/d-select-tag.js';
import '../../library/elements/d-label.js';
import '../../library/elements/d-wrap.js';
import { endYear } from 'src/store';

/**
 *
 * The base class for recurrence editors
 *
 */
export abstract class BaseEditRecurrence extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;
  /**
   * The recurrence rule in a restricted RRULE format.
   */
  @property({ type: String })
  recurrence = '';
  /**
   * The start date. Used to set default day of week or day of month values. Also used to limit the selectable until date
   * to be after this start date.
   */
  @property({ type: String })
  startDate = '';
  protected readonly weekdays = [
    { id: 'MO', value: 'M' },
    { id: 'TU', value: 'T' },
    { id: 'WE', value: 'O' },
    { id: 'TH', value: 'T' },
    { id: 'FR', value: 'F' },
    { id: 'SA', value: 'L' },
    { id: 'SU', value: 'S' },
  ];
  protected untilOptions = [
    { value: 'NONE', text: 'uten slutt' },
    { value: 'UNTIL', text: 'inntil dato' },
  ];
  protected get frequencyOptions() {
    return [
      { value: 'NONE', text: 'Ingen' },
      { value: 'DAILY-1', text: 'Hver dag' },
      { value: 'WEEKLY-1', text: 'Hver uke' },
      { value: 'WEEKLY-2', text: 'Hver andre uke' },
      { value: 'WEEKLY-3', text: 'Hver tredje uke' },
      { value: 'WEEKLY-4', text: 'Hver fjerde uke' },
      { value: 'WEEKLY-5', text: 'Hver femte uke' },
      { value: 'WEEKLY-6', text: 'Hver sjette uke' },
      { value: 'MONTHLY-1', text: 'Hver måned' },
      { value: 'MONTHLY-2', text: 'Hver andre måned' },
      { value: 'MONTHLY-3', text: 'Hver tredje måned' },
      { value: 'MONTHLY-4', text: 'Hver fjerde måned' },
      { value: 'MONTHLY-5', text: 'Hver femte måned' },
      { value: 'MONTHLY-6', text: 'Hver sjette måned' },
      { value: 'YEARLY-1', text: 'Hvert år' },
      { value: 'YEARLY-2', text: 'Hvert andre år' },
      { value: 'YEARLY-3', text: 'Hvert tredje år' },
      { value: 'YEARLY-4', text: 'Hvert fjerde år' },
      { value: 'YEARLY-5', text: 'Hvert femte år' },
      { value: 'YEARLY-6', text: 'Hvert sjette år' },
    ];
  }

  protected get recurrenceTypeOptions(): SelectDropdownOption[] {
    return [
      { value: 'BYMONTHDAY', text: this.byMonthdayOption },
      { value: 'BYDAY', text: this.byDayOption },
    ];
  }

  protected get recurrenceType(): string {
    const recurrenceArray = this.recurrence.split(';');
    const byday = recurrenceArray.find((s) => s.startsWith('BYDAY='));
    return byday ? 'BYDAY' : 'BYMONTHDAY';
  }

  protected get freqInterval(): string {
    return this.freq + (this.freq === 'NONE' ? '' : '-' + this.interval);
  }

  protected get endDate(): string {
    const recurrenceArray = this.recurrence.split(';');
    const until = recurrenceArray.find((s) => s.startsWith('UNTIL='));
    return until ? LocalDate.fromRecurrenceRuleString(until.split('=')[1]).toString() : endYear + '-12-31';
  }

  protected get freq() {
    if (this.recurrence === '') {
      return 'NONE';
    } else {
      const recurrenceArray = this.recurrence.split(';');

      const frequency = recurrenceArray.find((s) => s.startsWith('FREQ='));

      if (frequency) {
        switch (frequency) {
          case 'FREQ=DAILY':
            return 'DAILY';
          case 'FREQ=WEEKLY':
            return 'WEEKLY';
          case 'FREQ=MONTHLY':
            return 'MONTHLY';
          case 'FREQ=YEARLY':
            return 'YEARLY';
          default:
            throw new Error('Illegal state (E41), unexpected frequency ' + frequency + ' in ' + this.recurrence);
        }
      }

      throw new Error('Illegal state (E42), no frequency found in ' + this.recurrence);
    }
  }

  protected get interval() {
    const recurrenceArray = this.recurrence.split(';');

    const interval = recurrenceArray.find((s) => s.startsWith('INTERVAL='));
    return interval ? Number(interval.split('=')[1]) : 1;
  }

  protected get weekdayOptions() {
    const weekdayIndex = LocalDate.fromString(this.startDate).dayOfWeek().valueOf();
    return this.weekdays.map((o, index) => {
      return { value: o.id, text: o.value, disabled: weekdayIndex === index };
    });
  }

  protected get byDay(): string[] {
    if (this.freq === 'WEEKLY') {
      const recurrenceArray = this.recurrence.split(';');
      const weekdayIndex = LocalDate.fromString(this.startDate).dayOfWeek().valueOf();

      const field = recurrenceArray.find((s) => s.startsWith('BYDAY='));
      return field
        ? this.weekdays.filter((d) => field.split('=')[1].split(',').includes(d.id)).map((d) => d.id)
        : [this.weekdays[weekdayIndex].id];
    } else {
      return [];
    }
  }

  protected get byMonthday() {
    return LocalDate.fromString(this.startDate).day();
  }

  protected get byMonth() {
    return LocalDate.fromString(this.startDate).month();
  }

  protected get bySetpos() {
    const date = LocalDate.fromString(this.startDate).day();
    let setpos = 1;
    if (date > 7 && date < 15) {
      setpos = 2;
    }
    if (date > 14 && date < 22) {
      setpos = 3;
    }
    if (date > 21 && date < 29) {
      setpos = 4;
    }
    if (LocalDate.fromString(this.startDate).daysInMonth() - date < 7) {
      setpos = -1;
    }
    return setpos;
  }
  protected get byMonthdayOption() {
    let option = 'på den ' + LocalDate.fromString(this.startDate).day() + '.';
    if (this.freq === 'YEARLY') {
      option += ' ' + LocalDate.fromString(this.startDate).nameOfMonth();
    }
    return option;
  }

  protected get byDayOption() {
    const weekday = LocalDate.fromString(this.startDate).getDayOfWeekString() + 'en';
    const date = LocalDate.fromString(this.startDate).day();
    const month = LocalDate.fromString(this.startDate).nameOfMonth();
    let ordinal = 'første ';
    if (date > 7 && date < 15) {
      ordinal = 'andre ';
    }
    if (date > 14 && date < 22) {
      ordinal = 'tredje ';
    }
    if (date > 21 && date < 29) {
      ordinal = 'fjerde ';
    }
    if (LocalDate.fromString(this.startDate).daysInMonth() - date < 7) {
      ordinal = 'siste ';
    }
    let option = 'på den ' + ordinal + weekday;
    if (this.freq === 'YEARLY') {
      option += ' i ' + month;
    }
    return option;
  }
  protected computeDisableBefore(fromTime: string) {
    return LocalDate.fromString(fromTime).plusDays(1).toString();
  }

  protected onFreqIntervalChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    const freq = e.detail.value.split('-')[0];
    const interval = Number(e.detail.value.split('-')[1] ?? '1');
    const weekdayIndex = LocalDate.fromString(this.startDate).dayOfWeek().valueOf();
    const defaultWeekday = this.weekdays[weekdayIndex].id;

    if (freq === this.freq) {
      this.recurrence = this.getRecurrenceRule(interval, this.recurrenceType, this.endDate);
    } else {
      const untilClause =
        this.endDate === '' ? '' : ';UNTIL=' + LocalDate.fromString(this.endDate).toStringForRecurrenceRule();
      switch (freq) {
        case 'NONE':
          this.recurrence = '';
          break;
        case 'DAILY':
          this.recurrence = 'FREQ=DAILY' + untilClause;
          break;
        case 'WEEKLY':
          this.recurrence = 'FREQ=WEEKLY;INTERVAL=' + interval + ';BYDAY=' + defaultWeekday + untilClause;

          break;
        case 'MONTHLY':
          this.recurrence = 'FREQ=MONTHLY;INTERVAL=' + interval + ';BYMONTHDAY=' + this.byMonthday + untilClause;

          break;
        case 'YEARLY':
          this.recurrence =
            'FREQ=YEARLY;INTERVAL=' +
            interval +
            ';BYMONTHDAY=' +
            this.byMonthday +
            ';BYMONTH=' +
            this.byMonth +
            untilClause;
          break;

        default:
          throw new Error('Illegal state (E43), invalid frequency ' + freq);
      }
    }
    this.fireValueChanged();
  }

  protected getRecurrenceRule(interval: number, recurrenceType: string, endDate: string) {
    const weekdayIndex = LocalDate.fromString(this.startDate).dayOfWeek().valueOf();
    const defaultWeekday = this.weekdays[weekdayIndex].id;

    return (
      'FREQ=' +
      this.freq +
      ';INTERVAL=' +
      interval +
      (this.freq === 'WEEKLY' ? ';BYDAY=' + this.byDay.join(',') : '') +
      (this.freq === 'MONTHLY' && recurrenceType === 'BYMONTHDAY' ? ';BYMONTHDAY=' + this.byMonthday : '') +
      (this.freq === 'MONTHLY' && recurrenceType === 'BYDAY'
        ? ';BYDAY=' + defaultWeekday + ';BYSETPOS=' + this.bySetpos
        : '') +
      (this.freq === 'YEARLY' && recurrenceType === 'BYMONTHDAY'
        ? ';BYMONTHDAY=' + this.byMonthday + ';BYMONTH=' + this.byMonth
        : '') +
      (this.freq === 'YEARLY' && recurrenceType === 'BYDAY'
        ? ';BYDAY=' + defaultWeekday + ';BYSETPOS=' + this.bySetpos + ';BYMONTH=' + this.byMonth
        : '') +
      (endDate === '' ? '' : ';UNTIL=' + LocalDate.fromString(endDate).toStringForRecurrenceRule())
    );
  }

  protected onWeekdaysChanged(e: CustomEvent<{ value: string[] }>) {
    e.stopPropagation();
    this.recurrence =
      'FREQ=' +
      this.freq +
      ';INTERVAL=' +
      this.interval +
      ';BYDAY=' +
      this.weekdays
        .filter((d) => e.detail.value.includes(d.id))
        .map((d) => d.id)
        .join(',') +
      (this.endDate === '' ? '' : ';UNTIL=' + LocalDate.fromString(this.endDate).toStringForRecurrenceRule());
    this.fireValueChanged();
  }

  protected onRecurrenceTypeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.recurrence = this.getRecurrenceRule(this.interval, e.detail.value, this.endDate);
    this.fireValueChanged();
  }

  protected onEndDateChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.recurrence = this.getRecurrenceRule(this.interval, this.recurrenceType, e.detail.value);
    this.fireValueChanged();
  }

  protected fireValueChanged() {
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.recurrence },
      }),
    );
  }
}
