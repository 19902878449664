/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface InvoiceProduct
 */
export interface InvoiceProduct {
  /**
   *
   * @type {string}
   * @memberof InvoiceProduct
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceProduct
   */
  amount?: number;
}

/**
 * Check if a given object implements the InvoiceProduct interface.
 */
export function instanceOfInvoiceProduct(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function InvoiceProductFromJSON(json: any): InvoiceProduct {
  return InvoiceProductFromJSONTyped(json, false);
}

export function InvoiceProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceProduct {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    description: !exists(json, 'description') ? undefined : json['description'],
    amount: !exists(json, 'amount') ? undefined : json['amount'],
  };
}

export function InvoiceProductToJSON(value?: InvoiceProduct | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    amount: value.amount,
  };
}
