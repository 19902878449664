import { css, html } from 'lit';

import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/elements/d-label.js';
import { customElement, property } from 'lit/decorators.js';
import { ResponsiveTable } from '../../library/abstracts/responsive-table.js';
import { produce } from 'immer';

export type FunctionAssignment = {
  uuid: string;
  label: string;
  value: string;
  options: { value: string; text: string }[];
};
/**
 *
 * STATUS OK
 */
@customElement('d-function-reassignator-table')
export class DFunctionReassignatorTable extends ResponsiveTable {
  static readonly styles = [...ResponsiveTable.styles, css``];
  @property({ type: Array })
  functions: FunctionAssignment[] = [];

  renderTableContent() {
    return html`
      ${this.functions.map((f) => {
        return html`
          <tr>
            <td>
              <d-label .label=${f.label}></d-label>
            </td>
            <td>
              <d-label .label=${f.label}></d-label>
              <d-select-dropdown
                .options=${f.options}
                @value-changed=${(e: CustomEvent<{ value: string }>) => this.onValueChanged(e, f)}
              ></d-select-dropdown>
            </td>
          </tr>
        `;
      })}
    `;
  }

  private onValueChanged(e: CustomEvent<{ value: string }>, f: FunctionAssignment) {
    this.functions = produce(this.functions, (draft) => {
      const fx = draft.find((r) => r.uuid === f.uuid);
      if (fx !== undefined) {
        fx.value = e.detail.value;
      }
    });
    this.dispatchEvent(
      new CustomEvent<FunctionAssignment[]>('assignment-changed', {
        composed: true,
        bubbles: true,
        detail: this.functions,
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-function-reassignator-table': DFunctionReassignatorTable;
  }
}
