import { capitalize, sortBy } from 'lodash';
import { FunctionViewModel, MeetingOccurrenceViewModelReminderEnum } from 'src/store/api';
import { LocalDate } from 'src/utilities/local-date';
import { LocalTime } from 'src/utilities/local-time';
import { convertToMinutes, formatDuration, joinWithAnd } from 'src/utilities/text';
import { listNamesAsText } from 'src/store/selectors/employee-selector-functions';
import { ContactsAsUsers, listContactNamesWithPartnerNames, ShortNameEntry } from 'src/store/selectors';

function compareByLabel(
  a: { label?: string | null } | null | undefined,
  b: { label?: string | null } | null | undefined,
): number {
  const an = (a || {}).label ?? '';
  const bn = (b || {}).label ?? '';
  return an.toLowerCase().localeCompare(bn.toLowerCase());
}

export function sorted<T>(list: Array<T>, sortBy: (a: T, b: T) => number): Array<T> {
  return [...list].sort(sortBy);
}

export function sortedByName<T extends { name?: string | null }>(list: Array<T>): Array<T> {
  return sortBy(list, [(item) => item.name]);
}

export function sortedByLabel<T extends { label?: string | null }>(list: Array<T>): Array<T> {
  return sorted(list, compareByLabel);
}

export function sortedByNumberAndName<T extends { number: string; name: string | null }>(list: Array<T>): Array<T> {
  return sortBy(list, [(item) => item.number, (item) => item.name]);
}

export function reminderText(reminder: MeetingOccurrenceViewModelReminderEnum): string {
  switch (reminder) {
    case 'NONE':
      return '';
    case 'AT_START':
      return 'Ved start';
    case 'ONE_HOUR_BEFORE':
      return 'Én time før';
    case 'TWO_HOURS_BEFORE':
      return 'To timer før';
    case 'THREE_HOURS_BEFORE':
      return 'Tre timer før';
    case 'FIVE_HOURS_BEFORE':
      return 'Fem timer før';
  }
  return '';
}

export function timeDescription(time: string, duration: number = 0, prefix: string = '') {
  if (time && time !== 'NONE') {
    let result = formattedTime(time, prefix);
    if (duration > 0) {
      result += '-' + formatDuration(convertToMinutes(time) + duration).replace(':', '.');
    }
    return result;
  }
  return '';
}

export function dateTimeDescription(date: string | undefined, time: string | undefined, duration: number = 0) {
  if (!date) return 'Snarest';
  const s = LocalDate.fromString(date);
  let result = s.toStringForDisplayWithDayOfWeekAndYear();
  if (time && time !== 'NONE') {
    result += ' ' + timeDescription(time, duration, ' kl. ');
  }
  return capitalize(result);
}

export function formattedTime(time: string, prefix: string): string {
  if (time === '') {
    return '';
  } else {
    return prefix + LocalTime.fromString(time).toStringForDisplay();
  }
}

export function toDateTime(date: string, time: string | undefined | null) {
  return `${date} ${time ?? ''}`.trim();
}

export function functionNameWithAssignedNames(
  functionUuid: string | undefined,
  functions: FunctionViewModel[],
  employees: ShortNameEntry[],
): string {
  const func = functions.find((f) => {
    return f.uuid === functionUuid;
  });
  if (func) {
    let employeeNamesText = '';
    if (func.type !== 'COMMON') {
      let employeeNames = employees
        .filter((name) => {
          return func.employees.includes(name.uuid);
        })
        .map((name) => {
          return name.name;
        });
      if (employeeNames.length === 0) {
        employeeNames = ['ingen'];
      }
      employeeNamesText = ' (' + joinWithAnd(employeeNames) + ')';
    }
    return func.name + employeeNamesText;
  }
  return '';
}

export function eventAssigneesText(
  event: any,
  functions: FunctionViewModel[],
  employees: ShortNameEntry[],
  contacts: ContactsAsUsers[],
): string {
  if (event.functionUuid) {
    return functionNameWithAssignedNames(event.functionUuid, functions, employees);
  }
  if (event.employees?.length) {
    return listNamesAsText(employees, event.employees);
  }
  if (event.contacts?.length) {
    return listContactNamesWithPartnerNames(event.contacts, contacts);
  }
  return '';
}

export function meetingParticipantsText(
  meeting: any,
  functions: FunctionViewModel[],
  employees: ShortNameEntry[],
  contactsAsUsers: ContactsAsUsers[],
  includeResponsible: boolean = false,
): string {
  let names: string[] = [];
  let responsibleFunctionEmployeeUuid = '';
  if (meeting.responsibleUuid) {
    const responsibleFunctionEmployee = functions.find((f) => {
      return f.uuid === meeting.responsibleUuid;
    });
    if (responsibleFunctionEmployee) {
      responsibleFunctionEmployeeUuid = responsibleFunctionEmployee.employees[0];
    }
  }
  if (includeResponsible && functions.length && meeting.responsibleUuid) {
    names = [functionNameWithAssignedNames(meeting.responsibleUuid, functions, employees)];
  }
  if (includeResponsible && meeting.responsibleEmployeeUuid) {
    const responsibleEmployee = employees.find((e) => {
      return e.uuid === meeting.responsibleEmployeeUuid;
    });
    if (responsibleEmployee) {
      names = [responsibleEmployee.name];
    }
  }
  if (meeting.participants?.length) {
    const participantNames = employees
      .filter((e) => {
        return (
          meeting.participants.includes(e.uuid) &&
          e.uuid !== meeting.responsibleEmployeeUuid &&
          e.uuid !== responsibleFunctionEmployeeUuid
        );
      })
      .map((e) => {
        return e.name;
      });
    names = names.concat(participantNames);
  }
  if (meeting.externalParticipants) {
    const contactNamesList = contactsAsUsers
      .filter((c) => {
        return meeting.externalParticipants.includes(c.uuid);
      })
      .map((c) => {
        return c.name + ' (' + c.partnerName + ')';
      });
    names = names.concat(contactNamesList);
  }
  return joinWithAnd(names);
}
