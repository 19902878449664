import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sortBy } from 'lodash';
import { DayOfWeek, LocalDate, mondayThisWeek } from 'src/utilities/local-date.js';
import '../../library/elements/d-wrap';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/elements/d-label.js';
import './d-edit-rotation-week.js';
import type { RotationWeekEmployee } from './d-edit-rotation-week.js';

export interface RotationEmployeeWeek {
  startDate: string;
  employee: string;
  day1: string;
  day2: string;
  day3: string;
  day4: string;
  day5: string;
  day6: string;
  day7: string;
}

/**
 *
 */
@customElement('d-edit-rotation')
export class DEditRotation extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    d-select-dropdown {
      flex: none;
      width: 232px;
    }
    table {
      width: 100%;
    }
    table.rotationEdit th .selectWrapper {
      width: 238px;
    }
    table.rotationEdit th select {
      width: 266px;
    }
    table.rotationEdit td {
      height: 34px;
      border-bottom: 1px solid rgb(239, 239, 239);
    }
    table.rotationEdit td.day,
    table.rotationEdit td.radioCell {
      text-align: center;
    }
    .pageElement table.rotationEdit label {
      top: auto;
      margin: 0;
    }
    table.rotationEdit input[type='radio'] + label span {
      margin: 0;
    }
    td.day {
      width: 34px;
    }
  `;

  @property({ type: Array })
  rotations: RotationEmployeeWeek[] = [];
  @property({ type: String })
  ruleType = 'WEEKLY-1';
  @property({ type: String })
  rotationType = 'R-1';
  @property({ type: Array })
  selectedEmployees: { uuid: string; name: string }[] = [];
  /**
   * Prevent storybook change
   */
  @property({ type: String })
  staticMonday = '';
  private rotationTypes = [
    { value: 'R-0', text: 'Ikke definert' },
    {
      value: 'R-1',
      text: 'Én ukes syklus',
    },
    { value: 'R-2', text: 'To ukers syklus' },
    {
      value: 'R-3',
      text: 'Tre ukers syklus',
    },
    { value: 'R-4', text: 'Fire ukers syklus' },
    {
      value: 'R-5',
      text: 'Fem ukers syklus',
    },
    { value: 'R-6', text: 'Seks ukers syklus' },
    {
      value: 'R-7',
      text: 'Syv ukers syklus',
    },
    { value: 'R-8', text: 'Åtte ukers syklus' },
    {
      value: 'R-9',
      text: 'Ni ukers syklus',
    },
    { value: 'R-10', text: 'Ti ukers syklus' },
    {
      value: 'R-11',
      text: 'Elleve ukers syklus',
    },
    { value: 'R-12', text: 'Tolv ukers syklus' },
  ];

  get weeks(): {
    startDate: string;
    dateDisplay: string;
    weekDisplay: string;
    employees: RotationWeekEmployee[];
  }[] {
    if (!this.rotations || !this.rotationType || !this.selectedEmployees) {
      return [];
    }

    const rotationWeekCount = parseInt(this.rotationType.split('-')[1], 10);
    let currentDate = mondayThisWeek();
    if (this.staticMonday !== '') {
      currentDate = LocalDate.fromString(this.staticMonday);
    }
    const dateDisplay = (m: LocalDate) => {
      const m1 = m;
      const m2 = m.plusDays(6);
      return m1.toStringForDisplayWithDayAndMonth() + ' - ' + m2.toStringForDisplayWithDayAndMonth();
    };
    const sortedByName = function (list) {
      return sortBy(list, [(e) => e.name, (e) => e.uuid]);
    };

    const v: {
      index: number;
      employees: RotationWeekEmployee[];
      weekDisplay: string;
      dateDisplay: string;
      startDate: string;
    }[] = [];

    for (let i = 0; i < rotationWeekCount; i++) {
      const weekDate = currentDate.plusWeeks(i);
      const employeesForWeek: {
        uuid: string;
        name: string;
        rotationStartDate: string;
        i1: boolean;
        i2: boolean;
        i3: boolean;
        i4: boolean;
        i5: boolean;
        i6: boolean;
        i7: boolean;
      }[] = [];
      this.selectedEmployees.forEach((x) => {
        employeesForWeek.push({
          uuid: x.uuid,
          name: x.name,
          rotationStartDate: this.getRotation(x.uuid, rotationWeekCount, weekDate)?.startDate || '',
          i1: this.isAssigned(x.uuid, rotationWeekCount, weekDate, 1),
          i2: this.isAssigned(x.uuid, rotationWeekCount, weekDate, 2),
          i3: this.isAssigned(x.uuid, rotationWeekCount, weekDate, 3),
          i4: this.isAssigned(x.uuid, rotationWeekCount, weekDate, 4),
          i5: this.isAssigned(x.uuid, rotationWeekCount, weekDate, 5),
          i6: this.isAssigned(x.uuid, rotationWeekCount, weekDate, 6),
          i7: this.isAssigned(x.uuid, rotationWeekCount, weekDate, 7),
        });
      });
      v.push({
        startDate: weekDate.toString(),
        index: i,
        employees: sortedByName(employeesForWeek),
        weekDisplay: 'Uke ' + weekDate.toStringForDisplayWeekAndYear(),
        dateDisplay: dateDisplay(weekDate),
      });
    }

    return v;
  }

  getRotation(employeeUuid: string, weekCount: number, weekStartDate: LocalDate) {
    const rotationsForEmployee = this.rotations.filter((r) => r.employee === employeeUuid);
    const s1 = weekStartDate.withPreviousOrSame(DayOfWeek.MONDAY);
    return rotationsForEmployee.find((r) => {
      const s2 = LocalDate.fromString(r.startDate);
      const weekOffset = Math.floor(s2.until(s1) / 7);
      return Math.abs(weekOffset % weekCount) === 0;
    });
  }

  isAssigned(employeeUuid: string, weekCount: number, weekStartDate: LocalDate, dayNumber: number) {
    const date = weekStartDate.plusDays(dayNumber - 1);
    const rotation = this.getRotation(employeeUuid, weekCount, weekStartDate);
    if (rotation) {
      const dayNumber = date.dayOfWeek().valueOf() + 1;
      const key = 'day' + dayNumber;
      return rotation[key] === '1';
    }
    return false;
  }

  render() {
    return html`
      <d-wrap split-reverse>
        <d-select-dropdown
          .options=${this.rotationTypes}
          .value=${this.rotationType}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.rotationType = e.detail.value;
            this.rotations = [];
            this.fireChangedEvent();
          }}
        ></d-select-dropdown>
        <d-label field="functions_rotations"></d-label>
      </d-wrap>
      ${this.weeks.map(
        (week) =>
          html` <d-edit-rotation-week
            .dateDisplay=${week.dateDisplay}
            .employees=${week.employees}
            .weekDisplay=${week.weekDisplay}
            @rotation-week-changed=${(e) => this.onRotationWeekChanged(e, week.startDate)}
          >
          </d-edit-rotation-week>`,
      )}
    `;
  }

  private fireChangedEvent() {
    this.dispatchEvent(
      new CustomEvent<{ rotationType: string; rotations: RotationEmployeeWeek[] }>('rotation-changed', {
        composed: true,
        bubbles: true,
        detail: { rotationType: this.rotationType, rotations: this.rotations },
      }),
    );
  }

  private onRotationWeekChanged(event: CustomEvent<{ employees: RotationWeekEmployee[] }>, weekStart) {
    let rotations: RotationEmployeeWeek[] = [...this.rotations];
    event.detail.employees.forEach((e) => {
      const rotation = rotations.find((r) => {
        return r.startDate === e.rotationStartDate && r.employee === e.uuid;
      });
      if (rotation !== undefined) {
        rotations = rotations.map((r) => {
          if (r.startDate === e.rotationStartDate && r.employee === e.uuid) {
            return {
              startDate: r.startDate,
              employee: r.employee,
              day1: e.i1 ? '1' : '0',
              day2: e.i2 ? '1' : '0',
              day3: e.i3 ? '1' : '0',
              day4: e.i4 ? '1' : '0',
              day5: e.i5 ? '1' : '0',
              day6: e.i6 ? '1' : '0',
              day7: e.i7 ? '1' : '0',
            };
          }
          return r;
        });
      } else {
        rotations.push({
          startDate: weekStart,
          employee: e.uuid,
          day1: e.i1 ? '1' : '0',
          day2: e.i2 ? '1' : '0',
          day3: e.i3 ? '1' : '0',
          day4: e.i4 ? '1' : '0',
          day5: e.i5 ? '1' : '0',
          day6: e.i6 ? '1' : '0',
          day7: e.i7 ? '1' : '0',
        });
      }
    });
    rotations = rotations.filter((r) => {
      return (
        r.day1 === '1' ||
        r.day2 === '1' ||
        r.day3 === '1' ||
        r.day4 === '1' ||
        r.day5 === '1' ||
        r.day6 === '1' ||
        r.day7 === '1'
      );
    });
    this.rotations = rotations;
    this.fireChangedEvent();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-rotation': DEditRotation;
  }
}
