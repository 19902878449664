import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import '../../library/editors/elements/d-select-dropdown.js';
import { professionHasSpeciality, professions, specialitiesForProfession } from 'src/store/selectors';

import '../../library/fields/d-expansion.js';

/**
 *
 */
@customElement('d-select-profession')
export class DSelectProfession extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    d-expansion {
      padding-top: 8px;
    }
  `;
  @property({ type: String })
  speciality = '';
  @property({ type: String })
  value = '';

  _hasSpeciality(profession) {
    return professionHasSpeciality(profession);
  }

  _computeSpecialities(profession) {
    return specialitiesForProfession(profession);
  }

  render() {
    return html`
      <div class="sectionElementInner">
        <d-select-dropdown
          field="employees_profession"
          .options="${professions.map((x) => ({ value: x, text: x }))}"
          placeholder="Velg"
          .value="${this.value}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.value = e.detail.value;
            if (professionHasSpeciality(this.value)) {
              if (specialitiesForProfession(this.value).indexOf(this.speciality) === -1) {
                this.speciality = 'Ingen';
              }
            } else {
              this.speciality = 'Ingen';
            }
            this.dispatchEvent(
              new CustomEvent('value-changed', {
                bubbles: true,
                composed: true,
                detail: { value: this.value, speciality: this.speciality },
              }),
            );
          }}
        >
        </d-select-dropdown>

        <d-expansion ?opened=${this._hasSpeciality(this.value)}>
          <d-select-dropdown
            field="employees_expertise"
            id="speciality"
            .options="${this._computeSpecialities(this.value).map((x) => ({ value: x, text: x }))}"
            .value="${this.speciality}"
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              e.stopPropagation();
              this.speciality = e.detail.value;
              this.dispatchEvent(
                new CustomEvent('value-changed', {
                  bubbles: true,
                  composed: true,
                  detail: { value: this.value, speciality: this.speciality },
                }),
              );
            }}
          >
          </d-select-dropdown>
        </d-expansion>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-select-profession': DSelectProfession;
  }
}
