import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { EventList } from 'src/library/abstracts/event-list.js';
import { startTaskStyles } from 'src/library/start-task-styles.js';
import '../../library/elements/d-action.js';
import '../../library/elements/d-progress-bar.js';
import '../../library/fields/d-expansion.js';
import '../elements/d-label.js';
import './d-list-section.js';

export interface StartTaskListItem {
  label: string;
  secondaryLabel: string;
  rightLabel: string;
  rightLabelClass: string;
  eventCurrentUser: boolean;
  href: string;
  uuid: string;
  eventDone?: boolean;
  accessible: boolean;
}

/**
 *
 * STATUS OK
 */
@customElement('d-list-section-start-tasks')
export class DListSectionStartTasks extends EventList<StartTaskListItem> {
  static readonly styles = [
    startTaskStyles,
    css`
      :host {
        border-radius: 12px;
        padding-bottom: 2px;
      }
      :host([open]) .header {
        padding-top: 6px;
      }
      :host([open]) .label-wrapper {
        background-position-y: 4px;
      }
      :host([open]) d-label {
        font-size: 18px;
      }
    `,
  ];
  @property({ type: Boolean, reflect: true })
  open = false;
  @property({ type: Array })
  items: StartTaskListItem[] = [];
  @property({ type: String, attribute: 'page-name' })
  pageName = '';

  private get totalCount() {
    if (this.showAll) {
      return this.items.length;
    }
    return this.items.filter((item) => {
      return item.eventCurrentUser;
    }).length;
  }

  private get doneCount() {
    if (this.showAll) {
      return this.items.filter((item) => {
        return item.eventDone;
      }).length;
    }
    return this.items.filter((item) => {
      return item.eventDone && item.eventCurrentUser;
    }).length;
  }

  private get progressText() {
    let your = '';
    if (!this.showAll) {
      your = ' dine';
    }
    if (this.doneCount === this.totalCount) {
      return 'Alle' + your + ' etableringsoppgaver er utført';
    }
    return this.doneCount + ' av' + your + ' ' + this.totalCount + ' er utført';
  }

  private get progressTextFull() {
    let your = '';
    if (!this.showAll) {
      your = ' dine';
    }
    let themePageText = '';
    if (this.pageName) {
      themePageText = ' for ' + this.pageName;
    }
    if (this.doneCount === this.totalCount) {
      return 'Alle' + your + ' etableringsoppgaver' + themePageText + ' er utført';
    }
    return (
      this.doneCount + ' av' + your + ' ' + this.totalCount + ' etableringsoppgaver' + themePageText + ' er utført'
    );
  }

  _open() {
    this.open = true;
  }

  _close(e) {
    e.stopPropagation();
    this.open = false;
  }

  _toggleShowAll() {
    console.log('_toggleShowAll');
    this.showAll = !this.showAll;
  }

  render() {
    return html`
      <div @click=${() => this._open()}>
        <div class="header">
          ${
            this.open
              ? html`
                  <div class="top-right">
                    <d-action @click=${() => this._toggleShowAll()}
                      >${this.showAll ? 'Vis bare mine' : 'Vis alles'}</d-action
                    >
                    <d-closer @click=${(e) => this._close(e)}></d-closer>
                  </div>
                `
              : nothing
          }
          </d-expansion>
          <div class="label-wrapper">
            <d-label semibold field="tasks_startTasks"></d-label>
            <div class="header-progress">
              <div class="progress-text">${this.progressText}</div>
              <d-progress-bar .total=${this.totalCount} .partNumber=${this.doneCount}></d-progress-bar>
            </div>
          </div>
        </div>
        <d-expansion ?opened=${this.open}>
          <div class="progress">
            <d-progress-bar .total=${this.totalCount} .partNumber=${this.doneCount}></d-progress-bar>
            <div class="progress-text">${this.progressTextFull}</div>
          </div>
          <d-list-section
            .items=${this._eventsWithFilterProperties(this.items).map((e) => ({
              ...e,
              clickData: e.uuid,
            }))}
            bordered
            no-header
            start-tasks
            @item-clicked=${this.onItemClicked}
          ></d-list-section>
        </d-expansion>
      </div>
    `;
  }

  private onItemClicked(e: CustomEvent<{ clickData: string }>) {
    e.preventDefault();
    this.dispatchEvent(
      new CustomEvent<{ uuid: string }>('start-task-selected', {
        bubbles: true,
        composed: true,
        detail: { uuid: e.detail.clickData },
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section-start-tasks': DListSectionStartTasks;
  }
}
