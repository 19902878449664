import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../library/elements/d-section.js';
import '../../../../library/elements/d-wrap.js';
import '../../../../library/editors/elements/d-checkbox.js';
import '../../../../library/components/d-popup.js';
import '../../../../library/editors/elements/d-edit-text.js';
import '../../../../library/editors/elements/d-select-dropdown.js';
import '../../../../library/editors/components/d-select-dropdown-or-add.js';
import type { InfosecNetwork, InfosecStorageUnit } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { uuid } from 'src/utilities/text.js';
import { responsiveFormStyles } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { ResponsiveContainer } from 'src/library/elements/responsive-container.js';
import { cloneDeep } from 'lodash';
import {
  NewNetworkDialog,
  NewNetworkResult,
} from 'src/pages/computers-page/infosec-procedure/editors/new-network-dialog.js';

export interface ComputerNetworkChangeExisting {
  computerUuid: string;
  networkUuid: string;

  newNetwork: false;
  newComputer: false;
}

export interface ComputerNetworkChangeNewComputer {
  computerUuid: string;
  networkUuid: string;

  newNetwork: false;
  newComputer: true;
  newComputerName: string;
  newComputerType: string;
}
export interface ComputerNetworkChangeNewNetwork {
  computerUuid: string;
  networkUuid: string;
  newComputer: false;
  newNetwork: true;
  newNetworkName: string;
  newNetworkType: string;
  newNetworkConnectionType: string;
}

export type ComputerNetworkChange =
  | ComputerNetworkChangeExisting
  | ComputerNetworkChangeNewNetwork
  | ComputerNetworkChangeNewComputer;

/**
 *
 */
@customElement('d-edit-computers-network')
export class DEditComputersNetwork extends ResponsiveContainer {
  static readonly styles = [
    responsiveFormStyles,
    css`
      :host {
        display: block;
      }
      d-select-dropdown-or-add {
        min-width: 240px;
      }
    `,
  ];

  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  networks: InfosecNetwork[] = [];

  private get computersForNetwork(): InfosecStorageUnit[] {
    return this.computers;
  }

  private get networkOptions(): SelectDropdownOption[] {
    return this.networks.map((item) => {
      let text = item.name;
      if (text === '') {
        text = 'Nettverk uten navn';
      }
      return {
        value: item.uuid,
        text: text,
      };
    });
  }

  /* Force set select box */
  _resetItems(changed) {
    const computers = cloneDeep(this.computers);
    this.computers = [];
    setTimeout(() => {
      this.computers = computers;
      if (changed) {
        this._itemsChanged();
      }
    }, 0);
  }

  _itemsChanged() {
    this.computers = JSON.parse(JSON.stringify(this.computers));
    this.networks = JSON.parse(JSON.stringify(this.networks));
    this.dispatchEvent(
      new CustomEvent('items-changed', {
        bubbles: true,
        composed: true,
        detail: {
          computers: this.computers,
          networks: this.networks,
        },
      }),
    );
  }

  async _selectorChanged(index, value) {
    if (value === 'NEW') {
      const result: NewNetworkResult = await NewNetworkDialog.open({ name: '', type: 'CABLE', connectionType: 'NONE' });
      if (result.action === 'done') {
        const newItem: InfosecNetwork = {
          uuid: uuid(),
          name: result.name,
          type: result.type,
          connectionType: result.connectionType,
        };
        this.networks = [...this.networks, newItem];
        this.computers[index].networkUuid = newItem.uuid;
        this.dispatchEvent(
          new CustomEvent<ComputerNetworkChange>('computer-network-change', {
            composed: true,
            bubbles: true,
            detail: {
              computerUuid: this.computers[index].uuid,
              networkUuid: newItem.uuid,
              newNetwork: true,
              newComputer: false,
              newNetworkName: newItem.name,
              newNetworkConnectionType: newItem.connectionType,
              newNetworkType: newItem.type,
            },
          }),
        );
      }
    } else {
      this.computers[index].networkUuid = value;
      this._itemsChanged();
      this.dispatchEvent(
        new CustomEvent<ComputerNetworkChange>('computer-network-change', {
          composed: true,
          bubbles: true,
          detail: {
            computerUuid: this.computers[index].uuid,
            networkUuid: value,
            newNetwork: false,
            newComputer: false,
          },
        }),
      );
    }
  }

  render() {
    return html`
      <table>
        ${this.computersForNetwork.map((computer, index) => {
          return html`
            <tr>
              <td>
                <d-label .label=${(computer.name || computer.computerType) ?? ''}></d-label>
              </td>
              <td>
                <d-label .label=${(computer.name || computer.computerType) ?? ''}></d-label>
                <d-select-dropdown-or-add
                  theme-page
                  placeholder="Ingen nettverkstilknytning"
                  firstItemText="Registrer nettverk"
                  additionalItemText="Annet nettverk"
                  .options=${this.networkOptions}
                  .value=${computer.networkUuid ?? ''}
                  @value-changed=${(e) => this._selectorChanged(index, e.detail.value)}
                ></d-select-dropdown-or-add>
              </td>
            </tr>
          `;
        })}
      </table>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-computers-network': DEditComputersNetwork;
  }
}
