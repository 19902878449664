import type { State } from '../../store/types.js';
import type {
  OrganizationPageViewModel,
  SingleEmployee,
} from '../../pages/organization-page/organization-page-view-model.js';
import {
  activityCodesAvailable,
  BASE_PATH,
  constitutionalDocumentsNotDeleted,
  currentEmployeeUuid,
  key,
  activityCodesDisplayText,
  sectorOptions,
  employeesNotDeleted,
} from '../../store';
import { toListSectionItemInputs } from 'src/models/pages/overview-page-view.js';
import { LocalDate } from '../../utilities/local-date.js';
import { AbstractPageView } from 'src/pages/abstract-page-view';

export function organizationPageView(
  hrefPrefix: string,
  viewModel: AbstractPageView,
  state: State,
): OrganizationPageViewModel {
  const o = state.organization;

  if (o === undefined) {
    throw new Error('Illegal state (E151), organization not found');
  }

  const nullToUndefined = (v: string | null) => {
    return v === null ? undefined : v;
  };

  const employee = employeesNotDeleted(state)[0];
  const singleEmployee: SingleEmployee = {
    name: employee.name,
    associationType: employee.associationType ?? '',
    profession: employee.profession ?? '',
    href: hrefPrefix + 'employees/' + employee.uuid,
  };

  return {
    ...viewModel,
    type: 'organization-page',
    icon: 'partners',
    href: hrefPrefix,
    availableActivityCodes: activityCodesAvailable(),
    pdfHref: BASE_PATH + '/organizations/' + o.organizationId + '/overview.pdf?key=' + key(state),
    organization: {
      name: o.name,
      sectorDisplayText:
        sectorOptions.find((i) => {
          return i.value === o.sector;
        })?.text ?? '',
      activityCodes: o.activityCodes,
      activityCodesDisplayText: activityCodesDisplayText(activityCodesAvailable(), o.activityCodes),
      url: nullToUndefined(o.url),
      notes: nullToUndefined(o.notes),
      email: nullToUndefined(o.email),
      fax: nullToUndefined(o.fax),
      phone: nullToUndefined(o.phone),
      mailAddress: nullToUndefined(o.mailAddress),
      officeAddress: nullToUndefined(o.officeAddress),
      herNumber: nullToUndefined(o.herNumber),
      organizationNumber: nullToUndefined(o.organizationNumber),
      businessEntityType: o.businessEntityType,
      sector: viewModel.sector,
    },
    singleEmployee,
    constitutionalDocuments: toListSectionItemInputs(
      hrefPrefix + 'constitutionalDocuments/',
      constitutionalDocumentsNotDeleted(state),
      LocalDate.fromString(state.today),
      currentEmployeeUuid(state),
    ),
  };
}
