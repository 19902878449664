/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RestoreCommand
 */
export interface RestoreCommand {
  /**
   *
   * @type {string}
   * @memberof RestoreCommand
   */
  entityType?: string;
  /**
   *
   * @type {string}
   * @memberof RestoreCommand
   */
  entityId?: string;
}

/**
 * Check if a given object implements the RestoreCommand interface.
 */
export function instanceOfRestoreCommand(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function RestoreCommandFromJSON(json: any): RestoreCommand {
  return RestoreCommandFromJSONTyped(json, false);
}

export function RestoreCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestoreCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entityType: !exists(json, 'entityType') ? undefined : json['entityType'],
    entityId: !exists(json, 'entityId') ? undefined : json['entityId'],
  };
}

export function RestoreCommandToJSON(value?: RestoreCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entityType: value.entityType,
    entityId: value.entityId,
  };
}
