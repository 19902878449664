import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-infosec-view-header.js';
import './editors/d-edit-backup.js';
import type { InfosecDataItem, InfosecStorageUnit } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import {
  dataItemLabels,
  InfosecAsset,
  sortSelectedCategories,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';

/**
 *
 */
@customElement('d-infosec-backup')
export class DInfosecBackup extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
        margin-top: -1px;
      }
      d-section:last-of-type {
        border-bottom: 1px solid var(--borderColorOnGray);
      }
      d-label {
        margin-bottom: 6px;
      }
    `,
  ];

  @property({ type: Array })
  dataItems: InfosecDataItem[] = [];
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: String })
  organizationName = '';
  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  remoteServers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  cloudServices: InfosecStorageUnit[] = [];
  @property({ type: Array })
  locations: InfosecStorageUnit[] = [];
  @property({ type: Array })
  assets: InfosecAsset[] = [];

  _dataItemChanged(e, index) {
    e.stopPropagation();
    this.dataItems = this.dataItems.map((dataItem, i) => {
      if (i === index) {
        return e.detail;
      }
      return dataItem;
    });
    this._dispatchDataItemsChanged();
  }

  _dispatchDataItemsChanged() {
    this.dispatchEvent(
      new CustomEvent('data-items-changed', {
        bubbles: true,
        composed: true,
        detail: this.dataItems,
      }),
    );
  }

  render() {
    let info = '<p>Registrer sikkerhetskopiering for alle elementer.</p>';
    if (this.dataItems.length === 0) {
      info += `<p><em>Ingen databehandling er registrert. 
      Gå først til fanen Databehandling og registrer hvilke data som behandles.</em></p>`;
    }
    return html`
      <d-infosec-view-header title="Sikkerhetskopiering" .info=${info}></d-infosec-view-header>
      ${sortSelectedCategories(this.dataItems)
        .filter((dataItem) => dataItem.category !== 'paperDocuments')
        .map((dataItem, index) => {
          const labels = dataItemLabels(
            dataItem,
            this.computers,
            this.cloudServices,
            this.remoteServers,
            this.partners,
            this.assets,
          );
          return html`
            <d-section>
              <div>
                <d-label big allow-wrap label="${labels.primary}" sublabel="${labels.secondary}"></d-label>
                <d-edit-backup
                  .dataItem=${dataItem}
                  .partners=${this.partners}
                  .organizationName=${this.organizationName}
                  @data-item-changed=${(e) => {
                    this._dataItemChanged(e, index);
                  }}
                ></d-edit-backup>
              </div>
            </d-section>
          `;
        })}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-infosec-backup': DInfosecBackup;
  }
}
