import { html } from 'lit';
import '../../library/elements/d-section';
import '../../library/elements/d-smooth-resize';
import '../../library/fields/d-view-html';
import { customElement, property } from 'lit/decorators.js';
import { BaseDialog } from 'src/library/components/BaseDialog';

export interface TemplateDialogInput {
  title: string;
  content: string;
}
export interface TemplateDialogResult {
  action: 'cancel' | 'done';
}

/**
 *
 * STATUS OK
 */
@customElement('d-template-dialog')
export class DTemplateDialog extends BaseDialog<TemplateDialogInput, TemplateDialogResult> {
  @property({ type: String })
  title = '';
  @property({ type: String })
  content = '';
  @property({ type: Number })
  width = 764;

  protected get calculatedHeaderActions() {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Sett inn', action: 'insert' },
    ];
  }

  renderBody() {
    return html`
      <d-section>
        <d-smooth-resize>
          <d-view-html .value=${this.content}></d-view-html>
        </d-smooth-resize>
      </d-section>
    `;
  }

  protected fetchResult(detail: string | undefined): TemplateDialogResult {
    return {
      action: detail === 'insert' ? 'done' : 'cancel',
    };
  }

  protected initializeDialog(input: TemplateDialogInput) {
    this.title = input.title;
    this.content = input.content;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-template-dialog': DTemplateDialog;
  }
}
