/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PersonalDataItemUpdateMessage
 */
export interface PersonalDataItemUpdateMessage {
  [key: string]: object | any;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemUpdateMessage
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  establishedDate?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  storageMedium?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  storageUnit?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  storageLocation?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  backupOperatorType?: PersonalDataItemUpdateMessageBackupOperatorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  backupOperator?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  journalType?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  journalSupplier?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  journalSize?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  notes?: string;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemUpdateMessage
   */
  sharedMedicalRecords?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemUpdateMessage
   */
  sharedMedicalRecordsPartners?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemUpdateMessage
   */
  sharedMedicalRecordsContracts?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemUpdateMessage
   */
  treatments?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  purpose?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  legalBasis?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemUpdateMessage
   */
  consentContracts?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemUpdateMessage
   */
  journalHasElectronicMessaging?: boolean;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  messagingType?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  messagingSupplier?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PersonalDataItemUpdateMessage
   */
  riskAssessments?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PersonalDataItemUpdateMessage
   */
  otherRiskAssessments?: string;
  /**
   *
   * @type {boolean}
   * @memberof PersonalDataItemUpdateMessage
   */
  riskAssessmentConcludesOk?: boolean;
}

/**
 * @export
 */
export const PersonalDataItemUpdateMessageBackupOperatorTypeEnum = {
  Undefined: 'UNDEFINED',
  None: 'NONE',
  Inherit: 'INHERIT',
  Internal: 'internal',
  External: 'external',
  InternalAndExternal: 'internalAndExternal',
} as const;
export type PersonalDataItemUpdateMessageBackupOperatorTypeEnum =
  (typeof PersonalDataItemUpdateMessageBackupOperatorTypeEnum)[keyof typeof PersonalDataItemUpdateMessageBackupOperatorTypeEnum];

/**
 * Check if a given object implements the PersonalDataItemUpdateMessage interface.
 */
export function instanceOfPersonalDataItemUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'isConfirmedEntity' in value;

  return isInstance;
}

export function PersonalDataItemUpdateMessageFromJSON(json: any): PersonalDataItemUpdateMessage {
  return PersonalDataItemUpdateMessageFromJSONTyped(json, false);
}

export function PersonalDataItemUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PersonalDataItemUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...json,
    isConfirmedEntity: json['isConfirmedEntity'],
    name: !exists(json, 'name') ? undefined : json['name'],
    establishedDate: !exists(json, 'establishedDate') ? undefined : json['establishedDate'],
    type: !exists(json, 'type') ? undefined : json['type'],
    storageMedium: !exists(json, 'storageMedium') ? undefined : json['storageMedium'],
    storageUnit: !exists(json, 'storageUnit') ? undefined : json['storageUnit'],
    storageLocation: !exists(json, 'storageLocation') ? undefined : json['storageLocation'],
    backupOperatorType: !exists(json, 'backupOperatorType') ? undefined : json['backupOperatorType'],
    backupOperator: !exists(json, 'backupOperator') ? undefined : json['backupOperator'],
    journalType: !exists(json, 'journalType') ? undefined : json['journalType'],
    journalSupplier: !exists(json, 'journalSupplier') ? undefined : json['journalSupplier'],
    journalSize: !exists(json, 'journalSize') ? undefined : json['journalSize'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    sharedMedicalRecords: !exists(json, 'sharedMedicalRecords') ? undefined : json['sharedMedicalRecords'],
    sharedMedicalRecordsPartners: !exists(json, 'sharedMedicalRecordsPartners')
      ? undefined
      : json['sharedMedicalRecordsPartners'],
    sharedMedicalRecordsContracts: !exists(json, 'sharedMedicalRecordsContracts')
      ? undefined
      : json['sharedMedicalRecordsContracts'],
    treatments: !exists(json, 'treatments') ? undefined : json['treatments'],
    purpose: !exists(json, 'purpose') ? undefined : json['purpose'],
    legalBasis: !exists(json, 'legalBasis') ? undefined : json['legalBasis'],
    consentContracts: !exists(json, 'consentContracts') ? undefined : json['consentContracts'],
    journalHasElectronicMessaging: !exists(json, 'journalHasElectronicMessaging')
      ? undefined
      : json['journalHasElectronicMessaging'],
    messagingType: !exists(json, 'messagingType') ? undefined : json['messagingType'],
    messagingSupplier: !exists(json, 'messagingSupplier') ? undefined : json['messagingSupplier'],
    riskAssessments: !exists(json, 'riskAssessments') ? undefined : json['riskAssessments'],
    otherRiskAssessments: !exists(json, 'otherRiskAssessments') ? undefined : json['otherRiskAssessments'],
    riskAssessmentConcludesOk: !exists(json, 'riskAssessmentConcludesOk')
      ? undefined
      : json['riskAssessmentConcludesOk'],
  };
}

export function PersonalDataItemUpdateMessageToJSON(value?: PersonalDataItemUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...value,
    isConfirmedEntity: value.isConfirmedEntity,
    name: value.name,
    establishedDate: value.establishedDate === undefined ? undefined : value.establishedDate,
    type: value.type,
    storageMedium: value.storageMedium,
    storageUnit: value.storageUnit,
    storageLocation: value.storageLocation,
    backupOperatorType: value.backupOperatorType,
    backupOperator: value.backupOperator,
    journalType: value.journalType,
    journalSupplier: value.journalSupplier,
    journalSize: value.journalSize,
    notes: value.notes,
    sharedMedicalRecords: value.sharedMedicalRecords,
    sharedMedicalRecordsPartners: value.sharedMedicalRecordsPartners,
    sharedMedicalRecordsContracts: value.sharedMedicalRecordsContracts,
    treatments: value.treatments,
    purpose: value.purpose,
    legalBasis: value.legalBasis,
    consentContracts: value.consentContracts,
    journalHasElectronicMessaging: value.journalHasElectronicMessaging,
    messagingType: value.messagingType,
    messagingSupplier: value.messagingSupplier,
    riskAssessments: value.riskAssessments,
    otherRiskAssessments: value.otherRiskAssessments,
    riskAssessmentConcludesOk: value.riskAssessmentConcludesOk,
  };
}
