import { css, html, LitElement, nothing } from 'lit';
import '../../library/elements/d-wrap.js';
import { customElement, property } from 'lit/decorators.js';
import '../../library/fields/d-expansion.js';
import '../../library/editors/components/d-select-time.js';
import { produce } from 'immer';
import type { WorkScheduleWeek } from 'src/store/api';
import { forDayIndex, timeInMinutes } from 'src/store';
import { minutesToTime } from 'src/utilities/text.js';

/**
 *
 */
@customElement('d-edit-work-weeks')
export class DEditWorkWeeks extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }

      .weekdays {
        display: flex;
        margin: 0 0 8px 1px;
      }

      .day {
        flex: 1;
        min-width: 46px;
        max-width: 58px;
        margin-left: -1px;
      }

      .day-name,
      d-select-time {
        border: 1px solid hsl(1, 0%, 80%);
        margin-top: -1px;
        text-align: center;
      }

      .day-name {
        font-size: 12px;
        letter-spacing: 1px;
        padding: 8px 0;
        background-color: hsla(1, 0%, 0%, 0.1);
        color: hsl(1, 0%, 70%);
        cursor: pointer;
      }

      .day-name.workday {
        background-color: white;
        color: hsl(1, 0%, 20%);
      }

      d-select-time {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: baseline;
      }
    `,
  ];

  @property({ type: Array })
  workWeeks!: WorkScheduleWeek[];

  @property({ type: String })
  workHoursType = '';

  _singleWeek() {
    return this.workWeeks.length < 2;
  }

  _computeWeekNumber(weekIndex: number) {
    if (this.workWeeks.length > 1) {
      return weekIndex + 1 + '. uke';
    }
    return ' ';
  }

  _dayName(index: number) {
    return ['MAN', 'TIR', 'ONS', 'TOR', 'FRE', 'LØR', 'SØN'][index];
  }

  _workdayClass(week: WorkScheduleWeek, n: number) {
    if (forDayIndex(week, n).workDay) {
      return 'day-name workday';
    }
    return 'day-name';
  }

  _toggleSelect(e: CustomEvent, dayIndex: number, weekIndex: number) {
    this.workWeeks = produce(this.workWeeks, (draft) => {
      forDayIndex(draft[weekIndex], dayIndex).workDay = !forDayIndex(draft[weekIndex], dayIndex).workDay;
    });
    this.dispatchEvent(
      new CustomEvent('work-weeks-changed', { composed: true, bubbles: true, detail: { workWeeks: this.workWeeks } }),
    );
  }

  _showTime(workHoursType: string, week: WorkScheduleWeek, n: number) {
    return workHoursType !== 'default' && forDayIndex(week, n).workDay;
  }

  onUpdateWeekDayStartTime(weekIndex: number, dayIndex: number, newValue: string) {
    this.workWeeks = produce(this.workWeeks, (draft) => {
      forDayIndex(draft[weekIndex], dayIndex).start = newValue;
    });
    this.dispatchEvent(
      new CustomEvent<{ workWeeks: WorkScheduleWeek[] }>('work-weeks-changed', {
        composed: true,
        bubbles: true,
        detail: { workWeeks: this.workWeeks },
      }),
    );
    const day = forDayIndex(this.workWeeks[weekIndex], dayIndex);
    if (day.start && day.end) {
      const startMinutes = timeInMinutes(day.start);
      const endMinutes = timeInMinutes(day.end);
      if (endMinutes - startMinutes < 5) {
        this.onUpdateWeekDayEndTime(weekIndex, dayIndex, minutesToTime(startMinutes + 5));
      }
    }
  }

  onUpdateWeekDayEndTime(weekIndex: number, dayIndex: number, newValue: string) {
    this.workWeeks = produce(this.workWeeks, (draft) => {
      forDayIndex(draft[weekIndex], dayIndex).end = newValue;
    });
    this.dispatchEvent(
      new CustomEvent<{ workWeeks: WorkScheduleWeek[] }>('work-weeks-changed', {
        composed: true,
        bubbles: true,
        detail: { workWeeks: this.workWeeks },
      }),
    );
    const day = forDayIndex(this.workWeeks[weekIndex], dayIndex);
    if (day.start && day.end) {
      const startMinutes = timeInMinutes(day.start);
      const endMinutes = timeInMinutes(day.end);
      if (endMinutes - startMinutes < 5) {
        this.onUpdateWeekDayStartTime(weekIndex, dayIndex, minutesToTime(endMinutes - 5));
      }
    }
  }

  render() {
    return html`
      ${this.workWeeks.map(
        (week, weekIndex) => html`
          ${this._singleWeek() ? nothing : html` <d-label label="${this._computeWeekNumber(weekIndex)}"></d-label>`}
          <div class="weekdays">
            ${this.renderDay(week, weekIndex, week.monday, 0)} ${this.renderDay(week, weekIndex, week.tuesday, 1)}
            ${this.renderDay(week, weekIndex, week.wednesday, 2)} ${this.renderDay(week, weekIndex, week.thursday, 3)}
            ${this.renderDay(week, weekIndex, week.friday, 4)} ${this.renderDay(week, weekIndex, week.saturday, 5)}
            ${this.renderDay(week, weekIndex, week.sunday, 6)}
          </div>
        `,
      )}
    `;
  }

  private renderDay(week, weekIndex, day, dayIndex) {
    return html`
      <div class="day">
        <div
          class="${this._workdayClass(week, dayIndex)}"
          @click=${(e: CustomEvent) => this._toggleSelect(e, dayIndex, weekIndex)}
        >
          ${this._dayName(dayIndex)}
        </div>
        <d-expansion ?opened=${this._showTime(this.workHoursType, week, dayIndex)}>
          <d-select-time
            small
            max="23:50"
            .value=${day.start}
            @value-changed=${(e: CustomEvent) => this.onUpdateWeekDayStartTime(weekIndex, dayIndex, e.detail.value)}
          ></d-select-time>
          <d-select-time
            small
            min="00:05"
            .value=${day.end}
            @value-changed=${(e: CustomEvent) => this.onUpdateWeekDayEndTime(weekIndex, dayIndex, e.detail.value)}
          ></d-select-time>
        </d-expansion>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-work-weeks': DEditWorkWeeks;
  }
}
