import { customElement, property } from 'lit/decorators.js';
import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';
import { calendariumDays, calendariumMonths, calendariumWeeks } from '../../store/selectors';
import type { ZoomUnit } from './d-staffing-calendar-data.js';
import { zoomResolution } from 'src/pages/staffing-page/d-staffing-calendar-table.js';

/**
 *
 * The `d-staffing-calendar-header` component displays a years calendar in the form of a header.
 *
 * Migration: calendarium Months, weeks and days are not selectors. Depending on performance they may need to be reimplemented as selectors.
 */
@customElement('d-staffing-calendar-header')
export class DStaffingCalendarHeader extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .unitRow {
      display: flex;
      margin-left: -1px;
      margin-top: -1px;
      height: 31px;
      font-size: 13px;
    }

    .monthRow {
      height: 58px;
      margin-bottom: -30px;
      font-size: 15px;
    }

    .weekRow {
      height: 28px;
      margin-bottom: -2px;
    }

    .unitSpace {
      display: flex;
    }

    .weekSpace {
      margin-bottom: -2px;
    }

    .floatLabel {
      position: -webkit-sticky;
      position: sticky;
      border-left: 1px solid hsl(1, 0%, 60%);
      background: var(--backgroundGray);
      padding: 4px 10px 0 10px;
    }

    .dayRow {
      height: 60px;
    }

    .daySpace {
      display: flex;
      flex-direction: column;
    }

    .date {
      position: relative;
      height: 30px;
      border-left: 1px solid hsl(1, 0%, 75%);
      padding-top: 2px;
      font-size: 13px;
      text-align: center;
    }

    .date .hoverDayInfo {
      position: absolute;
      flex-direction: column-reverse;
      bottom: 50px;
      left: -135px;
      width: 300px;
      height: 200px;
      white-space: normal;
      z-index: 100;
      display: none;
    }

    .date .hoverDayInfo:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 30px solid white;
      position: absolute;
      bottom: -30px;
      left: 135px;
    }

    .date .hoverDayInfo div {
      flex: none;
      background: white;
      text-align: left;
      border-radius: 12px;
      box-shadow: 0 5px 10px hsla(0, 0%, 0%, 0.3);
      padding: 16px 20px;
      line-height: 140%;
      max-height: 100%;
      overflow: hidden;
    }
    .date.holiday {
      color: red;
      cursor: pointer;
    }

    .date.today {
      color: white;
      background: var(--themeColor);
    }

    .date .holidayInfo {
      position: absolute;
      top: 0;
      left: 0;
      padding: 4px 12px 0px 12px;
      height: 29px;
      box-sizing: border-box;
      z-index: 1;
      background: red;
      color: white;
      white-space: nowrap;
      display: none;
    }

    .date.holiday:hover .holidayInfo {
      display: block;
    }

    .weekday {
      height: 30px;
      border-left: 1px solid hsl(1, 0%, 75%);
      text-transform: uppercase;
      font-size: 11px;
      padding-top: 8px;
      text-align: center;
    }

    .unitStart .date,
    .unitStart .weekday,
    .unitStart .hours {
      border-left: 1px solid hsl(1, 0%, 60%);
    }

    :host([zoom='hour']) .date {
      text-align: left;
      padding-top: 8px;
      padding-left: 10px;
    }

    .hours {
      height: 24px;
      border-left: 1px solid hsl(0, 0%, 75%);
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .hours > div {
      flex: none;
      width: 24px;
      font-size: 10px;
      font-weight: 200;
      text-align: center;
    }
  `;
  @property({ type: String })
  year = '2022';
  @property({ type: Number })
  startOfDayHours = 8;
  @property({ type: Number })
  endOfDayHours = 16;
  @property({ type: String, attribute: 'zoom', reflect: true })
  zoom: ZoomUnit = 'day';
  @property({ type: Number })
  stickyLeft = 0;

  private get pixelsPerHour() {
    return zoomResolution[this.zoom];
  }

  private get hoursPerDay() {
    return this.endOfDayHours - this.startOfDayHours;
  }

  private get pixelsPerDay() {
    return this.pixelsPerHour * this.hoursPerDay;
  }

  private get calendariumMonths(): { count: number; name: string }[] {
    return calendariumMonths(this.year);
  }

  private get calendariumWeeks(): { count: number; name: string }[] {
    return calendariumWeeks(this.year);
  }

  private get calendariumDays(): {
    date: string;
    displayDate: number;
    displayDateFull: string;
    holiday: string;
    unitStart: boolean;
    weekday: string;
  }[] {
    return calendariumDays(this.year);
  }

  _widthStyle(count, cellWidth) {
    return 'width: ' + count * cellWidth + 'px';
  }

  private get floatLabelStyle() {
    return 'left: ' + (this.stickyLeft - 2) + 'px';
  }

  _daySpaceClasses(unitStart) {
    if (unitStart) {
      return 'unitSpace daySpace unitStart';
    }
    return 'unitSpace daySpace';
  }

  _dateClasses(item) {
    if (item.holiday) {
      return 'date holiday';
    }
    if (item.today) {
      return 'date today';
    }
    return 'date';
  }

  private get displayHours() {
    const elmsCount = (this.hoursPerDay - 2) / 2;
    const n = Number(this.startOfDayHours) + 2;
    const displayHours: string[] = [];
    for (let i = 0; i < elmsCount; i++) {
      displayHours.push(('00' + (n + i * 2)).slice(-2));
    }
    return displayHours;
  }

  render() {
    const count = this.calendariumDays.length;
    return html` <div class="unitRow monthRow" style="${this._widthStyle(count, this.pixelsPerDay)}">
        ${this.calendariumMonths.map(
          (item) =>
            html` <div class="unitSpace monthSpace" style="${this._widthStyle(item.count, this.pixelsPerDay)}">
              <div class="floatLabel" style="${this.floatLabelStyle}">${item.name}</div>
            </div>`,
        )}
      </div>
      <div class="unitRow weekRow" style="${this._widthStyle(count, this.pixelsPerDay)}">
        ${this.calendariumWeeks.map(
          (item) =>
            html`<div class="unitSpace weekSpace" style="${this._widthStyle(item.count, this.pixelsPerDay)}">
              <div class="floatLabel" style="${this.floatLabelStyle}">${item.name}</div>
            </div>`,
        )}
      </div>
      <div class="unitRow dayRow" style="${this._widthStyle(count, this.pixelsPerDay)}">
        ${this.calendariumDays.map(
          (item) =>
            html` <div
              class="${this._daySpaceClasses(item.unitStart)}"
              style="${this._widthStyle(1, this.pixelsPerDay)}"
            >
              ${this.zoom === 'day' ? html` <div class="weekday">${item.weekday}</div> ` : nothing}
              <div class="${this._dateClasses(item)}">
                ${this.zoom === 'day' ? item.displayDate : item.displayDateFull}
                <div class="holidayInfo">${item.holiday}</div>
              </div>
              ${this.zoom === 'hour'
                ? html`
                    <div class="hours">
                      ${this.displayHours.map((hour) => {
                        return html`<div>${hour}</div>`;
                      })}
                    </div>
                  `
                : nothing}
            </div>`,
        )}
      </div>`;
  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (this) {
      this.dispatchEvent(
        new CustomEvent('width-changed', { detail: { width: this.offsetWidth, widthPerDay: this.pixelsPerDay } }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-staffing-calendar-header': DStaffingCalendarHeader;
  }
}
