import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-edit-network-computers.js';
import type { InfosecNetwork, InfosecStorageUnit } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { ResponsiveContainer } from 'src/library/elements/responsive-container.js';

/**
 * Lists each network with connected computers, including servers.
 *
 */
@customElement('d-edit-networks-computers')
export class DEditNetworksComputers extends ResponsiveContainer {
  static readonly styles = [
    css`
      :host {
        display: block;
      }
      table {
        position: relative;
        width: 100%;
        border-collapse: collapse;
        border-bottom: 1px solid var(--borderColorOnGray);
      }
      td {
        width: auto;
        vertical-align: top;
        border-top: 1px solid var(--borderColorOnGray);
        padding: 12px 0 8px 0;
      }
      td:first-child {
        text-align: left;
        padding-right: 20px;
        display: none;
      }
      :host(.width600) td:first-child {
        display: table-cell;
      }
      td:last-child {
        padding-bottom: 12px;
      }
      d-label {
        margin-bottom: 8px;
      }
      td:first-child d-label {
        padding-top: 4px;
      }
      td:last-child d-label {
        padding-top: 0;
      }
      :host(.width600) td:last-child d-label {
        display: none;
      }
      td > * {
        display: block;
      }
    `,
  ];

  /**
   * All computers. The child elements receive the complete list of computers because servers must be named if
   * they are added when there already exists servers. Also because computers may only be connected to a single metwork.
   */
  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];

  /**
   * The networks.
   */
  @property({ type: Array })
  networks: InfosecNetwork[] = [];

  render() {
    return html`
      <table>
        ${this.networks.map((network) => {
          return html`
            <tr>
              <td>
                <d-label .label=${network.name}></d-label>
              </td>
              <td>
                <d-label .label=${network.name}></d-label>
                <div>
                  <d-edit-network-computers
                    .computers=${this.computers}
                    .network=${network}
                    @items-changed=${(e) => (this.computers = e.detail)}
                  ></d-edit-network-computers>
                </div>
              </td>
            </tr>
          `;
        })}
      </table>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-networks-computers': DEditNetworksComputers;
  }
}
