/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TutorialState
 */
export interface TutorialState {
  /**
   *
   * @type {Array<string>}
   * @memberof TutorialState
   */
  partStates: Array<string>;
  /**
   *
   * @type {number}
   * @memberof TutorialState
   */
  currentPart: number;
  /**
   *
   * @type {number}
   * @memberof TutorialState
   */
  id?: number;
}

/**
 * Check if a given object implements the TutorialState interface.
 */
export function instanceOfTutorialState(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'partStates' in value;
  isInstance = isInstance && 'currentPart' in value;

  return isInstance;
}

export function TutorialStateFromJSON(json: any): TutorialState {
  return TutorialStateFromJSONTyped(json, false);
}

export function TutorialStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialState {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    partStates: json['partStates'],
    currentPart: json['currentPart'],
    id: !exists(json, 'id') ? undefined : json['id'],
  };
}

export function TutorialStateToJSON(value?: TutorialState | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    partStates: value.partStates,
    currentPart: value.currentPart,
    id: value.id,
  };
}
