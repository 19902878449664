import 'src/content/entity-content';
import '../../library/fields/index.js';
import '../../library/editors/index.js';
import '../../library/lists/d-list-section-attachment.js';
import { customElement } from 'lit/decorators.js';
import { DataMapElement, DataMapItem } from './data-map-element.js';

export interface NationalHealthNetworkItem extends DataMapItem {
  unitType: 'nhn';
}

/**
 *
 */
@customElement('d-nhn-view')
export class DNhnView extends DataMapElement<NationalHealthNetworkItem, Record<string, never>> {
  _displayTypeName(): string {
    return 'Norsk helsenett';
  }

  _displayName() {
    return '';
  }

  _isValid() {
    return true;
  }

  initializeEditItem(): Record<string, never> {
    return {};
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-nhn-view': DNhnView;
  }
}
