import { css, html, LitElement, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { leavePeriodStyles } from 'src/library/leave-period-styles.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import '../elements/d-action.js';
import '../elements/d-label.js';
import '../fields/d-expansion.js';
import './d-list-section.js';
import './d-list-section-event-asset-item.js';
import { shortenPath } from 'src/utilities/path';

/**
 *
 * STATUS OK
 */
@customElement('d-list-section-item')
export class DListSectionItem extends LitElement {
  static readonly styles = [
    leavePeriodStyles,
    css`
      :host {
        display: block;
        border-color: var(--borderColor);
        container-type: inline-size;
      }

      .item.parent-item,
      .item.bold-label {
        font-weight: 500;
      }

      .item.parent-item {
        position: -webkit-sticky;
        position: sticky;
        /* top: 38px; TODO */
        background-color: white;
        font-size: 16px;
        z-index: 1;
      }

      :host([bordered]) .item.parent-item {
        top: 42px;
      }

      :host([theme-page]) .item.parent-item {
        background-color: var(--backgroundGray);
      }

      :host([bordered]) .item {
        border-top: 1px solid var(--borderColor);
      }

      :host([bordered][theme-page]) .item {
        border-top: 1px solid var(--borderColorOnGray);
      }

      :host([bordered][start-tasks]) .item {
        border-top: 1px solid var(--startTaskBorderColor);
      }

      .wrapper {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
        position: relative;
      }

      .split {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
      }

      .draft {
        flex: none;
        display: inline-block;
        position: relative;
        top: 5px;
        left: -1px;
        width: 18px;
        height: 22px;
        margin-top: -6px;
        margin-right: 5px;
        background: url(/images/draft.svg) -5px -2px no-repeat;
        background-size: 24px 24px;
      }

      .name {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
      }

      .primary {
        margin-right: 6px;
      }

      .secondary {
        font-size: 14px;
        font-weight: 200;
      }

      .secondary-label-items {
        display: block;
        padding-left: 20px;
      }

      .secondary-label-items span {
        display: block;
        font-size: 14px;
        font-weight: 200;
      }

      .notes {
        font-size: 14px;
        font-weight: 200;
      }

      .right {
        flex-grow: 1;
        margin-left: 10px;
        text-align: right;
      }

      a {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        margin-left: calc(var(--listPaddingLeft) * -1);
        padding: 5px 0 5px var(--listPaddingLeft);
        color: var(--linkColorGray);
        line-height: var(--lineHeightDefault);
        text-decoration: none;
        cursor: pointer;
      }

      :host([bordered]) a {
        padding-top: 10px;
        padding-bottom: 9px;
      }

      a span {
        pointer-events: none;
      }

      d-action {
        margin: -6px 0 -6px 6px;
        pointer-events: all;
      }

      d-label {
        pointer-events: all;
      }

      a:hover,
      a.overdue:hover .name {
        color: black;
      }

      a.unaccessible,
      a.unaccessible:hover {
        color: hsl(0, 0%, 20%);
        cursor: default;
        pointer-events: none;
      }

      :host([bordered]) .locked,
      :host([bordered]) .done,
      :host([bordered]) .ok,
      :host([bordered]) .not-ok,
      :host([bordered]) .overdue {
        background-position-y: 8px;
      }

      .template-deleted .primary:before,
      .template-updated .primary:before,
      .document-updated .primary:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;
      }

      .template-deleted .primary:before {
        background-color: var(--alertColor);
      }

      .template-updated .primary:before {
        background-color: var(--themeColor);
      }

      .document-updated .primary:before {
        background-color: hsl(100, 80%, 40%);
      }

      a.leave-period .split:before {
        content: '';
        display: inline-block;
        position: absolute;
        flex: none;
        left: -30px;
        top: 0;
        width: 18px;
        height: 18px;
      }

      a.timekeeping-correction {
        font-weight: 500;
      }

      a.suggestion .name .primary:before,
      a.risk .name .primary:before {
        position: relative;
        top: -1px;
        width: fit-content;
        margin-right: 6px;
        border-radius: 3px;
        padding: 4px 5px 4px 7px;
        color: white;
        font-family: var(--small), sans-serif;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 120%;
        font-weight: 500;
        letter-spacing: 2px;
      }

      a.suggestion .name .primary:before {
        content: 'Forslag';
        background: hsl(0, 0%, 75%);
      }

      a.risk1 .name .primary:before {
        content: 'Lav';
        background: hsl(106, 87%, 43%);
      }

      a.risk2 .name .primary:before {
        content: 'Moderat';
        background: hsl(62, 57%, 39%);
      }

      a.risk3 .name .primary:before {
        content: 'Høy';
        background: hsl(31, 93%, 51%);
      }

      a.risk4 .name .primary:before {
        content: 'Svært høy';
        background: hsl(0, 80%, 56%);
      }

      a span {
        pointer-events: none;
      }

      .list-content a {
        display: block;
        padding: 6px 0;
        color: var(--linkColorGray);
        text-decoration: none;
      }

      .list-content a:hover {
        color: black;
      }

      .no-item {
        color: hsl(0, 0%, 20%);
        cursor: default;
        font-style: italic;
      }

      .add-header {
        padding-left: var(--listPaddingLeft);
      }

      .add-header d-action {
        margin-left: -8px;
      }

      .explanation {
        font-size: 13px;
        font-weight: 300;
      }

      .comment {
        width: 100%;
        font-family: var(--mainSerif), serif;
        line-height: 150%;
        font-weight: 200;
        font-style: italic;
      }

      .secondary-info {
        font-weight: 300;
      }

      .secondary-info.notes {
        font-size: 14px;
      }

      .secondary-info.done-text {
        font-size: 14px;
      }

      .assignee {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .locked,
      .done,
      .ok,
      .not-ok,
      .info,
      .overdue,
      .rotated .primary:after {
        background-repeat: no-repeat;
        background-position: 1px 3px;
        background-size: 24px auto;
      }

      .meeting {
        background: url(/images/meetings-gray.svg) -2px 5px no-repeat;
        background-size: 30px;
      }

      .locked {
        background-image: url(/images/locked.svg);
      }

      .done {
        background-image: url(/images/check-mini-gray.svg);
      }

      .ok {
        background-image: url(/images/check-mini-blue.svg);
      }

      .not-ok {
        background-image: url(/images/x-fat-orange.svg);
      }

      .info {
        background-image: url(/images/info-fill.svg);
      }

      .overdue {
        background-image: url(/images/alert.svg);
      }

      .overdue .name {
        color: var(--alertColor);
      }

      .overdue .name {
        color: var(--alertColor);
      }

      .rotated .primary:after {
        content: '';
        display: inline-block;
        width: 26px;
        height: 26px;
        margin-top: -7px;
        margin-bottom: -7px;
        margin-right: -4px;
        background-image: url(/images/rotate.svg);
      }

      .in-list-action {
        margin-left: var(--listPaddingLeft);
        padding: 5px 0;
      }

      .list-content .in-list-action {
        margin-left: 0;
      }

      :host([bordered]) .in-list-action {
        border-top: 1px solid var(--borderColor);
        padding-top: 9px;
        padding-bottom: 9px;
      }

      :host([bordered][theme-page]) .in-list-action {
        border-color: var(--borderColorOnGray);
      }

      d-action.right-action {
        margin-right: -8px;
      }

      @container (max-width: 600px) {
        .locked,
        .done,
        .ok,
        .not-ok,
        .info,
        .overdue {
          margin-left: 0;
          background-position-x: -4px;
          padding-left: 24px;
        }

        .meeting {
          margin-left: 0;
          background: none;
          padding-left: 0;
        }

        .template-deleted .primary:before,
        .template-updated .primary:before,
        .document-updated .primary:before {
          margin-right: 6px;
        }

        a.leave-period {
          margin-left: 0;
        }
      }
    `,
  ];
  @property({ type: Object })
  input?: ListSectionItemInput;
  @property({ type: Boolean, reflect: true, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean, reflect: true, attribute: 'bordered' })
  bordered = false;
  @property({ type: Boolean })
  hideSublist = false;
  @property({ type: Boolean })
  showNotes = false;
  @property({ type: Number })
  itemStickyTop = 0;

  _computeTarget(target) {
    if (target) {
      return target;
    }
    return '';
  }

  _computeClass(i: ListSectionItemInput) {
    let classes = i.classes ?? '';
    if (!i.accessible) {
      classes += ' unaccessible';
    }
    if (i.locked) {
      classes += ' locked';
    }
    if (i.meeting) {
      classes += ' meeting';
    }
    if (i.updateStatus) {
      if (i.updateStatus === 'suggestion') {
        classes += ' suggestion';
      }
      if (i.updateStatus === 'templateDeleted') {
        classes += ' template-deleted';
      }
      if (i.updateStatus === 'templateUpdated') {
        classes += ' template-updated';
      }
      if (i.updateStatus === 'documentUpdated') {
        classes += ' document-updated';
      }
    }
    if (i.functionRotated) {
      classes += ' rotated';
    }
    if (i.eventOverdue) {
      classes += ' overdue';
    }
    if (i.eventDone) {
      classes += ' done';
    }
    if (i.assetCheckStatus === 'OK') {
      classes += ' ok';
    }
    if (i.assetCheckStatus === 'NOT_OK') {
      classes += ' not-ok';
    }
    if (i.riskAssessmentRisk) {
      classes += ' risk risk' + i.riskAssessmentRisk;
    }
    if (i.leavePeriodType) {
      classes += ' leave-period ' + i.leavePeriodType;
    }
    if (i.timekeepingCorrection) {
      classes += ' timekeeping-correction';
    }
    return classes;
  }

  _itemClasses(i: ListSectionItemInput) {
    if (i.items) {
      return 'item parent-item';
    }
    if (i.labelItems) {
      return 'item bold-label';
    }
    return 'item';
  }

  _toggleSublist() {
    console.log('_toggleSublist', this.hideSublist);
    this.hideSublist = !this.hideSublist;
  }

  _clickItem(e, input: ListSectionItemInput | undefined) {
    if (input !== undefined) {
      if (!input.accessible) {
        e.preventDefault();
      } else if (input.clickHandler) {
        e.preventDefault();
        input.clickHandler();
      } else if (input.clickData) {
        e.preventDefault();
        this.dispatchEvent(
          new CustomEvent('item-clicked', {
            bubbles: true,
            composed: true,
            detail: input,
          }),
        );
      }
    }
  }

  firstUpdated() {
    if (this.input && this.input.sublistHidden) {
      this.hideSublist = true;
    }
  }

  renderSublist() {
    if (this.input === undefined || this.input.items === undefined) {
      return nothing;
    }
    if (this.input.items.length === 0 && this.input.noItemText) {
      const noItem = {
        label: this.input.noItemText,
        accessible: false,
      };
      return html`
        <div class="list-content">
          <d-list-section-item
            class="no-item"
            .input=${noItem}
            ?theme-page=${this.themePage}
            ?bordered=${this.bordered}
          ></d-list-section-item>
        </div>
      `;
    }
    return html`
      <d-expansion ?opened=${!this.hideSublist}>
        <d-list-section
          sublist
          no-header
          ?bordered=${this.bordered}
          ?theme-page=${this.themePage}
          .items=${this.input.items}
          .limitItems=${this.input.limitItems}
        ></d-list-section>
      </d-expansion>
    `;
  }

  renderSublistHeader() {
    if (!this.input) {
      return nothing;
    }
    return html`
      <d-label .label=${this.input.label} .field=${this.input.field}></d-label>${this.input.sublistToggle
        ? html` <d-action mini ?theme-page=${this.themePage} @click=${() => this._toggleSublist()}
            >${this.hideSublist ? 'Vis' : 'Skjul'}
          </d-action>`
        : nothing}
    `;
  }

  renderItemName() {
    if (!this.input) {
      return nothing;
    }
    return html`
      <span class="primary">${this.input.label}</span>
      <span class="secondary">${this.input.secondaryLabel}</span>
    `;
  }

  render() {
    const i = this.input;
    if (i === undefined) {
      return nothing;
    }
    let parentItemStyles = {};
    if (i.items) {
      parentItemStyles = { top: this.itemStickyTop + 'px' };
    }
    const draftIcon = i.hasDraft ? html`<span class="draft"></span>` : nothing;
    const conditionalRightLabel =
      i.secondaryClickHandler === undefined
        ? html` <span class="right ${i.rightLabelClass}">${i.rightLabel}</span>`
        : nothing;
    const labelItems = i.labelItems?.length
      ? html`
          <span class="secondary-label-items">
            ${i.labelItems.map((labelItem) => html` <span>${labelItem}</span> `)}
          </span>
        `
      : nothing;
    const itemName = i.items && !i.accessible ? html`${this.renderSublistHeader()}` : html`${this.renderItemName()}`;
    const assetOrItem = i.assetCheck
      ? this.renderAssetItem(i)
      : this.renderItem(i, draftIcon, itemName, labelItems, conditionalRightLabel);
    return html`
      <d-expansion ?opened=${!i.hidden}>
        <div class="${this._itemClasses(i)}" style=${styleMap(parentItemStyles)}>${assetOrItem}</div>
        ${this.renderSublist()}
      </d-expansion>
    `;
  }

  private renderAssetItem(i: ListSectionItemInput) {
    return html` <d-list-section-event-asset-item
      ?writeAccess=${i.writeAccess}
      .input=${i}
    ></d-list-section-event-asset-item>`;
  }

  private renderItem(
    i: ListSectionItemInput,
    draftIcon: TemplateResult<1> | typeof nothing,
    itemName: TemplateResult<1>,
    labelItems: TemplateResult<1> | typeof nothing,
    conditionalRightLabel: TemplateResult<1> | typeof nothing,
  ) {
    return html`<span class="wrapper">
      <a
        class="${this._computeClass(i)}"
        href="${ifDefined(i.accessible ? shortenPath(i.href) : undefined)}"
        target="${this._computeTarget(i.target)}"
        action-data="${ifDefined(i.clickData)}"
        @click=${(e) => this._clickItem(e, this.input)}
      >
        <span class="split">
          <span class="left">
            <span class="name"> ${draftIcon} ${itemName} </span>
            ${labelItems}
            <d-expansion ?opened=${this.showNotes}>
              <span class="notes">${i.notes}</span>
            </d-expansion>
          </span>
          ${conditionalRightLabel}
        </span>
        <slot></slot>
      </a>
      ${this.renderRightAction(i)}
    </span>`;
  }

  private renderRightAction(i: ListSectionItemInput) {
    const handler = i.secondaryClickHandler;
    if (handler !== undefined) {
      return html`<d-action class="right-action" @click=${() => handler()}>${i.rightLabel}</d-action>`;
    } else {
      return nothing;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section-item': DListSectionItem;
  }
}
