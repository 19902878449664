import { css } from 'lit';

import { internalLinkStyles } from './internal-link-styles';

export const htmlContentStyles = [
  css`
    .html {
      font-family: var(--mainSerif), sans-serif;
      font-weight: 300;
      font-size: 16px;
      line-height: 180%;
    }

    .html h1 {
      margin-top: 24px;
      margin-bottom: 8px;
      font-size: 28px;
      font-weight: 500;
      line-height: 150%;
      word-wrap: break-word;
    }

    .html h2 {
      margin-top: 22px;
      margin-bottom: 8px;
      font-size: 20px;
      font-weight: 500;
      line-height: 150%;
      word-wrap: break-word;
    }

    .html.mainContent h1,
    .html.mainContent h2 {
      font-weight: 500;
    }

    .html h1 + h2,
    .html h2 + h2 {
      margin-top: 14px;
    }

    .html p {
      margin-top: 8px;
      margin-bottom: 10px;
    }

    .html ul,
    .html ol {
      margin-top: 8px;
      margin-bottom: 10px;
      padding-left: 40px;
      -webkit-padding-start: 40px;
    }

    .html li {
      margin-bottom: 6px;
    }

    .html li p {
      margin: 0;
    }

    .html b,
    .html strong {
      font-weight: 500;
    }

    .html a,
    .html ul a,
    .html ol a {
      color: var(--themeColorDarkerOne);
      text-decoration: none;
    }

    .html a:not([data-internal-link-doctype]),
    .html ul a:not([data-internal-link-doctype]),
    .html ol a:not([data-internal-link-doctype]) {
      border-bottom: 1px solid var(--themeColorDarkerOneTransparent);
    }

    .html:not(.editable) a:hover {
      color: black;
      border-bottom-color: var(--linkColorGray);
    }

    .html.editable a,
    .html.editable ul a,
    .html.editable ol a {
      text-decoration: none;
    }

    .html p:first-child,
    .html h1:first-child,
    .html h2:first-child,
    .html ul:first-child,
    .html ol:first-child {
      margin-top: 0;
    }

    .html p:last-child,
    .html h1:last-child,
    .html h2:last-child,
    .html ul:last-child,
    .html ol:last-child {
      margin-bottom: 0;
    }

    .html ul.checklist {
      list-style-type: none;
    }

    .html ul.checklist > li:before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      background-color: white;
      border: 2px solid silver;
      border-radius: 50%;
      position: relative;
      top: 5px;
      margin-top: -5px;
      margin-left: -30px;
      margin-right: 8px;
    }

    .editable.html ul.checklist > li:before {
      background-color: var(--inputElementColor);
    }

    .html a.reference {
      display: block;
      border: none;
      margin: 0 0 -6px 0;
      padding: 6px 0 3px 44px;
      background: url(/images/references.svg) 8px 1px no-repeat;
      background-size: 30px 30px;
      color: var(--linkColorGray);
      text-decoration: none;
    }

    .html a.reference.invalid {
      color: red;
      cursor: default;
    }

    .html a.reference:not(.invalid):hover {
      color: black;
    }

    ul[data-type='taskList'] {
      list-style-type: none;
    }

    ul[data-type='taskList'] li {
      display: flex;
      margin-bottom: 10px;
    }

    .editable input[type='checkbox'],
    .html:not(.editable) ul[data-type='taskList'] li:before {
      content: '';
      flex: none;
      display: block;
      appearance: none;
      box-sizing: content-box;
      width: 18px;
      height: 18px;
      position: relative;
      top: 8px;
      margin-top: -5px;
      margin-left: -30px;
      margin-right: 8px;
      border: 2px solid silver;
      border-radius: 50%;
      padding: 0;
      background-color: var(--inputElementColor);
      cursor: pointer;
    }

    :host([prevent-checklist]) .html:not(.editable) ul[data-type='taskList'] li:before {
      cursor: default;
    }

    .html:not(.editable) ul[data-type='taskList'] li:before {
      background-color: white;
    }

    .editable input[type='checkbox'][checked='checked'],
    .html:not(.editable) ul[data-type='taskList'] li[data-checked='true']:before {
      background-color: transparent;
      top: 2px;
      background-image: url(/images/check-blue.svg);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 36px 36px;
      border: none;
      border-radius: 0px;
      width: 30px;
      height: 30px;
      margin-left: -34px;
      margin-right: 4px;
    }
  `,
  internalLinkStyles,
];
