import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { createUuid } from 'src/store';
import '../../components/d-popup.js';
import '../elements/d-edit-text.js';
import '../elements/d-select-dropdown.js';

import '../../elements/d-label.js';
import '../../elements/d-section.js';
import '../../elements/d-wrap.js';
import '../elements/d-add-remove-buttons.js';
import { LabeledElement } from 'src/library/abstracts/labeled-element.js';
import { NewNameDialog, NewNameResult } from 'src/library/editors/components/new-name-dialog.js';

/**
 *
 */
@customElement('d-select-add')
export class DSelectAdd extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      :host {
        display: block;
      }
      d-select-dropdown {
        flex-grow: 1;
        margin-right: 0;
      }
    `,
  ];
  @property({ type: String })
  placeholder = '';
  @property({ type: String })
  addItemText = '';
  @property({ type: Boolean })
  allowRepeated = false;
  @property({ type: Array })
  selectedItems: string[] = [];
  @property({ type: Array })
  lockedItems: string[] = [];
  @property({ type: Array })
  options: SelectDropdownOption[] = [];
  @state()
  private addingItem = false;
  @state()
  private newItemSelectedValue = '';

  private get selectOptions() {
    if (this.addItemText) {
      return [...this.options, { value: 'new', text: this.addItemText }];
    }
    return this.options;
  }
  _disabled(item) {
    return item !== '' && this.lockedItems && this.lockedItems.includes(item);
  }

  _valueChanged(newItem?: { value: string; text: string }) {
    this.dispatchEvent(
      new CustomEvent('selected-items-changed', {
        bubbles: true,
        composed: true,
        detail: {
          options: this.options,
          selectedItems: this.selectedItems,
          newItem: newItem,
        },
      }),
    );
  }

  _filteredOptions(item) {
    if (this.allowRepeated) {
      return this.selectOptions;
    }
    return this.selectOptions.filter((option) => {
      return !this.selectedItems.includes(option.value) || option.value === item;
    });
  }

  async _itemChanged(value: string) {
    this.newItemSelectedValue = value;
    if (value === 'new') {
      const { name: newName }: NewNameResult = await NewNameDialog.open({ title: this.addItemText ?? '' });

      if (newName) {
        const newItemValue = createUuid();
        const newItem = { value: newItemValue, text: newName };
        this.options = [...this.options, newItem];
        this.selectedItems = [...this.selectedItems, newItemValue];
        this._valueChanged(newItem);
      }
    } else {
      this.selectedItems = [...this.selectedItems, value];
      this._valueChanged();
    }
    await this.updateComplete;
    this.newItemSelectedValue = '';
  }

  _addItem() {
    this.addingItem = true;
  }

  _removeItem(index) {
    this.selectedItems.splice(index, 1);
    this.requestUpdate();
    this._valueChanged();
  }

  renderContent() {
    const showNewItem = this.selectedItems.length === 0 || this.addingItem;

    return html`
      ${this.selectedItems.map((item, index) => {
        return html`
          <d-wrap nowrap>
            <d-select-dropdown
              ?disabled=${item !== ''}
              ?theme-page=${this.themePage}
              placeholder="${this.placeholder}"
              .value=${item}
              .options=${this._filteredOptions(item)}
            ></d-select-dropdown>
            <d-add-remove-buttons
              ?showRemove=${this.selectedItems.length > 1 && !this._disabled(item)}
              ?showAdd=${!this.addingItem && index === this.selectedItems.length - 1}
              @remove=${() => this._removeItem(index)}
              @add=${() => this._addItem()}
            ></d-add-remove-buttons>
          </d-wrap>
        `;
      })}
      ${showNewItem
        ? html`
            <d-wrap nowrap>
              <d-select-dropdown
                ?theme-page=${this.themePage}
                placeholder="${this.placeholder}"
                .value=${this.newItemSelectedValue}
                .options=${this._filteredOptions('')}
                @value-changed=${async (e) => await this._itemChanged(e.detail.value)}
              ></d-select-dropdown>
              <d-add-remove-buttons ?showRemove=${false} ?showAdd=${false}></d-add-remove-buttons>
            </d-wrap>
          `
        : nothing}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-select-add': DSelectAdd;
  }
}
