import { createSelector } from '@reduxjs/toolkit';
import { getOrganization } from './organization.js';
import { toList } from './utilities.js';
import type { FunctionViewModel, MeetingOccurrenceViewModel, MeetingViewModel, ReportViewModel } from '../api';
import {
  ContactsAsUsers,
  currentEmployeeUuid,
  employeesNotDeleted,
  employeesShortNamesAndI,
  functionsNotDeleted,
  getContactsAsUsers,
  reportsNotDeleted,
  ShortNameEntry,
} from 'src/store';
import { EmployeeViewModelWithName } from 'src/store/types';
import { MeetingStatus } from 'src/library/lists/d-occurrences-list';
import { dateTimeDescription, meetingParticipantsText } from 'src/store/utilities';
import { LocalDate } from 'src/utilities/local-date';
import { sortBy } from 'lodash';
import { entityNameForLists } from 'src/utilities/text';

export interface MeetingOccurrence {
  name: string;
  uuid: string;
  isConfirmedEntity: boolean;
  date: string;
  time: string;
  dateTimeForSorting: string;
  durationMinutes: number;
  displayDate: string;
  assignees: string;
  classified: boolean;
  restricted: boolean;
  meetingStatus?: MeetingStatus;
  draftName: string;
}

export interface MeetingModel {
  uuid: string;
  name: string;
  interviewEmployeeName: string;
  interviewEmployeeUuid: string;
  type: 'FUTURE' | 'PAST' | 'INTERVIEW_ACTIVE' | 'INTERVIEW_TERMINATED' | 'SUGGESTED';
  meetingOccurrences: MeetingOccurrence[];
  /* If the meeting is based on a meeting template */
  meetingUuid?: string;
}

export const meetingsNotDeleted = createSelector(getOrganization, (organization): MeetingViewModel[] => {
  if (organization === undefined) return [];
  return toList(organization.meetingsById)
    .filter((e) => !e.deleted)
    .filter((e) => e.templateId !== undefined)
    .filter((e) => e.templateId !== 10142);
});

export const meetingOccurrencesNotDeleted = createSelector(
  getOrganization,
  (organization): MeetingOccurrenceViewModel[] => {
    if (organization === undefined) return [];
    return organization.meetingOccurrences ?? [];
  },
);
export const meetingsAlsoDeleted = createSelector(getOrganization, (organization): MeetingViewModel[] => {
  if (organization === undefined) return [];
  return toList(organization.meetingsById);
});

export const meetingsDeleted = createSelector(getOrganization, (organization): MeetingViewModel[] => {
  if (organization === undefined) return [];
  return toList(organization.meetingsById).filter((e) => e.deleted);
});

export const employeeInterview = createSelector(meetingsNotDeleted, function (meetings) {
  return meetings.filter(function (item) {
    return item.templateId === 9850;
  })[0];
});

export const employeeInterviewUuid = createSelector(employeeInterview, function (employeeInterview) {
  if (employeeInterview) {
    return employeeInterview.uuid;
  }
  return '';
});

function toMeetingOccurrenceModel(
  occurrence: MeetingOccurrenceViewModel,
  currentEmployeeId: string,
  reports: ReportViewModel[],
  functions: FunctionViewModel[],
  employeesShortNamesAndI: ShortNameEntry[],
  contactsAsUsers: ContactsAsUsers[],
): MeetingOccurrence {
  let dateTimeForSorting = LocalDate.now().toString();
  if (occurrence.date) {
    dateTimeForSorting = occurrence.date;
    if (occurrence.time) {
      dateTimeForSorting += ' ' + occurrence.time;
    }
  }
  let meetingStatus: MeetingStatus = 'NONE';
  if (occurrence.noticeLastSent) {
    meetingStatus = 'NOTICE_SENT';
  }
  if (
    reports.find((r) => {
      return r.uuid === occurrence.reportUuid;
    }) !== undefined
  ) {
    meetingStatus = 'REPORT_WRITTEN';
  }
  return {
    name: occurrence.name || '',
    uuid: occurrence.uuid,
    isConfirmedEntity: occurrence.isConfirmedEntity,
    date: occurrence.date,
    time: occurrence.time ?? '',
    dateTimeForSorting,
    durationMinutes: occurrence.durationMinutes,
    displayDate: dateTimeDescription(occurrence.date ?? '', occurrence.time ?? '', occurrence.durationMinutes),
    assignees: meetingParticipantsText(occurrence, functions, employeesShortNamesAndI, contactsAsUsers, true),
    classified: occurrence.classification !== 'NONE',
    restricted: occurrence.classification !== 'NONE' && !occurrence.accessControl.includes(currentEmployeeId || ''),
    meetingStatus,
    draftName: occurrence.draftName ?? '',
  };
}

function isEmployeeTerminated(uuid: string, employees: EmployeeViewModelWithName[]) {
  return (
    employees.find((e) => {
      return e.uuid === uuid && e.status === 'TERMINATED';
    }) !== undefined
  );
}

export const meetings = createSelector(
  meetingsNotDeleted,
  employeeInterviewUuid,
  meetingOccurrencesNotDeleted,
  functionsNotDeleted,
  employeesNotDeleted,
  currentEmployeeUuid,
  reportsNotDeleted,
  employeesShortNamesAndI,
  getContactsAsUsers,
  function (
    meetings,
    employeeInterviewUuid,
    occurrences,
    functions,
    employees,
    currentEmployeeId,
    reports,
    employeesShortNamesAndI,
    contactsAsUsers,
  ) {
    const employeeInterview = meetings.find((meeting) => {
      return meeting.uuid === employeeInterviewUuid;
    });
    let groups: MeetingModel[] = [];
    const today = LocalDate.now();
    // Create groups for all meeting occurrences except employee interviews
    occurrences
      .filter((occurrence) => {
        return occurrence.name !== employeeInterview?.name;
      })
      .forEach((occurrence) => {
        let future = false;
        if (LocalDate.fromString(occurrence.date).isSameOrAfter(today)) {
          future = true;
        }
        const existingGroup = groups.find((group) => {
          return group.name === occurrence.name;
        });
        if (existingGroup) {
          groups = groups.map((group) => {
            if (group.name === occurrence.name) {
              if (future) {
                group.type = 'FUTURE';
              }
              group.meetingOccurrences.push(
                toMeetingOccurrenceModel(
                  occurrence,
                  currentEmployeeId ?? '',
                  reports,
                  functions,
                  employeesShortNamesAndI,
                  contactsAsUsers,
                ),
              );
            }
            return group;
          });
        } else {
          let uuid = occurrence.uuid;
          let meetingUuid: string | undefined = undefined;
          const meeting = meetings.find((meeting) => {
            return meeting.name === occurrence.name;
          });
          if (meeting) {
            uuid = meeting.uuid;
            meetingUuid = meeting.uuid;
          }
          groups.push({
            uuid,
            name: entityNameForLists(occurrence.name, occurrence.draftName, occurrence.isConfirmedEntity),
            interviewEmployeeName: '',
            interviewEmployeeUuid: '',
            type: future ? 'FUTURE' : 'PAST',
            meetingOccurrences: [
              toMeetingOccurrenceModel(
                occurrence,
                currentEmployeeId ?? '',
                reports,
                functions,
                employeesShortNamesAndI,
                contactsAsUsers,
              ),
            ],
            meetingUuid: meetingUuid,
          });
        }
      });
    // Create interview groups for all employees
    employees
      .filter((employee) => {
        return employee.isConfirmedEntity;
      })
      .forEach((employee) => {
        const interviewOccurrences = occurrences
          .filter((occurrence) => {
            return occurrence.name === employeeInterview?.name && occurrence.participants.includes(employee.uuid);
          })
          .map((occurrence) => {
            return toMeetingOccurrenceModel(
              occurrence,
              currentEmployeeId ?? '',
              reports,
              functions,
              employeesShortNamesAndI,
              contactsAsUsers,
            );
          });
        groups.push({
          uuid: employee.uuid,
          name: employeeInterview?.name || '',
          interviewEmployeeName: employee.name,
          interviewEmployeeUuid: employee.uuid,
          type: isEmployeeTerminated(employee.uuid, employees) ? 'INTERVIEW_TERMINATED' : 'INTERVIEW_ACTIVE',
          meetingOccurrences: interviewOccurrences,
        });
      });
    // Create groups for all template meetings with no occurrences
    meetings.forEach((meeting) => {
      const existingGroup = groups.find((g) => {
        return g.name === meeting.name;
      });
      if (!existingGroup && meeting.uuid !== employeeInterviewUuid) {
        groups.push({
          uuid: meeting.uuid,
          name: meeting.name || '',
          interviewEmployeeName: '',
          interviewEmployeeUuid: '',
          type: 'SUGGESTED',
          meetingOccurrences: [],
          meetingUuid: meeting.uuid,
        });
      }
    });
    return groups.map((group) => {
      group.meetingOccurrences = sortBy(group.meetingOccurrences, ['dateTimeForSorting']);
      return group;
    });
  },
);
