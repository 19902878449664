import { RiskLevels } from 'src/library/lists/utilities.js';

export function riskLevelFromRisk(risk: number): RiskLevels | undefined {
  if (risk === 0) {
    return undefined;
  }
  if (risk > 0 && risk < 5) {
    return 1;
  }
  if (risk > 4 && risk < 9) {
    return 2;
  }
  if (risk > 8 && risk < 13) {
    return 3;
  }
  if (risk > 12) {
    return 4;
  }
}
