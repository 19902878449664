import { css, html, LitElement, PropertyValues } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

/**
 *
 * The d-positioner positions slotted content relative to a given target element,
 * while keeping the content whithin the viewport. Suitable for e.g. contextual menus and tooltips.
 *
 * STATUS OK
 */
@customElement('d-positioner')
export class DPositioner extends LitElement {
  static readonly styles = css`
    :host {
      position: fixed;
      height: 0;
      visibility: hidden;
      z-index: 3;
    }
    #content {
      position: relative;
    }
    .backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  `;

  @property({ type: Object })
  target: HTMLElement | undefined;
  @property({ type: Object })
  cursorPosition: { left: number; top: number } = { left: 0, top: 0 };
  @property({ type: String })
  horizontal: 'left' | 'center' | 'right' = 'center';
  @property({ type: String })
  vertical: 'over' | 'top' | 'center' | 'bottom' | 'under' = 'over';
  @property({ type: Number })
  top = 0;
  @property({ type: Number })
  left = 0;
  @property({ type: Number })
  margin = 20;
  @query('#content')
  contentElm!: HTMLElement;

  show() {
    const viewportWidth: number = document.documentElement.clientWidth;
    const viewportHeight: number = document.documentElement.clientHeight;
    const contentRect: DOMRect = this.contentElm.getBoundingClientRect();
    const contentWidth = contentRect.width;
    const contentHeight = contentRect.height;
    let left: number;
    let top: number;
    if (this.target) {
      const position = this.relativeToTarget(this.target.getBoundingClientRect(), contentWidth, contentHeight);
      left = position.left;
      top = position.top;
    } else {
      const position = this.relativeToCursor(contentWidth, contentHeight);
      left = position.left;
      top = position.top;
    }
    if (top < this.margin) {
      top = this.margin;
    }
    if (top + contentHeight > viewportHeight - this.margin) {
      top = viewportHeight - contentHeight - this.margin;
    }
    if (left < this.margin) {
      left = this.margin;
    }
    if (left + contentWidth > viewportWidth - this.margin) {
      left = viewportWidth - contentWidth - this.margin;
    }
    this.style.left = left + 'px';
    this.style.top = top + 'px';
    this.style.visibility = 'visible';
    this.dispatchEvent(
      new CustomEvent('visible', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  hide() {
    this.style.visibility = 'hidden';
    this.target = undefined;
    this.dispatchEvent(
      new CustomEvent('hidden', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  render() {
    return html`<div class="backdrop" @click=${() => this.hide()}></div>
      <div id="content"><slot></slot></div>`;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    if (this.contentElm.offsetHeight) {
      this.show();
    } else {
      this.hide();
    }
  }

  private relativeToCursor(contentWidth: number, contentHeight: number) {
    let left = this.cursorPosition.left + this.left;
    let top = this.cursorPosition.top + this.top;
    if (this.horizontal === 'left') {
      left -= contentWidth;
    }
    if (this.horizontal === 'center') {
      left -= contentWidth / 2;
    }
    if (this.vertical === 'top' || this.vertical === 'over') {
      top -= contentHeight;
    }
    if (this.vertical === 'center') {
      top -= contentHeight / 2;
    }
    return { left, top };
  }

  private relativeToTarget(targetRect: DOMRect, contentWidth: number, contentHeight: number) {
    let left = 0;
    let top = 0;
    const targetLeft = targetRect.left;
    const targetTop = targetRect.top;
    const targetWidth = targetRect.width;
    const targetHeight = targetRect.height;
    switch (this.horizontal) {
      case 'left':
        left = targetLeft;
        break;
      case 'center':
        left = targetLeft + targetWidth / 2 - contentWidth / 2;
        break;
      case 'right':
        left = targetLeft + targetWidth - contentWidth;
        break;
    }
    switch (this.vertical) {
      case 'over':
        top = targetTop - contentHeight;
        break;
      case 'top':
        top = targetTop;
        break;
      case 'center':
        top = targetTop + targetHeight / 2 - contentHeight / 2;
        break;
      case 'bottom':
        top = targetTop + targetHeight - contentHeight;
        break;
      case 'under':
        top = targetTop + targetHeight;
        break;
    }
    return { left, top };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-positioner': DPositioner;
  }
}
