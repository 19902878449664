import { LocalDate } from 'src/utilities/local-date.js';
import { LocalTime } from 'src/utilities/local-time.js';

export class LocalDateTime {
  readonly date: LocalDate;
  readonly time: LocalTime;

  private constructor(d: LocalDate, t: LocalTime) {
    this.date = d;
    this.time = t;
  }

  static now(): LocalDateTime {
    return LocalDateTime.fromDate(new Date());
  }
  static fromIsoString(text: string): LocalDateTime {
    const s = text.split('T');
    return new LocalDateTime(LocalDate.fromString(s[0]), LocalTime.fromString(s[1]));
  }

  static fromDate(date: Date): LocalDateTime {
    return new LocalDateTime(
      LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate()),
      LocalTime.of(date.getHours(), date.getMinutes()),
    );
  }

  static fromString(text: string): LocalDateTime {
    const s = text.split(' ');
    return new LocalDateTime(LocalDate.fromString(s[0]), LocalTime.fromString(s[1]));
  }

  toStringForDisplay() {
    return this.date.toStringForDisplay() + ' kl. ' + this.time.toStringForDisplay();
  }

  toString() {
    return this.date.toString() + ' ' + this.time.toString();
  }
}
