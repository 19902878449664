import { css, LitElement } from 'lit';
import { property } from 'lit/decorators.js';

export class DPrepareBehavior extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }
      .outskirtsContent {
        margin: 0 auto;
        width: 768px;
        padding-left: 20px;
        padding-right: 20px;
        max-width: var(--appWidth);
        min-width: 360px;
        box-sizing: border-box;
      }

      .prompt {
        line-height: 150%;
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 80px;
      }

      button {
        margin-top: 16px;
        margin-left: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        border-radius: 4px;
        resize: none;
        padding: 10px 14px;
        font-family: var(--mainSans);
        font-size: 15px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.2);
        font-weight: normal;
        color: white;
      }
    `,
  ];

  @property({
    type: String,
  })
  page = '';
  protected next: string | undefined;
  protected back: string | undefined;
  private validate?: () => boolean;

  _forward(e) {
    e.preventDefault();
    if (this._validate()) {
      this._goStep(this.page, this.next);
    }
  }

  _backward(e) {
    e.preventDefault();
    this._goStep(this.page, this.back);
  }

  _cancel(e) {
    e.preventDefault();
    this.dispatchEvent(new CustomEvent('go-cancel', { bubbles: true, composed: true, detail: { current: this.page } }));
  }
  _beforeFinish() {
    return true;
  }
  _finish(e) {
    e.preventDefault();
    if (this._beforeFinish()) {
      this.dispatchEvent(
        new CustomEvent('go-finish', { bubbles: true, composed: true, detail: { current: this.page } }),
      );
    }
  }
  _goStep(from, to) {
    this.dispatchEvent(
      new CustomEvent('next-step', { bubbles: true, composed: true, detail: { current: from, next: to } }),
    );
  }
  _validate() {
    if (this.validate) {
      const valid = this.validate();
      this.dispatchEvent(
        new CustomEvent((valid ? '' : 'in') + 'valid-step', {
          bubbles: true,
          composed: true,
          detail: {
            step: this.page,
          },
        }),
      );
      return valid;
    }
    return true;
  }
}
