import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import 'src/content/entity-content';
import '../../library/editors/index.js';
import '../../library/fields/index.js';
import '../../library/fields/d-expansion.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../pages/computers-page/d-edit-single-related-item.js';
import { DataMapElement, DataMapItem } from './data-map-element.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { NetworkViewModelNetworkTypeEnum } from 'src/store/api';

export interface NetworkItem extends DataMapItem {
  systemUpdateOperator: any;
  antiVirusOperatorType: string;
  antiVirusOperator: any;
  systemUpdateOperatorType: any;
  partners: SelectDropdownOption[];
  connectionType: string;
  name: string;
  type?: NetworkViewModelNetworkTypeEnum;
  firewallName: string;
  firewallType: string;
  unitType: 'network';
  attachments: string[];
  supplierUuid: string;
}

export interface NetworkEditItem {
  supplierUuid: string;
  antiVirusOperator: string;
  antiVirusOperatorType: string;
  systemUpdateOperator: string;
  systemUpdateOperatorType: string;
  connectionType: string;
  firewallName: string;
  firewallType: string;
  type?: NetworkViewModelNetworkTypeEnum;
  name: string;
}

/**
 *
 */
@customElement('d-network-item-view')
export class DNetworkItemView extends DataMapElement<NetworkItem, NetworkEditItem> {
  @property({ type: String })
  firewall = '';
  @property({ type: String })
  firewallName = '';
  @property({ type: String })
  supplierUuid = '';
  @property({ type: String })
  isp = '';
  @property({ type: String })
  containedNewComputerUuid = '';
  @property({ type: Object })
  containedNewComputer: any = {};
  @property({ type: Boolean })
  connectionOpened = false;
  @property({ type: Boolean })
  firewallOpened = false;
  @property({ type: Boolean })
  networkOpened = false;
  @property({ type: Boolean })
  newElement = false;
  @property({ type: Object })
  viewOptions: any = {};
  @property({ type: Boolean })
  editModeForConnection = false;
  @property({ type: Boolean })
  editModeForFirewall = false;
  @property({ type: Boolean })
  editModeForNetwork = false;
  private networkTypes: SelectDropdownOption[] = [
    { value: 'Cable', text: 'Kablet nettverk' },
    { value: 'Wifi', text: 'Trådløst nettverk' },
    { value: 'Both', text: 'Kablet og trådløst' },
  ];

  private firewallOptions: SelectDropdownOption[] = [
    { value: 'NONE', text: 'Ingen' },
    { value: 'NHN', text: 'NHN med NHN samband' },
    { value: 'NHNotherISP', text: 'NHN med selvvalgt samband' },
    { value: 'OTHER', text: 'Annen brannmurtype, spesifiser…' },
  ];
  private connectionTypesForNetwork: SelectDropdownOption[] = [
    { value: 'NONE', text: 'Ingen internettforbindelse' },
    {
      value: 'NHN',
      text: 'Tilkoblet helsenettet med Norsk helsenett som internettleverandør',
    },
    {
      value: 'NHNotherISP',
      text: 'Tilkoblet helsenettet med selvvalgt internettleverandør',
    },
    { value: 'OTHER', text: 'Tilkoblet Internett utenfor helsenettet' },
  ];
  initializeEditItem(): NetworkEditItem {
    return {
      supplierUuid: this.item.supplierUuid,
      antiVirusOperator: this.item.antiVirusOperator,
      antiVirusOperatorType: this.item.antiVirusOperatorType,
      systemUpdateOperator: this.item.systemUpdateOperator,
      systemUpdateOperatorType: this.item.systemUpdateOperatorType,
      connectionType: this.item.connectionType,
      firewallName: this.item.firewallName,
      firewallType: this.item.firewallType,
      type: this.item.type,
      name: this.item.name,
    };
  }

  _showIsp(isp, elementOpened) {
    return isp !== '' || elementOpened;
  }

  _showFirewall(firewall, elementOpened) {
    return firewall != 'NONE' || elementOpened;
  }

  _computeConnectionElementClasses(connectionType, elementOpened) {
    let classes = 'mapElement access';
    if (elementOpened) {
      classes += ' opened';
    }
    if (connectionType === 'NONE') {
      classes += ' ghostElement';
    }
    return classes;
  }

  _computeFirewallElementClasses(firewall, elementOpened) {
    let classes = 'mapElement firewall';
    if (elementOpened) {
      classes += ' opened';
    }
    if (firewall === 'NONE' || firewall === '') {
      classes += ' ghostElement';
    }
    return classes;
  }

  _showsupplierUuidHeader(elementOpened) {
    return !elementOpened;
  }

  _showFirewallHeader(elementOpened, firewall) {
    return !elementOpened && firewall != 'NONE';
  }

  _name(type, uuid) {
    for (const item of type) {
      if (item.uuid === uuid[0]) {
        return item.name;
      }
    }
    return '';
  }

  _firewallDisplayName(firewall, firewallName) {
    if (firewall === 'NHN') {
      return 'NHN med NHN samband';
    }
    if (firewall === 'NHNotherISP') {
      return 'NHN med selvvalgt samband';
    }
    if (firewall === 'OTHER') {
      return firewallName;
    }
    return 'Ingen';
  }

  _connectionTypeChanged() {
    if (this.item.connectionType === 'NONE') {
      this.isp = '';
    }
  }

  _operatorTypes() {
    const list = [
      { value: 'UNDEFINED', text: 'Velg driftsmåte' },
      { value: 'NONE', text: 'Ingen' },
    ];
    list.push(
      { value: 'internal', text: 'Driftes internt' },
      { value: 'external', text: 'Driftes av ekstern leverandør' },
      {
        value: 'internalAndExternal',
        text: 'Driftes både internt og eksternt',
      },
    );
    return list;
  }

  _operatorIsExternal(operatorType) {
    return operatorType === 'external' || operatorType === 'internalAndExternal';
  }

  _getOperatorTypeValue(id) {
    let result = '';
    const list = this._operatorTypes();
    for (const item of list) {
      if (id === item.value) {
        result = item.text;
      }
    }

    if (result === 'Velg driftsmåte') {
      result = 'Ikke valgt';
    }

    return result;
  }

  renderUnitView() {
    return html`<section view="">
      <d-section>
        <d-view-text
          field="networks_systemUpdateOperatorType"
          value="${this._getOperatorTypeValue(this.item.systemUpdateOperatorType)}"
        >
        </d-view-text>
        <d-view-text
          field="networks_systemUpdateOperator"
          value="${this._getName(this.item.partners, this.item.systemUpdateOperator)}"
        >
        </d-view-text>
      </d-section>
      <d-section>
        <d-view-text
          field="networks_antiVirusOperatorType"
          value="${this._getOperatorTypeValue(this.item.antiVirusOperatorType)}"
        >
        </d-view-text>
        <d-view-text
          field="networks_antiVirusOperator"
          value="${this._getName(this.item.partners, this.item.antiVirusOperator)}"
        >
        </d-view-text>
      </d-section>

      <d-list-section-attachment
        .attachments="${this.item.attachments}"
        class="computers"
        label="Vedlegg"
        page-id="20"
        sort="1"
        .writeAccess=${this.writeAccess}
        theme-page
      >
      </d-list-section-attachment>
    </section>`;
  }

  renderUnitEdit(editItem: NetworkEditItem) {
    return html`
      <d-section>
        <d-select-dropdown
          theme-page
          field="networks_type"
          .options="${this.networkTypes}"
          .value=${editItem.type ?? ''}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              type: e.detail.value as NetworkViewModelNetworkTypeEnum,
            };
          }}
        >
        </d-select-dropdown>
        <d-edit-text
          theme-page
          field="networks_name"
          value="${editItem.name}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              name: e.detail.value,
            };
          }}
        >
        </d-edit-text>
      </d-section>
      ${this.newElement
        ? html`
            <d-section>
              <d-select-dropdown
                theme-page
                field="firewalls_firewall"
                .options="${this.firewallOptions}"
                value="${editItem.firewallType}"
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  e.stopPropagation();
                  this.editItem = {
                    ...editItem,
                    firewallType: e.detail.value,
                  };
                }}
              >
              </d-select-dropdown>
            </d-section>
            <d-expansion ?opened=${editItem.firewallType === 'OTHER'}>
              <d-section style="border-top: none; padding-top: 0">
                <d-edit-text
                  theme-page
                  value="${editItem.firewallName}"
                  @value-changed=${(e: CustomEvent<{ value: string }>) => {
                    e.stopPropagation();
                    this.editItem = {
                      ...editItem,
                      firewallName: e.detail.value,
                    };
                  }}
                >
                </d-edit-text>
              </d-section>
            </d-expansion>
            <d-section>
              <d-select-dropdown
                theme-page
                field="internetConnections_connectionType"
                .options="${this.connectionTypesForNetwork}"
                value="${editItem.connectionType}"
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  e.stopPropagation();
                  this.editItem = {
                    ...editItem,
                    connectionType: e.detail.value,
                  };
                }}
              >
              </d-select-dropdown>
            </d-section>
            <d-expansion ?opened=${editItem.connectionType !== 'NONE'}>
              <d-section>
                <d-edit-single-related-item
                  field="internetConnections_isp"
                  .items=${this.item.partners}
                  @new-item=${this.onNewPartner}
                  placeholder="Velg samarbeidspartner"
                  .relatedItem=${editItem.supplierUuid}
                  @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
                    e.stopPropagation();
                    this.editItem = {
                      ...editItem,
                      supplierUuid: e.detail.relatedItem,
                    };
                  }}
                >
                </d-edit-single-related-item>
              </d-section>
            </d-expansion>
          `
        : nothing}

      <d-section>
        <d-select-dropdown
          theme-page
          field="networks_systemUpdateOperatorType"
          .options="${this._operatorTypes()}"
          value="${editItem.systemUpdateOperatorType}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              systemUpdateOperatorType: e.detail.value,
            };
          }}
        >
        </d-select-dropdown>
      </d-section>
      <d-expansion ?opened=${this._operatorIsExternal(editItem.systemUpdateOperatorType)}>
        <d-section>
          <d-edit-single-related-item
            field="networks_systemUpdateOperator"
            .items=${this.item.partners}
            @new-item=${this.onNewPartner}
            placeholder="Velg leverandør"
            .relatedItem=${editItem.systemUpdateOperator}
            @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                systemUpdateOperator: e.detail.relatedItem,
              };
            }}
          >
          </d-edit-single-related-item>
        </d-section>
      </d-expansion>
      <d-section>
        <d-select-dropdown
          theme-page
          field="networks_antiVirusOperatorType"
          .options="${this._operatorTypes()}"
          value="${editItem.antiVirusOperatorType}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              antiVirusOperatorType: e.detail.value,
            };
          }}
        >
        </d-select-dropdown>
      </d-section>
      <d-expansion ?opened=${this._operatorIsExternal(editItem.antiVirusOperatorType)}>
        <d-section>
          <d-edit-single-related-item
            field="networks_antiVirusOperator"
            .items=${this.item.partners}
            @new-item=${this.onNewPartner}
            placeholder="Velg leverandør"
            .relatedItem=${editItem.antiVirusOperator}
            @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                antiVirusOperator: e.detail.relatedItem,
              };
            }}
          >
          </d-edit-single-related-item>
        </d-section>
      </d-expansion>
    `;
  }

  _displayTypeName(): string {
    return 'Lokalt nettverk';
  }

  _displayName() {
    return this.item.name;
  }

  _isValid() {
    return true;
  }

  protected typeClasses(): string {
    if (
      this.item.type === NetworkViewModelNetworkTypeEnum.Wifi ||
      this.item.type === NetworkViewModelNetworkTypeEnum.Both
    ) {
      return 'wifi';
    } else {
      return 'network';
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-network-item-view': DNetworkItemView;
  }
}
