/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface WorkScheduleException
 */
export interface WorkScheduleException {
  /**
   *
   * @type {string}
   * @memberof WorkScheduleException
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof WorkScheduleException
   */
  start?: string;
  /**
   *
   * @type {string}
   * @memberof WorkScheduleException
   */
  end?: string;
  /**
   *
   * @type {boolean}
   * @memberof WorkScheduleException
   */
  work: boolean;
}

/**
 * Check if a given object implements the WorkScheduleException interface.
 */
export function instanceOfWorkScheduleException(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'date' in value;
  isInstance = isInstance && 'work' in value;

  return isInstance;
}

export function WorkScheduleExceptionFromJSON(json: any): WorkScheduleException {
  return WorkScheduleExceptionFromJSONTyped(json, false);
}

export function WorkScheduleExceptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkScheduleException {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: json['date'],
    start: !exists(json, 'start') ? undefined : json['start'],
    end: !exists(json, 'end') ? undefined : json['end'],
    work: json['work'],
  };
}

export function WorkScheduleExceptionToJSON(value?: WorkScheduleException | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date,
    start: value.start,
    end: value.end,
    work: value.work,
  };
}
