import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../library/elements/d-section.js';
import '../../../../library/elements/d-wrap.js';
import '../../../../library/editors/elements/d-checkbox.js';
import '../../../../library/components/d-popup.js';
import '../../../../library/editors/elements/d-edit-text.js';
import '../../../../library/editors/elements/d-select-dropdown.js';
import '../../../../library/editors/components/d-select-dropdown-or-add.js';
import {
  internetConnectionTypeOptions,
  networkTypeOptions,
  responsiveFormStyles,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { BaseDialog, DialogCancelResult } from 'src/library/components/BaseDialog.js';
import type { ActionInput } from 'src/library/elements/d-action.js';

export interface NewNetworkInput {
  name: string;
  type: string;
  connectionType: string;
}

export type NewNetworkResult =
  | DialogCancelResult
  | {
      name: string;
      type: string;
      connectionType: string;
      action: 'done';
    };
/**
 *
 * USAGE:
 *    d-infosec-computers-networks
 *
 */
@customElement('new-network-dialog')
export class NewNetworkDialog extends BaseDialog<NewNetworkInput, NewNetworkResult> {
  static readonly styles = [
    ...BaseDialog.styles,
    responsiveFormStyles,
    css`
      d-select-dropdown-or-add {
        min-width: 240px;
      }
    `,
  ];

  @property({ type: String })
  name = '';
  @property({ type: String })
  type = 'CABLE';
  @property({ type: String })
  connectionType = 'NONE';

  width = 400;
  title = 'Nytt nettverk';

  protected get calculatedHeaderActions(): ActionInput[] {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Ferdig', action: 'done', disabled: this.name === '' },
    ];
  }
  _newItemNameChange(e) {
    this.name = e.detail.value;
  }

  renderBody() {
    return html`
      <d-section>
        <d-edit-text
          autofocus
          label="Navn"
          .value=${this.name}
          @value-changed=${(e) => this._newItemNameChange(e)}
        ></d-edit-text>
        <d-select-dropdown
          field="networks_type"
          .options=${networkTypeOptions}
          .value=${this.type}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.type = e.detail.value;
          }}
        >
        </d-select-dropdown>
        <d-select-dropdown
          field="internetConnections_connectionType"
          .options=${internetConnectionTypeOptions}
          .value=${this.connectionType}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.connectionType = e.detail.value;
          }}
        >
        </d-select-dropdown>
      </d-section>
    `;
  }

  protected fetchResult(detail: string | undefined): NewNetworkResult {
    if (detail === 'done') {
      return {
        name: this.name,
        type: this.type,
        connectionType: this.connectionType,
        action: 'done',
      };
    } else {
      return {
        action: 'cancel',
      };
    }
  }

  protected initializeDialog(input: NewNetworkInput) {
    this.name = input.name;
    this.type = input.type;
    this.connectionType = input.connectionType;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'new-network-dialog': NewNetworkDialog;
  }
}
