import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../library/editors/components/d-select-dropdown-or-add.js';
import '../../../../library/editors/elements/d-add-remove-buttons.js';
import type {
  InfosecDataType,
  InfosecCategory,
  InfosecDataItem,
  InfosecStorageUnit,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { uuid } from 'src/utilities/text.js';
import { NewNameDialog, NewNameResult } from 'src/library/editors/components/new-name-dialog.js';

export interface DataItemLocationChange {
  uuid?: string;
  newLocation: boolean;
  newLocationName?: string;
  dataType: string;
  category: string;
}

/**
 * Either display an existing data item stored in a physical location or allows the creation of a new data item in a physical location.
 * Also allows a new location to be registered when creating the new data item.
 *
 * Existing data items can not be changed.
 *
 * Locations are not stored except for in data items. The available locations are derived from all registered locations in data items.
 * A location is not avialable if it is already in use for this data type and category.
 *
 * @fires data-item-changed
 *
 *
 *
 *
 */
@customElement('d-edit-data-item-location')
export class DEditDataItemLocation extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    d-select-dropdown-or-add {
      flex: 1;
    }
  `;
  /**
   * The data type. Used for new items.
   */
  @property({ type: Object })
  dataType!: InfosecDataType;

  /**
   * The category. Used for new items.
   */
  @property({ type: Object })
  category!: InfosecCategory;

  /**
   * Available locations.
   */
  @property({ type: Array })
  locations: InfosecStorageUnit[] = [];

  /**
   * All data items for this type and category
   */
  @property({ type: Array })
  dataItems: InfosecDataItem[] = [];

  /**
   * The data item being edited or an "empty" instance to be added
   */
  @property({ type: Object })
  dataItem!: InfosecDataItem;

  /**
   * The label for this data type and category.
   */
  @property({ type: String })
  label = '';

  private get options() {
    const options = this.locations.map((location) => {
      return {
        value: location.uuid,
        text: location.name,
      };
    });
    if (this.dataItems.length) {
      return options.filter((option) => {
        const matchingDataItems = this.dataItems.filter((dataItem) => {
          return dataItem.storageLocation === option.value;
        });
        return option.value === this.dataItem.storageLocation || matchingDataItems.length === 0;
      });
    }
    return options;
  }

  async _valueChanged(value) {
    if (value === 'NEW') {
      const result: NewNameResult = await NewNameDialog.open({ title: 'Nytt oppbevaringsssted' });
      if (result.action === 'done') {
        const locationUuid = uuid();
        this.locations.push({
          uuid: locationUuid,
          name: result.name,
          number: '',
          unitType: 'assets',
          computerType: '',
          application: '',
          partnerUuid: '',
          networkUuid: '',
        });
        let dataItemUuid = this.dataItem.uuid;
        if (dataItemUuid === '') {
          dataItemUuid = uuid();
        }
        this.dataItem = {
          ...this.dataItem,
          uuid: dataItemUuid,
          storageUnit: locationUuid,
        };
        this._dispatchLocationsChanged();
        this._dispatchDataItemChanged();
        this.dispatchEvent(
          new CustomEvent<DataItemLocationChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              newLocation: true,
              newLocationName: result.name,
              dataType: this.dataType.type,
              category: this.category.category,
            },
          }),
        );
      }
    } else {
      let dataItemUuid = this.dataItem.uuid;
      if (dataItemUuid === '') {
        dataItemUuid = uuid();
      }
      this.dataItem = {
        ...this.dataItem,
        uuid: dataItemUuid,
        storageUnit: value,
        storageUnitType: 'locations',
      };
      this._dispatchDataItemChanged();
      this.dispatchEvent(
        new CustomEvent<DataItemLocationChange>('data-item-changed', {
          composed: true,
          bubbles: true,
          detail: {
            newLocation: false,
            newLocationName: value,
            dataType: this.dataType.type,
            category: this.category.category,
          },
        }),
      );
    }
  }

  _dispatchDataItemChanged() {
    this.dispatchEvent(
      new CustomEvent('data-item-updated', {
        bubbles: true,
        composed: true,
        detail: this.dataItem,
      }),
    );
  }

  _dispatchLocationsChanged() {
    this.dispatchEvent(
      new CustomEvent('locations-changed', {
        bubbles: true,
        composed: true,
        detail: this.locations,
      }),
    );
  }

  render() {
    return html`
      <d-select-dropdown-or-add
        theme-page
        .label=${this.label}
        placeholder="Velg oppbevaringssted"
        unselectablePlaceholder
        firstItemText="Registrer oppbevaringssted"
        additionalItemText="Annet oppbevaringssted"
        ?disabled=${this.dataItem.storageLocation !== ''}
        .options=${this.options}
        .value=${this.dataItem.storageLocation}
        @value-changed=${(e) => this._valueChanged(e.detail.value)}
      ></d-select-dropdown-or-add>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-data-item-location': DEditDataItemLocation;
  }
}
