/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ElmaServiceItem
 */
export interface ElmaServiceItem {
  /**
   *
   * @type {string}
   * @memberof ElmaServiceItem
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof ElmaServiceItem
   */
  name: string;
}

/**
 * Check if a given object implements the ElmaServiceItem interface.
 */
export function instanceOfElmaServiceItem(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'identifier' in value;
  isInstance = isInstance && 'name' in value;

  return isInstance;
}

export function ElmaServiceItemFromJSON(json: any): ElmaServiceItem {
  return ElmaServiceItemFromJSONTyped(json, false);
}

export function ElmaServiceItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElmaServiceItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    identifier: json['identifier'],
    name: json['name'],
  };
}

export function ElmaServiceItemToJSON(value?: ElmaServiceItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    identifier: value.identifier,
    name: value.name,
  };
}
