import { css, html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import 'src/content/entity-content';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import '../../library/editors/index.js';
import '../../library/elements/d-action.js';
import '../../library/fields/index.js';
import '../../library/fields/d-expansion.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../pages/computers-page/d-edit-single-related-item.js';
import { styleMap } from 'lit/directives/style-map.js';

export interface DataMapItem {
  alerts: string[];
  unitType: string;
  uuid: string;
}

/**
 * Abstract computer map item. Renders the header and icon.
 *
 */
export abstract class DataMapElement<T extends DataMapItem, E> extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
        max-width: 724px;
      }

      .access .elementHeader .icon {
        background-image: url('/images/data-map/access.svg');
      }

      .partner .elementHeader .icon {
        background-image: url('/images/data-map/access.svg');
      }

      .firewall .elementHeader .icon {
        background-image: url('/images/data-map/firewall.svg');
      }

      .network .elementHeader .icon {
        background-image: url('/images/data-map/network.svg');
      }

      .wifi .elementHeader .icon {
        background-image: url('/images/data-map/wifi.svg');
      }

      .computer d-expansion > .elementHeader .icon {
        background-image: url('/images/data-map/computer.svg');
      }

      .server d-expansion > .elementHeader .icon {
        background-image: url('/images/data-map/server.svg');
      }

      .mobile d-expansion > .elementHeader .icon {
        background-image: url('/images/data-map/mobile.svg');
      }

      .remoteServer d-expansion > .elementHeader .icon {
        background-image: url('/images/data-map/server.svg');
      }

      .cloudService d-expansion > .elementHeader .icon {
        background-image: url('/images/data-map/cloud.svg');
      }

      .externalPartner d-expansion > .elementHeader .icon {
        background-image: url('/images/data-map/external-connection.svg');
      }

      .homeOffice d-expansion > .elementHeader .icon {
        background-image: url('/images/data-map/homeoffice.svg');
      }

      .printer d-expansion > .elementHeader .icon {
        background-image: url('/images/data-map/printer.svg');
      }

      .personalDataItem d-expansion > .elementHeader .icon {
        background-image: url('/images/data-map/data.svg');
      }

      .elementHeader {
        font-size: 15px;
        font-weight: bold;
        color: hsl(1, 0%, 40%);
        margin-top: -4px;
        margin-bottom: -4px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        transition: all 0.5s;
      }

      .elementHeader .icon {
        width: 32px;
        height: 32px;
        margin-left: -4px;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        flex: none;
        transition: all 0.5s;
      }

      .elementHeader .text {
        flex: 1;
        margin-left: 4px;
      }

      .elementHeader .text > div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap-reverse;
      }

      .elementHeader .buttons {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .elementHeader:hover {
        color: black;
      }

      .mapElement {
        background-color: var(--map-element-background-color);
        border: 2px solid var(--map-element-border-color);
        border-radius: 12px;
        padding: 9px;
        transition: all 0.5s;
      }

      .mapElement.opened {
        padding: 20px 20px 8px 20px;
      }
      .mapElement.ghostElement {
        border-style: dashed;
      }

      .mapElement.ghostElement:hover {
        border-style: solid;
      }

      .mapElement.ghostElement .elementHeader {
        color: hsl(1, 0%, 65%);
        transition: color 0s;
      }

      .mapElement.ghostElement:hover .elementHeader,
      .mapElement.opened.ghostElement .elementHeader {
        color: hsl(1, 0%, 40%);
        transition: color 0s;
      }

      .mapElement.ghostElement .elementHeader .icon {
        opacity: 0.6;
        transition: opacity 0s;
      }

      .mapElement.ghostElement:hover .elementHeader .icon,
      .mapElement.opened.ghostElement .elementHeader .icon {
        opacity: 1;
        transition: opacity 0s;
      }

      .mapElement.ghostElement.opened {
        border-style: solid;
      }

      d-list-section-attachment {
        --backgroundGray: var(--map-element-background-color);
      }

      ul {
        margin: 0;
      }

      .elementType {
        margin-top: 7px;
        flex-grow: 1;
      }

      span .elementName {
        font-size: 15px;
        opacity: 1;
        transition: all 0.5s;
      }
      .elementName {
        font-weight: normal;
      }

      ul.alerts {
        color: #f37e1d;
        list-style-type: disc;
      }

      .details ul li:first-child {
        padding-top: 6px;
      }

      .details ul li:last-of-type {
        padding-bottom: 10px;
      }

      /* Transform element headers when element is opened */

      div.opened .elementHeader {
        font-size: 22px;
        transition: all 0.5s;
        margin-bottom: 10px;
      }

      div.opened .elementHeader .icon {
        width: 64px;
        height: 64px;
        margin-top: -12px;
        margin-left: -10px;
        transition: all 0.5s;
      }

      div.opened .elementHeader div.elementName {
        font-size: 18px;
        opacity: 1;
        transition: all 0.5s;
        min-height: 22px;
      }

      div.opened .elementHeader span.elementName {
        font-size: 1px;
        opacity: 0;
        transition: all 0.5s;
      }
    `,
  ];
  @property({ type: String })
  mode: 'header' | 'view' | 'edit' = 'header';
  @property({ type: Object })
  item!: T;
  @property({ type: Boolean })
  onlyNew = false;
  @property({ type: Boolean })
  elementOpened = false;
  @property({ type: Boolean })
  hideDetails = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  ghost = false;
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: Boolean })
  highlighted?: boolean = false;
  @state()
  protected editItem?: E;
  protected connectionTypes: SelectDropdownOption[] = [
    { value: 'OTHER', text: 'Tjenesten leveres utenfor helsenettet' },
    { value: 'NHN', text: 'Tjenesten leveres gjennom helsenettet' },
  ];

  _internalEditMode(editMode, onlyNew) {
    if (onlyNew) return true;
    return editMode;
  }

  _hideEdit(editMode, writeAccess, onlyNew) {
    if (onlyNew) return true;
    return editMode || !writeAccess;
  }

  _editModeOn() {
    this.editMode = true;
  }

  _hideDetails(hideDetails, elementOpened) {
    return elementOpened || hideDetails;
  }

  _openElement() {
    this.elementOpened = true;
  }

  abstract _displayTypeName();

  abstract _displayName();

  abstract _isValid(): boolean;

  _saveLinkClasses() {
    if (this._isValid()) {
      return 'action';
    } else {
      return 'action disabled';
    }
  }

  renderEditActions() {
    const disabledDelete = this.isDeleteDisabled();
    return html` ${this.onlyNew
        ? nothing
        : html`<d-action class="action delete" ?disabled=${disabledDelete} @click=${(e) => this._delete(e)}
            >Slett</d-action
          >`}
      <d-action class="action" @click=${(e) => this.onCancel(e)}>Avbryt</d-action
      ><d-action class="${this._saveLinkClasses()}" @click=${(e) => this._save(e)} ?disabled=${!this._isValid()}
        >Ferdig</d-action
      >`;
  }

  renderHeader() {
    return html`<d-expansion ?opened="${this.mode === 'header'}">
      <div class="elementHeader" @click=${() => this._openElement()}>
        <div class="icon"></div>
        <div class="text">
          <div>
            <div class="elementType">
              ${this._displayTypeName()}
              ${this.elementOpened ? nothing : html` <span class="elementName">${this._displayName()}</span>`}
            </div>
            ${this.elementOpened
              ? html`<div class="buttons">
                  ${this._internalEditMode(this.editMode, this.onlyNew) ? this.renderEditActions() : nothing}
                  ${this._hideEdit(this.editMode, this.writeAccess, this.onlyNew)
                    ? nothing
                    : html`<d-action class="action" @click=${() => this._editModeOn()}>Rediger</d-action>`}
                  ${this._internalEditMode(this.editMode, this.onlyNew)
                    ? nothing
                    : html`<d-action class="action" @click=${(e) => this.onCancel(e)}>Lukk</d-action>`}
                </div>`
              : nothing}
          </div>
          ${this.elementOpened ? html` <div class="elementName">${this._displayName()}</div> ` : nothing}
        </div>
      </div>
    </d-expansion>`;
  }

  renderDetails() {
    if (this.item === undefined) console.log(this.nodeName);
    if (this.item.alerts === undefined) return html` this.nodeName + ' ' + JSON.stringify(this.item)`;

    return html` <d-expansion
      class="details"
      @click=${() => this._openElement()}
      ?opened=${!this._hideDetails(this.hideDetails, this.elementOpened)}
    >
      <ul class="alerts">
        ${this.item.alerts.map((msg) => html`<li>${msg}</li>`)}
      </ul>
    </d-expansion>`;
  }

  renderUnitView() {
    return html``;
  }

  renderUnitEdit(_editItem: E) {
    return html``;
  }

  renderUnit() {
    return html` <d-expansion ?opened="${this.elementOpened}">
      <div>
        ${this._internalEditMode(this.editMode, this.onlyNew) && this.editItem !== undefined
          ? this.renderUnitEdit(this.editItem)
          : this.renderUnitView()}
      </div>
    </d-expansion>`;
  }

  render() {
    const styles = {
      boxShadow: this.highlighted ? '10px 5px 5px red' : undefined,
    };
    return html`
      <div class="${this.computeElementClasses(this.item.unitType, this.elementOpened)}" style=${styleMap(styles)}>
        ${this.renderHeader()} ${this.renderDetails()} ${this.renderUnit()}

        <slot></slot>
      </div>
    `;
  }

  _getName(options: SelectDropdownOption[], uuid: string): string {
    const e = options.find((x) => x.value === uuid);
    return e?.text ?? '';
  }

  abstract initializeEditItem(): E;

  setOperatorTypeDefaultValues(networkUuid) {
    if (this.editItem) {
      if (networkUuid !== '' && networkUuid !== 'NONE') {
        this.editItem = {
          ...this.editItem,
          systemUpdateOperatorType: 'INHERIT',
          backupOperatorType: 'INHERIT',
          antiVirusOperatorType: 'INHERIT',
        };
      } else {
        this.editItem = {
          ...this.editItem,
          systemUpdateOperatorType: 'UNDEFINED',
          backupOperatorType: 'UNDEFINED',
          antiVirusOperatorType: 'UNDEFINED',
        };
      }
    }
  }

  protected typeClasses() {
    return '';
  }

  protected computeElementClasses(unitType, elementOpened) {
    let classes = 'mapElement ' + this.item.unitType;

    classes += ' ' + this.typeClasses();

    if (elementOpened) {
      classes += ' opened';
    }

    if (this.ghost) {
      classes += ' ghostElement';
    }
    return classes;
  }

  protected onCancel(e: MouseEvent) {
    e.stopPropagation();
    this.elementOpened = false;
    this.editMode = false;
    this.editItem = undefined;
    this.dispatchEvent(new CustomEvent('cancel', { composed: true, bubbles: true }));
  }

  protected operatorTypes() {
    const list = [
      { value: 'UNDEFINED', text: 'Velg driftsmåte' },
      { value: 'NONE', text: 'Ingen' },
    ];
    list.push(
      { value: 'internal', text: 'Driftes internt' },
      { value: 'external', text: 'Driftes av ekstern leverandør' },
      {
        value: 'internalAndExternal',
        text: 'Driftes både internt og eksternt',
      },
    );
    return list;
  }

  protected willUpdate() {
    if (this.editMode && this.editItem === undefined) {
      this.editItem = this.initializeEditItem();
    }
  }

  protected getExtraInformation(): any {
    return undefined;
  }

  protected fireMapElementChanged() {
    if (this.editItem !== undefined) {
      this.dispatchEvent(
        new CustomEvent<{ uuid: string; unitType: T['unitType']; element: E; extra: any }>('map-element-changed', {
          bubbles: true,
          composed: true,
          detail: {
            unitType: this.item.unitType,
            uuid: this.item.uuid,
            element: { ...this.editItem },
            extra: this.getExtraInformation(),
          },
        }),
      );
    }
  }

  protected onNewPartner(e: CustomEvent<{ uuid: string; name: string }>) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; name: string }>('new-partner', {
        composed: true,
        bubbles: true,
        detail: e.detail,
      }),
    );
  }

  protected onNewRiskAssessment(e: CustomEvent<{ uuid: string; name: string }>) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; name: string }>('new-risk-assessment', {
        composed: true,
        bubbles: true,
        detail: e.detail,
      }),
    );
  }

  protected onNewEmployee(e: CustomEvent<{ uuid: string; name: string }>) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; name: string }>('new-employee', {
        composed: true,
        bubbles: true,
        detail: e.detail,
      }),
    );
  }

  protected onNewContract(e: CustomEvent<{ uuid: string; name: string }>) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; name: string }>('new-contract', {
        composed: true,
        bubbles: true,
        detail: e.detail,
      }),
    );
  }

  protected onNewAsset(e: CustomEvent<{ uuid: string; name: string }>) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; name: string }>('new-asset', {
        composed: true,
        bubbles: true,
        detail: e.detail,
      }),
    );
  }

  protected isDeleteDisabled(): boolean {
    return false;
  }

  private _save(e: MouseEvent) {
    e.stopPropagation();
    this.fireMapElementChanged();
    this.elementOpened = false;
    this.editMode = false;
    this.editItem = undefined;
  }

  private _delete(e: MouseEvent) {
    e.stopPropagation();
    this.elementOpened = false;
    this.editMode = false;
    this.editItem = undefined;
    if (!this.isDeleteDisabled()) {
      this.dispatchEvent(
        new CustomEvent<{ uuid: string; unitType: T['unitType'] }>('map-element-deleted', {
          bubbles: true,
          composed: true,
          detail: {
            unitType: this.item.unitType,
            uuid: this.item.uuid,
          },
        }),
      );
    }
  }
}
