/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateAccessCommand
 */
export interface UpdateAccessCommand {
  /**
   *
   * @type {string}
   * @memberof UpdateAccessCommand
   */
  accessLevel: UpdateAccessCommandAccessLevelEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateAccessCommand
   */
  accessExpires?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateAccessCommand
   */
  email: string;
}

/**
 * @export
 */
export const UpdateAccessCommandAccessLevelEnum = {
  None: 'NONE',
  Readonly: 'READONLY',
  User: 'USER',
  Assets: 'ASSETS',
} as const;
export type UpdateAccessCommandAccessLevelEnum =
  (typeof UpdateAccessCommandAccessLevelEnum)[keyof typeof UpdateAccessCommandAccessLevelEnum];

/**
 * Check if a given object implements the UpdateAccessCommand interface.
 */
export function instanceOfUpdateAccessCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'accessLevel' in value;
  isInstance = isInstance && 'email' in value;

  return isInstance;
}

export function UpdateAccessCommandFromJSON(json: any): UpdateAccessCommand {
  return UpdateAccessCommandFromJSONTyped(json, false);
}

export function UpdateAccessCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAccessCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessLevel: json['accessLevel'],
    accessExpires: !exists(json, 'accessExpires') ? undefined : json['accessExpires'],
    email: json['email'],
  };
}

export function UpdateAccessCommandToJSON(value?: UpdateAccessCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    accessLevel: value.accessLevel,
    accessExpires: value.accessExpires === undefined ? undefined : value.accessExpires,
    email: value.email,
  };
}
