import { getWeekdayName, toDayOfWeek, toOrdinal, WeekdayStr } from '../date.js';
import { BaseInstanceRule } from './base-instance-rule.js';
import type { DayOfWeek, LocalDate } from 'src/utilities/local-date.js';

export class MonthlyByNthWeekdayInstanceRule extends BaseInstanceRule {
  private static intervalDescriptions = ['', ' annen', ' tredje', ' fjerde', ' femte', ' sjette'];
  private readonly byWeekDayElement: WeekdayStr;
  private readonly bySetPos: number;
  private readonly dayOfWeek!: DayOfWeek;

  public constructor(byWeekDayElement: WeekdayStr, bySetPos: number) {
    super();
    this.bySetPos = bySetPos;
    this.byWeekDayElement = byWeekDayElement;
    this.dayOfWeek = toDayOfWeek(this.byWeekDayElement);
  }

  public matches(date: LocalDate): boolean {
    if (date.dayOfWeek() === this.dayOfWeek) {
      if (this.bySetPos === -1) {
        const test = date.withLastInMonth(this.dayOfWeek);
        return test.equals(date);
      } else {
        const test = date.withFirstInMonth(this.dayOfWeek).plusWeeks(this.bySetPos - 1);
        return test.equals(date);
      }
    } else {
      return false;
    }
  }

  public optionsToString(interval: number): string {
    return 'FREQ=MONTHLY;INTERVAL=' + interval + ';BYDAY=' + this.byWeekDayElement + ';BYSETPOS=' + this.bySetPos;
  }

  public scheduleDescription(interval: number): string {
    const i = MonthlyByNthWeekdayInstanceRule.intervalDescriptions[interval - 1];
    return 'Hver' + i + ' måned på den ' + toOrdinal(this.bySetPos) + ' ' + getWeekdayName(this.dayOfWeek) + 'en';
  }
}
