import { css } from 'lit';

export const leavePeriodStyles = css`
  div.leave:before,
  a.leave .split:before {
    background: hsl(130, 50%, 60%);
  }

  div.seminar:before,
  a.seminar .split:before {
    background: hsl(220, 50%, 60%);
  }

  div.jobTravel:before,
  a.jobTravel .split:before {
    background: hsl(257, 45%, 59%);
  }

  div.homeOffice:before,
  a.homeOffice .split:before {
    background: hsl(280, 100%, 77%);
  }

  div.sickLeave:before,
  a.sickLeave .split:before {
    background: hsl(324, 93%, 59%);
  }

  div.sickSelf:before,
  a.sickSelf .split:before {
    background: hsl(15, 95%, 49%);
  }

  div.sickChildren:before,
  a.sickChildren .split:before {
    background: hsl(31, 92%, 51%);
  }

  div.timeOff:before,
  a.timeOff .split:before {
    background: hsl(190, 50%, 60%);
  }

  div.vacation:before,
  a.vacation .split:before {
    background: hsl(80, 70%, 50%);
  }

  div.OTHER:before,
  a.OTHER .split:before {
    background: hsl(50, 80%, 60%);
  }
`;
