import { css, html, LitElement } from 'lit';
import '../../library/elements/d-tooltip.js';
import '../../library/elements/d-label.js';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';

/**
 *
 * STATUS OK
 */
@customElement('d-risk-gradient')
export class DRiskGradient extends LitElement {
  static readonly styles = [
    css`
      .riskGradientMask {
        height: 100%;
        width: 100%;
        background: white;
        opacity: 0.8;
        position: absolute;
        right: 0;
      }

      :host {
        display: block;
        margin-top: 10px;
        height: 4px;
        position: relative;
        background: rgba(21, 174, 230, 1);
        background: -moz-linear-gradient(
          left,
          rgb(102, 228, 73) 0%,
          rgba(248, 146, 37, 1) 78%,
          rgba(255, 26, 95, 1) 100%
        );
        background: -webkit-linear-gradient(
          left,
          rgba(102, 228, 73, 1) 0%,
          rgba(248, 146, 37, 1) 78%,
          rgba(255, 26, 95, 1) 100%
        );
        background: -o-linear-gradient(
          left,
          rgba(102, 228, 73, 1) 0%,
          rgba(248, 146, 37, 1) 78%,
          rgba(255, 26, 95, 1) 100%
        );
        background: -ms-linear-gradient(
          left,
          rgba(102, 228, 73, 1) 0%,
          rgba(248, 146, 37, 1) 78%,
          rgba(255, 26, 95, 1) 100%
        );
        background: linear-gradient(
          to right,
          rgb(102, 228, 73) 0%,
          rgba(248, 146, 37, 1) 78%,
          rgba(255, 26, 95, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#15aee6', endColorstr='#ff1a5f', GradientType=1);
      }

      :host {
        height: 12px;
      }

      table {
        height: 100%;
        border-collapse: collapse;
        position: absolute;
        cursor: default;
      }

      table {
        margin-top: 0;
        margin-bottom: -12px;
      }

      table td:first-child {
        border-left: none;
      }

      table td:last-child {
        border-right: none;
      }

      table td {
        cursor: pointer;
        width: 25%;
        text-align: center;
        color: silver;
        padding: 10px 4px 20px 4px;
        min-height: 40px;
        line-height: 15px;
        border: 0;
        font-family: inherit;
        font-size: 100%;
        font-style: inherit;
        font-weight: inherit;
        margin: 0;
        outline: 0;
        vertical-align: top;
      }

      table td {
        padding: 0;
      }

      table td {
        height: 100%;
        border-left: 1px solid white;
        border-right: 1px solid white;
      }

      body:not(.touch) table td:hover,
      table td:active {
        color: black;
      }

      body:not(.touch) :host table td:hover,
      :host table td:active {
        cursor: default;
        color: silver;
      }

      table {
        height: 100%;
        border-collapse: collapse;
        position: absolute;
        cursor: default;
      }
    `,
  ];

  /**
   * The value. A number between 0 and 16.
   */
  @property({ type: Number })
  value = 0;

  render() {
    const riskMaskStyles = { width: 100 - this.value * 6.25 + '%' };

    return html`
      <table cellpadding="0" cellspacing="0" width="100%" role="presentation">
        <tbody>
          <tr>
            <td @click=${() => this.handleClick(1)}></td>
            <td @click=${() => this.handleClick(2)}></td>
            <td @click=${() => this.handleClick(3)}></td>
            <td @click=${() => this.handleClick(4)}></td>
          </tr>
        </tbody>
      </table>

      <div class="riskGradientMask" id="riskMask" style=${styleMap(riskMaskStyles)}></div>
    `;
  }

  private handleClick(index: number) {
    this.dispatchEvent(
      new CustomEvent('index-clicked', {
        bubbles: true,
        composed: true,
        detail: { index: index },
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-risk-gradient': DRiskGradient;
  }
}
