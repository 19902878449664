import { css, html, LitElement, nothing, svg } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { zoomResolution } from 'src/pages/staffing-page/d-staffing-calendar-table.js';

export type LeavePeriodType =
  | 'work'
  | 'sickLeave'
  | 'vacation'
  | 'leave'
  | 'timeOff'
  | 'seminar'
  | 'jobTravel'
  | 'homeOffice'
  | 'sickSelf'
  | 'sickChildren'
  | 'OTHER';

/**
 * Data period exceptions. The other fields of the LeavePeriod are
 * the same as the "parent" LeavePeriod.
 */
export interface LeavePeriodException {
  /** The type. */
  type: LeavePeriodType;
  /** Start of period during day. Should fall inside the "parent" period */
  startMinutes: number;

  /** End of period during day. Should fall inside the "parent" period  and be greater than the start minutes */
  endMinutes: number;
}

/**
 * Basic information about periods of the day.
 */
export interface LeavePeriod {
  /** The type. */
  type: LeavePeriodType;
  /** Is confirmed? Is the period tentative or confirmed? */
  confirmed: boolean;

  /** Start of period during day. Number between 0 and 1439 */
  startMinutes: number;

  /** End of period during day. Number greater than startMinutes and up to 1440 */
  endMinutes: number;

  /** The stack index. Number from 0 to stackCount - 1. If there are
   *  multiple periods displayed then this period may display lower in the stack.
   */
  stackIndex: number;

  /**
   * The stack count. The number of "slots" for periods to be displayed in. Number from 1 and up.
   */
  stackCount: number;

  /** The exceptions. Optional. Some period types may have interleaved work periods. */
  exceptions?: LeavePeriodException[];
}

/**
 * Plus time period.
 */
export interface PlusTimePeriod {
  /** The type. */
  type: 'plusTime';
  /** Is confirmed? Is the period tentative or confirmed? */
  confirmed: boolean;

  /** Start of period during day. Number between 0 and 1439 */
  startMinutes: number;

  /** End of period during day. Number greater than startMinutes and up to 1440 */
  endMinutes: number;
}

/**
 * Period notes
 */
export interface PeriodNotes {
  /** Period type for notes */
  type: LeavePeriodType | 'plusTime';
  /** Is confirmed? Is the period tentative or confirmed? */
  confirmed: boolean;
  /** The notes. Should not be empty. */
  notes: string;
}

/**
 * Data for a single working day for an employee, ie. one day "cell".
 */
export interface StaffingCalendarDataDay {
  /** The date. ISO format */
  date: string;

  /** Is unit start? First day of month and week is unit start. (Could be a calculated property?) */
  unitStart: boolean;

  /** The working periods of the day. */
  workPeriods: { startMinutes: number; endMinutes: number }[];

  /** The plus time periods. */
  plusTimePeriods: PlusTimePeriod[];

  /** The leave periods */
  leavePeriods: LeavePeriod[];

  /** Notes from the periods */
  periodsWithNotes: PeriodNotes[];
}

/**
 * Data for a single employee. Normally the days will be all days for a given year.
 */
export interface StaffingCalendarDataEmployee {
  /** The employee uuid */
  uuid: string;

  /** The data for each day in the period */
  days: StaffingCalendarDataDay[];
}

/**
 * A group of employees.
 */
export interface StaffingCalendarDataGroup {
  /** The employees of the group */
  employees: StaffingCalendarDataEmployee[];
}

/**
 * Data for a period.
 */
export interface Period {
  s: number;
  e: number;
}

export type StartOfDayTime = '00' | '02' | '04' | '06' | '08';

export type EndOfDayTime = '16' | '18' | '20' | '22' | '24';

export type ZoomUnit = 'hour' | 'day';

/**
 * Viser kjernen i tabellen med hver dag for hver ansatt.
 *
 */
@customElement('d-staffing-calendar-data')
export class DStaffingCalendarData extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      background-color: white;
    }

    svg {
      display: block;
      position: relative;
      top: -1px;
      background-color: white;
      cursor: pointer;
    }

    rect.day {
      fill: none;
      pointer-events: fill;
    }

    rect.day:hover {
      fill: black;
      opacity: 0.05;
    }

    rect.vacation {
      fill: hsl(80, 70%, 50%);
    }

    rect.leave {
      fill: hsl(130, 50%, 60%);
    }

    rect.timeOff {
      fill: hsl(190, 50%, 60%);
    }

    rect.seminar {
      fill: hsl(220, 50%, 60%);
    }

    rect.jobTravel {
      fill: hsl(257, 45%, 59%);
    }

    rect.homeOffice {
      fill: hsl(280, 100%, 77%);
    }

    rect.sickLeave {
      fill: hsl(324, 93%, 59%);
    }

    rect.sickSelf {
      fill: hsl(15, 95%, 49%);
    }

    rect.sickChildren {
      fill: hsl(31, 92%, 51%);
    }

    rect.OTHER {
      fill: hsl(50, 80%, 60%);
    }

    rect.unconfirmed {
      mask: url(#mask-stripe);
    }

    rect.work {
      fill: white;
      opacity: 0.7;
    }

    rect.plusTimeTop {
      fill: var(--themeColorDarkerOne);
    }

    rect.plusTime:not(.plusTimeTop) {
      fill: url(#plus-time-gradient);
    }

    .anchor {
      left: 0;
      position: absolute;
      bottom: 0;
    }

    .hoverDayInfo {
      position: absolute;
      flex-direction: column-reverse;
      width: 300px;
      line-height: 150%;
      white-space: normal;
      z-index: 100;
    }

    .hoverDayInfo:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 30px solid white;
      position: absolute;
      left: 135px;
      pointer-events: none;
    }

    .hoverDayInfo > div {
      flex: none;
      background: white;
      text-align: left;
      border-radius: 12px;
      box-shadow: 0 5px 10px hsla(0, 0%, 0%, 0.3);
      padding: 16px 20px;
      line-height: 140%;
      max-height: 100%;
      overflow: hidden;
    }

    .hoverDayInfo .notesList div {
      display: flex;
      margin-top: 10px;
      font-size: 13px;
      font-weight: 200;
      line-height: 140%;
    }

    .hoverDayInfo .notesList div:first-of-type {
      margin-top: 0;
    }

    .hoverDayInfo .notesList > div:before {
      flex: none;
      display: inline-block;
      content: ' ';
      width: 16px;
      height: 16px;
      margin-right: 8px;
      position: relative;
      top: 1px;
    }

    div.vacation:before {
      background-color: hsl(80, 70%, 50%);
    }

    div.leave:before {
      background-color: hsl(130, 50%, 60%);
    }

    div.timeOff:before {
      background-color: hsl(190, 50%, 60%);
    }

    div.seminar:before {
      background-color: hsl(220, 50%, 60%);
    }

    div.jobTravel:before {
      background-color: hsl(257, 45%, 59%);
    }

    div.homeOffice:before {
      background-color: hsl(280, 100%, 77%);
    }

    div.sickLeave:before {
      background-color: hsl(324, 93%, 59%);
    }

    div.sickSelf:before {
      background-color: hsl(15, 95%, 49%);
    }

    div.sickChildren:before {
      background-color: hsl(31, 92%, 51%);
    }

    div.OTHER:before {
      background-color: hsl(50, 80%, 60%);
    }

    div.plusTime:before {
      background-image: linear-gradient(hsl(196, 83%, 90%), hsla(0, 0%, 100%, 0));
      border-top: 4px solid var(--themeColorDarkerOne);
      border-right: 1px solid hsla(1, 0%, 0%, 0.15);
      border-bottom: 1px solid hsla(1, 0%, 0%, 0.15);
      border-left: 1px solid hsla(1, 0%, 0%, 0.15);
      box-sizing: border-box;
    }

    .hoverDayInfo .notesList > div.unconfirmed:before {
      background-image: url(/images/hatched.png);
      background-size: 30px 30px;
    }
  `;

  @property({ type: Array })
  data!: StaffingCalendarDataGroup[];

  @property({ type: Number })
  startOfDayHours = 8;
  @property({ type: Number })
  endOfDayHours = 16;
  @property({ type: String })
  zoom: ZoomUnit = 'day';
  private get pixelsPerHour() {
    return zoomResolution[this.zoom];
  }
  private get hoursPerDay() {
    return this.endOfDayHours - this.startOfDayHours;
  }
  private get pixelsPerDay() {
    return this.pixelsPerHour * this.hoursPerDay;
  }
  private static readonly minutesPerHour = 60;
  private static readonly pixelHeightPerRow = 30;

  workPeriodsSvg(employeeDay: StaffingCalendarDataDay, index: number) {
    const offPeriods: Period[] = [];
    const visibleWorkPeriods = employeeDay.workPeriods
      .map((p) => {
        return {
          s: p.startMinutes / DStaffingCalendarData.minutesPerHour,
          e: p.endMinutes / DStaffingCalendarData.minutesPerHour,
        };
      })
      .filter((p) => {
        return p.s < this.endOfDayHours && p.e > this.startOfDayHours;
      });
    if (visibleWorkPeriods.length) {
      visibleWorkPeriods.forEach((p) => {
        if (p.s < this.endOfDayHours) {
          offPeriods.push({
            s: 0,
            e: Math.min(p.s, this.endOfDayHours) - this.startOfDayHours,
          });
        }
        if (p.e < this.endOfDayHours) {
          offPeriods.push({
            s: Math.max(p.e, this.startOfDayHours) - this.startOfDayHours,
            e: this.endOfDayHours - this.startOfDayHours,
          });
        }
      });
    } else {
      offPeriods.push({
        s: 0,
        e: this.hoursPerDay,
      });
    }
    return offPeriods
      .map((p) => ({
        x: index * this.pixelsPerDay + p.s * this.pixelsPerHour,
        w: (p.e - p.s) * this.pixelsPerHour,
      }))
      .filter((p) => p.w > 0)
      .map(
        (p) =>
          svg`<rect fill="black" opacity="0.1"
        x="${p.x}"
        width="${p.w}"
        height="${DStaffingCalendarData.pixelHeightPerRow}"></rect>`,
      );
  }

  plusTimePeriodsSvg(employeeDay: StaffingCalendarDataDay, index: number) {
    const startOfDayMinutes = this.startOfDayHours * DStaffingCalendarData.minutesPerHour;
    const endOfDayMinutes = startOfDayMinutes + this.hoursPerDay * DStaffingCalendarData.minutesPerHour;

    const periods = employeeDay.plusTimePeriods;

    return periods.map((p) => {
      return this.plusTimePeriodAsSvg(p, startOfDayMinutes, endOfDayMinutes, index);
    });
  }

  renderEmployeeDaySvg(employeeDay: StaffingCalendarDataDay, index: number, employeeIndex: number) {
    if (employeeDay.workPeriods === undefined) {
      console.log(employeeDay, index, employeeIndex);
    }

    return svg`
      ${this.leavePeriodsSvg(employeeDay, index)}
      ${this.plusTimePeriodsSvg(employeeDay, index)}
      ${this.workPeriodsSvg(employeeDay, index)}
      ${this.notesSvg(employeeDay, index)}
      <rect class="day"
      @mouseover=${() => this.showNotes(employeeDay.periodsWithNotes, index, employeeIndex)}
      x="${index * this.pixelsPerDay}"
      width="${this.pixelsPerDay}"
      height="${DStaffingCalendarData.pixelHeightPerRow}"></rect>
      ${this.unitStartSvg(employeeDay, index)}
     `;
  }

  renderEmployee(employee: StaffingCalendarDataEmployee, employeeIndex: number) {
    return employee.days.map((employeeDay, index) => this.renderEmployeeDaySvg(employeeDay, index, employeeIndex));
  }

  render() {
    const groups = this.data.filter((g) => g.employees.length > 0);
    if (groups.length === 0) {
      return nothing;
    }
    const employeeCount = groups.map((g) => g.employees.length).reduce((partialSum, a) => partialSum + a, 0);
    const daysForYear = groups[0].employees[0].days.length;

    return html`
      <svg
        @mouseout=${() => this.hideNotes()}
        id="svg"
        height="${employeeCount * DStaffingCalendarData.pixelHeightPerRow}px"
        width="${daysForYear * this.pixelsPerDay}px"
        viewBox="0 0 ${daysForYear * this.pixelsPerDay} ${employeeCount * DStaffingCalendarData.pixelHeightPerRow}"
        @click=${(e: MouseEvent) => this.handleClick(e)}
      >
        <defs>
          <pattern
            id="grid"
            width="${this.pixelsPerDay}"
            height="${DStaffingCalendarData.pixelHeightPerRow}"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M ${this.pixelsPerDay} 0 L 0 0 0 ${DStaffingCalendarData.pixelHeightPerRow}"
              fill="none"
              stroke="black"
              opacity="0.3"
              stroke-width="1"
            />
          </pattern>
          <pattern
            id="grid-hours"
            width="${this.pixelsPerHour}"
            height="${DStaffingCalendarData.pixelHeightPerRow}"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M ${this.pixelsPerHour} 0 L 0 0 0 ${DStaffingCalendarData.pixelHeightPerRow}"
              fill="none"
              stroke="black"
              opacity="0.07"
              stroke-width="1"
            />
          </pattern>
          <pattern id="pattern-stripe" width="4" height="4" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
            <rect width="3" height="4" transform="translate(0,0)" fill="white"></rect>
          </pattern>
          <mask id="mask-stripe">
            <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
          </mask>
          <linearGradient id="plus-time-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style="stop-color:hsl(196, 83%, 90%);stop-opacity:1" />
            <stop offset="100%" style="stop-color:hsla(0, 0%, 100%, 0);stop-opacity:1" />
          </linearGradient>
        </defs>
        ${groups
          .reduce<{ g: StaffingCalendarDataGroup; c: number }[]>((a, b) => {
            const previousCount = a.length > 0 ? a[a.length - 1].c + a[a.length - 1].g.employees.length : 0;
            return a.concat({ g: b, c: previousCount });
          }, [])
          .map(
            (employeeGroup) => html`
              ${employeeGroup.g.employees.map((employee, index) =>
                this.renderEmployeeRow(employee, index, employeeGroup.c),
              )}
            `,
          )}

        <rect width="100%" height="100%" fill="url(#grid)" style="pointer-events: none;" />
        ${this.zoom === 'hour'
          ? svg`<rect width="100%" height="100%" fill="url(#grid-hours)" style="pointer-events: none;" />`
          : nothing}
        ${this.groupDividersSvg()}
      </svg>
      <div id="anchor" class="anchor"></div>
      ${this.renderNotesPopup()}
    `;
  }

  @state()
  private notes: PeriodNotes[] = [];

  @state()
  private dayIndexForNotes = 0;

  @state()
  private employeeIndexForNotes = 0;

  private renderEmployeeRow(employee: StaffingCalendarDataEmployee, index: number, startIndex: number) {
    return svg`<svg
      y="${(startIndex + index) * DStaffingCalendarData.pixelHeightPerRow}"
      height="${DStaffingCalendarData.pixelHeightPerRow}px"
      width="${employee.days.length * this.pixelsPerDay}px"
      viewBox="0 0 ${employee.days.length * this.pixelsPerDay} ${DStaffingCalendarData.pixelHeightPerRow}"
    >

      ${this.renderEmployee(employee, startIndex + index)}

   </svg>`;
  }

  private clamp(value: number, min: number, max: number): number {
    if (value < min) {
      return min;
    }
    if (value > max) {
      return max;
    }
    return value;
  }

  private leavePeriodsSvg(employeeDay: StaffingCalendarDataDay, index: number) {
    const startOfDayMinutes = this.startOfDayHours * DStaffingCalendarData.minutesPerHour;
    const endOfDayMinutes = startOfDayMinutes + this.hoursPerDay * DStaffingCalendarData.minutesPerHour;
    const periods: LeavePeriod[] = employeeDay.leavePeriods.flatMap((p) => {
      return [
        p,
        ...(p.exceptions ?? []).map((e) => {
          return {
            type: e.type,
            startMinutes: e.startMinutes,
            endMinutes: e.endMinutes,
            confirmed: p.confirmed,
            stackCount: p.stackCount,
            stackIndex: p.stackIndex,
          };
        }),
      ];
    });
    return periods.map((p) => {
      return this.leavePeriodAsSvg(p, startOfDayMinutes, endOfDayMinutes, index);
    });
  }

  private leavePeriodAsSvg(p: LeavePeriod, startOfDayMinutes: number, endOfDayMinutes: number, index: number) {
    const startMinutes = this.clamp(p.startMinutes, startOfDayMinutes, endOfDayMinutes);
    const endMinutes = this.clamp(p.endMinutes, startOfDayMinutes, endOfDayMinutes);
    const durationMinutes = endMinutes - startMinutes;

    if (durationMinutes === 0) {
      return nothing;
    }

    const relativeStartMinutes = startMinutes - startOfDayMinutes;
    const classes = p.type + (p.confirmed ? '' : ' unconfirmed');
    const heightAsFraction = 1 / p.stackCount;
    const topAsFraction = p.stackIndex * heightAsFraction;

    const x =
      index * this.pixelsPerDay + (relativeStartMinutes / DStaffingCalendarData.minutesPerHour) * this.pixelsPerHour;
    return svg`<rect
            class="${classes}"
            x="${x}"
            y="${topAsFraction * DStaffingCalendarData.pixelHeightPerRow}"
            width="${(durationMinutes / DStaffingCalendarData.minutesPerHour) * this.pixelsPerHour}"
            height="${heightAsFraction * DStaffingCalendarData.pixelHeightPerRow}"></rect>`;
  }

  private plusTimePeriodAsSvg(p: PlusTimePeriod, startOfDayMinutes: number, endOfDayMinutes: number, index: number) {
    const startMinutes = this.clamp(p.startMinutes, startOfDayMinutes, endOfDayMinutes);
    const endMinutes = this.clamp(p.endMinutes, startOfDayMinutes, endOfDayMinutes);
    const durationMinutes = endMinutes - startMinutes;
    if (durationMinutes === 0) {
      return nothing;
    }
    const relativeStartMinutes = startMinutes - startOfDayMinutes;
    const classes = p.type + (p.confirmed ? '' : ' unconfirmed');

    return svg`<rect
            class="${classes} plusTimeTop"
            x="${
              index * this.pixelsPerDay +
              (relativeStartMinutes / DStaffingCalendarData.minutesPerHour) * this.pixelsPerHour
            }"
            y="0"
            width="${(durationMinutes / DStaffingCalendarData.minutesPerHour) * this.pixelsPerHour}"
            height="4"></rect>
            <rect
            class="${classes}"
            x="${
              index * this.pixelsPerDay +
              (relativeStartMinutes / DStaffingCalendarData.minutesPerHour) * this.pixelsPerHour
            }"
            y="4"
            width="${(durationMinutes / DStaffingCalendarData.minutesPerHour) * this.pixelsPerHour}"
            height="26"></rect>`;
  }

  private notesSvg(employeeDay: StaffingCalendarDataDay, index: number) {
    return employeeDay.periodsWithNotes.length > 0
      ? svg`<circle
            cy="7" cx="${index * this.pixelsPerDay + 7}" r="3" style="opacity: 0.7"></circle>`
      : nothing;
  }

  private showNotes(periods: PeriodNotes[], index: number, employeeIndex: number) {
    this.dayIndexForNotes = index;
    this.employeeIndexForNotes = employeeIndex;
    this.notes = periods;
  }

  private hideNotes() {
    this.notes = [];
  }

  @query('#anchor')
  private anchor!: HTMLDivElement | null;
  @query('#svg')
  private svg!: SVGElement | null;

  private renderNotesPopup() {
    if (this.anchor === null) {
      return nothing;
    }
    if (this.svg === null) {
      return nothing;
    }
    const anchorBoundingClientRect = this.anchor.getBoundingClientRect();
    const targetBoundingClientRect = this.svg.getBoundingClientRect();
    const notesIndicatorTop = this.employeeIndexForNotes * DStaffingCalendarData.pixelHeightPerRow + 7;
    const notesIndicatorLeft = this.dayIndexForNotes * this.pixelsPerDay + 7;

    const heightOfTooltipArrow = DStaffingCalendarData.pixelHeightPerRow;
    const positionBottom =
      anchorBoundingClientRect.bottom - notesIndicatorTop - targetBoundingClientRect.top + heightOfTooltipArrow;
    const hoverInfoWidthHalf = 150;
    const positionLeft =
      notesIndicatorLeft + 6 + targetBoundingClientRect.left - anchorBoundingClientRect.left - hoverInfoWidthHalf;
    const notes = this.notes.map((note) => {
      if (!note.confirmed) {
        note.type += ' unconfirmed';
      }
      return note;
    });
    return this.notes.length > 0
      ? html` <div class="hoverDayInfo" style="left: ${positionLeft}px; bottom: ${positionBottom}px">
          <div>
            <div class="notesList">
              ${notes.map((notePeriod) => html` <div class="${notePeriod.type}">${notePeriod.notes}</div>`)}
            </div>
          </div>
        </div>`
      : nothing;
  }

  private handleClick(e: MouseEvent) {
    const dayIndex = Math.floor(e.offsetX / this.pixelsPerDay);
    const employeeIndex = Math.floor(e.offsetY / DStaffingCalendarData.pixelHeightPerRow);
    const employees = this.data.flatMap((g) => g.employees);
    const employee = employees[employeeIndex];
    this.dispatchEvent(
      new CustomEvent<{ uuid: string; date: string }>('employee-day-click', {
        composed: true,
        bubbles: true,
        detail: { uuid: employee.uuid, date: employee.days[dayIndex].date },
      }),
    );
  }

  private unitStartSvg(employeeDay: StaffingCalendarDataDay, index: number) {
    return employeeDay.unitStart
      ? svg`<line
              x1="${index * this.pixelsPerDay + 0.5}"
              y1="0"
              x2="${index * this.pixelsPerDay + 0.5}"
              y2="${DStaffingCalendarData.pixelHeightPerRow}"
              stroke="hsla(0, 0%, 0%, 0.3)"></line>`
      : nothing;
  }

  private groupDividersSvg() {
    let y = 0.5;
    return this.data.map((group) => {
      y += group.employees.length * DStaffingCalendarData.pixelHeightPerRow;
      return svg`
        <line x1="0" x2="100%" y1="${y}" y2="${y}" stroke="hsla(0, 0%, 0%, 0.3)"></line>
      `;
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-staffing-calendar-data': DStaffingCalendarData;
  }
}
