import { css, html, LitElement, nothing } from 'lit';
import '../library/elements/d-section.js';
import '../library/fields/d-closer.js';
import '../library/fields/d-spinner-robot.js';
import '../library/editors/elements/d-select-checkbox.js';
import '../library/editors/elements/d-select-radio.js';
import '../library/editors/elements/d-rating.js';
import './d-tutorial-navigator.js';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import type { Option } from 'src/library/editors/elements/d-select-radio.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';

export interface TutorialTest {
  type: string;
  content: string;
  label: string;
  options: Option[];
  correct: string[];
  response: string[];
}

export interface TutorialContentPart {
  state: string;
  title: string;
  content: string;
  test: TutorialTest[];
}

export interface TutorialContent {
  id: string;
  title: string;
  description: string;
  intro: string;
  parts: TutorialContentPart[];
  currentPart: number;
  outro: string;
  rate: boolean;
  rating: number;

  guide: boolean;
}

/**
 * The state of the tutorial for the current user. Detail of the tutorial-state-changed event
 */
export interface TutorialState {
  tutorialId: string;
  currentPart: number;
  partStates: string[];
  rating: number;
}

/**
 *
 */
@customElement('d-tutorial-viewer')
export class DTutorialViewer extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      position: relative;
      background: white;
    }

    .header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      width: 100%;
      min-height: 42px;
      background: white;
      box-sizing: border-box;
      opacity: 1;
      box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.3);
      z-index: 1;
    }

    .header > div:first-child {
      display: flex;
      padding: 0 6px;
    }

    .header > div:last-child {
      padding: 0 20px;
    }

    .header-button {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      cursor: pointer;
    }

    .header-button svg {
      width: 15px;
      height: 15px;
    }

    .header-button:not([disabled]):hover svg rect,
    .header-button:not([disabled]):hover svg polygon {
      fill: black;
    }

    .header-top > div:last-child {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      padding-top: 13px;
      padding-right: 14px;
    }

    .title {
      flex-grow: 1;
      font-size: 15px;
      padding-right: 6px;
      padding-bottom: 10px;
    }

    .other-tutorials {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -6px;
      margin-right: -20px;
      padding-bottom: 6px;
      font-size: 13px;
      text-align: right;
    }

    .other-tutorials > div {
      position: fixed;
      top: 0;
      right: 0;
      padding: 8px 0;
      background-color: white;
      box-shadow: 0 5px 10px hsla(0, 0%, 0%, 0.3);
      z-index: 1;
      width: max-content;
    }

    .other-tutorials span {
      display: block;
      padding: 6px 20px;
      color: var(--themeColor);
      cursor: pointer;
    }

    .other-tutorials span:hover {
      color: black;
    }

    d-tutorial-navigator {
      padding: 12px 0;
      border-top: 1px solid var(--borderColor);
    }

    .state {
      border-top: 1px solid var(--borderColor);
      padding: 12px 0 12px var(--listPaddingLeft);
    }

    .state.passed {
      background: url(/images/check-mini-blue.svg) 0 6px no-repeat;
      background-size: 30px 30px;
    }

    .state.failed {
      background: url(/images/x-fat-orange.svg) 0 6px no-repeat;
      background-size: 30px 30px;
    }

    .state a {
      color: var(--themeColor);
      text-decoration: none;
      cursor: pointer;
    }

    .state a:hover {
      color: black;
    }

    #content {
      min-width: 300px;
      max-width: 1024px;
      box-sizing: border-box;
      padding: 0 30px 200px 30px;
      font-family: var(--mainSerif), serif;
      line-height: 160%;
      color: hsl(0, 0%, 20%);
    }

    #content figure,
    #content img {
      margin-top: 1.2em;
      margin-left: -30px;
      margin-right: -30px;
      width: calc(100% + 60px) !important;
      height: auto;
    }

    @media only screen and (max-width: 400px) {
      #content {
        padding: 0 20px 200px 20px;
      }
      #content figure,
      #content img {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px) !important;
        height: auto;
      }
    }

    #content img {
      margin-bottom: 1em;
      border-top: 1px solid hsl(1, 0%, 80%);
      border-bottom: 1px solid hsl(1, 0%, 80%);
    }

    #content img:first-child {
      margin-top: -1px;
    }

    #content figure img {
      margin: 0;
      width: 100% !important;
      height: auto;
    }

    #content figcaption {
      font-family: var(--mainSans);
      font-size: 14px;
      line-height: 150%;
      padding-top: 6px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 1em;
    }

    #content p,
    #content ul,
    #content ol {
      font-size: 16px;
      margin: 0.5em 0;
    }

    #content ul,
    #content ol {
      padding-left: 24px;
    }

    #content ul li,
    #content ol li {
      margin-bottom: 0.3em;
    }

    #content li ul,
    #content li ol {
      margin-top: 0.3em;
    }

    #content h1 {
      font-size: 28px;
      line-height: 130%;
      font-weight: 500;
      margin-bottom: 0.5em;
    }

    #content img + h1 {
      margin-top: 0;
    }

    #content h2 {
      font-size: 20px;
      line-height: 130%;
      font-weight: 500;
      margin-top: 1em;
      margin-bottom: 0.5em;
    }

    #content p:first-child,
    #content ul:first-child,
    #content ol:first-child,
    #content h2:first-child {
      margin-top: 0;
    }

    #content p:last-child,
    #content ul:last-child,
    #content ol:last-child {
      margin-bottom: 0;
    }

    #content a {
      color: var(--themeColor);
    }

    #content a:hover {
      color: black;
    }

    #content strong,
    #content b {
      font-weight: 500;
    }

    body:not(.touch) #content a:hover {
      color: black;
    }

    #content hr {
      border-width: 0;
    }

    #appContent a[href^="https://trinnvis.no/hjelp"]
    {
      font-weight: 500;
      margin-left: 10px;
      color: var(--themeColor);
    }

    #appContent a[href^="https://trinnvis.no/hjelp"]:before
    {
      content: '? ';
      display: inline-block;
      position: relative;
      top: 1px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: var(--themeColor);
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: white;
      text-align: center;
      margin-right: 4px;
      box-sizing: border-box;
    }

    #appContent .outskirts a[href^="https://trinnvis.no/hjelp"]
    {
      margin-left: 0;
    }

    #appContent .outskirts a[href^="https://trinnvis.no/hjelp"]:before
    {
      display: none;
    }

    :host(:not([touch])) #appContent a[href^="https://trinnvis.no/hjelp"]:hover
    {
      color: var(--themeColorDarkerTwo);
    }

    :host(:not([touch])) #appContent .outskirts a[href^="https://trinnvis.no/hjelp"]:hover
    {
      color: black;
      border-bottom-color: black;
    }

    :host(:not([touch])) #appContent a[href^="https://trinnvis.no/hjelp"]:hover:before
    {
      background: var(--themeColorDarkerTwo);
    }

    #content infobox-attention,
    #content infobox-app-reference {
      display: block;
      position: relative;
      min-height: 54px;
      box-sizing: border-box;
      margin: 20px 0;
      padding: 16px 20px 16px 54px;
      background-color: hsl(196, 83%, 90%);
      font-family: 'Gotham SSm A', 'Gotham SSm B', Verdana, sans-serif;
      line-height: 150%;
      border-radius: 6px;
    }

    #content infobox-attention:before {
      content: '!';
      display: block;
      position: absolute;
      left: 14px;
      top: 12px;
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: hsl(196, 83%, 49%);
      padding-top: 3px;
      text-align: center;
      font-size: 26px;
      line-height: 100%;
      font-weight: 700;
      color: white;
    }

    #content infobox-attention h2,
    #content infobox-app-reference h2 {
      margin-top: 0;
      font-size: 18px;
      font-weight: 500;
    }

    #content infobox-attention p,
    #content infobox-attention ul,
    #content infobox-attention ol,
    #content infobox-app-reference p,
    #content infobox-app-reference ul,
    #content infobox-app-reference ol {
      font-size: 15px;
      margin-bottom: 10px;
    }

    #content infobox-attention p:last-child,
    #content infobox-attention ul:last-child,
    #content infobox-attention ol:last-child,
    #content infobox-app-reference p:last-child,
    #content infobox-app-reference ul:last-child,
    #content infobox-app-reference ol:last-child {
      margin-bottom: 0;
    }

    #content infobox-attention a,
    #content infobox-app-reference a {
      border: none;
      color: hsl(196, 83%, 40%);
      text-decoration: none;
    }

    #content infobox-app-reference a {
      font-size: 18px;
      font-weight: 500;
    }

    #content infobox-app-reference a:hover {
      color: black;
    }

    #content d-spinner-robot {
      display: block;
      top: 12px;
      left: 12px;
      width: 30px;
      height: 30px;
    }

    #content d-spinner-robot + p,
    #content d-spinner-robot + h2 {
      margin-top: 0;
    }

    @media only screen and (min-width: 769px) {
      #content infobox-attention p,
      #content infobox-app-reference p {
        font-size: 15px;
      }
    }

    .test-info {
      margin-bottom: 12px;
    }

    .question {
      font-family: var(--mainSans);
      font-size: 16px;
      font-weight: 500;
    }

    .question-type-info {
      font-family: var(--mainSans);
      font-size: 14px;
      font-weight: 200;
    }

    d-select-radio,
    d-select-checkbox {
      margin-top: 8px;
      font-family: var(--mainSans), sans-serif;
    }

    .system-text {
      font-family: var(--mainSans), sans-serif;
    }

    .button {
      display: inline-block;
      margin-top: 12px;
      padding: 8px 12px;
      background-color: var(--themeColor);
      color: white;
      font-family: var(--mainSans), sans-serif;
      border-radius: 6px;
      cursor: pointer;
    }

    .button.check {
      background-image: url(/images/check-mini-white.svg);
      background-repeat: no-repeat;
      background-size: 26px 26px;
      background-position: 6px 6px;
      padding-left: 36px;
      text-align: left;
    }

    .button:hover {
      background-color: var(--themeColorDarkerOne);
    }

    .feedback {
      margin-top: 10px;
      padding: 50px 0 30px 0;
      background-size: 60px 60px;
      font-family: var(--mainSans), sans-serif;
      text-align: center;
    }

    .feedback.passed {
      background: url(/images/check-mini-blue.svg) 50% 0 no-repeat;
    }

    .feedback.failed {
      background: url(/images/x-fat-orange.svg) 50% 0 no-repeat;
    }

    #content .feedback h2 {
      margin-bottom: 6px;
      font-size: 42px;
      font-weight: bold;
    }

    #content .feedback p {
      margin-bottom: 12px;
    }
  `;
  @property({ type: Object })
  content!: TutorialContent;
  @property({ type: Array })
  availableTutorials: SelectDropdownOption[] = [];
  @property({ type: Boolean })
  showTest = false;
  @property({ type: Boolean })
  showTestFeedback = false;
  @property({ type: Boolean })
  showTutorialsList = false;

  private get multipleActive(): boolean {
    return true;
  }

  private get currentContentPartIndex() {
    return this.content.currentPart - 1;
  }

  private get contentPart(): TutorialContentPart | undefined {
    if (this.content.currentPart > 0) {
      return this.content.parts[this.currentContentPartIndex];
    }
    return undefined;
  }

  private get completed() {
    return (
      this.content.parts.filter((part) => {
        return part.state === 'PASS';
      }).length === this.content.parts.length
    );
  }

  private get almostCompleted() {
    return (
      this.content.parts.filter((part) => {
        return part.state === 'PASS';
      }).length ===
      this.content.parts.length - 1
    );
  }

  private get otherTutorials() {
    return this.availableTutorials.filter((tutorial) => {
      return tutorial.value !== this.content.id;
    });
  }

  _selectTutorial(id) {
    this.dispatchEvent(
      new CustomEvent<{ value?: string }>('update-tutorial', {
        bubbles: true,
        composed: true,
        detail: { value: id },
      }),
    );
    this.showTutorialsList = false;
  }

  _scrollTop() {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: window.scrollX,
        behavior: 'auto',
      });
    }, 0);
  }

  _selectPart(e) {
    this.showTest = false;
    this.showTestFeedback = false;
    this.content = {
      ...this.content,
      currentPart: e.detail,
    };
    this.fireTutorialStateChanged();
    this._scrollTop();
  }

  _showTest(e) {
    e.preventDefault();
    this.showTest = true;
    this.showTestFeedback = false;
    this.showTestFeedback = false;
    this._scrollTop();
  }

  _hideTest(e) {
    e.preventDefault();
    this.showTest = false;
    this._scrollTop();
  }

  _testResponse(index, value) {
    this.content.parts[this.currentContentPartIndex].test[index].response = value;
  }

  _submit(e) {
    e.preventDefault();
    let state = 'PASS';
    this.content.parts[this.currentContentPartIndex].test.forEach((test) => {
      if (test.correct.sort().join(',') !== test.response.sort().join(',')) {
        state = 'FAIL';
      }
    });
    this.content.parts[this.currentContentPartIndex].state = state;
    this.showTestFeedback = true;
    this.fireTutorialStateChanged();
    this._scrollTop();
  }

  _checkPart(e) {
    e.preventDefault();
    this.content.parts[this.currentContentPartIndex].state = 'PASS';
    this.content = { ...this.content };
    let nextPart = -1;
    if (this.completed) {
      nextPart = this.content.parts.length + 1;
    } else {
      this.content.parts.forEach((part, index) => {
        if (part.state !== 'PASS' && nextPart === -1) {
          nextPart = index + 1;
        }
      });
    }
    this.content = {
      ...this.content,
      currentPart: nextPart,
    };
    this.fireTutorialStateChanged();
    this._scrollTop();
  }

  _next(e) {
    e.preventDefault();
    if (this.currentContentPartIndex > -1 && this.content.parts[this.currentContentPartIndex].test.length === 0) {
      this.content.parts[this.currentContentPartIndex].state = 'PASS';
    }
    this.content = {
      ...this.content,
      currentPart: this.content.currentPart + 1,
    };
    this.showTest = false;
    this.showTestFeedback = false;
    this.fireTutorialStateChanged();
    this._scrollTop();
  }

  _linkClick(event) {
    const anchor = event.target.closest('a');
    if (anchor && anchor.href.startsWith('https://app.trinnvis.no/p/')) {
      event.preventDefault();
      const value = anchor.href.replace('https://app.trinnvis.no/p/', '');
      const split = value.split('?');
      const pageNumber = split[0];
      const n = Number(pageNumber);
      const qs = split[1] ?? '';
      this.fireOpenPage(n, qs);
    }
  }

  _rate(e) {
    this.content = {
      ...this.content,
      rating: e.detail.value,
    };
    this.fireTutorialStateChanged();
  }

  renderNavigator() {
    const navigatorParts = this.content.parts.map((part) => {
      return {
        state: part.state,
      };
    });
    return html`
      <d-tutorial-navigator
        .intro=${this.content.intro !== ''}
        .parts=${navigatorParts}
        .current=${this.content.currentPart}
        @select=${(e) => this._selectPart(e)}
      ></d-tutorial-navigator>
    `;
  }

  renderOutro() {
    let finishedHeading = 'Bestått';
    if (this.content.guide) {
      finishedHeading = 'Ferdig';
    }
    return html`
      <div class="feedback system-text passed">
        <h2>${finishedHeading}</h2>
        ${unsafeHTML(
          this.content.outro.replaceAll(
            'href="https://app.trinnvis.no/p',
            'class="appLink" href="https://app.trinnvis.no/p',
          ),
        )}
        ${this.content.rate ? html` <d-rating @value-changed=${(e) => this._rate(e)}></d-rating> ` : nothing}
        <div class="button" @click=${(e) => this.onClose(e)}>Lukk</div>
      </div>
    `;
  }

  renderTest() {
    if (this.contentPart) {
      return html`
        ${this.showTestFeedback
          ? html`
              ${this.contentPart.state === 'PASS'
                ? html`
                    ${this.completed
                      ? html` ${this.renderOutro()} `
                      : html`
                          <h1>${this.contentPart.title}</h1>
                          <div class="feedback system-text passed">
                            <h2>Bestått</h2>
                            ${this.currentContentPartIndex < this.content.parts.length - 1
                              ? html` <div class="button" @click=${(e) => this._next(e)}>Neste</div> `
                              : nothing}
                          </div>
                        `}
                  `
                : html` <div class="feedback system-text failed">
                    <h2>Ikke bestått</h2>
                    <a class="system-text" href="#" @click=${(e) => this._hideTest(e)}>Tilbake til teksten</a>
                  </div>`}
            `
          : html` <h1>Spørsmål til ${this.contentPart.title}</h1>
              ${this.contentPart.test.map(
                (t, index) => html`
                  <d-section>
                    <div>
                      ${t.content
                        ? html` <d-view-info class="test-info" .content=${t.content}></d-view-info> `
                        : nothing}
                      ${t.type === 'SINGLE'
                        ? html`
                            <div class="question">${t.label}</div>
                            <div class="question-type-info">Velg ett svar</div>
                            <d-select-radio
                              system-content
                              vertical
                              wrap-label
                              .options=${t.options}
                              @value-changed=${(e) => this._testResponse(index, [e.detail.value])}
                            ></d-select-radio>
                          `
                        : nothing}
                      ${t.type === 'MULTIPLE'
                        ? html`
                            <div class="question">${t.label}</div>
                            <div class="question-type-info">Velg ett eller flere svar</div>
                            <d-select-checkbox
                              system-content
                              vertical
                              wrap-label
                              .options=${t.options}
                              @value-changed=${(e) => this._testResponse(index, e.detail.value)}
                            ></d-select-checkbox>
                          `
                        : nothing}
                    </div>
                  </d-section>
                `,
              )}
              <d-section>
                <d-wrap split baseline>
                  <a class="system-text" href="#" @click=${(e) => this._hideTest(e)}>Tilbake til teksten</a>
                  <div>
                    <div class="button" @click=${(e) => this._submit(e)}>Ferdig</div>
                  </div>
                </d-wrap>
              </d-section>
              <p></p>`}
      `;
    }
    return nothing;
  }

  renderFeedback() {
    if (this.contentPart) {
      let passedText = 'Du har bestått denne delen.';
      if (this.contentPart.test.length === 0) {
        passedText = 'Denne delen er markert som ferdig.';
      }
      return html`
        ${this.contentPart && !this.showTestFeedback && this.contentPart.state === 'PASS'
          ? html` <div class="state passed">${passedText}</div>`
          : nothing}
        ${this.contentPart && !this.showTestFeedback && this.contentPart.state === 'FAIL'
          ? html` <div class="state failed">
              Du har ikke bestått denne delen. <a href="#" @click=${(e) => this._showTest(e)}>Prøv igjen</a>
            </div>`
          : nothing}
      `;
    }
    return nothing;
  }

  renderContentPart() {
    if (this.contentPart) {
      return html`
        ${this.showTest
          ? this.renderTest()
          : html`
              ${unsafeHTML(
                this.contentPart.content
                  .replaceAll('href="https://app.trinnvis.no/p', 'class="appLink" href="https://app.trinnvis.no/p')
                  .replaceAll(
                    '<infobox-app-reference>',
                    '<infobox-app-reference><d-spinner-robot size="30" gesture="blink|peekRight|happy"></d-spinner-robot>',
                  ),
              )}
              ${this.contentPart.test.length > 0
                ? html`
                    ${this.contentPart.state !== 'PASS'
                      ? html`
                          <div style="text-align: center">
                            <div class="button" @click=${(e) => this._showTest(e)}>Gå til spørsmål</div>
                          </div>
                        `
                      : nothing}
                  `
                : html`
                    ${this.contentPart.state !== 'PASS'
                      ? html`
                          <div style="text-align: center">
                            <div class="button check" @click=${(e) => this._checkPart(e)}>
                              Ferdig${this.almostCompleted ||
                              this.currentContentPartIndex === this.content.parts.length - 1
                                ? nothing
                                : ' med denne delen, gå til neste'}
                            </div>
                          </div>
                        `
                      : nothing}
                  `}
            `}
      `;
    }
    return nothing;
  }

  renderTutorialSelector() {
    if (this.otherTutorials.length > 0) {
      return html`
        <div class="other-tutorials">
          ${this.otherTutorials.length === 1
            ? html`
                <span @click=${() => this._selectTutorial(this.otherTutorials[0].value)}>
                  ${this.otherTutorials[0].text}
                </span>
              `
            : html`
                <span @click=${() => (this.showTutorialsList = true)}>Aktive kurs og veiledere</span>
                ${this.showTutorialsList
                  ? html`
                      <div id="tutorialslist" @mouseleave=${() => (this.showTutorialsList = false)}>
                        ${this.otherTutorials.map((tutorial) => {
                          return html`
                            <div>
                              <span @click=${() => this._selectTutorial(this.otherTutorials[0].value)}
                                >${tutorial.text}</span
                              >
                            </div>
                          `;
                        })}
                      </div>
                    `
                  : nothing}
              `}
        </div>
      `;
    }
    return nothing;
  }

  render() {
    return html`
      <div class="header">
        <div class="header-top">
          <div class="header-button closer" @click=${this.onClose}>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 10 10"
              enable-background="new 0 0 10 10"
            >
              <rect
                class="closerElement"
                x="4.214"
                y="-1.285"
                transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 4.9988 12.0706)"
                fill="hsl(196, 83%, 49%)"
                width="1.571"
                height="12.571"
              ></rect>
              <rect
                class="closerElement"
                x="4.215"
                y="-1.286"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.0708 5)"
                fill="hsl(196, 83%, 49%)"
                width="1.571"
                height="12.571"
              ></rect>
            </svg>
          </div>
          <div>
            <div class="title">${this.content.title}</div>
            ${this.multipleActive ? html` ${this.renderTutorialSelector()} ` : nothing}
          </div>
        </div>
        <div>${this.renderNavigator()} ${this.renderFeedback()}</div>
      </div>
      <div id="content" @click=${this._linkClick}>${this.renderContent()}</div>
    `;
  }

  private renderContent() {
    if (this.content.currentPart === 0) {
      return html`
        ${unsafeHTML(
          this.content.intro
            .replaceAll('href="https://app.trinnvis.no/p', 'class="appLink" href="https://app.trinnvis.no/p')
            .replaceAll(
              '<infobox-app-reference>',
              '<infobox-app-reference><d-spinner-robot size="30" gesture="blink|peekRight|happy"></d-spinner-robot>',
            ),
        )}
        <div style="text-align: center">
          <div class="button" @click=${(e) => this._next(e)}>Start</div>
        </div>
      `;
    } else if (this.content.currentPart === this.content.parts.length + 1) {
      return html` ${this.renderOutro()} `;
    } else {
      return html` ${this.renderContentPart()} `;
    }
  }

  private onClose(e: Event) {
    e.preventDefault();
    this.dispatchEvent(new CustomEvent<{ value: boolean }>('close-viewer', { bubbles: true, composed: true }));
    setTimeout(() => {
      this.dispatchEvent(
        new CustomEvent<{ value: boolean }>('toggle-tutorial', {
          bubbles: true,
          composed: true,
          detail: { value: false },
        }),
      );
    }, 500);
  }

  private fireOpenPage(n: number, qs: string) {
    this.dispatchEvent(
      new CustomEvent<{ pageId: number; queryString: string }>('open-page', {
        bubbles: true,
        composed: true,
        detail: { pageId: n, queryString: qs },
      }),
    );
  }

  private fireTutorialStateChanged() {
    const detail: TutorialState = {
      tutorialId: this.content.id,
      currentPart: this.content.currentPart,
      partStates: this.content.parts.map((p) => p.state),
      rating: this.content.rating,
    };
    this.dispatchEvent(
      new CustomEvent<TutorialState>('tutorial-state-changed', { bubbles: true, composed: true, detail: detail }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-tutorial-viewer': DTutorialViewer;
  }
}
