/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkScheduleWeek } from './WorkScheduleWeek';
import { WorkScheduleWeekFromJSON, WorkScheduleWeekFromJSONTyped, WorkScheduleWeekToJSON } from './WorkScheduleWeek';

/**
 *
 * @export
 * @interface WorkSchedule
 */
export interface WorkSchedule {
  /**
   *
   * @type {string}
   * @memberof WorkSchedule
   */
  start: string;
  /**
   *
   * @type {string}
   * @memberof WorkSchedule
   */
  restPeriod: WorkScheduleRestPeriodEnum;
  /**
   *
   * @type {boolean}
   * @memberof WorkSchedule
   */
  holidaysAreTimeOff: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WorkSchedule
   */
  workHoursDefinedPerDay: boolean;
  /**
   *
   * @type {string}
   * @memberof WorkSchedule
   */
  workDayStartTime?: string;
  /**
   *
   * @type {string}
   * @memberof WorkSchedule
   */
  workDayEndTime?: string;
  /**
   *
   * @type {Array<WorkScheduleWeek>}
   * @memberof WorkSchedule
   */
  workWeeks: Array<WorkScheduleWeek>;
}

/**
 * @export
 */
export const WorkScheduleRestPeriodEnum = {
  None: 'NONE',
  WithPay: 'WITH_PAY',
  WithoutPay: 'WITHOUT_PAY',
} as const;
export type WorkScheduleRestPeriodEnum = (typeof WorkScheduleRestPeriodEnum)[keyof typeof WorkScheduleRestPeriodEnum];

/**
 * Check if a given object implements the WorkSchedule interface.
 */
export function instanceOfWorkSchedule(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'start' in value;
  isInstance = isInstance && 'restPeriod' in value;
  isInstance = isInstance && 'holidaysAreTimeOff' in value;
  isInstance = isInstance && 'workHoursDefinedPerDay' in value;
  isInstance = isInstance && 'workWeeks' in value;

  return isInstance;
}

export function WorkScheduleFromJSON(json: any): WorkSchedule {
  return WorkScheduleFromJSONTyped(json, false);
}

export function WorkScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkSchedule {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    start: json['start'],
    restPeriod: json['restPeriod'],
    holidaysAreTimeOff: json['holidaysAreTimeOff'],
    workHoursDefinedPerDay: json['workHoursDefinedPerDay'],
    workDayStartTime: !exists(json, 'workDayStartTime') ? undefined : json['workDayStartTime'],
    workDayEndTime: !exists(json, 'workDayEndTime') ? undefined : json['workDayEndTime'],
    workWeeks: (json['workWeeks'] as Array<any>).map(WorkScheduleWeekFromJSON),
  };
}

export function WorkScheduleToJSON(value?: WorkSchedule | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    start: value.start,
    restPeriod: value.restPeriod,
    holidaysAreTimeOff: value.holidaysAreTimeOff,
    workHoursDefinedPerDay: value.workHoursDefinedPerDay,
    workDayStartTime: value.workDayStartTime,
    workDayEndTime: value.workDayEndTime,
    workWeeks: (value.workWeeks as Array<any>).map(WorkScheduleWeekToJSON),
  };
}
