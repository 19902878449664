import { css, html, LitElement, nothing } from 'lit';

import '../library/fields/d-view-html.js';
import '../library/elements/d-action.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { customElement, property } from 'lit/decorators.js';
import '../library/elements/d-smooth-resize.js';
import { LocalDateTime } from 'src/utilities/local-date-time.js';
import { calculateUpdateStatus } from 'src/models/resolvers/calculate-update-status.js';

export interface UpdateSectionItem {
  templateDeletedMessage: string;
  contentLastModifiedBy: string;
  hasTemplateDelete: boolean;
  contentLastModifiedWasCreate: boolean;
  templateUpdated: string;
  templateDeleted: string;
  templateDeclined: string;
  hasTemplateUpdate: boolean;
  contentLastModified: string;

  uuid: string;
}

/**
 *
 */
@customElement('d-update-section')
export class DUpdateSection extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    #revisionFieldWrapper {
      margin: 20px 0;
    }

    :host([help-section-present]) #revisionFieldWrapper {
      margin-top: 0;
    }

    #revisionField {
      min-height: 38px;
      position: relative;
      max-width: 724px;
      box-sizing: border-box;
      padding: 16px 20px 14px 20px;
      border-radius: 20px;
      word-wrap: normal;
    }

    #revisionField.templateDeleted {
      background-color: hsl(20, 100%, 96%);
      border: 1px solid hsl(20, 100%, 90%);
    }

    #revisionField.templateUpdated {
      background-color: hsl(190, 60%, 94%);
      border: 1px solid hsl(190, 60%, 88%);
    }

    #revisionField.documentUpdated {
      background-color: hsl(100, 80%, 92%);
      border: 1px solid hsl(100, 80%, 80%);
      padding: 8px 8px 0 13px;
    }

    .revisionHeader:before {
      display: inline-block;
      content: '';
      margin-left: -2px;
      margin-right: 4px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: relative;
      top: 2px;
    }

    .templateDeleted .revisionHeader:before {
      background-color: var(--alertColor);
    }

    .templateUpdated .revisionHeader:before {
      background-color: var(--themeColorDarkerOne);
    }

    .documentUpdated .revisionHeader:before {
      background-color: hsl(100, 80%, 40%);
    }

    .revisionHeader .documentUpdated {
      white-space: normal;
    }

    .revisionHeader .title {
      font-weight: 600;
    }

    d-view-info {
      margin-top: 12px;
    }

    .actions {
      margin: 6px -8px -6px -8px;
    }
  `;
  @property({ type: Boolean })
  opened = false;
  @property({ type: Boolean, attribute: 'help-section-present', reflect: true })
  helpSectionPresent = false;
  @property({ type: Boolean })
  noAnimation = false;
  @property({ type: String })
  entityType = '';
  @property({ type: String })
  entityId = '';
  @property({ type: Object })
  item!: UpdateSectionItem;
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: String })
  type = '';
  @property({ type: String })
  title = '';
  @property({ type: Object })
  today!: LocalDate;
  @property({ type: String })
  dateTime = '';
  @property({ type: String })
  byline = '';
  @property({ type: String })
  revisionContent = '';

  private get updateSectionItem() {
    const status = calculateUpdateStatus(this.item, this.today);

    if (status === 'templateDeleted') {
      return {
        type: 'templateDeleted',
        title: 'Foreslått sletting',
        dateTime: this._formatDateTime(this.item.templateDeleted),
        byline: 'av TrinnVis-teamet',
        revisionContent: this.item.templateDeletedMessage,
        opened: true,
      };
    } else if (status === 'templateUpdated') {
      return {
        type: 'templateUpdated',
        title: 'Foreslått oppdatering',
        dateTime: this._formatDateTime(this.item.templateUpdated),
        byline: 'av TrinnVis-teamet',
        revisionContent: '',
        opened: true,
      };
    } else if (
      this.item.contentLastModified !== '' &&
      this.today.minusDays(30).isBefore(LocalDate.fromString(this.item.contentLastModified))
    ) {
      let updateTerm = 'Oppdatert';
      if (this.item.contentLastModifiedWasCreate) {
        updateTerm = 'Opprettet';
      }
      let byline = 'av ' + this.item.contentLastModifiedBy;
      if (this.singleUserVersion && this.item.contentLastModifiedBy !== 'TrinnVis-teamet') {
        byline = 'av meg';
      }
      return {
        type: 'documentUpdated',
        title: updateTerm,
        dateTime: this._formatRelativeDays(LocalDate.fromString(this.item.contentLastModified)),
        byline: byline,
        revisionContent: '',
        opened: true,
      };
    } else {
      return {
        type: '',
        title: '',
        dateTime: '',
        byline: '',
        revisionContent: '',
        opened: false,
      };
    }
  }

  _formatRelativeDays(date: LocalDate) {
    const days = this.today.toEpochDay() - date.toEpochDay();
    let timeText = 'i dag';
    if (days === 1) {
      timeText = 'i går';
    }
    if (days === 2) {
      timeText = 'i forgårs';
    }
    if (days === 3) {
      timeText = 'for tre dager siden';
    }
    if (days === 4) {
      timeText = 'for fire dager siden';
    }
    if (days === 5) {
      timeText = 'for fem dager siden';
    }
    if (days === 6) {
      timeText = 'for seks dager siden';
    }
    if (days === 7) {
      timeText = 'for én uke siden';
    }
    if (days > 7) {
      timeText = 'for ' + days + ' dager siden';
    }
    return timeText;
  }

  _hostClasses(opened) {
    if (opened) {
      return 'opened';
    }
    return '';
  }

  _formatDateTime(dateTime) {
    return LocalDateTime.fromString(dateTime.replace('T', ' ')).toStringForDisplay();
  }

  _view() {
    this.dispatchEvent(new CustomEvent('view-suggestion', { bubbles: true, composed: true }));
  }

  _delete() {
    this.dispatchEvent(new CustomEvent('delete', { bubbles: true, composed: true }));
  }

  _decline() {
    this.dispatchEvent(
      new CustomEvent('decline', {
        bubbles: true,
        composed: true,
        detail: {
          entityType: this.entityType,
          entityId: this.entityId,
        },
      }),
    );
    this.opened = false;
  }

  infoContent(item) {
    if (item.type === 'templateDeleted') {
      return (
        item.revisionContent +
        '<p><i>Om du fjerner dette varselet, vil forslaget til sletting fortsatt ligge i versjonslisten.</i></p>'
      );
    }
    if (item.type === 'templateUpdated') {
      return (
        '<p>Det foreligger et forslag fra TrinnVis-teamet til oppdatering av dette dokumentet.</p>' +
        '<p><i>Selv om du fjerner dette varselet, vil nyeste forslag fra TrinnVis-teamet alltid ligge i versjonslisten.</i></p>'
      );
    }
    return '';
  }

  render() {
    const updateSectionItem = this.updateSectionItem;
    if (updateSectionItem.type === '') {
      return nothing;
    }
    return html`
      <d-smooth-resize>
        <div id="revisionFieldWrapper">
          <div class="${updateSectionItem.type}" id="revisionField">
            <div class="revisionHeader">
              <span class="title">${updateSectionItem.title}</span>
              <span class="noWrap">${updateSectionItem.dateTime} </span>
              <span class="noWrap">${updateSectionItem.byline}</span>
            </div>
            ${this.infoContent(updateSectionItem) !== ''
              ? html`<d-view-info .content=${this.infoContent(updateSectionItem)}></d-view-info>`
              : nothing}
            ${updateSectionItem.type === 'templateDeleted'
              ? html`
                  <div class="actions">
                    <d-action delete @click=${() => this._delete()}>Slett</d-action>
                    <d-action @click=${() => this._decline()}>Fjern varsel</d-action>
                  </div>
                `
              : nothing}
            ${updateSectionItem.type === 'templateUpdated'
              ? html`
                  <div class="actions">
                    <d-action @click=${() => this._view()}>Vis forslag</d-action>
                    <d-action @click=${() => this._decline()}>Fjern varsel</d-action>
                  </div>
                `
              : nothing}
          </div>
        </div>
      </d-smooth-resize>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-update-section': DUpdateSection;
  }
}
