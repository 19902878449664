import { ContractView, ContractViewEditItem } from 'src/content/contracts/d-contract-view.js';
import {
  fetchDraft,
  fetchRevisions,
  pagesAsSelectTagOptions,
  toAttachmentItems,
} from 'src/models/factory-utilities.js';
import { BASE_PATH } from 'src/store/api';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { getOrganization } from 'src/store/selectors/organization.js';
import { employeesNotDeleted } from 'src/store/selectors/organization-employees.js';
import { partnersNotDeleted } from 'src/store/selectors/organization-partners.js';
import { toContractViewModel } from 'src/store/selectors/organization-documents.js';
import { currentUserUuid, writeAccess } from 'src/store/selectors/user.js';
import { key } from 'src/store/selectors/selectors';
import { assertIsDefined } from 'src/lib';
import { CommonDataEntityView } from 'src/content/entity-content';

export async function buildContractView(
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<ContractView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const availablePages = pagesAsSelectTagOptions(state);
  const item = organization.contractsById[uuid];
  assertIsDefined(item);

  const entity = toContractViewModel(item, organization);

  return {
    ...commonData,
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess:
      entity.classification === 'NONE' || (entity.accessControl ?? []).includes(currentUserUuid(state) ?? ''),
    currentUserHasWriteAccess: writeAccess(state),

    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: entity.helpContent ?? '',
    href: currentParent + '/contracts/' + uuid,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/contracts/' + uuid + '.pdf?key=' + key(state),

    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'contracts',
    name: entity.name,
    classification: entity.classification ?? 'NONE',
    accessControl: entity.accessControl ?? [],
    content: entity.content ?? '',
    revisions: await fetchRevisions(
      state.token ?? '',
      'contracts',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
    ),
    attachments: toAttachmentItems('contracts', entity.uuid, entity.attachments),
    validFromDate: entity.validFromDate ?? '',
    validToDate: entity.validToDate ?? '',
    contractPartyNames: entity.contractPartyNames ?? '',
    checklist: undefined, // TODO entity.checklist === null ? undefined : entity.checklist,
    availablePages: availablePages,
    relationsLocked: entity.relationsLocked ?? false,
    availablePartners: partnersNotDeleted(state)
      .filter((p) => p.isConfirmedEntity)
      .map((p) => ({ value: p.uuid, text: p.name })),
    availableEmployees: employeesNotDeleted(state)
      .filter((e) => e.status !== 'TERMINATED' && e.isConfirmedEntity)
      .map((p) => ({ value: p.uuid, text: p.name })),
    employees: entity.employees ?? [],
    partners: entity.partners ?? [],
    pages: (entity.pages ?? []).map((x) => '' + x),
    today: LocalDate.fromString(state.today),
    deletable: true,
    signingOrders: entity.signingOrders ?? [],
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(state.token ?? '', 'contracts', uuid, organization.organizationId);
      return command.draft as ContractViewEditItem;
    },
  };
}
