import { sortBy } from 'lodash';
import { LocalDate } from 'src/utilities/local-date.js';
import { numberWord } from 'src/utilities/text.js';
import type { EntityViewModel, ItemsByTypeAndId, OrganizationState } from '../types.js';
import type { ContractViewModel } from 'src/store/api';

export function flatMap<T, U>(array: T[], callbackfn: (value: T, index: number, array: T[]) => U[]): U[] {
  return Array.prototype.concat(...array.map(callbackfn));
}

export function _sortByName(
  a: { name?: string | null } | null | undefined,
  b: { name?: string | null } | null | undefined,
): number {
  const an = (a || {}).name ?? '';
  const bn = (b || {}).name ?? '';
  return an.toLowerCase().localeCompare(bn.toLowerCase());
}

export function _formatDate(value: string | Date | undefined | null): string {
  if (value === undefined || value === null) {
    return 'Uten dato';
  } else if (typeof value === 'string') {
    if (value === '') {
      return 'Uten dato';
    } else {
      return LocalDate.fromString(value).toStringForDisplay();
    }
  } else {
    return LocalDate.fromString(value.toISOString()).toStringForDisplay();
  }
}

export function _sortByFirstAndLastName(
  a: { firstName: string; lastName: string },
  b: { firstName: string; lastName: string },
): number {
  const nameForA = a.firstName + ' ' + a.lastName;
  const nameForB = b.firstName + ' ' + b.lastName;

  return nameForA.toLowerCase().localeCompare(nameForB.toLowerCase());
}

export function _sortByValue(a: { value: string }, b: { value: string }): number {
  return a.value.toLowerCase().localeCompare(b.value.toLowerCase());
}

export function toList<T>(o: { [uuid: string]: T }): T[] {
  return Object.values(o).filter(function (e) {
    return e !== undefined;
  });
}

export function isCurrentUser(email?: string, username?: string): boolean {
  if (!email || !username) {
    return false;
  }

  return email.toUpperCase() === username.toUpperCase();
}

export function _filterByPartnerId(list?: ContractViewModel[], uuid?: string): ContractViewModel[] {
  if (list === undefined || uuid === undefined) {
    return [];
  }

  return sortBy(
    list.filter(function (item) {
      if (item.partners === undefined) {
        return false;
      }
      return item.partners.includes(uuid);
    }),
    (e) => e.name.toLowerCase(),
  );
}

export function itemsByType(organization: OrganizationState, entityType: string): { [key: string]: EntityViewModel } {
  switch (entityType) {
    case 'assets':
      return organization.assetsById;
    case 'substances':
      return organization.substancesById;
    case 'employees':
      return organization.employeesById;
    case 'functions':
      return organization.functionsById;
    case 'issues':
      return organization.issuesById;
    case 'partners':
      return organization.partnersById;
    case 'constitutionalDocuments':
      return organization.constitutionalDocumentsById;
    case 'computers':
      return organization.computersById;
    case 'contracts':
      return organization.contractsById;
    case 'guidelines':
      return organization.guidelinesById;
    case 'networks':
      return organization.networksById;
    case 'documents':
      return organization.documentsById;
    case 'externalConnections':
      return organization.externalConnectionsById;
    case 'personalDataItems':
      return organization.personalDataItemsById;
    case 'riskAssessments':
      return organization.riskAssessmentsById;
    case 'reports':
      return organization.reportsById;
    case 'contacts':
      return organization.contactsById;
    case 'tasks':
      return organization.tasksById;
    case 'meetings':
      return organization.meetingsById;
  }
  throw new Error('Illegal state (E905), entity type: ' + entityType);
}

export function isEmailValid(email: string): boolean {
  const r =
    /^[a-z0-9\u007F-\uffff!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9\u007F-\uffff!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,}$/i;
  return r.test(email);
}

export function groupBy<T>(list: T[], keyGetter: (e: T) => string): { group: string; order: number; items: T[] }[] {
  const map = new Map<string, T[]>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map, ([key, value]) => {
    return { group: key, items: value, order: 0 };
  });
}

export function arrayFind<T>(array: T[], p: (e: T) => boolean): T | undefined {
  return array.find(p);
}

function prop<Type, Key extends keyof Type>(obj: Type, key: Key) {
  return obj[key];
}

function getTaskData(uuid: string, itemsByTypeAndId: ItemsByTypeAndId, singleUserVersion: boolean) {
  const tasksById = prop(itemsByTypeAndId, 'tasks');
  const task = tasksById[uuid];
  const functionsById = prop(itemsByTypeAndId, 'functions');
  const fId = task.functionUuid;
  if (fId === undefined) throw new Error('Illegal state (E910), no parent reference ' + JSON.stringify(task));
  const parent = functionsById[fId];
  let responsible;
  if (task.responsibleFunctionUuid) {
    responsible = functionsById[task.responsibleFunctionUuid];
  }
  let target;
  const name = task.name;
  const startTask = task.locked;
  if (startTask) {
    target = uuid;
  } else if (!singleUserVersion) {
    target = parent.pages[0] + '/functions/' + parent.uuid + '/tasks/' + uuid;
  } else if (task.pages && task.pages.length > 0) {
    target = task.pages[0] + '/tasks/' + uuid;
  } else if (parent.type !== 'COMMON') {
    target = parent.pages[0] + '/tasks/' + uuid;
  } else if (responsible) {
    target = responsible.pages[0] + '/tasks/' + uuid;
  } else {
    target = '357' + '/tasks/' + uuid;
  }
  return { target: target, startTask: startTask, name: name };
}

export function getItemDataFromTemplateId(n: string, itemsByTypeAndId: ItemsByTypeAndId, singleUserVersion: boolean) {
  let target = '';
  let startTask = false;
  let name = '';

  const source: Pick<
    ItemsByTypeAndId,
    | 'contracts'
    | 'documents'
    | 'guidelines'
    | 'functions'
    | 'reports'
    | 'constitutionalDocuments'
    | 'riskAssessments'
    | 'tasks'
    | 'meetings'
  > = itemsByTypeAndId;

  const a: (keyof typeof source)[] = [
    'contracts',
    'documents',
    'guidelines',
    'functions',
    'reports',
    'constitutionalDocuments',
    'riskAssessments',
    'tasks',
    'meetings',
  ];
  a.forEach((t) => {
    const c = prop(source, t);
    Object.keys(c).forEach((uuid) => {
      if (c[uuid].mainTemplateId === +n) {
        name = c[uuid].name ?? '';
        const i = c[uuid];
        if (t === 'meetings') {
          target = '9772/' + t + '/' + uuid;
        } else if (t === 'riskAssessments') {
          target = '712363/' + t + '/' + uuid;
        } else if (t === 'constitutionalDocuments') {
          target = '104/' + t + '/' + uuid;
        } else if (t === 'tasks') {
          const taskData = getTaskData(uuid, itemsByTypeAndId, singleUserVersion);
          if (taskData.name) {
            name = taskData.name;
          }
          if (taskData.startTask) {
            startTask = true;
          }
          target = taskData.target;
        } else if ('pages' in i && i.pages?.length) {
          target = '' + i.pages[0] + '/' + t + '/' + uuid;
        }
      }
    });
  });
  if (target) {
    return { target: target, startTask: startTask, name: name };
  }
  return false;
}

export function relativeDate(string: string) {
  const date = LocalDate.fromString(string);
  const days = LocalDate.now().until(date);
  if (days === 0) {
    return 'I dag';
  } else if (days > 0) {
    if (days === 1) {
      return 'I morgen';
    } else if (days > 1 && days < 4) {
      return 'Om ' + numberWord(days) + ' dager';
    } else {
      return date.toStringForDisplay();
    }
  } else if (days === -1) {
    return 'I går';
  } else if (days < -1 && days > -4) {
    return 'For ' + numberWord(days * -1) + ' dager siden';
  } else {
    return date.toStringForDisplay();
  }
}

export function relativeDateAndTime(string: string) {
  if (string === undefined || string === null || string === '') return '--';
  return (
    relativeDate(string.toUpperCase().split('T')[0]) +
    ' kl. ' +
    string.toUpperCase().split('T')[1].substring(0, 5).replace(':', '.')
  );
}

export function levelPath(level: number, currentPathArray: string[]) {
  if (currentPathArray.length) {
    let levelPath = '/account/' + currentPathArray[2] + '/' + currentPathArray[3];
    if (level > 2) {
      levelPath += '/' + currentPathArray[4] + '/' + currentPathArray[5];
    }
    if (level > 3) {
      levelPath += '/' + currentPathArray[6] + '/' + currentPathArray[7];
    }
    if (level > 4) {
      levelPath += '/' + currentPathArray[8] + '/' + currentPathArray[9];
    }
    return levelPath;
  }
}
