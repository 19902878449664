import { DPrepareBehavior } from './d-prepare-behavior.js';
import { customElement, property } from 'lit/decorators.js';
import type { AccountStateViewModel } from '../../store/api';
import { css } from 'lit';
import { html } from 'lit-html';
import '../../library/fields/d-spinner-robot.js';

/**
 *
 */
@customElement('d-prepare-finish')
export class DPrepareFinish extends DPrepareBehavior {
  static readonly styles = [
    ...super.styles,
    css`
      :host {
        display: block;
      }

      :host {
        display: flex;
        flex-direction: column;
        background: var(--themeColorDarkerTwo) url(/images/paper.png) 0 0 repeat;
        background-size: 800px 800px;
        min-height: 100vh;
      }

      :host > div {
        display: flex;
        flex-direction: column;
        max-width: 768px;
        margin: 0 auto;
        box-sizing: border-box;
        z-index: 1;
      }

      :host > div:first-child,
      :host > div:last-child {
        flex: 1;
        width: 100%;
        max-width: 100%;
      }

      :host > div:last-child {
        flex: 3;
        background: hsl(0, 0%, 30%) url(/images/paper.png) 0 0 repeat;
        background-size: 800px 800px;
      }

      :host form {
        padding: 20px 20px 0 20px;
      }

      h1 {
        font-size: 34px;
        color: white;
      }

      p {
        color: white;
        font-size: 18px;
        line-height: 170%;
        transition: all 0.3s;
      }

      a {
        color: var(--themeColorLighterTwo);
        padding-bottom: 2px;
        border-bottom: 1px solid var(--themeColorLighterOne);
        text-decoration: none;
      }

      a:hover {
        color: white;
        border-bottom: 1px solid white;
      }

      #finishImage {
        position: relative;
        display: flex;
        transition: all 0.3s;
      }

      .buttons {
        justify-content: flex-start;
        position: absolute;
        top: 0;
        left: 0;
      }

      button {
        background-color: var(--themeColor);
        box-shadow: 2px 2px 6px hsla(0, 0%, 0%, 0.4);
        transition: all 0.3s;
        font-size: 16px;
      }

      button:hover:not([disabled]),
      button[type='submit']:hover:not([disabled]) {
        background-color: var(--themeColor);
        box-shadow: 4px 4px 12px hsla(0, 0%, 0%, 0.4);
        transform: scale(1.1);
      }

      button:first-child {
        margin-left: 0;
        margin-right: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      @media only screen and (max-width: 400px) {
        d-prepare-finish #finishImage {
          padding-top: 40px;
        }

        .outskirts d-prepare-finish p {
          font-size: 16px;
        }
      }

      .maxWidth400 d-prepare-finish #finishImage {
        padding-top: 40px;
      }

      .maxWidth400 .outskirts d-prepare-finish p {
        font-size: 16px;
      }
    `,
  ];
  /**
   * No animation. Turn off all animations. Used by storybook to enable consistent snapshots.
   */
  @property({ type: Boolean, reflect: true, attribute: 'no-animation' })
  noAnimation = false;
  @property({ type: Boolean })
  singleUser = false;
  @property({ type: Boolean })
  accountIsReady = false;
  @property({ type: Object })
  state: AccountStateViewModel = {
    assetFunction: '',
    computerFunction: '',
    economicsFunction: '',
    employees: [],
    healthSafetyAndEnvironmentalFunction: '',
    humanResourcesFunction: '',
    leaderFunction: '',
    professionalDevelopmentFunction: '',
    qualityManagerFunction: '',
  };

  _linkClick(event) {
    event.preventDefault();
    if (event.target.nodeName.toLowerCase() === 'a') {
      const helpPage = event.target.href
        .substring(0, event.target.href.length - 1)
        .replace('https://trinnvis.no/hjelp/', '');

      this.dispatchEvent(
        new CustomEvent<{ page: string }>('update-help', {
          bubbles: true,
          composed: true,
          detail: { page: helpPage },
        }),
      );
    }
  }

  _done(e) {
    e.preventDefault();
    this.dispatchEvent(new CustomEvent('prepare-done', { bubbles: true, composed: true, detail: this.state }));
  }

  _logOut(e) {
    e.preventDefault();
    this.dispatchEvent(new CustomEvent('request-logout', { bubbles: true, composed: true }));
  }

  render() {
    return this.accountIsReady
      ? html`
          <div></div>
          <div>
            <form id="accountConfigCompleteForm" @submit=${(e) => this._done(e)}>
              <h1>Klar, ferdig, gå!</h1>
              <p>
                TrinnVis har mange verktøy og ressurser som kan hjelpe deg å drive ryddig, effektivt og lovlig. Du
                velger selv hva du vil bruke.
              </p>

              <p>
                Hvis du vil ha fullt utbytte av TrinnVis, anbefaler vi at du gjør
                <a href="https://trinnvis.no/hjelp/etableringsoppgaver/" @click=${(e) => this._linkClick(e)}
                  >etableringsoppgavene</a
                >
                som kommer på epost hver mandag.
              </p>

              <div id="finishImage">
                <img src="/images/signup-finale.png" alt="etableringsoppgaver" width="100%" height="auto" />
                <div class="buttons">
                  <button type="submit">Min konto</button>
                  <button @click=${(e) => this._logOut(e)} type="button">Logg ut</button>
                </div>
              </div>
            </form>
          </div>
          <div></div>
        `
      : html` <d-spinner-robot
          ?no-animation=${this.noAnimation}
          size="60"
          style="background-color: hsl(197, 82%, 33%);"
        >
          <div style="margin-top: 20px; color: white; text-align: center; line-height: 150%;">
            Klargjør konto.<br />Dette kan ta noen sekunder.
          </div>
        </d-spinner-robot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-prepare-finish': DPrepareFinish;
  }
}
