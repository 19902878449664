import type { LocalDate } from 'src/utilities/local-date.js';
import type { WeekdayStr } from '../date.js';

export enum Frequency {
  YEARLY = 0,
  MONTHLY = 1,
  WEEKLY = 2,
  DAILY = 3,
}

export interface RecurrenceRuleOptions {
  start: string;
  until: string;
  byWeekDay: WeekdayStr[];
  byMonthDay: number;
  byMonth: number;
  byHour: number;
  byMinute: number;
  bySecond: number;
  bySetPos: number;
  interval: number;
  count: number;
  freq: Frequency.YEARLY | Frequency.WEEKLY | Frequency.DAILY | Frequency.MONTHLY;
}

export interface InstanceRule {
  expand(start: LocalDate, end: LocalDate, interval: number): LocalDate[];
  optionsToString(interval: number): string;
  scheduleDescription(interval: number): string;
}
