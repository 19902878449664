import type { EmployeeViewModelStatusEnum } from '../api';

export interface Employee {
  status?: EmployeeViewModelStatusEnum;
  name: string;
  firstName: string;
  uuid: string;
}

interface EmployeeShortName {
  name: string;
  uuid: string;
}

export function listNamesAsText(shortNames: EmployeeShortName[], employees: string[]): string {
  const list = shortNames.filter((v) => employees.includes(v.uuid)).map((v) => v.name);

  if (list.length > 1 && list.length === shortNames.length) {
    return 'Alle';
  } else if (list.length === 1) {
    return list[0];
  } else if (list.length === 0) {
    return '';
  } else {
    return list.slice(0, list.length - 1).join(', ') + ' og ' + list[list.length - 1];
  }
}
