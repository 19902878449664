import { createSelector } from '@reduxjs/toolkit';
import type { OrganizationState, Sector, State } from '../types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { defaultPageId } from 'src/store';

export function getOrganization(state: State): OrganizationState | undefined {
  return state.organization;
}

export function now(state: State): LocalDate {
  return LocalDate.fromString(state.today);
}

export function getOrganizationId(state: State): number {
  if (state.id === null) {
    return 0;
  }
  return state.id;
}

export function activityCodesDisplayText(availableCodes: any[], organizationCodes: string | any[]): string {
  let result: string = '';
  if (organizationCodes && availableCodes) {
    const names: string[] = [];
    availableCodes.forEach((code) => {
      if (organizationCodes.indexOf(code.code) !== -1) {
        if (code.codes) {
          const r = activityCodesDisplayText(code.codes, organizationCodes);
          if (r && r !== '') {
            names.push(r);
          } else {
            names.push(code.custom || code.name);
          }
        } else {
          names.push(code.custom || code.name);
        }
      }
    });
    result = names.join(', ');
  }
  return result;
}

export const organizationName = createSelector(getOrganization, function (organization) {
  if (organization !== undefined) {
    return organization.name;
  } else {
    return '';
  }
});

export const sector = createSelector(getOrganization, function (organization): Sector {
  if (organization === undefined) return 'OTHER';
  return organization.sector;
});

export const sectorOptions: SelectDropdownOption[] = [
  { value: 'HEALTH', text: 'Helse- og omsorgstjenester' },
  { value: 'CRAFTS_TRANSPORTATION_CLEANING', text: 'Håndverk, transport, renhold' },
  { value: 'FOOD_ACCOMMODATION', text: 'Mat og overnatting' },
  { value: 'PERSONAL_TRADE_SERVICE', text: 'Personlig handel og tjenester' },
  { value: 'OFFICE', text: 'Kontor' },
  { value: 'OTHER', text: 'Annet' },
];

export function getBusinessEntityTypeOptions(): SelectDropdownOption[] {
  return ['Enkeltpersonforetak', 'DA', 'ANS', 'KS', 'AS', 'NUF', 'Stiftelse', 'Kommunal enhet', 'Annet'].map((x) => ({
    value: x,
    text: x,
  }));
}

export const businessEntityTypeOptions: SelectDropdownOption[] = getBusinessEntityTypeOptions();

export const medicalDoctor = createSelector(getOrganization, function (organization): boolean {
  if (organization === undefined) return false;
  return organization.activityCodes.includes('86.21') || organization.activityCodes.includes('86.22');
});

export const maritimeDoctor = createSelector(getOrganization, function (organization): boolean {
  if (organization === undefined) return false;
  return organization.activityCodes.includes('86.21.01');
});

export const dentist = createSelector(getOrganization, function (organization): boolean {
  if (organization === undefined) return false;
  return organization.activityCodes.includes('86.23');
});

export function hasOrganizationNotPending(state: State): boolean {
  return state.organization !== undefined && !state.organization.pending;
}

export function singleUserVersion(state: State): boolean {
  return state.organization?.singleUser === true;
}

export function getOrganizationUrl(organization) {
  if (organization) {
    const url = '/account/' + organization.id;
    return (
      url +
      '/' +
      (organization.message === 'startup'
        ? 'news'
        : organization.pending
          ? 'prepare'
          : organization.upgradable
            ? 'upgrade'
            : defaultPageId)
    );
  } else {
    return '';
  }
}
