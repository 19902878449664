import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 *
 *
 */
@customElement('d-add-remove-buttons')
export class DAddRemoveButtons extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      flex: none;
      width: 50px;
      height: 30px;
      overflow: hidden;
      margin-left: 8px;
    }
    :host([adjust-width]) {
      width: auto;
    }
    .remove {
      display: inline-block;
      width: 24px;
      height: 30px;
      margin-right: -4px;
      background: url(/images/x-thin-orange.svg) 50% 50% no-repeat;
      background-size: 24px 24px;
      cursor: pointer;
    }
    .add {
      display: inline-block;
      width: 24px;
      height: 30px;
      background: url(/images/plus-thin-blue.svg) 50% 50% no-repeat;
      background-size: 24px 24px;
      cursor: pointer;
    }
  `;
  @property({ type: Boolean })
  showRemove = false;
  @property({ type: Boolean })
  showAdd = false;

  _remove() {
    this.dispatchEvent(
      new CustomEvent('remove', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  _add() {
    this.dispatchEvent(
      new CustomEvent('add', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  render() {
    return html`
      ${this.showRemove ? html`<div class="remove" @click=${() => this._remove()}></div> ` : nothing}
      ${this.showAdd ? html`<div class="add" @click=${() => this._add()}></div> ` : nothing}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-add-remove-buttons': DAddRemoveButtons;
  }
}
