import { css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ItemTable, ItemTableColumn } from '../../library/abstracts/item-table.js';

interface SkilCourseItems {
  category: string;
  categorySortValue: string;
  sort: string;
  name: string;
  price: string;
  href: string;
  target: string;
  hasDraft: false;
}

/**
 *
 * STATUS OK
 */
@customElement('d-skil-course-table')
export class DSkilCourseTable extends ItemTable<SkilCourseItems> {
  static readonly styles = [
    ...ItemTable.styles,
    css`
      :host {
        display: block;
        background-color: hsl(169, 25%, 85%);
      }

      td,
      tr.column-labels th {
        border-color: hsl(169, 25%, 78%);
      }

      tr.group-label th,
      tr.column-labels th {
        background-color: hsl(169, 25%, 85%);
      }

      @media only screen and (max-width: 700px) {
        :host > table > tr {
          border-color: hsl(169, 25%, 78%);
        }
      }
    `,
  ];
  protected columns: ItemTableColumn[] = [
    {
      name: 'name',
      field: '',
      label: 'Navn',
    },
    {
      name: 'price',
      field: '',
      label: 'Pris',
    },
    {
      name: 'sort',
      field: '',
      label: '',
      hide: true,
    },
  ];

  includeItem(_item: SkilCourseItems): boolean {
    throw new Error('Method not implemented.');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-skil-course-table': DSkilCourseTable;
  }
}
