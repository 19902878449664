import { css, html, LitElement } from 'lit';
import '../elements/d-label.js';
import { customElement, property } from 'lit/decorators.js';

/**
 *
 * Element used inside d-wrap to horizontally align text with input elements
 * Attributes "big", "bold" and "tight" (diminish horizontal spacing) are defined in d-wrap
 *
 * STATUS OK
 */
@customElement('d-inline')
export class DInline extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: inline-block;
        padding-top: 5px;
        font-family: var(--mainSans), sans-serif;
        font-size: 15px;
        line-height: var(--lineHeightDefault);
        text-align: center;
        margin-left: -6px;
        margin-right: -6px;
      }

      :host([big]) {
        font-size: 17px;
      }

      :host([bold]) {
        font-weight: bold;
      }
    `,
  ];

  @property({ type: Boolean, reflect: true })
  big = false;
  @property({ type: Boolean, reflect: true })
  tight = false;

  @property({ type: Boolean, reflect: true })
  bold = false;

  render() {
    return html`<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-inline': DInline;
  }
}
