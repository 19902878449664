import { css } from 'lit';

export const registerItemsPromptStyles = css`
  .register-items-prompt {
    display: block;
    width: 280px;
    margin: 40px auto;
    padding: 270px 20px 20px 20px;
    border: 5px dashed transparent;
    border-radius: 30px;
    text-align: center;
    opacity: 0.5;
    cursor: pointer;
    background-size: 200px 200px;
    transition:
      background 0.3s,
      padding 0.3s;
  }
  .register-items-prompt.assets {
    background: transparent url(/images/assets-gray.svg) 50% 40px no-repeat;
    background-size: 200px 200px;
  }
  .register-items-prompt.issues {
    background: transparent url(/images/issues-gray.svg) 50% 40px no-repeat;
    background-size: 200px 200px;
  }
  .register-items-prompt.substances {
    background: transparent url(/images/substances-gray.svg) 50% 40px no-repeat;
    background-size: 200px 200px;
  }
  .register-items-prompt h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
  }
  .register-items-prompt p {
    font-size: 16px;
    line-height: 130%;
  }
  @media (hover: hover) {
    .register-items-prompt:hover,
    .register-items-prompt.hover {
      opacity: 0.8;
      border-color: silver;
    }
  }
`;
