import { customElement } from 'lit/decorators.js';
import { css, html, LitElement } from 'lit';

/**
 * Displays the child elements in sections divided internally by border.
 *
 * STATUS OK
 */
@customElement('d-sections')
export class DSections extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    ::slotted(:not(:first-child)) {
      border-top: 1px solid var(--borderColorTransparent);
    }

    ::slotted(*) {
      padding: 12px 0 10px 0;
    }
  `;

  protected render(): unknown {
    return html`<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-sections': DSections;
  }
}
