import { css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';

import '../../elements/d-label.js';

/**
 *
 * @fires value-changed - Dispatched immediately after changes by the user
 *
 * STATUS OK
 */
@customElement('d-edit-number')
export class DEditNumber extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      input {
        text-align: right;
        padding-right: 6px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input {
        -moz-appearance: textfield;
      }
    `,
  ];
  @query('#input')
  input!: HTMLInputElement;
  @property({ type: String })
  field = '';
  @property({ type: Boolean })
  disabled = false;
  @property({ type: Number })
  min = Number.MIN_SAFE_INTEGER;
  @property({ type: Number })
  max = Number.MAX_SAFE_INTEGER;
  @property({ type: Number })
  value = 0;
  @property({ type: Boolean, attribute: 'select-on-focus' })
  selectOnFocus = false;

  _valueChange(value) {
    let correctedValue = value;
    if (value < this.min) {
      correctedValue = this.min;
    }
    if (value > this.max) {
      correctedValue = this.max;
    }

    this.value = correctedValue;
  }

  _select() {
    this.dispatchEvent(new CustomEvent('is-focused', { bubbles: true, composed: true }));
    if (this.selectOnFocus) {
      this.input.select();
    }
  }

  inputHandler(event) {
    this._valueChange(Number(event.target.value));
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
  }

  renderContent() {
    return html`
      <input
        id="input"
        value=${this.value}
        @input=${this.inputHandler}
        ?disabled=${this.disabled}
        type="number"
        min=${this.min}
        max=${this.max}
        @focus=${() => this._select()}
      />
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-number': DEditNumber;
  }
}
