import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import '../../library/elements/d-section.js';
import '../../library/fields/d-view-text.js';
import '../../library/fields/d-view-html.js';
import '../../library/fields/d-expansion.js';

/**
 *
 * STATUS OK
 */
@customElement('d-external-report-item')
class DExternalReportItem extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      padding: 3px 0;
    }

    .externalReportListItem {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 2px;
      color: hsla(1, 0%, 0%, 0.5);
      cursor: pointer;
    }

    .externalReportListItem.open {
      color: hsla(1, 0%, 0%, 1);
      cursor: default;
    }

    body:not(.touch) .externalReportListItem:hover {
      color: hsla(1, 0%, 0%, 1);
    }

    .externalReportListItem > div:first-child {
      margin-right: 6px;
    }

    .externalReportContent {
      background: white;
      margin-top: 16px;
      margin-bottom: 10px;
      padding: 30px;
      box-shadow: 0px 0px 10px hsla(1, 0%, 0%, 0.3);
    }

    @media only screen and (max-width: 600px) {
      .externalReportContent {
        padding: 20px;
      }
    }

    .externalReportContent d-section:last-child #flexWrapper {
      border-bottom: none;
    }

    .externalReportHeader {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .externalReportHeader h2 {
      font-size: 22px;
      color: var(--pageHeaderColor);
      margin-bottom: 12px;
    }

    .externalReportHeader .closer {
      margin-top: -20px;
      margin-right: -20px;
    }

    .externalReportContent d-view-html .big.d-label #label.d-label {
      font-size: 15px;
    }

    .externalReportContent d-view-html img {
      max-width: 100%;
    }
  `;
  @property({ type: Boolean })
  open = false;
  @property({ type: String })
  createdDisplay = '';
  @property({ type: String })
  createdDisplayFull = '';
  @property({ type: String })
  recipientName = '';
  @property({ type: String })
  senderName = '';
  @property({ type: String })
  subject = '';
  @property({ type: String })
  description = '';

  _toggle() {
    this.open = !this.open;
  }

  _listItemClasses(open) {
    if (open) {
      return 'externalReportListItem open';
    }
    return 'externalReportListItem';
  }

  render() {
    return html`
      <div class="${this._listItemClasses(this.open)}" @click=${() => this._toggle()}>
        <div>Sendt ${this.createdDisplay} til</div>
        <div>${this.recipientName}</div>
      </div>
      <d-expansion .opened=${this.open}>
        <div class="externalReportContent">
          <div class="externalReportHeader">
            <div class="closer" @click=${() => this._toggle()}></div>
            <h2>${this.subject}</h2>
          </div>
          <d-section>
            <d-view-text label="Sendt" value="${this.createdDisplayFull}"></d-view-text>
            <d-view-text label="Sendt av" value="${this.senderName}"></d-view-text>
          </d-section>
          <d-section>
            <d-view-html
              extra-height="100000"
              label="Beskrivelse"
              label-classes=""
              value="${this.description}"
            ></d-view-html>
          </d-section>
        </div>
      </d-expansion>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-external-report-item': DExternalReportItem;
  }
}
