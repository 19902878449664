/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Buffer } from 'buffer/index.js';
import * as runtime from '../runtime';
import type {
  AttachmentPropertiesUpdateMessage,
  AttachmentViewModel,
  ConstitutionalDocumentUpdateMessage,
  ConstitutionalDocumentViewModel,
  RevisionViewModel,
} from '../models';
import {
  AttachmentPropertiesUpdateMessageFromJSON,
  AttachmentPropertiesUpdateMessageToJSON,
  AttachmentViewModelFromJSON,
  AttachmentViewModelToJSON,
  ConstitutionalDocumentUpdateMessageFromJSON,
  ConstitutionalDocumentUpdateMessageToJSON,
  ConstitutionalDocumentViewModelFromJSON,
  ConstitutionalDocumentViewModelToJSON,
  RevisionViewModelFromJSON,
  RevisionViewModelToJSON,
} from '../models';

export interface CreateOrUpdateConstitutionalDocumentRequest {
  constitutionalDocumentId: string;
  organizationId: string;
  constitutionalDocumentUpdateMessage: ConstitutionalDocumentUpdateMessage;
}

export interface DeleteAttachmentForConstitutionalDocumentRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteConstitutionalDocumentRequest {
  constitutionalDocumentId: string;
  organizationId: string;
}

export interface FetchAttachmentForConstitutionalDocumentRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentLinkForConstitutionalDocumentRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchConstitutionalDocumentRequest {
  constitutionalDocumentId: string;
  organizationId: string;
}

export interface ListAttachmentsForConstitutionalDocumentRequest {
  entityId: string;
  organizationId: string;
}

export interface ListConstitutionalDocumentsRequest {
  organizationId: string;
}

export interface ListRevisionsForConstitutionalDocumentRequest {
  constitutionalDocumentId: string;
  organizationId: string;
}

export interface RenameAttachmentForConstitutionalDocumentRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
  attachmentPropertiesUpdateMessage: AttachmentPropertiesUpdateMessage;
}

export interface UploadAttachmentForConstitutionalDocumentRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
  contentLength?: string;
  body?: string;
}

/**
 *
 */
export class ConstitutionalDocumentsApi extends runtime.BaseAPI {
  /**
   * Creates or updates a constitutional document.
   */
  async createOrUpdateConstitutionalDocumentRaw(
    requestParameters: CreateOrUpdateConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.constitutionalDocumentId === null ||
      requestParameters.constitutionalDocumentId === undefined
    ) {
      throw new runtime.RequiredError(
        'constitutionalDocumentId',
        'Required parameter requestParameters.constitutionalDocumentId was null or undefined when calling createOrUpdateConstitutionalDocument.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateConstitutionalDocument.',
      );
    }

    if (
      requestParameters.constitutionalDocumentUpdateMessage === null ||
      requestParameters.constitutionalDocumentUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'constitutionalDocumentUpdateMessage',
        'Required parameter requestParameters.constitutionalDocumentUpdateMessage was null or undefined when calling createOrUpdateConstitutionalDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/constitutionalDocuments/{constitutionalDocumentId}`
          .replace(
            `{${'constitutionalDocumentId'}}`,
            encodeURIComponent(String(requestParameters.constitutionalDocumentId)),
          )
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ConstitutionalDocumentUpdateMessageToJSON(requestParameters.constitutionalDocumentUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates or updates a constitutional document.
   */
  async createOrUpdateConstitutionalDocument(
    requestParameters: CreateOrUpdateConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateConstitutionalDocumentRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for a constitutional document.
   */
  async deleteAttachmentForConstitutionalDocumentRaw(
    requestParameters: DeleteAttachmentForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling deleteAttachmentForConstitutionalDocument.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteAttachmentForConstitutionalDocument.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAttachmentForConstitutionalDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/constitutionalDocuments/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for a constitutional document.
   */
  async deleteAttachmentForConstitutionalDocument(
    requestParameters: DeleteAttachmentForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAttachmentForConstitutionalDocumentRaw(requestParameters, initOverrides);
  }

  /**
   * Deletes a constitutional document.
   */
  async deleteConstitutionalDocumentRaw(
    requestParameters: DeleteConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.constitutionalDocumentId === null ||
      requestParameters.constitutionalDocumentId === undefined
    ) {
      throw new runtime.RequiredError(
        'constitutionalDocumentId',
        'Required parameter requestParameters.constitutionalDocumentId was null or undefined when calling deleteConstitutionalDocument.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteConstitutionalDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/constitutionalDocuments/{constitutionalDocumentId}`
          .replace(
            `{${'constitutionalDocumentId'}}`,
            encodeURIComponent(String(requestParameters.constitutionalDocumentId)),
          )
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deletes a constitutional document.
   */
  async deleteConstitutionalDocument(
    requestParameters: DeleteConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteConstitutionalDocumentRaw(requestParameters, initOverrides);
  }

  /**
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForConstitutionalDocumentRaw(
    requestParameters: FetchAttachmentForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling fetchAttachmentForConstitutionalDocument.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentForConstitutionalDocument.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentForConstitutionalDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/constitutionalDocuments/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForConstitutionalDocument(
    requestParameters: FetchAttachmentForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentForConstitutionalDocumentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Gets an Accusoft viewing session for an attachment of a constitutional document.
   */
  async fetchAttachmentLinkForConstitutionalDocumentRaw(
    requestParameters: FetchAttachmentLinkForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling fetchAttachmentLinkForConstitutionalDocument.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentLinkForConstitutionalDocument.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentLinkForConstitutionalDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/constitutionalDocuments/{entityId}/attachments/{attachmentUuid}/session`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Gets an Accusoft viewing session for an attachment of a constitutional document.
   */
  async fetchAttachmentLinkForConstitutionalDocument(
    requestParameters: FetchAttachmentLinkForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentLinkForConstitutionalDocumentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Gets a constitutional document.
   */
  async fetchConstitutionalDocumentRaw(
    requestParameters: FetchConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ConstitutionalDocumentViewModel>> {
    if (
      requestParameters.constitutionalDocumentId === null ||
      requestParameters.constitutionalDocumentId === undefined
    ) {
      throw new runtime.RequiredError(
        'constitutionalDocumentId',
        'Required parameter requestParameters.constitutionalDocumentId was null or undefined when calling fetchConstitutionalDocument.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchConstitutionalDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/constitutionalDocuments/{constitutionalDocumentId}`
          .replace(
            `{${'constitutionalDocumentId'}}`,
            encodeURIComponent(String(requestParameters.constitutionalDocumentId)),
          )
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ConstitutionalDocumentViewModelFromJSON(jsonValue));
  }

  /**
   * Gets a constitutional document.
   */
  async fetchConstitutionalDocument(
    requestParameters: FetchConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ConstitutionalDocumentViewModel> {
    const response = await this.fetchConstitutionalDocumentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Lists attachments for a constitutional document.
   */
  async listAttachmentsForConstitutionalDocumentRaw(
    requestParameters: ListAttachmentsForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AttachmentViewModel>>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling listAttachmentsForConstitutionalDocument.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listAttachmentsForConstitutionalDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/constitutionalDocuments/{entityId}/attachments`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentViewModelFromJSON));
  }

  /**
   * Lists attachments for a constitutional document.
   */
  async listAttachmentsForConstitutionalDocument(
    requestParameters: ListAttachmentsForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AttachmentViewModel>> {
    const response = await this.listAttachmentsForConstitutionalDocumentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Lists constitutional documents.
   */
  async listConstitutionalDocumentsRaw(
    requestParameters: ListConstitutionalDocumentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ConstitutionalDocumentViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listConstitutionalDocuments.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/constitutionalDocuments`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConstitutionalDocumentViewModelFromJSON));
  }

  /**
   * Lists constitutional documents.
   */
  async listConstitutionalDocuments(
    requestParameters: ListConstitutionalDocumentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ConstitutionalDocumentViewModel>> {
    const response = await this.listConstitutionalDocumentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Lists versioning for a constitutional document.
   */
  async listRevisionsForConstitutionalDocumentRaw(
    requestParameters: ListRevisionsForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<RevisionViewModel>>> {
    if (
      requestParameters.constitutionalDocumentId === null ||
      requestParameters.constitutionalDocumentId === undefined
    ) {
      throw new runtime.RequiredError(
        'constitutionalDocumentId',
        'Required parameter requestParameters.constitutionalDocumentId was null or undefined when calling listRevisionsForConstitutionalDocument.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listRevisionsForConstitutionalDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/constitutionalDocuments/{constitutionalDocumentId}/revisions`
          .replace(
            `{${'constitutionalDocumentId'}}`,
            encodeURIComponent(String(requestParameters.constitutionalDocumentId)),
          )
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RevisionViewModelFromJSON));
  }

  /**
   * Lists versioning for a constitutional document.
   */
  async listRevisionsForConstitutionalDocument(
    requestParameters: ListRevisionsForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<RevisionViewModel>> {
    const response = await this.listRevisionsForConstitutionalDocumentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Updates properties of an attachment for a constitutional document.
   */
  async renameAttachmentForConstitutionalDocumentRaw(
    requestParameters: RenameAttachmentForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling renameAttachmentForConstitutionalDocument.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling renameAttachmentForConstitutionalDocument.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling renameAttachmentForConstitutionalDocument.',
      );
    }

    if (
      requestParameters.attachmentPropertiesUpdateMessage === null ||
      requestParameters.attachmentPropertiesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentPropertiesUpdateMessage',
        'Required parameter requestParameters.attachmentPropertiesUpdateMessage was null or undefined when calling renameAttachmentForConstitutionalDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/constitutionalDocuments/{entityId}/attachments/{attachmentUuid}/properties`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AttachmentPropertiesUpdateMessageToJSON(requestParameters.attachmentPropertiesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates properties of an attachment for a constitutional document.
   */
  async renameAttachmentForConstitutionalDocument(
    requestParameters: RenameAttachmentForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameAttachmentForConstitutionalDocumentRaw(requestParameters, initOverrides);
  }

  /**
   * Creates an attachment for a constitutional document.
   */
  async uploadAttachmentForConstitutionalDocumentRaw(
    requestParameters: UploadAttachmentForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling uploadAttachmentForConstitutionalDocument.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling uploadAttachmentForConstitutionalDocument.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling uploadAttachmentForConstitutionalDocument.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.contentLength !== undefined && requestParameters.contentLength !== null) {
      headerParameters['Content-Length'] = String(requestParameters.contentLength);
    }

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.body !== undefined) {
      formParams.append('body', requestParameters.body as any);
    }

    const response = await this.request(
      {
        path: `/organizations/{organizationId}/constitutionalDocuments/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates an attachment for a constitutional document.
   */
  async uploadAttachmentForConstitutionalDocument(
    requestParameters: UploadAttachmentForConstitutionalDocumentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.uploadAttachmentForConstitutionalDocumentRaw(requestParameters, initOverrides);
  }
}
