import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { DayOfWeek, LocalDate, mondayThisWeek } from 'src/utilities/local-date.js';
import holidaysList from '../data/holidays.json';
import { RecurrenceRule } from '../recurrence-rule/recurrence-rule.js';
import type { OrganizationState, State, TaskInstance } from '../types.js';
import { doneInformation } from './event-selector-functions.js';
import { getOrganization, getOrganizationId, now, singleUserVersion } from './organization.js';
import { currentEmployeeUuid, employeesShortNamesAndI, employeesShortNamesAndMe } from './organization-employees.js';
import { currentEmployeeFunctions, functionsNotDeleted, tasksNotDeleted } from './organization-functions.js';
import { meetings } from './organization-meetings.js';
import { getContactsAsUsers, listContactNamesWithPartnerNames, PartnerContactAsUser } from './organization-partners.js';
import { employeesForStaffingCalendar, periodTimeDisplayText } from './selectors-for-staffing-calendar.js';
import { currentUserUuid } from './user.js';
import { eventOccurrencesNotDeleted, todoListHideAfterDaysOption } from 'src/store';
import { EventOccurrenceViewModel, FunctionViewModel } from 'src/store/api';
import { listNamesAsText } from 'src/store/selectors/employee-selector-functions';
import { eventAssigneesText, meetingParticipantsText, timeDescription } from 'src/store/utilities';
import { ListSectionItemInput } from 'src/library/lists/utilities';
import { LeavePeriodType } from 'src/pages/staffing-page/d-staffing-calendar-data';

export function findHolidayName(date: string) {
  const f = holidaysList.find((h) => h.date.startsWith(date));
  return f?.description ?? '';
}

export const weekStart = (state: State): string => state.weekStart;

export const singleUserView = (state: State): boolean => state.singleUserView;

function leavePeriodPhrases(type: string): string {
  switch (type) {
    case 'vacation':
      return 'har ferie';
    case 'leave':
      return 'er i permisjon';
    case 'timeOff':
      return 'avspaserer';
    case 'seminar':
      return 'er på kurs';
    case 'jobTravel':
      return 'er på jobbreise';
    case 'homeOffice':
      return 'har hjemmekontor';
    case 'sickLeave':
      return 'er sykmeldt';
    case 'sickSelf':
      return 'er syk';
    case 'sickChildren':
      return 'har syke barn';
    case 'OTHER':
      return 'er borte';
  }

  throw new Error('Illegal state (E903), leave period type ' + type);
}

export interface LeavePeriodSummary {
  type: string;
  start: string;
  text: string;
  confirmed: boolean;
  myPeriod: boolean;
}

export interface LeavePeriodTodaySummary {
  type: string;
  text: string;
}

export const currentEmployeeForStaffingCalendar = createSelector(
  employeesForStaffingCalendar,
  currentEmployeeUuid,
  (employees, currentEmployeeUuid) => {
    return employees.find((e) => e.uuid === currentEmployeeUuid);
  },
);

export const leavePeriods = createSelector(
  employeesForStaffingCalendar,
  currentEmployeeUuid,
  weekStart,
  (employees, currentEmployeeUuid, weekStart) => {
    const calStart = LocalDate.fromString(weekStart);
    const calEnd = calStart.plusDays(6);

    const result: LeavePeriodSummary[] = [];
    for (const item1 of employees) {
      const periods: {
        confirmed: boolean;
        type: string;
        start: string;
        end: string;
        startTime?: string;
        endTime?: string;
      }[] = item1.leavePeriods;

      for (const item of periods) {
        if (
          LocalDate.fromString(item.start).isSameOrBefore(calEnd) &&
          LocalDate.fromString(item.end).isSameOrAfter(calStart)
        ) {
          let employee = item1.displayName;
          if (item1.uuid === currentEmployeeUuid) {
            employee = 'Jeg';
          }
          let startTime = item.startTime;
          if (startTime === undefined) {
            startTime = '00:00';
          }
          let endTime = item.endTime;
          if (endTime === undefined) {
            endTime = '00:00';
          }
          const startDate = LocalDate.fromString(item.start);
          const endDate = LocalDate.fromString(item.end);
          const durationText = periodTimeDisplayText(startDate, startTime, endDate, endTime, false);
          const text = employee + ' ' + leavePeriodPhrases(item.type) + ' ' + durationText;

          const myPeriod = item1.uuid === currentEmployeeUuid;
          result.push({
            start: item.start,
            type: item.type,
            text: text,
            confirmed: item.confirmed,
            myPeriod: myPeriod,
          });
        }
      }
    }
    return sortBy(result, (e) => e.start);
  },
);

export const leavePeriodsToday = createSelector(
  singleUserVersion,
  employeesForStaffingCalendar,
  currentEmployeeUuid,
  (singleUser, employees, currentEmployeeUuid): ListSectionItemInput[] => {
    if (singleUser) {
      return [];
    }
    const today = LocalDate.now();
    const result: ListSectionItemInput[] = [];
    for (const item1 of employees) {
      const periods: {
        confirmed: boolean;
        type: string;
        start: string;
        end: string;
        startTime?: string;
        endTime?: string;
      }[] = item1.leavePeriods;

      for (const item of periods) {
        if (
          item.confirmed &&
          LocalDate.fromString(item.start).isSameOrBefore(today) &&
          LocalDate.fromString(item.end).isSameOrAfter(today)
        ) {
          let employee = item1.displayName;
          if (item1.uuid === currentEmployeeUuid) {
            employee = 'Jeg';
          }
          let hoursText = '';
          if (item.startTime && item.endTime) {
            hoursText = ' fra ' + item.startTime + ' til ' + item.endTime;
          }
          const text = employee + ' ' + leavePeriodPhrases(item.type) + ' i dag' + hoursText.replace(':', '.');
          result.push({
            leavePeriodType: item.type as LeavePeriodType,
            accessible: false,
            label: text,
          });
        }
      }
    }
    return result;
  },
);

export const leavePeriodsConfirmed = createSelector(leavePeriods, (periods) => {
  return periods.filter((p) => p.confirmed);
});

export const startTaskInstances = createSelector(
  tasksNotDeleted,
  getOrganizationId,
  getOrganization,
  currentEmployeeFunctions,
  weekStart,
  singleUserVersion,
  (tasks, organizationId, o, currentEmployeeFunctions, weekStart, singleUserVersion) => {
    let t: TaskInstance[] = [];
    tasks.forEach(function (task) {
      let add = false;
      let instance;
      let time;
      let done = '';
      if (task.locked && task.rrule) {
        const rule = RecurrenceRule.fromString(task.rrule);
        const thisWeek = mondayThisWeek();
        const date = rule.sameOrAfter(thisWeek);
        if (date) {
          time = date;
          instance = date;
          add = instance.withPreviousOrSame(DayOfWeek.MONDAY).isSame(LocalDate.fromString(weekStart));
        }
      } else if (task.lastExecuted) {
        time = LocalDate.fromString(task.lastExecuted);
        add = time.withPreviousOrSame(DayOfWeek.MONDAY).isSame(LocalDate.fromString(weekStart));
        done = time.toStringForDisplay();
      }
      if (add && time !== undefined) {
        let href = '/account/' + organizationId + '/' + 318434;
        href += '/functions/' + task.functionUuid;
        href += '/tasks/' + task.uuid;
        const myTask = currentEmployeeFunctions.includes(task.functionUuid);
        let functionNameDisplay = o?.functionsById[task.functionUuid]?.name ?? '';
        if (singleUserVersion) {
          functionNameDisplay = '';
        }
        t.push({
          instance: time,
          done: done,
          taskUuid: task.uuid,
          name: task.name,
          functionUuid: task.functionUuid,
          functionName: functionNameDisplay,
          href: href,
          time: time,
          myTask: myTask,
          timeOfDayDisplay: '',
        });
      }
    });
    t = sortBy(t, (e) => e.time);
    return t;
  },
);
export function today(state: State): string {
  return state.today;
}

export const holidays = createSelector(weekStart, (weekStart) => {
  const calStart = LocalDate.fromString(weekStart);
  const calEnd = calStart.plusDays(6);
  let holidays = holidaysList.filter(function (holiday) {
    return (
      LocalDate.fromString(holiday.date).isSameOrAfter(calStart) &&
      LocalDate.fromString(holiday.date).isSameOrBefore(calEnd)
    );
  });
  holidays = sortBy(holidays, (e) => e.date);
  return holidays;
});

export interface TodoItem {
  uuid: string;
  name?: string;
  eventNotes: string;
  href: string;
  assignee?: string;
  myTask: boolean;
  overdue: boolean;
  dateTimeForOrdering: string;
  done: boolean;
  doneDate?: string;
  doneAtDisplay: string;
  doneByEmployeeUuid?: string;
  doneText: string;
  hasDraft: boolean;
  isConfirmedEntity: boolean;
  draftName: string;
}

export const overdueEventOccurrences = createSelector(eventOccurrencesNotDeleted, today, (occurrences, today) => {
  const mondayThisWeek = LocalDate.fromString(today).withPreviousOrSame(DayOfWeek.MONDAY);

  return occurrences.filter(
    (e) => e.date !== null && e.persistent && !e.doneDate && LocalDate.fromString(e.date).isBefore(mondayThisWeek),
  );
});

export const unscheduledEventOccurrences = createSelector(
  eventOccurrencesNotDeleted,
  now,
  todoListHideAfterDaysOption,
  (occurrences, t, hideAfterDays) => {
    const excludeDoneTasksBefore = t.minusDays(hideAfterDays);
    return occurrences.filter(
      (e) =>
        (e.date === null || e.date === undefined) &&
        (!e.doneDate || LocalDate.fromString(e.doneDate).isAfter(excludeDoneTasksBefore)),
    );
  },
);

function homePageEventItem(
  e: EventOccurrenceViewModel,
  overdue: boolean,
  organization: OrganizationState,
  employeesShortNamesAndMe: { uuid: string; status: string; name: string }[],
  currentUserUuid: string,
  contactsAsUsers: PartnerContactAsUser[],
  employeesShortNamesAndI: { uuid: string; status: string; name: string }[],
  singleUserVersion: boolean,
  functions: FunctionViewModel[],
): TodoItem {
  let done = false;
  let doneAtDisplay = '';
  if (e.doneDate && (e.doneByEmployeeUuid || e.doneByContactPersonUuid)) {
    done = true;
    doneAtDisplay = LocalDate.fromString(e.doneDate).toStringForDisplay();
  }

  let doneText = '';
  if (e.doneDate && e.doneByEmployeeUuid && currentUserUuid) {
    let doneByText = '';
    if (!singleUserVersion) {
      const employeesListDone = [e.doneByEmployeeUuid];
      const employeeNamesListDone = listNamesAsText(employeesShortNamesAndMe, employeesListDone);
      doneByText = ' av ' + employeeNamesListDone;
    }
    doneText = 'merket som utført ' + LocalDate.fromString(e.doneDate).toStringForDisplay() + doneByText;
  }
  if (e.doneDate && e.doneByContactPersonUuid && currentUserUuid) {
    let doneByText = '';
    if (!singleUserVersion) {
      const contactsListDone = [e.doneByContactPersonUuid];
      const contactNamesListDone = listContactNamesWithPartnerNames(contactsListDone, contactsAsUsers);
      doneByText = ' av ' + contactNamesListDone;
    }
    doneText = 'merket som utført ' + LocalDate.fromString(e.doneDate).toStringForDisplay() + doneByText;
  }

  let myTask = true;
  if (!singleUserVersion) {
    myTask = false;
    if (e.functionUuid) {
      if (organization.functionsById[e.functionUuid].employees.includes(currentUserUuid)) {
        myTask = true;
      }
    } else {
      if (e.employees?.includes(currentUserUuid)) {
        myTask = true;
      }
      if (e.contacts?.length && e.contacts.includes(currentUserUuid)) {
        myTask = true;
      }
    }
  }

  const assignee = eventAssigneesText(e, functions, employeesShortNamesAndI, contactsAsUsers);

  let href = '/account/' + organization.organizationId + '/' + 318434 + '/eventOccurrences/' + e.uuid;
  if (!e.isConfirmedEntity) {
    href += '?edit';
  }

  return {
    uuid: e.uuid,
    name: e.name,
    href,
    eventNotes: e.notes,
    assignee: singleUserVersion ? '' : assignee,
    myTask: myTask,
    overdue: overdue,
    dateTimeForOrdering: e.date + ' ' + (e.time && e.time !== 'NONE' ? e.time : '00:00'),
    done: done,
    doneDate: e.doneDate ?? '',
    doneAtDisplay: doneAtDisplay,
    doneByEmployeeUuid: e.doneByEmployeeUuid,
    doneText: doneText,
    hasDraft: e.hasDraft,
    isConfirmedEntity: e.isConfirmedEntity,
    draftName: e.draftName ?? '',
  };
}

export const todoList = createSelector(
  overdueEventOccurrences,
  unscheduledEventOccurrences,
  getOrganization,
  employeesShortNamesAndMe,
  currentUserUuid,
  getContactsAsUsers,
  employeesShortNamesAndMe,
  singleUserVersion,
  functionsNotDeleted,
  (
    overdueEventOccurrences,
    unscheduledEventOccurrences,
    organization,
    employeesShortNamesAndMe,
    currentUserUuid,
    contactsAsUsers,
    employeesShortNamesAndI,
    singleUserVersion,
    functions,
  ) => {
    if (organization === undefined) return [];
    return [
      ...overdueEventOccurrences.map((x) =>
        homePageEventItem(
          x,
          true,
          organization,
          employeesShortNamesAndMe,
          currentUserUuid ?? '',
          contactsAsUsers,
          employeesShortNamesAndI,
          singleUserVersion,
          functions,
        ),
      ),
      ...unscheduledEventOccurrences.map((x) =>
        homePageEventItem(
          x,
          false,
          organization,
          employeesShortNamesAndMe,
          currentUserUuid ?? '',
          contactsAsUsers,
          employeesShortNamesAndI,
          singleUserVersion,
          functions,
        ),
      ),
    ];
  },
);

/**
 * event occurrences given the current value of week start
 */
export const currentWeekEventOccurrences = createSelector(
  eventOccurrencesNotDeleted,
  weekStart,
  (occurrences, weekStart) => {
    const startDate = LocalDate.fromString(weekStart);
    const endDate = startDate.plusDays(6);

    return occurrences.filter((e) => {
      if (e.date === null || e.date === undefined) return false;
      const d = LocalDate.fromString(e.date);

      return d.inRange(startDate, endDate);
    });
  },
);

export const currentWeekTodoEventOccurrences = createSelector(
  eventOccurrencesNotDeleted,
  weekStart,
  todoListHideAfterDaysOption,
  now,
  (occurrences, weekStart, hideAfterDays, t) => {
    const startDate = LocalDate.fromString(weekStart);
    const endDate = startDate.plusDays(6);
    const excludeDoneTasksBefore = t.minusDays(hideAfterDays);

    return occurrences.filter((e) => {
      if (e.date !== null || e.doneDate === null) return false;
      const d = LocalDate.fromString(e.doneDate);

      return d.inRange(startDate, endDate) && LocalDate.fromString(e.doneDate).isSameOrBefore(excludeDoneTasksBefore);
    });
  },
);

export interface CalendarDayTask {
  dateTimeForOrdering: string;
  time?: string;
  uuid: string;
  name: string;
  eventNotes: string;
  assignee: string;
  href: string;
  myTask: boolean;
  timeOfDayDisplay: string;
  instanceTime?: string;
  persistent?: boolean;
  overdue: boolean;
  doneDate?: string;
  doneByEmployeeUuid?: string;
  doneText: string;
  locked: boolean;
  accessible: boolean;
  hasDraft: boolean;
  meeting?: boolean;
  isConfirmedEntity: boolean;
  draftName: string;
}

interface CalendarDay {
  date: string;
  holiday: string;
  tasks: CalendarDayTask[];
}

export const calendarTaskInstances = createSelector(
  currentUserUuid,
  currentWeekEventOccurrences,
  employeesShortNamesAndMe,
  getContactsAsUsers,
  holidays,
  weekStart,
  getOrganization,
  singleUserVersion,
  meetings,
  employeesShortNamesAndI,
  today,
  currentWeekTodoEventOccurrences,
  functionsNotDeleted,
  (
    currentUserUuid,
    eventOccurrences,
    employeesShortNamesAndMe,
    contactsAsUsers,
    holidays,
    weekStart,
    organization,
    singleUserVersion,
    meetings,
    employeesShortNamesAndI,
    today,
    eventOccurrencesForTodo,
    functions,
  ): CalendarDay[] => {
    if (organization === undefined) return [];

    const days: CalendarDay[] = [];
    const t = LocalDate.fromString(today);

    for (let d = 0; d < 7; d++) {
      const day: CalendarDay = {
        date: LocalDate.fromString(weekStart).plusDays(d).toString(),
        holiday: '',
        tasks: [],
      };

      const holiday = holidays.filter((holiday) =>
        LocalDate.fromString(holiday.date).isSame(LocalDate.fromString(day.date)),
      )[0];

      if (holiday) {
        day.holiday = holiday.description;
      }
      eventOccurrences
        .filter((item) => LocalDate.fromString(item.date).isSame(LocalDate.fromString(day.date)))
        .forEach((e) => {
          const overdue = e.date !== null && e.persistent && !e.doneDate && LocalDate.fromString(e.date).isBefore(t);
          const item = homePageEventItem(
            e,
            overdue,
            organization,
            employeesShortNamesAndMe,
            currentUserUuid ?? '',
            contactsAsUsers,
            employeesShortNamesAndI,
            singleUserVersion,
            functions,
          );
          const doneText = doneInformation(item, singleUserVersion, employeesShortNamesAndMe, contactsAsUsers).doneText;
          day.tasks.push({
            timeOfDayDisplay: timeDescription(e.time ?? '', e.durationMinutes),
            instanceTime: e.time,
            uuid: e.uuid,
            name: e.name ?? '',
            eventNotes: item.eventNotes,
            assignee: item.assignee && !singleUserVersion ? item.assignee : '',
            href: item.href,
            myTask: item.myTask,
            dateTimeForOrdering: item.dateTimeForOrdering,
            persistent: e.persistent,
            overdue: item.overdue,
            doneDate: item.doneDate,
            doneByEmployeeUuid: item.doneByEmployeeUuid,
            doneText: doneText,
            locked: false,
            accessible: true,
            hasDraft: item.hasDraft,
            isConfirmedEntity: e.isConfirmedEntity,
            draftName: e.draftName ?? '',
          });
        });
      eventOccurrencesForTodo
        .filter((item) => LocalDate.fromString(item.doneDate).isSame(LocalDate.fromString(day.date)))
        .forEach((e) => {
          const overdue = false;
          const item = homePageEventItem(
            e,
            overdue,
            organization,
            employeesShortNamesAndMe,
            currentUserUuid ?? '',
            contactsAsUsers,
            employeesShortNamesAndI,
            singleUserVersion,
            functions,
          );
          const doneText = doneInformation(item, singleUserVersion, employeesShortNamesAndMe, contactsAsUsers).doneText;
          day.tasks.push({
            timeOfDayDisplay: '',
            instanceTime: '',
            uuid: e.uuid,
            name: e.name ?? '',
            eventNotes: item.eventNotes,
            assignee: item.assignee && !singleUserVersion ? item.assignee : '',
            href: item.href,
            myTask: item.myTask,
            dateTimeForOrdering: item.dateTimeForOrdering,
            persistent: e.persistent,
            overdue: item.overdue,
            doneDate: item.doneDate,
            doneByEmployeeUuid: item.doneByEmployeeUuid,
            doneText: doneText,
            locked: false,
            accessible: true,
            hasDraft: item.hasDraft,
            isConfirmedEntity: e.isConfirmedEntity,
            draftName: e.draftName ?? '',
          });
        });
      if (!singleUserVersion) {
        const occurrences = meetings.flatMap((m) => {
          return m.meetingOccurrences.filter((o) => o.date === day.date);
        });
        occurrences.forEach((o) => {
          const item = organization.meetingOccurrences.find((x) => x.uuid === o.uuid);
          const assignee = meetingParticipantsText(item, functions, employeesShortNamesAndI, contactsAsUsers, true);
          let myTask = false;
          if (item) {
            if (
              item.participants.includes(currentUserUuid) ||
              item.responsibleEmployeeUuid?.includes(currentUserUuid)
            ) {
              myTask = true;
            }
            if (item.responsibleUuid) {
              const responsibleFunction = organization.functionsById[item.responsibleUuid];
              if (responsibleFunction.employees.includes(currentUserUuid)) {
                myTask = true;
              }
            }
          }
          if (item !== undefined) {
            let href = '/account/' + organization.organizationId + '/318434/meetingOccurrences/' + o.uuid;
            if (!o.isConfirmedEntity) {
              href += '?edit';
            }
            day.tasks.push({
              timeOfDayDisplay: timeDescription(item.time ?? '', item.durationMinutes),
              instanceTime: item.time,
              uuid: item.uuid,
              name: item.name ?? '',
              eventNotes: '',
              assignee,
              href,
              myTask,
              dateTimeForOrdering: item.date + ' ' + (item.time && item.time !== 'NONE' ? item.time : '00:00'),
              persistent: false,
              overdue: false,
              doneDate: '',
              doneByEmployeeUuid: '',
              doneText: '',
              locked: o.classified,
              accessible: !o.restricted,
              hasDraft: item.hasDraft,
              meeting: true,
              isConfirmedEntity: o.isConfirmedEntity,
              draftName: o.draftName ?? '',
            });
          }
        });
      }
      day.tasks = sortBy(day.tasks, ['dateTimeForOrdering']);
      days.push(day);
    }
    return days;
  },
);

/**
 * Returns the number of undone todoitems plus events and meeting for the rest of the week, for current employee
 */
export const currentWeekRemainingTasksAndMeetingsForEmployee = createSelector(
  calendarTaskInstances,
  todoList,
  (tasks, todo): number => {
    const today = LocalDate.now();
    const remainingDatedTasksAndMeetings: CalendarDayTask[] = [];
    tasks.forEach((day) => {
      day.tasks.forEach((task) => {
        if (
          task.myTask &&
          !task.doneDate &&
          (LocalDate.fromString(task.dateTimeForOrdering).isSameOrAfter(today) || task.persistent)
        ) {
          remainingDatedTasksAndMeetings.push(task);
        }
      });
    });
    const remainingTodoTasks = todo.filter((t) => {
      return t.myTask && !t.doneDate;
    });
    return remainingDatedTasksAndMeetings.length + remainingTodoTasks.length;
  },
);
