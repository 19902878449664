import { getMonthName } from '../date.js';
import { BaseInstanceRule } from './base-instance-rule.js';
import type { LocalDate } from 'src/utilities/local-date.js';

export class YearlyByDayOfMonthInstanceRule extends BaseInstanceRule {
  private static intervalDescriptions = ['', ' annet', ' tredje', ' fjerde', ' femte', ' sjette'];
  private readonly byMonth: number;
  private readonly byMonthDay: number;

  public constructor(byMonth: number, byMonthDay: number) {
    super();
    this.byMonth = byMonth;
    this.byMonthDay = byMonthDay;
  }

  public matches(date: LocalDate): boolean {
    return date.month() === this.byMonth && date.day() === this.byMonthDay;
  }

  public optionsToString(interval: number): string {
    return 'FREQ=YEARLY;INTERVAL=' + interval + ';BYMONTHDAY=' + this.byMonthDay + ';BYMONTH=' + this.byMonth;
  }

  public scheduleDescription(interval: number): string {
    const i = YearlyByDayOfMonthInstanceRule.intervalDescriptions[interval - 1];
    return 'Hvert' + i + ' år på den ' + this.byMonthDay + '. ' + getMonthName(this.byMonth);
  }
}
