import type { State } from '../../store/types.js';

import {
  activityCodesAvailable,
  BASE_PATH,
  currentEmployeeUuid,
  getOrganizationId,
  getPartnersAndContacts,
  key,
  writeAccess,
} from '../../store';
import { PartnersPageViewModel } from 'src/pages/partners-page/d-partners-page-content';
import { AbstractPageView } from 'src/pages/abstract-page-view';

export function partnersPageView(hrefPrefix: string, viewModel: AbstractPageView, state: State): PartnersPageViewModel {
  const o = state.organization;

  if (o === undefined) {
    throw new Error('Illegal state (E151), organization not found');
  }

  return {
    ...viewModel,
    type: 'partners-page',
    icon: 'partners',
    href: hrefPrefix,
    writeAccess: writeAccess(state),
    currentEmployeeUuid: currentEmployeeUuid(state),
    organizationId: getOrganizationId(state),
    availableActivityCodes: activityCodesAvailable(),
    pdfHref: BASE_PATH + '/organizations/' + o.organizationId + '/overview.pdf?key=' + key(state),
    partnersAndContacts: getPartnersAndContacts(state),
  };
}
