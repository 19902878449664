import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../library/editors/components/d-select-dropdown-or-add.js';
import '../../../../library/editors/elements/d-add-remove-buttons.js';
import type {
  InfosecDataType,
  InfosecCategory,
  InfosecDataItem,
  InfosecStorageUnit,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { uuid } from 'src/utilities/text.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { NewNameDialog, NewNameResult } from 'src/library/editors/components/new-name-dialog.js';

export interface DataItemRemoteServerChange {
  uuid?: string;
  newSupplier: boolean;
  newSupplierName?: string;
  supplierUuid: string;
  dataType: string;
  category: string;
}

/**
 * Either display an existing data item stored on a remote server or allows the creation of a new data item on a
 * remote server. Also allows a new partner to be registered when creating the new data item.
 *
 * Existing remote servers can not be changed.
 *
 * A partner is only available if they do not already supply a remote server.
 *
 * @fires data-item-changed
 *
 *
 *
 */
@customElement('d-edit-data-item-remote-server')
export class DEditDataItemRemoteServer extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
    d-select-dropdown-or-add {
      flex: 1;
    }
  `;

  /**
   * The data type. Used for new items.
   */
  @property({ type: Object })
  dataType!: InfosecDataType;

  /**
   * The category. Used for new items.
   */
  @property({ type: Object })
  category!: InfosecCategory;

  /**
   * The remote servers. Used to lookup the partner given the storage unit.
   */
  @property({ type: Array })
  remoteServers: InfosecStorageUnit[] = [];

  /**
   * The available partners.
   */
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];

  /**
   * The data item. Can be an "empty" data item.
   */
  @property({ type: Object })
  dataItem!: InfosecDataItem;

  /**
   * The label for this remote service. Usually found based on type and category.
   */
  @property({ type: String })
  label = '';

  _partnerServerExists(partnerUuid) {
    return (
      this.remoteServers.filter((remoteServer) => {
        return this.dataItem.storageUnit === remoteServer.uuid && remoteServer.partnerUuid === partnerUuid;
      }).length > 0
    );
  }

  _options(partnerUuid) {
    return this.partners.filter((partner) => {
      return !this._partnerServerExists(partner.value) || partner.value === partnerUuid;
    });
  }

  _remoteServerPartner(remoteServerUuid) {
    const remoteServers = this.remoteServers.filter((remoteServer) => {
      return remoteServer.uuid === remoteServerUuid;
    });
    if (remoteServers.length) {
      return remoteServers[0].partnerUuid;
    }
    return '';
  }

  _setRemoteServerAndDataItem(partnerUuid) {
    let remoteServerUuid = '';
    this.remoteServers = this.remoteServers.map((remoteServer) => {
      if (remoteServer.uuid === this.dataItem.storageUnit) {
        remoteServer.partnerUuid = partnerUuid;
        remoteServerUuid = remoteServer.uuid;
      }
      return remoteServer;
    });
    if (remoteServerUuid === '') {
      remoteServerUuid = uuid();
      this.remoteServers.push({
        uuid: remoteServerUuid,
        name: '',
        unitType: 'remoteServers',
        computerType: '',
        application: '',
        partnerUuid: partnerUuid,
        networkUuid: '',
      });
    }
    let dataItemUuid = this.dataItem.uuid;
    if (dataItemUuid === '') {
      dataItemUuid = uuid();
    }
    this.dataItem = {
      ...this.dataItem,
      storageUnit: remoteServerUuid,
      storageUnitType: 'remoteServers',
      uuid: dataItemUuid,
    };
    this._dispatchPartnersChanged();
    this._dispatchRemoteServersChanged();
    this._dispatchDataItemChanged();
  }

  async _valueChanged(partnerUuid) {
    if (partnerUuid === 'NEW') {
      const result: NewNameResult = await NewNameDialog.open({ title: 'Ny samarbeidspartner' });
      if (result.action === 'done') {
        const partnerUuid = uuid();
        this.partners = [
          ...this.partners,
          {
            value: partnerUuid,
            text: result.name,
          },
        ];
        this._setRemoteServerAndDataItem(partnerUuid);
        this.dispatchEvent(
          new CustomEvent<DataItemRemoteServerChange>('data-item-changed', {
            composed: true,
            bubbles: true,
            detail: {
              newSupplier: true,
              newSupplierName: result.name,
              supplierUuid: partnerUuid,
              dataType: this.dataType.type,
              category: this.category.category,
            },
          }),
        );
      }
    } else {
      this.dispatchEvent(
        new CustomEvent<DataItemRemoteServerChange>('data-item-changed', {
          composed: true,
          bubbles: true,
          detail: {
            newSupplier: false,
            supplierUuid: partnerUuid,
            dataType: this.dataType.type,
            category: this.category.category,
          },
        }),
      );
    }
  }

  _dispatchDataItemChanged() {
    this.dispatchEvent(
      new CustomEvent('data-item-updated', {
        bubbles: true,
        composed: true,
        detail: this.dataItem,
      }),
    );
  }

  _dispatchRemoteServersChanged() {
    this.dispatchEvent(
      new CustomEvent('remote-servers-changed', {
        bubbles: true,
        composed: true,
        detail: this.remoteServers,
      }),
    );
  }

  _dispatchPartnersChanged() {
    this.dispatchEvent(
      new CustomEvent('partners-changed', {
        bubbles: true,
        composed: true,
        detail: this.partners,
      }),
    );
  }

  render() {
    const partner = this._remoteServerPartner(this.dataItem.storageUnit);
    return html`
      <d-select-dropdown-or-add
        .label=${this.label}
        theme-page
        placeholder="Velg leverandør av fjernserver"
        unselectablePlaceholder
        firstItemText="Registrer leverandør av fjernserver"
        additionalItemText="Annen leverandør"
        ?disabled=${partner !== ''}
        .options=${this._options(partner)}
        .value=${partner}
        @value-changed=${(e) => this._valueChanged(e.detail.value)}
      ></d-select-dropdown-or-add>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-data-item-remote-server': DEditDataItemRemoteServer;
  }
}
