import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ItemTable, ItemTableColumn, TableItem } from '../../library/abstracts/item-table.js';
import type { GestureType } from '../../library/fields/d-spinner-robot.js';
import '../../library/fields/d-spinner-robot.js';

export interface TableItemsSubstance extends TableItem {
  state: '' | 'pending' | 'unreadable';
  uploaded: string;
  uploadedSortValue: string;
  name: string;
  supplier: string;
  hazardLabels: string[];
  applications: string;
  location: string;
  risk: string;
  uuid: string;
}

/**
 *
 * STATUS OK
 */
@customElement('d-substances-table')
export class DSubstancesTable extends ItemTable<TableItemsSubstance> {
  static readonly styles = [
    ...ItemTable.styles,
    css`
      :host {
        display: block;
      }

      .robot {
        display: flex;
        align-items: center;
      }

      d-spinner-robot {
        position: relative;
        display: inline-block;
        width: 40px;
        margin-right: 10px;
      }

      /* TODO Define column widths. Currently setting labels and risk to fixed width */
      td[data-field='name'] {
        width: 300px;
      }
      td[data-field='hazardLabels'] {
        width: 100px;
      }
      td[data-field='applications'] {
        width: 300px;
      }
      td[data-field='risk'] {
        width: 100px;
      }

      td[data-field='hazardLabels'] table {
        display: block;
        margin: 4px 40px 4px 6px;
        width: 100%;
      }

      td[data-field='hazardLabels'] table tr {
        display: inline-block;
        margin: -10px;
      }

      td[data-field='hazardLabels'] table tr:last-child {
        margin-right: 10px;
      }

      td[data-field='hazardLabels'] table td {
        display: inline-block;
        border: none;
        width: 40px;
        height: 40px;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        font-size: 0;
      }

      td[data-value='GHS01'] {
        background-image: url(/images/ghs/ghs-01-explosive.svg);
      }

      td[data-value='GHS02'] {
        background-image: url(/images/ghs/ghs-02-flammable.svg);
      }

      td[data-value='GHS03'] {
        background-image: url(/images/ghs/ghs-03-oxidizing.svg);
      }

      td[data-value='GHS04'] {
        background-image: url(/images/ghs/ghs-04-compressed-gas.svg);
      }

      td[data-value='GHS05'] {
        background-image: url(/images/ghs/ghs-05-corrosive.svg);
      }

      td[data-value='GHS06'] {
        background-image: url(/images/ghs/ghs-06-toxic.svg);
      }

      td[data-value='GHS07'] {
        background-image: url(/images/ghs/ghs-07-harmful.svg);
      }

      td[data-value='GHS08'] {
        background-image: url(/images/ghs/ghs-08-health-hazard.svg);
      }

      td[data-value='GHS09'] {
        background-image: url(/images/ghs/ghs-09-enviromental-hazard.svg);
      }

      th[data-field='risk'] d-label {
        display: none;
      }

      th[data-field='risk']:after {
        content: 'Risiko ikke angitt';
        display: inline-block;
        border-radius: 6px;
        background: silver;
        padding: 6px 10px;
        color: white;
        font-family: var(--mainSans), sans-serif;
        font-size: 18px;
        font-weight: 500;
      }

      td[data-field='risk'],
      tr:hover td[data-field='risk'] {
        font-size: 0;
      }

      td[data-field='risk'] d-label,
      tr:hover td[data-field='risk'] d-label {
        font-size: 15px;
      }

      td[data-field='risk']:after {
        content: '';
        display: inline-block;
        margin: -2px 0;
        border-radius: 3px;
        padding: 4px 6px;
        color: white;
        font-family: var(--mainSans), sans-serif;
        font-size: 13px;
        font-weight: 500;
      }

      th[data-field='risk'][data-value='1']:after,
      td[data-field='risk'][data-value='1']:after {
        background: hsl(106, 87%, 43%);
      }

      th[data-field='risk'][data-value='2']:after,
      td[data-field='risk'][data-value='2']:after {
        background: hsl(62, 55%, 58%);
      }

      th[data-field='risk'][data-value='3']:after,
      td[data-field='risk'][data-value='3']:after {
        background: hsl(31, 93%, 51%);
      }

      th[data-field='risk'][data-value='4']:after,
      td[data-field='risk'][data-value='4']:after {
        background: hsl(0, 80%, 56%);
      }

      th[data-field='risk'][data-value='1']:after {
        content: 'Lav risiko';
      }

      th[data-field='risk'][data-value='2']:after {
        content: 'Moderat risiko';
      }

      th[data-field='risk'][data-value='3']:after {
        content: 'Høy risiko';
      }

      th[data-field='risk'][data-value='4']:after {
        content: 'Svært høy risiko';
      }

      td[data-field='risk'][data-value='1']:after {
        content: 'Lav';
      }

      td[data-field='risk'][data-value='2']:after {
        content: 'Moderat';
      }

      td[data-field='risk'][data-value='3']:after {
        content: 'Høy';
      }

      td[data-field='risk'][data-value='4']:after {
        content: 'Svært høy';
      }

      @media only screen and (max-width: 700px) {
        td[data-field='hazardLabels'] table {
          margin: 8px;
        }
      }
    `,
  ];
  @property({ type: Array })
  items: TableItemsSubstance[] = [];
  @property({ type: Boolean, attribute: 'no-animation' })
  noAnimation = false;
  protected columns: ItemTableColumn[] = [
    {
      name: 'name',
      field: '',
      label: 'Navn',
    },
    {
      name: 'supplier',
      field: '',
      label: 'Leverandør',
      groupOption: true,
      hide: true,
    },
    {
      name: 'hazardLabels',
      field: '',
      label: '',
    },
    {
      name: 'applications',
      field: '',
      label: 'Bruksområder',
    },
    {
      name: 'location',
      field: '',
      label: 'Lagringssted',
      groupOption: true,
      hide: true,
    },
    {
      name: 'risk',
      field: '',
      label: 'Risiko',
      groupOption: true,
    },
    {
      name: 'uploaded',
      field: '',
      label: 'Opplastingstidspunkt',
      groupOption: true,
      hide: true,
    },
  ];

  protected get sortGroupsDescending(): boolean {
    return this.groupBy === 'uploaded' || this.groupBy === 'risk';
  }

  includeItem(item: TableItemsSubstance): boolean {
    console.log('filtering', item);
    return this.filterFields(item.name, item.applications);
  }

  pendingRow(item: TableItemsSubstance, colspan: number) {
    let gesture: GestureType[] = ['readHorizontal'];
    let message = 'Leser sikkerhetsdatablad…';
    if (item.state === 'unreadable') {
      gesture = ['blink', 'surprised'];
      message = 'Kunne ikke lese sikkerhetsdatablad – trykk for å registrere manuelt';

      return html`
        <tr class="click-elm item" href="${item.href}" @click=${(e) => this.rowClick(item.href, e)}>
          <td colspan="${colspan}">
            <div class="robot">
              <d-spinner-robot .gesture=${gesture} size="40" ?no-animation=${this.noAnimation}></d-spinner-robot>
              <span>${message}</span>
            </div>
          </td>
        </tr>
      `;
    }
    return html`
      <tr>
        <td colspan="${colspan}">
          <div class="robot">
            <d-spinner-robot .gesture=${gesture} size="40" ?no-animation=${this.noAnimation}></d-spinner-robot>
            <span>${message}</span>
          </div>
        </td>
      </tr>
    `;
  }

  override renderRow(item, columns) {
    return html`
      ${item.state ? html` ${this.pendingRow(item, columns.length)} ` : html` ${super.renderRow(item, columns)} `}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-substances-table': DSubstancesTable;
  }
}
