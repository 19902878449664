import { html } from 'lit';
import './d-function-reassignator-table.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/elements/d-label.js';
import '../../library/elements/d-section.js';
import { customElement, property, state } from 'lit/decorators.js';
import { BaseDialog, DialogCancelResult } from 'src/library/components/BaseDialog.js';
import type { FunctionAssignment } from 'src/content/employees/d-function-reassignator-table.js';

export interface ReassignFunctionsInput {
  employees: { uuid: string; firstName: string; lastName: string }[];
  functions: { uuid: string; name: string; type: string }[];
  inactiveEmployeeId: string;
}

export interface DialogConfirmResult {
  action: 'confirm';
}

export type ReassignFunctionsResult =
  | { action: 'confirm'; assignments: { functionUuid: string; employeeUuid: string }[] }
  | DialogCancelResult;

/**
 *
 * USAGE:
 *    d-employee-view
 *
 */
@customElement('reassign-functions-dialog')
export class ReassignFunctionsDialog extends BaseDialog<ReassignFunctionsInput, ReassignFunctionsResult> {
  @property({ type: Array })
  employees: { uuid: string; firstName: string; lastName: string }[] = [];
  @property({ type: Array })
  selections: { functionUuid: string; employeeUuid: string }[] = [];
  @property({ type: Array })
  functions: { uuid: string; name: string; type: string }[] = [];
  @property({ type: String })
  inactiveEmployeeId = '';
  footerActions = [
    { name: 'Avbryt', action: 'cancel' },
    { name: 'Overfør', action: 'confirm' },
  ];
  title = 'Overfør ansvarsområder';
  width = 500;
  @state()
  private assignments: FunctionAssignment[] = [];

  /*
  open(settings) {
    if (this.inactiveEmployeeId !== settings.inactiveEmployeeId) {
      this.selections = [];
      this.functions = [];
      this.employees = [];
    }

    this.inactiveEmployeeId = settings.inactiveEmployeeId;

    const fFunctions = settings.functions.filter((funct) => {
      const foundInEmployees = funct.employees.filter((employee) => employee === this.inactiveEmployeeId);
      return funct.type !== 'COMMON' && foundInEmployees.length > 0;
    });

    const employees = settings.employees.filter(
      (employee) => employee.status === 'ACTIVE' && employee.uuid !== settings.inactiveEmployeeId,
    );

    this.functions = fFunctions;
    this.employees = employees;
  }

   */

  private get info() {
    return '<p>Hvem skal overta for ' + this._getEmployeeNameByUuid(this.employees, this.inactiveEmployeeId) + '?</p>';
  }

  _employeeOptions(funct, employees) {
    const options: { value: string; text: string }[] = [];

    if (funct.type === 'SIMULTANEOUS') {
      options.push({ value: '', text: 'Ingen' });
    }

    employees
      .filter((e) => {
        return e.uuid !== this.inactiveEmployeeId;
      })
      .forEach(function (employee) {
        options.push({
          value: employee.uuid,
          text: employee.firstName + ' ' + employee.lastName,
        });
      });

    return options;
  }

  _onAssigneeChanged(evt, detail) {
    if (evt.model.item) {
      const selection = this.selections.filter(function (sel) {
        return sel.functionUuid == evt.model.item.uuid;
      })[0];
      if (selection) {
        selection.employeeUuid = detail.value;
      }
    }
  }

  _updateDone() {
    this.show = false;
  }

  _getEmployeeName(employee) {
    if (employee.lastName) {
      return employee.firstName + ' ' + employee.lastName;
    } else {
      return employee.firstName;
    }
  }

  _getEmployeeNameByUuid(employees, uuid) {
    const employee = employees.filter((e) => {
      return e.uuid === uuid;
    });
    if (employee.length) {
      return this._getEmployeeName(employee[0]);
    }
    return '';
  }

  _showMe(w) {
    return JSON.stringify(w);
  }

  renderBody() {
    return html`
      <d-section>
        <d-view-info .content=${this.info}></d-view-info>
      </d-section>
      <d-function-reassignator-table
        .functions=${this.assignments}
        @assignment-changed=${this.onAssignmentChanged}
      ></d-function-reassignator-table>
    `;
  }

  protected fetchResult(detail: string | undefined): ReassignFunctionsResult {
    if (detail === 'confirm') {
      return {
        action: 'confirm',
        assignments: this.assignments.map((a) => ({ functionUuid: a.uuid, employeeUuid: a.value })),
      };
    }
    return {
      action: 'cancel',
    };
  }

  protected initializeDialog(input: ReassignFunctionsInput) {
    this.inactiveEmployeeId = input.inactiveEmployeeId;
    this.employees = input.employees;
    this.functions = input.functions;

    this.assignments = this.functionsToReassign();
  }

  private functionsToReassign() {
    return this.functions.map((f) => {
      return {
        uuid: f.uuid,
        label: f.name,
        value: this._employeeOptions(f, this.employees)[0].value,
        options: this._employeeOptions(f, this.employees),
      };
    });
  }

  private onAssignmentChanged(e: CustomEvent<FunctionAssignment[]>) {
    this.assignments = e.detail;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'reassign-functions-dialog': ReassignFunctionsDialog;
  }
}
