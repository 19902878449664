import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import '../elements/d-checkbox.js';
import '../elements/d-select-tag.js';
import '../../fields/d-expansion.js';

/**
 *
 * STATUS OK
 */
@customElement('d-edit-classification')
export class DEditClassification extends LitElement {
  ready() {
    if (this.field && this.fieldsByCode[this.field]) {
      this.label = this.fieldsByCode[this.field].label;
    }
  }

  private get locked(): boolean {
    return this.classification === 'CONFIDENTIAL';
  }

  private onLockedChanged(e: CustomEvent<{ checked: boolean }>) {
    e.stopPropagation();
    if (e.detail.checked) {
      // Adds the current user since that is the one that is disabled.
      const c = this.accessControlOptions.filter((o) => o.disabled)[0];
      if (!this.accessControl.includes(c.value)) {
        this.accessControl = [...this.accessControl, c.value];
      }
    }
    this.classification = e.detail.checked ? 'CONFIDENTIAL' : 'NONE';
    this.fireValueChanged();
  }

  @property({ type: Object })
  fieldsByCode = {};
  @property({ type: String })
  field = 'generalFields_limitedAccess';
  @property({ type: String })
  label = '';
  @property({ type: Array })
  accessControl: string[] = [];
  @property({ type: String })
  classification = 'NONE';
  @property({ type: Array })
  accessControlOptions: { value: string; text: string; disabled?: boolean }[] = [];

  private fireValueChanged() {
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { classification: this.classification, accessControl: this.accessControl },
      }),
    );
  }

  static readonly styles = css`
    :host {
      display: block;
    }
    d-checkbox {
      padding-left: 2px;
      margin: 0;
    }

    d-select-tag {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  `;

  render() {
    return html`
      <div class="sectionElementInner">
        <d-checkbox
          .checked=${this.locked}
          option-field="generalFields_limitedAccess"
          @checked-changed=${this.onLockedChanged}
        ></d-checkbox>
        <d-expansion ?opened=${this.locked}>
          <d-select-tag
            .options=${this.accessControlOptions}
            .value=${this.accessControl}
            @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
              e.stopPropagation();
              this.accessControl = [...e.detail.value];
              this.fireValueChanged();
            }}
          >
          </d-select-tag>
        </d-expansion>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-classification': DEditClassification;
  }
}
