import type { OrganizationState, State } from '../types.js';
import { toList } from './utilities.js';
import { createSelector } from '@reduxjs/toolkit';
import { employeeNamesAsText, employeeNamesById } from './organization-employees.js';
import { partnerNamesAsText, partnerNamesById } from './organization-partners.js';
import type {
  ConstitutionalDocumentViewModel,
  ContractViewModel,
  DocumentViewModel,
  GuidelineViewModel,
  ReportViewModel,
} from '../api';

export function getContracts(state: State): ContractViewModel[] {
  return state.organization !== undefined ? toList(state.organization.contractsById) : [];
}

export function constitutionalDocumentsNotDeleted(state: State): ConstitutionalDocumentViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.constitutionalDocumentsById).filter((e) => !e.deleted);
}

export function contractsNotDeleted(state: State): ContractViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.contractsById).filter((e) => !e.deleted);
}

export function documentsNotDeleted(state: State): DocumentViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.documentsById).filter((e) => !e.deleted);
}

export function guidelinesNotDeleted(state: State): GuidelineViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.guidelinesById).filter((e) => !e.deleted);
}

export function reportsNotDeleted(state: State): ReportViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.reportsById).filter((e) => !e.deleted);
}

export function constitutionalDocumentsDeleted(state: State): ConstitutionalDocumentViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.constitutionalDocumentsById).filter((e) => e.deleted);
}

export function contractsDeleted(state: State): ContractViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.contractsById).filter((e) => e.deleted);
}

export function documentsDeleted(state: State): DocumentViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.documentsById).filter((e) => e.deleted);
}

export function guidelinesDeleted(state: State): GuidelineViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.guidelinesById).filter((e) => e.deleted);
}

export function reportsDeleted(state: State): ReportViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.reportsById).filter((e) => e.deleted);
}

export function namesAsText(item: { employees?: string[]; partners?: string[] }, o: OrganizationState): string {
  const e = employeeNamesAsText(item, o);
  const p = partnerNamesAsText(item, o);

  const n: string[] = [];
  if (e !== 'Ingen') {
    n.push(e);
  }
  if (p !== 'Ingen') {
    n.push(p);
  }

  if (n.length === 0) {
    return 'Ingen';
  } else {
    return n.join(', ');
  }
}

export const contractsNotDeletedWithParties = createSelector(
  contractsNotDeleted,
  employeeNamesById,
  partnerNamesById,
  (contracts, employees, partners) => {
    const withParties = (e: ContractViewModel): ContractViewModel & { nameWithParties: string } => {
      const employeeNames: string[] =
        e.employees === undefined
          ? []
          : e.employees.map((id) => {
              return employees[id];
            });
      employeeNames.sort();
      const partnerNames: string[] =
        e.partners === undefined
          ? []
          : e.partners.map((id) => {
              return partners[id];
            });
      partnerNames.sort();

      const names = [...employeeNames, ...partnerNames];
      return Object.assign({}, e, {
        party: names.join(', '),
        nameWithParties: e.name + (names.length ? ' (' + names.join(', ') + ')' : ''),
      });
    };

    return contracts.map(withParties);
  },
);

export function toContractViewModel(
  item: ContractViewModel,
  o: OrganizationState,
): ContractViewModel & { contractPartyNames: string } {
  return Object.assign({}, item, {
    contractPartyNames: namesAsText(item, o),
  });
}

export function toConstitutionalDocumentViewModel(
  item: ConstitutionalDocumentViewModel,
  o: OrganizationState,
): ConstitutionalDocumentViewModel & { contractPartyNames: string } {
  return Object.assign({}, item, {
    contractPartyNames: namesAsText(item, o),
  });
}

export function toDocumentViewModel(item: DocumentViewModel): DocumentViewModel {
  return Object.assign({}, item);
}
export function toGuidelineViewModel(item: GuidelineViewModel): GuidelineViewModel {
  return Object.assign({}, item);
}

export function toReportViewModel(item: ReportViewModel, o: OrganizationState): ReportViewModel {
  const employeeName =
    item.employeeUuid && o.employeesById[item.employeeUuid] ? o.employeesById[item.employeeUuid].name : '';
  const contactName =
    item.partnerContactUuid && o.contactsById[item.partnerContactUuid]
      ? o.contactsById[item.partnerContactUuid].firstName + ' ' + o.contactsById[item.partnerContactUuid].lastName
      : '';

  return Object.assign({}, item, {
    writtenByName: employeeName || contactName,
  });
}
