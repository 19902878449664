/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationSettingsUpdateMessage
 */
export interface OrganizationSettingsUpdateMessage {
  /**
   *
   * @type {string}
   * @memberof OrganizationSettingsUpdateMessage
   */
  invoiceAddress?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationSettingsUpdateMessage
   */
  reference?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationSettingsUpdateMessage
   */
  invoiceReceiver?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationSettingsUpdateMessage
   */
  invoiceReference?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationSettingsUpdateMessage
   */
  ownerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationSettingsUpdateMessage
   */
  invoicePostcode?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationSettingsUpdateMessage
   */
  invoiceLocality?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationSettingsUpdateMessage
   */
  specialTerms?: OrganizationSettingsUpdateMessageSpecialTermsEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationSettingsUpdateMessage
   */
  sector?: OrganizationSettingsUpdateMessageSectorEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationSettingsUpdateMessage
   */
  invoiceSendMethod?: OrganizationSettingsUpdateMessageInvoiceSendMethodEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationSettingsUpdateMessage
   */
  invoiceOrganizationNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationSettingsUpdateMessage
   */
  singleUser?: boolean;
}

/**
 * @export
 */
export const OrganizationSettingsUpdateMessageSpecialTermsEnum = {
  None: 'NONE',
  Dnlf: 'DNLF',
  Nff: 'NFF',
  Nof: 'NOF',
  Ntf: 'NTF',
  Nkf: 'NKF',
  Nmf: 'NMF',
  Npf: 'NPF',
  Nnf: 'NNF',
} as const;
export type OrganizationSettingsUpdateMessageSpecialTermsEnum =
  (typeof OrganizationSettingsUpdateMessageSpecialTermsEnum)[keyof typeof OrganizationSettingsUpdateMessageSpecialTermsEnum];

/**
 * @export
 */
export const OrganizationSettingsUpdateMessageSectorEnum = {
  Health: 'HEALTH',
  CraftsTransportationCleaning: 'CRAFTS_TRANSPORTATION_CLEANING',
  FoodAccommodation: 'FOOD_ACCOMMODATION',
  PersonalTradeService: 'PERSONAL_TRADE_SERVICE',
  Office: 'OFFICE',
  Other: 'OTHER',
} as const;
export type OrganizationSettingsUpdateMessageSectorEnum =
  (typeof OrganizationSettingsUpdateMessageSectorEnum)[keyof typeof OrganizationSettingsUpdateMessageSectorEnum];

/**
 * @export
 */
export const OrganizationSettingsUpdateMessageInvoiceSendMethodEnum = {
  Manual: 'MANUAL',
  Ehf: 'EHF',
} as const;
export type OrganizationSettingsUpdateMessageInvoiceSendMethodEnum =
  (typeof OrganizationSettingsUpdateMessageInvoiceSendMethodEnum)[keyof typeof OrganizationSettingsUpdateMessageInvoiceSendMethodEnum];

/**
 * Check if a given object implements the OrganizationSettingsUpdateMessage interface.
 */
export function instanceOfOrganizationSettingsUpdateMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function OrganizationSettingsUpdateMessageFromJSON(json: any): OrganizationSettingsUpdateMessage {
  return OrganizationSettingsUpdateMessageFromJSONTyped(json, false);
}

export function OrganizationSettingsUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationSettingsUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    invoiceAddress: !exists(json, 'invoiceAddress') ? undefined : json['invoiceAddress'],
    reference: !exists(json, 'reference') ? undefined : json['reference'],
    invoiceReceiver: !exists(json, 'invoiceReceiver') ? undefined : json['invoiceReceiver'],
    invoiceReference: !exists(json, 'invoiceReference') ? undefined : json['invoiceReference'],
    ownerEmail: !exists(json, 'ownerEmail') ? undefined : json['ownerEmail'],
    invoicePostcode: !exists(json, 'invoicePostcode') ? undefined : json['invoicePostcode'],
    invoiceLocality: !exists(json, 'invoiceLocality') ? undefined : json['invoiceLocality'],
    specialTerms: !exists(json, 'specialTerms') ? undefined : json['specialTerms'],
    sector: !exists(json, 'sector') ? undefined : json['sector'],
    invoiceSendMethod: !exists(json, 'invoiceSendMethod') ? undefined : json['invoiceSendMethod'],
    invoiceOrganizationNumber: !exists(json, 'invoiceOrganizationNumber')
      ? undefined
      : json['invoiceOrganizationNumber'],
    singleUser: !exists(json, 'singleUser') ? undefined : json['singleUser'],
  };
}

export function OrganizationSettingsUpdateMessageToJSON(value?: OrganizationSettingsUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    invoiceAddress: value.invoiceAddress,
    reference: value.reference,
    invoiceReceiver: value.invoiceReceiver,
    invoiceReference: value.invoiceReference,
    ownerEmail: value.ownerEmail,
    invoicePostcode: value.invoicePostcode,
    invoiceLocality: value.invoiceLocality,
    specialTerms: value.specialTerms,
    sector: value.sector,
    invoiceSendMethod: value.invoiceSendMethod,
    invoiceOrganizationNumber: value.invoiceOrganizationNumber,
    singleUser: value.singleUser,
  };
}
