import { css, html, nothing, TemplateResult } from 'lit';
import { property, state } from 'lit/decorators.js';
import '../../elements/d-label.js';
import '../elements/d-select-dropdown.js';
import '../elements/d-add-remove-buttons.js';
import './d-select-dropdown-or-add.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';

/**
 *
 * Renders a set of items with remove buttons. The last item has a button for add. If the list is empty or the user
 * selects add then a new item is displayed last.
 *
 *
 */
export abstract class BaseListWithAddAndRemove<T> extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      div {
        display: flex;
      }
      .existing + .existing {
        margin-top: 12px;
      }
      :host([bordered]) .existing,
      :host([bordered]) .existing + .existing {
        margin: 0;
        padding: 6px 0;
        border-top: 1px solid var(--borderColorLight);
      }
      d-select-dropdown-or-add {
        flex: 1;
      }
      :host(:not([bordered])) .existing + .new {
        margin-top: 12px;
      }
    `,
  ];
  /**
   * The list of selected values.
   */
  @property({ type: Array })
  value: T[] = [];
  /**
   * Makes the editor always show a dropdown ready to select an additional item,
   * without user having to press the add-button.
   */
  @property({ type: Boolean, attribute: 'always-add-select' })
  alwaysAddSelect = false;
  @property({ type: Boolean, attribute: 'bordered', reflect: true })
  bordered = false;
  @state()
  protected addingItem = false;
  @state()
  protected newItemValue = '';

  abstract renderExistingItem(item: T): TemplateResult<1>;
  abstract renderNewItem(): TemplateResult<1>;

  private renderNew() {
    return html`<div class="new">
      ${this.renderNewItem()}
      ${!this.alwaysAddSelect
        ? html` <d-add-remove-buttons ?showRemove=${false} ?showAdd=${false}></d-add-remove-buttons> `
        : nothing}
    </div> `;
  }

  protected renderContent() {
    return html`
      ${this.value.map((v, index, array) => {
        return html`
          <div class="existing">
            ${this.renderExistingItem(v)}
            <d-add-remove-buttons
              ?showRemove=${true}
              ?showAdd=${index === array.length - 1 && !this.alwaysAddSelect}
              ?adjust-width=${this.alwaysAddSelect}
              @remove=${() => this.removeDataItem(v)}
              @add=${() => this.addDataItem()}
            ></d-add-remove-buttons>
          </div>
        `;
      })}
      ${this.alwaysAddSelect || this.addingItem || this.value.length === 0 ? html`${this.renderNew()}` : nothing}
    `;
  }

  private removeDataItem(item: T) {
    this.value = this.value.filter((v) => v !== item);
    this.dispatchEvent(
      new CustomEvent<{ value: T }>('item-removed', {
        bubbles: true,
        composed: true,
        detail: { value: item },
      }),
    );
  }

  private addDataItem() {
    this.addingItem = true;
  }
}
