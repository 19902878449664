/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateUserCommand
 */
export interface CreateUserCommand {
  /**
   *
   * @type {string}
   * @memberof CreateUserCommand
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserCommand
   */
  serviceType: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserCommand
   */
  module: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserCommand
   */
  target?: string;
}

/**
 * Check if a given object implements the CreateUserCommand interface.
 */
export function instanceOfCreateUserCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'serviceType' in value;
  isInstance = isInstance && 'module' in value;

  return isInstance;
}

export function CreateUserCommandFromJSON(json: any): CreateUserCommand {
  return CreateUserCommandFromJSONTyped(json, false);
}

export function CreateUserCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    serviceType: json['serviceType'],
    module: json['module'],
    target: !exists(json, 'target') ? undefined : json['target'],
  };
}

export function CreateUserCommandToJSON(value?: CreateUserCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    serviceType: value.serviceType,
    module: value.module,
    target: value.target,
  };
}
