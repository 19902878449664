import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/elements/d-label.js';
import * as dabihStore from 'src/store';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';

export interface RelatedItem {
  value: string;
  text: string;
}
/**
 *
 */
@customElement('d-edit-related-items')
export class DEditRelatedItems extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    d-label {
      padding-bottom: 6px;
    }

    .relationsEditor .items {
      padding: 0 10px 6px 10px;
      font-family: var(--mainSerif) serif;
    }

    .relationsEditor .labels,
    .relationsEditor .item {
      display: flex;
      align-items: stretch;
    }

    .relationsEditor #labelWrapper {
      display: flex;
      flex-wrap: nowrap;
    }

    .relationsEditor d-tooltip {
      padding-left: 3px;
    }

    .relationsEditor .item a,
    .relationsEditor .item span {
      padding: 3px 0;
      text-decoration: none;
      display: inline-block;
    }

    body:not(.touch) .relationsEditor .item:hover a {
      color: black;
    }

    .relationsEditor .labels > *:nth-child(1),
    .relationsEditor .item > *:nth-child(1) {
      flex: 1;
      padding-right: 20px;
    }

    .relationsEditor form {
      display: flex;
    }

    .relationsEditor form * {
      margin: 0;
    }

    .relationsEditor .items .unrelate {
      flex: none;
      width: 12px;
      height: 100%;
      background: transparent url(/images/unrelate.svg) 100% 8px no-repeat;
      text-indent: -1000px;
      overflow: hidden;
      cursor: pointer;
    }

    .relationsEditor input[type='text'] {
      flex: 1;
      box-sizing: border-box;
      border: 1px solid var(--borderColor);
      border-top-left-radius: var(--inputElementBorderRadius);
      border-bottom-left-radius: var(--inputElementBorderRadius);
      padding: 4px 8px 5px 9px;
      font-family: var(--mainSerif), serif;
      font-size: 15px;
      outline: none;
    }

    .relationsEditor div#cancelNew {
      flex: none;
      border-top: 1px solid hsl(0, 0%, 88%);
      border-bottom: 1px solid hsl(0, 0%, 88%);
      padding: 8px 8px 5px 10px;
      color: hsl(0, 0%, 50%);
      background-color: white;
      margin-left: -1px;
      font-family: var(--small) sans-serif;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 11px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .relationsEditor div#cancelNew:hover {
      color: black;
    }

    .relationsEditor input[type='submit'] {
      flex: none;
      width: auto;
      border: 1px solid var(--themeColorDarkerOne);
      border-left: none;
      border-top-right-radius: var(--inputElementBorderRadius);
      border-bottom-right-radius: var(--inputElementBorderRadius);
      background-color: var(--themeColor);
      padding: 5px 8px 6px 8px;
      color: white;
      font-family: var(--small) sans-serif;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 11px;
      text-transform: uppercase;
      box-shadow: none;
      cursor: pointer;
    }

    .relationsEditor input[type='submit']:hover {
      background-color: var(--themeColorDarkerOne);
    }

    .relationsEditor input[type='submit']:disabled,
    .relationsEditor input[type='submit']:disabled:hover {
      cursor: default;
      background-color: var(--themeColor);
      opacity: 0.5;
    }
  `;
  @property({ type: String })
  field = '';
  @property({ type: String })
  placeholder = '';
  @property({ type: Array })
  items: RelatedItem[] = [];
  @property({ type: Array })
  relatedItems: string[] = [];
  @property({ type: String })
  selectedItem = '';
  @property({ type: Boolean })
  single = false;
  @property({ type: String })
  newItem = '';
  @property({ type: Boolean })
  isNew = false;
  @query('.newInput')
  input!: HTMLInputElement;

  private get newIsEmpty() {
    return this.newItem === undefined || this.newItem == null || this.newItem.length <= 0;
  }

  displayName(item: RelatedItem) {
    return item.text;
  }

  _getName(items: RelatedItem[], uuid: string) {
    for (const item of items) {
      if (item.value === uuid) {
        return this.displayName(item);
      }
    }
  }

  _isTrue(value) {
    return value || value == 'true';
  }

  _itemsOptions(items: RelatedItem[], relatedItems: string[], single: boolean): SelectDropdownOption[] {
    if (items && relatedItems) {
      const options: SelectDropdownOption[] = [];
      for (const item1 of items) {
        if (relatedItems === null) {
          relatedItems = [];
        }
        if (relatedItems.length > 0) {
          let match = false;
          for (const item of relatedItems) {
            if (item1.value === item) {
              match = true;
              break;
            }
          }
          if (!match || single) {
            options.push({
              value: item1.value,
              text: this.displayName(item1),
            });
          }
        } else {
          options.push({
            value: item1.value,
            text: this.displayName(item1),
          });
        }
      }
      options.push({ value: 'NEW', text: 'Opprett ny' });
      return options;
    }
    return [];
  }

  _addItem() {
    if (this.selectedItem && this.relatedItems) {
      if (this.selectedItem === 'NEW') {
        this.isNew = true;
        this.newItem = '';
      } else {
        if (this.single) {
          this.relatedItems = [];
        }
        const clone = this.relatedItems.slice(0);
        clone.push(this.selectedItem);
        this.relatedItems = clone;
      }
      if (!this.single || this.selectedItem === 'NEW') {
        this.selectedItem = '';
      }
    }
  }

  _cancelNew(event) {
    event.preventDefault();
    this.isNew = false;
    this.newItem = '';
  }

  _saveNew(event) {
    const uuid = dabihStore.createUuid();
    event.preventDefault();
    event.stopPropagation();
    this.items = [
      ...this.items,
      {
        value: uuid,
        text: this.newItem,
      },
    ];
    if (this.single) {
      this.relatedItems = [uuid];
      this.selectedItem = uuid;
    } else {
      this.relatedItems = [...this.relatedItems, uuid];
    }
    this.dispatchEvent(
      new CustomEvent('new-item', {
        bubbles: true,
        composed: true,
        detail: {
          uuid: uuid,
          name: this.newItem,
        },
      }),
    );
    this.dispatchEvent(
      new CustomEvent<{ relatedItems: string[] }>('related-items-changed', {
        bubbles: true,
        composed: true,
        detail: {
          relatedItems: this.relatedItems,
        },
      }),
    );
    this.isNew = false;
    this.newItem = '';
    this.selectedItem = '';
  }

  _removeItem(itemId: string) {
    this.relatedItems = this.relatedItems.filter((i) => i !== itemId);
    this.dispatchEvent(
      new CustomEvent<{ relatedItems: string[] }>('related-items-changed', {
        bubbles: true,
        composed: true,
        detail: {
          relatedItems: this.relatedItems,
        },
      }),
    );
  }

  async onValueChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.selectedItem = e.detail.value;
    if (e.detail.value === 'NEW') {
      this.isNew = true;
      this.newItem = '';
      await this.updateComplete;
      this.input.focus();
    } else {
      this.relatedItems = [...this.relatedItems, e.detail.value];
      this.dispatchEvent(
        new CustomEvent<{ relatedItems: string[] }>('related-items-changed', {
          bubbles: true,
          composed: true,
          detail: {
            relatedItems: this.relatedItems,
          },
        }),
      );
    }
    await this.updateComplete;
    this.selectedItem = '';
  }

  onInput(event: InputEvent) {
    this.newItem = (event.target as HTMLInputElement).value;
  }

  render() {
    return html`
      <div class="sectionElementInner">
        <div class="relationsEditor">
          <d-label field="${this.field}"></d-label>

          ${this._isTrue(this.single) || this.relatedItems.length === 0
            ? nothing
            : html`<div class="items">
                ${this.relatedItems.map(
                  (item) =>
                    html` <div class="item">
                      <span class="name">${this._getName(this.items, item)}</span>
                      <a
                        class="unrelate"
                        @click=${(e) => {
                          e.stopPropagation();
                          this._removeItem(item);
                        }}
                        >Fjern</a
                      >
                    </div>`,
                )}
              </div>`}
          ${this.isNew
            ? html`<form @submit=${(e) => this._saveNew(e)}>
                <input class="newInput" placeholder="Navn" type="text" value="${this.newItem}" @input=${this.onInput} />
                <div id="cancelNew" @click=${(e) => this._cancelNew(e)}>Avbryt</div>
                <input ?disabled=${this.newIsEmpty} type="submit" value="OK" />
              </form>`
            : html`<d-select-dropdown
                theme-page
                deselectable
                .options=${this._itemsOptions(this.items, this.relatedItems, this.single)}
                .placeholder=${this.placeholder}
                value="${this.selectedItem}"
                @value-changed=${this.onValueChanged}
              >
              </d-select-dropdown>`}
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-related-items': DEditRelatedItems;
  }
}
