import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LocalDate } from 'src/utilities/local-date';
import '../../library/elements/d-label';
import '../../library/editors/elements/d-select-radio';
import '../../library/editors/elements/d-select-date';
import '../../library/editors/elements/d-select-dropdown';
import '../../library/editors/elements/d-select-tag';
import '../../library/editors/components/d-edit-time';
import '../../library/elements/d-wrap';
import '../../library/fields/d-expansion';
import '../../library/elements/d-action';
import '../../library/components/d-calendar-year';
import { endYear, RecurrenceRule } from 'src/store';
import { BaseEditRecurrence } from './base-edit-recurrence';

/**
 *
 *
 *
 * USAGE:
 *    d-edit-occurrences
 *
 * STATUS OK
 */
@customElement('d-create-occurrences')
export class DCreateOccurrences extends BaseEditRecurrence {
  static readonly styles = css`
    :host {
      display: block;
      position: relative;
    }
    .header {
      margin-bottom: 6px;
    }
    d-label {
      margin-top: 9px;
      margin-right: 12px;
    }
    .between {
      display: flex;
      align-items: center;
    }
    #weekdays {
      margin-bottom: -4px;
    }
  `;
  @property({ type: String })
  label = 'Gjentakelse';
  @property({ type: String })
  occurrenceType = 'DATE';
  @property({ type: Boolean })
  onlyDated = false;
  @property({ type: Boolean })
  onlyRecurring = false;
  @property({ type: String })
  recurrenceTime = 'NONE';
  @property({ type: Number })
  recurrenceDuration = 0;
  @property({ type: Number })
  contentStickyTop = 0;

  private occurrenceTypeOptions = [
    { value: 'TODO', text: 'Snarest' },
    { value: 'DATE', text: 'På dato' },
  ];

  protected get frequencyOptions() {
    return super.frequencyOptions
      .map((f) => {
        if (f.value === 'NONE') {
          f.text = 'Ingen repetisjon';
        }
        return f;
      })
      .filter((f) => {
        return !(f.value === 'NONE' && this.onlyRecurring);
      });
  }

  private get occurrences(): string[] {
    if (this.recurrence) {
      const occurrencesFromRule: string[] = [];
      const r = RecurrenceRule.fromString('DTSTART=' + this.startDate.replaceAll('-', '') + ';' + this.recurrence);
      const start = LocalDate.fromString('1000-01-01');
      const end = LocalDate.fromString(endYear + '-12-31');
      let x = r.sameOrAfter(start);
      while (x !== null && x.isSameOrBefore(end)) {
        const date = x.toString();
        occurrencesFromRule.push(date);
        x = r.after(x);
      }
      return occurrencesFromRule.map((o) => {
        let dateTime = o;
        if (this.recurrenceTime !== 'NONE') {
          dateTime += ' ' + this.recurrenceTime + ' ' + this.recurrenceDuration;
        }
        return dateTime;
      });
    }
    if (this.occurrenceType === 'TODO') {
      return [''];
    }
    let time = '';
    if (this.recurrenceTime !== 'NONE') {
      time = ' ' + this.recurrenceTime + ' ' + this.recurrenceDuration;
    }
    return [this.startDate + time];
  }

  onOccurrenceTypeChanged(e) {
    const value = e.detail.value;
    this.occurrenceType = value;
    if (value === 'TODO') {
      this.recurrence = '';
    }
  }

  cancel() {
    this.dispatchEvent(
      new CustomEvent('cancel', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  done() {
    this.dispatchEvent(
      new CustomEvent('done', {
        bubbles: true,
        composed: true,
        detail: { value: this.occurrences },
      }),
    );
  }

  render() {
    return html`
      <d-wrap split-reverse tight class="header">
        <div>
          <d-action plain @click=${() => this.cancel()}>Avbryt</d-action>
          <d-action plain @click=${() => this.done()}>Legg til</d-action>
        </div>
        <d-wrap tight>
          <d-label big .label=${this.label}></d-label>
          ${this.onlyDated
            ? nothing
            : html`
                <d-select-radio
                  controller
                  .options=${this.occurrenceTypeOptions}
                  .value=${this.occurrenceType}
                  @value-changed=${(e) => this.onOccurrenceTypeChanged(e)}
                ></d-select-radio>
              `}
        </d-wrap>
      </d-wrap>
      <d-expansion .opened="${this.onlyDated || this.occurrenceType === 'DATE'}">
        <d-wrap>
          <d-select-date
            controller
            .disableBefore=${this.startDate}
            .disableAfter=${endYear + '-12-31'}
            .value=${this.startDate}
            @value-changed=${(e) => this.onStartDateChanged(e)}
          ></d-select-date>
          <d-edit-time
            controller
            whole-day-option
            .time=${this.recurrenceTime}
            .durationMinutes=${this.recurrenceDuration}
            @value-changed=${this.onRecurrenceTimeChanged}
          ></d-edit-time>
          <d-select-dropdown
            controller
            .value=${this.freqInterval}
            .options=${this.frequencyOptions}
            @value-changed=${(e) => this.onFreqIntervalChanged(e)}
          ></d-select-dropdown>
        </d-wrap>
        <d-expansion .opened="${this.freq !== 'NONE'}">
          <d-wrap style="padding-top: 12px">
            ${this.freq === 'WEEKLY'
              ? html` <div class="between">på</div>
                  <d-select-tag
                    controller
                    id="weekdays"
                    .options=${this.weekdayOptions}
                    .value=${this.byDay}
                    @value-changed=${this.onWeekdaysChanged}
                  >
                  </d-select-tag>`
              : nothing}
            ${this.freq === 'MONTHLY' || this.freq === 'YEARLY'
              ? html`<d-select-dropdown
                  controller
                  .options=${this.recurrenceTypeOptions}
                  .value=${this.recurrenceType}
                  @value-changed=${this.onRecurrenceTypeChanged}
                >
                </d-select-dropdown>`
              : nothing}
            <d-select-date
              label="til"
              inline-label
              light-label
              controller
              .disableBefore=${LocalDate.fromString(this.startDate).plusDays(1).toString()}
              .disableAfter=${endYear + '-12-31'}
              .value=${this.endDate}
              @value-changed=${(e) => this.onEndDateChanged(e)}
            ></d-select-date>
          </d-wrap>
        </d-expansion>
      </d-expansion>
    `;
  }
  private onRecurrenceTimeChanged(e: CustomEvent<{ time: string; durationMinutes: number }>) {
    e.stopPropagation();
    this.recurrenceTime = e.detail.time;
    this.recurrenceDuration = e.detail.durationMinutes;
    if (this.freq === 'NONE') {
      this.recurrence = '';
    } else {
      this.recurrence = this.getRecurrenceRule(this.interval, this.recurrenceType, this.endDate);
    }
  }

  private onStartDateChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.startDate = e.detail.value;
    if (this.freq === 'NONE') {
      this.recurrence = '';
    } else {
      this.recurrence = this.getRecurrenceRule(this.interval, this.recurrenceType, this.endDate);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-create-occurrences': DCreateOccurrences;
  }
}
