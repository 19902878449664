/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface IssueUpdateMessage
 */
export interface IssueUpdateMessage {
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  identifiedMeasures?: string;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  shallEvaluated?: boolean;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  immediateMeasures?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  furtherMeasures?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  correction?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  registeredBy?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  reportedTo?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  reportedBy?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  responsibleBy?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  processedBy?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  evaluatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  processedDate?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  reportedDate?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  evaluatedDate?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  eventDate?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  registeredDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  evaluated?: boolean;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  workRelatedHealthIssueDescription?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  workRelatedInjuryTask?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  workRelatedInjuryIncidentCause?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  workRelatedSicknessCausingTask?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  workRelatedSicknessCausingTaskLocation?: string;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  workRelatedSicknessCausingEnvironment?: string;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  implementedMeasure?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  reportedLabourInspection?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  reportedInspectorate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  reportedSafetyManager?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  reportedCountyMedical?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  informationLeakagePossibility?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  personDamagePossibility?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  relateToEmployees?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  relateToEquipment?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  relateToPatient?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  relateToPatientInstitution?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  relateToRadiation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  requiresMeasures?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  requireEvaluation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  measuresSatisfactorily?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  relateToMaritimeHealthCertificate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  workRelatedInjury?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  workRelatedSickness?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  workRelatedHealthIssue?: boolean;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  workRelatedHealthIssueEmployee?: string;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  relateToRadiationEquipmentMissing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IssueUpdateMessage
   */
  evaluatedOk?: boolean;
  /**
   *
   * @type {string}
   * @memberof IssueUpdateMessage
   */
  classification: IssueUpdateMessageClassificationEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof IssueUpdateMessage
   */
  accessControl: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof IssueUpdateMessage
   */
  pages?: Array<number>;
}

/**
 * @export
 */
export const IssueUpdateMessageClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
  Private: 'PRIVATE',
} as const;
export type IssueUpdateMessageClassificationEnum =
  (typeof IssueUpdateMessageClassificationEnum)[keyof typeof IssueUpdateMessageClassificationEnum];

/**
 * Check if a given object implements the IssueUpdateMessage interface.
 */
export function instanceOfIssueUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'classification' in value;
  isInstance = isInstance && 'accessControl' in value;

  return isInstance;
}

export function IssueUpdateMessageFromJSON(json: any): IssueUpdateMessage {
  return IssueUpdateMessageFromJSONTyped(json, false);
}

export function IssueUpdateMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isConfirmedEntity: json['isConfirmedEntity'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    description: !exists(json, 'description') ? undefined : json['description'],
    identifiedMeasures: !exists(json, 'identifiedMeasures') ? undefined : json['identifiedMeasures'],
    shallEvaluated: !exists(json, 'shallEvaluated') ? undefined : json['shallEvaluated'],
    immediateMeasures: !exists(json, 'immediateMeasures') ? undefined : json['immediateMeasures'],
    furtherMeasures: !exists(json, 'furtherMeasures') ? undefined : json['furtherMeasures'],
    name: !exists(json, 'name') ? undefined : json['name'],
    correction: !exists(json, 'correction') ? undefined : json['correction'],
    registeredBy: !exists(json, 'registeredBy') ? undefined : json['registeredBy'],
    reportedTo: !exists(json, 'reportedTo') ? undefined : json['reportedTo'],
    reportedBy: !exists(json, 'reportedBy') ? undefined : json['reportedBy'],
    responsibleBy: !exists(json, 'responsibleBy') ? undefined : json['responsibleBy'],
    processedBy: !exists(json, 'processedBy') ? undefined : json['processedBy'],
    evaluatedBy: !exists(json, 'evaluatedBy') ? undefined : json['evaluatedBy'],
    processedDate: !exists(json, 'processedDate') ? undefined : json['processedDate'],
    reportedDate: !exists(json, 'reportedDate') ? undefined : json['reportedDate'],
    evaluatedDate: !exists(json, 'evaluatedDate') ? undefined : json['evaluatedDate'],
    eventDate: !exists(json, 'eventDate') ? undefined : json['eventDate'],
    registeredDate: !exists(json, 'registeredDate') ? undefined : json['registeredDate'],
    evaluated: !exists(json, 'evaluated') ? undefined : json['evaluated'],
    workRelatedHealthIssueDescription: !exists(json, 'workRelatedHealthIssueDescription')
      ? undefined
      : json['workRelatedHealthIssueDescription'],
    workRelatedInjuryTask: !exists(json, 'workRelatedInjuryTask') ? undefined : json['workRelatedInjuryTask'],
    workRelatedInjuryIncidentCause: !exists(json, 'workRelatedInjuryIncidentCause')
      ? undefined
      : json['workRelatedInjuryIncidentCause'],
    workRelatedSicknessCausingTask: !exists(json, 'workRelatedSicknessCausingTask')
      ? undefined
      : json['workRelatedSicknessCausingTask'],
    workRelatedSicknessCausingTaskLocation: !exists(json, 'workRelatedSicknessCausingTaskLocation')
      ? undefined
      : json['workRelatedSicknessCausingTaskLocation'],
    workRelatedSicknessCausingEnvironment: !exists(json, 'workRelatedSicknessCausingEnvironment')
      ? undefined
      : json['workRelatedSicknessCausingEnvironment'],
    implementedMeasure: !exists(json, 'implementedMeasure') ? undefined : json['implementedMeasure'],
    reportedLabourInspection: !exists(json, 'reportedLabourInspection') ? undefined : json['reportedLabourInspection'],
    reportedInspectorate: !exists(json, 'reportedInspectorate') ? undefined : json['reportedInspectorate'],
    reportedSafetyManager: !exists(json, 'reportedSafetyManager') ? undefined : json['reportedSafetyManager'],
    reportedCountyMedical: !exists(json, 'reportedCountyMedical') ? undefined : json['reportedCountyMedical'],
    informationLeakagePossibility: !exists(json, 'informationLeakagePossibility')
      ? undefined
      : json['informationLeakagePossibility'],
    personDamagePossibility: !exists(json, 'personDamagePossibility') ? undefined : json['personDamagePossibility'],
    relateToEmployees: !exists(json, 'relateToEmployees') ? undefined : json['relateToEmployees'],
    relateToEquipment: !exists(json, 'relateToEquipment') ? undefined : json['relateToEquipment'],
    relateToPatient: !exists(json, 'relateToPatient') ? undefined : json['relateToPatient'],
    relateToPatientInstitution: !exists(json, 'relateToPatientInstitution')
      ? undefined
      : json['relateToPatientInstitution'],
    relateToRadiation: !exists(json, 'relateToRadiation') ? undefined : json['relateToRadiation'],
    requiresMeasures: !exists(json, 'requiresMeasures') ? undefined : json['requiresMeasures'],
    requireEvaluation: !exists(json, 'requireEvaluation') ? undefined : json['requireEvaluation'],
    measuresSatisfactorily: !exists(json, 'measuresSatisfactorily') ? undefined : json['measuresSatisfactorily'],
    relateToMaritimeHealthCertificate: !exists(json, 'relateToMaritimeHealthCertificate')
      ? undefined
      : json['relateToMaritimeHealthCertificate'],
    workRelatedInjury: !exists(json, 'workRelatedInjury') ? undefined : json['workRelatedInjury'],
    workRelatedSickness: !exists(json, 'workRelatedSickness') ? undefined : json['workRelatedSickness'],
    workRelatedHealthIssue: !exists(json, 'workRelatedHealthIssue') ? undefined : json['workRelatedHealthIssue'],
    workRelatedHealthIssueEmployee: !exists(json, 'workRelatedHealthIssueEmployee')
      ? undefined
      : json['workRelatedHealthIssueEmployee'],
    relateToRadiationEquipmentMissing: !exists(json, 'relateToRadiationEquipmentMissing')
      ? undefined
      : json['relateToRadiationEquipmentMissing'],
    evaluatedOk: !exists(json, 'evaluatedOk') ? undefined : json['evaluatedOk'],
    classification: json['classification'],
    accessControl: json['accessControl'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
  };
}

export function IssueUpdateMessageToJSON(value?: IssueUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isConfirmedEntity: value.isConfirmedEntity,
    notes: value.notes,
    description: value.description,
    identifiedMeasures: value.identifiedMeasures,
    shallEvaluated: value.shallEvaluated,
    immediateMeasures: value.immediateMeasures,
    furtherMeasures: value.furtherMeasures,
    name: value.name,
    correction: value.correction,
    registeredBy: value.registeredBy,
    reportedTo: value.reportedTo,
    reportedBy: value.reportedBy,
    responsibleBy: value.responsibleBy,
    processedBy: value.processedBy,
    evaluatedBy: value.evaluatedBy,
    processedDate: value.processedDate === undefined ? undefined : value.processedDate,
    reportedDate: value.reportedDate === undefined ? undefined : value.reportedDate,
    evaluatedDate: value.evaluatedDate === undefined ? undefined : value.evaluatedDate,
    eventDate: value.eventDate === undefined ? undefined : value.eventDate,
    registeredDate: value.registeredDate === undefined ? undefined : value.registeredDate,
    evaluated: value.evaluated,
    workRelatedHealthIssueDescription: value.workRelatedHealthIssueDescription,
    workRelatedInjuryTask: value.workRelatedInjuryTask,
    workRelatedInjuryIncidentCause: value.workRelatedInjuryIncidentCause,
    workRelatedSicknessCausingTask: value.workRelatedSicknessCausingTask,
    workRelatedSicknessCausingTaskLocation: value.workRelatedSicknessCausingTaskLocation,
    workRelatedSicknessCausingEnvironment: value.workRelatedSicknessCausingEnvironment,
    implementedMeasure: value.implementedMeasure,
    reportedLabourInspection: value.reportedLabourInspection,
    reportedInspectorate: value.reportedInspectorate,
    reportedSafetyManager: value.reportedSafetyManager,
    reportedCountyMedical: value.reportedCountyMedical,
    informationLeakagePossibility: value.informationLeakagePossibility,
    personDamagePossibility: value.personDamagePossibility,
    relateToEmployees: value.relateToEmployees,
    relateToEquipment: value.relateToEquipment,
    relateToPatient: value.relateToPatient,
    relateToPatientInstitution: value.relateToPatientInstitution,
    relateToRadiation: value.relateToRadiation,
    requiresMeasures: value.requiresMeasures,
    requireEvaluation: value.requireEvaluation,
    measuresSatisfactorily: value.measuresSatisfactorily,
    relateToMaritimeHealthCertificate: value.relateToMaritimeHealthCertificate,
    workRelatedInjury: value.workRelatedInjury,
    workRelatedSickness: value.workRelatedSickness,
    workRelatedHealthIssue: value.workRelatedHealthIssue,
    workRelatedHealthIssueEmployee: value.workRelatedHealthIssueEmployee,
    relateToRadiationEquipmentMissing: value.relateToRadiationEquipmentMissing,
    evaluatedOk: value.evaluatedOk,
    classification: value.classification,
    accessControl: value.accessControl,
    pages: value.pages,
  };
}
