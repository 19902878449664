/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AttachmentViewModel
 */
export interface AttachmentViewModel {
  /**
   *
   * @type {string}
   * @memberof AttachmentViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentViewModel
   */
  fullPath?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentViewModel
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentViewModel
   */
  urlForInline?: string;
}

/**
 * Check if a given object implements the AttachmentViewModel interface.
 */
export function instanceOfAttachmentViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;

  return isInstance;
}

export function AttachmentViewModelFromJSON(json: any): AttachmentViewModel {
  return AttachmentViewModelFromJSONTyped(json, false);
}

export function AttachmentViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    fullPath: !exists(json, 'fullPath') ? undefined : json['fullPath'],
    name: !exists(json, 'name') ? undefined : json['name'],
    url: !exists(json, 'url') ? undefined : json['url'],
    urlForInline: !exists(json, 'urlForInline') ? undefined : json['urlForInline'],
  };
}

export function AttachmentViewModelToJSON(value?: AttachmentViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    fullPath: value.fullPath,
    name: value.name,
    url: value.url,
    urlForInline: value.urlForInline,
  };
}
