import { css, html, LitElement, nothing, TemplateResult } from 'lit';
import { ActionInput } from '../elements/d-action.js';
import './d-list-header.js';
import { property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';

/**
 *
 *
 */

export class BaseListSectionElement<T> extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }

      .list-content {
        font-weight: 400;
      }

      d-action {
        margin-left: calc(var(--listPaddingLeft) - 8px);
      }
    `,
  ];
  @property({ type: Number })
  sort = 1;
  addItem?: () => void;
  @property({ type: String })
  icon: string | undefined = undefined;
  @property({ type: String })
  field = '';
  @property({ type: String })
  label = '';
  @property({ type: String })
  subfield = '';
  @property({ type: String })
  sublabel = '';
  @property({ type: String })
  addItemText = '';
  @property({ type: Boolean })
  hideTooltip = false;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean, reflect: true, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean, reflect: true, attribute: 'map-element' })
  mapElement = false;
  @property({ type: Boolean, reflect: true, attribute: 'start-tasks' })
  startTasks = false;
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: Array })
  items!: T[];

  protected get renderedItems() {
    return this.items ?? [];
  }

  _addItemText(addItemText) {
    if (addItemText) {
      return addItemText;
    }
    return 'Legg til';
  }

  renderHeaderActions(): TemplateResult | typeof nothing {
    return nothing;
  }
  renderHeader(): TemplateResult | typeof nothing {
    const listHeaderStyles = { top: this.contentStickyTop - 1 + 'px' };
    return html`
      <d-list-header
        .icon=${this.icon}
        .field=${this.field}
        .label=${this.label}
        ?theme-page=${this.themePage}
        style=${styleMap(listHeaderStyles)}
      >
        ${this.renderHeaderActions()}
      </d-list-header>
    `;
  }

  renderItemsEmpty(): TemplateResult | typeof nothing {
    return nothing;
  }

  renderItems(): TemplateResult | typeof nothing {
    return html`
      <div class="list-content">
        ${this.renderedItems.length === 0
          ? this.renderItemsEmpty()
          : this.renderedItems.map((item) => this.renderItem(item))}
        <slot></slot>
      </div>
    `;
  }

  renderItem(_item: T): TemplateResult | typeof nothing {
    return nothing;
  }

  renderPreContent(): TemplateResult | typeof nothing {
    return nothing;
  }
  renderPostContent(): TemplateResult | typeof nothing {
    return nothing;
  }
  render() {
    return html`
      ${this.renderHeader()} ${this.renderPreContent()} ${this.renderItems()} ${this.renderAddItemAction()}
      ${this.renderPostContent()}
    `;
  }

  private renderAddItemAction() {
    const addItemHandler = this.addItem;
    if (!(addItemHandler !== undefined && this.writeAccess)) {
      return nothing;
    } else {
      const i: ActionInput = {
        name: this._addItemText(this.addItemText),
        action: 'add',
      };
      return html`<d-action
        .input=${i}
        @action=${(e) => {
          e.preventDefault();
          e.stopPropagation();
          addItemHandler();
        }}
      ></d-action>`;
    }
  }
}
