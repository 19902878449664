/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SendShareVacationCommand
 */
export interface SendShareVacationCommand {
  /**
   *
   * @type {string}
   * @memberof SendShareVacationCommand
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof SendShareVacationCommand
   */
  recipient?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SendShareVacationCommand
   */
  employees?: Array<string>;
}

/**
 * Check if a given object implements the SendShareVacationCommand interface.
 */
export function instanceOfSendShareVacationCommand(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SendShareVacationCommandFromJSON(json: any): SendShareVacationCommand {
  return SendShareVacationCommandFromJSONTyped(json, false);
}

export function SendShareVacationCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendShareVacationCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    recipient: !exists(json, 'recipient') ? undefined : json['recipient'],
    employees: !exists(json, 'employees') ? undefined : json['employees'],
  };
}

export function SendShareVacationCommandToJSON(value?: SendShareVacationCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    notes: value.notes,
    recipient: value.recipient,
    employees: value.employees,
  };
}
