import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import '../../content/entity-content.js';
import '../../library/editors/index.js';
import '../../library/fields/index.js';
import '../../library/fields/d-expansion.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../pages/computers-page/d-edit-single-related-item.js';
import { DataMapElement, DataMapItem } from './data-map-element.js';

export interface NetworkConnection extends DataMapItem {
  partners: SelectDropdownOption[];
  unitType: 'connection';
  ghost: boolean;
  supplierUuid: string;
  connectionType: string;
}

export interface NetworkConnectionEditItem {
  supplierUuid: string;
  connectionType: string;
}

/**
 *
 */
@customElement('d-network-connection-view')
export class DNetworkConnectionView extends DataMapElement<NetworkConnection, NetworkConnectionEditItem> {
  @property({ type: String })
  firewall = '';
  @property({ type: String })
  firewallName = '';
  @property({ type: String })
  supplierUuid = '';
  @property({ type: String })
  isp = '';
  @property({ type: String })
  containedNewComputerUuid = '';
  @property({ type: Object })
  containedNewComputer: any = {};
  @property({ type: Boolean })
  connectionOpened = false;
  @property({ type: Boolean })
  firewallOpened = false;
  @property({ type: Boolean })
  networkOpened = false;
  @property({ type: Boolean })
  newElement = false;
  @property({ type: Object })
  viewOptions: any = {};
  @property({ type: Boolean })
  editModeForConnection = false;
  @property({ type: Boolean })
  editModeForFirewall = false;
  @property({ type: Boolean })
  editModeForNetwork = false;

  _operatorTypes() {
    const list = [
      { value: 'UNDEFINED', text: 'Velg driftsmåte' },
      { value: 'NONE', text: 'Ingen' },
    ];
    list.push(
      { value: 'internal', text: 'Driftes internt' },
      { value: 'external', text: 'Driftes av ekstern leverandør' },
      {
        value: 'internalAndExternal',
        text: 'Driftes både internt og eksternt',
      },
    );
    return list;
  }

  _operatorIsExternal(operatorType) {
    return operatorType === 'external' || operatorType === 'internalAndExternal';
  }

  _getOperatorTypeValue(id) {
    let result = '';
    const list = this._operatorTypes();
    for (const item of list) {
      if (id === item.value) {
        result = item.text;
      }
    }

    if (result === 'Velg driftsmåte') {
      result = 'Ikke valgt';
    }

    return result;
  }

  _getName(type, uuid) {
    if (Array.isArray(uuid)) {
      uuid = uuid[0];
    }
    for (const item of type) {
      if (item.uuid === uuid) {
        return item.name;
      }
    }
  }

  _displayTypeName(): string {
    return 'Internettforbindelse';
  }

  renderUnitEdit(editItem: NetworkConnectionEditItem) {
    return html`
      <d-section>
        <d-select-dropdown
          theme-page
          field="internetConnections_connectionType"
          .options="${this.connectionTypes}"
          value="${editItem.connectionType}"
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            e.stopPropagation();
            this.editItem = {
              ...editItem,
              connectionType: e.detail.value,
            };
          }}
        >
        </d-select-dropdown>
      </d-section>
      <d-expansion ?opened=${editItem.connectionType !== 'NONE'}>
        <d-section>
          <d-edit-single-related-item
            field="internetConnections_isp"
            .items=${this.item.partners}
            @new-item=${this.onNewPartner}
            placeholder="Velg internettleverandør"
            .relatedItem=${editItem.supplierUuid}
            @related-item-changed=${(e: CustomEvent<{ relatedItem: string }>) => {
              e.stopPropagation();
              this.editItem = {
                ...editItem,
                supplierUuid: e.detail.relatedItem,
              };
            }}
          >
          </d-edit-single-related-item>
        </d-section>
      </d-expansion>
    `;
  }

  _displayName() {
    return '';
  }

  _isValid() {
    return true;
  }

  initializeEditItem(): NetworkConnectionEditItem {
    return { connectionType: this.item.connectionType, supplierUuid: this.item.supplierUuid };
  }

  protected typeClasses(): string {
    return 'access';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-network-connection-view': DNetworkConnectionView;
  }
}
