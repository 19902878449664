import { sortBy } from 'lodash';
import type { SubstanceView, SubstanceViewEditItem } from 'src/content/substances/d-substance-view.js';
import type { RelatedRiskAssessment } from 'src/library/lists/d-list-section-related-risk-assessments.js';
import { fetchDraft, toAttachmentItems } from 'src/models/factory-utilities.js';
import { BASE_PATH, RiskAssessmentViewModel, SubstanceViewModel } from 'src/store/api';
import { attachment } from 'src/store/backend.js';
import {
  getOrganization,
  key,
  partnersNotDeleted,
  riskAssessmentsNotDeleted,
  SuggestedRiskAssessment,
  suggestedRiskAssessmentsForSubstances,
  toSubstanceViewModel,
  writeAccess,
} from 'src/store/selectors';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { assertIsDefined } from 'src/lib';
import { entityNameForLists } from 'src/utilities/text';
import { CommonDataEntityView } from 'src/content/entity-content';

function _relatedRiskAssessments(
  substance: SubstanceViewModel,
  suggestedRiskAssessments: SuggestedRiskAssessment[],
  riskAssessments: RiskAssessmentViewModel[],
): RelatedRiskAssessment[] {
  const result: RelatedRiskAssessment[] = [];
  suggestedRiskAssessments.forEach((item) => {
    const createdFromTemplate = riskAssessments.filter(
      (r) => r.relatedSubstance === substance.uuid && r.createdFromTemplate === item.pageId,
    );
    if (createdFromTemplate.length) {
      const c = createdFromTemplate[0];
      let risk: number | null = null;
      if (c.probability && c.consequence) {
        risk = c.probability * c.consequence;
      }
      result.push({
        suggestion: false,
        uuid: c.uuid,
        name: entityNameForLists(c.name, c.draftName, c.isConfirmedEntity),
        risk: risk ?? 0,
        hasDraft: c.hasDraft,
      });
    } else {
      result.push({
        suggestion: true,
        templateId: item.pageId,
        name: item.name,
        risk: 0,
        uuid: '',
        hazardLabel: item.hazardLabel,
        hasDraft: false,
      });
    }
  });
  riskAssessments = sortBy(riskAssessments, (item) => item.name);
  riskAssessments.forEach(function (r) {
    const s = suggestedRiskAssessments.filter(function (item) {
      return item.pageId === r.createdFromTemplate;
    });
    if (r.relatedSubstance === substance.uuid && (!r.createdFromTemplate || s.length === 0)) {
      let risk: any = null;
      if (r.probability && r.consequence) {
        risk = r.probability * r.consequence;
      }
      result.push({
        suggestion: false,
        uuid: r.uuid,
        name: entityNameForLists(r.name, r.draftName, r.isConfirmedEntity),
        risk: risk,
        hasDraft: r.hasDraft,
      });
    }
  });
  return result;
}

export function buildSubstanceView(
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): SubstanceView {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const item = organization.substancesById[uuid];
  assertIsDefined(item);

  const entity = toSubstanceViewModel(item);

  const riskAssessments = riskAssessmentsNotDeleted(state);

  const availablePartners = partnersNotDeleted(state)
    .filter((p) => p.isConfirmedEntity)
    .map((p) => ({ value: p.uuid, text: p.name }));
  return {
    ...commonData,
    docsForLinking: [],
    currentUserHasAccess: true,
    currentUserHasWriteAccess: writeAccess(state),
    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: '',
    href: currentParent + '/substances/' + uuid,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/substances/' + uuid + '.pdf?key=' + key(state),
    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'substances',
    name: entity.name ?? '',
    attachments: toAttachmentItems('substances', entity.uuid, entity.attachments),
    location: entity.location ?? '',
    supplierName: availablePartners.find((p) => p.value === entity.supplierUuid)?.text ?? '',
    notes: entity.notes ?? '',
    applications: entity.applications ?? '',
    archived: entity.archived === true,
    hazardLabels: entity.hazardLabels ?? [],
    relatedRiskAssessments: _relatedRiskAssessments(
      entity,
      suggestedRiskAssessmentsForSubstances,
      riskAssessments,
    ).filter((r) => !r.suggestion || (r.hazardLabel && (entity.hazardLabels ?? []).includes(r.hazardLabel))),
    supplierUuid: entity.supplierUuid ?? '',
    today: LocalDate.fromString(state.today),
    availablePartners: availablePartners,
    pdf: fetchAttachment(
      state.token ?? '',
      organization.organizationId,
      entity.uuid,
      (entity.attachments ?? [])[0].uuid,
    ),
    unreadable: entity.uploadStatus === 'UNREADABLE',
    deletable: true,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(state.token ?? '', 'substances', uuid, organization.organizationId);
      return command.draft as SubstanceViewEditItem;
    },
  };
}
/*

function _suggestedRiskAssessments(hazardLabels: string[], suggestedDocuments: { hazardLabel: string }[]) {
  return hazardLabels.map((label) => suggestedDocuments.filter((template) => template.hazardLabel === label)[0]);
}

 */

async function fetchAttachment(
  token: string,
  organizationId: number,
  substanceUuid: string,
  attachmentUuid: string,
): Promise<string> {
  const a = await attachment(token, 'substances', substanceUuid, organizationId, attachmentUuid);
  return a.urlForInline ?? '';
}
