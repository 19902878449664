/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ExternalConnectionUpdateMessage
 */
export interface ExternalConnectionUpdateMessage {
  /**
   *
   * @type {boolean}
   * @memberof ExternalConnectionUpdateMessage
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionUpdateMessage
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionUpdateMessage
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionUpdateMessage
   */
  connectionType?: ExternalConnectionUpdateMessageConnectionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionUpdateMessage
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionUpdateMessage
   */
  employeeUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionUpdateMessage
   */
  partnerUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionUpdateMessage
   */
  networkUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionUpdateMessage
   */
  supplierUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalConnectionUpdateMessage
   */
  otherRiskAssessments: string;
  /**
   *
   * @type {boolean}
   * @memberof ExternalConnectionUpdateMessage
   */
  riskAssessmentConcludesOk: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ExternalConnectionUpdateMessage
   */
  riskAssessments: Array<string>;
}

/**
 * @export
 */
export const ExternalConnectionUpdateMessageConnectionTypeEnum = {
  None: 'NONE',
  Nhn: 'NHN',
  Other: 'OTHER',
  NhNotherIsp: 'NHNotherISP',
} as const;
export type ExternalConnectionUpdateMessageConnectionTypeEnum =
  (typeof ExternalConnectionUpdateMessageConnectionTypeEnum)[keyof typeof ExternalConnectionUpdateMessageConnectionTypeEnum];

/**
 * Check if a given object implements the ExternalConnectionUpdateMessage interface.
 */
export function instanceOfExternalConnectionUpdateMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'otherRiskAssessments' in value;
  isInstance = isInstance && 'riskAssessmentConcludesOk' in value;
  isInstance = isInstance && 'riskAssessments' in value;

  return isInstance;
}

export function ExternalConnectionUpdateMessageFromJSON(json: any): ExternalConnectionUpdateMessage {
  return ExternalConnectionUpdateMessageFromJSONTyped(json, false);
}

export function ExternalConnectionUpdateMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ExternalConnectionUpdateMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isConfirmedEntity: json['isConfirmedEntity'],
    name: !exists(json, 'name') ? undefined : json['name'],
    type: !exists(json, 'type') ? undefined : json['type'],
    connectionType: !exists(json, 'connectionType') ? undefined : json['connectionType'],
    description: !exists(json, 'description') ? undefined : json['description'],
    employeeUuid: !exists(json, 'employeeUuid') ? undefined : json['employeeUuid'],
    partnerUuid: !exists(json, 'partnerUuid') ? undefined : json['partnerUuid'],
    networkUuid: !exists(json, 'networkUuid') ? undefined : json['networkUuid'],
    supplierUuid: !exists(json, 'supplierUuid') ? undefined : json['supplierUuid'],
    otherRiskAssessments: json['otherRiskAssessments'],
    riskAssessmentConcludesOk: json['riskAssessmentConcludesOk'],
    riskAssessments: json['riskAssessments'],
  };
}

export function ExternalConnectionUpdateMessageToJSON(value?: ExternalConnectionUpdateMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    isConfirmedEntity: value.isConfirmedEntity,
    name: value.name,
    type: value.type,
    connectionType: value.connectionType,
    description: value.description,
    employeeUuid: value.employeeUuid,
    partnerUuid: value.partnerUuid,
    networkUuid: value.networkUuid,
    supplierUuid: value.supplierUuid,
    otherRiskAssessments: value.otherRiskAssessments,
    riskAssessmentConcludesOk: value.riskAssessmentConcludesOk,
    riskAssessments: value.riskAssessments,
  };
}
