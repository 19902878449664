import { getMonthName, getWeekdayName, toDayOfWeek, toOrdinal, WeekdayStr } from '../date.js';
import { BaseInstanceRule } from './base-instance-rule.js';
import type { DayOfWeek, LocalDate } from 'src/utilities/local-date.js';

export class YearlyByNthWeekdayInstanceRule extends BaseInstanceRule {
  private static intervalDescriptions = ['', ' annet', ' tredje', ' fjerde', ' femte', ' sjette'];
  private readonly byMonth: number;
  private readonly byWeekDayElement: WeekdayStr;
  private readonly bySetPos: number;
  private readonly dayOfWeek!: DayOfWeek;

  public constructor(byMonth: number, byWeekDayElement: WeekdayStr, bySetPos: number) {
    super();
    this.byMonth = byMonth;
    this.byWeekDayElement = byWeekDayElement;
    this.bySetPos = bySetPos;
    this.dayOfWeek = toDayOfWeek(this.byWeekDayElement);
  }

  public matches(date: LocalDate): boolean {
    if (date.month() === this.byMonth && date.dayOfWeek() === this.dayOfWeek) {
      if (this.bySetPos === -1) {
        const test = date.withLastInMonth(this.dayOfWeek);
        return test.equals(date);
      } else {
        const test = date.withFirstInMonth(this.dayOfWeek).plusWeeks(this.bySetPos - 1);
        return test.equals(date);
      }
    } else {
      return false;
    }
  }

  public optionsToString(interval: number): string {
    return (
      'FREQ=YEARLY;INTERVAL=' +
      interval +
      ';BYDAY=' +
      this.byWeekDayElement +
      ';BYSETPOS=' +
      this.bySetPos +
      ';BYMONTH=' +
      this.byMonth
    );
  }

  public scheduleDescription(interval: number): string {
    const i = YearlyByNthWeekdayInstanceRule.intervalDescriptions[interval - 1];
    return (
      'Hvert' +
      i +
      ' år på den ' +
      toOrdinal(this.bySetPos) +
      ' ' +
      getWeekdayName(this.dayOfWeek) +
      'en i ' +
      getMonthName(this.byMonth)
    );
  }
}
