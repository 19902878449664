/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';

/**
 *
 * @export
 * @interface SubstanceViewModel
 */
export interface SubstanceViewModel {
  /**
   *
   * @type {string}
   * @memberof SubstanceViewModel
   */
  uploadStatus?: string;
  /**
   *
   * @type {string}
   * @memberof SubstanceViewModel
   */
  uploadDateTime?: string;
  /**
   *
   * @type {string}
   * @memberof SubstanceViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SubstanceViewModel
   */
  supplierUuid?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SubstanceViewModel
   */
  hazardLabels?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof SubstanceViewModel
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof SubstanceViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof SubstanceViewModel
   */
  applications?: string;
  /**
   *
   * @type {string}
   * @memberof SubstanceViewModel
   */
  location?: string;
  /**
   *
   * @type {boolean}
   * @memberof SubstanceViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SubstanceViewModel
   */
  archived?: boolean;
  /**
   *
   * @type {string}
   * @memberof SubstanceViewModel
   */
  notes?: string;
  /**
   *
   * @type {boolean}
   * @memberof SubstanceViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof SubstanceViewModel
   */
  draftName?: string;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof SubstanceViewModel
   */
  attachments?: Array<AttachmentViewModel>;
}

/**
 * Check if a given object implements the SubstanceViewModel interface.
 */
export function instanceOfSubstanceViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'hasDraft' in value;

  return isInstance;
}

export function SubstanceViewModelFromJSON(json: any): SubstanceViewModel {
  return SubstanceViewModelFromJSONTyped(json, false);
}

export function SubstanceViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubstanceViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uploadStatus: !exists(json, 'uploadStatus') ? undefined : json['uploadStatus'],
    uploadDateTime: !exists(json, 'uploadDateTime') ? undefined : json['uploadDateTime'],
    name: !exists(json, 'name') ? undefined : json['name'],
    supplierUuid: !exists(json, 'supplierUuid') ? undefined : json['supplierUuid'],
    hazardLabels: !exists(json, 'hazardLabels') ? undefined : json['hazardLabels'],
    isConfirmedEntity: json['isConfirmedEntity'],
    uuid: json['uuid'],
    applications: !exists(json, 'applications') ? undefined : json['applications'],
    location: !exists(json, 'location') ? undefined : json['location'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    archived: !exists(json, 'archived') ? undefined : json['archived'],
    notes: !exists(json, 'notes') ? undefined : json['notes'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function SubstanceViewModelToJSON(value?: SubstanceViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uploadStatus: value.uploadStatus,
    uploadDateTime: value.uploadDateTime,
    name: value.name,
    supplierUuid: value.supplierUuid,
    hazardLabels: value.hazardLabels,
    isConfirmedEntity: value.isConfirmedEntity,
    uuid: value.uuid,
    applications: value.applications,
    location: value.location,
    deleted: value.deleted,
    archived: value.archived,
    notes: value.notes,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
