import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../library/components/d-popup.js';
import '../../library/elements/d-section.js';
import '../../library/fields/d-view-info.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';
import type { ActionInput } from 'src/library/elements/d-action.js';

export interface RecurrenceOptionsInput {
  deleting: boolean;
  isMeeting: boolean;
}

export interface RecurrenceOptionsResult {
  action: 'following' | 'single' | 'cancel';
}

/**
 *
 * USAGE:
 *    base-event-editor
 *
 */
@customElement('recurrence-options-dialog')
export class RecurrenceOptionsDialog extends BaseDialog<RecurrenceOptionsInput, RecurrenceOptionsResult> {
  @property({ type: Boolean })
  deleting = false;
  @property({ type: Boolean })
  isMeeting = false;
  width = 400;

  protected get calculatedTitle(): string {
    if (this.deleting) {
      return this.calculatedDeletingTitle();
    } else {
      return this.calculatedEditingTitle();
    }
  }

  protected get calculatedFooterActions(): ActionInput[] {
    return [
      { name: 'Alle', action: 'following' },
      { name: this.isMeeting ? 'Bare dette' : 'Bare denne', action: 'single' },
      { name: 'Avbryt', action: 'cancel' },
    ];
  }

  private get text() {
    if (this.deleting) {
      if (this.isMeeting) {
        return '<p>Du sletter et gjentatt møte. Vil du slette alle etterfølgende møter, eller bare dette?</p>';
      } else {
        return '<p>Du sletter en gjentatt oppgave. Vil du slette alle etterfølgende oppgaver, eller bare denne?</p>';
      }
    } else if (this.isMeeting) {
      return '<p>Du redigerer et gjentatt møte. Gjelder endringen alle etterfølgende møter, eller bare dette?</p>';
    } else {
      return '<p>Du redigerer en gjentatt oppgave. Gjelder endringen alle etterfølgende oppgaver, eller bare denne?</p>';
    }
  }

  renderBody() {
    return html`
      <d-section>
        <d-view-info .content=${this.text}></d-view-info>
      </d-section>
    `;
  }

  protected fetchResult(detail: string | undefined): RecurrenceOptionsResult {
    switch (detail) {
      case 'following':
        return { action: 'following' };
      case 'single':
        return { action: 'single' };
      case 'cancel':
        return { action: 'cancel' };
    }
    if (detail === undefined) {
      return { action: 'cancel' };
    }
    throw new Error('Method not implemented.');
  }

  protected initializeDialog(input: RecurrenceOptionsInput) {
    this.deleting = input.deleting;
    this.isMeeting = input.isMeeting;
  }

  private calculatedEditingTitle() {
    if (this.isMeeting) {
      return 'Redigere gjentatt møte';
    } else {
      return 'Redigere gjentatt oppgave';
    }
  }

  private calculatedDeletingTitle() {
    if (this.isMeeting) {
      return 'Slette gjentatt møte';
    } else {
      return 'Slette gjentatt oppgave';
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'recurrence-options-dialog': RecurrenceOptionsDialog;
  }
}
