import type { OrganizationState, State } from 'src/store/types.js';
import type { TutorialContent } from 'src/outskirts/d-tutorial-viewer.js';
import type { EmployeeViewModel, TutorialViewModel } from 'src/store/api';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import tutorialsList from 'src/store/data/tutorials.json';

export function isSingleTutorialActive(tutorial: TutorialViewModel, id: string) {
  const f = tutorial.participants.find((p) => p.uuid === id);
  return f !== undefined && f.assigned !== '' && f.completed === '';
}

export function isSingleTutorialAssigned(tutorial: TutorialViewModel, id: string) {
  const f = tutorial.participants.find((p) => p.uuid === id);
  return f !== undefined && f.assigned !== '';
}

export function isTutorialActive(organization: OrganizationState, currentEmployee: EmployeeViewModel | undefined) {
  if (currentEmployee === undefined) {
    return false;
  }
  const id = currentEmployee.uuid;
  return isGuideActive(organization.tutorial_state_1) || isSingleTutorialActive(organization.tutorial_2, id);
}

export function isGuideActive(tutorialState: { currentPart: number; partStates: string[] } | undefined): boolean {
  if (tutorialState === undefined) {
    return false;
  }

  const f = tutorialState.partStates.filter((s) => s === 'PASS');

  return tutorialState.currentPart > 0 && f.length !== tutorialState.partStates.length;
}

/**
 * Merges the default tutorial with the current state for the employee
 * @param tutorial
 * @param currentEmployeeTutorialState
 */
function mergeTutorial(
  tutorial: TutorialContent | undefined,
  currentEmployeeTutorialState: { currentPart: number; partStates: string[] } | undefined,
  organizationTutorialState: { currentPart: number; partStates: string[] } | undefined,
) {
  if (tutorial === undefined) return undefined;

  if (currentEmployeeTutorialState === undefined && organizationTutorialState === undefined) {
    return tutorial;
  }

  const sourceForState = organizationTutorialState ?? currentEmployeeTutorialState;

  return {
    ...tutorial,
    currentPart: sourceForState?.currentPart ?? tutorial.currentPart ?? 0,
    parts: tutorial.parts.map((p, i) => ({
      ...p,
      state: sourceForState?.partStates[i] ?? tutorial.parts[i].state ?? '',
    })),
  };
}

export function buildCurrentTutorialContent(
  currentTutorialId: string | undefined,
  organization: OrganizationState,
  currentEmployee: EmployeeViewModel | undefined,
): TutorialContent | undefined {
  if (currentEmployee === undefined) {
    return undefined;
  }

  if (currentTutorialId === undefined) {
    return undefined;
  }

  const id = currentEmployee.uuid;
  if (currentTutorialId === '1') {
    return mergeTutorial(
      tutorialsList.find((p) => p.id === '1'),
      currentEmployee['tutorial_state_1'],
      organization['tutorial_state_1'],
    );
  }

  if (currentTutorialId === '2' && isSingleTutorialAssigned(organization.tutorial_2, id)) {
    return mergeTutorial(
      tutorialsList.find((p) => p.id === '2'),
      currentEmployee['tutorial_state_2'],
      undefined,
    );
  }

  return undefined;
}

export function findAvailableTutorials(
  state: State,
  organization: OrganizationState,
  currentEmployee: EmployeeViewModel | undefined,
) {
  const result: SelectDropdownOption[] = [];
  if (currentEmployee) {
    const id = currentEmployee.uuid;
    if (isSingleTutorialAssigned(organization.tutorial_1, id)) {
      const t = tutorialsList.find((p) => p.id === '1');
      if (t) {
        result.push({ value: '1', text: t.title });
      }
    }

    if (isSingleTutorialAssigned(organization.tutorial_2, id)) {
      const t = tutorialsList.find((p) => p.id === '2');
      if (t) {
        result.push({ value: '2', text: t.title });
      }
    }
  }

  return result;
}
