/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationCreateForNewUserMessage
 */
export interface OrganizationCreateForNewUserMessage {
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateForNewUserMessage
   */
  serviceType?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateForNewUserMessage
   */
  email?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OrganizationCreateForNewUserMessage
   */
  features?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateForNewUserMessage
   */
  postAddress?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateForNewUserMessage
   */
  postPostcode?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateForNewUserMessage
   */
  postLocality?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateForNewUserMessage
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateForNewUserMessage
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateForNewUserMessage
   */
  organizationName?: string;
  /**
   *
   * @type {number}
   * @memberof OrganizationCreateForNewUserMessage
   */
  employeesCount?: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateForNewUserMessage
   */
  specialTerms?: OrganizationCreateForNewUserMessageSpecialTermsEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateForNewUserMessage
   */
  sector?: OrganizationCreateForNewUserMessageSectorEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationCreateForNewUserMessage
   */
  organizationNumber?: string;
}

/**
 * @export
 */
export const OrganizationCreateForNewUserMessageSpecialTermsEnum = {
  None: 'NONE',
  Dnlf: 'DNLF',
  Nff: 'NFF',
  Nof: 'NOF',
  Ntf: 'NTF',
  Nkf: 'NKF',
  Nmf: 'NMF',
  Npf: 'NPF',
  Nnf: 'NNF',
} as const;
export type OrganizationCreateForNewUserMessageSpecialTermsEnum =
  (typeof OrganizationCreateForNewUserMessageSpecialTermsEnum)[keyof typeof OrganizationCreateForNewUserMessageSpecialTermsEnum];

/**
 * @export
 */
export const OrganizationCreateForNewUserMessageSectorEnum = {
  Health: 'HEALTH',
  CraftsTransportationCleaning: 'CRAFTS_TRANSPORTATION_CLEANING',
  FoodAccommodation: 'FOOD_ACCOMMODATION',
  PersonalTradeService: 'PERSONAL_TRADE_SERVICE',
  Office: 'OFFICE',
  Other: 'OTHER',
} as const;
export type OrganizationCreateForNewUserMessageSectorEnum =
  (typeof OrganizationCreateForNewUserMessageSectorEnum)[keyof typeof OrganizationCreateForNewUserMessageSectorEnum];

/**
 * Check if a given object implements the OrganizationCreateForNewUserMessage interface.
 */
export function instanceOfOrganizationCreateForNewUserMessage(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function OrganizationCreateForNewUserMessageFromJSON(json: any): OrganizationCreateForNewUserMessage {
  return OrganizationCreateForNewUserMessageFromJSONTyped(json, false);
}

export function OrganizationCreateForNewUserMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationCreateForNewUserMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    serviceType: !exists(json, 'serviceType') ? undefined : json['serviceType'],
    email: !exists(json, 'email') ? undefined : json['email'],
    features: !exists(json, 'features') ? undefined : json['features'],
    postAddress: !exists(json, 'postAddress') ? undefined : json['postAddress'],
    postPostcode: !exists(json, 'postPostcode') ? undefined : json['postPostcode'],
    postLocality: !exists(json, 'postLocality') ? undefined : json['postLocality'],
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    organizationName: !exists(json, 'organizationName') ? undefined : json['organizationName'],
    employeesCount: !exists(json, 'employeesCount') ? undefined : json['employeesCount'],
    specialTerms: !exists(json, 'specialTerms') ? undefined : json['specialTerms'],
    sector: !exists(json, 'sector') ? undefined : json['sector'],
    organizationNumber: !exists(json, 'organizationNumber') ? undefined : json['organizationNumber'],
  };
}

export function OrganizationCreateForNewUserMessageToJSON(value?: OrganizationCreateForNewUserMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    serviceType: value.serviceType,
    email: value.email,
    features: value.features,
    postAddress: value.postAddress,
    postPostcode: value.postPostcode,
    postLocality: value.postLocality,
    firstName: value.firstName,
    lastName: value.lastName,
    organizationName: value.organizationName,
    employeesCount: value.employeesCount,
    specialTerms: value.specialTerms,
    sector: value.sector,
    organizationNumber: value.organizationNumber,
  };
}
