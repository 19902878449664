import { css, html, LitElement } from 'lit';
import '../../library/elements/d-label.js';
import { customElement, property } from 'lit/decorators.js';

/**
 * Label med check / X foran. brukes for å vise krav som er oppfylt eller ikke oppfylt
 *
 *
 */
@customElement('d-view-checkpoint')
export class DViewCheckpoint extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .item {
      padding: 4px 0 4px 26px;
      background: url('/images/x-fat-orange.svg') 0 3px no-repeat;
    }

    .item:before {
      content: 'Ikke oppfylt: ';
    }

    .checked {
      background: url('/images/check-mini-blue.svg') 0 3px no-repeat;
    }

    .checked:before {
      content: '';
    }
  `;
  @property({ type: String })
  field = '';
  @property({ type: Boolean })
  checked = false;

  _computeClasses(checked) {
    let classes = 'checkpoint';
    if (checked) {
      classes += ' checked';
    }
    return classes;
  }

  render() {
    return html` <d-label allow-wrap classes="${this._computeClasses(this.checked)}" field="${this.field}"></d-label> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-checkpoint': DViewCheckpoint;
  }
}
