import { css, html, nothing, PropertyValues } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import type { AbstractPageView } from 'src/pages/abstract-page-view';
import { leavePeriodStyles } from 'src/library/leave-period-styles';
import { ListSectionItemInput } from 'src/library/lists/utilities';
import 'src/library/lists/d-list-section';
import { LocalDate } from 'src/utilities/local-date';
import { capitalize } from 'src/utilities/text';
import { DPromoDialog, PromoDialogResult } from 'src/library/promo/d-promo-dialog';
import { getEven, NameValue } from 'src/utilities/layout';
import _ from 'lodash';
import { features, promo } from 'src/store/selectors/features';

interface Widget {
  name: string;
  active: boolean;
  promo: string;
}

export interface DocumentUpdatesForStatusPage {
  summary: ListSectionItemInput[];
  items: ListSectionItemInput[];
}

export interface StartTasksForStatusPage {
  total: number;
  done: number;
  statusText: string;
  items: ListSectionItemInput[];
}
export interface StatusPageViewModel extends AbstractPageView {
  type: 'status-page';
  icon: 'robot';
  key: string;
  pdfHref: string;
  isInternalOrganization: boolean;
  leavePeriodsToday?: ListSectionItemInput[];
  overdueEvents: ListSectionItemInput[];
  drafts: ListSectionItemInput[];
  issues: ListSectionItemInput[];
  documentUpdates: DocumentUpdatesForStatusPage;
  startTasks: StartTasksForStatusPage;
  futureMeetings?: ListSectionItemInput[];
  interviewInfo?: { status: string; text: string }[];
  currentEmployeeInfo?: { type: string; text: string; class: string }[];
  functionsInfo?: ListSectionItemInput[];
}

/**
 *
 * STATUS OK
 */
@customElement('d-status-page-content')
export class DStatusPageContent extends PageContent<StatusPageViewModel> {
  static readonly styles = [
    ...super.styles,
    leavePeriodStyles,
    css`
      :host {
        display: block;
        direction: ltr;
        color: var(--text-color);
      }
      #measure {
        position: fixed;
        top: -10000000px;
        opacity: 0;
      }
      .wrapper {
        display: flex;
        gap: 20px;
        margin-top: 12px;
      }
      .column {
        flex: 1;
        max-width: 500px;
      }
      .widget {
        box-shadow: 0 0 10px hsla(1, 0%, 0%, 0.2);
        margin-bottom: 20px;
        background: white;
        border-radius: 12px;
        padding: 18px 20px 12px;
        line-height: 130%;
      }
      .widget.promo {
        box-shadow: none;
        opacity: 0.7;
        cursor: pointer;
      }
      @media (hover: hover) {
        .widget.promo:hover {
          box-shadow: 0 0 10px hsla(1, 0%, 0%, 0.2);
          opacity: 1;
          transform: scale(1.01);
          transition: all 0.3s;
        }
      }
      .name {
        font-size: 22px;
        line-height: 120%;
        font-weight: 500;
        margin-bottom: 6px;
      }
      .name:last-child {
        margin-bottom: 8px;
      }
      .status-text {
        font-weight: 400;
        padding: 5px 0;
      }
      .status-text.checked {
        background: url(/images/check-mini-blue.svg) -2px 3px no-repeat;
        background-size: 24px auto;
        padding-left: 26px;
      }
      .status-text.alert {
        background: url(/images/alert.svg) -2px 2px no-repeat;
        background-size: 24px auto;
        padding-left: 26px;
      }
      .actions {
        margin: 0 -8px;
      }
      d-progress-bar {
        height: 8px;
        margin: 10px 0 6px 0;
      }
    `,
  ];

  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  showUpdates = false;
  @property({ type: Boolean })
  showStartTasks = false;
  @property({ type: Boolean })
  showAllIssues = false;
  @property({ type: Array })
  columns: Widget[][] = [];
  @state()
  columnsCount = 1;
  @state()
  private debouncedSetColumns = _.debounce(() => this.setColumns(), 100, { maxWait: 100 });

  onResize() {
    super.onResize();
    this.debouncedSetColumns();
  }

  private setColumns() {
    const width = this.getBoundingClientRect().width;
    if (width < 700) {
      this.columnsCount = 1;
    } else if (width < 1200) {
      this.columnsCount = 2;
    } else {
      this.columnsCount = 3;
    }
    const widgetHeights: NameValue[] = this.widgetHeights;
    const columns: NameValue[][] = getEven(widgetHeights, this.columnsCount);
    this.columns = columns.map((column: NameValue[]) => {
      return column.map((item: NameValue) => {
        const widget: Widget | undefined = this.widgets.find((w) => {
          return w.name === item.name;
        });
        return widget || { name: '', active: false, promo: '' };
      });
    });
  }

  private get widgetHeights(): NameValue[] {
    const wrapper = this.shadowRoot?.getElementById('wrapper');
    if (wrapper) {
      const gap = 20;
      const columnWidth = (wrapper.offsetWidth - gap * (this.columnsCount - 1)) / this.columnsCount;
      const measure = this.shadowRoot?.getElementById('measure');
      if (measure) {
        measure.style.width = columnWidth + 'px';
        const widgets = [...measure.getElementsByClassName('widget')] as HTMLElement[];
        return widgets.map((w: HTMLElement): NameValue => {
          return {
            name: w.dataset.name ?? '',
            value: w.offsetHeight,
          };
        });
      }
    }
    return [];
  }

  protected get currentItemSublabel() {
    return capitalize(LocalDate.now().toStringForDisplayWithDayOfWeekAndYear());
  }

  private onStartTaskClicked(e: CustomEvent<{ clickData: string }>) {
    e.preventDefault();
    this.dispatchEvent(
      new CustomEvent<{ uuid: string }>('start-task-selected', {
        bubbles: true,
        composed: true,
        detail: { uuid: e.detail.clickData },
      }),
    );
  }

  async onPromoClick(feature: string) {
    const promoData = promo.find((p) => {
      return p.id === feature;
    });
    if (promoData) {
      const result: PromoDialogResult = await DPromoDialog.open({
        promoData: promo.find((p) => {
          return p.id === feature;
        }),
        features: features,
        employeesCount: this.pageView.employeesCount,
        specialTerms: this.pageView.specialTerms,
      });
      if (result.action === 'upgrade' && promoData.pageAfterUpgrade)
        this.dispatchEvent(
          new CustomEvent('navigate', {
            bubbles: true,
            composed: true,
            detail: { href: '/account/' + this.pageView.organizationId + '/' + promoData.pageAfterUpgrade },
          }),
        );
    }
  }

  private get widgets(): Widget[] {
    const m = this.featureStates;
    const activeWidgets: Widget[] = [];
    const inactiveWidgets: Widget[] = [];
    const widgets: Widget[] = [
      {
        name: 'myThings',
        active: m.core,
        promo: '',
      },
      { name: 'staffing', active: m.core || m.staffing, promo: 'staffing' },
      { name: 'overdue', active: m.core, promo: '' },
      { name: 'drafts', active: m.core, promo: '' },
      { name: 'updates', active: m.core, promo: '' },
      { name: 'issues', active: m.core || m.issues, promo: 'issues' },
      { name: 'startTasks', active: m.core, promo: '' },
      { name: 'meetings', active: m.core || m.meetings, promo: 'meetings' },
      { name: 'interviews', active: m.core || m.meetings, promo: 'meetings' },
      { name: 'functions', active: m.core || m.meetings, promo: '' },
    ];
    if (this.pageView.isInternalOrganization) {
      widgets.push({ name: 'healthcare', active: m.healthcare, promo: 'healthcare' }); // TODO
    }
    widgets.forEach((widget) => {
      if (widget.active) {
        activeWidgets.push(widget);
      } else if (widget.promo) {
        inactiveWidgets.push(widget);
      }
    });
    return [...activeWidgets, ...inactiveWidgets];
  }

  renderMyThings(widget: Widget) {
    if (widget.active && this.pageView.currentEmployeeInfo) {
      return html`
        <div class="widget" data-name="${widget.name}">
          <div class="name">Meg og mitt</div>
          ${this.pageView.currentEmployeeInfo
            .filter((item) => item.type === 'first')
            .map((item) => {
              return html`<div class="status-text ${item.class}">${item.text}</div>`;
            })}
          <div class="actions">
            <d-action plain href="${'/account/' + this.pageView.organizationId + '/318434'}"> Gå til Ukeplan </d-action>
          </div>
          ${this.pageView.currentEmployeeInfo
            .filter((item) => item.type === 'second')
            .map((item) => {
              return html`<div class="status-text ${item.class}">${item.text}</div>`;
            })}
          <div class="actions">
            <d-action
              plain
              href="${'/account/' +
              this.pageView.organizationId +
              '/695944/employees/' +
              this.pageView.currentEmployeeUuid}"
            >
              Gå til mine personalopplysninger
            </d-action>
          </div>
        </div>
      `;
    }
    return html``;
  }

  renderStaffing(widget: Widget) {
    if (this.pageView.leavePeriodsToday) {
      if (widget.active) {
        return html`
          <div class="widget" data-name="${widget.name}">
            <div class="name">Bemanning og fravær</div>
            ${this.pageView.leavePeriodsToday.length
              ? html` <d-list-section no-header .items=${this.pageView.leavePeriodsToday}></d-list-section> `
              : html` <div class="status-text checked">Ingen fravær i dag</div> `}
            <div class="actions">
              <d-action plain href="${'/account/' + this.pageView.organizationId + '/5962'}"
                >Gå til Bemanningskalender
              </d-action>
            </div>
          </div>
        `;
      }
      if (widget.promo) {
        return html`
          <div class="widget promo" data-name="${widget.name}" @click=${() => this.onPromoClick(widget.promo)}>
            <div class="name">Bemanning og fravær</div>
          </div>
        `;
      }
    }
    return html``;
  }

  renderOverdue(widget: Widget) {
    if (widget.active) {
      return html`
        <div class="widget" data-name="${widget.name}">
          <div class="name">Oppgaver på overtid</div>
          ${this.pageView.overdueEvents.length
            ? html` <d-list-section no-header .items=${this.pageView.overdueEvents}></d-list-section> `
            : html` <div class="status-text checked">Ingen oppgaver på overtid</div> `}
          <div class="actions">
            <d-action plain href="${'/account/' + this.pageView.organizationId + '/318434'}">Gå til Ukeplan</d-action>
          </div>
        </div>
      `;
    }
    return html``;
  }

  renderDrafts(widget: Widget) {
    if (widget.active) {
      return html`
        <div class="widget" data-name="${widget.name}">
          <div class="name">Utkast</div>
          ${this.pageView.drafts.length
            ? html` <d-list-section no-header .items=${this.pageView.drafts}></d-list-section> `
            : html`<div class="status-text checked">Ingen utkast</div> `}
        </div>
      `;
    }
    return html``;
  }

  renderIssues(widget: Widget, interactive: boolean) {
    if (widget.active) {
      const toggleShowLabel = this.showAllIssues ? 'Vis færre' : 'Vis alle';
      return html`
        <div class="widget" data-name="${widget.name}">
          <div class="name">Aktive avvik</div>
          ${this.pageView.issues.length
            ? html`
                <d-list-section
                  no-header
                  .items=${this.pageView.issues.filter((item, index) => {
                    return index < 3;
                  })}
                ></d-list-section>
                <d-expansion .opened=${this.showAllIssues && interactive}>
                  <d-list-section
                    no-header
                    .items=${this.pageView.issues.filter((item, index) => {
                      return index > 2;
                    })}
                  ></d-list-section>
                </d-expansion>
              `
            : html` <div class="status-text checked">Ingen åpne avvik</div> `}
          <div class="actions">
            ${this.pageView.issues.length > 3
              ? html`<d-action plain @click=${() => (this.showAllIssues = !this.showAllIssues)}
                  >${toggleShowLabel}</d-action
                >`
              : nothing}
            <d-action plain href="${'/account/' + this.pageView.organizationId + '/882901'}"
              >Gå til Avviksmeldinger
            </d-action>
          </div>
        </div>
      `;
    }
    if (widget.promo) {
      return html`
        <div class="widget promo" data-name="${widget.name}" @click=${() => this.onPromoClick(widget.promo)}>
          <div class="name">Avvik</div>
        </div>
      `;
    }
    return html``;
  }

  renderUpdates(widget: Widget, interactive: boolean) {
    if (widget.active) {
      const toggleShowLabel = this.showUpdates ? 'Skjul oppdateringer' : 'Vis oppdateringer';
      return html`
        <div class="widget" data-name="${widget.name}">
          <div class="name">Oppdateringer</div>
          <d-list-section no-header .items=${this.pageView.documentUpdates.summary}></d-list-section>
          ${this.pageView.documentUpdates.items.length > 0
            ? html`
                <div class="actions">
                  <d-action plain @click=${() => (this.showUpdates = !this.showUpdates)}>${toggleShowLabel}</d-action>
                </div>
              `
            : nothing}
          <d-expansion .opened=${this.showUpdates && interactive}>
            <d-list-section
              style="margin-top: 6px"
              no-header
              .items=${this.pageView.documentUpdates.items}
            ></d-list-section>
          </d-expansion>
        </div>
      `;
    }
    return html``;
  }

  renderStartTasks(widget: Widget, interactive: boolean) {
    if (widget.active) {
      const toggleShowLabel = this.showStartTasks ? 'Skjul gjenstående' : 'Vis gjenstående';
      return html`
        <div class="widget" data-name="${widget.name}">
          <div class="name">Etableringsoppgaver</div>
          ${this.pageView.startTasks.items.length
            ? html`
                <d-progress-bar
                  .total=${this.pageView.startTasks.total}
                  .partNumber=${this.pageView.startTasks.done}
                ></d-progress-bar>
              `
            : nothing}
          <div class="status-text ${this.pageView.startTasks.items.length === 0 ? 'checked' : ''}">
            ${this.pageView.startTasks.statusText}
          </div>
          ${this.pageView.startTasks.items.length
            ? html`
                <div class="actions">
                  <d-action plain @click=${() => (this.showStartTasks = !this.showStartTasks)}
                    >${toggleShowLabel}
                  </d-action>
                </div>
                <d-expansion ?opened=${this.showStartTasks && interactive}>
                  <d-list-section
                    .items=${this.pageView.startTasks.items}
                    no-header
                    start-tasks
                    @item-clicked=${this.onStartTaskClicked}
                  ></d-list-section>
                </d-expansion>
              `
            : nothing}
        </div>
      `;
    }
    if (widget.promo) {
      return html`
        <div class="widget promo" data-name="${widget.name}" @click=${() => this.onPromoClick(widget.promo)}>
          <div class="name">Etableringsoppgaver</div>
        </div>
      `;
    }
    return html``;
  }

  renderMeetings(widget: Widget) {
    if (this.pageView.futureMeetings) {
      if (widget.active) {
        return html`
          <div class="widget" data-name="${widget.name}">
            <div class="name">Møter</div>
            ${this.pageView.futureMeetings.length
              ? html` <d-list-section no-header .items=${this.pageView.futureMeetings}></d-list-section> `
              : html` <div class="status-text checked">Ingen møter i dag eller i morgen</div> `}
            <div class="actions">
              <d-action plain href="${'/account/' + this.pageView.organizationId + '/9772'}">Gå til Møter</d-action>
            </div>
          </div>
        `;
      }
      if (widget.promo) {
        return html`
          <div class="widget promo" data-name="${widget.name}" @click=${() => this.onPromoClick(widget.promo)}>
            <div class="name">Møter og medarbeidersamtaler</div>
          </div>
        `;
      }
    }
    return html``;
  }

  renderInterviews(widget: Widget) {
    if (widget.active && this.pageView.interviewInfo) {
      return html`
        <div class="widget" data-name="${widget.name}">
          <div class="name">Medarbeidersamtaler</div>
          ${this.pageView.interviewInfo.map((item) => {
            return html`<div class="status-text ${item.status}">${item.text}</div>`;
          })}
          <div class="actions">
            <d-action plain href="${'/account/' + this.pageView.organizationId + '/9772'}">Gå til Møter</d-action>
          </div>
        </div>
      `;
    }
    return html``;
  }

  renderFunctions(widget: Widget) {
    if (widget.active && this.pageView.functionsInfo) {
      return html`
        <div class="widget" data-name="${widget.name}">
          <div class="name">Ansvarsområder</div>
          <d-list-section style="margin-top: 6px" no-header .items=${this.pageView.functionsInfo}></d-list-section>
        </div>
      `;
    }
    return html``;
  }

  renderHealthcare(widget: Widget) {
    const items: ListSectionItemInput[] = [
      { label: 'Vi har TrinnVis Bedriftshelsetjeneste', classes: 'ok', accessible: false },
    ];
    if (widget.active) {
      return html`
        <div class="widget" data-name="${widget.name}">
          <div class="name">Bedriftshelsetjeneste</div>
          <d-list-section style="margin-top: 6px" no-header .items=${items}></d-list-section>
        </div>
      `;
    }
    if (widget.promo) {
      return html`
        <div class="widget promo" data-name="${widget.name}" @click=${() => this.onPromoClick(widget.promo)}>
          <div class="name">Bedriftshelsetjeneste</div>
        </div>
      `;
    }
    return html``;
  }

  renderWidget(widget: Widget, interactive: boolean = true) {
    if (widget.name === 'myThings') {
      return this.renderMyThings(widget);
    } else if (widget.name === 'staffing') {
      return this.renderStaffing(widget);
    } else if (widget.name === 'overdue') {
      return this.renderOverdue(widget);
    } else if (widget.name === 'drafts') {
      return this.renderDrafts(widget);
    } else if (widget.name === 'issues') {
      return this.renderIssues(widget, interactive);
    } else if (widget.name === 'updates') {
      return this.renderUpdates(widget, interactive);
    } else if (widget.name === 'startTasks') {
      return this.renderStartTasks(widget, interactive);
    } else if (widget.name === 'meetings') {
      return this.renderMeetings(widget);
    } else if (widget.name === 'interviews') {
      return this.renderInterviews(widget);
    } else if (widget.name === 'functions') {
      return this.renderFunctions(widget);
    } else if (widget.name === 'healthcare') {
      return this.renderHealthcare(widget);
    } else {
      return html``;
    }
  }

  renderColumn(column: Widget[]) {
    return html`<div class="column">
      ${column.map((widget: Widget) => {
        return this.renderWidget(widget);
      })}
    </div>`;
  }

  renderContent() {
    return html`<div id="wrapper" class="wrapper">
        ${this.columns.map((column: Widget[]) => {
          return this.renderColumn(column);
        })}
      </div>
      <div id="measure">
        ${this.widgets.map((widget: Widget) => {
          return this.renderWidget(widget, false);
        })}
      </div>`;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    this.debouncedSetColumns();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-status-page-content': DStatusPageContent;
  }
}
