import { css, html, nothing } from 'lit';
import './parts/d-select-organization.js';
import './parts/d-organization-header.js';
import './parts/d-robot-alert.js';
import '../library/fields/d-spinner-robot.js';
import '../library/elements/d-wrap.js';
import '../library/elements/d-action.js';
import './d-level-touch-slider';
import { customElement, property, state } from 'lit/decorators.js';
import './parts/file-viewer-dialog.js';
import { classMap } from 'lit/directives/class-map.js';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';
/**
 *
 */
@customElement('d-application-layout')
export class DApplicationLayout extends ResponsiveElement {
  static readonly styles = css`
    :host {
      display: block;
      --top-height: 56px;
      --main-menu-width: 240px;
      --level-top-step: 32px;
      --level-left-step: 20px;
      --minified-header-height: 36px;
      --document-width: 784px;
      --aw: 1000px;
      --available-width-wide: calc(var(--aw) - var(--main-menu-width) - var(--document-width));
      --available-width-narrow: calc(var(--aw) - var(--level-left-step) - var(--document-width));
      --transition: 500ms ease;
      --main-sans: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
      --main-serif: 'Chronicle SSm A', 'Chronicle SSm B', sans-serif;
      --meta-content-max-width: 1004px;
    }

    html {
      scroll-behavior: smooth;
    }

    :host {
      margin: 0;
      height: 100vh;
      font-family: var(--main-sans), sans-serif;
      font-size: 15px;
      line-height: 150%;
      -webkit-text-size-adjust: 100%;
    }

    #body.dragging {
      top: 0;
      right: 0;
      left: 0;
      position: fixed;
      overflow: hidden;
    }

    .backdrop {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background-color: hsla(197, 82%, 20%, 0.6);
      backdrop-filter: blur(2px);
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
    }

    :host([edit]) .backdrop {
      visibility: visible;
      opacity: 1;
    }

    :host([edit]) #body.current-level-2 .backdrop {
      z-index: 3;
    }

    :host([edit]) #body.current-level-3 .backdrop {
      z-index: 4;
    }

    :host([edit]) #body.current-level-4 .backdrop {
      z-index: 5;
    }

    :host([edit]) #body.current-level-5 .backdrop {
      z-index: 6;
    }

    header {
      display: block;
      position: fixed;
      top: 0;
      height: var(--top-height);
      color: white;
      z-index: 2;
    }

    #search {
      position: fixed;
      top: var(--top-height);
      width: 100%;
      height: 0;
      overflow: hidden;
      color: white;
      z-index: 1;
      opacity: 0;
      transition: all var(--transition);
    }

    .search #search {
      height: var(--levels-top);
      opacity: 1;
    }

    #search-header {
      width: calc(100% - 40px);
      max-width: 600px;
      display: flex;
      padding: 0 20px;
      margin-bottom: 16px;
    }

    #search-content {
      padding: 20px;
      min-height: 1000px;
    }

    #search-header input {
      border-radius: 18px;
      margin-right: 6px;
      padding: 6px 9px 6px 30px;
      background: white url(/images/search.svg) 3px 50% no-repeat;
      background-size: 24px 24px;
      font-family: var(--mainSans);
      box-shadow: none;
      font-size: 15px;
      line-height: 160%;
      resize: none;
      border: none;
      -webkit-appearance: none;
      -webkit-writing-mode: horizontal-tb;
      writing-mode: horizontal-tb;
      width: 100%;
      outline: none;
      box-sizing: border-box;
    }

    #settings {
      position: fixed;
      top: var(--top-height);
      width: 100%;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      z-index: 1;
      transition: all var(--transition);
    }

    .settings #settings {
      max-height: 100vh;
      opacity: 1;
      color: black;
    }

    #settings-content {
      max-width: 1004px;
      min-height: 500px;
      margin: 0 20px 20px 20px;
      border-radius: 12px;
      background-color: hsla(196, 100%, 81%, 0.45);
      padding: 20px;
      transition: all var(--transition);
    }

    .max844 #settings-content {
      margin: 0 0 20px 0;
      border-radius: 0;
    }

    /* LEVELS AND LEVEL CONTENT */

    .level {
      position: fixed;
      top: calc(100vh + 10px);
      width: var(--document-width);
      max-width: 100%;
      background-color: white;
      box-shadow: 0 0 10px hsla(1, 0%, 0%, 0.3);
      transition:
        top var(--transition),
        left var(--transition),
        width var(--transition),
        height var(--transition),
        margin var(--transition);
    }

    .dragging .level {
      transition: all 0s;
    }

    #level-0 {
      top: calc(var(--top-height) + var(--levels-top));
      width: 100%;
      max-height: calc(100vh - var(--top-height));
      padding-top: 12px;
      background: none;
      box-shadow: none;
      overflow-x: hidden;
      overflow-y: auto;
      direction: rtl;
      z-index: 1;
    }

    @media only screen and (min-width: 600px) {
      #level-0 {
        width: var(--main-menu-width);
        padding-top: 0;
      }
    }

    #level-1 {
      border-top-left-radius: 12px;
      background-color: var(--backgroundGray);
      box-shadow: 0px 0px 14px hsla(1, 0%, 0%, 0.35);
      z-index: 2;
    }

    #level-2 {
      z-index: 3;
    }

    #level-3 {
      z-index: 4;
    }

    #level-4 {
      z-index: 5;
    }

    #level-5 {
      z-index: 6;
    }

    .level-content {
      position: relative;
      height: 100%;
      background: white;
      box-shadow: 0 0 10px hsla(1, 0%, 0%, 0.3);
      transition: all var(--transition);
    }

    #level-0 .level-content {
      box-shadow: none;
      background: transparent;
      padding-bottom: 100px;
    }

    #level-1 .level-content {
      border-top-left-radius: 12px;
      background: hsl(1, 0%, 90%);
    }

    /* STATE: VISIBLE */

    #body.level-1 #level-1 {
      top: calc(var(--top-height) + var(--levels-top));
      height: calc(100vh - (var(--top-height) + var(--levels-top)));
    }

    #body.level-2 #level-2 {
      top: calc(var(--top-height) + var(--levels-top) + var(--level-top-step));
      height: calc(100vh - (var(--top-height) + var(--levels-top) + var(--level-top-step)));
    }

    #body.level-3 #level-3 {
      top: calc(var(--top-height) + var(--levels-top) + var(--level-top-step) * 2);
      height: calc(100vh - (var(--top-height) + var(--levels-top) + var(--level-top-step) * 2));
    }

    #body.level-4 #level-4 {
      top: calc(var(--top-height) + var(--levels-top) + var(--level-top-step) * 3);
      height: calc(100vh - (var(--top-height) + var(--levels-top) + var(--level-top-step) * 3));
    }

    #body.level-5 #level-5 {
      top: calc(var(--top-height) + var(--levels-top) + var(--level-top-step) * 4);
      height: calc(100vh - (var(--top-height) + var(--levels-top) + var(--level-top-step) * 4));
    }

    /* STATE: CURRENT */

    #body:not(.search):not(.settings).current-level-0 #level-0 {
      position: absolute;
    }

    #body:not(.search):not(.settings).current-level-1 #level-1,
    #body:not(.search):not(.settings).current-level-2 #level-2,
    #body:not(.search):not(.settings).current-level-3 #level-3,
    #body:not(.search):not(.settings).current-level-4 #level-4,
    #body:not(.search):not(.settings).current-level-5 #level-5 {
      position: absolute;
      top: 0;
      height: auto;
    }

    #body:not(.search):not(.settings).current-level-1 #level-1 {
      margin-top: var(--top-height);
      min-height: calc(100vh - var(--top-height));
    }

    #body:not(.search):not(.settings).current-level-2 #level-2 {
      margin-top: calc(var(--top-height) + var(--level-top-step));
      min-height: calc(100vh - (var(--top-height) + var(--level-top-step)));
    }

    #body:not(.search):not(.settings).current-level-3 #level-3 {
      margin-top: calc(var(--top-height) + var(--level-top-step) * 2);
      min-height: calc(100vh - (var(--top-height) + var(--level-top-step) * 2));
    }

    #body:not(.search):not(.settings).current-level-4 #level-4 {
      margin-top: calc(var(--top-height) + var(--level-top-step) * 3);
      min-height: calc(100vh - (var(--top-height) + var(--level-top-step) * 3));
    }

    #body:not(.search):not(.settings).current-level-5 #level-5 {
      margin-top: calc(var(--top-height) + var(--level-top-step) * 4);
      min-height: calc(100vh - (var(--top-height) + var(--level-top-step) * 4));
    }

    :host([edit]) #body:not(.max844).current-level-2 #level-2,
    :host([edit]) #body:not(.max844).current-level-3 #level-3,
    :host([edit]) #body:not(.max844).current-level-4 #level-4,
    :host([edit]) #body:not(.max844).current-level-5 #level-5 {
      box-shadow: 0 16px 24px 2px hsla(0, 0%, 0%, 0.3);
      left: calc((var(--aw) - var(--document-width)) / 2);
    }

    /* Settings open */

    #body.settings.level-0:not(.current-level-0) #level-0,
    #body.settings.level-1:not(.current-level-1) #level-1,
    #body.settings.level-2:not(.current-level-2) #level-2,
    #body.settings.level-3:not(.current-level-3) #level-3,
    #body.settings.level-4:not(.current-level-4) #level-4,
    #body.settings.level-5:not(.current-level-5) #level-5 {
      top: 100vh;
      height: 0;
    }

    #body.current-level-1 #level-1,
    #body.current-level-2 #level-2,
    #body.current-level-3 #level-3,
    #body.current-level-4 #level-4,
    #body.current-level-5 #level-5 {
      transition: all var(--transition);
    }

    #body.settings.current-level-1 #level-1,
    #body.settings.current-level-2 #level-2,
    #body.settings.current-level-3 #level-3,
    #body.settings.current-level-4 #level-4,
    #body.settings.current-level-5 #level-5 {
      margin-top: 0;
      top: 100vh;
      animation: to-hidden 0s;
      animation-delay: 500ms;
      animation-fill-mode: forwards;
    }

    @keyframes to-hidden {
      to {
        height: 0;
        overflow: hidden;
      }
    }

    /* HORIZONTAL MEASURES --------------------------------- */

    /* WIDTH: >= 1600 */

    /* State: hidden and current */

    #level-1 {
      left: var(--main-menu-width);
      width: calc(var(--aw) - var(--main-menu-width));
    }

    #level-2 {
      left: calc(var(--main-menu-width) + var(--document-width));
    }

    #level-3,
    #level-4,
    #level-5 {
      left: calc(var(--aw) - var(--document-width));
    }

    /* State: covered */

    .level-3 #level-2 {
      left: calc(var(--main-menu-width) + var(--available-width-wide) / 2);
    }

    .level-4 #level-2 {
      left: calc(var(--main-menu-width) + var(--available-width-wide) / 3);
    }

    .level-5 #level-2 {
      left: calc(var(--main-menu-width) + var(--available-width-wide) / 4);
    }

    .level-4 #level-3 {
      left: calc(var(--main-menu-width) + (var(--available-width-wide) / 3) * 2);
    }

    .level-5 #level-3 {
      left: calc(var(--main-menu-width) + (var(--available-width-wide) / 4) * 2);
    }

    .level-5 #level-4 {
      left: calc(var(--main-menu-width) + (var(--available-width-wide) / 4) * 3);
    }

    /* WIDTH: < 1800 */

    .max1809 #level-2 {
      left: calc(var(--aw) - var(--document-width));
    }

    .max1809.level-3 #level-2 {
      left: calc(var(--main-menu-width) + var(--level-left-step));
    }

    /* WIDTH: < 1600 */

    .max1599.level-3 #level-2 {
      left: calc(var(--main-menu-width) + var(--level-left-step));
    }

    .max1599.level-4 #level-3 {
      left: calc(var(--main-menu-width) + var(--level-left-step) * 2);
    }

    .max1599.level-5 #level-4 {
      left: calc(var(--main-menu-width) + var(--level-left-step) * 3);
    }

    /* WIDTH: < 1280 */

    .max1279.level-5 #level-1 {
      left: var(--level-left-step);
      width: calc(var(--aw) - var(--level-left-step));
    }

    .max1279.level-5 #level-2 {
      left: calc(var(--level-left-step) * 2);
    }

    .max1279.level-5 #level-3 {
      left: calc(var(--level-left-step) * 3);
    }

    .max1279.level-5 #level-4 {
      left: calc(var(--level-left-step) * 4);
    }

    /* WIDTH: < 1220 */

    .max1219.level-2 #level-1 {
      left: var(--level-left-step);
      width: calc(var(--aw) - var(--level-left-step));
    }

    .max1219.level-3 #level-2 {
      left: calc(var(--level-left-step) * 2);
    }

    .max1219.level-4 #level-3 {
      left: calc(var(--level-left-step) * 3);
    }

    /* WIDTH: < 1024 */

    /* State: hidden and current */

    .max1023 #level-1 {
      left: var(--level-left-step);
      width: calc(var(--aw) - var(--level-left-step));
    }

    .max1023 #level-2 {
      left: calc(var(--aw) - var(--document-width));
    }

    /* State: covered */

    .max1023.level-4 #level-1 {
      left: 0;
      width: var(--aw);
    }

    .max1023.level-4 #level-2 {
      left: var(--level-left-step);
    }

    .max1023.level-4 #level-3 {
      left: calc(var(--level-left-step) * 2);
    }

    .max1023.level-5 #level-2 {
      left: 0;
    }

    .max1023.level-5 #level-3 {
      left: var(--level-left-step);
    }

    .max1023.level-5 #level-4 {
      left: calc(var(--level-left-step) * 2);
    }

    .max1023.level-4 #level-1 .level-content {
      border-radius: 0;
    }

    /* WIDTH: < 845 */

    /* State: hidden and current */

    .max844 #level-1,
    .max844.level-2 #level-1,
    .max844 #level-2,
    .max844 #level-3,
    .max844.level-4 #level-3,
    .max844 #level-4,
    .max844.level-5 #level-4,
    .max844 #level-5 {
      left: 0;
      width: var(--aw);
    }

    .max844 #level-1 .level-content {
      border-radius: 0;
    }

    /* State: covered */

    .max844.level-2 #level-1,
    .max844.level-3 #level-2 {
      left: 0;
    }

    #new-document {
      position: fixed;
      bottom: 0;
      z-index: 100;
    }

    /* UNCOVER MENU */

    /* >= 1280 Menu is always visible */

    /* < 1280 Menu is otherwise covered on level 5 */

    .uncover-menu.max1279.level-5 #level-1 {
      left: var(--main-menu-width);
      width: calc(var(--aw) - var(--main-menu-width));
    }

    .uncover-menu.max1279.level-5 #level-2 {
      left: calc(var(--main-menu-width) + var(--level-left-step));
    }

    .uncover-menu.max1279.level-5 #level-3 {
      left: calc(var(--main-menu-width) + var(--level-left-step) * 2);
    }

    .uncover-menu.max1279.level-5 #level-4 {
      left: calc(var(--main-menu-width) + var(--level-left-step) * 3);
    }

    /* < 1220 Menu is otherwise covered on level 1 */

    .uncover-menu.max1219.level-2 #level-1 {
      left: var(--main-menu-width);
      width: calc(var(--aw) - var(--main-menu-width));
    }

    .uncover-menu.max1219.level-3 #level-2 {
      left: calc(var(--main-menu-width) + var(--level-left-step));
    }

    .uncover-menu.max1219.level-4 #level-3 {
      left: calc(var(--main-menu-width) + (var(--level-left-step) * 2));
    }

    .uncover-menu.max1219 #level-3 {
      max-width: calc(var(--aw) - var(--main-menu-width) - (var(--level-left-step) * 2));
      left: max(
        calc(var(--aw) - var(--document-width)),
        calc(var(--aw) - (var(--aw) - var(--main-menu-width) - (var(--level-left-step) * 2)))
      );
    }

    .uncover-menu.max1219 #level-4 {
      max-width: calc(var(--aw) - var(--main-menu-width) - (var(--level-left-step) * 3));
      left: max(
        calc(var(--aw) - var(--document-width)),
        calc(var(--aw) - (var(--aw) - var(--main-menu-width) - (var(--level-left-step) * 3)))
      );
    }

    .uncover-menu.max1219 #level-5 {
      max-width: calc(var(--aw) - var(--main-menu-width) - (var(--level-left-step) * 4));
      left: max(
        calc(var(--aw) - var(--document-width)),
        calc(var(--aw) - (var(--aw) - var(--main-menu-width) - (var(--level-left-step) * 4)))
      );
    }

    /* < 1024 Menu is otherwise always covered */

    .uncover-menu.max1023 #level-1,
    .uncover-menu.max1023 #level-2,
    .uncover-menu.max1023.level-3 #level-2,
    .uncover-menu.max1023 #level-3,
    .uncover-menu.max1023.level-4 #level-3,
    .uncover-menu.max1023 #level-4,
    .uncover-menu.max1023.level-5 #level-4,
    .uncover-menu.max1023 #level-5 {
      left: var(--main-menu-width);
      width: calc(var(--aw) - var(--main-menu-width));
      max-width: none;
    }

    /* < 845 Not enough space to uncover menu */

    .uncover-menu.max844 #level-1,
    .uncover-menu.max844.level-2 #level-1,
    .uncover-menu.max844 #level-2,
    .uncover-menu.max844.level-3 #level-2,
    .uncover-menu.max844 #level-3,
    .uncover-menu.max844.level-4 #level-3,
    .uncover-menu.max844 #level-4,
    .uncover-menu.max844.level-5 #level-4,
    .uncover-menu.max844 #level-5 {
      left: 0;
      width: var(--aw);
    }
  `;

  /**
   * The route path segments. The first element is the current pageId
   */
  @property({ type: Boolean })
  isAppPlatform = false;
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Array })
  route: string[] = [];
  @property({ type: Number })
  currentLevel = 0;
  @property({ type: Boolean })
  search = false;
  @property({ type: Boolean })
  settings = false;
  @property({ type: Boolean, reflect: true })
  edit = false;
  @property({ type: Boolean, attribute: 'uncover-menu', reflect: true })
  uncoverMenu = false;

  @state()
  private appWidth = 0;

  private get headerStyle() {
    return 'width: ' + this.appWidth + 'px';
  }

  private get newDocumentStyle() {
    return 'left: ' + this.appWidth + 'px';
  }

  onResize(contentRect: DOMRectReadOnly) {
    this.appWidth = contentRect.width;
    this.style.setProperty('--aw', this.appWidth + 'px');
    this.dispatchEvent(
      new CustomEvent('app-width-changed', { composed: true, bubbles: true, detail: { appWidth: this.appWidth } }),
    );
  }

  private openSearch() {
    this.dispatchEvent(
      new CustomEvent('open-search', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  private levelSwipedDown(level: number) {
    let href = '/account/' + this.organizationId;
    if (level > 1) {
      let routeParts = 1;
      if (level === 3) {
        routeParts = 3;
      }
      if (level === 4) {
        routeParts = 5;
      }
      if (level === 5) {
        routeParts = 7;
      }
      href += '/' + this.route.slice(0, routeParts).join('/');
    }
    this.dispatchEvent(new CustomEvent('navigate', { bubbles: true, composed: true, detail: { href } }));
    this.dispatchEvent(new CustomEvent('edit-off', { bubbles: true, composed: true }));
  }

  render() {
    const bodyClassMap = {
      'level-0': this.currentLevel >= 0,
      'level-1': this.currentLevel >= 1,
      'level-2': this.currentLevel >= 2,
      'level-3': this.currentLevel >= 3,
      'level-4': this.currentLevel >= 4,
      'level-5': this.currentLevel >= 5,
      'current-level-0': this.currentLevel === 0,
      'current-level-1': this.currentLevel === 1,
      'current-level-2': this.currentLevel === 2,
      'current-level-3': this.currentLevel === 3,
      'current-level-4': this.currentLevel === 4,
      'current-level-5': this.currentLevel === 5,
      max1809: this.appWidth < 1810,
      max1599: this.appWidth < 1600,
      max1279: this.appWidth < 1280,
      max1219: this.appWidth < 1220,
      max1023: this.appWidth < 1024,
      max844: this.appWidth < 845,
      search: this.search,
      settings: this.settings,
      'uncover-menu': this.uncoverMenu,
    };

    let levelsTop = '0px';
    if (this.search) {
      levelsTop = 'calc(var(--top-height) )';
    }
    if (this.settings) {
      levelsTop = 'calc(100vh - (var(--top-height) + 100px))';
    }
    this.style.setProperty('--levels-top', levelsTop);

    return html`
      <div id="body" class="${classMap(bodyClassMap)}">
        <div class="backdrop"></div>
        <header style="${this.headerStyle}">
          <slot name="header"></slot>
        </header>
        <d-level-touch-slider
          class="level"
          id="level-0"
          .enabled=${this.isAppPlatform}
          @click="${this.onLevels}"
          triggerpoint="80"
          trigger-without-release
          @triggered=${() => this.openSearch()}
        >
          <slot name="menu"></slot>
        </d-level-touch-slider>
        <d-level-touch-slider
          class="level"
          id="level-1"
          .enabled=${this.isAppPlatform}
          @click="${this.onLevels}"
          @triggered=${() => this.levelSwipedDown(1)}
        >
          <slot name="level-1-content"></slot>
        </d-level-touch-slider>
        <d-level-touch-slider
          class="level"
          id="level-2"
          .enabled=${this.isAppPlatform}
          @click="${this.onLevels}"
          @triggered=${() => this.levelSwipedDown(2)}
        >
          <slot name="level-2-content"></slot>
        </d-level-touch-slider>
        <d-level-touch-slider
          class="level"
          id="level-3"
          .enabled=${this.isAppPlatform}
          @click="${this.onLevels}"
          @triggered=${() => this.levelSwipedDown(3)}
        >
          <slot name="level-3-content"></slot>
        </d-level-touch-slider>
        <d-level-touch-slider
          class="level"
          id="level-4"
          .enabled=${this.isAppPlatform}
          @click="${this.onLevels}"
          @triggered=${() => this.levelSwipedDown(4)}
        >
          <slot name="level-4-content"></slot>
        </d-level-touch-slider>
        <d-level-touch-slider
          class="level"
          id="level-5"
          .enabled=${this.isAppPlatform}
          @click="${this.onLevels}"
          @triggered=${() => this.levelSwipedDown(5)}
        >
          <slot name="level-5-content"></slot>
        </d-level-touch-slider>
        ${this.settings
          ? nothing
          : html`
              <div id="new-document" style="${this.newDocumentStyle}">
                <slot name="new-document"></slot>
              </div>
            `}
      </div>
    `;
  }

  private level(n: number) {
    this.currentLevel = n;
  }

  private onLevels() {
    this.settings = false;
    this.search = false;
  }

  private onSearch() {
    this.settings = false;
    this.search = !this.search;
  }

  private onSettings() {
    this.search = false;
    this.settings = !this.settings;
  }

  private onHelp() {
    this.dispatchEvent(new CustomEvent('toggle-help', { bubbles: true, composed: true }));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-application-layout': DApplicationLayout;
  }
}
