import { css, html } from 'lit';
import '../../library/elements/d-tooltip.js';
import '../../library/elements/d-label.js';
import './d-risk-gradient.js';
import { customElement, property } from 'lit/decorators.js';
import { LabeledElement } from '../../library/abstracts/labeled-element.js';
import { riskLevelFromRisk } from 'src/models/risk.js';

/**
 *
 * STATUS OK
 */
@customElement('d-view-risk')
export class DViewRisk extends LabeledElement {
  static readonly styles = [
    super.styles[0],
    css`
      d-risk-gradient {
        height: 12px;
      }

      .riskVisualizer table {
        margin-top: 0;
        margin-bottom: -12px;
      }

      .riskVisualizer table td.selected {
        color: black;
        font-weight: bold;
      }

      .riskVisualizer table td {
        cursor: pointer;
        width: 25%;
        text-align: center;
        color: silver;
        padding: 10px 4px 20px 4px;
        min-height: 40px;
        line-height: 15px;
        border: 0;
        font-family: inherit;
        font-size: 100%;
        font-style: inherit;
        font-weight: inherit;
        margin: 0;
        outline: 0;
        vertical-align: top;
      }

      body:not(.touch) .riskVisualizer table td:hover,
      .riskVisualizer table td:active {
        color: black;
      }

      body:not(.touch) :host .riskVisualizer table td:hover,
      :host .riskVisualizer table td:active {
        cursor: default;
        color: silver;
      }

      body:not(.touch) :host .riskVisualizer table td.selected:hover,
      :host .riskVisualizer table td.selected:active {
        color: black;
      }

      .riskVisualizer .measures {
        margin-top: 4px;
        display: none;
      }

      .riskVisualizer td.selected .measures {
        font-weight: normal;
        display: block;
      }
    `,
  ];
  field = 'riskAssessments_calculatedRisk';
  /**
   * The value. The combined risk as score between 1 and 16. For unknown use 0.
   */
  @property({ type: Number })
  value = 0;

  _computeClass(value, level) {
    let _class = 'result' + level;
    _class += riskLevelFromRisk(this.value) === level ? ' selected' : '';
    return _class;
  }

  renderContent() {
    return html`
      <div class="riskVisualizer">
        <d-risk-gradient .value=${this.value}></d-risk-gradient>

        <table border="0" cellpadding="0" cellspacing="0" width="100%" role="presentation">
          <tbody>
            <tr>
              <td class="${this._computeClass(this.value, 1)}">
                Lav
                <div class="measures">Ingen tiltak nød­vendig</div>
              </td>
              <td class="${this._computeClass(this.value, 2)}">
                Mode­rat
                <div class="measures">Tiltak skal vurderes</div>
              </td>
              <td class="${this._computeClass(this.value, 3)}">
                Høy
                <div class="measures">Tiltak bør besluttes</div>
              </td>
              <td class="${this._computeClass(this.value, 4)}">
                Svært høy
                <div class="measures">Tiltak må be­sluttes</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-risk': DViewRisk;
  }
}
