import '../library/elements/d-section.js';
import '../library/elements/d-wrap.js';
import '../library/fields/d-view-text.js';
import '../library/fields/d-inline.js';
import '../library/editors/elements/d-select-dropdown.js';
import '../library/editors/components/d-select-time.js';
import * as dabihStore from 'src/store';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { produce } from 'immer';
import { type LeavePeriodDay, LeavePeriodDayLeaveStatusEnum } from 'src/store/api';
import { defaultWorkEndTime, defaultWorkStartTime, type ScheduleDayData, timeInMinutes } from 'src/store';
import { LocalDate } from 'src/utilities/local-date.js';
import { minutesToTime } from 'src/utilities/text.js';

/**
 * Skal flyttes ut av denne elementen
 *
 *
 */
@customElement('d-graded-leave-day')
export class DGradedLeaveDay extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .date {
      width: 320px;
    }

    .date d-view-text:last-child {
      display: none;
    }

    @media only screen and (max-width: 670px) {
      .date {
        width: 260px;
      }

      .date d-view-text:first-child {
        display: none;
      }
      .date d-view-text:last-child {
        display: flex;
      }
    }

    .hours {
      flex: none;
      width: 50px;
      font-weight: bold;
      text-align: right;
    }
  `;
  /**
   * Oppsummering av data for dagen
   */
  @property({ type: Object })
  day!: LeavePeriodDay;
  @property({ type: Object })
  scheduledDay!: ScheduleDayData;

  @property({ type: Boolean })
  isHoliday = false;

  private typeOptions = [
    { value: LeavePeriodDayLeaveStatusEnum.Work, text: 'Jobb' },
    { value: LeavePeriodDayLeaveStatusEnum.Leave, text: 'Fri' },
  ];
  private defaultStart = dabihStore.defaultWorkStartTime;
  private defaultEnd = dabihStore.defaultWorkEndTime;

  get leaveDay() {
    return this.day.leaveStatus === LeavePeriodDayLeaveStatusEnum.Leave;
  }

  private get scheduledWorkHoursText() {
    let result = '(Fri)';
    if (this.scheduledDay.workHours) {
      result = '(' + this.scheduledDay.start.replace(':', '.') + '-' + this.scheduledDay.end.replace(':', '.') + ')';
    }
    if (this.scheduledDay.holidays && this.isHoliday) {
      result = '(Fri)';
    }
    return result;
  }

  private get displayDate() {
    return LocalDate.fromString(this.day.date).toStringForDisplayWithDayOfWeekAndYear();
  }

  private get displayDateShort() {
    const localDate = LocalDate.fromString(this.day.date);
    return localDate.toStringForDisplayWithDayAndMonth() + ' ' + localDate.year();
  }

  _dayHours() {
    if (this.leaveDay) {
      return 0;
    }
    if (this.day.end === undefined || this.day.start === undefined) {
      return 0;
    }
    const editedMinutes = dabihStore.timeInMinutes(this.day.end) - dabihStore.timeInMinutes(this.day.start);
    return Math.ceil((editedMinutes / 60.0) * 100) / 100;
  }

  _dayHoursText(dayHours) {
    return dayHours.toString().replace('.', ',') + ' t';
  }

  _leaveDayChange(leaveDay) {
    const day = { ...this.day };
    if (leaveDay) {
      day.start = '00:00';
      day.end = '00:00';
    } else if (day.start === '00:00' && day.end === '00:00') {
      day.start = this.defaultStart;
      day.end = this.defaultEnd;
    }
    this.day = day;
  }

  render() {
    const dayHours = this._dayHours();
    return html`
      <d-section light-border>
        <d-wrap split nowrap tight>
          <d-wrap align split tight>
            <d-wrap align class="date">
              <d-view-text wrap-label .label=${this.displayDate} .value=${this.scheduledWorkHoursText}></d-view-text>
              <d-view-text
                wrap-label
                .label=${this.displayDateShort}
                .value=${this.scheduledWorkHoursText}
              ></d-view-text>
            </d-wrap>
            <d-wrap tight>
              <d-select-dropdown
                .options=${this.typeOptions}
                .value=${this.day.leaveStatus}
                @value-changed=${this.onTypeChanged}
              ></d-select-dropdown>
              ${this.renderTime()}
            </d-wrap>
          </d-wrap>
          <d-inline class="hours"> ${this._dayHoursText(dayHours)}</d-inline>
        </d-wrap>
      </d-section>
    `;
  }

  private fireDayChanged() {
    this.dispatchEvent(new CustomEvent('day-changed', { composed: true, bubbles: true, detail: { day: this.day } }));
  }

  private renderTime() {
    if (!this.leaveDay) {
      return html`
        <d-wrap tight>
          <d-select-time
            max="23:50"
            .value=${this.day.start || defaultWorkStartTime}
            @value-changed=${this.onStartChanged}
          ></d-select-time>
          <d-inline tight> -</d-inline>
          <d-select-time
            min="00:05"
            .value=${this.day.end || defaultWorkEndTime}
            @value-changed=${this.onEndChanged}
          ></d-select-time>
        </d-wrap>
      `;
    } else {
      return html`
        <d-wrap tight style="opacity: 0">
          <d-select-time disabled> </d-select-time>
          <d-inline tight> -</d-inline>
          <d-select-time disabled> </d-select-time>
        </d-wrap>
      `;
    }
  }

  private onStartChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    let dayEnd = this.day.end;
    if (this.day.end && timeInMinutes(e.detail.value) >= timeInMinutes(this.day.end)) {
      dayEnd = minutesToTime(timeInMinutes(e.detail.value) + 5);
    }
    this.day = produce(this.day, (draft) => {
      draft.start = e.detail.value;
      draft.end = dayEnd;
    });
    this.fireDayChanged();
  }
  private onEndChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    let dayStart = this.day.start;
    if (this.day.start && timeInMinutes(this.day.start) >= timeInMinutes(e.detail.value)) {
      dayStart = minutesToTime(timeInMinutes(e.detail.value) - 5);
    }
    this.day = produce(this.day, (draft) => {
      draft.end = e.detail.value;
      draft.start = dayStart;
    });
    this.fireDayChanged();
  }

  private onTypeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.day = produce(this.day, (draft) => {
      draft.leaveStatus = e.detail.value as LeavePeriodDayLeaveStatusEnum;
    });
    this.fireDayChanged();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-graded-leave-day': DGradedLeaveDay;
  }
}
