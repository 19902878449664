import { LocalDate } from 'src/utilities/local-date.js';
import { LeavePeriodType } from 'src/pages/staffing-page/d-staffing-calendar-data.js';

/**
 * The types, interfaces and utility functions for lists are defined here so that the view model builders do
 * not depend on lit specific code.
 */

export interface TaskEvent {
  timeOfDayDisplay: string;
  overdue: boolean;
  doneDate?: string;
  time: string;
  sortTime: string;
  uuid: string;
  href: string;
  eventCurrentUser: boolean;
  secondaryLabel: string;
  rightLabel: string;
  rightLabelClass: string;
  notes?: string;
  eventDone?: boolean;
  updateStatus?: string;
  hasDraft: boolean;
  eventName?: string;
}

export function displayTime(item: TaskEvent): string {
  if (item.time) {
    const string = LocalDate.fromString(item.sortTime).toStringForDisplayWithDayOfWeekAndYear();
    return string[0].toUpperCase() + string.slice(1) + ' ' + item.timeOfDayDisplay;
  }
  return 'Snarest';
}

export type UpdateStatus = 'none' | 'suggestion' | 'templateDeleted' | 'templateUpdated' | 'documentUpdated' | 'draft';
export type RightLabelClasses = 'assignee';
export type AssetCheckStatuses = 'NONE' | 'OK' | 'NOT_OK';
export type RiskLevels = 1 | 2 | 3 | 4 | undefined;
export interface ListSectionItemInput {
  writeAccess?: boolean;
  // text content
  label?: string;
  secondaryLabel?: string;
  labelItems?: string[]; // when label includes a list, e.g. employee functions on organization page
  field?: string;
  subfield?: string;
  rightLabel?: string;
  notes?: string;
  // functional
  href?: string;
  target?: string;
  clickHandler?: () => void;
  secondaryClickHandler?: () => void;
  clickData?: string;
  // status

  /**
   * If true the item is available for the current viewer.
   */
  accessible: boolean;
  locked?: boolean;
  updateStatus?: UpdateStatus;
  hasDraft?: boolean;
  // presentation
  preItem?: boolean;
  postItem?: boolean;
  hidden?: boolean;
  rightLabelClass?: RightLabelClasses;
  hideNotes?: boolean;
  sublistToggle?: boolean;
  sublistHidden?: boolean;
  limitItems?: number;
  noItemText?: string;
  classes?: string;
  // function specific
  functionRotated?: boolean;
  // event specific
  eventOverdue?: boolean;
  eventDone?: boolean;
  eventCurrentUser?: boolean;
  meeting?: boolean;
  // asset specific
  assetCheck?: boolean;
  assetLocation?: string;
  assetCheckStatus?: AssetCheckStatuses;
  assetCheckDoneText?: string;
  assetCheckComments?: string;
  // risk assessment specific
  riskAssessmentRisk?: RiskLevels;
  // leave period specific
  leavePeriodType?: LeavePeriodType;
  // timekeeping specific
  timekeepingCorrection?: boolean;
  // sublist
  items?: ListSectionItemInput[];
}
