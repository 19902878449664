import { html, nothing, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import _, { sortBy } from 'lodash';
import type { FormViewItem } from 'src/library/components/d-form-view.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { sortedByName } from 'src/store/utilities.js';
import { LocalDate } from 'src/utilities/local-date.js';
import '../../library/editors/index.js';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import './d-edit-rotation';
import { AbstractEntityViewWithRevisions, contentViewBuilder, EntityContent, toLookup } from '../entity-content.js';
import { DEditRotation, RotationEmployeeWeek } from './d-edit-rotation.js';
import './d-view-rotation-schedule.js';
import type { TaskViewModel } from 'src/store/api';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { calculateUpdateStatus } from 'src/models/resolvers/calculate-update-status.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';

export interface FunctionTask {
  name: string;
  functionUuid: string;
  responsibleFunctionUuid: string;
  locked: boolean;
  uuid: string;
  templateDeleted: string;
  hasTemplateUpdate: boolean;
  templateUpdated: string;
  contentLastModified: string;
  templateDeclined: string;
  hasDraft: boolean;
  href: string;
}

export interface FunctionView extends AbstractEntityViewWithRevisions<FunctionViewEditItem>, UpdateSectionItem {
  pages: string[];
  employees: string[];
  responsibilityLocked: boolean;
  availableEmployees: SelectTagOption[];
  type: 'functions';
  name: string;
  description: string;
  functionType: 'COMMON' | 'ROTATED' | 'SIMULTANEOUS' | 'SINGLE';
  assignedEmployeeNames: string;
  rotation?: {
    count: number;
    type: string;
    employees: { uuid: string; firstName: string; lastName: string }[];
    rotations: RotationEmployeeWeek[];
    selectedEmployees: string[];
  };
  assignedTasks: FunctionTask[];
  responsibleTasks: FunctionTask[];
  availablePages: SelectTagOption[];
  isCommon: boolean;
  isRotated: boolean;
}

export interface FunctionViewEditItem {
  functionType: string;
  rotations: RotationEmployeeWeek[];
  rotationType: string;
  employees: string[];
  description: string;
  pages: string[];
  name: string;
}

/**
 *
 */
@customElement('d-function-view')
export class DFunctionView extends EntityContent<FunctionView, FunctionViewEditItem> {
  static readonly styles = EntityContent.styles;
  private static functionTypeLookup = toLookup({
    SINGLE: 'Fast (én person)',
    ROTATED: 'Roterbar (én om gangen)',
    SIMULTANEOUS: 'Simultan (flere samtidig)',
  });
  private static functionTypeOptions = [
    { value: 'SINGLE', text: 'Fast (én person)' },
    { value: 'ROTATED', text: 'Roterbar (én om gangen)' },
    {
      value: 'SIMULTANEOUS',
      text: 'Simultan (flere samtidig)',
    },
  ];
  @query('#rotations')
  rotations!: DEditRotation;
  @property({ type: Object })
  organization: any = {};
  @property({ type: String })
  entityType = 'functions';
  @property({ type: String })
  employeeId = '';
  @property({ type: Array })
  employees = [];
  @property({ type: Array })
  tasks = [];

  protected get preventDelete(): boolean {
    return this.entityView.isCommon;
  }

  protected get viewItems(): FormViewItem[] {
    const builder = contentViewBuilder();
    builder.addHtmlContent('functions_description', this.entityView.description);
    if (this.entityView.functionType !== 'COMMON') {
      builder.addTextLookup('functions_type', this.entityView.functionType, 'm', DFunctionView.functionTypeLookup);
      builder.addText('functions_employees', this.entityView.assignedEmployeeNames, 'm', true);
    }

    return builder.build();
  }

  _assignedTasks(tasks: TaskViewModel[], functionUuid: string) {
    return _.chain(tasks)
      .filter((item) => item.functionUuid === functionUuid)
      .sortBy((item) => item.locked)
      .reverse()
      .value();
  }

  _responsibleTasks(tasks: TaskViewModel[], functionUuid: string) {
    const filteredTasks = tasks.filter(function (item) {
      return item.responsibleFunctionUuid === functionUuid;
    });
    const sortedTasks = sortBy(filteredTasks, (item) => item.locked);
    return sortedByName(sortedTasks);
  }

  _isEmpty(list) {
    return list.length === 0;
  }

  _computeEmployeeId(route, level3EntityId) {
    if (route === 'employees-functions') {
      return level3EntityId;
    }
    return '';
  }

  _computeAddhref(functionId, organizationId, pageId) {
    if (pageId === 223117) {
      return '';
    } else {
      return '/account/' + organizationId + '/' + pageId + '/functions/' + functionId + '/tasks/new';
    }
  }

  _typeDescription(value) {
    if (!value) {
      return '';
    }
    const m = {
      SINGLE: 'Fast (én person)',
      ROTATED: 'Roterbar (én om gangen)',
      SIMULTANEOUS: 'Simultan (flere samtidig)',
    };
    return m[value];
  }

  _availableEmployeesPREV(employees, currentEmployees) {
    return employees.filter(function (employee) {
      return currentEmployees.indexOf(employee.uuid) !== -1 || employee.status === 'ACTIVE';
    });
  }

  _availableEmployees(employees, currentEmployees) {
    const availableEmployees = employees.filter(function (employee) {
      return currentEmployees.indexOf(employee.uuid) !== -1 || employee.status === 'ACTIVE';
    });

    const employeesList: any[] = [];

    for (const item of availableEmployees) {
      const id = item.uuid;
      const value = item.firstName + ' ' + item.lastName;
      const employee = { id: id, value: value };
      employeesList.push(employee);
    }
    return employeesList;
  }

  renderLists(): TemplateResult<1> {
    return html`
      ${this.entityView.rotation === undefined
        ? nothing
        : html` <d-section>
            <d-view-rotation-schedule
              .count=${this.entityView.rotation.count}
              .employees=${this.entityView.rotation.employees}
              .rotationType=${this.entityView.rotation.type}
              .rotations=${this.entityView.rotation.rotations}
              .selectedEmployees=${this.entityView.rotation.selectedEmployees}
              .today=${LocalDate.fromString(this.today)}
            >
            </d-view-rotation-schedule
          ></d-section>`}

      <d-list-section
        .items=${this.asListItems(this.entityView.assignedTasks, this.entityView.today)}
        page-id="${this.pageId}"
        employee-id="${this.employeeId}"
        field="functions_ownTasks"
        icon="tasks"
        .writeAccess="${this.entityView.currentUserHasWriteAccess}"
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section>

      ${this._isEmpty(this.entityView.responsibleTasks)
        ? nothing
        : html`<d-list-section
            .items=${this.asListItems(this.entityView.responsibleTasks, this.entityView.today)}
            .limitItems=${3}
            page-id="${this.pageId}"
            icon="tasks"
            employee-id="${this.employeeId}"
            field="tasks_responsible"
            .writeAccess="${this.entityView.currentUserHasWriteAccess}"
            .contentStickyTop=${this.contentStickyTop}
          >
          </d-list-section>`}
    `;
  }

  async initializeEditItem() {
    this.editItem = {
      functionType: this.entityView.functionType,
      rotationType: this.entityView.rotation === undefined ? 'R-0' : this.entityView.rotation.type,
      rotations: this.entityView.rotation?.rotations || [],
      description: this.entityView.description,
      employees: this.entityView.employees,
      pages: this.entityView.pages,
      name: this.entityView.name,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderEditNotCommon(item: FunctionViewEditItem) {
    return html`
      <d-section>
        <d-edit-text
          field="functions_name"
          .autofocus=${!this.entityView.isConfirmedEntity}
          .selectOnFocus=${!this.entityView.isConfirmedEntity}
          placeholder="Dette feltet må fylles ut"
          mark-if-empty
          .value=${item.name}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, name: e.detail.value };
          }}
        ></d-edit-text>
      </d-section>

      <d-edit-html
        field="functions_description"
        .value=${item.description}
        .docsForLinking=${this.entityView.docsForLinking ?? []}
        .contentStickyTop=${this.contentStickyTop}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          this.editItem = { ...item, description: e.detail.value };
        }}
      >
      </d-edit-html>
      ${this.entityView.functionType === 'COMMON'
        ? nothing
        : html`
            <d-section>
              <d-select-dropdown
                field="functions_type"
                ?disabled=${this.entityView.responsibilityLocked}
                .options=${DFunctionView.functionTypeOptions}
                .value=${item.functionType}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, functionType: e.detail.value };
                }}
              >
              </d-select-dropdown>
            </d-section>
            <d-section>
              <d-select-tag
                field="functions_employees"
                ?single=${item.functionType === 'SINGLE'}
                .options=${this.entityView.availableEmployees}
                .value=${item.employees}
                @value-changed=${(e: CustomEvent<{ value: string[] }>) => {
                  this.editItem = { ...item, employees: e.detail.value };
                }}
              >
              </d-select-tag>
            </d-section>
          `}
      ${item.functionType === 'ROTATED' && item.employees.length > 1
        ? html` <d-section>
            <d-edit-rotation
              .employees=${this.entityView.availableEmployees}
              id="rotations"
              .rotationType=${item.rotationType}
              .rotations=${item.rotations}
              .selectedEmployees=${this.entityView.availableEmployees
                .filter((e) => item.employees.includes(e.value))
                .map((e) => ({ uuid: e.value, name: e.text }))}
              @rotation-changed=${(e: CustomEvent<{ rotationType: string; rotations: RotationEmployeeWeek[] }>) => {
                this.editItem = { ...item, rotationType: e.detail.rotationType, rotations: e.detail.rotations };
              }}
            >
            </d-edit-rotation>
          </d-section>`
        : nothing}
    `;
  }

  /**
   * Common function can only edit description
   */
  renderEditCommon(item: FunctionViewEditItem) {
    return html`
      <d-section>
        <d-edit-html
          field="functions_description"
          .value=${item.description}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, description: e.detail.value };
          }}
        >
        </d-edit-html>
      </d-section>
    `;
  }

  renderEditItem(item: FunctionViewEditItem): TemplateResult<1> {
    return this.entityView.isCommon ? this.renderEditCommon(item) : this.renderEditNotCommon(item);
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }

  private asListItems(list: FunctionTask[], today: LocalDate): ListSectionItemInput[] {
    return list.map((v) => ({
      href: v.href,
      label: v.name,
      locked: v.locked,
      accessible: true,
      updateStatus: calculateUpdateStatus(v, today),
      hasDraft: v.hasDraft,
    }));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-function-view': DFunctionView;
  }
}
