import activityCodesAvailableList from '../data/activity-codes-available.json';
import activityCodesCommonList from '../data/activity-codes-common.json';
import ghsLabelsList from '../data/ghs-labels.json';
import suggestedDocumentsList from '../data/suggested-documents.json';
import { createSelector } from '@reduxjs/toolkit';
import type {
  ConstitutionalDocumentViewModel,
  ContactPersonViewModel,
  ContractViewModel,
  EmployeeViewModel,
  FieldViewModel,
  FunctionViewModel,
  IssueViewModel,
  PartnerViewModel,
  ReferenceViewModel,
  SubstanceViewModel,
} from '../api';
import { _filterByPartnerId, _formatDate, toList } from './utilities.js';
import { _filterByEmployeeId, currentEmployeeUuid, employeeNamesById } from './organization-employees.js';
import { partnerNamesById } from './organization-partners.js';
import { getContracts, toContractViewModel } from './organization-documents.js';
import { toFunctionViewModel } from './organization-functions.js';
import { getOrganization } from './organization.js';
import { isIssueProcessed } from './organization-issues.js';

import { splitPath } from './path.js';
import { EmployeeViewModelWithName, ItemsByTypeAndId, OrganizationState, State } from '../types.js';
import type { ActivityCodeCommon } from 'src/store/selectors/variants.js';

export function activityCodesAvailable() {
  return activityCodesAvailableList;
}

export function activityCodesCommon(): ActivityCodeCommon[] {
  return activityCodesCommonList;
}

export function ghsLabels() {
  return ghsLabelsList;
}

export function suggestedRiskAssessments() {
  return suggestedDocumentsList.riskAssessments;
}

export interface SuggestedRiskAssessment {
  pageId: number;
  date: string;
  name: string;
  helpContent: string;
  description: string;
  pages: number[];
  condition: { context: string; expression: string };
  mainServiceType: string;
  deleted: string;
  mainVersionPageId: number;
  hazardLabel?: string;
}

export interface HazardLabel {
  label: string;
  pageId: number;
}

export function hazardLabels(): HazardLabel[] {
  return [
    { label: 'GHS01', pageId: 12616 },
    { label: 'GHS02', pageId: 12621 },
    { label: 'GHS03', pageId: 12624 },
    { label: 'GHS04', pageId: 12626 },
    { label: 'GHS05', pageId: 12629 },
    { label: 'GHS06', pageId: 12636 },
    { label: 'GHS07', pageId: 12638 },
    { label: 'GHS08', pageId: 12640 },
    { label: 'GHS09', pageId: 12642 },
  ];
}

export const defaultHelpForSubstancesRiskAssessment = createSelector(
  suggestedRiskAssessments,
  function (suggestedRiskAssessments: SuggestedRiskAssessment[]) {
    return suggestedRiskAssessments.filter(function (item) {
      return item.pageId === 12583;
    })[0].helpContent;
  },
);

export const suggestedRiskAssessmentsForSubstances: SuggestedRiskAssessment[] = hazardLabels()
  .map((label) => {
    const riskAssessment = suggestedRiskAssessments().filter(
      (item: SuggestedRiskAssessment) => item.pageId === label.pageId,
    )[0];
    return { r: riskAssessment, hazardLabel: label.label };
  })
  .filter((x) => x.r !== undefined)
  .map((x) => ({ ...x.r, hazardLabel: x.hazardLabel }));

export const contractsForCurrentUser = createSelector(getContracts, currentEmployeeUuid, (contracts, uuid) =>
  _filterByEmployeeId(contracts, uuid),
);
export const itemsByTypeAndId = createSelector(getOrganization, function (m) {
  if (m === undefined) return undefined;
  const result: ItemsByTypeAndId = {
    functions: m.functionsById,
    assets: m.assetsById,
    substances: m.substancesById,
    issues: m.issuesById,
    partners: m.partnersById,
    constitutionalDocuments: m.constitutionalDocumentsById,
    computers: m.computersById,
    contracts: m.contractsById,
    guidelines: m.guidelinesById,
    networks: m.networksById,
    documents: m.documentsById,
    employees: m.employeesById,
    externalConnections: m.externalConnectionsById,
    riskAssessments: m.riskAssessmentsById,
    reports: m.reportsById,
    contacts: m.contactsById,
    tasks: m.tasksById,
    meetings: m.meetingsById,
    personalDataItems: m.personalDataItemsById,
  };
  return result;
});

export const menuItemsByType = createSelector(
  getOrganization,
  employeeNamesById,
  partnerNamesById,
  function (m, employees, partners) {
    if (m === undefined) return undefined;
    const f = function <T>(e: T): T {
      return e;
    };

    const withIssueStatus = function (
      e: IssueViewModel,
    ): IssueViewModel & { reportedDateFormatted: string; status: string } {
      return Object.assign({}, e, {
        reportedDateFormatted: _formatDate(e.reportedDate?.toString()),
        status: isIssueProcessed(e) ? 'ferdigbehandlet' : 'aktiv',
      });
    };

    const withParties = function (
      e: ContractViewModel | ConstitutionalDocumentViewModel,
    ): ContractViewModel | (ConstitutionalDocumentViewModel & { party: string }) {
      const employeeNames: string[] =
        e.employees === undefined
          ? []
          : e.employees.map((id) => {
              return employees[id];
            });
      employeeNames.sort();
      const partnerNames: string[] =
        e.partners === undefined
          ? []
          : e.partners.map((id) => {
              return partners[id];
            });
      partnerNames.sort();

      const names = [...employeeNames, ...partnerNames];
      return Object.assign({}, e, { party: names.join(', ') });
    };

    const withContractSubmenusForEmployee = function (e: EmployeeViewModel): EmployeeViewModel {
      return Object.assign({}, e, {
        contracts: toList(m.contractsById)
          .filter(function (c) {
            return !c.deleted;
          })
          .filter(function (c) {
            return c.employees !== undefined && c.employees.includes(e.uuid);
          })
          .map((x) => withParties(x))
          .map(f),
      });
    };
    const withContractSubmenusForPartner = function (e: PartnerViewModel): PartnerViewModel {
      return Object.assign({}, e, {
        contracts: toList(m.contractsById)
          .filter(function (c) {
            return !c.deleted;
          })
          .filter(function (c) {
            return c.partners !== undefined && c.partners.includes(e.uuid);
          })
          .map(withParties)
          .map(f),
      });
    };
    const withContactSubmenusForPartner = function (e: PartnerViewModel): PartnerViewModel {
      return Object.assign({}, e, {
        contacts: toList(m.contactsById)
          .filter(function (c) {
            return !c.deleted;
          })
          .filter(function (c) {
            return c.partnerUuid === e.uuid;
          })
          .map(f),
      });
    };
    const withTaskSubmenusForFunction = function (e: FunctionViewModel): FunctionViewModel {
      return Object.assign({}, e, {
        tasks: toList(m.tasksById)
          .filter(function (c) {
            return !c.deleted;
          })
          .filter(function (c) {
            return c.functionUuid === e.uuid;
          })
          .map(f),
      });
    };

    return {
      functions: toList(m.functionsById).map(withTaskSubmenusForFunction).map(f),
      assets: toList(m.assetsById).map(f),
      substances: toList(m.substancesById).map(f),
      issues: toList(m.issuesById).map(withIssueStatus).map(f),
      partners: toList(m.partnersById).map(withContactSubmenusForPartner).map(withContractSubmenusForPartner).map(f),
      constitutionalDocuments: toList(m.constitutionalDocumentsById).map(withParties).map(f),
      computers: toList(m.computersById).map(f),
      contracts: toList(m.contractsById).map(withParties).map(f),
      guidelines: toList(m.guidelinesById).map(f),
      networks: toList(m.networksById).map(f),
      documents: toList(m.documentsById).map(f),
      employees: toList(m.employeesById).map(withContractSubmenusForEmployee).map(f),
      externalConnections: toList(m.externalConnectionsById).map(f),
      riskAssessments: toList(m.riskAssessmentsById).map(f),
      reports: toList(m.reportsById).map(f),
      contacts: toList(m.contactsById).map(f),
      tasks: toList(m.tasksById).map(f),
    };
  },
);

export function optionsFromFields(
  fieldsByCode: { [key: string]: FieldViewModel },
  entity: string,
  fields: string[],
): { id: string; value: string }[] {
  const options: { id: string; value: string }[] = [];
  for (const item of fields) {
    options.push({
      id: item,
      value: fieldsByCode[entity + '_' + item].label,
    });
  }
  return options;
}
export function toEmployeeViewModel(
  item: EmployeeViewModelWithName,
  o: OrganizationState,
): EmployeeViewModelWithName & {
  assignedFunctions: (FunctionViewModel & { assignedEmployeeNames: string })[];
  unassignedFunctions: (FunctionViewModel & { assignedEmployeeNames: string })[];
  contracts: ContractViewModel[];
} {
  return Object.assign({}, item, {
    assignedFunctions: toList(o.functionsById)
      .filter(function (f) {
        return !f.deleted;
      })
      .filter(function (f) {
        return f.employees !== undefined && f.employees.includes(item.uuid);
      })
      .map(function (f) {
        return toFunctionViewModel(f, o);
      }),
    unassignedFunctions: toList(o.functionsById)
      .filter(function (f) {
        return !f.deleted;
      })
      .filter(function (f) {
        return !(f.employees !== undefined && f.employees.includes(item.uuid));
      })
      .map(function (f) {
        return toFunctionViewModel(f, o);
      }),
    contracts: _filterByEmployeeId(
      toList(o.contractsById).filter(function (f) {
        return !f.deleted;
      }),
      item.uuid,
    ).map(function (c) {
      return toContractViewModel(c, o);
    }),
  });
}

export function toPartnerViewModel(
  item: PartnerViewModel,
  o: OrganizationState,
): PartnerViewModel & {
  contacts: ContactPersonViewModel[];
  contracts: ContractViewModel[];
} {
  return Object.assign({}, item, {
    contacts: toList(o.contactsById)
      .filter((f) => !f.deleted)
      .filter((f) => f.partnerUuid === item.uuid),
    contracts: _filterByPartnerId(
      toList(o.contractsById).filter((f) => !f.deleted),
      item.uuid,
    ).map((c) => toContractViewModel(c, o)),
  });
}

export function toContactPersonViewModel(item: ContactPersonViewModel): ContactPersonViewModel {
  return Object.assign({}, item);
}

export function toSubstanceViewModel(item: SubstanceViewModel): SubstanceViewModel {
  return Object.assign({}, item);
}
export function loaded(state: State): boolean {
  return state.loaded;
}

export function key(state: State): string {
  return state.key;
}

export function references(state: State): ReferenceViewModel[] {
  return state.references;
}

export const associationTypes = [
  'Ikke-ansatt deltaker',
  'Eier og ansatt',
  'Ansatt',
  'Vikar',
  'Student',
  'Leietaker',
  'Oppdragstaker',
];

export const level3EntityId = createSelector(splitPath, function (a) {
  if (a.length < 6) return undefined;
  const element = a[5];
  return element.split(':')[0];
});

export const level4EntityId = createSelector(splitPath, function (a) {
  if (a.length < 8) return undefined;
  const element = a[7];
  return element.split(':')[0];
});

export const level5EntityId = createSelector(splitPath, function (a) {
  if (a.length < 10) return undefined;
  const element = a[9];
  return element.split(':')[0];
});

export function filterByActivityCodes(description: string, activityCodes: string[]): string {
  if (description) {
    const html = description;
    const org = activityCodes;

    const descriptionClassReplacer = (match: string, classes: string): string => {
      let filter = false;
      if (
        (classes.includes('fysioterapi') && !org.includes('86.902')) ||
        (classes.includes('kiropraktikk') && !org.includes('86.909.02')) ||
        (classes.includes('lege') && !org.includes('86.21')) ||
        (classes.includes('psykologi') && !org.includes('86.905')) ||
        (classes.includes('tannhelsetjeneste') && !org.includes('86.230'))
      ) {
        filter = true;
      }
      if (filter) {
        return '';
      } else {
        return match;
      }
    };

    const filtered = html
      .replace(/<span\s.*?(?=class=")class="(.*?(?="))".*?(?=<\/span>)<\/span>/g, descriptionClassReplacer)
      .replace(/<div\s.*?(?=class=")class="(.*?(?="))".*?(?=<\/div>)<\/div>/g, descriptionClassReplacer)
      .replace(/<p\s.*?(?=class=")class="(.*?(?="))".*?(?=<\/p>)<\/p>/g, descriptionClassReplacer)
      .replace(/<h1\s.*?(?=class=")class="(.*?(?="))".*?(?=<\/h1>)<\/h1>/g, descriptionClassReplacer)
      .replace(/<h2\s.*?(?=class=")class="(.*?(?="))".*?(?=<\/h2>)<\/h2>/g, descriptionClassReplacer)
      .replace(/<h3\s.*?(?=class=")class="(.*?(?="))".*?(?=<\/h3>)<\/h3>/g, descriptionClassReplacer)
      .replace(/<ul\s.*?(?=class=")class="(.*?(?="))".*?(?=<\/ul>)<\/ul>/g, descriptionClassReplacer)
      .replace(/<ol\s.*?(?=class=")class="(.*?(?="))".*?(?=<\/ol>)<\/ol>/g, descriptionClassReplacer)
      .replace(/<li\s.*?(?=class=")class="(.*?(?="))".*?(?=<\/li>)<\/li>/g, descriptionClassReplacer)
      .replace(/<[a-zA-Z0-9\s\-_="]*>[\s\n]*<\/[a-zA-Z0-9\s\-_="]*>/g, '');

    if (!filtered.replace(/\s/g, '').length) {
      return '';
    } else {
      return filtered;
    }
  } else {
    return '';
  }
}

export const popupOpen = (state: State): boolean => state.popupOpen;

export const currentEditLevel = (state: State): number => state.currentEditLevel;

export const helpViewerOpen = (state: State): boolean => state.helpViewerOpen;

export const currentHelpPage = (state: State): string => state.currentHelpPage;
