/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SearchResultHit
 */
export interface SearchResultHit {
  /**
   *
   * @type {string}
   * @memberof SearchResultHit
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SearchResultHit
   */
  entity?: string;
  /**
   *
   * @type {string}
   * @memberof SearchResultHit
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchResultHit
   */
  highlights?: Array<string>;
}

/**
 * Check if a given object implements the SearchResultHit interface.
 */
export function instanceOfSearchResultHit(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SearchResultHitFromJSON(json: any): SearchResultHit {
  return SearchResultHitFromJSONTyped(json, false);
}

export function SearchResultHitFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResultHit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    entity: !exists(json, 'entity') ? undefined : json['entity'],
    description: !exists(json, 'description') ? undefined : json['description'],
    highlights: !exists(json, 'highlights') ? undefined : json['highlights'],
  };
}

export function SearchResultHitToJSON(value?: SearchResultHit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    entity: value.entity,
    description: value.description,
    highlights: value.highlights,
  };
}
