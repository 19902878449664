import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../library/elements/d-menu.js';
import '../../../../library/editors/elements/d-select-checkbox.js';
import '../d-infosec-view-header.js';
import '../../../../library/elements/d-wrap.js';
import '../../../../library/elements/d-step-menu.js';
import './d-data-items.js';
import '../../../../library/editors/components/d-select-add.js';
import './d-edit-computers-network.js';
import type { InfosecDataItem } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { backupFrequencyOptions, noBackupReasonOptions } from 'src/pages/computers-page/infosec-procedure/defaults.js';

export interface BackupChange {
  uuid: string;
  backupFrequency: string;
  backupResponsible: string;
  noBackupReason: string;
}

/**
 *
 */

@customElement('d-edit-backup')
export class DEditBackup extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }
    `,
  ];

  @property({ type: Object })
  dataItem!: InfosecDataItem;
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: String })
  organizationName = '';

  private static get frequencyOptions() {
    return [
      {
        value: 'never',
        text: 'Aldri',
      },
      {
        value: 'weeklyOrLess',
        text: 'Ukentlig eller sjeldnere',
      },
      {
        value: 'lessThanDaily',
        text: 'Flere ganger i uken',
      },
      {
        value: 'daily',
        text: 'Daglig',
      },
      {
        value: 'moreThanDaily',
        text: 'Flere ganger om dagen',
      },
      {
        value: 'continuously',
        text: 'Kontinuerlig',
      },
    ];
  }

  private get responsibleOptions() {
    return [
      {
        value: 'internal',
        text: this.organizationName,
      },
      ...this.partners,
    ];
  }

  _frequencyChanged(value) {
    this.dataItem = {
      ...this.dataItem,
      backupFrequency: value,
    };
    this._dispatchDataItemChanged();
    this.dispatchEvent(
      new CustomEvent<BackupChange>('backup-change', {
        composed: true,
        bubbles: true,
        detail: {
          uuid: this.dataItem.uuid,
          backupFrequency: this.dataItem.backupFrequency,
          backupResponsible: this.dataItem.backupResponsible,
          noBackupReason: this.dataItem.noBackupReason,
        },
      }),
    );
  }

  _responsibleChanged(value) {
    this.dataItem = {
      ...this.dataItem,
      backupResponsible: value,
    };
    this._dispatchDataItemChanged();
    this.dispatchEvent(
      new CustomEvent<BackupChange>('backup-change', {
        composed: true,
        bubbles: true,
        detail: {
          uuid: this.dataItem.uuid,
          backupFrequency: this.dataItem.backupFrequency,
          backupResponsible: this.dataItem.backupResponsible,
          noBackupReason: this.dataItem.noBackupReason,
        },
      }),
    );
  }

  _noBackupReasonChanged(value) {
    this.dataItem = {
      ...this.dataItem,
      noBackupReason: value,
    };
    this._dispatchDataItemChanged();
    this.dispatchEvent(
      new CustomEvent<BackupChange>('backup-change', {
        composed: true,
        bubbles: true,
        detail: {
          uuid: this.dataItem.uuid,
          backupFrequency: this.dataItem.backupFrequency,
          backupResponsible: this.dataItem.backupResponsible,
          noBackupReason: this.dataItem.noBackupReason,
        },
      }),
    );
  }

  _dispatchDataItemChanged() {
    this.dispatchEvent(
      new CustomEvent('data-item-changed', {
        bubbles: true,
        composed: true,
        detail: this.dataItem,
      }),
    );
  }

  render() {
    return html`
      <d-wrap>
        <d-select-dropdown
          theme-page
          small-label
          semibold-label
          label="Hvor ofte"
          placeholder="Velg frekvens"
          .options=${backupFrequencyOptions}
          .value=${this.dataItem.backupFrequency}
          @value-changed=${(e) => this._frequencyChanged(e.detail.value)}
        ></d-select-dropdown>
        ${this.dataItem.backupFrequency !== '' && this.dataItem.backupFrequency !== 'never'
          ? html`
              <d-select-dropdown
                theme-page
                small-label
                semibold-label
                label="Av hvem"
                placeholder="Velg ansvarlig"
                .options=${this.responsibleOptions}
                .value=${this.dataItem.backupResponsible}
                @value-changed=${(e) => this._responsibleChanged(e.detail.value)}
              ></d-select-dropdown>
            `
          : nothing}
        ${this.dataItem.backupFrequency === 'never'
          ? html`
              <d-select-dropdown
                theme-page
                small-label
                semibold-label
                label="Hvorfor ikke?"
                placeholder="Velg begrunnelse"
                .options=${noBackupReasonOptions}
                .value=${this.dataItem.noBackupReason}
                @value-changed=${(e) => this._noBackupReasonChanged(e.detail.value)}
              ></d-select-dropdown>
            `
          : nothing}
      </d-wrap>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-backup': DEditBackup;
  }
}
