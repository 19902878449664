/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RequestSignaturesCommand
 */
export interface RequestSignaturesCommand {
  /**
   *
   * @type {string}
   * @memberof RequestSignaturesCommand
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof RequestSignaturesCommand
   */
  entityType?: string;
  /**
   *
   * @type {string}
   * @memberof RequestSignaturesCommand
   */
  entityUuid?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof RequestSignaturesCommand
   */
  employees?: Array<string>;
}

/**
 * Check if a given object implements the RequestSignaturesCommand interface.
 */
export function instanceOfRequestSignaturesCommand(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function RequestSignaturesCommandFromJSON(json: any): RequestSignaturesCommand {
  return RequestSignaturesCommandFromJSONTyped(json, false);
}

export function RequestSignaturesCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RequestSignaturesCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: !exists(json, 'message') ? undefined : json['message'],
    entityType: !exists(json, 'entityType') ? undefined : json['entityType'],
    entityUuid: !exists(json, 'entityUuid') ? undefined : json['entityUuid'],
    employees: !exists(json, 'employees') ? undefined : json['employees'],
  };
}

export function RequestSignaturesCommandToJSON(value?: RequestSignaturesCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
    entityType: value.entityType,
    entityUuid: value.entityUuid,
    employees: value.employees,
  };
}
