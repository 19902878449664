import * as dabihStore from 'src/store';
import { LabeledElement } from '../../library/abstracts/labeled-element.js';
import { customElement, property } from 'lit/decorators.js';
import { css } from 'lit';
import { html } from 'lit-html';

/**
 *
 * STATUS OK
 */
@customElement('d-ghs-hazard-label')
export class DGhsHazardLabel extends LabeledElement {
  static readonly styles = [
    super.styles[0],
    css`
      .ghsLabel {
        width: 100px;
        padding-top: 104px;
        background-size: 95px 95px;
        background-position: 50% 0;
        background-repeat: no-repeat;
        text-align: center;
        hyphens: auto;
      }

      @media only screen and (max-width: 600px) {
        .ghsLabel {
          width: 95px;
          font-size: 14px;
        }
      }

      .maxWidth600 .ghsLabel {
        width: 95px;
        font-size: 14px;
      }

      .GHS01 {
        background-image: url(/images/ghs/ghs-01-explosive.svg);
      }

      .GHS02 {
        background-image: url(/images/ghs/ghs-02-flammable.svg);
      }

      .GHS03 {
        background-image: url(/images/ghs/ghs-03-oxidizing.svg);
      }

      .GHS04 {
        background-image: url(/images/ghs/ghs-04-compressed-gas.svg);
      }

      .GHS05 {
        background-image: url(/images/ghs/ghs-05-corrosive.svg);
      }

      .GHS06 {
        background-image: url(/images/ghs/ghs-06-toxic.svg);
      }

      .GHS07 {
        background-image: url(/images/ghs/ghs-07-harmful.svg);
      }

      .GHS08 {
        background-image: url(/images/ghs/ghs-08-health-hazard.svg);
      }

      .GHS09 {
        background-image: url(/images/ghs/ghs-09-enviromental-hazard.svg);
      }
    `,
  ];
  @property({ type: String })
  type = '';
  private ghsLabels = dabihStore.ghsLabels();

  _class(type) {
    return 'ghsLabel ' + type;
  }

  _name(type) {
    return this.ghsLabels.filter((item) => type === item.type)[0].name;
  }

  renderContent() {
    return html`
      <div class="${this._class(this.type)}">
        <div class="hazardName">${this._name(this.type)}</div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-ghs-hazard-label': DGhsHazardLabel;
  }
}
