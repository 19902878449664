import type { State } from 'src/store/types.js';
import type { TrashList, TrashPageViewModel } from 'src/pages/trash-page/trash-page-view-model.js';
import * as dabihStore from 'src/store';
import { displayName } from 'src/utilities/text';
import { AbstractPageView } from 'src/pages/abstract-page-view';

function addTrashList(
  lists: TrashList[],
  items: {
    uuid: string;
    name?: string;
    accessControl?: string[];
    classification?: string;
    taskCount?: number;
    uploadStatus?: string;
  }[],
  icon: string,
  field: string,
  type: string,
) {
  if (items.length > 0) {
    lists.push({
      icon,
      field,
      type,
      items: items.map((item) => {
        let extraInfo = '';
        if (type === 'substances') {
          extraInfo = item.uploadStatus === 'UNREADABLE' ? 'Ikke lesbart' : '';
        } else if (item.taskCount !== undefined) {
          extraInfo = `(${item.taskCount} oppgaver)`;
        }

        return {
          uuid: item.uuid,
          name: displayName(item.name),
          accessControl: item.accessControl ?? [],
          classification: item.classification ?? 'NONE',
          extraInfo: extraInfo,
        };
      }),
    });
  }
}

/**
 * Lists the deleted entities.
 * Computer related items are not included since they are not shown.
 *
 * @param viewModel
 * @param state
 */
export function trashPageView(hrefPrefix: string, viewModel: AbstractPageView, state: State): TrashPageViewModel {
  const lists: TrashList[] = [];

  addTrashList(
    lists,
    dabihStore.constitutionalDocumentsDeleted(state),
    'constitutionalDocuments',
    'organization_constitutionalDocuments',
    'constitutionalDocuments',
  );
  addTrashList(lists, dabihStore.employeesDeleted(state), 'employees', 'organization_employees', 'employees');
  addTrashList(lists, dabihStore.partnersDeleted(state), 'partners', 'organization_partners', 'partners');
  addTrashList(lists, dabihStore.contactsDeleted(state), 'employees', 'organization_contacts', 'contacts');
  addTrashList(
    lists,
    dabihStore.functionsDeletedWithTaskCount(state),
    'functions',
    'organization_functions',
    'functions',
  );
  addTrashList(lists, dabihStore.tasksDeleted(state), 'tasks', 'organization_tasks', 'tasks');
  addTrashList(lists, dabihStore.meetingsDeleted(state), 'meetings', 'organization_meetings', 'meetings');
  addTrashList(lists, dabihStore.documentsDeleted(state), 'documents', 'organization_documents', 'documents');
  addTrashList(lists, dabihStore.guidelinesDeleted(state), 'guidelines', 'organization_guidelines', 'guidelines');
  addTrashList(lists, dabihStore.reportsDeleted(state), 'reports', 'organization_reports', 'reports');
  addTrashList(lists, dabihStore.contractsDeleted(state), 'contracts', 'organization_contracts', 'contracts');
  addTrashList(lists, dabihStore.assetsDeleted(state), 'assets', 'organization_assets', 'assets');
  addTrashList(lists, dabihStore.substancesDeleted(state), 'substances', 'organization_substances', 'substances');
  addTrashList(lists, dabihStore.issuesDeleted(state), 'issues', 'organization_issues', 'issues');
  addTrashList(
    lists,
    dabihStore.riskAssessmentsDeleted(state),
    'riskAssessments',
    'organization_riskAssessments',
    'riskAssessments',
  );

  return {
    ...viewModel,
    type: 'trash-page',
    icon: 'trash',
    href: hrefPrefix,
    lists: lists,
    organization: dabihStore.getOrganization(state),
  };
}
