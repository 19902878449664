import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import type { ComputersPageViewModel } from './computers-page-view-model.js';
import './d-computers-page-content-inner.js';
import { features, promo } from 'src/store/selectors/features';

/**
 *
 * Displays the computers page. The page may have a query parameter `tab` that sets the initial value for the main tab component.
 *
 */
@customElement('d-computers-page-content')
export class DComputersPageContent extends PageContent<ComputersPageViewModel> {
  renderContent() {
    if (!this.pageView.featureStates.core) {
      const promoData = promo.find((p) => {
        return p.id === 'data';
      });
      if (promoData) {
        return html` <d-promo-section
          theme-page
          .employeesCount=${this.pageView.employeesCount}
          .specialTerms=${this.pageView.specialTerms}
          .promoData=${promoData}
          .features=${features}
        ></d-promo-section>`;
      }
      return nothing;
    }
    return html`
      <d-computers-page-content-inner .contentStickyTop=${this.contentStickyTop} .pageView=${this.pageView}>
      </d-computers-page-content-inner>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-computers-page-content': DComputersPageContent;
  }
}
