import { css, html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../elements/d-label.js';
import '../elements/d-select-dropdown.js';
import '../elements/d-add-remove-buttons.js';
import './d-select-dropdown-or-add.js';
import { BaseListWithAddAndRemove } from 'src/library/editors/components/base-list-with-add-and-remove.js';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

/**
 *
 * Given a list of options and an array of selected options, displays the selected options as disabled select items.
 * If the list is empty or if the user clicks add item, then a select dropdown is displayed allowing the user to select
 * from the remaining unselected options.
 *
 * The user may also add a new item. This is presented as the last option of the dropdown or as single possililty if no
 * options are available.
 *
 * @fires item-removed
 *
 *    The value event is passed through from the underlying dropdown
 *
 */
@customElement('d-list-dropdowns-or-add')
export class DListDropdownsOrAdd extends BaseListWithAddAndRemove<SelectDropdownOption> {
  static readonly styles = [
    ...BaseListWithAddAndRemove.styles,
    css`
      d-select-dropdown-or-add {
        flex: 1;
      }
    `,
  ];
  /**
   * The placeholder used for new items
   */
  @property({ type: String })
  placeholder = '';
  /**
   * The text used if no options are available.
   */
  @property({ type: String, attribute: 'first-item-text' })
  firstItemText = '';
  /**
   * The prompt used in the dropdown to add additional items.
   */
  @property({ type: String, attribute: 'additional-item-text' })
  additionalItemText = '';
  @property({ type: Boolean })
  unselectablePlaceholder = false;
  @property({ type: Boolean })
  disabled = false;
  @property({ type: Array })
  options: SelectDropdownOption[] = [];
  @property({ type: Boolean, reflect: true })
  controller = false;
  @property({ type: Boolean, reflect: true })
  toolbar = false;
  @property({ type: Boolean, attribute: 'disabled-as-view-text', reflect: true })
  disabledAsViewText = false;

  private get availableOptions() {
    const selectedValues = this.value.map((v) => {
      return v.value;
    });
    return this.options.filter((o) => {
      return !selectedValues.includes(o.value);
    });
  }

  renderExistingItem(item: SelectDropdownOption): TemplateResult<1> {
    return html`<d-select-dropdown-or-add
      ?theme-page=${this.themePage}
      .label=${this.label}
      .placeholder=${this.placeholder}
      ?unselectablePlaceholder=${this.unselectablePlaceholder}
      firstItemText=${this.firstItemText}
      additionalItemText=${this.additionalItemText}
      disabled
      .disabledAsViewText=${this.disabledAsViewText}
      .options=${[item]}
      .value=${item.value}
    ></d-select-dropdown-or-add>`;
  }

  renderNewItem(): TemplateResult<1> {
    return html`<d-select-dropdown-or-add
      ?theme-page=${this.themePage}
      .label=${this.label}
      .placeholder=${this.placeholder}
      ?unselectablePlaceholder=${this.unselectablePlaceholder}
      firstItemText=${this.firstItemText}
      additionalItemText=${this.additionalItemText}
      .options=${this.availableOptions}
      .value=${this.newItemValue}
      @value-changed=${(e) => {
        e.stopPropagation();
        this.selectItem(e.detail.value);
      }}
    ></d-select-dropdown-or-add>`;
  }

  private selectItem(uuid: string) {
    if (uuid !== 'NEW') {
      const selectDropdownOption = this.options.find((o) => o.value === uuid);
      if (selectDropdownOption !== undefined) {
        this.value = [...this.value, selectDropdownOption];
        this.dispatchEvent(
          new CustomEvent<{ value: string }>('item-selected', {
            bubbles: true,
            composed: true,
            detail: { value: uuid },
          }),
        );
      }
    } else {
      this.dispatchEvent(
        new CustomEvent<{ value: string }>('item-selected', {
          bubbles: true,
          composed: true,
          detail: { value: uuid },
        }),
      );
    }
    this.addingItem = false;
    if (this.alwaysAddSelect) {
      setTimeout(() => {
        this.addingItem = true;
      }, 0);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-dropdowns-or-add': DListDropdownsOrAdd;
  }
}
