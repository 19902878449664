import { css, html, LitElement, nothing, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/editors/elements/d-select-tag.js';

import '../../library/elements/d-label.js';
import '../../library/elements/d-wrap.js';

/**
 * Assigns events to either employees, responsibility or contact persons
 *
 * STATUS OK
 */
@customElement('d-edit-assignee')
export class DEditAssignee extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    d-label {
      display: block;
      padding: 0px 10px 6px 0px;
    }

    .type {
      display: flex;
      margin-bottom: 6px;
    }

    .type > div {
      display: flex;
      align-items: center;
      border: 1px solid hsla(0, 0%, 0%, 0.1);
      margin-bottom: 4px;
      background: hsla(0, 0%, 0%, 0.05);
      padding: 8px 10px 8px 10px;
      color: hsla(0, 0%, 0%, 0.5);
      font-family: var(--small), sans-serif;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
    }

    .type > div:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-right: none;
    }

    .type > div:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-left: none;
    }

    .type > div:not(:first-child) {
      margin-left: -1px;
    }

    .type > div[selected] {
      background-color: var(--themeColor);
      border-color: var(--themeColor);
      color: white;
    }
  `;

  @property({ type: Array })
  employees: SelectTagOption[] = [];
  @property({ type: Array })
  functions: SelectDropdownOption[] = [];
  @property({ type: Array })
  contacts: SelectTagOption[] = [];
  @property({ type: Array })
  assignedToEmployees: string[] = [];
  @property({ type: String })
  assignedToFunction = '';
  @property({ type: Array })
  assignedToContacts: string[] = [];
  @property({ type: String })
  defaultEmployee = '';
  @property({ type: String })
  defaultFunction = '';
  @property({ type: String })
  defaultContact = '';
  @property({ type: Array })
  disabledEmployees = [];
  @property({ type: String })
  currentUserUuid = '';
  @property({ type: String, attribute: 'add-new-employee-text' })
  addNewEmployeeText = '';
  @property({ type: String, attribute: 'add-new-contact-text' })
  addNewContactText = '';
  @property({ type: String })
  field = 'events_assignee';
  @property({ type: Boolean })
  single = false;

  private get employeesOptions() {
    return this.employees.map((e) => {
      return e;
    });
  }

  private fireAssignmentChanged() {
    this.dispatchEvent(
      new CustomEvent<{ assignedToEmployees: string[]; assignedToFunction: string; assignedToContacts: string[] }>(
        'assignment-changed',
        {
          bubbles: true,
          composed: true,
          detail: {
            assignedToEmployees: this.assignedToEmployees,
            assignedToFunction: this.assignedToFunction,
            assignedToContacts: this.assignedToContacts,
          },
        },
      ),
    );
  }

  private onEmployeesSelected() {
    if (this.assignedToEmployees.length === 0) {
      let defaultEmployee = this.employees[0].value;
      if (this.defaultEmployee) {
        defaultEmployee = this.defaultEmployee;
      }
      this.assignedToEmployees = [defaultEmployee];
      this.assignedToFunction = '';
      this.assignedToContacts = [];
      this.fireAssignmentChanged();
    }
  }

  private onFunctionSelected() {
    if (this.assignedToFunction === '') {
      let defaultFunction = this.functions[0].value;
      if (this.defaultFunction) {
        defaultFunction = this.defaultFunction;
      }
      this.assignedToFunction = defaultFunction;
      this.assignedToEmployees = [];
      this.assignedToContacts = [];
      this.fireAssignmentChanged();
    }
  }

  private onContactsSelected() {
    if (this.assignedToContacts.length === 0) {
      let defaultContact = this.contacts[0].value;
      if (this.defaultContact) {
        defaultContact = this.defaultContact;
      }
      this.assignedToEmployees = [];
      this.assignedToFunction = '';
      this.assignedToContacts = [defaultContact];
      this.fireAssignmentChanged();
    }
  }

  private get onlyEmployees() {
    return this.employees.length && !this.functions.length && !this.contacts.length;
  }

  private get onlyFunctions() {
    return !this.employees.length && this.functions.length && !this.contacts.length;
  }

  private get onlyContacts() {
    return !this.employees.length && !this.functions.length && this.contacts.length;
  }

  private get hasAlternativeTypes() {
    return !this.onlyEmployees && !this.onlyFunctions && !this.onlyContacts;
  }

  private renderAssigneeType() {
    if (this.hasAlternativeTypes) {
      return html`
        <div class="type">
          ${this.employees.length > 0
            ? html`
                <div ?selected="${this.assignedToEmployees.length > 0}" @click=${() => this.onEmployeesSelected()}>
                  Person
                </div>
              `
            : nothing}
          ${this.functions.length > 0
            ? html`
                <div ?selected="${this.assignedToFunction !== ''}" @click=${() => this.onFunctionSelected()}>
                  Ansvars&shy;område
                </div>
              `
            : nothing}
          ${this.contacts.length > 0
            ? html`
                <div ?selected="${this.assignedToContacts.length > 0}" @click=${() => this.onContactsSelected()}>
                  Kontakt&shy;person
                </div>
              `
            : nothing}
        </div>
      `;
    }
    return nothing;
  }

  private renderSelector() {
    if (this.assignedToEmployees.length > 0 || this.onlyEmployees) {
      return html`
        <d-select-tag
          .value=${this.assignedToEmployees}
          not-deselectable
          ?single="${this.single}"
          .options=${this.employees}
          .addNewText=${this.addNewEmployeeText}
          @value-changed=${this.onAssignedEmployeesChanged}
          @add-new=${() => this.addNewEmployee()}
        >
        </d-select-tag>
      `;
    } else if (this.assignedToFunction !== '' || this.onlyFunctions) {
      return html`
        <d-select-dropdown
          class="functionSelector"
          .options=${this.functions}
          .value=${this.assignedToFunction}
          @value-changed=${this.onAssignedFunctionChanged}
        >
        </d-select-dropdown>
      `;
    } else if (this.assignedToContacts.length > 0 || this.onlyContacts) {
      return html`
        <d-select-tag
          .value=${this.assignedToContacts}
          not-deselectable
          .options=${this.contacts}
          .addNewText=${this.addNewContactText}
          @value-changed=${this.onAssignedContactsChanged}
          @add-new=${() => this.addNewContact()}
        >
        </d-select-tag>
      `;
    }
    return nothing;
  }
  render() {
    return html`
      <d-label field="${this.field}"></d-label>
      ${this.renderAssigneeType()}
      <div>${this.renderSelector()}</div>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    if (this.onlyEmployees && this.assignedToEmployees.length === 0) {
      this.assignedToEmployees = [this.defaultEmployee];
      this.fireAssignmentChanged();
    }
  }

  private onAssignedEmployeesChanged(e: CustomEvent<{ value: string[] }>) {
    e.stopPropagation();
    this.assignedToEmployees = e.detail.value;
    this.fireAssignmentChanged();
  }

  private onAssignedFunctionChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.assignedToFunction = e.detail.value;
    this.fireAssignmentChanged();
  }

  private onAssignedContactsChanged(e: CustomEvent<{ value: string[] }>) {
    e.stopPropagation();
    this.assignedToContacts = e.detail.value;
    this.fireAssignmentChanged();
  }

  private addNewEmployee() {
    this.dispatchEvent(
      new CustomEvent('add-new-employee', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  private addNewContact() {
    this.dispatchEvent(
      new CustomEvent('add-new-contact', {
        bubbles: true,
        composed: true,
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-assignee': DEditAssignee;
  }
}
