import { css, html } from 'lit';
import '../elements/d-label.js';
import { customElement, property } from 'lit/decorators.js';
import { LabeledElement } from '../abstracts/labeled-element.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

/**
 *
 * STATUS OK
 */
@customElement('d-view-text')
export class DViewText extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        -webkit-user-select: text;
        -ms-user-select: text;
        -moz-user-select: text;
        user-select: text;
      }

      :host([mini]) {
        font-size: 13px;
        font-weight: 200;
      }

      :host([inline-label]) {
        display: block;
      }

      :host([wrap-label]) {
        flex-direction: row;
        flex-wrap: wrap;
      }

      .content {
        display: block;
        font-family: var(--mainSerif), serif;
        line-height: var(--lineHeightDefault);
      }

      :host([system-content]) .content {
        font-family: var(--mainSans), sans-serif;
      }

      :host([inline-label]) .content {
        display: inline;
      }

      :host([inline-label]) d-label {
        display: inline;
      }

      :host([inline-label]) d-label .label {
        margin-bottom: 0;
      }
    `,
  ];

  private valueAsHtml(value) {
    if (value) {
      const tagsToReplace = { '&': '&amp;', '<': '&lt;', '>': '&gt;' };
      const s = value.replace(/[&<>]/g, function (tag) {
        return tagsToReplace[tag] || tag;
      });
      return s.replace(/$/gm, '<br>');
    } else {
      return '';
    }
  }

  @property({ type: String })
  value = '';
  @property({ type: Boolean, attribute: 'system-content', reflect: true })
  systemContent = false;
  @property({ type: Boolean, reflect: true })
  mini = false;
  @property({ type: Boolean, attribute: 'wrap-label', reflect: true })
  wrapLabel = false;

  renderContent() {
    return html`
      <slot></slot>
      <span class="content">${unsafeHTML(this.valueAsHtml(this.value))}</span>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-text': DViewText;
  }
}
