import { html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import 'src/content/issues/d-new-issue';
import { DMobilePanel } from 'src/outskirts/account/mobile-app/d-mobile-panel';
import type { DEditPeriods, PeriodEditItem } from 'src/pages/d-edit-periods';
import type { EmployeeForStaffingCalendar } from 'src/store';
import 'src/pages/d-edit-periods';
import { LocalDate } from 'src/utilities/local-date';
import { EditPeriodResult } from 'src/pages/edit-periods-dialog';

/**
 *
 * STATUS OK
 */
@customElement('d-mobile-new-leave-period')
export class DMobileNewLeavePeriod extends DMobilePanel {
  @property({ type: Object })
  editItem: PeriodEditItem = {
    type: 'vacation',
    confirmed: false,
    startDate: LocalDate.fromString().toString(),
    startTime: 'NONE',
    endDate: LocalDate.fromString().toString(),
    endTime: 'NONE',
    notes: '',
    periodId: '',
    grade: 100,
    days: [],
  };
  @property({ type: Object })
  employee!: EmployeeForStaffingCalendar;
  @property({ type: Boolean })
  currentUserHasAccess = false;
  @property({ type: Boolean })
  leavePeriodEditRestriction = false;
  @query('d-edit-periods')
  editPeriodsElm!: DEditPeriods;

  done() {
    const value: EditPeriodResult = {
      type: this.editItem.type,
      confirmed: this.editItem.confirmed,
      start: this.editItem.startDate + (this.editItem.startTime === 'NONE' ? '' : ' ' + this.editItem.startTime),
      end: this.editItem.endDate + (this.editItem.endTime === 'NONE' ? '' : ' ' + this.editItem.endTime),
      notes: this.editItem.notes,
      grade: this.editItem.grade,
      employeeUuid: this.employee.uuid,
      days: this.editPeriodsElm.calculatedDays,
      newPeriod: true,
    };
    this.dispatchEvent(
      new CustomEvent<EditPeriodResult>('save-leave-period', {
        bubbles: true,
        composed: true,
        detail: value,
      }),
    );
    this.close();
  }

  renderContent() {
    return html`<d-edit-periods
      .editItem=${this.editItem}
      .days=${this.editItem.days}
      .newPeriod=${true}
      .employee=${this.employee}
      .currentUserHasAccess=${this.currentUserHasAccess}
      .leavePeriodEditRestriction=${this.leavePeriodEditRestriction}
      @edit-item-changed=${(e: CustomEvent<PeriodEditItem>) => {
        this.editItem = e.detail;
      }}
    ></d-edit-periods> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-mobile-new-leave-period': DMobileNewLeavePeriod;
  }
}
