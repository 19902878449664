import { css, html, LitElement } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import '../../elements/d-label.js';
import '../elements/d-edit-text.js';
import type { DEditText } from '../elements/d-edit-text.js';
import '../elements/d-select-dropdown.js';
import type { SelectDropdownOption } from '../elements/d-select-dropdown.js';

/**
 *
 * STATUS OK
 */
@customElement('d-edit-html-toolbar')
export class DEditHtmlToolbar extends LitElement {
  @query('d-edit-text')
  dabihEditText: DEditText | undefined;
  @property({ type: String })
  href = '';
  @property({ type: Boolean, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean })
  showChecklist = false;

  private get blockStyleOptions(): SelectDropdownOption[] {
    const result = [
      { value: 'p', text: 'Normal' },
      { value: 'h1', text: 'Overskrift' },
      { value: 'h2', text: 'Mellomtittel' },
      { value: 'ul', text: 'Punktliste' },
      { value: 'ol', text: 'Nummerliste' },
    ];
    if (this.showChecklist) {
      result.push({ value: 'checklist', text: 'Sjekkliste' });
    }
    return result;
  }

  static readonly styles = css`
    :host {
      display: flex;
      justify-content: flex-end;
    }

    div:first-child {
      flex: none;
      max-width: 332px;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      position: relative;
      transition: max-width 0.5s;
    }

    :host(.show-link) div:first-child {
      max-width: 0;
    }

    div:first-child > * {
      flex: none;
      margin-left: 4px;
      background-color: hsl(0, 0%, 92%);
      border-radius: 4px;
    }

    :host > *:first-child {
      margin-left: 0;
    }

    d-select-dropdown {
      height: 30px;
    }

    button {
      -moz-appearance: none;
      -webkit-appearance: none;
      box-sizing: border-box;
      height: 30px;
      border: none;
      font-size: 15px;
      width: 30px;
      text-align: center;
      cursor: pointer;
      outline: none;
      background: hsl(0, 0%, 92%) url(/images/formatbuttons.svg) 0 0 no-repeat;
      background-size: 310px 70px;
    }

    :host([theme-page]) button {
      height: 32px;
      border: 1px solid var(--borderColor);
      background-color: white;
    }

    .bold {
      background-position: 0 0;
    }

    .italic {
      background-position: -40px 0;
    }

    .ind-plus {
      background-position: -160px 0;
    }

    .ind-minus {
      background-position: -200px 0;
    }

    .link {
      background-position: -240px 0;
    }

    .buttonCheckpoint {
      background: url(/images/formatbutton-listcheck.svg) no-repeat 50% 50%;
      background-size: 16px 16px;
    }
  `;

  _showEditLinkDialog() {
    this.dispatchEvent(new CustomEvent('show-edit-link-dialog', { bubbles: true, composed: true }));
  }

  _dispatch(e: Event, style: string) {
    e.stopPropagation();
    this.dispatchEvent(new CustomEvent('set-style', { bubbles: true, composed: true, detail: style }));
  }

  async _onBlockTypeChange(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.blockStyleValue = e.detail.value;
    this.dispatchEvent(new CustomEvent('block-type', { detail: e.detail.value }));
    await this.updateComplete;
    this.blockStyleValue = '';
  }

  @state()
  private blockStyleValue = '';

  render() {
    return html`
      <div>
        <d-select-dropdown
          placeholder="Avsnittsformat"
          .options=${this.blockStyleOptions}
          toolbar
          no-arrow
          ?theme-page=${this.themePage}
          @value-changed=${this._onBlockTypeChange}
          .value=${this.blockStyleValue}
        ></d-select-dropdown>
        <button class="bold" @click=${(e) => this._dispatch(e, 'bold')}></button>
        <button class="italic" @click=${(e) => this._dispatch(e, 'italic')}></button>
        <button class="ind-plus" @click=${(e) => this._dispatch(e, 'indPlus')}></button>
        <button class="ind-minus" @click=${(e) => this._dispatch(e, 'indMinus')}></button>
        <button class="link" @click=${() => this._showEditLinkDialog()}></button>
      </div>
    `;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'd-edit-html-toolbar': DEditHtmlToolbar;
  }
}
