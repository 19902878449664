/*
 *  Selectors that define the different variants. The organization can be based on different templates,
 *  currently EMPTY, BASE or COMMON
 *
 *  BASE has predefined content applicable to all small businesses
 *  COMMON has content for the health sector
 *
 *  Only COMMON is quality assured. The other two variants are prototypes.
 *
 *  This configuration shall be moved to the template when it stabilises
 */

// KOMMER FRA INNHOLDET ELLER PÅ SERVER
import { createSelector } from '@reduxjs/toolkit';
import type { State } from '../types.js';
import type { OrganizationReference } from '../api';

import { activityCodesAvailable, activityCodesCommon } from './selectors';
import activityCodesRootList from '../data/activity-codes-root.json';
import { professions } from './professions.js';

export function getUserOrganization(state: State): OrganizationReference | undefined {
  const path = state.path ?? '';
  const segments = path.split('/');
  if (segments.length >= 3) {
    const id = segments[2];
    const user = state.user;
    if (user?.organizations) {
      return user.organizations.find((x) => x.id === +id);
    }
  }
  return undefined;
}

export const variantName = createSelector(getUserOrganization, (organization) => {
  if (organization !== undefined && organization.template !== 'COMMON') {
    return 'BASE';
  }

  return 'COMMON';
});

export interface ActivityCode {
  name: string;
  code: string;
  codes?: ActivityCode[];
}

export interface ActivityCodeCommon {
  name: string;
  code: string[];
  codes?: ActivityCodeCommon[];
}

interface VariantConfiguration {
  activityCodesAvailable: ActivityCode[];
  activityCodesCommon: ActivityCodeCommon[];
  professions: string[];
  hideProfessions: boolean;
  hideShareVacation: boolean;
  hideHerId: boolean;
  hideActivityCodes: boolean;
  simplifiedIssuesChecklist: boolean;
  simplifiedAssets: boolean;
  simplifiedPrivacy: boolean;
  networkView: {
    firewallTooltip: string;
    connectionTypes: {
      id: string;
      value: string;
    }[];
    firewallOptions: {
      id: string;
      value: string;
    }[];
  };
  personalDataItemTypes: {
    id: string;
    value: string;
  }[];
  assetsView: {
    categories: string[];
  };
  staffingPage: {
    groupingPrompt: string;
  };
}

interface VariantConfigurations {
  BASE: VariantConfiguration;
  COMMON: VariantConfiguration;
}

function activityCodesRoot(): ActivityCode[] {
  return activityCodesRootList;
}

function activityCodesRootCommon(): ActivityCodeCommon[] {
  const commonCodes = ['A', 'C', 'D', 'F', 'G', 'H', 'I', 'J', 'L'];
  return activityCodesRootList
    .filter((x) => commonCodes.includes(x.code))
    .map((x) => {
      return {
        name: x.name,
        code: [x.code],
      };
    });
}

const variantConfigurations: VariantConfigurations = {
  BASE: {
    activityCodesAvailable: activityCodesRoot(),
    activityCodesCommon: activityCodesRootCommon(),
    professions: ['Annet'],
    hideProfessions: true,
    hideHerId: true,
    hideActivityCodes: true,
    hideShareVacation: true,
    simplifiedPrivacy: true,
    simplifiedIssuesChecklist: true,
    simplifiedAssets: true,
    networkView: {
      firewallTooltip: '',
      firewallOptions: [
        { id: 'NONE', value: 'Ingen' },
        { id: 'OTHER', value: 'Brannmur, spesifiser…' },
      ],
      connectionTypes: [
        { id: 'NONE', value: 'Ingen internettforbindelse' },
        { id: 'OTHER', value: 'Tilkoblet Internett' },
      ],
    },
    personalDataItemTypes: [
      { id: 'UNDEFINED', value: 'Velg type' },
      { id: 'employeeRecords', value: 'Personalarkiv' },
      { id: 'customerRecords', value: 'Kundeliste' },
      { id: 'OTHER', value: 'Andre persondata' },
    ],
    assetsView: {
      categories: ['Ingen', 'Produksjon', 'Annet'],
    },
    staffingPage: {
      groupingPrompt:
        'For å gi bedre oversikt over bemanningen kan personalet sorteres i grupper.\n' +
        '                                Grafen nederst i kalenderen viser hvor mange i hver gruppe som er til stede hver dag.\n' +
        '                                Du kan redigere gruppefordelingen ved å trykke på gruppefargen for hver person nedenfor.',
    },
  },
  COMMON: {
    activityCodesAvailable: activityCodesAvailable(),
    activityCodesCommon: activityCodesCommon(),
    professions: professions,
    hideProfessions: false,
    hideHerId: false,
    hideShareVacation: false,
    hideActivityCodes: false,
    simplifiedPrivacy: false,
    simplifiedIssuesChecklist: false,
    simplifiedAssets: false,
    networkView: {
      firewallTooltip:
        '<p><strong>NHN med NHN samband: </strong>Norsk helsenett har satt opp brannmuren ' +
        'for selv å levere linjen (gjennom sin egen underleverandør).</p>' +
        '<p><strong>NHN med selvvalgt samband: </strong>Norsk helsenett har satt opp brannmuren slik at du selv ' +
        'kan velge internettleverandør</p>',
      firewallOptions: [
        { id: 'NONE', value: 'Ingen' },
        { id: 'NHN', value: 'NHN med NHN samband' },
        { id: 'NHNotherISP', value: 'NHN med selvvalgt samband' },
        { id: 'OTHER', value: 'Annen brannmurtype, spesifiser…' },
      ],
      connectionTypes: [
        { id: 'NONE', value: 'Ingen internettforbindelse' },
        {
          id: 'NHN',
          value: 'Tilkoblet helsenettet med Norsk helsenett som internettleverandør',
        },
        {
          id: 'NHNotherISP',
          value: 'Tilkoblet helsenettet med selvvalgt internettleverandør',
        },
        { id: 'OTHER', value: 'Tilkoblet Internett utenfor helsenettet' },
      ],
    },
    personalDataItemTypes: [
      { id: 'UNDEFINED', value: 'Velg type' },
      { id: 'medicalRecords', value: 'Pasientjournal' },
      {
        id: 'medicalEquipmentRecords',
        value: 'Pasientdata for medisinsk teknisk utstyr',
      },
      { id: 'patientDialogue', value: 'System for pasientdialog' },
      { id: 'employeeRecords', value: 'Personalarkiv' },
      { id: 'customerRecords', value: 'Kundeliste' },
      { id: 'OTHER', value: 'Andre persondata' },
    ],
    assetsView: {
      categories: ['Ingen', 'Diagnostisk utstyr', 'Behandlingsutstyr', 'Treningsutstyr', 'Annet'],
    },
    staffingPage: {
      groupingPrompt:
        'For å gi bedre oversikt over bemanningen kan personalet sorteres i grupper,\n' +
        '                                f.eks etter hvem som er pasientbehandlere og hvem som er hjelpepersonell.\n' +
        '                                Grafen nederst i kalenderen viser hvor mange i hver gruppe som er til stede hver dag.\n' +
        '                                Du kan redigere gruppefordelingen ved å trykke på gruppefargen for hver person nedenfor.',
    },
  },
};

export const variantConfiguration = createSelector(variantName, (name) => {
  return variantConfigurations[name as keyof VariantConfigurations];
});

export const variantActivityCodesAvailable = createSelector(variantConfiguration, (v) => {
  return v.activityCodesAvailable;
});

export const variantActivityCodesCommon = createSelector(variantConfiguration, (v) => {
  return v.activityCodesCommon;
});

export const variantProfessions = createSelector(variantConfiguration, (v) => {
  return v.professions;
});

export const variantHideProfessions = createSelector(variantConfiguration, (v) => {
  return v.hideProfessions;
});

export const variantHideActivityCodes = createSelector(variantConfiguration, (v) => {
  return v.hideActivityCodes;
});

export const variantHideShareVacation = createSelector(variantConfiguration, (v) => {
  return v.hideShareVacation;
});

export const variantHideHerId = createSelector(variantConfiguration, (v) => {
  return v.hideHerId;
});

export const variantSimplifiedPrivacy = createSelector(variantConfiguration, (v) => {
  return v.simplifiedPrivacy;
});

export const variantNetworkView = createSelector(variantConfiguration, (v) => {
  return v.networkView;
});

export const variantPersonalDataItemTypes = createSelector(variantConfiguration, (v) => {
  return v.personalDataItemTypes;
});

export const variantSimplifiedIssuesChecklist = createSelector(variantConfiguration, (v) => {
  return v.simplifiedIssuesChecklist;
});

export const variantAssetsView = createSelector(variantConfiguration, (v) => {
  return v.assetsView;
});

export const variantSimplifiedAssets = createSelector(variantConfiguration, (v) => {
  return v.simplifiedAssets;
});

export const variantStaffingPage = createSelector(variantConfiguration, (v) => {
  return v.staffingPage;
});
