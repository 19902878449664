import { css, html, LitElement, nothing } from 'lit';

import '../../library/components/d-help-section.js';
import '../../library/fields/d-view-html.js';
import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-item.js';
import '../../library/elements/d-smooth-resize.js';
import '../../library/elements/d-section.js';
import '../../library/elements/d-progress-bar.js';
import '../../library/fields/d-expansion.js';
import { SlideNotificationContent } from './d-slide-notification.js';
import { customElement, property } from 'lit/decorators.js';
import { startTaskStyles } from 'src/library/start-task-styles.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { ordinal } from 'src/utilities/text.js';

interface RelatedItems {
  href: string;
  name: string;
}

export interface StartTask {
  weeksFromNow: number;
  weekDisplay?: string;
  functionName?: string;
  uuid: string;
  href: string;
  name: string;
  helpContent: string;
  procedures: string;
  relatedItems: RelatedItems[];
}

/**
 *
 */
@customElement('d-starttask-field')
export class DStarttaskField extends LitElement {
  static readonly styles = [
    startTaskStyles,
    css`
      :host {
        display: block;
      }

      d-label {
        font-size: 15px;
      }

      .header {
        align-items: baseline;
      }

      .content {
        margin-top: 12px;
        margin-left: var(--listPaddingLeft);
        border-color: var(--startTaskBorderColor);
      }

      .contentScroller {
        padding: 16px 30px 20px 30px;
        max-height: calc(100vh - var(--applicationHeaderHeight) - 40vh);
        max-height: calc((var(--vh, 1vh) * 100) - var(--applicationHeaderHeight) - 40vh);
        overflow: auto;
      }

      .content d-help-section .collapsibleWrapper {
        max-width: none;
        margin-top: 0;
        margin-bottom: 12px;
      }

      .content d-help-section {
        margin: 14px 0;
      }

      .content d-help-section .systemText a.reference {
        color: var(--linkColorGray);
      }

      .content h1 {
        margin-top: 0;
        margin-bottom: 9px;
        font-size: 18px;
        color: var(--pageHeaderColor);
      }

      .content h2 {
        margin-top: 0;
        margin-bottom: 9px;
        font-size: 16px;
        color: var(--pageHeaderColor);
      }

      .content d-section > div:first-child {
        flex-basis: 400px;
        flex-grow: 3;
        padding-right: 20px;
      }

      .content d-section > div:last-child {
        flex-basis: 300px;
        flex-grow: 1;
      }

      .content d-section > div:last-child ul {
        list-style-type: none;
        margin-top: 0;
        padding-left: 20px;
      }

      .content d-section > div:last-child ul a {
        display: block;
        padding: 4px 0;
        color: var(--linkColorGray);
        text-decoration: none;
      }

      .content d-section > div:last-child ul a:hover {
        color: black;
      }

      d-slide-notification .progressText {
        margin-top: 6px;
      }
    `,
  ];
  @property({ type: Boolean, reflect: true })
  open = false;
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Boolean })
  noExpandAnimation = false;
  @property({ type: Number })
  userTotal = 0;
  @property({ type: Number })
  userExecuted = 0;
  @property({ type: Object })
  startTask!: StartTask;

  private get notificationExecuted() {
    return this.userExecuted + 1;
  }

  private get headerLabel() {
    if (this.startTask.weeksFromNow === 0) {
      return 'Ukens etableringsoppgave';
    }
    if (this.startTask.weeksFromNow === 1) {
      return 'Neste ukes etableringsoppgave';
    }
    if (this.startTask.weekDisplay) {
      return this.startTask.weekDisplay;
    }
    throw new Error('Illegal state');
  }

  private get headerSubLabel() {
    if (this.open) {
      return '';
    }
    if (this.startTask.name === '' && this.startTask.functionName && !this.singleUserVersion) {
      return this.startTask.functionName;
    }
    return this.startTask.name;
  }

  private get procedure() {
    return this.startTask.procedures + '<p><i>Trykk på «Merk som utført» øverst til høyre når du er ferdig.</i></p>';
  }

  private get notificationText() {
    if (this.userTotal === this.notificationExecuted) {
      return 'Du har fullført alle etableringsoppgavene.';
    }
    return 'Du har fullført den ' + ordinal(this.notificationExecuted) + ' etableringsoppgaven.';
  }

  private get progressText() {
    return this.notificationExecuted + ' av ' + this.userTotal + ' oppgaver utført';
  }

  _disableSmoothResize(show, open) {
    return !show || !open;
  }

  _open() {
    this.open = true;
  }

  _close(e) {
    e.stopPropagation();
    this.open = false;
    this.dispatchEvent(
      new CustomEvent('start-task-closed', {
        bubbles: true,
        composed: true,
        detail: {},
      }),
    );
  }

  dispatchSlideNotification() {
    this.dispatchEvent(
      new CustomEvent<SlideNotificationContent>('slide-notification', {
        bubbles: true,
        composed: true,
        detail: {
          primaryText: 'Gratulerer! ' + this.notificationText,
          secondaryText: this.progressText,
          progressBar: {
            total: this.userTotal,
            partNumber: this.notificationExecuted,
          },
        },
      }),
    );
  }

  _done(e: MouseEvent) {
    e.stopPropagation();
    this.open = false;
    this.dispatchEvent(
      new CustomEvent<{ taskUuid: string }>('task-done', {
        bubbles: true,
        composed: true,
        detail: {
          taskUuid: this.startTask.uuid,
        },
      }),
    );
    this.dispatchSlideNotification();
  }

  render() {
    if (this.startTask === undefined) {
      return nothing;
    }
    return html`
      <d-expansion opened @click=${() => this._open()}>
        <div>
          <div class="header">
            <div class="top-right">
              ${this.open
                ? html`
                    <d-action @click=${(e) => this._done(e)}>Merk som utført</d-action>
                    <d-closer @click=${(e) => this._close(e)}></d-closer>
                  `
                : nothing}
            </div>
            <div class="label-wrapper">
              <d-label
                allow-wrap
                semibold
                .label=${this.headerLabel}
                sublabel="${ifDefined(this.headerSubLabel)}"
              ></d-label>
            </div>
          </div>
          <d-expansion ?opened=${this.open}>
            <div class="content">
              <d-section>
                <div>
                  <h1>${this.startTask.name}</h1>
                  ${this.startTask.helpContent
                    ? html` <d-help-section content="${this.startTask.helpContent}"></d-help-section> `
                    : nothing}
                  <d-view-info content="${this.procedure}"></d-view-info>
                </div>
              </d-section>
            </div>
          </d-expansion>
        </div>
      </d-expansion>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-starttask-field': DStarttaskField;
  }
}
