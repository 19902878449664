import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../library/elements/d-menu.js';
import './d-infosec-view-header.js';
import '../../../library/editors/elements/d-select-checkbox.js';
import '../../../library/elements/d-step-menu.js';
import './editors/d-data-items.js';
import '../../../library/editors/components/d-select-add.js';
import {
  dataItemLabels,
  getCategoryName,
  getDataTypeName,
  InfosecAsset,
  responsiveFormStyles,
  sortSelectedCategories,
} from './defaults.js';
import './editors/d-edit-computers-network.js';
import type { InfosecDataItem, InfosecStorageUnit } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { ResponsiveContainer } from 'src/library/elements/responsive-container.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { dataItemName } from 'src/models/pages/computers-page-view.js';

export interface AccessChange {
  uuid: string;
  accessingEmployees: string[];
  accessingPartners: string[];
  newPartner?: { value: string; text: string };
}

/**
 *
 */

@customElement('d-infosec-access')
export class DInfosecAccess extends ResponsiveContainer {
  static readonly styles = [
    responsiveFormStyles,
    css`
      :host {
        display: block;
        margin-top: -1px;
      }
      d-section:last-of-type {
        border-bottom: 1px solid var(--borderColorOnGray);
      }
    `,
  ];

  @property({ type: Array })
  dataItems: InfosecDataItem[] = [];
  @property({ type: Array })
  employees: { value: string; text: string }[] = [];
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  remoteServers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  cloudServices: InfosecStorageUnit[] = [];
  @property({ type: Array })
  locations: InfosecStorageUnit[] = [];
  @property({ type: Array })
  assets: InfosecAsset[] = [];
  @property({ type: Number })
  index = 0;
  @property({ type: Array })
  currentPath: number[] = [0, 0];

  noItemsInfo = `<p><em>Ingen databehandling er registrert. 
     Gå først til fanen Databehandling og registrer hvilke data som behandles.</em></p>`;

  private get steps() {
    return [
      {
        label: 'Personale',
        steps: sortSelectedCategories(this.dataItems).map((dataItem) => {
          const labels = dataItemLabels(
            dataItem,
            this.computers,
            this.cloudServices,
            this.remoteServers,
            this.partners,
            this.assets,
          );
          return {
            label: labels.primary,
            sublabel: labels.secondary,
          };
        }),
      },
      {
        label: 'Samarbeidspartnere',
      },
    ];
  }
  _pathChanged(path) {
    this.currentPath = path;
  }

  _accessChangedEmployees(dataItem, detail) {
    this.dataItems = this.dataItems.map((d) => {
      if (d === dataItem) {
        return {
          ...dataItem,
          accessingEmployees: detail,
        };
      } else {
        return d;
      }
    });
    this.dispatchEvent(
      new CustomEvent<AccessChange>('access-change', {
        composed: true,
        bubbles: true,
        detail: {
          uuid: dataItem.uuid,
          accessingEmployees: detail,
          accessingPartners: dataItem.accessingPartners,
        },
      }),
    );
  }

  _storageUnitPartner(dataItem) {
    if (dataItem.storageUnit) {
      const list = this[dataItem.storageUnitType] ?? [];

      const storageUnits = list.filter((unit) => {
        return unit.uuid === dataItem.storageUnit;
      });
      if (storageUnits.length && storageUnits[0].partnerUuid) {
        return storageUnits[0].partnerUuid;
      }
    }
  }

  _accessingAndStorageUnitPartners(dataItem) {
    const result: string[] = [];
    if (this._storageUnitPartner(dataItem)) {
      result.push(this._storageUnitPartner(dataItem));
    }
    dataItem.accessingPartners.forEach((partner) => {
      result.push(partner);
    });
    return result;
  }

  _accessChangedPartners(dataItem: InfosecDataItem, detail) {
    const selectedItems = detail.selectedItems;
    const partnerOptions = detail.options;
    partnerOptions.forEach((option) => {
      const existingPartner = this.partners.filter((partner) => {
        return partner.value === option.value;
      });
      if (existingPartner.length === 0) {
        this.partners.push({
          value: option.value,
          text: option.text,
        });
      }
    });

    // this.dataItems = cloneDeep(this.dataItems);
    this.dispatchEvent(
      new CustomEvent<AccessChange>('access-change', {
        composed: true,
        bubbles: true,
        detail: {
          uuid: dataItem.uuid,
          accessingEmployees: dataItem.accessingEmployees,
          accessingPartners: selectedItems.filter((item) => {
            return item !== this._storageUnitPartner(dataItem);
          }),
          newPartner: detail.newItem,
        },
      }),
    );
  }

  renderStepAccessEmployees() {
    if (this.dataItems.length === 0) {
      return html`
        <d-infosec-view-header title="Personale med tilgang" .info=${this.noItemsInfo}></d-infosec-view-header>
      `;
    }
    const dataItem: InfosecDataItem = sortSelectedCategories(this.dataItems)[this.currentPath[1]];
    let info = `<p>Velg alle personer som har tilgang til informasjonen.</p>`;
    if (
      dataItem.category === 'webHostingProviders' ||
      dataItem.category === 'emailProviders' ||
      dataItem.category === 'socialMedia'
    ) {
      info = `<p>Velg alle personer som kan endre informasjon i systemet.</p>`;
    }
    const labels = dataItemLabels(
      dataItem,
      this.computers,
      this.cloudServices,
      this.remoteServers,
      this.partners,
      this.assets,
    );
    return html`
      <d-infosec-view-header
        title="Personale med tilgang"
        .subtitle=${labels.primary}
        .subtitleSecondary=${labels.secondary}
        .info=${info}
      ></d-infosec-view-header>
      <d-select-checkbox
        vertical
        bordered
        theme-page
        wrap-label
        .options=${this.employees}
        .value=${dataItem.accessingEmployees}
        @value-changed=${(e) => {
          console.log(e.detail);
          console.log(dataItem);
          console.log(this.currentPath[1]);

          this._accessChangedEmployees(dataItem, e.detail.value);
        }}
      ></d-select-checkbox>
    `;
  }

  renderStepAccessPartners() {
    if (this.dataItems.length === 0) {
      return html`
        <d-infosec-view-header title="Samarbeidspartnere med tilgang" .info=${this.noItemsInfo}></d-infosec-view-header>
      `;
    }
    let info = `<p>Velg samarbeidspartnere som har tilgang til informasjonen.</p>`;
    if (
      sortSelectedCategories(this.dataItems).filter((dataItem) => {
        return (
          dataItem.category === 'webServices' ||
          dataItem.category === 'emailServices' ||
          dataItem.category === 'socialMedia'
        );
      }).length > 0
    ) {
      info = `
        <p>Velg samarbeidspartnere som har tilgang til informasjonen.</p>
        <ul><li>For webtjenester, eposttjenester og sosiale medier menes tilgang til å endre informasjon i systemene.</li></ul>
        `;
    }
    return html`
      <d-infosec-view-header title="Samarbeidspartnere med tilgang" .info=${info}></d-infosec-view-header>
      <table>
        ${sortSelectedCategories(this.dataItems)
          .filter((d) => d.storageUnitType !== '')
          .map((dataItem) => {
            const labels = dataItemLabels(
              dataItem,
              this.computers,
              this.cloudServices,
              this.remoteServers,
              this.partners,
              this.assets,
            );
            return html`
              <tr>
                <td>
                  <d-label allow-wrap .label=${labels.primary} sublabel="${labels.secondary}"></d-label>
                </td>
                <td>
                  <d-label
                    allow-wrap
                    .label=${dataItemName(dataItem, this.computers, this.assets, this.locations)}
                    sublabel="${getCategoryName(dataItem)} med ${getDataTypeName(dataItem)}"
                  ></d-label>
                  <div class="editor">
                    <d-select-add
                      theme-page
                      placeholder="Velg samarbeidspartner"
                      addItemText="Registrer ny samarbeidspartner"
                      .selectedItems=${this._accessingAndStorageUnitPartners(dataItem)}
                      .lockedItems=${this._storageUnitPartner(dataItem)}
                      .options=${this.partners}
                      @selected-items-changed=${(e) => this._accessChangedPartners(dataItem, e.detail)}
                    ></d-select-add>
                  </div>
                </td>
              </tr>
            `;
          })}
      </table>
    `;
  }
  render() {
    return html`
      <d-step-menu
        .steps=${this.steps}
        .currentPath=${this.currentPath}
        @path-changed=${(e) => this._pathChanged(e.detail)}
      ></d-step-menu>
      ${this.currentPath[0] === 0 ? html` ${this.renderStepAccessEmployees()} ` : nothing}
      ${this.currentPath[0] === 1 ? html` ${this.renderStepAccessPartners()} ` : nothing}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-infosec-access': DInfosecAccess;
  }
}
