import { css, html, nothing, PropertyValueMap } from 'lit';
import '../library/fields/d-spinner-robot.js';
import { customElement, property, query, state } from 'lit/decorators.js';
import '../library/fields/d-expansion.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';

export interface TutorialNavigatorParts {
  state: string;
}

/**
 *
 */
@customElement('d-tutorial-navigator')
export class DTutorialNavigator extends ResponsiveElement {
  static readonly styles = css`
    :host {
      display: flex;
      align-items: center;
    }
    .prev-next {
      position: relative;
      top: -1px;
      color: var(--themeColor);
      font-size: 12px;
      line-height: 100%;
      font-weight: 400;
      cursor: pointer;
    }
    .prev-next:hover {
      color: black;
    }
    .prev-next[disabled],
    .prev-next[disabled]:hover {
      color: black;
      opacity: 0.5;
      cursor: default;
    }
    .graph {
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      height: 15px;
      margin: 0 16px;
    }
    .bar {
      flex: 1;
      display: flex;
      height: 7px;
    }
    .part {
      flex: 1;
      background: hsl(0, 0%, 90%);
      margin-right: 2px;
      cursor: pointer;
    }
    .part:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .part:last-child {
      margin-right: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .part.passed {
      background: var(--themeColor);
    }
    .part.failed {
      background: var(--alertColor);
    }
    .part:hover:after {
      content: '';
      position: relative;
      top: -100%;
      display: inline-block;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.1;
    }
    .current {
      position: absolute;
      box-sizing: border-box;
      height: 15px;
      border: 2px solid hsl(0, 0%, 80%);
      border-radius: 4px;
      transition: left 0.2s;
    }
  `;
  @property({ type: Boolean })
  intro = false;
  @property({ type: Array })
  parts: TutorialNavigatorParts[] = [];
  @property({ type: Number })
  current = 1;

  @query('.part')
  private _partEl?: HTMLElement;

  @state()
  private _markerStyles: { width: string; left: string } = { width: '0px', left: '0px' };

  private get partWidth() {
    if (this._partEl) {
      return this._partEl.getBoundingClientRect().width;
    }
    return 0;
  }

  private get nextDisabled() {
    return this.current >= this.parts.length;
  }

  _prev(e) {
    e.preventDefault();
    if (this.current > 0) {
      this.current = this.current - 1;
      this.dispatchEvent(new CustomEvent('select', { bubbles: true, composed: true, detail: this.current }));
    }
  }

  _next(e) {
    e.preventDefault();
    if (!this.nextDisabled) {
      this.current = this.current + 1;
      this.dispatchEvent(new CustomEvent('select', { bubbles: true, composed: true, detail: this.current }));
    }
  }

  _select(index) {
    this.current = index + 1;
    this.dispatchEvent(new CustomEvent('select', { bubbles: true, composed: true, detail: index + 1 }));
  }

  render() {
    return html` <a class="prev-next prev" ?disabled=${this.current === 0} @click=${(e) => this._prev(e)}
        >&#60; forrige</a
      >
      <div class="graph">
        <div class="bar">
          ${this.parts.map(
            (part, index) => html`
              <div
                class="part ${classMap({ passed: part.state === 'PASS', failed: part.state === 'FAIL' })}"
                @click=${() => this._select(index)}
              ></div>
            `,
          )}
        </div>
        ${this.current === 0 || this.current > this.parts.length
          ? nothing
          : html` <div class="current" style="${styleMap(this._markerStyles)}"></div> `}
      </div>
      <a class="prev-next next" ?disabled=${this.nextDisabled} @click=${(e) => this._next(e)}>neste &#62;</a>`;
  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('current') || _changedProperties.has('parts')) {
      this._markerStyles = {
        width: this.partWidth + 8 + 'px',
        left: (this.partWidth + 2) * (this.current - 1) - 4 + 'px',
      };
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-tutorial-navigator': DTutorialNavigator;
  }
}
