import type { State } from '../../store/types.js';
import { BASE_PATH, employeeGroupsAsListItems, employeesNotDeleted, functionsNotDeleted, key } from '../../store';
import { EmployeesPageViewModel } from 'src/pages/employees-page/d-employees-page-content';
import { getFeatureStates } from 'src/store/selectors/features';
import { AbstractPageView } from 'src/pages/abstract-page-view';

export function employeesPageView(
  hrefPrefix: string,
  viewModel: AbstractPageView,
  state: State,
): EmployeesPageViewModel {
  const o = state.organization;
  if (o === undefined) {
    throw new Error('Illegal state (E151), organization not found');
  }
  return {
    ...viewModel,
    type: 'employees-page',
    icon: 'employees',
    href: hrefPrefix,
    pdfHref: BASE_PATH + '/organizations/' + o.organizationId + '/overview.pdf?key=' + key(state),
    employeeGroups: employeeGroupsAsListItems(
      hrefPrefix,
      employeesNotDeleted(state),
      functionsNotDeleted(state),
      getFeatureStates(state),
    ),
  };
}
