import { css, html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { levelHeaderStyles } from 'src/library/level-header-styles.js';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';
import '../library/elements/d-smooth-resize.js';
import '../library/elements/d-more-menu';
import '../library/elements/d-label';
import '../library/elements/d-wrap';
import { ActionInput } from 'src/library/elements/d-action';
import { newItemLabels } from 'src/utilities/new-item-labels';
import { isEmptyOrInvalidString } from 'src/utilities/text';
/**
 *
 *
 *
 */
@customElement('d-entity-header')
export class DEntityHeader extends ResponsiveElement {
  static readonly styles = [
    levelHeaderStyles,
    css`
      .header {
        border-bottom: 1px solid var(--borderColor);
        background-color: white;
      }
      .title {
        padding-left: 60px;
        background-size: 60px 60px;
        background-position: -6px -6px;
      }
      .sublabel {
        font-weight: 400;
        font-size: max(80%, 15px);
      }
      .progress-bar {
        height: 1px;
        margin-top: -1px;
      }
      .progress-bar:after {
        content: '';
        display: block;
        width: 0;
        height: 100%;
        background: var(--themeColor);
        animation-name: progress;
        animation-duration: 1s;
      }
      @keyframes progress {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }
    `,
  ];
  @property({ type: Boolean, attribute: 'theme-page', reflect: true })
  themePage = false;
  @property({ type: String })
  icon = '';
  @property({ type: String })
  label = '';
  @property({ type: String })
  secondaryLabel = '';
  @property({ type: String })
  sublabel = '';
  @property({ type: String })
  href = '';
  @property({ type: String })
  parentHref = '';
  @property({ type: Boolean, reflect: true })
  covered = false;
  @property({ type: Boolean, reflect: true })
  top = false;
  @property({ type: String })
  mode = 'normal';
  @property({ type: String })
  internalMode = 'normal';
  @property({ type: Boolean })
  deleted = false;
  @property({ type: Boolean })
  hasDraft = false;
  @property({ type: Boolean })
  newItem = false;
  @property({ type: Boolean })
  currentUserHasWriteAccess = false;
  @property({ type: Array })
  draftHeaderActions: ActionInput[] = [];
  @property({ type: Array })
  editHeaderActions: ActionInput[] = [];
  @property({ type: Array })
  trashHeaderActions: ActionInput[] = [];
  @property({ type: Array })
  mainHeaderActions: ActionInput[] = [];
  @property({ type: Array })
  mainHeaderMoreActions: ActionInput[] = [];
  @property({ type: Boolean })
  savingDraft = false;
  @property({ type: String })
  editName = '';

  @query('#wrapper')
  private wrapperElm?: HTMLDivElement;

  private get titleStyle() {
    if (this.icon) {
      const typeName = this.icon.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
      return 'background-image: url(/images/' + typeName + '-color' + '.svg)';
    }
    return 'padding-left: 0';
  }

  private get titleClass() {
    if (this.icon) {
      return 'title icon';
    }
    return 'title';
  }

  private get editHeaderLabel() {
    return this.newItem ? 'Utkast til nytt dokument' : 'Utkast';
  }

  private get name() {
    if (!isEmptyOrInvalidString(this.editName)) {
      return this.editName;
    }
    if (!isEmptyOrInvalidString(this.label)) {
      return this.label;
    }
    return this.noLabelLabel(this.icon);
  }

  noLabelLabel(icon) {
    const l = newItemLabels.find(function (item) {
      return item.type === icon;
    });
    if (l) {
      return l.name;
    }
    return 'Uten navn';
  }

  onResize() {
    if (this.wrapperElm) {
      const height = this.wrapperElm.clientHeight;
      this.dispatchEvent(
        new CustomEvent('height-changed', {
          bubbles: true,
          composed: true,
          detail: height,
        }),
      );
    }
  }

  private handleAction(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent('action', {
        detail: e.detail,
      }),
    );
  }

  private renderDraftHeader() {
    return html` <div class="special-header draft-header">
      <div class="actions">
        ${this.draftHeaderActions.map(
          (action) => html` <d-action .input=${action} @action=${(e) => this.handleAction(e)}></d-action>`,
        )}
      </div>
      ${this.mode === 'normal' ? html` <d-label field="generalFields_hasDraft"></d-label>` : nothing}
    </div>`;
  }

  private renderEditHeader() {
    const optionalProgressBar = this.savingDraft ? html`<div class="progress-bar"></div> ` : nothing;
    return html`<div class="special-header draft-header">
        <div class="actions">
          ${this.editHeaderActions.map(
            (action) => html` <d-action .input=${action} @action=${(e) => this.handleAction(e)}></d-action>`,
          )}
        </div>
        <d-label label="${this.editHeaderLabel}" small-sublabel sublabel="lagres automatisk"></d-label>
      </div>
      ${optionalProgressBar} `;
  }

  private renderTrashHeader() {
    const trashHeaderActions = this.trashHeaderActions.map(
      (action) => html` <d-action .input=${action} @action=${(e) => this.handleAction(e)}></d-action>`,
    );
    const actions = this.currentUserHasWriteAccess ? html` <div class="actions">${trashHeaderActions}</div> ` : nothing;
    return html` <div class="special-header trash-header">
      ${actions}
      <d-label label="Dette elementet er slettet"></d-label>
    </div>`;
  }

  private renderHeader() {
    return html`
      <div class="header">
        <div class="actions">
          ${this.mainHeaderActions.map(
            (action) => html` <d-action .input=${action} @action=${(e) => this.handleAction(e)}></d-action>`,
          )}
          ${this.mainHeaderMoreActions.length
            ? html`
                <d-more-menu
                  .actions=${this.mainHeaderMoreActions}
                  @action=${(e) => this.handleAction(e)}
                ></d-more-menu>
              `
            : nothing}
        </div>
        <div style="${this.titleStyle}" class="${this.titleClass}">
          <span class="name">${this.name}</span>
          ${!this.secondaryLabel ? nothing : html` <span class="name">${this.secondaryLabel}</span> `}
          ${!this.sublabel ? nothing : html` <span class="sublabel">${this.sublabel}</span> `}
        </div>
        ${this.covered ? html`<a class="header-link" href="${this.href}"></a>` : nothing}
      </div>
    `;
  }

  render() {
    const optionalEditHeader = this.internalMode === 'edit' ? this.renderEditHeader() : nothing;
    const optionalDraftHeader =
      this.internalMode === 'normal' && this.hasDraft && this.currentUserHasWriteAccess
        ? this.renderDraftHeader()
        : nothing;
    const optionalPreHeaders = this.deleted
      ? this.renderTrashHeader()
      : html` ${optionalEditHeader} ${optionalDraftHeader} `;
    return html`<div id="wrapper">${optionalPreHeaders} ${this.renderHeader()}</div> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-entity-header': DEntityHeader;
  }
}
