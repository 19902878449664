import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { numberWord } from 'src/utilities/text.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { DListSection } from './d-list-section.js';

export interface TutorialListItem {
  uuid: string;
  name: string;
  assigned: string;
  due: string;
  completed: string;
}

/**
 *
 */
@customElement('d-list-section-tutorials')
export class DListSectionTutorials extends LitElement {
  static readonly styles = [
    ...DListSection.styles,
    css`
      :host {
        display: block;
      }
    `,
  ];
  @property({ type: String })
  label = 'Kurs og veiledere';
  @property({ type: Boolean, attribute: 'theme-page', reflect: true })
  themePage = false;
  @property({ type: Array })
  tutorials: TutorialListItem[] = [];
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: String })
  today = LocalDate.now().toString();

  private get tutorialItems(): ListSectionItemInput[] {
    return this.tutorials.map((tutorial) => {
      let eventDone = false;
      let secondaryLabel = 'påmeldt ' + LocalDate.fromString(tutorial.assigned).toStringForDisplay();
      let eventOverdue = false;
      if (tutorial.completed) {
        const completedDate = LocalDate.fromString(tutorial.completed);
        eventDone = true;
        secondaryLabel = 'gjennomført ' + completedDate.toStringForDisplay();
      } else if (tutorial.due) {
        const now = LocalDate.fromString(this.today);
        const dueDate = LocalDate.fromString(tutorial.due);
        if (dueDate.isBefore(now)) {
          eventOverdue = true;
          const daysOverdue = dueDate.until(now);
          let daysTerm = ' dager';
          if (daysOverdue === 1) {
            daysTerm = ' dag';
          }
          secondaryLabel += ', ' + numberWord(dueDate.until(now)) + daysTerm + ' på overtid';
        } else {
          secondaryLabel += ', frist ' + dueDate.toStringForDisplay();
        }
      }
      return {
        label: tutorial.name,
        secondaryLabel: secondaryLabel,
        eventDone: eventDone,
        eventOverdue: eventOverdue,
        clickData: tutorial.uuid,
        accessible: true,
      };
    });
  }

  render() {
    return html`
      <d-list-section
        .label=${this.label}
        .themePage=${this.themePage}
        icon="tutorials"
        .items=${this.tutorialItems}
        .contentStickyTop=${this.contentStickyTop}
      >
      </d-list-section>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section-tutorials': DListSectionTutorials;
  }
}
