import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../library/components/d-popup.js';
import '../library/editors/elements/d-checkbox.js';
import '../library/editors/elements/d-edit-textarea.js';
import '../library/elements/d-section.js';
import '../library/fields/d-view-info.js';
import { BaseDialog, DialogCancelResult } from 'src/library/components/BaseDialog.js';
import type { ActionInput } from 'src/library/elements/d-action.js';

export interface AlertsOnChangeInput {
  taskEvent: boolean;
  /**
   * Set to true if there are no eligible recipients. There should be a noAccessMessage included.
   */
  alertsDisabled: boolean;
  /**
   * Allow user to include all details
   */
  noDetails: boolean;

  noAccessMessage: string;

  newItem: boolean;

  isDelete: boolean;
}

export type AlertsOnChangeResult =
  | DialogCancelResult
  | { action: 'save' }
  | { action: 'save-and-alert'; includeDetails: boolean; message: string };

/**
 *
 * USAGE:
 *    base-event-editor
 *    d-task-view
 *
 */
@customElement('alerts-on-change-dialog')
export class AlertsOnChangeDialog extends BaseDialog<AlertsOnChangeInput, AlertsOnChangeResult> {
  @property({ type: Boolean })
  show = false;
  /**
   * Changes the title depending on the type of event
   */
  @property({ type: Boolean })
  taskEvent = false;
  /**
   * Set to true if there are no eligible recipients. There should be a noAccessMessage included.
   */
  @property({ type: Boolean })
  alertsDisabled = false;
  /**
   * Allow user to include all details
   */
  @property({ type: Boolean })
  noDetails = false;
  @property({ type: Boolean })
  includeDetails = false;
  @property({ type: String })
  message = '';
  @property({ type: String })
  noAccessMessage = '';
  @property({ type: Boolean })
  newItem = false;
  @property({ type: Boolean })
  isDelete = false;
  width = 500;
  private includeDetailsLabel = 'Ta med detaljer';

  protected get calculatedFooterActions(): ActionInput[] {
    return [
      { name: 'Send varsel', action: 'save-and-alert', disabled: this.alertsDisabled },
      { name: 'Ikke send varsel', action: 'save' },
      { name: 'Avbryt', action: 'cancel' },
    ];
  }

  protected get calculatedTitle(): string {
    const type = this.taskEvent ? 'rutine' : 'oppgave';
    let changeType = 'endret';
    if (this.newItem) {
      changeType = 'ny';
    }
    if (this.isDelete) {
      changeType = 'slettet';
    }

    return 'Varsel om ' + changeType + ' ' + type;
  }

  private get info() {
    if (this.taskEvent) {
      return (
        '<p>Send et epostvarsel om endringen til den eller dem som har oppgaver knyttet til denne rutinen. ' +
        'Du kan eventuelt også legge til en kommentar i dette varselet. ' +
        'Husk at personopplysninger ikke må sendes på epost.</p>'
      );
    }
    return (
      '<p>Send et epostvarsel til den eller dem som er berørt av dette. ' +
      'Du kan eventuelt også legge til en kommentar i dette varselet. ' +
      'Husk at personopplysninger ikke må sendes på epost.</p>'
    );
  }

  renderBody() {
    return html`
      <d-section>
        <d-view-info .content=${this.info}></d-view-info>
      </d-section>
      <d-section topless>
        <d-edit-textarea
          placeholder="Kommentar"
          value=${this.message}
          @value-changed=${(e) => (this.message = e.detail.value)}
        ></d-edit-textarea>
      </d-section>
      ${this.noDetails
        ? nothing
        : html`
            <d-section topless
              ><d-checkbox
                ?checked=${this.includeDetails}
                .optionLabel=${this.includeDetailsLabel}
                @checked-changed=${(e) => (this.includeDetails = e.detail.checked)}
              ></d-checkbox>
            </d-section>
          `}
      ${!this.noAccessMessage
        ? nothing
        : html`
            <d-section>
              <d-view-info .content=${this.noAccessMessage}></d-view-info>
            </d-section>
          `}
    `;
  }

  protected fetchResult(detail: string | undefined): AlertsOnChangeResult {
    if (detail === 'save-and-alert') {
      return {
        action: 'save-and-alert',
        includeDetails: this.includeDetails,
        message: this.message,
      };
    } else if (detail === 'save') {
      return {
        action: 'save',
      };
    } else {
      return {
        action: 'cancel',
      };
    }
  }

  protected initializeDialog(input: AlertsOnChangeInput) {
    this.taskEvent = input.taskEvent;
    this.alertsDisabled = input.alertsDisabled;
    this.noDetails = input.noDetails;
    this.noAccessMessage = input.noAccessMessage;
    this.newItem = input.newItem;
    this.isDelete = input.isDelete;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'alerts-on-change-dialog': AlertsOnChangeDialog;
  }
}
