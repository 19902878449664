import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';

import '../../library/editors/elements/d-select-tag.js';
import '../../library/editors/elements/d-checkbox.js';
import '../../library/fields/d-view-text.js';
import '../../library/fields/d-expansion.js';
import '../../library/elements/d-action.js';
import '../../library/elements/d-wrap.js';

/**
 *
 * STATUS OK
 */
@customElement('d-staffing-calendar-access')
export class DStaffingCalendarAccess extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      padding: 12px 0 10px 0;
    }
    d-checkbox {
      margin-top: 12px;
    }
  `;

  private listEmployeesWithAccess(employees: SelectTagOption[], value: string[]) {
    let list = '';
    if (value) {
      value.forEach((item: string) => {
        const filterElement = employees.find((e) => {
          return item === e.value;
        });
        if (filterElement !== undefined) {
          list += filterElement.text + ', ';
        }
      });
    }

    return list.slice(0, -2);
  }

  private onEdit() {
    this.value = this.accessList;
    this.edit = true;
  }

  private onSave() {
    this.dispatchEvent(
      new CustomEvent<{ accessList: string[]; leavePeriodEditRestriction: boolean }>('save-staffing-access', {
        composed: true,
        bubbles: true,
        detail: { accessList: this.value, leavePeriodEditRestriction: this.leavePeriodEditRestriction },
      }),
    );
    this.edit = false;
  }

  @property({ type: Object })
  fieldsByCode = {};
  @property({ type: Boolean })
  edit = false;
  @property({ type: Array })
  value: string[] = [];
  @property({ type: Boolean })
  currentUserHasAccess = false;
  @property({ type: Boolean })
  leavePeriodEditRestriction = false;
  @property({ type: Array })
  employees: SelectTagOption[] = [];
  @property({ type: Array })
  accessList: string[] = [];

  private onValueChanged(e: CustomEvent<{ value: string[] }>) {
    e.stopPropagation();
    this.value = [...e.detail.value];
  }

  private get accessors() {
    if (this.edit) {
      return '';
    }
    return this.listEmployeesWithAccess(this.employees, this.accessList);
  }

  render() {
    return html`
      <div>
        <d-wrap split-reverse baseline>
          ${this.currentUserHasAccess
            ? html`<d-action>
                ${this.edit
                  ? html`<span @click=${this.onSave}>Ferdig</span>`
                  : html`<span @click=${this.onEdit}>Rediger</span>`}
              </d-action>`
            : nothing}
          <d-view-text wrap-label field="staffing_fullAccess" value="${this.accessors}"></d-view-text>
        </d-wrap>
        <d-expansion ?opened=${this.edit}>
          <d-select-tag
            .options=${this.employees}
            type="tag"
            .value=${this.value}
            @value-changed=${this.onValueChanged}
          >
          </d-select-tag>
          <d-checkbox
            theme-page
            wrap-label
            field="staffing_leavePeriodEditRestriction"
            option-field="staffing_leavePeriodEditRestrictionOption"
            .checked=${this.leavePeriodEditRestriction}
            @checked-changed=${(e) => {
              this.leavePeriodEditRestriction = e.detail.checked;
            }}
          ></d-checkbox>
        </d-expansion>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-staffing-calendar-access': DStaffingCalendarAccess;
  }
}
