/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MarkEventOccurrenceNotDoneMessage
 */
export interface MarkEventOccurrenceNotDoneMessage {
  /**
   * The event occurrence uuid.
   * @type {string}
   * @memberof MarkEventOccurrenceNotDoneMessage
   */
  uuid: string;
}

/**
 * Check if a given object implements the MarkEventOccurrenceNotDoneMessage interface.
 */
export function instanceOfMarkEventOccurrenceNotDoneMessage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;

  return isInstance;
}

export function MarkEventOccurrenceNotDoneMessageFromJSON(json: any): MarkEventOccurrenceNotDoneMessage {
  return MarkEventOccurrenceNotDoneMessageFromJSONTyped(json, false);
}

export function MarkEventOccurrenceNotDoneMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MarkEventOccurrenceNotDoneMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
  };
}

export function MarkEventOccurrenceNotDoneMessageToJSON(value?: MarkEventOccurrenceNotDoneMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
  };
}
