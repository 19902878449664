import { css } from 'lit';

export const startTaskStyles = css`
  :host {
    display: block;
    background-color: var(--startTaskBackgroundColor);
    padding: 0 20px 12px 10px;
    cursor: pointer;
    container-name: host;
    container-type: inline-size;
    transition:
      margin 0.3s,
      border-radius 0.3s;
  }

  :host([open]) {
    cursor: default;
  }

  .header {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    padding-top: 4px;
    background-color: var(--startTaskBackgroundColor);
    z-index: 2;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    transition: padding-top 0.3s;
  }

  .top-right {
    display: flex;
    margin-top: 2px;
    margin-right: -8px;
    text-align: right;
  }

  d-closer {
    position: relative;
    top: 0;
    right: 0;
  }

  .label-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin: -1px 0 -2px 0;
    background-image: url(/images/starttask.svg);
    background-size: 30px 30px;
    background-position: -1px 3px;
    background-repeat: no-repeat;
    padding: 8px 0 0 var(--listPaddingLeft);
    font-family: var(--mainSans), sans-serif;
    transition: background-position 0.3s;
  }

  d-label {
    margin-right: 14px;
    font-size: 16px;
    color: var(--startHeaderTextColor);
    transition: font-size 0.3s;
  }

  .header-progress {
    display: flex;
    flex-grow: 1;
    margin-bottom: 8px;
  }

  :host([open]) .header-progress {
    display: none;
  }

  .progress-text {
    color: var(--startHeaderTextColor);
  }

  .header-progress .progress-text {
    margin-right: 10px;
    font-size: 14px;
  }

  .header-progress d-progress-bar {
    flex: 1;
    height: 8px;
    min-width: 40px;
    margin-top: 8px;
    margin-right: 6px;
    border-radius: 4px;
  }

  .progress {
    padding-left: var(--listPaddingLeft);
  }

  .progress d-progress-bar {
    margin-top: 10px;
    height: 10px;
    border-radius: 5px;
  }

  .progress .progress-text {
    margin: 10px 0;
  }

  d-list-section {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 600px) {
    .progress {
      padding-left: 0;
    }
  }
`;
