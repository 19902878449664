import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-select-time';
import { convertToMinutes, formatDuration } from 'src/utilities/text';

/**
 *
 * STATUS OK
 */
@customElement('d-edit-time')
export class DEditTime extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: flex;
      }
      d-select-time:first-child {
        margin-right: 6px;
      }
    `,
  ];
  @property({ type: String })
  time = 'NONE';
  @property({ type: Number })
  durationMinutes = 0;
  @property({ type: Boolean, attribute: 'whole-day-option' })
  wholeDayOption = false;
  @property({ type: Boolean })
  controller = false;

  private get endTime() {
    if (this.time !== 'NONE' && this.time !== '') {
      return formatDuration(convertToMinutes(this.time) + this.durationMinutes);
    }
    return 'NONE';
  }

  render() {
    return html`
      <d-select-time
        ?controller=${this.controller}
        no-arrow
        .wholeDayOption=${this.wholeDayOption}
        .value=${this.time}
        @value-changed=${this.onStartTimeChanged}
      ></d-select-time>
      ${this.time === 'NONE'
        ? nothing
        : html` <d-select-time
            ?controller=${this.controller}
            no-arrow
            label="til"
            inline-label
            light-label
            .value=${this.endTime}
            .max=${'24:00'}
            @value-changed=${this.onEndTimeChanged}
          ></d-select-time>`}
    `;
  }

  fireValueChanged() {
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { time: this.time, durationMinutes: this.durationMinutes },
      }),
    );
  }

  private onStartTimeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.time = e.detail.value;
    if (this.time === 'NONE') {
      this.durationMinutes = 0;
    } else {
      if (convertToMinutes(this.time) + this.durationMinutes > 1440) {
        this.durationMinutes = 1440 - convertToMinutes(this.time);
      }
    }
    this.fireValueChanged();
  }

  private onEndTimeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    let durationMinutes = convertToMinutes(e.detail.value) - convertToMinutes(this.time);
    if (this.time === 'NONE') {
      durationMinutes = 0;
    } else {
      if (durationMinutes < 0) {
        this.time = formatDuration(convertToMinutes(this.time) + durationMinutes);
        durationMinutes = 0;
      }
    }
    this.durationMinutes = durationMinutes;
    this.fireValueChanged();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-time': DEditTime;
  }
}
