import type { State } from 'src/store/types.js';
import type { ReferencesPageViewModel } from 'src/pages/references-page/references-page-view-model.js';
import { references } from 'src/store';
import { AbstractPageView } from 'src/pages/abstract-page-view';

export function referencesPageView(
  hrefPrefix: string,
  viewModel: AbstractPageView,
  state: State,
): ReferencesPageViewModel {
  return {
    ...viewModel,
    type: 'references-page',
    icon: 'references',
    href: hrefPrefix,
    references: references(state),
  };
}
