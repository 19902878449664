import { applications, purposeAndLegalBasisDefaults } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { OrganizationState, State } from 'src/store/types.js';
import {
  ComputerUpdateMessage,
  PartnerUpdateMessage,
  PersonalDataItemUpdateMessage,
  PersonalDataItemUpdateMessageBackupOperatorTypeEnum,
  PersonalDataItemViewModel,
} from 'src/store/api';
import { createAsset, createPartner, getStore, saveItem } from 'src/store';
import { uuid } from 'src/utilities/text.js';
import { mappedCloudServices, mappedRemoteServers } from 'src/models/pages/computers-page-view.js';
import type { DataItemRemoteServerChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-remote-server.js';
import type { DataItemLocationChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-location.js';
import type { DataItemComputerChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-computer.js';
import type { DataItemAssetChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-asset.js';
import type {
  DataItemApplicationChange,
  DataItemApplicationSupplierChange,
} from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-application.js';
import type { DataItemEmailAccountChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-items-email-account.js';
import type {
  DataItemAccountingChange,
  DataItemExistingApplicationAccountingChange,
  DataItemExistingComputerAccountingChange,
  DataItemExistingPartnerAccountingChange,
  DataItemNewComputerAccountingChange,
} from 'src/pages/computers-page/infosec-procedure/editors/d-edit-data-item-accounting.js';
import { computersNotDeleted, partnersNotDeleted } from 'src/store/selectors';

const defaultNewPersonalDataItem: PersonalDataItemUpdateMessage = {
  establishedDate: '',
  name: '',
  type: '',
  storageMedium: 'digital',
  storageUnit: '',
  storageLocation: '',
  backupOperatorType: PersonalDataItemUpdateMessageBackupOperatorTypeEnum.Undefined,
  backupOperator: '',
  journalSupplier: '',
  journalSize: '',
  sharedMedicalRecords: false,
  sharedMedicalRecordsPartners: [],
  sharedMedicalRecordsContracts: [],
  treatments: [],
  purpose: '',
  legalBasis: '',
  consentContracts: [],
  journalHasElectronicMessaging: false,
  messagingType: '',
  messagingTypeOther: '',
  messagingSupplier: '',
  riskAssessments: [],
  otherRiskAssessments: '',
  riskAssessmentConcludesOk: false,
  patientDialogueRequirements: [],
  notes: '',
  application: '',
  isConfirmedEntity: true,
};

async function patientDataItemChanged(
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
) {
  switch (e.detail.category) {
    case 'medicalRecordsSystems':
      await applicationItemChanged(o, e, state, 'medicalRecordsSystems', showNotification);
      return;
    case 'patientDialogSystems':
      await applicationItemChanged(o, e, state, 'patientDialogSystems', showNotification);
      return;
    case 'assetData':
      await patientDataAssetDataItemChanged(o, e);
      return;
    case 'localDocuments':
      await localDocumentsItemChanged(o, e);
      return;
    case 'remoteDocuments':
      await remoteDocumentsItemChanged(o, e, state, showNotification);
      return;
    case 'paperDocuments':
      await paperDocumentsItemChanged(o, e);
      return;
    default:
      alert(e.detail.category);
  }
}
async function managementDataItemChanged(
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
) {
  switch (e.detail.category) {
    case 'managementSystems':
      await applicationItemChanged(o, e, state, 'managementSystems', showNotification);
      return;
    case 'localDocuments':
      await localDocumentsItemChanged(o, e);
      return;
    case 'remoteDocuments':
      await remoteDocumentsItemChanged(o, e, state, showNotification);
      return;
    case 'paperDocuments':
      await paperDocumentsItemChanged(o, e);
      return;
    case 'accounting':
      await accountingItemChanged(o, e, state, showNotification);
      return;
    default:
      alert(e.detail.category);
  }
}
async function publicInformationAndCommunicationItemChanged(
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
) {
  switch (e.detail.category) {
    case 'emailServices':
      await applicationItemChanged(o, e, state, 'emailServices', showNotification);
      return;
    case 'webServices':
      await applicationItemChanged(o, e, state, 'webServices', showNotification);
      return;
    case 'socialMedia':
      await applicationItemChanged(o, e, state, 'socialMedia', showNotification);
      return;
    case 'emailAccounts':
      await emailAccountsItemChanged(o, e);
      return;
    default:
      alert(e.detail.category);
  }
}

function defaultUpdateMessage(element: PersonalDataItemViewModel) {
  return {
    establishedDate: element.establishedDate,
    name: element.name,
    type: element.type,
    storageMedium: element.storageMedium,
    storageUnit: element.storageUnit,
    storageLocation: element.storageLocation,
    backupOperatorType: element.backupOperatorType as PersonalDataItemUpdateMessageBackupOperatorTypeEnum,
    backupOperator: element.backupOperator,
    journalSupplier: element.journalSupplier,
    journalSize: element.journalSize,
    sharedMedicalRecords: element.sharedMedicalRecords,
    sharedMedicalRecordsPartners: element.sharedMedicalRecordsPartners,
    sharedMedicalRecordsContracts: element.sharedMedicalRecordsContracts,
    treatments: element.treatments,
    purpose: element.purpose,
    legalBasis: element.legalBasis,
    consentContracts: element.consentContracts,
    journalHasElectronicMessaging: element.journalHasElectronicMessaging,
    messagingType: element.messagingType,
    messagingTypeOther: element.messagingTypeOther,
    messagingSupplier: element.messagingSupplier,
    riskAssessments: element.riskAssessments,
    otherRiskAssessments: element.otherRiskAssessments,
    riskAssessmentConcludesOk: element.riskAssessmentConcludesOk,
    patientDialogueRequirements: element.patientDialogueRequirements,
    notes: element.notes,
    application: element.application,
  };
}
async function applicationCreated(
  e: CustomEvent<DataItemApplicationChange>,
  state: State,
  systems: string,
  showNotification: (s: string) => void,
) {
  switch (e.detail.storageUnitType) {
    case 'cloudServices':
      return await applicationCloudServiceCreated(e.detail, state, systems, showNotification);
    case 'remoteServers':
      return await applicationRemoteServerCreated(e.detail, state, showNotification);
    case 'computers':
      return await applicationComputerCreated(e.detail);
  }
}

async function applicationComputerCreated(detail: DataItemApplicationChange) {
  if (detail.storageUnitType !== 'computers') {
    throw new Error('Illegal state (E01), wrong storage unit type ' + detail.storageUnitType);
  }
  let storageUnit: string;
  if (!detail.newComputer) {
    storageUnit = detail.computerUuid;
  } else {
    storageUnit = uuid();
    const computerUpdateMessage: ComputerUpdateMessage = {
      location: '',
      name: detail.newComputerName,
      type: detail.newComputerType,
      networkUuid: undefined,
      unitType: 'computer',
      serviceProvider: '',
      connectionType: 'OTHER',
      serviceProviderContract: '',
      systemUpdateOperatorType: 'UNDEFINED',
      systemUpdateOperator: undefined,
      antiVirusOperatorType: 'UNDEFINED',
      antiVirusOperator: undefined,
      antiVirusRequirements: [],
      locked: false,
      restrictedPhysicalAccess: false,
      displayPositionSecure: false,
      elevated: false,
      mobileMedicalDataRequirements: [],
      notes: undefined,
      createdDateTime: new Date().toISOString(),
      isConfirmedEntity: true,
    };
    await getStore().dispatch(saveItem('computers', storageUnit, computerUpdateMessage));
  }

  const n: PersonalDataItemUpdateMessage = {
    ...defaultNewPersonalDataItem,
    type: detail.dataType + '.' + detail.category,
    storageUnit: storageUnit,
    application: detail.application,
    personalData: false,
    dataProcessor: '',
    purpose: purposeAndLegalBasisDefaults[detail.category]?.purpose ?? '',
    legalBasis: purposeAndLegalBasisDefaults[detail.category]?.legalBasis ?? '',
  };
  await getStore().dispatch(saveItem('personalDataItems', detail.uuid ?? uuid(), n));
}

async function applicationRemoteServerCreated(
  detail: DataItemApplicationChange,
  state: State,
  showNotification: (s: string) => void,
) {
  if (detail.storageUnitType !== 'remoteServers') {
    throw new Error('Illegal state (E02), wrong storage unit type ' + detail.storageUnitType);
  }
  let partnerUuid = '';
  if (detail.newSupplier) {
    partnerUuid = uuid();
    await getStore().dispatch(createPartner(partnerUuid, detail.newSupplierName));
    showNotification((detail.newSupplierName ?? '') + ' ble lagt til som samarbeidspartner');
  } else {
    partnerUuid = detail.supplierUuid;
  }

  const remoteServer = mappedRemoteServers(computersNotDeleted(state)).find((c) => c.partnerUuid === partnerUuid);
  const storageUnit = remoteServer?.uuid ?? uuid();
  if (remoteServer === undefined) {
    const computerUpdateMessage: ComputerUpdateMessage = {
      location: '',
      name: '',
      type: 'UNDEFINED',
      networkUuid: undefined,
      unitType: 'remoteServer',
      serviceProvider: partnerUuid,
      connectionType: 'OTHER',
      serviceProviderContract: '',
      systemUpdateOperatorType: 'UNDEFINED',
      systemUpdateOperator: undefined,
      antiVirusOperatorType: 'UNDEFINED',
      antiVirusOperator: undefined,
      antiVirusRequirements: [],
      locked: false,
      restrictedPhysicalAccess: false,
      displayPositionSecure: false,
      elevated: false,
      mobileMedicalDataRequirements: [],
      notes: undefined,
      createdDateTime: new Date().toISOString(),
      isConfirmedEntity: true,
    };
    await getStore().dispatch(saveItem('computers', storageUnit, computerUpdateMessage));
  }

  const n: PersonalDataItemUpdateMessage = {
    ...defaultNewPersonalDataItem,
    type: detail.dataType + '.' + detail.category,
    storageUnit: storageUnit,
    application: detail.application,
    personalData: false,
    dataProcessor: '',
    purpose: purposeAndLegalBasisDefaults[detail.category]?.purpose ?? '',
    legalBasis: purposeAndLegalBasisDefaults[detail.category]?.legalBasis ?? '',
  };
  await getStore().dispatch(saveItem('personalDataItems', detail.uuid ?? uuid(), n));
}

type CreateApplicationCloudServiceType = { uuid?: string; application: string; dataType: string; category: string } & {
  storageUnitType: 'cloudServices' | 'remoteServers';
} & DataItemApplicationSupplierChange;

async function createApplicationCloudService(
  systems: string,
  detail: DataItemApplicationChange,
  state: State,
  showNotification: (s: string) => void,
) {
  if (detail.storageUnitType !== 'cloudServices') {
    throw new Error('Illegal state (E03), wrong storage unit type ' + detail.storageUnitType);
  }
  const type = applications[systems].find((t) => t.value === detail.application);
  let partnerUuid = '';
  if (type === undefined) {
    if (detail.newSupplier) {
      partnerUuid = uuid();
      await getStore().dispatch(createPartner(partnerUuid, detail.newSupplierName));
      showNotification((detail.newSupplierName ?? '') + ' ble lagt til som samarbeidspartner');
    } else {
      partnerUuid = detail.supplierUuid;
    }
  } else {
    const partner = partnersNotDeleted(state).find((p) => p.name === type.provider);
    if (partner === undefined) {
      const partnerUpdateMessage: PartnerUpdateMessage = {
        name: type.provider,
        remoteAccess: true,
        physicalAccess: false,
        pages: [65],
        isConfirmedEntity: true,
      };
      partnerUuid = uuid();
      await getStore().dispatch(saveItem('partners', partnerUuid, partnerUpdateMessage));
      showNotification(type.provider + ' ble lagt til som samarbeidspartner');
    } else {
      partnerUuid = partner.uuid;
    }
  }

  const cloudService = mappedCloudServices(computersNotDeleted(state)).find((c) => c.partnerUuid === partnerUuid);
  const storageUnit = cloudService?.uuid ?? uuid();
  if (cloudService === undefined) {
    const computerUpdateMessage: ComputerUpdateMessage = {
      location: '',
      name: '',
      type: 'UNDEFINED',
      networkUuid: undefined,
      unitType: 'cloudService',
      serviceProvider: partnerUuid,
      connectionType: 'OTHER',
      serviceProviderContract: '',
      systemUpdateOperatorType: 'UNDEFINED',
      systemUpdateOperator: undefined,
      antiVirusOperatorType: 'UNDEFINED',
      antiVirusOperator: undefined,
      antiVirusRequirements: [],
      locked: false,
      restrictedPhysicalAccess: false,
      displayPositionSecure: false,
      elevated: false,
      mobileMedicalDataRequirements: [],
      notes: undefined,
      createdDateTime: new Date().toISOString(),
      isConfirmedEntity: true,
    };
    await getStore().dispatch(saveItem('computers', storageUnit, computerUpdateMessage));
  }

  const n: PersonalDataItemUpdateMessage = {
    ...defaultNewPersonalDataItem,
    type: detail.dataType + '.' + detail.category,
    storageUnit: storageUnit,
    application: detail.application,
    personalData: false,
    dataProcessor: '',
    purpose: purposeAndLegalBasisDefaults[detail.category]?.purpose ?? '',
    legalBasis: purposeAndLegalBasisDefaults[detail.category]?.legalBasis ?? '',
  };
  await getStore().dispatch(saveItem('personalDataItems', detail.uuid ?? uuid(), n));
}

async function applicationCloudServiceCreated(
  detail: DataItemApplicationChange,
  state: State,
  systems: string,
  showNotification: (s: string) => void,
) {
  if (detail.storageUnitType !== 'cloudServices') {
    throw new Error('Illegal state (E04), wrong storage unit type ' + detail.storageUnitType);
  }
  await createApplicationCloudService(systems, detail, state, showNotification);
}

async function assetDataCreated(e: CustomEvent<DataItemAssetChange>) {
  if (e.detail.newAsset) {
    await getStore().dispatch(
      createAsset(e.detail.newAssetUuid ?? '', e.detail.newAssetName ?? '', e.detail.newAssetNumber ?? ''),
    );
  }

  const n: PersonalDataItemUpdateMessage = {
    ...defaultNewPersonalDataItem,
    type: e.detail.dataType + '.' + e.detail.category,
    relatedAssets: e.detail.assets,
    purpose: purposeAndLegalBasisDefaults[e.detail.category]?.purpose ?? '',
    legalBasis: purposeAndLegalBasisDefaults[e.detail.category]?.legalBasis ?? '',
    createdDateTime: new Date().toISOString(),
  };
  await getStore().dispatch(saveItem('personalDataItems', e.detail.uuid ?? uuid(), n));
}

async function assetDataUpdated(e: CustomEvent<DataItemAssetChange>, element: PersonalDataItemViewModel) {
  if (e.detail.newAsset) {
    await getStore().dispatch(
      createAsset(e.detail.newAssetUuid ?? '', e.detail.newAssetName ?? '', e.detail.newAssetNumber ?? ''),
    );
  }

  if (e.detail.newComputer) {
    const computerUpdateMessage: ComputerUpdateMessage = {
      location: '',
      name: e.detail.newComputerName ?? '',
      type: e.detail.newComputerType ?? 'UNDEFINED',
      networkUuid: undefined,
      unitType: 'computer',
      serviceProvider: '',
      connectionType: 'OTHER',
      serviceProviderContract: '',
      systemUpdateOperatorType: 'UNDEFINED',
      systemUpdateOperator: undefined,
      antiVirusOperatorType: 'UNDEFINED',
      antiVirusOperator: undefined,
      antiVirusRequirements: [],
      locked: false,
      restrictedPhysicalAccess: false,
      displayPositionSecure: false,
      elevated: false,
      mobileMedicalDataRequirements: [],
      notes: undefined,
      isConfirmedEntity: true,
    };
    await getStore().dispatch(saveItem('computers', e.detail.computerUuid ?? '', computerUpdateMessage));
  }

  const n: PersonalDataItemUpdateMessage = {
    ...defaultUpdateMessage(element),
    relatedAssets: e.detail.assets,
    name: e.detail.name,
    storageUnit: e.detail.computerUuid,
    isConfirmedEntity: true,
  };

  await getStore().dispatch(saveItem('personalDataItems', e.detail.uuid ?? '', n));
}

async function localDocumentsCreated(e: CustomEvent<DataItemComputerChange>) {
  if (e.detail.newComputer) {
    const computerUpdateMessage: ComputerUpdateMessage = {
      location: '',
      name: e.detail.newComputerName ?? '',
      type: e.detail.newComputerType ?? 'UNDEFINED',
      networkUuid: undefined,
      unitType: 'computer',
      serviceProvider: '',
      connectionType: 'OTHER',
      serviceProviderContract: '',
      systemUpdateOperatorType: 'UNDEFINED',
      systemUpdateOperator: undefined,
      antiVirusOperatorType: 'UNDEFINED',
      antiVirusOperator: undefined,
      antiVirusRequirements: [],
      locked: false,
      restrictedPhysicalAccess: false,
      displayPositionSecure: false,
      elevated: false,
      mobileMedicalDataRequirements: [],
      notes: undefined,
      isConfirmedEntity: true,
    };
    await getStore().dispatch(saveItem('computers', e.detail.computerUuid, computerUpdateMessage));
  }

  const n: PersonalDataItemUpdateMessage = {
    ...defaultNewPersonalDataItem,
    type: e.detail.dataType + '.' + e.detail.category,
    storageUnit: e.detail.computerUuid,
    purpose: purposeAndLegalBasisDefaults[e.detail.category]?.purpose ?? '',
    legalBasis: purposeAndLegalBasisDefaults[e.detail.category]?.legalBasis ?? '',
  };
  await getStore().dispatch(saveItem('personalDataItems', e.detail.uuid ?? uuid(), n));
}

function createApplicationCloudserviveTypeMessage(
  detail: DataItemExistingApplicationAccountingChange,
): CreateApplicationCloudServiceType {
  if (detail.application.startsWith('CUSTOM-')) {
    return {
      storageUnitType: 'cloudServices',
      dataType: detail.dataType,
      category: detail.category,
      application: detail.application,
      newSupplier: true,
      supplierUuid: '',
      newSupplierName: detail.application.substring(7),
    };
  } else {
    return {
      storageUnitType: 'cloudServices',
      dataType: detail.dataType,
      category: detail.category,
      application: detail.application,
      newSupplier: false,
      supplierUuid: '',
    };
  }
}

async function cloudServiceAccountingCreated(
  detail: DataItemExistingApplicationAccountingChange,
  state: State,
  showNotification: (s: string) => void,
) {
  const m = createApplicationCloudserviveTypeMessage(detail);
  await createApplicationCloudService('accountingServices', m, state, showNotification);
}
async function computerAccountingCreated(
  detail: DataItemExistingComputerAccountingChange | DataItemNewComputerAccountingChange,
) {
  if (detail.newComputer) {
    const computerUpdateMessage: ComputerUpdateMessage = {
      location: '',
      name: detail.newComputerName ?? '',
      type: detail.newComputerType ?? 'UNDEFINED',
      networkUuid: undefined,
      unitType: 'computer',
      serviceProvider: '',
      connectionType: 'OTHER',
      serviceProviderContract: '',
      systemUpdateOperatorType: 'UNDEFINED',
      systemUpdateOperator: undefined,
      antiVirusOperatorType: 'UNDEFINED',
      antiVirusOperator: undefined,
      antiVirusRequirements: [],
      locked: false,
      restrictedPhysicalAccess: false,
      displayPositionSecure: false,
      elevated: false,
      mobileMedicalDataRequirements: [],
      notes: undefined,
      isConfirmedEntity: true,
    };
    await getStore().dispatch(saveItem('computers', detail.computerUuid, computerUpdateMessage));
  }
  const n: PersonalDataItemUpdateMessage = {
    ...defaultNewPersonalDataItem,
    type: detail.dataType + '.' + detail.category,
    storageUnit: detail.computerUuid,
    purpose: purposeAndLegalBasisDefaults[detail.category]?.purpose ?? '',
    legalBasis: purposeAndLegalBasisDefaults[detail.category]?.legalBasis ?? '',
  };
  await getStore().dispatch(saveItem('personalDataItems', uuid(), n));
}
async function partnerAccountingCreated(detail: DataItemExistingPartnerAccountingChange) {
  const n: PersonalDataItemUpdateMessage = {
    ...defaultNewPersonalDataItem,
    type: detail.dataType + '.' + detail.category,
    dataProcessor: detail.dataProcessor,
    purpose: purposeAndLegalBasisDefaults[detail.category]?.purpose ?? '',
    legalBasis: purposeAndLegalBasisDefaults[detail.category]?.legalBasis ?? '',
  };
  await getStore().dispatch(saveItem('personalDataItems', uuid(), n));
}

async function accountingCreated(
  e: CustomEvent<DataItemAccountingChange>,
  state: State,
  showNotification: (s: string) => void,
) {
  switch (e.detail.type) {
    case 'cloudServices':
      await cloudServiceAccountingCreated(e.detail, state, showNotification);
      break;
    case 'computers':
      await computerAccountingCreated(e.detail);
      break;
    case 'partners':
      await partnerAccountingCreated(e.detail);
      break;
  }
}

async function remoteDocumentsCreated(
  e: CustomEvent<DataItemRemoteServerChange>,
  state: State,
  showNotification: (s: string) => void,
) {
  if (e.detail.newSupplier) {
    await getStore().dispatch(createPartner(e.detail.supplierUuid, e.detail.newSupplierName ?? ''));
    showNotification((e.detail.newSupplierName ?? '') + ' ble lagt til som samarbeidspartner');
  }

  const remoteServers = computersNotDeleted(state).filter((c) => c.unitType === 'remoteServer');
  const remoteServer = remoteServers.find((r) => r.serviceProvider === e.detail.supplierUuid);
  const storageUnit = remoteServer?.uuid ?? uuid();
  if (remoteServer === undefined) {
    const computerUpdateMessage: ComputerUpdateMessage = {
      location: '',
      name: '',
      type: 'UNDEFINED',
      networkUuid: undefined,
      unitType: 'remoteServer',
      serviceProvider: e.detail.supplierUuid,
      connectionType: 'OTHER',
      serviceProviderContract: '',
      systemUpdateOperatorType: 'UNDEFINED',
      systemUpdateOperator: undefined,
      antiVirusOperatorType: 'UNDEFINED',
      antiVirusOperator: undefined,
      antiVirusRequirements: [],
      locked: false,
      restrictedPhysicalAccess: false,
      displayPositionSecure: false,
      elevated: false,
      mobileMedicalDataRequirements: [],
      notes: undefined,
      isConfirmedEntity: true,
    };
    await getStore().dispatch(saveItem('computers', storageUnit, computerUpdateMessage));
  }

  const n: PersonalDataItemUpdateMessage = {
    ...defaultNewPersonalDataItem,
    type: e.detail.dataType + '.' + e.detail.category,
    storageUnit: storageUnit,
    purpose: purposeAndLegalBasisDefaults[e.detail.category]?.purpose ?? '',
    legalBasis: purposeAndLegalBasisDefaults[e.detail.category]?.legalBasis ?? '',
  };
  await getStore().dispatch(saveItem('personalDataItems', e.detail.uuid ?? uuid(), n));
}

async function paperDocumentsCreated(e: CustomEvent<DataItemLocationChange>) {
  const n: PersonalDataItemUpdateMessage = {
    ...defaultNewPersonalDataItem,
    type: e.detail.dataType + '.' + e.detail.category,
    storageMedium: 'physical',
    storageUnit: '',
    storageLocation: e.detail.newLocationName,
    purpose: purposeAndLegalBasisDefaults[e.detail.category]?.purpose ?? '',
    legalBasis: purposeAndLegalBasisDefaults[e.detail.category]?.legalBasis ?? '',
  };
  await getStore().dispatch(saveItem('personalDataItems', e.detail.uuid ?? uuid(), n));
}
async function emailAccountsCreated(e: CustomEvent<DataItemEmailAccountChange>) {
  const n: PersonalDataItemUpdateMessage = {
    ...defaultNewPersonalDataItem,
    type: e.detail.dataType + '.' + e.detail.category,
    storageUnit: '',
    name: e.detail.email,
    purpose: purposeAndLegalBasisDefaults[e.detail.category]?.purpose ?? '',
    legalBasis: purposeAndLegalBasisDefaults[e.detail.category]?.legalBasis ?? '',
  };
  await getStore().dispatch(saveItem('personalDataItems', e.detail.uuid ?? uuid(), n));
}

function tempAlert(e: CustomEvent) {
  alert(JSON.stringify(e.detail, null, 2));
}

async function applicationItemChanged(
  o: OrganizationState,
  e: CustomEvent<DataItemApplicationChange>,
  state: State,
  system: string,
  showNotification: (s: string) => void,
) {
  const element = e.detail.uuid !== undefined ? o.personalDataItemsById[e.detail.uuid] : undefined;
  if (element !== undefined) {
    tempAlert(e);
  } else {
    await applicationCreated(e, state, system, showNotification);
  }
}

async function patientDataAssetDataItemChanged(o: OrganizationState, e: CustomEvent) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    await assetDataUpdated(e, element);
  } else {
    await assetDataCreated(e);
  }
}

async function localDocumentsItemChanged(o: OrganizationState, e: CustomEvent) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    tempAlert(e);
  } else {
    await localDocumentsCreated(e);
  }
}

async function accountingItemChanged(
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    tempAlert(e);
  } else {
    await accountingCreated(e, state, showNotification);
  }
}

async function remoteDocumentsItemChanged(
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    tempAlert(e);
  } else {
    await remoteDocumentsCreated(e, state, showNotification);
  }
}

async function paperDocumentsItemChanged(o: OrganizationState, e: CustomEvent) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    tempAlert(e);
  } else {
    await paperDocumentsCreated(e);
  }
}
async function emailAccountsItemChanged(o: OrganizationState, e: CustomEvent) {
  const element = o.personalDataItemsById[e.detail.uuid];
  if (element !== undefined) {
    tempAlert(e);
  } else {
    await emailAccountsCreated(e);
  }
}

export async function dataItemChanged(
  o: OrganizationState,
  e: CustomEvent,
  state: State,
  showNotification: (s: string) => void,
) {
  switch (e.detail.dataType) {
    case 'patientData':
      await patientDataItemChanged(o, e, state, showNotification);
      return;
    case 'managementData':
      await managementDataItemChanged(o, e, state, showNotification);
      return;
    case 'publicInformationAndCommunication':
      await publicInformationAndCommunicationItemChanged(o, e, state, showNotification);
      return;
    default:
      throw new Error('Illegal state (E05), unexpected datatype ' + e.detail.dataType);
  }
}
