/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';
import type { RelatedAssetViewModel } from './RelatedAssetViewModel';
import {
  RelatedAssetViewModelFromJSON,
  RelatedAssetViewModelFromJSONTyped,
  RelatedAssetViewModelToJSON,
} from './RelatedAssetViewModel';

/**
 *
 * @export
 * @interface EventOccurrenceViewModel
 */
export interface EventOccurrenceViewModel {
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  time?: string;
  /**
   *
   * @type {number}
   * @memberof EventOccurrenceViewModel
   */
  durationMinutes: number;
  /**
   *
   * @type {boolean}
   * @memberof EventOccurrenceViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  draftName?: string;
  /**
   *
   * @type {boolean}
   * @memberof EventOccurrenceViewModel
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EventOccurrenceViewModel
   */
  published: boolean;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  reminder?: EventOccurrenceViewModelReminderEnum;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  helpContent?: string;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  taskUuid?: string;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  functionUuid?: string;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  doneByEmployeeUuid?: string;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  doneByContactPersonUuid?: string;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  doneDate?: string;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof EventOccurrenceViewModel
   */
  notes: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EventOccurrenceViewModel
   */
  employees?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof EventOccurrenceViewModel
   */
  contacts?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof EventOccurrenceViewModel
   */
  persistent: boolean;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof EventOccurrenceViewModel
   */
  attachments?: Array<AttachmentViewModel>;
  /**
   *
   * @type {Array<RelatedAssetViewModel>}
   * @memberof EventOccurrenceViewModel
   */
  relatedAssets?: Array<RelatedAssetViewModel>;
}

/**
 * @export
 */
export const EventOccurrenceViewModelReminderEnum = {
  None: 'NONE',
  AtStart: 'AT_START',
  OneHourBefore: 'ONE_HOUR_BEFORE',
  TwoHoursBefore: 'TWO_HOURS_BEFORE',
  ThreeHoursBefore: 'THREE_HOURS_BEFORE',
  FiveHoursBefore: 'FIVE_HOURS_BEFORE',
} as const;
export type EventOccurrenceViewModelReminderEnum =
  (typeof EventOccurrenceViewModelReminderEnum)[keyof typeof EventOccurrenceViewModelReminderEnum];

/**
 * Check if a given object implements the EventOccurrenceViewModel interface.
 */
export function instanceOfEventOccurrenceViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'durationMinutes' in value;
  isInstance = isInstance && 'hasDraft' in value;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'published' in value;
  isInstance = isInstance && 'description' in value;
  isInstance = isInstance && 'notes' in value;
  isInstance = isInstance && 'persistent' in value;

  return isInstance;
}

export function EventOccurrenceViewModelFromJSON(json: any): EventOccurrenceViewModel {
  return EventOccurrenceViewModelFromJSONTyped(json, false);
}

export function EventOccurrenceViewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EventOccurrenceViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    date: !exists(json, 'date') ? undefined : json['date'],
    time: !exists(json, 'time') ? undefined : json['time'],
    durationMinutes: json['durationMinutes'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    isConfirmedEntity: json['isConfirmedEntity'],
    published: json['published'],
    reminder: !exists(json, 'reminder') ? undefined : json['reminder'],
    name: !exists(json, 'name') ? undefined : json['name'],
    helpContent: !exists(json, 'helpContent') ? undefined : json['helpContent'],
    taskUuid: !exists(json, 'taskUuid') ? undefined : json['taskUuid'],
    functionUuid: !exists(json, 'functionUuid') ? undefined : json['functionUuid'],
    doneByEmployeeUuid: !exists(json, 'doneByEmployeeUuid') ? undefined : json['doneByEmployeeUuid'],
    doneByContactPersonUuid: !exists(json, 'doneByContactPersonUuid') ? undefined : json['doneByContactPersonUuid'],
    doneDate: !exists(json, 'doneDate') ? undefined : json['doneDate'],
    description: json['description'],
    notes: json['notes'],
    employees: !exists(json, 'employees') ? undefined : json['employees'],
    contacts: !exists(json, 'contacts') ? undefined : json['contacts'],
    persistent: json['persistent'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
    relatedAssets: !exists(json, 'relatedAssets')
      ? undefined
      : (json['relatedAssets'] as Array<any>).map(RelatedAssetViewModelFromJSON),
  };
}

export function EventOccurrenceViewModelToJSON(value?: EventOccurrenceViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    date: value.date === undefined ? undefined : value.date,
    time: value.time,
    durationMinutes: value.durationMinutes,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    isConfirmedEntity: value.isConfirmedEntity,
    published: value.published,
    reminder: value.reminder,
    name: value.name,
    helpContent: value.helpContent,
    taskUuid: value.taskUuid,
    functionUuid: value.functionUuid,
    doneByEmployeeUuid: value.doneByEmployeeUuid,
    doneByContactPersonUuid: value.doneByContactPersonUuid,
    doneDate: value.doneDate === undefined ? undefined : value.doneDate,
    description: value.description,
    notes: value.notes,
    employees: value.employees,
    contacts: value.contacts,
    persistent: value.persistent,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
    relatedAssets:
      value.relatedAssets === undefined
        ? undefined
        : (value.relatedAssets as Array<any>).map(RelatedAssetViewModelToJSON),
  };
}
