import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-action.js';

export interface StepMenuItem {
  label: string;
  sublabel?: string;
  locked?: boolean;
  steps?: StepMenuItem[];
}

/**
 *
 *
 */
@customElement('d-step-menu')
export class DStepMenu extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      overflow: hidden;
      margin-top: -1px;
    }
    .wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-top: 1px solid var(--borderColorOnGray);
      border-bottom: 1px solid var(--borderColorOnGray);
      margin: 0 -8px;
      padding: 8px 0 10px 0;
    }
    .prev-next {
      flex-grow: 1;
      text-align: right;
    }
    .step-menu > div {
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
    }
    .step-menu > div + div {
      margin-top: 4px;
    }
    .step-menu > div > div {
      padding: 4px 8px 4px 8px;
      opacity: 0.5;
      cursor: pointer;
    }
    .step-menu > div > div:not([disabled]):hover {
      opacity: 1;
    }
    .step-menu > div > div[disabled] {
      opacity: 0.3;
      cursor: default;
    }
    .step-menu > div > div[selected] {
      opacity: 1;
    }
    .step-menu > div > div > span {
      font-weight: 200;
      font-size: 13px;
    }
    d-action:first-of-type {
      margin-left: -8px;
    }
  `;

  @property({ type: Array })
  currentPath: number[] = [];
  @property({ type: Array })
  steps: StepMenuItem[] = [];
  @property({ type: Array })
  nextStep: number[] | undefined = undefined;

  private get prevDisabled() {
    return this._getPrevStep(this.currentPath) === undefined;
  }

  private get nextDisabled() {
    return this._getNextStep(this.currentPath) === undefined;
  }

  _capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  _isValidPath(path) {
    if (path.length === 0) {
      return false;
    }
    let result = true;
    let steps: StepMenuItem[] | undefined = this.steps;
    path.forEach((p, index) => {
      if (steps && steps[p] && !steps[p].locked) {
        if (index < path.length - 1 && steps[p].steps) {
          steps = steps[p].steps;
        }
      } else {
        result = false;
      }
    });
    return result;
  }

  _stepContent(path) {
    let currentStep;
    let currentSteps = this.steps;
    path.forEach((p) => {
      currentStep = currentSteps[p];
      currentSteps = currentStep?.steps;
    });
    return currentStep;
  }

  _increaseLast(path: number[]): number[] {
    return path.map((p, index) => {
      if (index === path.length - 1) {
        return p + 1;
      }
      return p;
    });
  }

  _decreaseLast(path: number[]): number[] {
    return path.map((p, index) => {
      if (index === path.length - 1) {
        return p - 1;
      }
      return p;
    });
  }

  _addLastSubstep(path) {
    const content = this._stepContent(path);
    if (content?.steps?.length) {
      path.push(content.steps.length - 1);
    }
    return path;
  }

  _addFirstSubstep(path) {
    const content = this._stepContent(path);
    if (content?.steps?.length) {
      path.push(0);
    }
    return path;
  }

  _getPrevStep(path) {
    if (path[path.length - 1] > 0) {
      path = this._decreaseLast(path);
      if (this._stepContent(path)) {
        path = this._addLastSubstep(path);
      }
    } else if (path.length > 1) {
      path = path.slice(0, -1);
      return this._getPrevStep(path);
    } else {
      return undefined;
    }
    return path;
  }

  _getNextStep(path) {
    const nextStep = this._increaseLast(path);
    if (this._isValidPath(nextStep)) {
      return nextStep;
    } else {
      path = path.slice(0, -1);
      if (this._isValidPath(this._increaseLast(path))) {
        const nextStepContent = this._stepContent(this._increaseLast(path));
        if (nextStepContent.steps?.length) {
          path = this._increaseLast(path);
          path.push(0);
          path = this._addFirstSubstep(path);
          return path;
        } else {
          return this._getNextStep(path);
        }
      }
      return undefined;
    }
  }

  _dispatch() {
    this.dispatchEvent(
      new CustomEvent('path-changed', {
        bubbles: true,
        composed: true,
        detail: this.currentPath,
      }),
    );
  }

  _prev(e) {
    if (!e.target.disabled) {
      const prevStep = this._getPrevStep(this.currentPath);
      if (prevStep) {
        this.currentPath = prevStep;
        this._dispatch();
      }
    }
  }

  _next(e) {
    if (!e.target.disabled) {
      const nextStep = this._getNextStep(this.currentPath);
      if (nextStep) {
        this.currentPath = nextStep;
        this._dispatch();
      }
    }
  }

  _stepPath(level, index) {
    let path: number[] = [];
    if (level === 0) {
      path = [index];
      path = this._addFirstSubstep(path);
      path = this._addFirstSubstep(path);
    }
    if (level === 1) {
      path = [this.currentPath[0], index];
      path = this._addFirstSubstep(path);
    }
    if (level === 2) {
      path = [this.currentPath[0], this.currentPath[1], index];
    }
    return path;
  }

  _clickMenu(path) {
    if (this._isValidPath(path)) {
      this.currentPath = path;
      this._dispatch();
    }
  }

  renderLevels(level, steps) {
    let nextLevel = null;
    let nextStep = null;
    if (steps[this.currentPath[level]]) {
      nextLevel = level + 1;
      nextStep = steps[this.currentPath[level]].steps;
    }
    return html`
      <div>
        ${steps.map((step, index) => {
          return html`
            <div
              ?disabled=${!this._isValidPath(this._stepPath(level, index))}
              ?selected=${this.currentPath[level] === index}
              @click=${() => this._clickMenu(this._stepPath(level, index))}
            >
              ${step.label}
              <span>${step.sublabel}</span>
            </div>
          `;
        })}
      </div>
      ${nextLevel
        ? html`
            ${this.currentPath.length > nextLevel && nextStep
              ? html` ${this.renderLevels(nextLevel, nextStep)} `
              : nothing}
          `
        : nothing}
    `;
  }

  render() {
    return html`
      <div class="wrapper">
        <div class="step-menu">${this.renderLevels(0, this.steps)}</div>
        <div class="prev-next">
          <d-action @click=${(e) => this._prev(e)} ?disabled=${this.prevDisabled}>Forrige</d-action>
          <d-action @click=${(e) => this._next(e)} ?disabled=${this.nextDisabled}>Neste</d-action>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-step-menu': DStepMenu;
  }
}
