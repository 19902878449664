import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../library/editors/components/d-select-dropdown-or-add.js';
import type { InfosecDataItem } from 'src/pages/computers-page/infosec-procedure/defaults.js';
import { uuid } from 'src/utilities/text.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { NewNameDialog, NewNameResult } from 'src/library/editors/components/new-name-dialog.js';

/**
 *
 * Represents an external data processor. This is data stored and "owned" outside this organization, currently used for
 * accounting data managed by the external accountant.
 *
 * This is a variant of the d-edit-data-item-accounting.
 *
 * @fires data-item-changed -  a data processor is selected
 * @fires new-partner when a new partner is used. Fires before the data-item-changed event.
 *
 *
 *
 */
@customElement('d-edit-data-item-data-processor')
export class DEditDataItemDataProcessor extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;

  /**
   * The label for this data processor
   */
  @property({ type: String })
  label = '';

  /**
   * light label display property
   */
  @property({ type: Boolean, attribute: 'light-label' })
  lightLabel = false;

  /**
   * small label display property
   */
  @property({ type: Boolean, attribute: 'small-label' })
  smallLabel = false;

  /**
   * The data item. Either an existing accounting data item or an "empty" new item
   */
  @property({ type: Object })
  dataItem!: InfosecDataItem;

  /**
   * The available partners.
   */
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];

  async _valueChanged(value) {
    if (value === 'NEW') {
      const result: NewNameResult = await NewNameDialog.open({ title: 'Ny samarbeidspartner' });
      if (result.action === 'done') {
        const partnerUuid = uuid();
        this.partners = [
          ...this.partners,
          {
            value: partnerUuid,
            text: result.name,
          },
        ];
        this.dataItem = {
          ...this.dataItem,
          dataProcessor: partnerUuid,
          uuid: this.dataItem.uuid || uuid(),
        };
        this.dispatchEvent(
          new CustomEvent<{ uuid: string; name: string }>('new-partner', {
            composed: true,
            bubbles: true,
            detail: { uuid: partnerUuid, name: result.name },
          }),
        );
        this._dispatchPartnersChanged();
        this._dispatchDataItemChanged();
      }
    } else {
      if (this.dataItem.uuid === '') {
        this.dataItem.uuid = uuid();
      }
      this.dataItem.dataProcessor = value;
      this._dispatchDataItemChanged();
    }
  }

  _dispatchPartnersChanged() {
    this.dispatchEvent(
      new CustomEvent('partners-changed', {
        bubbles: true,
        composed: true,
        detail: this.partners,
      }),
    );
  }

  _dispatchDataItemChanged() {
    this.dispatchEvent(
      new CustomEvent('data-item-changed', {
        bubbles: true,
        composed: true,
        detail: this.dataItem,
      }),
    );
  }

  render() {
    return html`
      <d-select-dropdown-or-add
        theme-page
        ?light-label=${this.lightLabel}
        ?small-label=${this.smallLabel}
        .label=${this.label}
        unselectablePlaceholder
        placeholder="Velg samarbeidspartner"
        firstItemText="Registrer samarbeidspartner"
        additionalItemText="Annen samarbeidspartner"
        .options=${this.partners}
        .value=${this.dataItem.dataProcessor}
        @value-changed=${(e) => this._valueChanged(e.detail.value)}
      ></d-select-dropdown-or-add>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-data-item-data-processor': DEditDataItemDataProcessor;
  }
}
