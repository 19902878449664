import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../library/editors/elements/d-radio-cell.js';

export interface RotationWeekEmployee {
  uuid: string;
  name: string;
  rotationStartDate: string;
  i1: boolean;
  i2: boolean;
  i3: boolean;
  i4: boolean;
  i5: boolean;
  i6: boolean;
  i7: boolean;
}

/**
 *
 *
 * STATUS OK
 */
@customElement('d-edit-rotation-week')
export class DEditRotationWeek extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    table {
      width: 100%;
    }

    table.rotationEdit td {
      height: 34px;
      border-bottom: 1px solid rgb(239, 239, 239);
    }

    table.rotationEdit td.day,
    table.rotationEdit td.radioCell {
      text-align: center;
    }

    table.rotationEdit label {
      top: auto;
      margin: 0;
    }

    thead td {
      vertical-align: bottom;
      padding-bottom: 4px;
    }

    td.day,
    td.radioCell {
      width: 34px;
    }

    d-radio-cell {
      margin-left: 5px;
    }

    @media only screen and (max-width: 600px) {
      tbody td {
        padding: 6px 0;
      }

      td.day,
      td.radioCell {
        width: 30px;
      }

      d-radio-cell {
        margin-left: 3px;
      }
    }

    .maxWidth600 tbody td {
      padding: 6px 0;
    }

    .maxWidth600 td.day,
    .maxWidth600 td.radioCell {
      width: 30px;
    }
  `;
  @property({ type: String })
  dateDisplay = '';
  @property({ type: String })
  weekDisplay = '';
  @property({ type: Array })
  employees: RotationWeekEmployee[] = [];

  _onChange(e: CustomEvent<{ checked: boolean }>, employee: RotationWeekEmployee, index: number) {
    if (e.detail.checked) {
      const dayKey = 'i' + (index + 1);
      const uuid = employee.uuid;
      this.employees = this.employees.map((employee) => {
        return { ...employee, [dayKey]: employee.uuid === uuid };
      });
      this.fireChangedEvent();
    }
  }

  render() {
    return html`
      <table
        border="0"
        cellpadding="0"
        cellspacing="0"
        class="rotationEdit deselectableRadioButtons"
        role="presentation"
      >
        <thead>
          <tr>
            <td>
              <strong>${this.weekDisplay}</strong>
              <span class="hideOnNarrowScreen">${this.dateDisplay}</span>
            </td>
            <td class="day">M</td>
            <td class="day">T</td>
            <td class="day">O</td>
            <td class="day">T</td>
            <td class="day">F</td>
            <td class="day">L</td>
            <td class="day">S</td>
          </tr>
        </thead>
        <tbody>
          ${this.employees.map(
            (employee) =>
              html` <tr>
                <td>${employee.name}</td>

                <td class="radioCell">
                  <d-radio-cell
                    ?checked="${employee.i1}"
                    name="i1"
                    @checked-changed=${(e) => this._onChange(e, employee, 0)}
                  ></d-radio-cell>
                </td>
                <td class="radioCell">
                  <d-radio-cell
                    ?checked="${employee.i2}"
                    name="i2"
                    @checked-changed=${(e) => this._onChange(e, employee, 1)}
                  ></d-radio-cell>
                </td>
                <td class="radioCell">
                  <d-radio-cell
                    ?checked="${employee.i3}"
                    name="i3"
                    @checked-changed=${(e) => this._onChange(e, employee, 2)}
                  ></d-radio-cell>
                </td>
                <td class="radioCell">
                  <d-radio-cell
                    ?checked="${employee.i4}"
                    name="i4"
                    @checked-changed=${(e) => this._onChange(e, employee, 3)}
                  ></d-radio-cell>
                </td>
                <td class="radioCell">
                  <d-radio-cell
                    ?checked="${employee.i5}"
                    name="i5"
                    @checked-changed=${(e) => this._onChange(e, employee, 4)}
                  ></d-radio-cell>
                </td>
                <td class="radioCell">
                  <d-radio-cell
                    ?checked="${employee.i6}"
                    name="i6"
                    @checked-changed=${(e) => this._onChange(e, employee, 5)}
                  ></d-radio-cell>
                </td>
                <td class="radioCell">
                  <d-radio-cell
                    ?checked="${employee.i7}"
                    name="i7"
                    @checked-changed=${(e) => this._onChange(e, employee, 6)}
                  ></d-radio-cell>
                </td>
              </tr>`,
          )}
        </tbody>
      </table>
    `;
  }

  private fireChangedEvent() {
    this.dispatchEvent(
      new CustomEvent<{ employees: RotationWeekEmployee[] }>('rotation-week-changed', {
        composed: true,
        bubbles: true,
        detail: { employees: this.employees },
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-rotation-week': DEditRotationWeek;
  }
}
