import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../library/fields/d-view-info.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

/**
 *
 */
@customElement('d-infosec-view-header')
export class DInfosecViewHeader extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
        margin: -1px 0;
        border-top: 1px solid var(--borderColorOnGray);
        border-bottom: 1px solid var(--borderColorOnGray);
        padding: 12px 0;
      }
      h2 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 22px;
      }
      h3 {
        margin-top: 8px;
        margin-bottom: 6px;
        font-size: 18px;
        font-weight: 500;
      }
      .lighter {
        font-weight: 200;
      }
    `,
  ];
  @property({ type: String })
  title = '';
  @property({ type: String })
  subtitle = '';
  @property({ type: String })
  subtitleSecondary = '';
  @property({ type: String })
  info = '';
  render() {
    return html`
      <h2>${this.title}</h2>
      ${this.subtitle
        ? html`
            <h3>
              ${unsafeHTML(this.subtitle)}
              ${this.subtitleSecondary ? html` <span class="lighter"> ${this.subtitleSecondary} </span> ` : nothing}
            </h3>
          `
        : nothing}
      <d-view-info .content=${this.info}></d-view-info>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-infosec-view-header': DInfosecViewHeader;
  }
}
