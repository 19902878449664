interface ProfessionInformation {
  name: string;
  defaultFunction?: string;
  defaultFunctionTemplateId?: number;
  specialities?: string[];
}

const professionInformationList: ProfessionInformation[] = [
  { name: 'Akupunktør' },
  { name: 'Assistent' },
  { name: 'Audiograf' },
  { name: 'Bioingeniør' },
  { name: 'Ergoterapeut' },
  { name: 'Ernæringsfysiolog' },
  {
    name: 'Fysioterapeut',
    defaultFunction: 'Fysioterapeut',
    defaultFunctionTemplateId: 3784,
    specialities: [
      'Ingen',
      'Allmenn fysioterapi',
      'Barne- og ungdomsfysioterapi',
      'Helse- og miljøarbeid',
      'Idrettsfysioterapi',
      'Manuellterapi',
      'Onkologisk fysioterapi',
      'Psykomotorisk fysioterapi',
      'Psykiatrisk og psykosomatisk fysioterapi',
      'Geriatrisk fysioterapi',
      'Hjerte- og lungefysioterapi',
      'Nevrologisk fysioterapi',
      'Ortopedisk fysioterapi',
      'Obstetrisk og gynekologisk fysioterapi',
      'Revmatologisk fysioterapi',
    ],
  },
  { name: 'Helsefagarbeider' },
  { name: 'Helsesekretær' },
  { name: 'Helsesykepleier' },
  { name: 'Helsesøster' },
  { name: 'Hjelpepleier' },
  { name: 'Instruktør' },
  { name: 'Jordmor' },
  {
    name: 'Kiropraktor',
    defaultFunction: 'Kiropraktor',
    defaultFunctionTemplateId: 3775,
  },
  {
    name: 'Naprapat',
    defaultFunction: 'Naprapat',
    defaultFunctionTemplateId: 11281,
  },
  { name: 'Kontormedarbeider' },
  {
    name: 'Lege',
    defaultFunction: 'Lege',
    defaultFunctionTemplateId: 3765,
    specialities: [
      'Ingen',
      'Allmennmedisin',
      'Anestesiologi',
      'Arbeidsmedisin',
      'Barne- og ungdomspsykiatri',
      'Barnekirurgi',
      'Barnesykdommer',
      'Blodsykdommer',
      'Bryst- og endokrinkirurgi',
      'Endokrinologi',
      'Fordøyelsessykdommer',
      'Fysikalsk medisin og rehabilitering',
      'Fødselshjelp og kvinnesykdommer',
      'Gastroenterologisk kirurgi',
      'Generell kirurgi',
      'Geriatri',
      'Hjertesykdommer',
      'Hud- og veneriske sykdommer',
      'Immunologi og transfusjonsmedisin',
      'Indremedisin',
      'Infeksjonssykdommer',
      'Karkirurgi',
      'Klinisk farmakologi',
      'Klinisk nevrofysiologi',
      'Lungesykdommer',
      'Maxillofacial kirurgi',
      'Medisinsk biokjemi',
      'Medisinsk genetikk',
      'Medisinsk mikrobiologi',
      'Nevrokirurgi',
      'Nevrologi',
      'Nukleærmedisin',
      'Nyresykdommer',
      'Onkologi',
      'Ortopedisk kirurgi',
      'Patologi',
      'Plastikkirurgi',
      'Psykiatri',
      'Radiologi',
      'Revmatologi',
      'Rus- og avhengighetsmedisin',
      'Samfunnsmedisin',
      'Thoraxkirurgi',
      'Urologi',
      'Øre-nese-halssykdommer',
      'Øyesykdommer',
    ],
  },
  {
    name: 'LIS 1',
    defaultFunction: 'Lege',
    defaultFunctionTemplateId: 3765,
  },
  { name: 'Muskelterapeut' },
  {
    name: 'Osteopat',
    defaultFunction: 'Osteopat',
    defaultFunctionTemplateId: 10786,
  },
  { name: 'Optiker' },
  {
    name: 'Psykolog',
    defaultFunction: 'Psykolog',
    defaultFunctionTemplateId: 3769,
    specialities: [
      'Ingen',
      'Barne- og ungdomspsykologi',
      'Arbeidspsykologi',
      'Eldrepsykologi',
      'Organisasjonspsykologi',
      'Voksenpsykologi',
      'Habiliteringspsykologi',
      'Familiepsykologi',
      'Nevropsykologi',
      'Psykoterapi',
      'Rus- og avhengighetspsykologi',
      'Samfunns- og allmennpsykologi',
    ],
  },
  { name: 'Rengjører' },
  { name: 'Sekretær' },
  { name: 'Student' },
  { name: 'Sykepleier' },
  {
    name: 'Tannhelsesekretær',
    defaultFunction: 'Tannbehandlingsassistent',
    defaultFunctionTemplateId: 7079,
  },
  {
    name: 'Tannlege',
    defaultFunction: 'Tannlege',
    defaultFunctionTemplateId: 3777,
  },
  {
    name: 'Tannpleier',
    defaultFunction: 'Tannpleier',
    defaultFunctionTemplateId: 7063,
  },
  { name: 'Trener' },
  { name: 'Turnus' },
  {
    name: 'Turnuslege',
    defaultFunction: 'Lege',
    defaultFunctionTemplateId: 3765,
  },
  { name: 'Vernepleier' },
];

export const professionsInformationMap: Map<string, ProfessionInformation> = new Map(
  professionInformationList.map((i) => [i.name, i]),
);

export function specialitiesForProfession(p: string): string[] {
  const v = professionsInformationMap.get(p);
  return v?.specialities ?? [];
}

export function professionHasSpeciality(p: string): boolean {
  return specialitiesForProfession(p).length > 0;
}

function normalizeProfessions(list: ProfessionInformation[]): string[] {
  const v = list.map((i) => i.name);
  v.sort();
  v.push('Annet');
  return v;
}

export const professions: string[] = normalizeProfessions(professionInformationList);
