import { css, html, LitElement } from 'lit';

import '../editors/elements/d-checkbox.js';
import { customElement, property } from 'lit/decorators.js';
import '../fields/d-expansion.js';
import type { ActivityCodeCommon } from 'src/store';
import _ from 'lodash';

/**
 *
 * STATUS OK
 */
@customElement('d-common-activity-codes')
export class DCommonActivityCodes extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    d-checkbox {
      margin-bottom: 4px;
    }

    div {
      padding-left: 30px;
    }
  `;
  @property({ type: Array })
  activityCodes: string[] = [];
  @property({ type: Array })
  commonActivityCodes: ActivityCodeCommon[] = [];
  @property({ type: Boolean, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean, reflect: true })
  outskirts = false;

  _active(activityCodes, codes) {
    if (activityCodes !== undefined && codes !== undefined) {
      for (const item of codes) {
        if (activityCodes.indexOf(item) < 0) {
          return false;
        }
      }
    }
    return true;
  }

  flatActiveCommonCodes() {
    const result: ActivityCodeCommon[] = [];

    this.commonActivityCodes.forEach((l1) => {
      if (this._active(this.activityCodes, l1.code)) {
        result.push(l1);
        (l1.codes ?? []).forEach((l2) => {
          if (this._active(this.activityCodes, l2.code)) {
            result.push(l2);
            (l2.codes ?? []).forEach((l3) => {
              if (this._active(this.activityCodes, l3.code)) {
                result.push(l3);
              }
            });
          }
        });
      }
    });
    return result;
  }

  render() {
    return html`
      ${this.commonActivityCodes.map(
        (level1) =>
          html` <d-checkbox
              @checked-changed=${(e) => this.onCheckedChanged(e, level1.code)}
              ?checked=${this._active(this.activityCodes, level1.code)}
              .optionLabel=${level1.name}
              ?theme-page=${this.themePage}
              ?outskirts=${this.outskirts}
            ></d-checkbox>

            <d-expansion ?opened=${this._active(this.activityCodes, level1.code)}>
              <div class="collapse-content">
                ${(level1.codes ?? []).map(
                  (level2) =>
                    html`<d-checkbox
                        @checked-changed=${(e) => this.onCheckedChanged(e, level2.code)}
                        ?checked=${this._active(this.activityCodes, level2.code)}
                        .optionLabel=${level2.name}
                        ?theme-page=${this.themePage}
                        ?outskirts=${this.outskirts}
                      ></d-checkbox>

                      <d-expansion ?opened=${this._active(this.activityCodes, level2.code)}>
                        <div>
                          ${(level2.codes ?? []).map(
                            (level3) =>
                              html`<d-checkbox
                                @checked-changed=${(e) => this.onCheckedChanged(e, level3.code)}
                                ?checked=${this._active(this.activityCodes, level3.code)}
                                .optionLabel=${level3.name}
                                ?theme-page=${this.themePage}
                                ?outskirts=${this.outskirts}
                              ></d-checkbox>`,
                          )}
                        </div>
                      </d-expansion>`,
                )}
              </div>
            </d-expansion>`,
      )}
    `;
  }

  private removeItemAndChildren(f: ActivityCodeCommon[], i: ActivityCodeCommon) {
    let list = [...f];
    list = list.filter((x) => !_.isEqual(x.code, i.code));

    for (const childItem of i.codes ?? []) {
      list = this.removeItemAndChildren(list, childItem);
    }

    return list;
  }

  private removeCodes(codes: string[]) {
    const f = this.flatActiveCommonCodes();
    const itemToRemove = f.find((x) => _.isEqual(x.code, codes));
    if (itemToRemove === undefined) {
      throw new Error('Illegal state (E071), item to remove not found for codes ' + JSON.stringify(codes));
    }
    const n = this.removeItemAndChildren(f, itemToRemove);

    return _.uniq(n.flatMap((x) => x.code));
  }
  private addCodes(codes: string[]) {
    const targetCodes = codes.filter((item) => this.activityCodes.indexOf(item) < 0);
    return [...this.activityCodes, ...targetCodes];
  }

  private onCheckedChanged(e: CustomEvent<{ checked: boolean }>, codes: string[]) {
    e.preventDefault();
    e.stopPropagation();
    this.activityCodes = e.detail.checked ? this.addCodes(codes) : this.removeCodes(codes);
    this.dispatchEvent(
      new CustomEvent<{ codes: string[] }>('activity-codes-changed', {
        bubbles: true,
        composed: true,
        detail: {
          codes: this.activityCodes,
        },
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-common-activity-codes': DCommonActivityCodes;
  }
}
