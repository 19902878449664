import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { LocalDate } from 'src/utilities/local-date.js';

import '../../library/elements/d-label.js';
import '../../library/fields/d-expansion.js';

/**
 *
 */
@customElement('d-sickness-absence-statistics-periods')
export class DSicknessAbsenceStatisticsPeriods extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }

    .periodGroupHeader {
      display: flex;
      margin-top: -1px;
      padding: 8px 0;
      border-top: 1px solid var(--borderColorOnGray);
      border-bottom: 1px solid var(--borderColorOnGray);
    }

    .periodGroupHeader d-label {
      font-weight: 500;
    }

    *:last-child {
      margin-bottom: -11px;
    }

    .periods {
      padding-bottom: 0;
    }

    .col-1 {
      flex: 1;
      min-width: 130px;
      margin-right: 10px;
    }

    .col-2 {
      display: flex;
      min-width: 200px;
      flex: none;
      margin-right: 10px;
    }

    .col-3 {
      width: 170px;
      flex: 1;
    }

    .col-2-1 {
      flex-shrink: 0;
      flex-grow: 1;
      width: 75px;
      min-width: 75px;
    }

    .col-2-2 {
      flex-shrink: 0;
      flex-grow: 1;
      width: 75px;
      min-width: 75px;
    }

    .periods {
      padding-bottom: 0;
    }
  `;
  @property({ type: String })
  label = '';
  @property({ type: Boolean })
  showPeriods = false;
  @property({ type: Array })
  periods!: { start: string; employeeName: string; absenceDays: number }[];

  render() {
    return html`
      <div class="periodGroupHeader">
        <div class="col-1">
          <d-label label="${this.label}"></d-label>
        </div>
        <div class="col-2 row flexChildren spaceChildren20">
          <div class="col-2-1">${this.periodDays()}</div>
          <div class="col-2-2">${this.periodsCount(this.periods.length)}</div>
        </div>
      </div>

      <d-expansion ?opened=${this.showPeriods && this.periods.length > 0}>
        <div class="periods">
          ${this.periods.map(
            (period) =>
              html`<div class="periodRow" data-day="${period.start}" @click=${(e) => this.periodTap(e, period.start)}>
                <div class="col-1" data-day="${period.start}">${period.employeeName}</div>
                <div class="col-2" data-day="${period.start}">
                  <div class="col-2-1" data-day="${period.start}">${this.roundDays(period.absenceDays)}</div>
                  <div class="col-2-2" data-day="${period.start}">${this.formatDate(period.start)}</div>
                </div>
              </div>`,
          )}
        </div>
      </d-expansion>
    `;
  }

  private roundDays(n) {
    const days = Math.round(n * 100) / 100;
    if (!days) {
      return 'Ingen';
    }
    if (days === 1) {
      return days + ' dag';
    }
    return days + ' dager';
  }

  private formatDate(date) {
    return LocalDate.fromString(date).toStringForDisplay();
  }

  private periodDays() {
    const absenceDays = this.periods
      .map((p) => p.absenceDays)
      .reduce((accumulator, absenceDays) => {
        return accumulator + absenceDays;
      }, 0);

    const days = Math.round(absenceDays * 100) / 100;
    if (!days) {
      return 'Ingen';
    }
    if (days === 1) {
      return days + ' dag';
    }
    return days + ' dager';
  }

  private periodsCount(count: number): string {
    if (count === 0) {
      return '';
    }
    if (count === 1) {
      return count + ' periode';
    }
    return count + ' perioder';
  }

  private periodTap(e: Event, start: string) {
    e.stopPropagation();
    this.dispatchEvent(new CustomEvent<string>('period-tap', { bubbles: true, composed: true, detail: start }));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-sickness-absence-statistics-periods': DSicknessAbsenceStatisticsPeriods;
  }
}
