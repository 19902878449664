import { fetchDraft, toAttachmentItems } from 'src/models/factory-utilities';
import { BASE_PATH, FunctionViewModel, MeetingOccurrenceViewModel, ReportViewModel } from 'src/store/api';
import {
  ContactsAsUsers,
  currentEmployeeShortName,
  currentEmployeeUuid,
  currentUserUuid,
  employeesDeleted,
  employeesNotDeleted,
  employeesShortNamesAndI,
  functionsNotDeleted,
  getContactsAsUsers,
  getOrganization,
  isRestricted,
  key,
  MeetingModel,
  meetings,
  meetingsNotDeleted,
  partnersNotDeleted,
  reportsNotDeleted,
  ShortNameEntry,
  singleFunctionsWithEmployeeNames,
  writeAccess,
} from 'src/store/selectors';
import type { OrganizationState, State } from 'src/store/types';
import { LocalDate } from 'src/utilities/local-date';
import {
  MeetingOccurrenceView,
  MeetingOccurrenceViewEditItem,
  ModelMeeting,
} from 'src/content/meeting-occurrences/d-meeting-occurrence-view';
import {
  reminderText,
  dateTimeDescription,
  toDateTime,
  meetingParticipantsText,
  functionNameWithAssignedNames,
} from 'src/store/utilities';
import { findLast, sortBy } from 'lodash';
import { capitalize, displayName, isEmptyOrInvalidString } from 'src/utilities/text';
import { assertIsDefined } from 'src/lib';
import { Occurrence } from 'src/library/lists/d-occurrences-list';
import { getUsersForAccess } from 'src/models/application-view-model';
import { getFeatureStates } from 'src/store/selectors/features';
import { MessageRecipient } from 'src/content/event-occurrences/d-event-occurrence-view';
import { CommonDataEntityView } from 'src/content/entity-content';

function messageSentText(lastSent: string | undefined) {
  if (lastSent) {
    return (
      LocalDate.fromString(lastSent.substring(0, 10)).toStringForDisplayWithDayOfWeekAndYear() +
      ' kl. ' +
      lastSent.substring(11, 16).replace(':', '.')
    );
  }

  return '';
}
function getFutureOccurrences(
  occurrences: MeetingOccurrenceViewModel[],
  functions: FunctionViewModel[],
  employeesShortNamesAndI: ShortNameEntry[],
  contactsAsUsers: ContactsAsUsers[],
): Occurrence[] {
  const today = LocalDate.now();
  return occurrences
    .filter((o) => {
      return LocalDate.fromString(o.date).isAfter(today);
    })
    .map((o) => {
      return {
        occurrenceUuid: o.uuid,
        href: '',
        classified: o.classification && o.classification !== 'NONE',
        restricted: isRestricted(o, currentEmployeeUuid),
        disabled: false,
        dateTime: o.date + ' ' + o.time,
        durationMinutes: o.durationMinutes,
        displayDate: dateTimeDescription(o.date ?? '', o.time ?? '', o.durationMinutes),
        assignees: meetingParticipantsText(o, functions, employeesShortNamesAndI, contactsAsUsers, true),
        meetingStatus: 'NONE',
      };
    });
}

function listModelMeetings(
  meetingModels: MeetingModel[],
  meetings,
  organization: OrganizationState,
  functions: FunctionViewModel[],
  employeesShortNamesAndI: ShortNameEntry[],
  contactsAsUsers: ContactsAsUsers[],
): ModelMeeting[] {
  return meetingModels.map((m) => {
    const accessibleOccurrences = m.meetingOccurrences.filter((e) => !e.restricted);
    let name = displayName(m.name);
    let isEmployeeInterview = false;
    if (m.type === 'INTERVIEW_ACTIVE' || m.type === 'INTERVIEW_TERMINATED') {
      name = 'Medarbeidersamtale med ' + m.interviewEmployeeName;
      isEmployeeInterview = true;
    }
    let meetingResponsible = '';
    let agenda = '';
    const meetingTemplate = meetings.find((t) => {
      return t.name === m.name;
    });
    if (meetingTemplate) {
      meetingResponsible = meetingTemplate.functionUuid || '';
      agenda = meetingTemplate.agenda;
    }
    if (accessibleOccurrences.length === 0) {
      return {
        name,
        isEmployeeInterview,
        meetingResponsible,
        meetingResponsibleEmployee: '',
        employees: isEmployeeInterview ? [m.interviewEmployeeUuid] : [],
        contacts: [],
        notes: '',
        agenda,
        meetingUuid: m.uuid,
        classification: 'NONE',
        accessControl: [],
        time: '12:00',
        durationMinutes: 0,
        futureOccurrences: [],
      };
    } else {
      const today = LocalDate.now().toString();
      const sortedOccurrences = sortBy(accessibleOccurrences, [(e) => e.date]);
      const modelOccurrence = findLast(sortedOccurrences, (e) => e.date <= today) ?? sortedOccurrences[0];
      const x = organization.meetingOccurrences.find((e) => e.uuid === modelOccurrence.uuid);
      assertIsDefined(x);
      const occurrences = organization.meetingOccurrences.filter((e) => {
        if (e.name === 'Medarbeidersamtale') {
          return e.name === x.name && e.participants === x.participants;
        }
        return e.name === x.name;
      });
      const futureOccurrences = getFutureOccurrences(occurrences, functions, employeesShortNamesAndI, contactsAsUsers);
      return {
        name,
        isEmployeeInterview,
        meetingResponsible: x.responsibleUuid || '',
        meetingResponsibleEmployee: x.responsibleEmployeeUuid || '',
        employees: x.participants,
        contacts: x.externalParticipants,
        notes: '',
        agenda: x.agenda,
        meetingUuid: m.uuid || x.meetingUuid,
        classification: x.classification,
        accessControl: x.accessControl,
        time: x.time || 'NONE',
        durationMinutes: x.durationMinutes,
        futureOccurrences: sortBy(futureOccurrences, ['dateTime']),
      };
    }
  });
}

export function getMeetingMessageRecipients(
  state: State,
  responsibleEmployeeUuid: string,
  selectedEmployees: string[] = [],
  selectedContacts: string[] = [],
): MessageRecipient[] {
  const employees: MessageRecipient[] = employeesNotDeleted(state)
    .filter((p) => selectedEmployees.includes(p.uuid) || p.uuid === responsibleEmployeeUuid)
    .map((p) => {
      return {
        uuid: p.uuid,
        name: p.name,
        email: p.email,
        accessLevel: p.accessLevel?.toString() ?? 'NONE',
      };
    });
  const contacts: MessageRecipient[] = getContactsAsUsers(state)
    .filter((p) => selectedContacts.includes(p.uuid))
    .map((p) => {
      return {
        uuid: p.uuid,
        name: p.name + ' (' + p.partnerName + ')',
        email: p.email,
        accessLevel: p.accessLevel?.toString() ?? 'NONE',
      };
    });
  return [...employees, ...contacts];
}

export function buildMeetingOccurrenceView(
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): MeetingOccurrenceView {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const currentEmployeeUuid1 = currentEmployeeUuid(state);

  const meetingOccurrence = organization.meetingOccurrences.find((o) => o.uuid === uuid);

  assertIsDefined(meetingOccurrence);

  const meetingModels = meetings(state);

  const meetingModel = meetingModels.find((x) => x.meetingOccurrences.map((o) => o.uuid).includes(uuid));

  assertIsDefined(meetingModel);

  const entity = meetingOccurrence;

  const relatedMeeting = meetingsNotDeleted(state).find((m) => {
    return m.name === entity.name;
  });

  const singleFunctionsWithEmployeeNames1 = singleFunctionsWithEmployeeNames(state).map((p) => ({
    value: p.uuid,
    text: p.name,
  }));
  const meetingResponsibleFunction = organization.functionsById[entity.responsibleUuid ?? ''];
  const meetingResponsibleFunctionEmployeeUuid = meetingResponsibleFunction?.employees[0];

  const relatedReport: ReportViewModel | undefined = reportsNotDeleted(state).find(
    (report) => report.meetingOccurrenceUuid === uuid,
  );

  let meetingReportIsClassified = false;
  if (relatedReport && relatedReport.classification && relatedReport.classification !== 'NONE') {
    meetingReportIsClassified = true;
  }

  const localDate = LocalDate.fromString(entity.date);
  const canUpdateFutureOccurrences =
    entity.isConfirmedEntity &&
    meetingModel.meetingOccurrences.filter((e) => LocalDate.fromString(e.date).isAfter(localDate)).length > 0;

  const allEmployees = employeesDeleted(state).concat(employeesNotDeleted(state));
  const employeeFullNames = allEmployees.map((e) => {
    return { uuid: e.uuid, name: e.name };
  });

  let meetingResponsibleDisplayText: string;
  if (entity.responsibleUuid) {
    meetingResponsibleDisplayText = functionNameWithAssignedNames(
      entity.responsibleUuid,
      functionsNotDeleted(state),
      employeesShortNamesAndI(state),
    );
  } else {
    meetingResponsibleDisplayText =
      employeesShortNamesAndI(state).find((e) => {
        return e.uuid === entity['responsibleEmployeeUuid'];
      })?.name ?? '';
  }

  const availablePartners = partnersNotDeleted(state)
    .filter((p) => {
      return p.templateId === null || !p.contentLastModifiedWasCreate;
    })
    .map((p) => {
      return { value: p.uuid, text: p.name };
    });

  let meetingResponsibleFunctionUuid = '';
  if (getFeatureStates(state).core) {
    meetingResponsibleFunctionUuid = entity.responsibleUuid ?? '';
  }

  return {
    ...commonData,
    agendaDescriptionLabel: '',
    hasReport: relatedReport !== undefined,
    meetingReportWrittenLabel: relatedReport ? 'Referat skrevet' : 'Referat ikke skrevet',
    meetingReportWrittenUuid: relatedReport?.uuid,
    meetingReportWrittenText: relatedReport
      ? LocalDate.fromString(relatedReport.reportDate).toStringForDisplayWithDayOfWeekAndYear()
      : 'Referat ikke skrevet',
    meetingReportIsClassified,
    meetingReportContent: relatedReport?.content,
    meetingResponsibleDisplayText: capitalize(meetingResponsibleDisplayText),
    noticeSent: entity.noticeLastSent !== undefined && entity.noticeLastSent !== null && entity.noticeLastSent !== '',
    noticeSentText: messageSentText(entity.noticeLastSent),
    reportSent: messageSentText(entity.reportLastSent),
    currentUserHasWriteAccess: writeAccess(state),
    isConfirmedEntity: entity.isConfirmedEntity,
    newItem: !entity.isConfirmedEntity,
    uuid: uuid,
    modelMeetings: listModelMeetings(
      meetingModels.filter((m) => {
        return m.type !== 'INTERVIEW_TERMINATED';
      }),
      meetingsNotDeleted(state),
      organization,
      functionsNotDeleted(state),
      employeesShortNamesAndI(state),
      getContactsAsUsers(state),
    ),
    templateMeetings: meetingsNotDeleted(state).map((m) => {
      return {
        name: m.name || '',
        uuid: m.uuid,
        helpContent: m.helpContent || '',
        agenda: m.procedures || '',
      };
    }),
    helpContent: relatedMeeting?.helpContent ?? '',
    href: currentParent + '/meetingOccurrences/' + uuid,
    pdfHref:
      BASE_PATH +
      '/organizations/' +
      organization.organizationId +
      '/meetingOccurrences/' +
      uuid +
      '.pdf?key=' +
      key(state),
    parentHref: currentParent,
    deleted: false,
    reminderText: reminderText(entity.reminder),
    assigneeDisplayText: capitalize(
      meetingParticipantsText(entity, [], employeesShortNamesAndI(state), getContactsAsUsers(state)),
    ),
    notes: '',
    timeDescription: dateTimeDescription(entity.date, entity.time, entity.durationMinutes),
    type: 'meetingOccurrences',
    name: entity.name ?? '',
    attachments: toAttachmentItems('meetingOccurrences', entity.uuid, entity.attachments),
    thisEventInstance: '',
    today: LocalDate.fromString(state.today),
    functions: functionsNotDeleted(state),
    availableEmployees: sortBy(
      employeesShortNamesAndI(state)
        .filter((e) => e.name !== '')
        .filter((e) => e.status !== 'TERMINATED' || (entity.participants ?? []).includes(e.uuid))
        .map((p) => ({ value: p.uuid, text: p.name })),
      ['text'],
    ),
    availableContacts: sortBy(
      getContactsAsUsers(state)
        .filter((p) => {
          return !isEmptyOrInvalidString(p.name);
        })
        .map((p) => ({ value: p.uuid, text: p.name.trim() + ' (' + p.partnerName + ')' })),
      ['text'],
    ),
    availableFunctions: getFeatureStates(state).core ? sortBy(singleFunctionsWithEmployeeNames1, ['text']) : [],
    currentEmployeeUuid: currentEmployeeUuid1,
    defaultMeetingResponsibleFunctionUuid: sortBy(singleFunctionsWithEmployeeNames1, ['text'])[0].value,
    defaultMeetingResponsibleEmployeeUuid: currentEmployeeUuid1 ?? '',
    currentEmployeeShortName: currentEmployeeShortName(state),
    employeeFullNames,
    deletable: true,
    hasDraft: meetingOccurrence.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(state.token ?? '', 'meetingOccurrences', uuid, organization.organizationId);
      return command.draft as MeetingOccurrenceViewEditItem;
    },
    date: entity.date,
    time: entity.time ?? '12:00',
    durationMinutes: entity.durationMinutes,
    assignedToEmployees: entity.participants ?? [],
    reminder: entity.reminder ?? 'NONE',
    accessControl: entity.accessControl,
    classification: entity.classification,
    meetingAgenda: entity.agenda ?? '',
    meetingResponsibleFunctionUuid,
    meetingResponsibleEmployeeUuid: entity.responsibleEmployeeUuid ?? '',
    relatedMeetingProcedures: relatedMeeting?.procedures ?? '',
    messageRecipients: getMeetingMessageRecipients(
      state,
      meetingResponsibleFunctionEmployeeUuid,
      entity.participants ?? [],
      entity.externalParticipants,
    ),
    currentUserHasAccess:
      entity.classification === 'NONE' || (entity.accessControl ?? []).includes(currentUserUuid(state) ?? ''),
    docsForLinking: [],
    lastModified: entity.lastModified ?? '',
    templateUpdated: relatedMeeting?.templateUpdated ?? '',
    occurrences: meetingModel.meetingOccurrences
      .filter((o) => {
        return o.isConfirmedEntity;
      })
      .map((o) => {
        return {
          occurrenceUuid: o.uuid,
          dateTime: toDateTime(o.date, o.time ?? ''),
          durationMinutes: o.durationMinutes,
          displayDate: o.displayDate,
          assignees: o.assignees,
          classified: o.classified,
          restricted: o.restricted,
          meetingStatus: o.meetingStatus,
          href: '/meetingOccurrences/' + o.uuid,
        };
      }),
    canUpdateFutureOccurrences,
    organizationId: organization.organizationId,
    userEmails: getUsersForAccess(state)
      .map((e) => e.email ?? '')
      .filter((x) => x !== ''),
    assignedToContacts: entity.externalParticipants,
    availablePartners: sortBy(availablePartners, ['text']),
  };
}
